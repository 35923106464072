import React, { useMemo } from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

import images from 'assets/images';
import Wallet from './wallet/Wallet';

export default ({ user, level }) => {
	const showLevelProgress = useMemo(() => !!level.next || level.progress.percentage, [
		level.next,
		level.progress.percentage,
	]);

	return user.wallet ? (
		<div
			className={classnames('user-panel position-fixed d-flex align-items-center', {
				membership: user.membership,
			})}
		>
			{user.membership && (
				<NavLink
					to={`/team/${user.membership.team().id()}/members`}
					className="d-flex align-items-center justify--center"
				>
					<div
						className="user-panel__teamAvatar position-absolute rounded-circle align-items-center justify--center"
						style={{
							backgroundImage: `url(${user.membership.team().avatar() || images.default.avatar.team})`,
						}}
					/>
				</NavLink>
			)}
			<div
				className={classnames('user-panel__avatarContainer position-relative', {
					'user-panel__avatarContainer--progress': showLevelProgress,
				})}
			>
				{showLevelProgress ? (
					<div className="position-absolute">
						<CircularProgressbar
							value={level.progress.percentage}
							styles={buildStyles({
								strokeLinecap: 'butt',
							})}
						/>
					</div>
				) : null}
				<div className="position-absolute w-100 h-100 d-flex align-items-center">
					{user.userProfile ? (
						<NavLink to={`/user/${user.userProfile.id()}/details`} className="mx-auto">
							<div
								className="user-panel__avatar rounded-circle"
								style={{
									backgroundImage: `url(${user.userProfile.avatar() || images.default.avatar.user})`,
								}}
							/>
						</NavLink>
					) : (
						<NavLink to="/user/details" className="mx-auto">
							<div
								className="user-panel__avatar rounded-circle"
								style={{
									backgroundImage: `url(${images.default.avatar.user})`,
								}}
							/>
						</NavLink>
					)}
				</div>
			</div>
			{level.current && (
				<div className="user-panel__level rounded-circle d-flex align-items-center justify--center fs-5 fs-sm-7 font-weight-bold">
					{level.current.levelNumber()}
				</div>
			)}
			{user.wallet && (
				<div className="user-panel__wallet">
					<Wallet wallet={user.wallet} key={Math.random()} />
				</div>
			)}
		</div>
	) : null;
};
