export default class TrackerService {
	_adapters = {};

	_listenerConfigs = [];
	_trackQueue = [];
	_trackQueueCullBy = 100;
	_trackQueueMaxLength = 500;

	constructor(applicationService) {
		this._applicationService = applicationService;
	}

	registerAdapter(name, adapterClass) {
		this._adapters[name] = adapterClass;
	}

	init() {
		const trackingCodes = this._applicationService.applicationConfig().trackingCodes();

		if (!trackingCodes) {
			return;
		}

		// configs:
		const googleAnalyticsConfig = trackingCodes.googleAnalytics();
		const googleTagManagerConfig = trackingCodes.googleTagManager();

		// adapters:
		const { GoogleAnalytics, GoogleTagManager } = this._adapters;

		if (googleAnalyticsConfig && GoogleAnalytics) {
			this._addTracker(new GoogleAnalytics(googleAnalyticsConfig), googleAnalyticsConfig.events());
		}

		if (googleTagManagerConfig && GoogleTagManager) {
			this._addTracker(new GoogleTagManager(googleTagManagerConfig), googleTagManagerConfig.events());
		}
	}

	_addTracker(tracker, events) {
		let listenerConfig = { tracker, events };
		this._listenerConfigs.push(listenerConfig);

		this._trackQueue.forEach(function(event) {
			this._dispatchToListener(listenerConfig, event.name, event.data);
		}, this);
	}

	track(eventName, eventData) {
		if (!eventData) {
			eventData = {};
		}

		eventData.eventName = eventName;

		if (eventData.eventId === undefined) {
			eventData.eventId = Math.random()
				.toString()
				.slice(2);
		}

		this._addToTrackQueue({ name: eventName, data: eventData });

		this._listenerConfigs.forEach(listenerConfig => this._dispatchToListener(listenerConfig, eventName, eventData));
	}

	_addToTrackQueue(event) {
		this._trackQueue.push(event);

		if (this._trackQueue.length > this._trackQueueMaxLength) {
			this._trackQueue = this._trackQueue.slice(this._trackQueueCullBy);
		}
	}

	_dispatchToListener(listenerConfig, eventName, eventData) {
		let passedFilter = false;

		if (!listenerConfig.events) {
			passedFilter = true;
		} else {
			passedFilter = listenerConfig.events.some(function(eventFilter) {
				if (eventFilter instanceof RegExp) {
					return eventFilter.test(eventName);
				} else {
					return eventName === eventFilter;
				}
			});
		}

		if (passedFilter) {
			listenerConfig.tracker.track(eventName, eventData);
		}
	}
}
