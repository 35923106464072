import APIService from 'services/APIService';
import { ActionDTO } from 'models/onboarding/process/action/Action';
import BaseService from 'services/BaseService';
import LogService from 'services/application/LogService';

export default class ActionService extends BaseService {
	_type = 'action';
	_typeKey = 'content_type';

	constructor(private readonly logService: LogService, private readonly ApiService: APIService) {
		super();
	}

	async fetchActions(employeeId?: string, processesId: string[] = []) {
		let url = '/v1/workflows/process/action?decorate=content,user';

		if (employeeId) {
			url += `&employeeId=${employeeId}`;
		}

		if (processesId.length > 0) {
			url += `&processId=${processesId.join(',')}`;
		}

		return this.ApiService.get<{ items: ActionDTO[] }>(url).then(response => ({
			items: response.items.map(process => this.toModel(process)),
		}));
	}

	toModel<Data>(action: Data) {
		try {
			return super.toModel(action);
		} catch (error) {
			this.logService.warn(error);
		}
	}
}
