import TaskBaseService from 'services/task/TaskBaseService';

import ImageSingleChoiceQuestionTask from 'models/task/question/questionTask/choice/image/ImageSingleChoiceQuestionTask';

export default class ImageSingleChoiceQuestionTaskService extends TaskBaseService {
	toModel(task) {
		return new ImageSingleChoiceQuestionTask({
			...task,
			state: this._mapState(task),
		});
	}
}
