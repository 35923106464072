import AchievementNotificationService from 'services/notification/subServices/AchievementNotificationService';
import BonusTransactionNotificationService from 'services/notification/subServices/BonusTransactionNotificationService';
import ChallengeAwardedPlaceNotificationService from 'services/notification/subServices/ChallengeAwardedPlaceNotificationService';
import ChallengeSubmissionAddedNotificationService from 'services/notification/subServices/ChallengeSubmissionAddedNotificationService';
import ChallengeSubmissionStateChangedNotificationService from 'services/notification/subServices/ChallengeSubmissionStateChangedNotificationService';
import DeclarationTaskAcceptanceChangedNotificationService from 'services/notification/subServices/DeclarationTaskAcceptanceChangedNotificationService';
import EmailNotificationService from 'services/notification/subServices/email/EmailNotificationService';
import emailSubServices from 'services/notification/subServices/email/subServices';
import ItemNotificationService from 'services/notification/subServices/ItemNotificationService';
import LevelUpNotificationService from 'services/notification/subServices/LevelUpNotificationService';
import TargetChallengeCalculatedNotificationService from 'services/notification/subServices/TargetChallengeCalculatedNotificationService';
import TaskNotificationService from 'services/notification/subServices/TaskNotificationService';

export default {
	'services.achievementNotificationService': {
		class: AchievementNotificationService,
		deps: [{ $ref: 'services.achievementService' }, { $ref: 'services.currencyService' }],
	},
	'services.bonusTransactionNotificationService': {
		class: BonusTransactionNotificationService,
		deps: [{ $ref: 'services.currencyService' }],
	},
	'services.challengeAwardedPlaceNotificationService': {
		class: ChallengeAwardedPlaceNotificationService,
		deps: [{ $ref: 'services.challengeAwardedPlaceService' }],
	},
	'services.challengeSubmissionAddedNotification': {
		class: ChallengeSubmissionAddedNotificationService,
		deps: [{ $ref: 'services.challengeSubmissionService' }, { $ref: 'services.currencyService' }],
	},
	'services.challengeSubmissionStateChangedNotificationService': {
		class: ChallengeSubmissionStateChangedNotificationService,
		deps: [{ $ref: 'services.challengeSubmissionService' }, { $ref: 'services.currencyService' }],
	},
	'services.declarationTaskAcceptanceChangedNotificationService': {
		class: DeclarationTaskAcceptanceChangedNotificationService,
		deps: [{ $ref: 'services.taskService' }],
	},
	'services.emailNotificationService': {
		class: EmailNotificationService,
		calls: [
			/*{
				name: 'registerSubService',
				args: [
					'email.registration',
					{$ref: 'services.registrationEmailNotificationService'},
				],
			},
			{
				name: 'registerSubService',
				args: [
					'email.remember_pass',
					{$ref: 'services.rememberPassEmailNotificationService'},
				],
			},
			{
				name: 'registerSubService',
				args: [
					'email.authorization.email.reset_password',
					{$ref: 'services.submissionRejectedEmailNotificationService'},
				],
			},
			{
				name: 'registerSubService',
				args: [
					'email.challenge.submission_status_rejected',
					{$ref: 'services.submissionRejectedEmailNotificationService'},
				],
			},
			{
				name: 'registerSubService',
				args: [
					'email.challenge.submission_status_accepted',
					{$ref: 'services.submissionAcceptedEmailNotificationService'},
				],
			},*/
		],
	},
	...emailSubServices,
	'services.itemNotificationService': {
		class: ItemNotificationService,
		deps: [
			{ $ref: 'services.storeItemService' },
			{ $ref: 'services.currencyService' },
			{ $ref: 'services.rewardCafeteriaService' },
		],
	},
	'services.levelUpNotificationService': {
		class: LevelUpNotificationService,
		deps: [{ $ref: 'services.currencyService' }],
	},
	'services.targetChallengeCalculatedNotificationService': {
		class: TargetChallengeCalculatedNotificationService,
		deps: [
			{ $ref: 'services.challengeService' },
			{ $ref: 'services.currencyService' },
			{ $ref: 'services.transactionLinkService' },
		],
	},
	'services.taskNotificationService': {
		class: TaskNotificationService,
		deps: [{ $ref: 'services.taskService' }, { $ref: 'services.currencyService' }],
	},
};
