import blocks from 'components/task/types/content/blocks';
import EmbeddedAnalytics from 'components/task/types/embeddedAnalytics/EmbeddedAnalyticsTask';

export default {
	...blocks,
	'components.task.EmbeddedAnalytics': {
		factory: EmbeddedAnalytics,
		deps: [{ $ref: 'components.task.layout.BaseLayout' }],
	},
};
