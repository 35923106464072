import React from 'react';
import { Link } from 'react-router-dom';

import ExtendedEntryComponent from 'components/ranking/_/entry/extended-entry/ExtendedEntry';

export default props => {
	const { currency, position, entry, team, container, name, shortName, scores } = props;

	return (
		<div className="col-24">
			<div className="ranking-entry d-flex mx-0 mx-sm-3">
				<div className="d-flex flex-grow-1">
					<div className="d-flex">
						<p className="d-flex entry-position px-3 px-sm-5 py-3 align-items-center text-right">
							{position}.
						</p>
						<div className="d-flex entry-avatar ml-3 mr-3 mr-sm-5 my-3 align-items-center">
							<div className="position-relative">
								{container ? (
									<div className="container-avatar rounded-circle text-center">{name[0]}</div>
								) : (
									<Link to={entry.url}>
										<div
											style={{
												backgroundImage: `url(${entry.avatar})`,
											}}
											className="avatar rounded-circle"
										/>
									</Link>
								)}
								{entry.level && (
									<div className="user-level position-absolute rounded-circle">{entry.level}</div>
								)}
								{team && (
									<Link
										to={team.url}
										className="team-avatar position-absolute rounded-circle"
										style={{ backgroundImage: `url(${team.avatar})` }}
									/>
								)}
							</div>
						</div>
					</div>
					<div className="d-flex flex-column flex-sm-row flex-grow-1 justify-content-center justify-content-sm-between align-items-sm-stretch py-3 py-sm-0">
						<div className="d-flex align-items-center h-100 py-0 py-sm-3">
							{container ? (
								<div>{name}</div>
							) : (
								<Link to={entry.url}>
									<div className="d-flex flex-column flex-sm-row">
										<p className="entry-fullName">{name}</p>
										{shortName && (
											<p className="entry-shortName ml-0 ml-sm-3 mt-1 mt-sm-0">{shortName}</p>
										)}
									</div>
								</Link>
							)}
						</div>
						<div className="d-flex align-items-stretch">
							<div className="d-flex entry-scores px-0 pl-sm-5 pr-sm-4 mt-1 mt-sm-0 py-0 py-sm-3 rounded-right">
								<div className="d-flex align-items-center h-100">
									{scores.map((score, index) => (
										<div key={index} className="d-flex align-items-center mr-2">
											{currency}
											<p className="text-right">{score}</p>
										</div>
									))}
								</div>
								{entry.description && (
									<div className="ranking-entry__loupe pr-2 pl-2 align-self-stretch d-flex">
										<ExtendedEntryComponent {...props} />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
