import InternalDynamicAnchorPrize from 'models/application/anchor/types/internalDynamic/types/Prize';

export default class InternalDynamicAnchorPrizeService {
	constructor(itemService) {
		this._itemService = itemService;
	}

	toModel({ item, ...rest }) {
		return new InternalDynamicAnchorPrize({
			item: this._itemService.toModel(item),
			...rest,
		});
	}
}
