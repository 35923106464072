import TaskState from 'models/task/state/TaskState';

/**
 * Class representing a task.
 * @abstract
 */
export default class Task {
	constructor(task) {
		/**
		 * @type {string}
		 */
		this._id = task.id;
		/**
		 * @type {string}
		 */
		this._backgroundImage = task.background_image;
		/**
		 * @type {boolean}
		 */
		this._beta = task.beta;
		/**
		 * @type {Object}
		 */
		this._color = task.color;
		/**
		 * @type {string}
		 */
		this._description = (task.description || '');
		/**
		 * @type {number}
		 */
		this._difficultyLevel = task.difficulty_level;
		/**
		 * @type {string}
		 */
		this._icon = task.icon;
		/**
		 * @type {string}
		 */
		this._image = task.image;
		/**
		 * @type {string}
		 */
		this._instructionsForPlayer = task.instructions_for_player;
		/**
		 * @type {string}
		 */
		this._listImage = task.list_image;
		/**
		 * @type {string}
		 */
		this._name = (task.name || '');

		this._acceptanceMessage = task.acceptance_message;
		this._acceptanceRequired = task.acceptance_required;

		if (task.state) {
			this.setState(task.state);
		}

		/**
		 * @type {string}
		 */
		this._taskType = task.task_type;
		/**
		 * @type {string}
		 */
		this._viewWhenComplete = !!task.view_when_complete;
		/**
		 * @type {string}
		 */
		this._successMessage = task.success_message;
		/**
		 * @type {string}
		 */
		this._failureMessage = task.failure_message;
		/**
		 * @type {Object}
		 * @private
		 */
		this._nextTask = task.next_task;
		/**
		 * @type {Object}
		 * @private
		 */
		this._helpTask = task.help_task;
		/**
		 * @type {string}
		 * @private
		 */
		this._helpExternalUrl = task.help_external_url;

		/**
		 * @type {Object|null}
		 */
		this._feedback = null;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._complete = false;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._failed = false;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._submitted = false;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._crashed = false;
	}

	id() {
		return this._id;
	}

	backgroundImage() {
		return this._backgroundImage;
	}

	beta() {
		return this._beta;
	}

	color() {
		return this._color;
	}

	description() {
		return this._description;
	}

	difficultyLevel() {
		return this._difficultyLevel;
	}

	/**
	 * @deprecated use task.state().earningsInformation() instead
	 * @return {*}
	 */
	earningsInformation() {
		return this.state().earningsInformation();
	}

	/**
	 * @deprecated use task.state().hasCompletionEarnings() instead
	 * @return {*}
	 */
	hasCompletionEarnings() {
		return this.state().hasCompletionEarnings();
	}

	icon() {
		return this._icon;
	}

	image() {
		return this._image;
	}

	instructionsForPlayer() {
		return this._instructionsForPlayer;
	}

	listImage() {
		return this._listImage;
	}

	name() {
		return this._name;
	}

	state() {
		return this._state;
	}

	setState(state) {
		if (!(state instanceof TaskState)) {
			throw new Error('Task state must be instance of TaskState');
		}

		this._state = state;
	}

	taskType() {
		return this._taskType;
	}

	isMission() {
		return false;
	}

	viewWhenComplete() {
		return this._viewWhenComplete;
	}

	successMessage() {
		return this._successMessage;
	}

	failureMessage() {
		return this._failureMessage;
	}

	nextTask() {
		return this._nextTask;
	}

	helpTask() {
		return this._helpTask;
	}

	helpExternalUrl() {
		return this._helpExternalUrl;
	}

	helpUrl() {
		if (this.helpExternalUrl()) {
			return this.helpExternalUrl();
		}

		return `/${this.helpTask().isMission() ? 'mission' : 'task'}/${this.helpTask().id()}/details`;
	}

	helpEnabled() {
		return this.helpTask() || this.helpExternalUrl();
	}

	/**
	 * @param {object} feedback Feedback model based on task process response feedback field
	 */
	setFeedback(feedback) {
		this._feedback = feedback;
	}

	feedback() {
		return this._feedback;
	}

	setFailed() {
		this._failed = true;
	}

	isFailed() {
		return this._failed;
	}

	setComplete() {
		this._complete = true;
	}

	isCompleted() {
		return this._complete;
	}

	setSubmitted() {
		this._submitted = true;
	}

	isSubmitted() {
		return this._submitted;
	}

	canBeSubmitted() {
		return !this._submitted;
	}

	setCrashed() {
		this._crashed = true;
	}

	isCrashed() {
		return this._crashed;
	}

	reset() {
		this._failed = false;
		this._feedback = null;
		this._submitted = false;
		this._crashed = false;
	}

	acceptanceMessage() {
		return this._acceptanceMessage;
	}

	acceptanceRequired() {
		return this._acceptanceRequired;
	}
}
