import Process from 'models/onboarding/process/Process';
import moment, { Moment } from 'moment';
import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';
import { useMemo } from 'react';
import { workflowCategory } from 'i18n/util/workflowCategory';

export type UseGetProcessListDataProps = {
	processList: Process[];
	expandable?: boolean;
};

const getLastProcessShouldEnd = (processList: Process[]): Moment => {
	let last = processList[0].processShouldEndAt;

	processList.forEach(process => {
		if (last.valueOf() < process.processShouldEndAt.valueOf()) {
			last = process.processShouldEndAt;
		}
	});

	return last;
};

const getIsFirstDaySame = (processList: Process[]): boolean => {
	const firstDay = processList[0].firstDay;

	return processList.every(process => process.firstDay.valueOf() === firstDay.valueOf());
};

const getIsWorkflowCategorySame = (processList: Process[]): boolean => {
	const workflowCategory = processList[0].workflowCategory;

	return processList.every(process => process.workflowCategory === workflowCategory);
};

const hideDurationForWorkflowCategory = 'last-day';

export const useGetProcessListData = ({ processList, expandable }: UseGetProcessListDataProps) => {
	return useMemo(() => {
		const lastProcessShouldEnd = getLastProcessShouldEnd(processList);
		const isFirstDaySame = getIsFirstDaySame(processList);
		const isWorkflowCategorySame = getIsWorkflowCategorySame(processList);
		const daysToProcessEnd =
			lastProcessShouldEnd.valueOf() - new Date().getTime() > 0
				? lastProcessShouldEnd.diff(moment(), 'days') + 1
				: 0;
		const allActionsDone = processList.reduce((prev, current) => prev + current.actionsDone, 0);
		const allActionsCount = processList.reduce((prev, current) => prev + current.actionsCount, 0);
		const isDelayed = processList.some(process => process.actionsDelayed);
		const progress = Math.floor((allActionsDone / allActionsCount) * 100);
		const roles = uniqBy(flatten(processList.map(process => process.roles)), role => role.roleId).filter(
			role => role.user
		);
		const canExpand = expandable && processList.length > 1;
		const shouldShowProcessDate =
			isWorkflowCategorySame && isFirstDaySame && processList[0].firstDay.valueOf() - new Date().getTime() > 0;
		const shouldShowProcessDuration = !shouldShowProcessDate
			? true
			: workflowCategory(processList[0].workflowCategory) !== hideDurationForWorkflowCategory;

		return {
			lastProcessShouldEnd,
			isFirstDaySame,
			isWorkflowCategorySame,
			daysToProcessEnd,
			allActionsDone,
			allActionsCount,
			isDelayed,
			progress,
			roles,
			canExpand,
			shouldShowProcessDate,
			shouldShowProcessDuration,
		};
	}, [processList, expandable]);
};
