import Notification from 'models/notification/Notification';

/**
 * Class representing a bonus transaction notification.
 * @extends Notification
 */
export default class BonusTransactionNotification extends Notification {
	/**
	 * @param {Object} notification
	 * @param {Object} images
	 */
	constructor(notification, images) {
		super(notification);

		this._images = images;
		this._title = notification.title;
		this._type = 'bonus-transaction';
	}

	getMessage() {
		return super.getMessage('messages.bonusTransaction');
	}

	getDate(params) {
		return super.getDate('dates.bonusTransaction', params);
	}

	getIconPath() {
		return super.getIconPath(this._images.icon.staticNotificationTypes.bonusTransactionNotification);
	}
}
