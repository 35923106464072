import React, { PureComponent, Fragment } from 'react';
import { wire } from 'react-hot-wire';
import { Formik } from 'formik';
import { injectIntl } from 'react-intl';

import AuthorizeCode from './AuthorizeCode.template';
import { schema } from './AuthorizeCode.schema';
import SetPassword from '../set-password/SetPassword';

export class AuthorizeCodeComponent extends PureComponent {
	render() {
		return (
			<Formik
				initialValues={{
					authorizationCode: '',
				}}
				validationSchema={schema}
				render={props => (
					<Fragment>
						<AuthorizeCode {...props} {...this.props} />
						<SetPassword
							{...this.props}
							onAuthorizeError={error => props.setFieldError('authorizationCode', error)}
							authorizationCode={props.values.authorizationCode}
							type="phone"
						/>
					</Fragment>
				)}
			/>
		);
	}
}

export default injectIntl(wire(['services.importedIdIntegrationService'], AuthorizeCodeComponent));
