import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import { isBoolean } from 'lodash';

import VerifyToken from './VerifyToken.template';
import SetPassword from '../set-password/SetPassword';

export class VerifyTokenComponent extends PureComponent {
	static actions = {
		'reset-password': 'resetPasswordCheck',
		register: 'registerCheck',
	};

	state = {
		isValid: null,
	};

	componentDidMount() {
		const { integration, token, action } = this.props;
		const actionName = this.constructor.actions[action];

		this.props['services.emailIntegrationService']
			// eslint-disable-next-line no-unexpected-multiline
			[actionName]({
				id: integration.id,
				reset_token: token,
			})
			.then(isValid => this.setState({ isValid }));
	}

	render() {
		if (!isBoolean(this.state.isValid)) {
			return null;
		}

		return (
			<VerifyToken isValid={this.state.isValid} integrationId={this.props.integration.id}>
				<SetPassword {...this.props} />
			</VerifyToken>
		);
	}
}

export default wire(['services.emailIntegrationService'], VerifyTokenComponent);
