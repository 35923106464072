import _ from 'lodash';

/**
 * TODO: better generic typing (remove any)
 */

export interface ServiceInterface {
	registerSubService(type: string, service: any): void;
	toModel<Data>(subject: Data, ...rest: any[]): any;
}

export default abstract class BaseService implements ServiceInterface {
	protected abstract _type: string;
	protected abstract _typeKey: string;
	protected _subServices: { [key: string]: any } = {};

	registerSubService(type: string, service: any) {
		this._subServices[type] = service;
	}

	toModel<Data>(subject: Data, ...rest: any[]): any {
		const subService = this._recognizeSubService(subject);

		return subService && subService.toModel(subject, ...rest);
	}

	protected _recognizeSubService<Data>(subject: Data): any {
		const type = _.get(subject, this._typeKey);
		const subService = this._subServices[type];

		if (!subService) {
			throw new Error(`Unrecognized ${this._type} type: '${type}'.`);
		}

		return subService;
	}
}
