import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

export default ({ container, entry, name, ReactMarkdown, open, close, visible }) => (
	<div className="extended-entry d-flex" onMouseEnter={() => open()} onMouseLeave={() => close()}>
		<div
			className="extended-entry__loupe d-flex align-items-center justify-content-center mr-2 mr-xs-0 ml-1 ml-xs-3 p-0 p-xs-2"
			onClick={event => open(event)}
		>
			<i className="fa fa-search fa-flip-horizontal" />
		</div>
		{visible && (
			<Fragment>
				<div className="extended-entry__backdrop d-block d-lg-none fixed-top fixed-bottom" />
				<div
					className="extended-entry__cloud position-absolute shadow p-3 rounded"
					onClick={event => event.preventDefault()}
				>
					<div className="entry d-flex d-lg-none align-items-center pb-2">
						{container ? (
							<div>{container.firstLetter}</div>
						) : (
							<div className="entry__avatar">
								<img src={entry.avatar} className="rounded-circle" alt="" />
							</div>
						)}
						<div className="entry__name flex-grow-1 pl-3">
							<NavLink to={entry.url} className="font-weight-bold">
								{name}
							</NavLink>
						</div>
					</div>
					<p>
						<ReactMarkdown source={entry.description} />
					</p>
					<div
						className="extended-entry__close d-flex d-lg-none align-items-center justify-content-center position-absolute rounded-circle"
						onClick={event => close(event)}
					>
						<i className="fa fa-times" />
					</div>
				</div>
			</Fragment>
		)}
	</div>
);
