import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';

import resetPasswordIntegrations from './integrations';
import { Integration } from 'components/auth/Integration';
import Logo from '../Logo';
import LanguageSwitcher from 'components/auth/LanguageSwitcher';

export class ResetPasswordComponent extends PureComponent {
	render() {
		const integrations = this.props['services.applicationService'].applicationConfig().integrations();
		const { id } = this.props.match.params;

		if (id) {
			return (
				<div className="integrations integrations__resetPassword">
					<div className="content">
						<LanguageSwitcher containerClassName="auth top-right" />
						<Logo />
						{Integration(resetPasswordIntegrations, integrations.get(id))}
					</div>
				</div>
			);
		}
	}
}

export default wire(['services.applicationService'], ResetPasswordComponent);
