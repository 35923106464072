import KPIIndexedChallenge from 'models/challenge/challenge/KPIIndexedChallenge';
import KPIChallengeState from 'models/challenge/challenge/state/KPIChallengeState';

import ChallengeBaseService from 'services/challenge/challenge/ChallengeBaseService';

export default class KPIIndexedChallengeService extends ChallengeBaseService {
	constructor(rankingService) {
		super();
		this._rankingService = rankingService;
	}

	toModel(challenge) {
		return new KPIIndexedChallenge({
			...challenge,
			state: challenge.state && new KPIChallengeState(challenge.state),
			ranking: challenge.ranking && this._rankingService.toModel(challenge.ranking),
		});
	}
}
