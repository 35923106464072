import React, { Fragment } from 'react';
import { wire } from 'react-hot-wire';
import { Field, FieldArray } from 'formik';
import { injectIntl } from 'react-intl';

import { ErrorMessage } from 'components/_/form/error/Error';

import { Button } from 'components/_/common/button/Button';

import InputFile from 'components/_/form/input-file/InputFile';

export const FileUploadFormBlock = wire(
	['services.taskService'],
	injectIntl(({ intl, formBlock, values, task, disabled, ...props }) => {
		const taskService = props['services.taskService'];

		return (
			<div className="fs-4">
				<p className="mb-3">{formBlock.body()}</p>
				<div className="w-100 w-sm-50">
					<FieldArray
						name={formBlock.id()}
						render={arrayHelpers => {
							return (
								<Fragment>
									{values[formBlock.id()].map(({ id, fileMetadata }, index) => {
										return (
											<div className="mb-3" key={index}>
												<Field name={`${formBlock.id()}[${index}].file`}>
													{({ field }) => {
														const saveFileHandler = file => {
															const form = new FormData();

															form.append('uploads[0][id]', formBlock.id());
															form.append('uploads[0][files][0][id]', null);
															form.append('uploads[0][files][0][file]', file);
															form.append('uploads[0][files][0][delete]', '0');

															return taskService
																.processTask(task, form)
																.then(({ task }) => {
																	const uploads = task
																		.state()
																		.currentRepetitionState()
																		.formResponse()
																		.fileUploads();

																	arrayHelpers.replace(
																		index,
																		uploads[uploads.length - 1]
																	);
																});
														};
														const deleteFileHandler = () => {
															const form = new FormData();

															form.append('uploads[0][id]', formBlock.id());
															form.append('uploads[0][files][0][id]', id);
															form.append('uploads[0][files][0][file]', null);
															form.append('uploads[0][files][0][delete]', '1');

															return taskService.processTask(task, form).then(() => {
																arrayHelpers.remove(index);

																if (values[formBlock.id()].length === 1) {
																	arrayHelpers.push({});
																}
															});
														};
														const inputFileProps = {
															...field,
															key: id,
															disabled,
															file: id
																? {
																		name: fileMetadata.originalName,
																		type: fileMetadata.mimeType,
																  }
																: null,
															saveFileHandler,
															deleteFileHandler,
														};

														if (!id && values[formBlock.id()].length > 1) {
															inputFileProps.clearInputHandler = () =>
																arrayHelpers.remove(index);
														}

														return <InputFile {...inputFileProps} />;
													}}
												</Field>
												<ErrorMessage
													name={`${formBlock.id()}[${index}]`}
													extract={obj => obj.id}
												/>
											</div>
										);
									})}
									{values[formBlock.id()].every(({ id }) => id) && (
										<Button
											onClick={() => arrayHelpers.push({})}
											color={disabled ? 'disabled' : 'primary'}
											disabled={disabled}
										>
											{intl.formatMessage({ id: 'common.form.block.fileUpload.addAnotherFile' })}
										</Button>
									)}
								</Fragment>
							);
						}}
					/>
				</div>
			</div>
		);
	})
);
