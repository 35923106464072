import subServices from 'services/task/subServices/questionTask/trueFalse/answer/mode/subServices';
import TrueFalseAnswerModeService from 'services/task/subServices/questionTask/trueFalse/answer/mode/TrueFalseAnswerModeService';

export default {
	...subServices,
	'services.task.trueFalse.answer.mode': {
		class: TrueFalseAnswerModeService,
		deps: [{ $ref: 'services.logService' }],
		calls: [
			{
				name: 'registerSubService',
				args: ['custom', { $ref: 'services.task.trueFalse.answer.mode.custom' }],
			},
			{
				name: 'registerSubService',
				args: ['happy-sad', { $ref: 'services.task.trueFalse.answer.mode.happySad' }],
			},
			{
				name: 'registerSubService',
				args: ['plus-minus', { $ref: 'services.task.trueFalse.answer.mode.plusMinus' }],
			},
			{
				name: 'registerSubService',
				args: ['true-false', { $ref: 'services.task.trueFalse.answer.mode.trueFalse' }],
			},
		],
	},
};
