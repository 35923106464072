import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import types from 'components/ranking/_/entry/types';

import useFetch from 'components/_/hooks/useFetch';

import Loader from 'components/commons/_/loader/Loader';
import Pagination from 'components/_/common/pagination/Pagination';
import { RankingPositionListSearch } from './RankingPositionListSearch';
import Media from 'react-media';

export const RankingPositionList = ({ rankingId, container, team, rankingService, showSearch = false }) => {
	const i18nKey = 'ranking';

	function singlePosition({ ranking, entry, position, index }) {
		if (!types[ranking.type]) {
			return;
		}

		const PositionElement = types[ranking.type];

		return <PositionElement key={index} {...{ ranking, position, entry }} />;
	}

	const [rowsPerPage] = useState(100);
	const [page, setPage] = useState(0);
	const [search, setSearch] = useState('');

	const {
		loading,
		error,
		response: { ranking, list = [], count = 0 },
	} = useFetch(
		() =>
			rankingService.fetchRanking({
				id: rankingId,
				offset: page * rowsPerPage,
				limit: rowsPerPage,
				container,
				team,
				search,
			}),
		{ list: [], count: 0 },
		[rankingId, page, rowsPerPage, container, team, search]
	);

	return (
		<div className="ranking-view__list flex-grow-1 my-4 my-sm-0 fs-4">
			{showSearch && (
				<div className="mb-2 d-flex justify--end">
					<Media
						queries={{
							mobile: '(max-width: 576px)',
							others: '(min-width: 576px)',
						}}
						render={({ others, mobile }) => (
							<Fragment>
								{mobile && (
									<RankingPositionListSearch
										search={search}
										setSearch={setSearch}
										style={{ flexGrow: 1 }}
									/>
								)}
								{others && (
									<RankingPositionListSearch
										search={search}
										setSearch={setSearch}
										style={{ maxWidth: '300px', flexGrow: 1 }}
									/>
								)}
							</Fragment>
						)}
					/>
				</div>
			)}
			{loading ? (
				<Loader />
			) : error ? (
				<p className="fs-6 mx-0 mx-sm-3">
					<FormattedMessage id={`${i18nKey}.error.${error}`} />
				</p>
			) : isEmpty(list) ? (
				<p className="fs-6 mx-0 mx-sm-3">
					<FormattedMessage id={`${i18nKey}.error.empty`} />
				</p>
			) : (
				<div className="row mb-4 mb-sm-5">
					{list.map(({ position, entry }, index) => singlePosition({ ranking, position, entry, index }))}
				</div>
			)}

			{count > 0 && !loading && (
				<Pagination count={count} page={page} rowsPerPage={rowsPerPage} onChange={page => setPage(page)} />
			)}
		</div>
	);
};
