import _ from 'lodash';
import QuestionTaskService from '../QuestionTaskService';

import OrderQuestionTask from 'models/task/question/questionTask/order/OrderQuestionTask';

/**
 * @extends QuestionTaskService
 */
export default class OrderQuestionTaskService extends QuestionTaskService {
	/**
	 * @param {TaskStateService} taskStateService
	 * @param {OrderAnswerService} orderAnswerService
	 */
	constructor(taskStateService, orderAnswerService) {
		super(taskStateService);

		/**
		 * @type {OrderAnswerService}
		 * @private
		 */
		this._orderAnswerService = orderAnswerService;
	}

	/**
	 * @param {Object} task
	 * @returns {OrderQuestionTask}
	 */
	toModel(task) {
		return new OrderQuestionTask({
			...task,
			question: {
				...task.question,
				answers:
					task.question && _.map(task.question.answers, answer => this._orderAnswerService.toModel(answer)),
			},
			state: this._mapState(task),
		});
	}
}
