import Async from 'components/commons/Async';
import AutoWidthInput from 'components/commons/AutoWidthInput';
import AutoScrollingText from 'components/commons/AutoScrollingText';
import currencies from 'components/commons/currencies';
import Image from 'components/commons/Image';
import NodeConnector from 'components/commons/NodeConnector';
import ImageViewer from 'components/commons/ImageViewer';
import inProgress from 'components/commons/inProgress';
import MatchingImageType from 'components/commons/MatchingImageType';
import ResizableTextField from 'components/commons/ResizableTextField';
import StateManager from 'components/commons/StateManager';
import tabs from 'components/commons/tabs';
import RemainingChars from 'components/commons/RemainingChars';

export default {
	'components.Async': {
		factory: Async,
	},
	'components.AutoWidthInput': {
		factory: AutoWidthInput,
	},
	'components.base.AutoScrollingText': {
		factory: AutoScrollingText,
	},
	...currencies,
	'components.Image': {
		factory: Image,
		deps: [{ $ref: 'services.imageService' }],
	},
	'components.base.ImageViewer': {
		factory: ImageViewer,
	},
	'enhancers.inProgress': {
		factory: inProgress,
	},
	'components.MatchingImageType': {
		factory: MatchingImageType,
		deps: [{ $ref: 'components.MatchingImageType.template' }],
	},
	'components.NodeConnector': {
		factory: NodeConnector,
	},
	'components.RemainingChars': {
		factory: RemainingChars,
	},
	'components.ResizableTextField': {
		factory: ResizableTextField,
		deps: [{ $ref: 'components.RemainingChars' }],
	},
	'components.StateManager': {
		factory: StateManager,
	},
	...tabs,
};
