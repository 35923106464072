import Agreement from './Agreement';
import Agreements from './Agreements';

export default {
	'components.Agreement': {
		factory: Agreement,
		deps: [{ $ref: 'services.userService' }, { $ref: 'components.ReactMarkdown' }],
	},
	'components.Agreements': {
		factory: Agreements,
		deps: [{ $ref: 'services.applicationService' }, { $ref: 'components.Agreement' }],
	},
};
