import { WorkflowCategory, WorkflowCategoryPhase } from 'models/onboarding/workflow/WorkflowCategory';

const categories = {
	[WorkflowCategory['talent-and-onboarding']]: WorkflowCategoryPhase['first-day'],
	[WorkflowCategory['learning-and-development']]: WorkflowCategoryPhase['key-date'],
	[WorkflowCategory.offboarding]: WorkflowCategoryPhase['last-day'],
};

export function workflowCategory(category: keyof typeof WorkflowCategory) {
	return categories[category];
}
