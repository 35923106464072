import ContentBlock from 'models/common/content/block/ContentBlock';

export default class ButtonContentBlock extends ContentBlock {
	constructor(block) {
		super(block);

		this._text = block.text;
		this._link = block.link;
		this._openInNewWindow = !!block.open_in_new_window;
	}

	text() {
		return this._text;
	}

	link() {
		return this._link;
	}

	openInNewWindow() {
		return this._openInNewWindow;
	}
}
