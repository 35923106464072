import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { Currency } from 'components/_/common/currency/Currency';

import { UserAvatar } from 'components/_/app/user/user-avatar/UserAvatar';

import { Select } from 'components/_/form/select/Select';

export const TeamMember = ({ member, editable, teamService }) => {
	const wallet = member.userProfile.user.wallet();
	const level = member.userProfile.user.level();

	const $member = (
		<div className="member d-flex align-items-center">
			<div className="mr-3">
				<UserAvatar
					user={{
						href: `/user/${member.userProfile.id()}/details`,
						avatar: member.userProfile.avatar(),
						level: level && level.levelNumber(),
					}}
				/>
			</div>
			<div className="mr-0 mr-xs-3 flex-grow-1 flex-sm-grow-0">
				<p className="name mb-1 w-100">{member.userProfile.fullName()}</p>
				<p
					className={classnames('nickname mb-1 w-100', {
						invisible: !member.userProfile.nickname(),
					})}
				>
					{member.userProfile.nickname() || '[hidden]'}
				</p>
				<div className="role mb-1">
					{editable ? (
						<Select
							name="role"
							onChange={() => {
								member.roles.toggleRole();

								return teamService.editRole(member);
							}}
							selected={member.roles.role()}
							list={[
								{
									value: 'user',
									content: 'user',
								},
								{
									value: 'manager',
									content: 'manager',
								},
							]}
						/>
					) : (
						member.roles.role()
					)}
				</div>
				<div className="wallet d-flex flex-wrap w-100">
					{Object.keys(wallet).map(name => (
						<Currency key={name} currency={name} value={wallet[name] || 0} className="mr-2" />
					))}
				</div>
			</div>
		</div>
	);

	return (
		<div className="col-24 col-xs-12 col-sm-8 col-lg-6 col-xl-4 mb-4">
			{editable ? $member : <Link to={`/user/${member.userProfile.id()}/details`}>{$member}</Link>}
		</div>
	);
};
