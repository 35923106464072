import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

export default () => {
	return function nextTaskButton(args) {
		const { notification, close } = args;
		const task = notification.task();

		return (
			<NavLink
				className="btn__basic"
				onClick={() => close()}
				to={`/${task.nextTask().isMission() ? 'mission' : 'task'}/${task.nextTask().id()}/details`}
			>
				<FormattedMessage id={`notification.real-time.${notification.type()}.button.nextTask`} />
			</NavLink>
		);
	};
};
