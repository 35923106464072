export default class FormBlock {
	constructor(formBlock) {
		this._id = formBlock.id;
		this._type = formBlock.type;
		this._required = formBlock.required;
	}

	id() {
		return this._id;
	}

	type() {
		return this._type;
	}

	required() {
		return this._required;
	}
}
