import Notification from 'models/notification/Notification';

/**
 * Class representing a challenge awarded place notification.
 * @extends Notification
 */
export default class ChallengeAwardedPlaceNotification extends Notification {
	/**
	 * @param {Object} notification
	 * @param {Object} images
	 */
	constructor(notification, images) {
		super(notification);

		this._awardedPlace = notification.awarded_place;
		this._removed = this._awardedPlace.challenge.type() !== 'kpi' && !this._awardedPlace.submissions;
		this._type = 'challenge-awarded-place';
		this._earnings = notification.awarded_place.points();
		this._images = images;
	}

	getIconPath() {
		return super.getIconPath(this._images.icon.staticNotificationTypes.awardedPlace);
	}

	getMessage() {
		if (this._removed) {
			return super.getMessage('null-states.awardedPlace');
		}

		const challenge = this._awardedPlace.challenge;
		const awardedPlace = this._awardedPlace.placeInDecimal();

		return super.getMessage('messages.submission.awardedPlace', {
			challengeTitle: challenge.title(),
			awardedPlace,
		});
	}

	getDate(params) {
		return super.getDate('dates.submission.awardedPlace', params);
	}
}
