import moment from 'moment';

/**
 * Class representing a submission.
 * @abstract
 */
export default class Submission {
	/**
	 * @param {Object} submission
	 */
	constructor(submission) {
		/**
		 * @type {string}
		 */
		this._id = submission.id;
		/**
		 * @type {string}
		 */
		this._title = submission.title;
		/**
		 * @type {string}
		 */
		this._rejectionReason = submission.rejection_reason;
		/**
		 * @type {Challenge}
		 */
		this.challenge = submission.challenge;
		/**
		 * @type {UserProfile}
		 */
		this._author = submission.userProfile;
		/**
		 * @type {string}
		 */
		this._submissionType = submission.submission_type;
		/**
		 * @type {boolean}
		 */
		this._hidden = !!submission.hidden;
		/**
		 * @type {string}
		 * @private
		 */
		this._status = submission.status;
		/**
		 * @type {Date}
		 * @private
		 */
		this._createdAt = moment(submission.created_at);
		/**
		 * @type {number}
		 */
		this._averageScore = submission.averageScore || 0;
		/**
		 * @type {number}
		 */
		this._likeCount = submission.likeCount || 0;
	}

	averageScore() {
		return this._averageScore;
	}

	likeCount() {
		return this._likeCount;
	}

	id() {
		return this._id;
	}

	title() {
		return this._title;
	}

	rejectionReason() {
		return this._rejectionReason;
	}

	author() {
		return this._author;
	}

	/**
	 * @throws {Error}
	 * @returns {string}
	 */
	status() {
		return Submission.STATUS_TYPES[this._status];
	}

	/**
	 * @returns {string}
	 */
	createdAt() {
		return this._createdAt.format(Submission.DATE_FORMAT);
	}

	submissionType() {
		return this._submissionType;
	}

	hidden() {
		return this._hidden;
	}

	/**
	 * @type {{
	 * 	STATUS_ACCEPTED: string,
	 * 	STATUS_NEW: string,
	 * 	STATUS_REJECTED: string
	 * }}
	 */
	static STATUS_TYPES = {
		STATUS_ACCEPTED: 'accepted',
		STATUS_NEW: 'new',
		STATUS_REJECTED: 'rejected',
	};

	/**
	 * @type {string}
	 */
	static DATE_FORMAT = 'DD-MM-YYYY HH:mm';
	static MAX_TITLE_LENGTH = 100;
}
