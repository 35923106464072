import TaskNotification from 'models/notification/TaskNotification';

import NotificationBaseService from 'services/notification/NotificationBaseService';

export default class TaskNotificationService extends NotificationBaseService {
	constructor(taskService, currencyService) {
		super();
		this._taskService = taskService;
		this._currencyService = currencyService;
	}

	toModel({ task, earnings, ...rest }) {
		return new TaskNotification({
			task: task && this._taskService.toModel(task),
			earnings: this._currencyService.toModel(earnings),
			...rest,
		});
	}
}
