import BaseService from 'services/BaseService';

export default class FormBlockService extends BaseService {
	_type = 'formBlock';
	_typeKey = 'type';

	constructor(logService) {
		super();

		this._logService = logService;
	}

	toModel(formBlock) {
		try {
			return super.toModel(formBlock);
		} catch (error) {
			this._logService.warn(error);
		}
	}
}
