import React from 'react';

export default function Layout1({ children }) {
	return (
		<div>
			<section className="middle">
				<div>
					<div className="contentWrapper image--cover">
						<section className="mainContent">
							<div className="mainContainer">{children}</div>
						</section>
					</div>
				</div>
			</section>
		</div>
	);
}
