import React, { Fragment } from 'react';
import { wire } from 'react-hot-wire';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';

import Loader from 'components/commons/_/loader/Loader';

import useFetch from 'components/_/hooks/useFetch';

import { Notifications } from './Notifications.template';

function NotificationsComponent(props) {
	const i18nKey = 'notification-list';
	const {
		loading,
		response: { list },
	} = useFetch(
		() =>
			props['services.notificationService'].fetchList({
				id: props.id,
			}),
		{ list: [] },
		[props.id]
	);

	return (
		<Fragment>
			<hr className="mb-2 mb-sm-5" />
			<div className="notificationList fs-4">
				{loading ? (
					<Loader />
				) : isEmpty(list) ? (
					<p className="fs-6 mx-0 mx-sm-3 mt-3">
						<FormattedMessage id={`${i18nKey}.error.empty`} />
					</p>
				) : (
					<Notifications list={list} />
				)}
			</div>
		</Fragment>
	);
}

export default wire(['services.notificationService'], NotificationsComponent);
