import TaskRepetitionState from 'models/task/repetitionState/TaskRepetitionState';

import FormResponse from 'models/common/form/FormResponse';

/**
 * @extends {TaskRepetitionState}
 */
export default class FormTaskRepetitionState extends TaskRepetitionState {
	/**
	 * @param {Object} repetitionState
	 */
	constructor(repetitionState) {
		super(repetitionState);

		/**
		 * @private
		 */
		this._formResponse = new FormResponse(repetitionState.form_response);
	}

	formResponse() {
		return this._formResponse;
	}
}
