import React from 'react';
import classnames from 'classnames';

import useStyles from './Modal.jss';

import Loader from 'components/commons/_/loader/Loader';

export const Modal = ({ close = () => {}, loading = false, children, config = {} }) => {
	const classes = useStyles(config);

	return (
		<div className={classes.modal}>
			<div className={classnames(classes.backdrop)}>
				<div
					className={classnames('rounded shadow', classes.content)}
					onClick={event => event.stopPropagation()}
				>
					<button type="button" className="btn btn-interactive btn-close top-right p-0" onClick={close} />
					{loading ? <Loader /> : <section className="rounded">{children()}</section>}
				</div>
			</div>
		</div>
	);
};
