import { useTabsContext } from './TabsContext';
import React, { Fragment, PropsWithChildren } from 'react';

export type TabPanelProps = {
	id?: string | number;
};

export const TabPanel = ({ id, children }: PropsWithChildren<TabPanelProps>) => {
	const { current } = useTabsContext();

	if (current === id) {
		return <Fragment>{children}</Fragment>;
	}

	return null;
};
