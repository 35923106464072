import React, { useEffect, useState } from 'react';
import { wire } from 'react-hot-wire';

import ErrorLayer from './ErrorLayer';
import InvalidTokenError from './InvalidTokenError';

export const Error = ({ children, ...props }) => {
	const [isRegister, register] = useState(false);
	const [error, setError] = useState(null);
	const [message, setMessage] = useState(null);
	const APIService = props['services.APIService'];
	const SetAgreements = props['components.RequiredAgreements'];

	useEffect(() => {
		register(true);

		return APIService.addErrorHandler(({ error, url }) => {
			setError({ error, url });
			setMessage(error ? error.messageId || error.message : 'unidentified');

			return Promise.reject(error);
		});
	}, []);

	if (!isRegister) {
		return null;
	}

	return message === 'agreements-required' ? (
		<SetAgreements />
	) : ['invalid-token', 'http-status.401'].includes(message) ? (
		<InvalidTokenError message={message} error={error} />
	) : error ? (
		<ErrorLayer message={message} error={error} />
	) : (
		children()
	);
};

export default wire(['services.APIService', 'components.RequiredAgreements'], Error);
