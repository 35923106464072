import QuestionTaskService from '../QuestionTaskService';

import GuessPhraseQuestionTask from 'models/task/question/questionTask/phrase/GuessPhraseQuestionTask';

export default class GuessPhraseQuestionTaskService extends QuestionTaskService {
	toModel(task) {
		return new GuessPhraseQuestionTask({
			...task,
			state: this._mapState(task),
		});
	}
}
