import React, { Fragment } from 'react';
import { Form, Field, FieldArray, ErrorMessage } from 'formik';
import { injectIntl, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

export default injectIntl(
	({ intl, agreements, values, status, errors, touched, isSubmitting, isValid, ReactMarkdown }) => (
		<div className="integration">
			<Form>
				<h2>
					<FormattedMessage id="auth.register.integration.email.title" tagName={React.Fragment} />
				</h2>
				<div
					className={classnames('field', {
						invalid: errors.email && touched.email,
					})}
				>
					<Field
						name="email"
						render={({ field }) => (
							<input
								{...field}
								type="text"
								autoComplete="username"
								placeholder={intl.formatMessage({
									id: 'auth.register.integration.email.field.email.placeholder',
								})}
							/>
						)}
					/>
					<ErrorMessage
						name="email"
						render={error => (
							<div className="error">
								<FormattedMessage id={`auth.form.error.${error}`} />
							</div>
						)}
					/>
				</div>
				{!isEmpty(agreements) && (
					<div className="agreements pb-3 pt-2">
						<FieldArray
							name="agreements"
							render={output => (
								<Fragment>
									{agreements.map(agreement => (
										<label
											key={agreement.id()}
											className="selectWrapper noMargin wrapper--row mb-2"
										>
											<div className="checkboxWrapper el--shrink">
												<input
													type="checkbox"
													name="agreements"
													value={agreement.id()}
													checked={values.agreements.includes(agreement.id())}
													onChange={event => {
														if (event.target.checked) {
															output.push(agreement.id());
														} else {
															output.remove(values.agreements.indexOf(agreement.id()));
														}
													}}
												/>
												<div className="checked" />
											</div>
											<div className="standardText standardText--noMargin standardText--leftAlign">
												<ReactMarkdown
													source={agreement.body()}
													className="inline"
													render={({ text }) => (
														<Fragment>
															{text}
															{agreement.required() ? '*' : ''}
														</Fragment>
													)}
												/>
											</div>
										</label>
									))}
								</Fragment>
							)}
						/>
						{status === 'agreements-error' && (
							<div className="error mb-0 pt-2">
								<FormattedMessage id="auth.form.error.agreements-required" />
							</div>
						)}
					</div>
				)}
				<button
					className={classnames('btn__basic btn__basic--fullWidth', {
						'btn__basic--disabled': !isValid,
						loading: isSubmitting,
					})}
					type="submit"
				>
					<FormattedMessage id="auth.register.integration.email.button" />
				</button>
			</Form>
		</div>
	)
);
