import React from 'react';
import { Form, Field, ErrorMessage } from 'formik';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

export default ({ intl, errors, touched }) => (
	<div className="integration">
		<Form>
			<h2>
				<FormattedMessage id="auth.reset-password.action.authorize-code.title.reset" tagName={React.Fragment} />
			</h2>
			<div
				className={classnames('field', {
					invalid: errors.authorizationCode && touched.authorizationCode,
				})}
			>
				<Field
					name="authorizationCode"
					render={({ field }) => (
						<input
							{...field}
							type="text"
							placeholder={intl.formatMessage({
								id: 'auth.reset-password.action.authorize-code.field.authorization-code.placeholder',
							})}
						/>
					)}
				/>
				<ErrorMessage
					name="authorizationCode"
					render={error => (
						<div className="error">
							<FormattedMessage id={`auth.form.error.${error}`} />
						</div>
					)}
				/>
			</div>
		</Form>
	</div>
);
