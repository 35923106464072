import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';

import UserProfileDetails from './Details.template';

class UserProfileDetailsComponent extends PureComponent {
	render() {
		return <UserProfileDetails {...this.props} />;
	}
}

export default wire(['services.userProfileService'], UserProfileDetailsComponent);
