import React, { PureComponent, Fragment } from 'react';

import { FormattedMessage } from 'react-intl';

export default class More extends PureComponent {
	state = {
		expanded: false,
	};

	render() {
		if (!this.state.expanded) {
			return (
				<a
					href="/"
					onClick={event => {
						event.preventDefault();
						this.setState({ expanded: true });
					}}
				>
					<FormattedMessage id="markdown.show-more" />
				</a>
			);
		}

		return (
			<Fragment>
				{' '}
				{this.props.text}
				<a
					href="/"
					onClick={event => {
						event.preventDefault();
						this.setState({ expanded: false });
					}}
				>
					<FormattedMessage id="markdown.show-less" />
				</a>
			</Fragment>
		);
	}
}
