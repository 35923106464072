import React, { useEffect } from 'react';
import { wire } from 'react-hot-wire';

import ErrorLayer from './ErrorLayer';

export const InvalidTokenError = props => {
	const accessTokenService = props['services.accessTokenService'];
	const navigationService = props['services.navigationService'];

	useEffect(() => {
		accessTokenService.clear();
		navigationService.load('/auth/login');
	}, []);

	return <ErrorLayer {...props} />;
};

export default wire(['services.accessTokenService', 'services.navigationService'], InvalidTokenError);
