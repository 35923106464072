import subServices from 'services/challenge/awardedPlace/subServices';

import ChallengeAwardedPlaceService from 'services/challenge/awardedPlace/ChallengeAwardedPlaceService';

export default {
	...subServices,
	'services.challengeAwardedPlaceService': {
		class: ChallengeAwardedPlaceService,
		deps: [
			{ $ref: 'services.APIService' },
			{ $ref: 'services.challengeSubmissionService' },
			{ $ref: 'services.currencyService' },
		],
		calls: [
			{
				name: 'registerSubService',
				args: ['submissionawardedplace', { $ref: 'services.challengeSubmissionAwardedPlaceService' }],
			},
			{
				name: 'registerSubService',
				args: ['teamawardedplace', { $ref: 'services.challengeTeamAwardedPlaceService' }],
			},
			{
				name: 'registerSubService',
				args: ['userawardedplace', { $ref: 'services.challengeUserAwardedPlaceService' }],
			},
			{
				name: 'registerSubService',
				args: ['usercontainerawardedplace', { $ref: 'services.challengeUserContainerAwardedPlaceService' }],
			},
		],
	},
};
