import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import map from 'lodash/map';
import each from 'lodash/each';
import { NavLink } from 'react-router-dom';

import images from 'assets/images';

import { SettingField } from 'components/_/form/setting-field/SettingField';
import { Markdown } from 'components/_/common/text/markdown/Markdown';

import { CustomUserProfileFieldDefinition } from 'models/user/userProfileField/definition/CustomUserProfileFieldDefinition';

const IsAnonymous = injectIntl(
	class IsAnonymous extends PureComponent {
		render() {
			const { intl } = this.props;

			return (
				<div className="isAnonymous wrapper--col align--center">
					<p className="description">
						<FormattedMessage id="task-details.join.is-anonymous.description" />
					</p>
					<div
						className="title"
						data-text={intl.formatMessage({
							id: 'task-details.join.is-anonymous.title',
						})}
					/>
					<p className="description">
						<FormattedMessage id="task-details.join.is-anonymous.click-button" />
					</p>
					<NavLink to="/auth/register" className="btn__basic">
						<FormattedMessage id="task-details.join.is-anonymous.button-text" />
					</NavLink>
				</div>
			);
		}
	}
);

function renderField({ intl }, field) {
	return {
		label:
			field.definition() instanceof CustomUserProfileFieldDefinition
				? field.definition().name()
				: intl.formatMessage({
						id: `user-profile.field.mapped.${field.definition().mappedProfileField()}`,
				  }),
		name: field.definition().id(),
		defaultValue: field.value(),
		editable: true,
		type: field.definition().valueType() === 'text' ? 'textarea' : 'input',
		valueType: field.definition().valueType(),
		mappedField:
			field.definition() instanceof CustomUserProfileFieldDefinition
				? null
				: field.definition().mappedProfileField(),
	};
}

export function UserProfileFieldsTask(props) {
	const { task, goBack } = props;
	const Layout = props['components.task.layout.BaseLayout'];
	const Avatar = props['components.Avatar'];
	const userProfileService = props['services.userProfileService'];
	const avatar = task.userProfileFields.avatar();
	const fields = userProfileService.isAnonymous()
		? []
		: task.userProfileFields.exclude(['image'], false).map(field => renderField(props, field));
	const initialValues = fields.reduce((result, field) => {
		result[field.name] = field.defaultValue || '';

		return result;
	}, {});
	const validationSchema = fields.reduce((result, field) => {
		result[field.name] =
			field.mappedField === 'email'
				? Yup.string()
						.email()
						.required()
				: Yup.string().required();

		return result;
	}, {});

	return (
		<Formik
			initialValues={
				avatar
					? {
							[avatar.definition().id()]: {
								image: avatar.value(),
								blob: null,
							},
							...initialValues,
					  }
					: initialValues
			}
			validationSchema={Yup.object().shape(
				avatar
					? {
							[avatar.definition().id()]: Yup.object().shape({
								image: Yup.string().required(),
								blob: Yup.string().nullable(),
							}),
							...validationSchema,
					  }
					: validationSchema
			)}
			onReset={() => task.reset()}
			validateOnMount={true}
			onSubmit={(values, actions) => {
				if (avatar) {
					const data = new FormData();
					let index = 0;

					each(values, (value, key) => {
						data.append(`fields[${index}][definition][id]`, key);

						if (avatar && key === avatar.definition().id()) {
							if (value.blob) {
								data.append(`fields[${index}][file][file]`, value.blob);
							}
							data.append(`fields[${index}][file][delete]`, '0');
						} else {
							data.append(`fields[${index}][value]`, value);
						}

						index++;
					});

					return props
						.process(data, false, {
							'Content-Type': 'multipart/form-data',
						})
						.then(() => userProfileService.fetchDetails())
						.then(() => actions.setSubmitting(false));
				}

				return props
					.process({
						fields: map(values, (value, key) => ({
							definition: { id: key },
							value,
						})),
					})
					.then(() => actions.setSubmitting(false));
			}}
		>
			{({ values, errors, handleReset, isSubmitting, setFieldValue, setFieldTouched, isValid }) => (
				<Form className="d-flex flex-grow-1">
					<Layout
						task={task}
						actions={{
							submit: {
								buttonText: 'task-details.common.button.confirm',
							},
							goBack,
							tryAgain: handleReset,
							isSubmitting,
							isValid,
						}}
						options={{
							hideCheckButton: userProfileService.isAnonymous(),
						}}
					>
						{userProfileService.isAnonymous() ? (
							<IsAnonymous />
						) : (
							<div className="text-center">
								{task.description() && <Markdown className="mb-4 fs-6">{task.description()}</Markdown>}
								<div className="fs-4 text-center">
									{props.intl.formatMessage({ id: 'task-details.user-profile-fields.instruction' })}
								</div>
								<div className="d-flex flex-column mt-6">
									{avatar && (
										<div className="d-flex justify-content-center mb-4">
											<Avatar
												name={avatar.definition().id()}
												currentImage={values[avatar.definition().id()].image}
												defaultImage={images.default.avatar.user}
												editMode={true}
												onChange={_avatar => setFieldValue(avatar.definition().id(), _avatar)}
											/>
										</div>
									)}
									<div className="row justify-content-center no-gutters w-100">
										<div className="col-24 col-md-14">
											{fields.map(field => (
												<div className="mb-2" key={field.name}>
													<SettingField
														{...field}
														inForm={true}
														onChange={value => {
															setFieldTouched(field.name);
															setFieldValue(field.name, value);
														}}
													/>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						)}
					</Layout>
				</Form>
			)}
		</Formik>
	);
}

export default wire(
	['components.task.layout.BaseLayout', 'components.Avatar', 'services.userProfileService'],
	injectIntl(UserProfileFieldsTask)
);
