import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';

export default function(Currencies) {
	return injectIntl(
		class FooterInfo extends PureComponent {
			render() {
				const { task, actions, intl } = this.props;

				return (
					<div className="footer__infoSection d-flex flex-wrap align-items-center w-100">
						{!task.isSubmitted() &&
						task.state().currentRepetitionState() &&
						!['awaiting', 'rejected'].includes(
							task
								.state()
								.currentRepetitionState()
								.acceptanceState()
						) ? (
							<NavLink
								to="/"
								onClick={event => actions.goBack(event)}
								className="exitButton btn__basic btn__basic--disabled"
							>
								<FormattedMessage id="task-details.common.button.leave" />
							</NavLink>
						) : null}
						{task.isFailed() || task.isCrashed() ? (
							<div className="wrapper--row align--center answerStatusWrapper">
								<div className="roundVisualStatus roundVisualStatus--incorrect--mini">
									<div className="roundVisualStatus__signWrapper" />
								</div>
								<div className="statusDescription" style={{ marginRight: '30px' }}>
									<h2 className="mb-0">
										{task.isCrashed()
											? intl.formatMessage({
													id: 'task-details.common.content.answer-status.crashed',
											  })
											: task.failureMessage() ||
											  intl.formatMessage({
													id: 'task-details.common.content.answer-status.wrong-answer',
											  })}
									</h2>
								</div>
							</div>
						) : null}
						{task.state().currentRepetitionState() &&
						task
							.state()
							.currentRepetitionState()
							.acceptanceState() === 'awaiting' ? (
							<div className="wrapper--row align--center answerStatusWrapper">
								<div className="roundVisualStatus roundVisualStatus--awaiting--mini">
									<div className="roundVisualStatus__signWrapper" />
								</div>
								<div className="statusDescription" style={{ marginRight: '30px' }}>
									<h2 className="mb-0 d-flex align-items-center">
										{task.acceptanceMessage() ||
											intl.formatMessage({
												id: 'task-details.common.content.answer-status.awaiting',
											})}
									</h2>
								</div>
							</div>
						) : null}
						{task.state().currentRepetitionState() &&
						task
							.state()
							.currentRepetitionState()
							.acceptanceState() === 'rejected' ? (
							<div className="wrapper--row align--center answerStatusWrapper">
								<div className="roundVisualStatus roundVisualStatus--incorrect--mini">
									<div className="roundVisualStatus__signWrapper" />
								</div>
								<div className="statusDescription" style={{ marginRight: '30px' }}>
									<h2 className="mb-0">
										{task
											.state()
											.currentRepetitionState()
											.rejectionReason()
											? intl.formatMessage(
													{
														id: 'task-details.common.content.answer-status.rejected-reason',
													},
													{
														reason: task
															.state()
															.currentRepetitionState()
															.rejectionReason(),
													}
											  )
											: intl.formatMessage({
													id: 'task-details.common.content.answer-status.rejected',
											  })}
									</h2>
								</div>
							</div>
						) : null}
						{task.isCompleted() ? (
							<div className="wrapper--row align--center answerStatusWrapper answerStatusWrapper__correct">
								<div className="roundVisualStatus roundVisualStatus--correct">
									<div className="roundVisualStatus__signWrapper" />
								</div>
								<div className="wrapper--col statusDescription w-100" style={{ marginRight: '30px' }}>
									<h2 className="mb-0">
										{task.successMessage() ||
											intl.formatMessage({
												id: 'task-details.common.content.answer-status.correct-answer',
											})}
									</h2>
									{task.state().hasLastEarnedPoints() && (
										<div className="wrapper--row">
											<p>
												<FormattedMessage id="task-details.common.content.answer-status.gaining" />
											</p>
											<Currencies
												parentClasses="wrapper--row align--center"
												currencies={task.state().lastEarnedPoints()}
											/>
										</div>
									)}
								</div>
							</div>
						) : null}
					</div>
				);
			}
		}
	);
}
