import Team from 'models/team/Team';
import Roles from 'models/user/Roles';

/**
 * Class representing a user membership.
 */
export default class Membership {
	/**
	 * @param {Object} membership
	 */
	constructor(membership) {
		/**
		 * @type {TeamProfile}
		 * @private
		 */
		this._team = new Team(membership.team);
		/**
		 * @type {Roles}
		 * @private
		 */
		this._roles = membership.roles && new Roles(...membership.roles);
	}

	/**
	 * @returns {TeamProfile}
	 */
	team() {
		return this._team;
	}

	/**
	 * @returns {string[]}
	 */
	roles() {
		return this._roles;
	}
}
