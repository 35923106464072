import React, { PureComponent } from 'react';

export default function() {
	return class HeaderBlock extends PureComponent {
		render() {
			const { block } = this.props;
			const level = block.level();
			const Level = `h${level}`;

			return <Level className="block--header markdown mb-0">{block.header()}</Level>;
		}
	};
}
