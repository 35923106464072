import ContentBlock from 'models/common/content/block/ContentBlock';

export default class ImageContentBlock extends ContentBlock {
	constructor(block) {
		super(block);

		this._image = block.image;
	}

	image() {
		return this._image;
	}
}
