import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorMessage as FormikErrorMessage } from 'formik';
import { createUseStyles } from 'react-jss';

type ErrorMessageProps = {
	name: string;
	extract?: (error: string) => string;
	inForm?: boolean;
};

const useStyles = createUseStyles({
	error: {
		color: 'red',
		textAlign: 'left',
		paddingTop: '5px',
		paddingLeft: '10px',
	},
});

export const ErrorMessage: FC<ErrorMessageProps> = ({ extract, inForm = true, ...props }) => {
	const classes = useStyles();
	let Message: string | React.ComponentType<ErrorMessageProps> = 'div';

	if (inForm) {
		Message = FormikErrorMessage;
	}

	return (
		<Message {...props}>
			{(error: any) => {
				return (
					<FormattedMessage
						id={`form.error.${(extract ? extract(error) : error).message}`}
						values={error.values}
					>
						{message => <div className={classes.error}>{message}</div>}
					</FormattedMessage>
				);
			}}
		</Message>
	);
};
