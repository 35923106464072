import React from 'react';
import { Field } from 'formik';

import useStyles from 'components/task/types/form/formBlock/question/Question.jss';

import { Input } from 'components/_/form/input/Input';
import { ErrorMessage } from 'components/_/form/error/Error';

export const EmailOpenQuestion = ({ formBlock, disabled }) => {
	const classes = useStyles();

	return (
		<div className="fs-4">
			<p className={classes.question}>{formBlock.body()}</p>
			<div className="w-100 w-sm-50">
				<Field name={formBlock.id()}>{({ field }) => <Input {...field} rounded disabled={disabled} />}</Field>
			</div>
			<ErrorMessage name={formBlock.id()} />
		</div>
	);
};
