import React, { Fragment, useState } from 'react';
import { injectIntl } from 'react-intl';
import debounce from 'lodash/debounce';
import Media from 'react-media';
import classnames from 'classnames';

const Search = injectIntl(({ intl, search, setSearch, className, inputProps, ...props }) => {
	const onChange = debounce(setSearch, 200);

	return (
		<form
			className={classnames('form d-flex align-items-center', className)}
			onSubmit={event => event.preventDefault()}
			{...props}
		>
			<label className="search">
				<input
					type="text"
					placeholder={intl.formatMessage({ id: 'common.view.list.search.placeholder' })}
					defaultValue={search}
					autoFocus
					onChange={event => onChange(event.target.value)}
					{...inputProps}
				/>
			</label>
		</form>
	);
});

function MobileSearch({ search, setSearch, onVisibilityChange }) {
	const [visible, setVisible] = useState(false);

	return visible ? (
		<Search
			search={search}
			setSearch={setSearch}
			inputProps={{
				onBlur: () => {
					setVisible(false);
					onVisibilityChange(false);
				},
			}}
			className="w-100"
		/>
	) : (
		<button
			onClick={() => {
				setVisible(true);
				onVisibilityChange(true);
			}}
			className="btn btn-icon btn-icon-interactive"
		>
			<i className="fa fa-search" />
		</button>
	);
}

export const ViewSearch = ({ search, setSearch, onVisibilityChange }) => {
	return (
		<Media
			queries={{
				mobile: '(max-width: 576px)',
				others: '(min-width: 576px)',
			}}
			render={({ others, mobile }) => (
				<Fragment>
					{mobile && (
						<MobileSearch search={search} setSearch={setSearch} onVisibilityChange={onVisibilityChange} />
					)}
					{others && <Search search={search} setSearch={setSearch} style={{ width: '300px' }} />}
				</Fragment>
			)}
		/>
	);
};
