import ReactDOM from 'react-dom';
import SVGInjector from 'svg-injector';
import propTypes from 'prop-types';
import React, { Component } from 'react';

export default class SVGImage extends Component {
	state = {
		image: undefined,
	};

	_recognizeFileType({ src, injectAsSVGElement, ...rest }) {
		if (!src) {
			return;
		}

		const ext = src.split('.').pop();

		if (this.constructor.ALLOWED_IMAGE_EXTENSION.indexOf(ext) >= 0 && injectAsSVGElement) {
			this.setState({
				image: <image xlinkHref={src} {...rest} />,
			});
		} else if (ext === 'svg') {
			this._SVGInjectorInstance = new SVGInjector(ReactDOM.findDOMNode(this).parentElement);
			this._SVGInjectorInstance.launch();
		}
	}

	componentDidMount() {
		this._recognizeFileType(this.props);
	}

	render() {
		const { src, ...rest } = this.props;
		const { image } = this.state;

		return image ? image : <img src={src} alt="" {...rest} />;
	}

	static ALLOWED_IMAGE_EXTENSION = ['png', 'jpg', 'gif'];

	static propTypes = {
		src: propTypes.string,
	};
}
