import { createUseStyles } from 'react-jss';
import chroma from 'chroma-js';

/**
 * @TODO:
 *   2. remove styles from _buttons.scss
 */
export default ({ variant }) =>
	createUseStyles(theme => {
		const textSize = 1;
		const borderSize = 0.05; // 1 / 20
		const verticalPaddings = 0.4;
		const horizontalPaddings = 1;
		const size = textSize + verticalPaddings * 2 + borderSize * 2; // text + (py * 2) + (border * 2)

		function gradient({ color }) {
			if (variant === 'flat') {
				return {};
			}

			return {
				'&::after': {
					content: '""',
					position: 'absolute',
					zIndex: 100,
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					borderRadius: `${size}em`,
					background: `linear-gradient(to bottom, ${color} 0%, ${chroma(color).darken(0.9)} 100%)`,
				},

				'&:hover:not([disabled])': {
					backgroundColor: `${chroma(color).brighten(0.5)}`,
				},
			};
		}

		return {
			button: {
				position: 'relative',
				cursor: 'pointer',
				border: 0,
				fontSize: `${textSize}em`,
				padding: `${verticalPaddings}em ${horizontalPaddings}em`,
				height: `${size}em`,
				borderRadius: `${size}em`,
				overflow: 'hidden',
				flexShrink: 0,
				zIndex: 300,

				'&:focus': {
					outline: 0,
				},

				'&:hover:not([disabled]):not($disabled)': {
					'&::after': {
						top: '0.1em',
					},
				},

				'&[disabled]': {
					cursor: 'not-allowed',
				},

				'& > span': {
					position: 'relative',
					zIndex: 200,
					whiteSpace: 'nowrap',
				},
			},
			primary: {
				color: theme.color.system['color--primary--foreground'],
				backgroundColor: theme.color.system['color--primary--background'],

				...gradient({
					color: theme.color.system['color--primary--background'],
				}),
			},
			interactive: {
				color: theme.color.system['color--interactive--foreground'],
				backgroundColor: theme.color.system['color--interactive--background'],

				...gradient({
					color: theme.color.system['color--interactive--background'],
				}),
			},
			danger: {
				color: '#FFF',
				backgroundColor: '#c91212',

				...gradient({
					color: '#c91212',
				}),
			},
			warning: {
				color: '#FFF',
				backgroundColor: '#ffc700',

				...gradient({
					color: '#ffc700',
				}),
			},
			disabled: {
				color: theme.color.system['color--interactive--foreground'],
				backgroundColor: theme.color.system['color--content--foreground-15'],
				cursor: 'not-allowed',

				...gradient({
					color: '#a1a1a1',
				}),
			},
			loading: {
				transition: 'all 0.2s ease-in',
				'&::before': {
					fontFamily: 'FontAwesome',
					position: 'absolute',
					content: "'\\f110'",
					top: 'calc(50% - 0.5em)',
					right: '10px',
					animation: 'rotate 1s infinite ease-in',
					fontSize: '1em',
				},
				'& > span': {
					paddingRight: '15px',
				},
			},
		};
	});
