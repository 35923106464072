import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

export default function() {
	return injectIntl(
		class IsAnonymous extends PureComponent {
			render() {
				const { intl } = this.props;

				return (
					<div className="wrapper--col align--center">
						<p className="description">
							<FormattedMessage id="task-details.join.is-anonymous.description" />
						</p>
						<div
							className="title"
							data-text={intl.formatMessage({
								id: 'task-details.join.is-anonymous.title',
							})}
						/>
						<p className="description">
							<FormattedMessage id="task-details.join.is-anonymous.click-button" />
						</p>
						<NavLink to="/auth/register" className="btn__basic">
							<FormattedMessage id="task-details.join.is-anonymous.button-text" />
						</NavLink>
					</div>
				);
			}
		}
	);
}
