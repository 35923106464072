import Question from 'models/task/question/Question';

/**
 * Class representing a order question.
 * @extends Question
 */
export default class OrderQuestion extends Question {
	/**
	 * @param {Object} question
	 */
	constructor(question = {}) {
		super(question);

		/**
		 * @type {TextAnswer[]}
		 */
		this._answers = (question.answers || []);
	}

	/**
	 * @returns {TextAnswer[]}
	 */
	answers() {
		return this._answers;
	}
}
