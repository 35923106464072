import React from 'react';
import { wire } from 'react-hot-wire';
import { FormattedMessage } from 'react-intl';

import { Modal } from 'components/_/common/modal/Modal';
import useFetch from 'components/_/hooks/useFetch';

import { BuyModalForm } from 'components/_/app/store/buy-item/BuyModal.form';
import { Button } from 'components/_/common/button/Button';

export const BuyModal = ({ item, close, reload, ...props }) => {
	const rewardCafeteriaService = props['services.rewardCafeteriaService'];
	const storeItemService = props['services.storeItemService'];
	const userProfileService = props['services.userProfileService'];
	const { userProfile } = userProfileService.user();
	const {
		response: { addresses },
		loading,
	} = useFetch(() => rewardCafeteriaService.addresses({ type: item.deliveryType }), { addresses: [] });

	function _close() {
		close();
		reload();
	}

	function onSubmit(values, actions) {
		return storeItemService
			.buyItem(item.currentAvailabilityWithStock().id, values)
			.then(() => {
				actions.setStatus({ message: 'bought' });
				userProfileService.addToWalletQueue(item.currentAvailabilityWithStock().cost());
			})
			.catch(error => actions.setStatus({ error: error.messageId }));
	}

	return (
		<Modal close={_close} loading={loading}>
			{() => (
				<BuyModalForm
					item={item}
					addresses={addresses}
					userProfile={userProfile}
					onSubmit={onSubmit}
					success={
						<div>
							<h3 className="mb-4">
								<FormattedMessage id="prize-details.content.buy-item.ordered" />
							</h3>
							<Button onClick={_close} className="mr-3">
								<FormattedMessage id="prize-details.content.buy-item.close" />
							</Button>
						</div>
					}
				/>
			)}
		</Modal>
	);
};

export default wire(
	['services.storeItemService', 'services.rewardCafeteriaService', 'services.userProfileService'],
	BuyModal
);
