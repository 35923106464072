import React from 'react';
import classnames from 'classnames';

export default ({
	answer,
	component,
	component: {
		props: { task },
	},
}) => (
	<li
		className={classnames('answer answer__textType wrapper--row', {
			correct: task.isCompleted(),
			incorrect: task.isFailed(),
		})}
	>
		<label className="wrapper--row el--grow">
			<input
				type="checkbox"
				checked={answer.isChecked()}
				className="wrapper--row el--grow technicalInput"
				name="answer"
				onClick={() => component.click(answer)}
			/>
			<div className="answer__selectArea wrapper--row align--center justify--center el--shrink">
				<div className="iterator">{answer.index()}</div>
				<div className="customInputWrapper wrapper--row align--center">
					<div className="customInput">
						<div className="checked" />
					</div>
				</div>
			</div>
			<div className="answer__contentArea wrapper--row el--grow align--middle">
				<div className="standardText standardText--noMargin wrapper--row align--center">{answer.body()}</div>
			</div>
		</label>
	</li>
);
