import _ from 'lodash';

import TaskBaseService from 'services/task/TaskBaseService';
import ContentTask from 'models/task/content/ContentTask';
import Content from 'models/common/content/Content';

export default class ContentTaskService extends TaskBaseService {
	constructor(taskStateService, contentBlocksService) {
		super(taskStateService);

		this._contentBlocksService = contentBlocksService;
	}

	toModel(task) {
		return new ContentTask({
			...task,
			content: new Content({
				...task.content,
				blocks:
					task.content &&
					_.filter(
						_.map(task.content.blocks, block => this._contentBlocksService.toModel(block)),
						block => block
					),
			}),
			state: this._mapState(task),
		});
	}
}
