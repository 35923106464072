import InternalDynamicAnchorAchievementService from 'services/application/anchor/subServices/internalDynamic/subServices/InternalDynamicAnchorAchievementService';
import InternalDynamicAnchorChallengeService from 'services/application/anchor/subServices/internalDynamic/subServices/InternalDynamicAnchorChallengeService';
import InternalDynamicAnchorMapService from 'services/application/anchor/subServices/internalDynamic/subServices/InternalDynamicAnchorMapService';
import InternalDynamicAnchorMissionService from 'services/application/anchor/subServices/internalDynamic/subServices/InternalDynamicAnchorMissionService';
import InternalDynamicAnchorNewsService from 'services/application/anchor/subServices/internalDynamic/subServices/InternalDynamicAnchorNewsService';
import InternalDynamicAnchorPrizeService from 'services/application/anchor/subServices/internalDynamic/subServices/InternalDynamicAnchorPrizeService';
import InternalDynamicAnchorRankingService from 'services/application/anchor/subServices/internalDynamic/subServices/InternalDynamicAnchorRankingService';
import InternalDynamicAnchorTaskService from 'services/application/anchor/subServices/internalDynamic/subServices/InternalDynamicAnchorTaskService';

export default {
	'services.internalDynamicAnchorAchievementService': {
		class: InternalDynamicAnchorAchievementService,
		deps: [{ $ref: 'services.achievementService' }],
	},
	'services.internalDynamicAnchorChallengeService': {
		class: InternalDynamicAnchorChallengeService,
		deps: [{ $ref: 'services.challengeService' }],
	},
	'services.internalDynamicAnchorMapService': {
		class: InternalDynamicAnchorMapService,
		deps: [{ $ref: 'services.taskService' }],
	},
	'services.internalDynamicAnchorMissionService': {
		class: InternalDynamicAnchorMissionService,
		deps: [{ $ref: 'services.taskService' }],
	},
	'services.internalDynamicAnchorNewsService': {
		class: InternalDynamicAnchorNewsService,
		deps: [{ $ref: 'services.newsService' }],
	},
	'services.internalDynamicAnchorPrizeService': {
		class: InternalDynamicAnchorPrizeService,
		deps: [{ $ref: 'services.storeItemService' }],
	},
	'services.internalDynamicAnchorRankingService': {
		class: InternalDynamicAnchorRankingService,
	},
	'services.internalDynamicAnchorTaskService': {
		class: InternalDynamicAnchorTaskService,
		deps: [{ $ref: 'services.taskService' }],
	},
};
