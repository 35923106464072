import Challenge from 'models/challenge/challenge/Challenge';
import moment from 'moment';

/**
 * Class representing a KPI indexed challenge.
 * @extends Challenge
 */
export default class KPIIndexedChallenge extends Challenge {
	/**
	 * @param {Object} challenge
	 */
	constructor(challenge) {
		super(challenge);

		/**
		 * @type {string}
		 */
		this.challengeType = 'kpi';

		this.lastImportDate = this._lastImportDate(challenge.last_import_date);
	}

	icon() {
		return 'defaultIcon';
	}

	_lastImportDate(date) {
		return date && moment(date).format('DD.MM.YYYY, HH:mm');
	}
}
