import React, { PureComponent } from 'react';
import { Form, Formik } from 'formik';

import { Markdown } from 'components/_/common/text/markdown/Markdown';

export default function(modes, Layout, AnswerMode, MatchingImageType) {
	return class TrueFalseTask extends PureComponent {
		render() {
			const { task, goBack } = this.props;
			const question = task.question();

			return (
				<Formik
					initialValues={{}}
					onReset={() => this.props.task.reset()}
					onSubmit={(values, actions) => this._submit().then(() => actions.setSubmitting(false))}
				>
					{({ handleReset, isSubmitting }) => (
						<Form className="form d-flex flex-grow-1">
							<Layout
								task={task}
								actions={{
									submit: {
										buttonText: 'task-details.common.button.confirm',
									},
									goBack,
									tryAgain: handleReset,
									isSubmitting,
								}}
							>
								<div className="wrapper--row el--grow standardPreview__columnWrapper overflow--auto noInstructionQuestion">
									<div className="wrapper--col standardPreview__column standardPreview__column--left el--shrink">
										<div className="wrapper--col el--grow">
											<div className="fs-6 standardText questionBody el--shrink">
												<Markdown>{question.body()}</Markdown>
											</div>
											{question.image() && (
												<MatchingImageType
													classes="el--grow rounded el--shrink"
													src={question.image()}
												/>
											)}
											{question.videoUrl() && (
												<iframe
													width="100%"
													height="350px"
													src={question.videoUrl()}
													frameBorder="0"
													title="videoUrl"
													allowFullScreen
												/>
											)}
										</div>
									</div>
									<div className="standardPreview__column standardPreview__column--middle wrapper--row justify--center">
										<div className="wrapper--col el--grow">
											<div className="wrapper--row el--grow standardPreview__columnWrapper justify--center">
												<div className="wrapper--col el--grow">
													<div className="view__trueFalse">
														{question.answers().map(answer => (
															<AnswerMode
																answer={answer}
																key={answer.id()}
																Mode={modes[answer.mode()]}
																click={value => this._click(answer, value)}
															/>
														))}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Layout>
						</Form>
					)}
				</Formik>
			);
		}

		/**
		 * @param {AnswerMode} answer
		 * @param {boolean} value
		 * @private
		 */
		_click(answer, value) {
			this.props.task.setAnswerValue(answer, value);
			this.forceUpdate();
		}

		_submit() {
			return this.props.process({ answers: this.props.task.question().values() });
		}
	};
}
