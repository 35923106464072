import _ from 'lodash';

/**
 * Class representing a store item.
 * @abstract
 */
export default class StoreItem {
	/**
	 * @param {Object} storeItem
	 */
	constructor(storeItem) {
		/**
		 * @type {string}
		 */
		this.id = storeItem.id;
		/**
		 * @type {string}
		 */
		this.name = storeItem.name;
		/**
		 * @type {string}
		 */
		this.description = storeItem.description;
		/**
		 * @type {Availability[]}
		 */
		this.availabilities = storeItem.availabilities;

		this.deliveryType = storeItem.delivery_type;

		/**
		 * @type {Tag[]}
		 * @private
		 */
		this._tags = storeItem.tags;
		/**
		 *
		 * @type {ItemAsset[]}
		 * @private
		 */
		this._assets = storeItem.assets;
	}

	currentAvailabilityWithStock() {
		return _(this.availabilities)
			.filter(availability => availability.currentRemainingQuantity() > 0 && availability.isNow())
			.sortBy(availability => availability.from().toDate())
			.head();
	}

	nearestAvailabilityWithStock() {
		return _(this.availabilities)
			.filter(
				availability =>
					availability.currentRemainingQuantity() > 0 || availability.futureRemainingQuantity() > 0
			)
			.sortBy(availability => availability.from().toDate())
			.head();
	}

	nearestAvailability() {
		return _(this.availabilities)
			.sortBy(availability => availability.from().toDate())
			.head();
	}

	availabilitiesOnDay(date) {
		return _(this.availabilities)
			.filter(availability => availability.isAvailableOnDate(date))
			.value();
	}

	isAvailableOnDate(date) {
		return _(this.availabilities).some(availability => availability.isAvailableOnDate(date));
	}

	currentQuantities() {
		return _.reduce(
			this.availabilities,
			(quantity, availability) => ({
				remaining: quantity.remaining + availability.currentRemainingQuantity(),
				start: quantity.start + availability.currentStartQuantity(),
			}),
			{ remaining: 0, start: 0 }
		);
	}

	currentQuantitiesRatio() {
		let quantity = this.currentQuantities();

		return quantity.start ? quantity.remaining / quantity.start : 0;
	}

	imageAssets() {
		return _.filter(this._assets, { type: StoreItem.IMAGE_ITEM_ASSET });
	}

	image() {
		const imageAsset = _.find(this._assets, asset => asset.type === StoreItem.IMAGE_ITEM_ASSET);

		return imageAsset && imageAsset.image;
	}

	static IMAGE_ITEM_ASSET = 'image';
}
