export interface EmployeePreviewDTO {
	id: string;
	forename?: string | null;
	surname?: string | null;
	allows_batch_messaging?: boolean;
	avatar?: string | null;
}

export default class EmployeePreview {
	private _id: string;
	private _avatar?: string | null;
	private _forename: string;
	private _surname: string;
	private _allowsBatchMessaging?: boolean;

	constructor(employee: EmployeePreviewDTO) {
		this._id = employee.id;
		this._avatar = employee.avatar || '/image/I/c/1sI-drmcrA.jpg';
		this._forename = employee.forename || '';
		this._surname = employee.surname || '';
		this._allowsBatchMessaging = employee.allows_batch_messaging;
	}

	get userId() {
		return this._id;
	}

	get avatar() {
		return this._avatar;
	}

	get forename() {
		return this._forename;
	}

	get surname() {
		return this._surname;
	}

	get fullName() {
		return `${this._forename} ${this._surname}`;
	}
}
