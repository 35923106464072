export default class ModalService {
	/**
	 * @type {Object<string,function[]>}
	 * @private
	 */
	_changeListeners = {};

	/**
	 *
	 * @param {string} modal
	 * @param {function} changeListener
	 * @returns {function(): void}
	 */
	addChangeListener(modal, changeListener) {
		if (!this._changeListeners[modal]) {
			this._changeListeners[modal] = [];
		}

		this._changeListeners[modal].push(changeListener);

		return () => this._unregisterListener(modal, changeListener);
	}

	runChangeListeners(modal) {
		this._changeListeners[modal].forEach(listener => listener());
	}

	/**
	 * @param {string} modal
	 * @param {function} changeListener
	 * @private
	 */
	_unregisterListener(modal, changeListener) {
		this._changeListeners[modal] = this._changeListeners[modal].filter(listener => listener !== changeListener);
	}
}
