import { map, snakeCase, transform } from 'lodash';

import BaseService from 'services/BaseService';
import Availability from 'models/store/Availability';
import StoreItem from 'models/store/item/StoreItem';

export default class StoreItemService extends BaseService {
	_type = 'storeItem';
	_typeKey = 'type';

	constructor(APIService, tagService, currencyService, itemAssetService) {
		super();
		this.APIService = APIService;
		this._tagService = tagService;
		this._currencyService = currencyService;
		this._itemAssetService = itemAssetService;
	}

	/**
	 *
	 * @param {number} offset
	 * @param {number} limit
	 * @param {string} tag
	 * @returns {Promise}
	 */
	fetchItemList({ offset = 0, limit = 100, tag } = {}) {
		const url = 'v1/store/items';

		return this.APIService.get(tag ? `${url}/${tag}` : url, {
			offset,
			limit,
		}).then(({ list, has_next_page }) => ({
			list: list.map(item => this.toModel(item)),
			has_next_page,
		}));
	}

	fetchAddressList() {
		return this.APIService.get('v1/store/address');
	}

	toModel({ availabilities, tags, assets, ...rest }) {
		return new StoreItem({
			availabilities: map(
				availabilities,
				availability =>
					new Availability({
						...availability,
						price: this._currencyService.toModel(availability.price),
						cost: this._currencyService.toModel(availability.price, {
							reverse: true,
						}),
					})
			),
			tags: map(tags, tag => this._tagService.toModel(tag)),
			assets: map(assets, asset => this._itemAssetService.toModel(asset)),
			...rest,
		});
	}

	/**
	 *
	 * @param {string|number} id
	 * @returns {Promise}
	 */
	fetchItemDetails({ id } = {}) {
		return this.APIService.get(`v1/store/item/${id}`).then(item => this.toModel(item));
	}

	/**
	 * @returns {Promise}
	 */
	fetchTagList() {
		return this.APIService.get('v1/store/tagList').then(tags => tags.map(tag => this._tagService.toModel(tag)));
	}

	buyItemFromAvailability(availabilityId) {
		return this.APIService.post(`v1/store/buyItemFromAvailability/${availabilityId}`, null, undefined, {
			omitErrorHandlers: true,
		});
	}

	buyItem(availabilityId, values = {}) {
		const data = {};

		if (values.addressSelect === 'create') {
			data.new_address_dto = transform(values.address, (result, value, key) => {
				result[snakeCase(key)] = value;
			});
		} else {
			data.address = { id: values.addressSelect.id };
		}

		return this.APIService.post(`v1/store/buyItemFromAvailability/${availabilityId}`, data, undefined, {
			omitErrorHandlers: true,
		});
	}
}
