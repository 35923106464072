import SingleColorScheme from 'models/application/config/colorScheme/SingleColorScheme';

export default class ColorScheme {
	constructor(scheme) {
		this._content = new SingleColorScheme(scheme.content);
		this._interactive = new SingleColorScheme(scheme.interactive);
		this._primary = new SingleColorScheme(scheme.primary);
		this._isMenuTransparent = scheme.is_menu_transparent;
	}

	get(name) {
		return this[`_${name}`];
	}

	isMenuTransparent() {
		return this._isMenuTransparent;
	}
}
