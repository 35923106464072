import External from 'components/task/types/visitLink/External';
import Internal from 'components/task/types/visitLink/Internal';
import VisitLink from 'components/task/types/visitLink/VisitLink';

export default {
	'components.VisitLinkTask.External': {
		factory: External,
		deps: [{ $ref: 'services.taskService' }, { $ref: 'components.task.layout.BaseLayout' }],
	},
	'components.VisitLinkTask.Internal': {
		factory: Internal,
		deps: [{ $ref: 'services.taskService' }, { $ref: 'components.task.layout.BaseLayout' }],
	},
	'components.VisitLinkTask': {
		factory: VisitLink,
		deps: [{ $ref: 'components.VisitLinkTask.External' }, { $ref: 'components.VisitLinkTask.Internal' }],
	},
};
