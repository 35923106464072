import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';

import Mission from 'models/task/Mission';
import SVGImage from 'components/commons/SVGImage';

export default function(taskService) {
	return class WrapperMissionList extends PureComponent {
		state = {
			scrolling: false,
			direction: null,
		};

		componentDidUpdate() {
			if (!this.state.scrolling || !this.$_container) {
				return;
			}

			this.__cachedScrollAnimation = window.requestAnimationFrame(() => this._scrollDirection());
		}

		render() {
			const list = taskService.suggestedList();

			return (
				<section className="missionListWrapper wrapper--col el--shrink el--grow">
					<div className="wrapper--col wrapper--col--stretch el--grow">
						<div className="missionListWrapper__upArrowWrapper">
							<div
								className="arrow--up"
								onMouseEnter={() => this._initiateScrolling('up')}
								onMouseLeave={() => this._stopScrolling()}
							/>
						</div>
						<div className="missionListWrapper__listContainer el--grow">
							<div
								className="overflow--auto"
								ref={container => {
									this.$_container = container;
								}}
							>
								<ul className="wrapper--col align--center">
									{list.map(
										task =>
											task && (
												<li key={task.id()} className={`task task--${task.state().status()}`}>
													<NavLink
														to={`/${
															task instanceof Mission ? 'mission' : 'task'
														}/${task.id()}/details`}
														className="wrapper--col align--center justify--center tileWrapper"
														activeClassName="active"
													>
														{task.icon() ? (
															<SVGImage
																src={task.icon()}
																className="missionBox missionBox__image"
															/>
														) : (
															<div className="missionBox missionBox__emptyImage">
																{task.name()[0]}
															</div>
														)}
														{task instanceof Mission && task.state().hasUnseenTasks() && (
															<div className="roundNumber roundNumber--topRight">
																<div className="number">
																	{task.state().counts().unseen}
																</div>
															</div>
														)}
														{task instanceof Mission && (
															<div className="progressBar">
																<div
																	className="progressBar__content"
																	style={{
																		width: `${Math.round(
																			task.state().progress() * 100
																		)}%`,
																	}}
																/>
															</div>
														)}
														<div className="missionBox__name">{task.name()}</div>
														<div className="taskVisualStatus taskVisualStatus--topRight" />
													</NavLink>
												</li>
											)
									)}
								</ul>
							</div>
						</div>
						<div className="missionListWrapper__bottomArrowWrapper">
							<div
								className="arrow--bottom"
								onMouseOver={() => this._initiateScrolling('down')}
								onMouseLeave={() => this._stopScrolling()}
							/>
						</div>
					</div>
				</section>
			);
		}

		/**
		 * @param {string} direction
		 */
		_initiateScrolling(direction) {
			this._setScrollState({
				scrolling: true,
				direction: direction,
			});
		}

		_stopScrolling() {
			this._setScrollState({
				scrolling: false,
				direction: null,
			});

			window.cancelAnimationFrame(this.__cachedScrollAnimation);
		}

		/**
		 * @param {boolean} scrolling
		 * @param {string} direction
		 * @private
		 */
		_setScrollState({ scrolling, direction }) {
			this.setState({
				scrolling,
				direction,
			});
		}

		/**
		 * @private
		 */
		_scrollDirection() {
			this.$_container.scrollTop +=
				this.state.direction === 'down'
					? WrapperMissionList.SCROLLING_STEP
					: -WrapperMissionList.SCROLLING_STEP;

			this.__cachedScrollAnimation = window.requestAnimationFrame(() => this._scrollDirection());
		}

		/**
		 * @type {number}
		 */
		static SCROLLING_STEP = 8;
	};
}
