/**
 * Class representing a tag.
 * @abstract
 */
export default class Tag {
	/**
	 * @param {Object} tag
	 */
	constructor(tag = {}) {
		/**
		 * @type {string}
		 */
		this.id = tag.id;
		/**
		 * @type {string}
		 */
		this.name = tag.name;
		/**
		 * @type {string}
		 */
		this.description = tag.description;
		/**
		 * @type {string}
		 */
		this.tagType = tag.tag_type;
	}
}
