import ItemNotification from 'models/notification/ItemNotification';

import NotificationBaseService from 'services/notification/NotificationBaseService';

import images from 'assets/images';

export default class ItemNotificationService extends NotificationBaseService {
	constructor(itemService, currencyService, rewardCafeteriaService) {
		super();
		this._itemService = itemService;
		this._currencyService = currencyService;
		this._rewardCafeteriaService = rewardCafeteriaService;
	}

	toModel(notification) {
		return new ItemNotification(
			{
				...notification,
				backpack_item: notification.backpack_item && {
					...notification.backpack_item,
					item: notification.backpack_item.item && this._itemService.toModel(notification.backpack_item.item),
					boughtRewardCafeteriaItem:
						notification.backpack_item.bought_reward_cafeteria_item &&
						this._rewardCafeteriaService.toModel(notification.backpack_item.bought_reward_cafeteria_item),
				},
				costs: this._currencyService.toModel(notification.costs, {
					reverse: true,
				}),
			},
			images
		);
	}
}
