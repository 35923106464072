import React, { Fragment, useMemo, useState } from 'react';
import moment from 'moment';
import { ProgressBar } from 'components/_/common/progress-bar/ProgressBar';
import Process from 'models/onboarding/process/Process';
import { FormattedHTMLMessage } from 'react-intl';
import images from 'assets/images';
import useCollapse from 'react-collapsed';
import classnames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import { workflowCategory } from 'i18n/util/workflowCategory';
import { useGetProcessListData } from '../hook/computed/useGetProcessListData';

export type ProcessListProps = {
	processList: Process[];
	expandable?: boolean;
	showUserData?: boolean;
	className?: string;
	overdueTranslation?: string;
};

const RenderWithLink = ({ withLink, ...props }: LinkProps & { withLink?: boolean }) => {
	return withLink ? <Link {...props} /> : <div className={props.className}>{props.children}</div>;
};

export const ProcessList = ({
	processList,
	expandable,
	showUserData,
	className,
	overdueTranslation = 'portal.process.title.overdue',
}: ProcessListProps) => {
	const [isFullyCollapsed, setIsFullyCollapsed] = useState(true);
	const { getCollapseProps, setExpanded, isExpanded } = useCollapse({
		defaultExpanded: false,
		onCollapseEnd: () => setIsFullyCollapsed(true),
		onExpandStart: () => setIsFullyCollapsed(false),
	});
	const ExpandIcon = useMemo(() => images.portal[isExpanded ? 'expandLess' : 'expandMore'], [isExpanded]);
	const {
		daysToProcessEnd,
		allActionsDone,
		allActionsCount,
		isDelayed,
		progress,
		roles,
		canExpand,
		shouldShowProcessDate,
		shouldShowProcessDuration,
	} = useGetProcessListData({ processList, expandable });

	return (
		<div
			className={classnames('processList position-static overflow--hidden', className, {
				'processList--open': !isFullyCollapsed,
				'processList--expandable': canExpand,
			})}
			onClick={() => canExpand && setExpanded(!isExpanded)}
		>
			<RenderWithLink
				to={`/user/${processList[0].userId}/details`}
				withLink={showUserData}
				className={classnames('d-block processList__main')}
			>
				<div className="processList__listRow d-flex flex-column fs-mobile fs-sm-4">
					<div
						className={classnames(
							'processList__listRow__content d-flex flex-row flex-grow-1',
							showUserData ? 'm-3 mx-sm-5' : 'm-3 mb-4 m-sm-4 mx-sm-5'
						)}
					>
						{showUserData && (
							<div className="mr-3 mr-sm-4">
								<div
									className="flex processList__avatar rounded-circle background-image size--cover "
									style={{
										backgroundImage: `url(${processList[0].user.avatar ||
											images.default.avatar.user})`,
									}}
								/>
							</div>
						)}
						<div className="flex-grow-1">
							{showUserData && (
								<div className="fs-mobile fs-sm-4 mb-1 mb-sm-2 font-weight-bold">
									{processList[0].user.fullName}
								</div>
							)}
							<div className="d-flex flex-grow-1 flex-wrap processList__title">
								{shouldShowProcessDate && (
									<div className="mt-1">
										<FormattedHTMLMessage
											id={`portal.process.title.date.${workflowCategory(
												processList[0].workflowCategory
											)}`}
											values={{
												time: processList[0].firstDay.diff(moment(), 'days') + 1,
											}}
										/>
									</div>
								)}
								&nbsp;
								{shouldShowProcessDuration && (
									<div className="mt-1">
										{daysToProcessEnd > 0 ? (
											<FormattedHTMLMessage
												id="portal.process.title.duration"
												values={{
													duration: daysToProcessEnd,
												}}
											/>
										) : (
											<FormattedHTMLMessage id={overdueTranslation} />
										)}
									</div>
								)}
							</div>
							<div className="d-flex flex-grow-1 mt-1 mt-sm-2 align-items-center processList__progressBar">
								<ProgressBar
									progress={progress}
									className="align-self-center flex-grow-1 mr-1"
									innerPadding={0.125}
									progressBarColor={isDelayed ? 'base.red' : 'base.green'}
								/>
								<div className="processList__progressBar__actions font-weight-bolder">
									{allActionsDone} / {allActionsCount}
								</div>
							</div>
							{showUserData && (
								<div className="processList__roleAssignedList d-flex flex-column flex-wrap fs-mobile fs-sm-4 mt-1">
									{roles.map(role => (
										<div key={role.roleId} className="mt-1">
											{role.user?.fullName} - {role.name}
										</div>
									))}
								</div>
							)}
						</div>
					</div>
					{canExpand && (
						<div className="d-flex flex-grow-1 d-flex w-100 justify-content-center">
							<div className="position-absolute">
								<ExpandIcon className="processList__toggleList__icon" />
							</div>
						</div>
					)}
				</div>
			</RenderWithLink>
			<div className="processList__list " {...getCollapseProps()}>
				<div className="pt-2 pt-sm-3 pb-sm-3 ">
					{processList.map((process, index) => {
						return (
							<Fragment key={process.id}>
								<div className="processList__listRow position-relative d-block py-3 mx-3 mx-sm-5">
									<div className=" d-flex fs-mobile fs-sm-4">
										<div className="process__content d-flex flex-column flex-grow-1">
											<div className="d-flex flex-grow-1 flex-wrap">{process.name}</div>
											<div className="d-flex flex-grow-1 mt-2 align-items-center processList__progressBar">
												<ProgressBar
													progress={process.progress}
													innerPadding={0.125}
													className="align-self-center flex-grow-1 mr-1 processList__progressBar"
													progressBarColor={
														process.actionsDelayed ? 'base.red' : 'base.green'
													}
												/>
												<div className="processList__progressBar__actions font-weight-bolder">
													{process.actionsDone} / {process.actionsCount}
												</div>
											</div>
										</div>
									</div>
								</div>
								{index + 1 < processList.length && <div className="portal__spacer mx-5" />}
							</Fragment>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default ProcessList;
