import AchievementNotification from 'models/notification/AchievementNotification';

import NotificationBaseService from 'services/notification/NotificationBaseService';

export default class AchievementNotificationService extends NotificationBaseService {
	constructor(achievementService, currencyService) {
		super();
		this._achievementService = achievementService;
		this._currencyService = currencyService;
	}

	toModel({ achievement, earnings, ...rest }) {
		return new AchievementNotification({
			achievement: achievement && this._achievementService.toModel(achievement),
			earnings: this._currencyService.toModel(earnings),
			...rest,
		});
	}
}
