import DeclarationTaskAcceptanceChangedNotification from 'models/notification/DeclarationTaskAcceptanceChangedNotification';

import NotificationBaseService from 'services/notification/NotificationBaseService';

export default class DeclarationTaskAcceptanceChangedNotificationService extends NotificationBaseService {
	constructor(taskService) {
		super();
		this._taskService = taskService;
	}

	toModel({ task, earnings, ...rest }) {
		return new DeclarationTaskAcceptanceChangedNotification({
			task: task && this._taskService.toModel(task),
			...rest,
		});
	}
}
