export default class Style {
	_variables = {};

	/**
	 * @param {CSSStyleSheet} $styleSheet
	 */
	constructor($styleSheet) {
		/**
		 * @type {CSSStyleSheet}
		 * @private
		 */
		this._$styleElement = $styleSheet || this._createStyleElement();
		/**
		 * @type {Object<string, {selector: string, element: Element}>}
		 * @private
		 */
		this._scopes = {
			root: {
				selector: ':root',
				element: document.documentElement,
			},
		};
	}

	variables() {
		return this._variables;
	}

	innerHTML(css) {
		this._$styleElement.innerHTML = css;
	}

	/**
	 * @param {string} selector
	 * @param {string} rules
	 * @throws {Error}
	 */
	addRule(selector, rules) {
		const { sheet } = this._$styleElement;
		const index = sheet.cssRules.length;

		if ('insertRule' in sheet) {
			sheet.insertRule(`${selector} { ${rules} }`, index);
		} else if ('addRule' in sheet) {
			sheet.addRule(selector, rules, index);
		} else {
			throw new Error('"insert-css-rule" action is not supported by this browser.');
		}
	}

	/**
	 * @param {string} name
	 * @param {string} value
	 */
	addVariable(name, value) {
		this._variables[name] = value;

		this.addRule(this._scopes.root.selector, `--${name}: ${value}`);
	}

	/**
	 * @param {string} name
	 * @param {string} value
	 */
	updateVariable(name, value) {
		this._variables[name] = value;

		this._scopes.root.element.style.setProperty(`--${name}`, value);
	}

	/**
	 * @private
	 * @returns {CSSStyleSheet}
	 */
	_createStyleElement() {
		let style = document.createElement('style');

		style.type = 'text/css';
		style.setAttribute('class', 'transformed');

		// WebKit hack
		style.appendChild(document.createTextNode(''));

		document.head.appendChild(style);

		return style;
	}

	static areCSSVariablesSupported() {
		return window.CSS && window.CSS.supports && window.CSS.supports('(--foo: red)');
	}
}
