export interface LevelDTO {
	name: string;
	description: string;
	image: string;
	level_number: number;
	min_points: number;
}

export default class Level {
	private _name: string;
	private _description: string;
	private _image: string;
	private _levelNumber: number;
	private _minPoints: number;

	constructor(level: LevelDTO) {
		this._description = level.description;
		this._image = level.image;
		this._levelNumber = level.level_number;
		this._minPoints = level.min_points;
		this._name = level.name;
	}

	/**
	 * @returns {string}
	 */
	description() {
		return this._description;
	}

	/**
	 * @returns {string}
	 */
	image() {
		return this._image;
	}

	/**
	 * @returns {number}
	 */
	levelNumber() {
		return this._levelNumber;
	}

	/**
	 * @returns {number}
	 */
	minPoints() {
		return this._minPoints;
	}

	/**
	 * @returns {string}
	 */
	name() {
		return this._name;
	}
}
