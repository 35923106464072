import ItemAsset from 'models/store/itemAsset/ItemAsset';

/**
 * Class representing a image item asset.
 * @extends ItemAsset
 */
export default class ImageItemAsset extends ItemAsset {
	/**
	 * @param {Object} asset
	 */
	constructor(asset) {
		super(asset);

		/**
		 * @type {string}
		 */
		this.image = asset.image;
		/**
		 * @type {string}
		 */
		this.type = 'image';
	}
}
