import Footer from 'components/task/common/footer/Footer';
import FooterInfo from 'components/task/common/footer/FooterInfo';
import FooterInteractive from 'components/task/common/footer/FooterInteractive';

export default {
	'components.task.common.Footer': {
		factory: Footer,
		deps: [{ $ref: 'components.task.common.footer.Info' }, { $ref: 'components.task.common.footer.Interactive' }],
	},
	'components.task.common.footer.Info': {
		factory: FooterInfo,
		deps: [{ $ref: 'components.Currencies' }],
	},
	'components.task.common.footer.Interactive': {
		factory: FooterInteractive,
	},
};
