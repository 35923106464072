import GraphInteractionService from 'services/graph/interaction/GraphInteractionService';
import NodeClickHandler from 'services/graph/interaction/NodeClickHandler';

export default {
	'services.graphInteractionService': {
		class: GraphInteractionService,
		calls: [
			{
				name: 'addInteractionHandler',
				args: ['nodeClick', { $ref: 'services.graphInteractionService.nodeClick' }],
			},
		],
	},
	'services.graphInteractionService.nodeClick': {
		class: NodeClickHandler,
		deps: [
			{ $ref: 'services.navigationService' },
			{ $ref: 'services.taskService' },
			{ $ref: 'services.achievementService' },
		],
	},
};
