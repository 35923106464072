import 'core-js/stable';
import 'regenerator-runtime/runtime';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import init from './init';

import { isProduction } from 'utils/env/isProduction';
import browserHistory from 'services/browserHistory';

window.buildNonce = document.documentElement.getAttribute('data-build-nonce');

if (isProduction) {
	Sentry.init({
		dsn: 'https://d5be6c1a163b4266917b917b11b4f2b4@o4503936648085504.ingest.sentry.io/4505084935602176',
		integrations: [
			new Integrations.BrowserTracing({
				routingInstrumentation: Sentry.reactRouterV5Instrumentation(browserHistory),
			}),
			new Sentry.Replay(),
		],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		environment: process.env.NODE_ENV,
	});
}

init();
