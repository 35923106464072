/**
 * Class representing a currency.
 */
export class Currency {
	constructor(currency) {
		/**
		 * @type {string}
		 */
		this.codeName = currency.code_name;
		/**
		 * @type {string}
		 */
		this.description = currency.description;
		/**
		 * @type {string}
		 */
		this.image = currency.image;
		/**
		 * @type {string}
		 */
		this.name = currency.name;
	}
}
