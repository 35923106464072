import ChallengeAwardedPlace from 'models/challenge/awardedPlace/ChallengeAwardedPlace';

/**
 * Class representing a challenge user awarded place.
 * @extends ChallengeAwardedPlace
 */
export default class ChallengeUserAwardedPlace extends ChallengeAwardedPlace {
	/**
	 * @param {Object} awardedPlace
	 */
	constructor(awardedPlace) {
		super(awardedPlace);

		/**
		 * @type {UserProfile[]}
		 * @private
		 */
		this._users = awardedPlace.users;
	}
}
