import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import Async from 'react-async-action';

import RankingUserPosition from './UserPosition.template';
import Loader from 'components/commons/_/loader/Loader';

export class RankingUserPositionComponent extends PureComponent {
	render() {
		const isAnonymous = this.props['services.userProfileService'].isAnonymous();
		const openAccessPopup = () => this.props['services.accessPopupService'].open();

		return (
			<Async action={() => this._action()}>
				<Async.Loading>
					<Loader />
				</Async.Loading>
				<Async.Resolved>
					{({ response } = {}) => (
						<RankingUserPosition
							{...response}
							ranking={this.props.ranking}
							isAnonymous={isAnonymous}
							openAccessPopup={openAccessPopup}
						/>
					)}
				</Async.Resolved>
			</Async>
		);
	}

	_action() {
		return this.props['services.ranking.rankingService'].fetchLoggedUserPosition({
			ranking: this.props.ranking,
		});
	}
}

export default wire(
	['services.ranking.rankingService', 'services.userProfileService', 'services.accessPopupService'],
	RankingUserPositionComponent
);
