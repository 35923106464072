import image from 'services/task/subServices/questionTask/choice/image';
import text from 'services/task/subServices/questionTask/choice/text';
import ChoiceQuestionTaskService from 'services/task/subServices/questionTask/choice/ChoiceQuestionTaskService';

export default {
	...image,
	...text,
	'services.choiceQuestionTaskService': {
		class: ChoiceQuestionTaskService,
	},
};
