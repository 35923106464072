import Challenge from 'models/challenge/challenge/Challenge';

export default class TargetChallenge extends Challenge {
	constructor(challenge) {
		super(challenge);

		this.challengeType = 'target';
		this._submissionEarnings = challenge.submission_earnings;
		this._isSubmissionLimit = challenge.is_submission_limit;
		this._lastImportDate = challenge.last_import_date;
		this._submissionLimit = challenge.submission_limit;
	}

	submissionEarnings() {
		return this._submissionEarnings;
	}

	hasSubmissionEarnings() {
		return this.submissionEarnings().hasValues();
	}

	isSubmissionLimit() {
		return this._isSubmissionLimit;
	}

	lastImportDate() {
		return this._lastImportDate;
	}

	submissionLimit() {
		return this._submissionLimit;
	}
}
