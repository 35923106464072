import UserAgreements from 'views/profile/user/agreements/UserAgreements';

export default {
	'profile.user.UserAgreements': {
		class: UserAgreements,
		deps: [
			{ $ref: 'enhancers.inProgress' },
			{ $ref: 'components.ReactMarkdown' },
			{ $ref: 'components.Agreement' },
			{ $ref: 'services.applicationService' },
			{ $ref: 'services.userService' },
			{ $ref: 'services.navigationService' },
		],
	},
};
