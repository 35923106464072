import OpenQuestion from 'models/common/form/question/open/OpenQuestion';

export default class TextOpenQuestion extends OpenQuestion {
	constructor(question) {
		super(question);

		this._minLength = question.min_length || 0;
		this._maxLength = question.max_length || Infinity;
		/**
		 * long | short
		 */
		this._textSize = question.text_size;
	}

	minLength() {
		return this._minLength;
	}

	maxLength() {
		return this._maxLength;
	}

	textSize() {
		return this._textSize;
	}
}
