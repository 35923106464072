import React, { Component } from 'react';
import Login from '../../components/auth/login/Login';

export default function(accessPopupService) {
	return class LoginPopup extends Component {
		state = {
			popup: false,
		};

		componentDidMount() {
			accessPopupService.setHandler(() => this._openPopup());
		}

		render() {
			return (
				<div className="accessPopup wrapper--row justify--center align--center">
					{this.state.popup ? (
						<div className="popup">
							<div className="popup__layer" onClick={() => this._closePopup()} />
							<div className="popup__background">
								<div className="popup__content">
									<Login />
								</div>
							</div>
						</div>
					) : null}
				</div>
			);
		}

		_openPopup() {
			this.setState({
				popup: true,
			});
		}

		_closePopup() {
			this.setState({
				popup: false,
			});
		}
	};
}
