import { createUseStyles } from 'react-jss';

export default (() => {
	return createUseStyles(theme => ({
		modal: {
			fontSize: '0.9rem',
		},
		label: {
			color: theme.color.system['color--content--foreground-60'],
			fontWeight: 600,
		},
		footer: {
			backgroundColor: theme.color.system['color--content--foreground-5'],
		},
		bought: {
			minHeight: 'inherit',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		},
	}));
})();
