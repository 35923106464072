import React, { useEffect, useState } from 'react';
import { wire } from 'react-hot-wire';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import useStyles from './Installation.jss';

import { Modal } from 'components/_/common/modal/Modal';

const Installation = props => {
	const PWAInstallationService = props['services.PWAInstallationService'];
	const platformService = props['services.platformService'];
	const [state, setState] = useState('not-installed');
	const classes = useStyles();

	useEffect(() => {
		PWAInstallationService.registerEventHandler(
			'installation-result',
			// result: accepted/dismissed
			() => setState(null)
		);
	}, [PWAInstallationService]);

	const views = {
		'not-installed': (
			<Modal close={() => setState(null)} config={{ blur: true }}>
				{() => (
					<div className={classnames('px-2 py-0 p-xs-4', classes.container)}>
						<div>
							<h1 className="mb-4">
								<FormattedMessage id="app.installation.title" />
							</h1>
							<hr />
							<h2 className="mb-2">
								<FormattedMessage
									id="app.installation.subtitle"
									values={{
										separator: <br />,
									}}
								/>
							</h2>
							<hr className="mb-4" />
							<button className="btn__basic" onClick={() => PWAInstallationService.prompt()}>
								<FormattedMessage id="app.installation.button" />
							</button>
						</div>
					</div>
				)}
			</Modal>
		),
	};

	if (!platformService.isMobile()) {
		return null;
	}

	return PWAInstallationService.isInstalled() ? null : views[state] ? views[state] : null;
};

export default wire(['services.PWAInstallationService', 'services.platformService'], Installation);
