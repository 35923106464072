import content from 'components/task/types/content';
import declaration from 'components/task/types/declaration';
import document from 'components/task/types/document';
import embeddedAnalytics from 'components/task/types/embeddedAnalytics';
import form from 'components/task/types/form';
import join from 'components/task/types/join';
import mockup from 'components/task/types/mockup';
import question from 'components/task/types/question';
import visitLink from 'components/task/types/visitLink';

export default {
	...content,
	...declaration,
	...document,
	...embeddedAnalytics,
	...form,
	...join,
	...mockup,
	...question,
	...visitLink,
};
