import TaskBaseService from 'services/task/TaskBaseService';
import JoinTask from 'models/task/join/JoinTask';

export default class JoinTaskService extends TaskBaseService {
	toModel(task) {
		return new JoinTask({
			...task,
			state: this._mapState(task),
		});
	}
}
