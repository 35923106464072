import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Checkbox } from 'components/_/form/checkbox/Checkbox';
import { Currency } from 'components/_/common/currency/Currency';

export const CafeteriaFilters = injectIntl(props => {
	const { filters, onChange, currency, intl } = props;

	const onChangeFrom = event => {
		const from = event.target.value;

		if (Number.isInteger(Number(from))) {
			onChange({
				...filters,
				from: from ? Number(from) : '',
			});
		}
	};

	const onChangeTo = event => {
		const to = event.target.value;

		if (Number.isInteger(Number(to))) {
			onChange({
				...filters,
				to: to ? Number(to) : '',
			});
		}
	};

	const onAffordChange = event => {
		const { checked } = event.target;

		onChange({
			...filters,
			afford: checked,
		});
	};

	return currency ? (
		<div className="cafeteria__filters px-3 py-4 py-sm-0 pr-sm-4">
			<div className="filter__price">
				<form className="form">
					<strong className="fs-5 fs-sm-6">
						<FormattedMessage id="cafeteria.filter.price" />
					</strong>
					<div className="d-flex align-items-center justify-content-center py-3">
						<input
							type="text"
							size={5}
							className="mr-2 text-center rounded"
							value={filters.from}
							disabled={filters.afford}
							placeholder={intl.formatMessage({ id: 'cafeteria.filter.from' })}
							onChange={onChangeFrom}
						/>{' '}
						-{' '}
						<input
							type="text"
							size={5}
							className="mx-2 text-center rounded"
							value={filters.to}
							disabled={filters.afford}
							placeholder={intl.formatMessage({ id: 'cafeteria.filter.to' })}
							onChange={onChangeTo}
						/>
						<h3 className="mb-0">
							<Currency currency={currency.codeName()} />
						</h3>
					</div>
				</form>
				<div className="fs-4">
					<Checkbox fieldType="input" className="mt-3" checked={filters.afford} onChange={onAffordChange}>
						<FormattedMessage id="cafeteria.filter.afford" />
					</Checkbox>
				</div>
			</div>
		</div>
	) : null;
});
