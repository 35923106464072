import { ContainerGroup, ContainerGroupDTO, ContainerGroupInterface, ContainerGroupType } from './ContainerGroup';

export default class UserContainerGroup extends ContainerGroup implements UserContainerGroupInterface {
	public containerGroupType: ContainerGroupType.UserContainerGroup = ContainerGroupType.UserContainerGroup;
}

export interface UserContainerGroupInterface extends ContainerGroupInterface {
	containerGroupType: ContainerGroupType.UserContainerGroup;
}

export interface UserContainerGroupDTO extends ContainerGroupDTO {
	container_group_type: ContainerGroupType.UserContainerGroup;
}
