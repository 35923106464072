import _ from 'lodash';
import Membership from 'models/user/Membership';
import User from 'models/user/User';
import Roles from 'models/user/Roles';

/**
 * Class representing a user profile.
 */
export default class UserProfile {
	/**
	 * @param {Object} userProfile
	 */
	constructor(userProfile) {
		if (!userProfile) {
			return;
		}

		/**
		 * @type {string}
		 */
		this._id = userProfile.id;
		/**
		 * @type {string}
		 */
		this.type = userProfile.type;
		/**
		 * @type {string[]}
		 */
		this.roles = userProfile.roles && new Roles(...userProfile.roles);
		/**
		 * @type {User}
		 */
		this.user = new User(userProfile.user);
		/**
		 * @type {string}
		 */
		this._avatar = userProfile.avatar && `${userProfile.avatar}?w=128`;
		/**
		 * @type {Object}
		 */
		this._membership = userProfile.membership && new Membership(userProfile.membership);
		/**
		 * @type {string}
		 */
		this._nickname = userProfile.nickname || '';
		/**
		 * @type {string}
		 */
		this._description = userProfile.description;
		/**
		 * @type {string}
		 */
		this._forename = userProfile.forename || '';
		/**
		 * @type {string}
		 */
		this._surname = userProfile.surname || '';
		/**
		 * @type {boolean}
		 */
		this.verified = !!userProfile.verified;
		/**
		 * @type {string}
		 */
		this.sex = userProfile.sex;
		/**
		 * @type {string}
		 */
		this.externalId = userProfile.external_id;
		/**
		 * @type {string}
		 */
		this.importedExternalId = userProfile.imported_external_id;
		/**
		 * @type {Object[]}
		 */
		this._givenAgreements = (userProfile.given_agreements || []);
		/**
		 * @type {boolean}
		 */
		this._active = userProfile.active;
		/**
		 * @type {string}
		 */
		this._email = userProfile.email;
	}

	id() {
		return this._id;
	}

	avatar() {
		return this._avatar;
	}

	membership() {
		return this._membership;
	}

	nickname() {
		return this._nickname;
	}

	description() {
		return this._description;
	}

	forename() {
		return this._forename;
	}

	surname() {
		return this._surname;
	}

	name() {
		return _.chain([this.forename(), this.surname()])
			.reject(_.isEmpty)
			.join(' ')
			.value();
	}

	fullName() {
		return this.forename() || this.surname() ? `${this.forename()} ${this.surname()}` : this.nickname();
	}

	setAvatar(avatar) {
		this._avatar = avatar;
	}

	setField(name, value) {
		this[`_${name}`] = value;
	}

	givenAgreements() {
		return this._givenAgreements;
	}

	checkAgreement({ id } = {}) {
		return _.some(this._givenAgreements, agreement => agreement.id === id);
	}

	active() {
		return this._active;
	}

	email() {
		return this._email;
	}
}
