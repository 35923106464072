import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { wire } from 'react-hot-wire';
import { NavLink } from 'react-router-dom';

import Info from '../../../Info';

export class OAuthCallbackComponent extends PureComponent {
	state = {
		error: null,
	};

	render() {
		const { error } = this.state;

		if (!error) {
			return (
				<h2>
					<FormattedMessage id="auth.login.integration.oauth.loading" />
				</h2>
			);
		}

		return (
			<Info
				title={this.props.intl.formatMessage({
					id: `auth.login.integration.oauth.error.type.${this.state.error}`,
				})}
				message={
					<NavLink to="/auth/login">
						{this.props.intl.formatMessage({
							id: 'auth.login.integration.oauth.return',
						})}
					</NavLink>
				}
			/>
		);
	}

	UNSAFE_componentWillMount() {
		const { id, code, state } = this.props;

		this.props['services.OAuthIntegrationService']
			.authorizeCallback({ id, code, state })
			.catch(error => this.setState({ error }));
	}
}

export default injectIntl(wire(['services.OAuthIntegrationService'], OAuthCallbackComponent));
