import APIService from 'services/APIService';
import EmployeePreview, { EmployeePreviewDTO } from '../../../models/onboarding/employee/EmployeePreview';

export type GetEmployeePreviewListParameters = {
	limit?: number;
	page?: number;
	search?: string;
	skipIds?: string[];
};

export default class EmployeeService {
	constructor(private readonly ApiService: APIService) {}

	fetchEmployeePreviewList({ limit = 10, page = 1, search, skipIds }: GetEmployeePreviewListParameters) {
		return this.ApiService.get<{ items: EmployeePreviewDTO[] }>(
			`/user-preview`,
			undefined,
			undefined,
			{ version: 'v2', controller: 'workflows' },
			{
				limit,
				page,
				search,
				skipIds: skipIds ? skipIds.join(',') : undefined,
			}
		).then(response => ({
			items: response.items.map(process => this.toModel(process)),
		}));
	}

	toModel(employee: EmployeePreviewDTO): EmployeePreview {
		return new EmployeePreview(employee);
	}
}
