import moment from 'moment';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import React from 'react';
import TaskAction from 'models/onboarding/process/action/TaskAction';

export type ActionDelayTimeProps = InjectedIntlProps & { action: TaskAction };

export const ActionDelayTime = ({ action, intl }: ActionDelayTimeProps) => {
	const deadlineTimeInDays = action.deadline ? action.deadline.diff(moment(), 'days') : 0;
	const deadlineTimeInHours = action.deadline ? action.deadline.diff(moment(), 'hours') : 0;
	const deadlineTimeInMinutes = action.deadline ? action.deadline.diff(moment(), 'minutes') : 0;
	const timePlural =
		deadlineTimeInDays > 0 ? 'time.days' : deadlineTimeInMinutes > 59 ? 'time.hours' : 'time.minutes';
	const deadlineTime =
		deadlineTimeInDays > 0
			? deadlineTimeInDays
			: deadlineTimeInMinutes > 59
			? deadlineTimeInHours
			: deadlineTimeInMinutes;

	if (!action.deadline) {
		return null;
	}

	return (
		<>
			{action.deadline
				? action.isDelayed
					? intl.formatMessage(
							{
								id: `portal.action.deadline.overdue`,
							},
							{
								time: action.deadline.from(moment(), true),
							}
					  )
					: intl.formatMessage(
							{
								id: `portal.action.deadline.general`,
							},
							{
								type: intl.formatMessage({
									id: `portal.action.deadline.${action.detailedContentType}`,
								}),
								time: intl.formatMessage(
									{ id: timePlural },
									{
										time: deadlineTime,
									}
								),
							}
					  )
				: null}
		</>
	);
};

export default injectIntl(ActionDelayTime);
