import moment from 'moment';

import Challenge from 'models/challenge/challenge/Challenge';

/**
 * Class representing a creative challenge.
 * @extends Challenge
 */
export default class CreativeChallenge extends Challenge {
	/**
	 * @param {Object} challenge
	 */
	constructor(challenge) {
		super(challenge);

		/**
		 * @type {string}
		 */
		this.challengeType = 'creative';
		/**
		 * @type {boolean}
		 */
		this._autoAccept = challenge.auto_accept;
		/**
		 * @type {string[]}
		 */
		this._submissionTypes = (challenge.submission_types || []);
		/**
		 * @type {number}
		 */
		this._submissionLimit = challenge.submission_limit || 1;
		/**
		 * @type {boolean}
		 */
		this.isSubmissionTypesSet = !!~(this._submissionTypes.length - 1);
		/**
		 * Points for submission add
		 * @type {object}
		 */
		this._pointsForNothing = challenge.points_for_nothing;
		/**
		 * @type {object}
		 */
		this._submissionEarnings = challenge.submission_earnings;
		/**
		 * @type {number}
		 */
		this._submissionCount = challenge.submissionCount;
		/**
		 * @type {boolean}
		 */
		this._canLike = challenge.can_like;
		/**
		 * @type {boolean}
		 */
		this._canScore = challenge.can_score;
		/**
		 * @type {string}
		 */
		this._likeType = challenge.like_type || 'thumbs-up';
		/**
		 * @type {Moment}
		 * @private
		 */
		this._submissionPublicationAt =
			challenge.submission_publication_at && moment(challenge.submission_publication_at);
	}

	autoAccept() {
		return this._autoAccept;
	}

	icon() {
		if (!this.isSubmissionTypesSet || this._submissionTypes.length > 1) {
			return 'defaultIcon';
		}

		return this._submissionTypes[0];
	}

	submissionTypes() {
		return this._submissionTypes;
	}

	submissionLimit() {
		return this._submissionLimit;
	}

	limitReached() {
		return this._submissionLimit === this.state().submissionCount();
	}

	pointsForNothing() {
		return this._pointsForNothing;
	}

	submissionEarnings() {
		return this._submissionEarnings;
	}

	hasSubmissionEarnings() {
		return this.submissionEarnings().hasValues();
	}

	submissionCount() {
		return this._submissionCount;
	}

	canLike() {
		return this._canLike;
	}

	canScore() {
		return this._canScore;
	}

	likeType() {
		return this._likeType;
	}

	submissionPublicationAt() {
		return this._submissionPublicationAt?.format(Challenge.DATE_FORMAT);
	}

	isPublicationReady() {
		return moment().isSameOrAfter(this._submissionPublicationAt);
	}
}
