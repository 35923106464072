import APIService from 'services/APIService';
import Contact, { ContactDTO } from 'models/onboarding/contact/Contact';

export default class ContactsService {
	constructor(private readonly ApiService: APIService) {}

	fetchList() {
		return this.ApiService.get<{ items: ContactDTO[] }>(`/contacts`, undefined, undefined, {
			version: 'v2',
			controller: 'workflows',
		}).then(response => ({
			items: response.items.map(process => this.toModel(process)),
		}));
	}

	toModel(contact: ContactDTO): Contact {
		return new Contact(contact);
	}
}
