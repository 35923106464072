import ContainerService from './ContainerService';

import subServices from 'services/container/subServices';

export default {
	...subServices,
	'services.containerService': {
		class: ContainerService,
		calls: [
			{
				name: 'registerSubService',
				args: ['teamContainerGroup', { $ref: 'services.teamContainerService' }],
			},
			{
				name: 'registerSubService',
				args: ['userContainerGroup', { $ref: 'services.userContainerService' }],
			},
		],
	},
};
