import React from 'react';
import classnames from 'classnames';

import useStyles from './Radio.jss';

export const Radio = ({ className, disabled, children, ...props }) => {
	const classes = useStyles();

	return (
		<label className={classnames(classes.label, disabled && classes.disabled, className)}>
			<input type="radio" className={classes.input} disabled={disabled} {...props} />
			<div className={classes.check} />
			{children && <div className={classes.content}>{children}</div>}
		</label>
	);
};
