import { Children, cloneElement } from 'react';
import classnames from 'classnames';

export default function tabFactory(TabBase) {
	return function(tabManager) {
		return class Tab extends TabBase {
			render() {
				let tab = Children.only(this.props.children);

				return cloneElement(tab, {
					onClick: () => (this.props.disabled ? null : tabManager.activate(this.props.name)),
					className: classnames(tab.props.className, {
						active: this.state.active,
					}),
				});
			}

			componentDidMount() {
				tabManager.register('tab', this);
			}
		};
	};
}
