import React, { PureComponent } from 'react';

export default function(userProfileService, IsAMemberAlready, TeamJoinView) {
	return class JoinToTeam extends PureComponent {
		_membership = userProfileService.user().membership;

		UNSAFE_componentWillMount() {
			this.props.checkMembership(this._membership);
		}

		render() {
			return this._membership ? (
				<IsAMemberAlready type="team" task={this.props.task} name={this._membership.team().name()} />
			) : (
				<TeamJoinView onChange={this.props.onChange} task={this.props.task} />
			);
		}
	};
}
