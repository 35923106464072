/**
 * Class representing a item asset.
 * @abstract
 */
export default class ItemAsset {
	/**
	 * @param {Object} asset
	 */
	constructor(asset) {
		/**
		 * @type {string}
		 */
		this.name = asset.name;
		/**
		 * @type {string}
		 */
		this.type = asset.type;
	}
}
