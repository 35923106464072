import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../_/common/button/Button';

export default ({ position, ranking, isAnonymous, openAccessPopup }) => {
	if (!isAnonymous && !position) {
		return null;
	}

	const yourPlace = (
		<p className="mr-2 mr-sm-0 fs-5 fs-sm-6 mb-0 mb-sm-3">
			<FormattedMessage
				id={`ranking.content.your-place.${ranking.type}`}
				values={{ rankingName: ranking.name }}
			/>
		</p>
	);

	return isAnonymous ? (
		<div className="d-flex flex-row flex-sm-column mt-3 mt-sm-5">
			<div className="d-none d-sm-block">{yourPlace}</div>
			<Button color="interactive" onClick={openAccessPopup} className="align-self-start">
				<FormattedMessage id="ranking.content.button.log-in" />
			</Button>
		</div>
	) : (
		<div className="d-flex flex-row flex-sm-column mt-4 mt-sm-5">
			{yourPlace}
			<p className="fs-5 fs-sm-7 font-weight-bold">{position}</p>
		</div>
	);
};
