export default class Content {
	/**
	 * @param {Object} content
	 */
	constructor(content) {
		this._blocks = content.blocks || [];
	}

	blocks() {
		return this._blocks;
	}
}
