import Task from 'models/task/Task';

/**
 * Class representing a visit link task.
 * @extends Task
 */
export default class VisitLinkTask extends Task {
	/**
	 * @param {Object} task
	 */
	constructor(task) {
		super(task);

		/**
		 * @type {string}
		 */
		this._linkUrl = task.link_url;

		/**
		 * @type {boolean}
		 */
		this._openInFrame = task.open_in_frame;

		/**
		 * @type {number}
		 */
		this._requiredTime = this._openInFrame ? task.required_time : 0;
	}

	linkUrl() {
		return this._linkUrl;
	}

	openInFrame() {
		return this._openInFrame;
	}

	requiredTime() {
		return this._requiredTime;
	}
}
