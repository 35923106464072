import ImageMenuPositionService from 'services/application/menu/position/subServices/ImageMenuPositionService';
import LinkMenuPositionService from 'services/application/menu/position/subServices/LinkMenuPositionService';
import LogoMenuPositionService from 'services/application/menu/position/subServices/LogoMenuPositionService';
import MoreMenuPositionService from 'services/application/menu/position/subServices/MoreMenuPositionService';

export default {
	'services.imageMenuPositionService': {
		class: ImageMenuPositionService,
		deps: [{ $ref: 'services.anchorService' }],
	},
	'services.linkMenuPositionService': {
		class: LinkMenuPositionService,
		deps: [{ $ref: 'services.anchorService' }],
	},
	'services.logoMenuPositionService': {
		class: LogoMenuPositionService,
		deps: [{ $ref: 'services.anchorService' }],
	},
	'services.moreMenuPositionService': {
		class: MoreMenuPositionService,
		deps: [{ $ref: 'services.anchorService' }],
	},
};
