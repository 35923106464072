import Anchor from 'models/application/anchor/Anchor';

export interface MenuPositionDTO {
	main_page: boolean;
	welcome_page: boolean;
	anchor: Anchor;
	title: string;
	image: string | null;
	type: string;
}

export default class MenuPosition {
	public readonly mainPage: boolean;
	public readonly welcomePage: boolean;
	public readonly anchor: Anchor;
	public readonly title: string;
	public readonly image: string | null;
	public readonly type: string;
	public desktop: boolean;
	public mobile: boolean;

	constructor(position: MenuPositionDTO) {
		this.mainPage = position.main_page;
		this.welcomePage = position.welcome_page;
		this.anchor = position.anchor;
		this.title = position.title;
		this.image = position.image;
		this.type = position.type;

		this.desktop = false;
		this.mobile = false;
	}

	allowDesktop() {
		this.desktop = true;
	}

	allowMobile() {
		this.mobile = true;
	}

	url() {
		return this.anchor && this.anchor.url();
	}
}
