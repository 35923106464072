import TeamMember, { TeamMemberDTO } from 'models/team/TeamMember';

export type TeamDTO = {
	id: string;
	name: string;
	nickname?: string;
	description?: string;
	avatar?: string;
	team_members?: TeamMemberDTO[];
};

export default class Team {
	private _id?: string;
	private _name?: string;
	private _nickname?: string;
	private _description?: string;
	private _avatar?: string;
	private _teamMembers?: TeamMember[];
	[key: string]: any;

	constructor(team?: TeamDTO) {
		if (!team) {
			return;
		}

		this._id = team.id;
		this._name = team.name;
		this._nickname = team.nickname;
		this._description = team.description;
		this._avatar = team.avatar && `${team.avatar}?w=128`;
		this._teamMembers = (team.team_members || []).map(member => new TeamMember(member));
	}

	id() {
		return this._id;
	}

	name() {
		return this._name;
	}

	nickname() {
		return this._nickname;
	}

	description() {
		return this._description;
	}

	avatar() {
		return this._avatar;
	}

	teamMembers() {
		return this._teamMembers;
	}

	setFieldValue(field: 'id' | 'name' | 'nickname' | 'description' | 'avatar' | 'teamMembers', value: any) {
		this[`_${field}`] = value;
	}
}
