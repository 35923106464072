import ChallengeService from 'services/challenge/challenge/ChallengeService';
import subServices from 'services/challenge/challenge/subServices';

export default {
	'services.challengeService': {
		class: ChallengeService,
		deps: [{ $ref: 'services.APIService' }],
		calls: [
			{
				name: 'registerSubService',
				args: ['creative', { $ref: 'services.creativeChallengeService' }],
			},
			{
				name: 'registerSubService',
				args: ['kpi', { $ref: 'services.KPIIndexedChallengeService' }],
			},
			{
				name: 'registerSubService',
				args: ['target', { $ref: 'services.targetChallengeService' }],
			},
		],
	},
	...subServices,
};
