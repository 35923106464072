import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import images from 'assets/images';

import useStyles from './UserAvatar.jss';

export const UserAvatar = ({ user = {}, team, size }) => {
	const classes = useStyles({ size })();

	return (
		<div className={classes.container}>
			<Link to={user.href}>
				<div
					className={classnames('background-image size--cover', classes.userAvatar)}
					style={{ backgroundImage: `url(${user.avatar || images.default.avatar.user})` }}
				/>
			</Link>
			{team && (
				<Link to={team.href}>
					<div
						className={classnames('background-image size--cover', classes.teamAvatar)}
						style={{ backgroundImage: `url(${team.avatar || images.default.avatar.team})` }}
					/>
				</Link>
			)}
			{user.level && <div className={classes.userLevel}>{user.level}</div>}
		</div>
	);
};
