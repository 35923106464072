import CreativeChallengeDetails from 'views/challenge/tabs/CreativeChallengeDetails';
import TargetChallengeDetails from 'views/challenge/tabs/TargetChallengeDetails';

export default {
	'components.CreativeChallengeDetails': {
		factory: CreativeChallengeDetails,
		deps: [
			{ $ref: 'components.ReactMarkdown' },
			{ $ref: 'components.Currencies' },
			{ $ref: 'components.MatchingImageType' },
		],
	},
	'components.TargetChallengeDetails': {
		factory: TargetChallengeDetails,
		deps: [
			{ $ref: 'components.ReactMarkdown' },
			{ $ref: 'components.Currencies' },
			{ $ref: 'components.MatchingImageType' },
		],
	},
};
