import RegistrationEmailNotificationService from 'services/notification/subServices/email/subServices/RegistrationEmailNotificationService';
import RememberPassEmailNotificationService from 'services/notification/subServices/email/subServices/RememberPassEmailNotificationService';
import ResetPassEmailNotificationService from 'services/notification/subServices/email/subServices/ResetPassEmailNotificationService';
import SubmissionRejectedEmailNotificationService from 'services/notification/subServices/email/subServices/SubmissionRejectedEmailNotificationService';
import SubmissionAcceptedEmailNotificationService from 'services/notification/subServices/email/subServices/SubmissionAcceptedEmailNotificationService';

export default {
	'services.registrationEmailNotificationService': {
		class: RegistrationEmailNotificationService,
	},
	'services.rememberPassEmailNotificationService': {
		class: RememberPassEmailNotificationService,
	},
	'services.resetPassEmailNotificationService': {
		class: ResetPassEmailNotificationService,
	},
	'services.submissionRejectedEmailNotificationService': {
		class: SubmissionRejectedEmailNotificationService,
	},
	'services.submissionAcceptedEmailNotificationService': {
		class: SubmissionAcceptedEmailNotificationService,
	},
};
