import Process from 'models/onboarding/process/Process';
import ProcessService from 'services/onboarding/process/ProcessService';
import { useQuery } from 'react-query';
import { EnsureResponseData } from 'utils/type/query/EnsureResponseData';

export type UseGetEmployeeProgressProps = {
	'services.onboardingProcessService': ProcessService;
	employeeId?: string;
};

export type UseGetEmployeeProgressResult = EnsureResponseData<{ items: Process[] }>;

const defaultData = { items: [] };

export const useGetEmployeeProgress = (props: UseGetEmployeeProgressProps): UseGetEmployeeProgressResult => {
	const processService = props['services.onboardingProcessService'];
	const employeeProgress = useQuery<{ items: Process[] }>(
		['portal-employee-progress', props.employeeId],
		() => processService.fetchEmployeeList(props.employeeId),
		{ cacheTime: 0, keepPreviousData: true }
	);

	return {
		...employeeProgress,
		data: Object.assign({}, defaultData, employeeProgress.data),
	};
};

export default useGetEmployeeProgress;
