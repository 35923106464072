import { TrueFalseAnswerMode } from 'models/task/question/questionTask/trueFalse/answer/mode/TrueFalseAnswerMode';

export default class TrueFalseAnswerModeService {
	/**
	 * @param {Object} answer
	 * @returns {TrueFalseAnswerMode}
	 */
	toModel(answer) {
		return new TrueFalseAnswerMode(answer);
	}
}
