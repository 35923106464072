import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import { wire } from 'react-hot-wire';

import CreateTextSubmission from './TextSubmission.template';
import { schema } from './TextSubmission.schema';

export class CreateTextSubmissionComponent extends PureComponent {
	render() {
		return (
			<Formik
				initialValues={{
					title: '',
					text: '',
				}}
				validationSchema={schema}
				onSubmit={(...args) => this._onSubmit(...args)}
				render={props => <CreateTextSubmission {...this.props} {...props} />}
			/>
		);
	}

	_onSubmit(values) {
		const { challenge, activateModal } = this.props;
		const userProfileService = this.props['services.userProfileService'];

		this.props['services.creativeChallengeService']
			.addSubmission({
				id: challenge.id,
				title: values.title,
				text: values.text,
				submission_type: 'textsubmission',
			})
			.then(() => {
				userProfileService.addToWalletQueue(challenge.pointsForNothing());

				if (challenge.autoAccept()) {
					this.props.onSubmit();
					this.props.closeModal();
				} else {
					activateModal('confirmation');
				}
			})
			.catch(reason => activateModal('error', reason));
	}
}

export default wire(
	['services.creativeChallengeService', 'services.userProfileService'],
	CreateTextSubmissionComponent
);
