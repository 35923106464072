import Task from 'models/task/Task';

export default class QuestionTask extends Task {
	question() {
		return this._question;
	}

	isImageQuestionTask() {
		return false;
	}
}
