import join from 'components/task/types/join/join';
import JoinTask from 'components/task/types/join/JoinTask';
import JoinToTeam from 'components/task/types/join/JoinToTeam';
import JoinToUserContainer from 'components/task/types/join/JoinToUserContainer';

export default {
	...join,
	'components.task.JoinTask': {
		factory: JoinTask,
		deps: [
			{ $ref: 'components.task.layout.BaseLayout' },
			{ $ref: 'services.userProfileService' },
			{ $ref: 'components.task.JoinTask.IsAnonymous' },
			{
				team: { $ref: 'components.task.JoinTask.JoinToTeam' },
				container: {
					$ref: 'components.task.JoinTask.JoinToUserContainer',
				},
			},
		],
	},
	'components.task.JoinTask.JoinToTeam': {
		factory: JoinToTeam,
		deps: [
			{ $ref: 'services.userProfileService' },
			{ $ref: 'components.task.JoinTask.IsAMemberAlready' },
			{ $ref: 'components.task.JoinTask.TeamJoinView' },
		],
	},
	'components.task.JoinTask.JoinToUserContainer': {
		factory: JoinToUserContainer,
		deps: [
			{ $ref: 'services.userProfileService' },
			{ $ref: 'components.task.JoinTask.IsAMemberAlready' },
			{ $ref: 'components.task.JoinTask.JoinTaskCrashed' },
			{ $ref: 'components.task.JoinTask.UserContainerJoinView' },
		],
	},
};
