import React, { PureComponent } from 'react';
import classnames from 'classnames';

export default function(FooterInfo, FooterInteractive) {
	return class Footer extends PureComponent {
		render() {
			const { task, options, actions, progressIndicator } = this.props;
			const defaultOptions = {
				hideCheckButton: false,
			};
			const defaultActions = {
				isValid: true,
			};

			return (
				<div
					className={classnames('standardPreview__footer wrapper--row align--center justify--between', {
						correct: task.isCompleted(),
						incorrect: task.isFailed() || task.isCrashed(),
						...(task.acceptanceRequired() && {
							[task.state().currentRepetitionState() &&
							task
								.state()
								.currentRepetitionState()
								.acceptanceState()]: true,
						}),
					})}
				>
					<FooterInfo task={task} actions={actions} />
					<FooterInteractive
						task={task}
						options={{
							...defaultOptions,
							...options,
						}}
						actions={{
							...defaultActions,
							...actions,
						}}
						progressIndicator={progressIndicator}
					/>
				</div>
			);
		}
	};
}
