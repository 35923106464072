import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { Markdown } from 'components/_/common/text/markdown/Markdown';

import TextSubmission from 'models/challenge/submission/TextSubmission';
import ImageSubmission from 'models/challenge/submission/ImageSubmission';

export default ({ submission, visibility = {} }) => {
	const $submission = (
		<div className="submissionList__item">
			<div className="item__front rounded">
				{submission.status() && visibility.status && (
					<Fragment>
						<ReactTooltip
							className="status__tooltip"
							place="right"
							effect="float"
							border
							id={`status-${submission.id()}`}
						>
							{submission.status() === 'rejected' ? (
								<Fragment>
									<strong>
										<FormattedMessage
											id={`challenge-details.creative.submission-list.content.submission-status.${submission.status()}`}
										/>
									</strong>
									<div className="mt-1">
										<FormattedMessage
											id={`challenge-details.creative.submission-list.content.submission-status.reject-reason`}
											values={{
												reason: submission.rejectionReason(),
											}}
										/>
									</div>
								</Fragment>
							) : (
								<FormattedMessage
									id={`challenge-details.creative.submission-list.content.submission-status.${submission.status()}`}
								/>
							)}
						</ReactTooltip>
						<div
							data-for={`status-${submission.id()}`}
							data-tip="hello world"
							className={classnames('item__status rounded-circle', {
								[submission.status()]: true,
							})}
						>
							<div className="fa" />
						</div>
					</Fragment>
				)}
				{!submission.hidden() && (
					<Fragment>
						{submission instanceof TextSubmission && (
							<div className="item__text rounded-top">
								<Markdown strip={true} limit={100}>
									{submission.text()}
								</Markdown>
							</div>
						)}
						{submission instanceof ImageSubmission && (
							<img
								src={`${submission.image()}?w=512`}
								alt={submission.id()}
								className="item__image rounded-top"
							/>
						)}
					</Fragment>
				)}
				<div className="item__title rounded">
					{submission.hidden() ? (
						<p className="title w-100">
							<FormattedMessage id="challenge-details.creative.submission-list.content.removed" />
						</p>
					) : (
						<Fragment>
							{(submission.challenge.canScore() || submission.challenge.canLike()) &&
								visibility.judgement && (
									<div className="judgement w-100 d-flex">
										{submission.challenge.canScore() && (
											<div className="mr-2">
												<i className="fa fa-star" />
												<span>{submission.averageScore()}</span>
											</div>
										)}
										{submission.challenge.canLike() && (
											<div>
												<i
													className={classnames('fa', {
														[`fa-${submission.challenge.likeType()}`]: true,
													})}
												/>
												<span>{submission.likeCount()}</span>
											</div>
										)}
									</div>
								)}
							<p className="title w-100">{submission.title()}</p>
							{visibility.author && <p className="author w-100">{submission.author().fullName()}</p>}
						</Fragment>
					)}
				</div>
			</div>
		</div>
	);

	return (
		<div>
			{submission.hidden() ? (
				$submission
			) : (
				<Link to={`/challenge/submission/${submission.id()}/details`}>{$submission}</Link>
			)}
		</div>
	);
};
