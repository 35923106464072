import React from 'react';
import { Field } from 'formik';

//@deprecated => use Input.jsx
export const AutoResizeInput = ({ value, ...props }) => {
	const minChars = 5;
	const size = value.length > minChars ? value.length : minChars;

	return <Field {...props} value={value} size={size} />;
};
