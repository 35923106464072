import { CustomUserProfileFieldDefinitionService } from './CustomUserProfileFieldDefinitionService';
import { UserProfileFieldDefinitionService } from './UserProfileFieldDefinitionService';
import { MappedUserProfileFieldDefinitionService } from './MappedUserProfileFieldDefinitionService';

export default {
	'services.customUserProfileFieldDefinitionService': {
		class: CustomUserProfileFieldDefinitionService,
	},
	'services.userProfileFieldDefinitionService': {
		class: UserProfileFieldDefinitionService,
		calls: [
			{
				name: 'registerSubService',
				args: ['custom', { $ref: 'services.customUserProfileFieldDefinitionService' }],
			},
			{
				name: 'registerSubService',
				args: ['mapped', { $ref: 'services.mappedUserProfileFieldDefinitionService' }],
			},
		],
	},
	'services.mappedUserProfileFieldDefinitionService': {
		class: MappedUserProfileFieldDefinitionService,
	},
};
