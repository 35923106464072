import CompletePhraseQuestionTaskService from 'services/task/subServices/questionTask/phrase/CompletePhraseQuestionTaskService';
import GuessPhraseQuestionTaskService from 'services/task/subServices/questionTask/phrase/GuessPhraseQuestionTaskService';
import RetypePhraseQuestionTaskService from 'services/task/subServices/questionTask/phrase/RetypePhraseQuestionTaskService';

export default {
	'services.completePhraseQuestionTaskService': {
		class: CompletePhraseQuestionTaskService,
		deps: [{ $ref: 'services.taskStateService' }],
	},
	'services.guessPhraseQuestionTaskService': {
		class: GuessPhraseQuestionTaskService,
		deps: [{ $ref: 'services.taskStateService' }],
	},
	'services.retypePhraseQuestionTaskService': {
		class: RetypePhraseQuestionTaskService,
		deps: [{ $ref: 'services.taskStateService' }],
	},
};
