import Mission from 'models/task/Mission';

/**
 * Class representing a collection mission task.
 * @extends Mission
 */
export default class CollectionMission extends Mission {
	/**
	 * @param {Object} task
	 */
	constructor(task) {
		super(task);

		/**
		 * @type {Task[]}
		 */
		this._tasks = task.tasks;

		/**
		 * @type {string}
		 */
		this._viewType = task.view_type;
	}

	tasks() {
		return this._tasks;
	}

	viewType() {
		return this._viewType;
	}
}
