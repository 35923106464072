import Integration, { IntegrationDTO, IntegrationInterface } from './Integration';

export default class TokenIntegration extends Integration implements TokenIntegrationInterface {
	constructor(integration: TokenIntegrationDTO) {
		super(integration);

		// TODO: Add switch in admin panel
		this.registrationEnabled = true;
		this.authEnabled = false;
	}
}

export interface TokenIntegrationInterface extends IntegrationInterface {}

export interface TokenIntegrationDTO extends IntegrationDTO {}
