import React from 'react';
import classnames from 'classnames';

import useStyles from './Input.jss';

export const Input = ({ type = 'input', className, rounded, active = false, autoResize, ...props }) => {
	const classes = useStyles();
	const inputProps = {
		...props,
	};

	if (autoResize) {
		const minChars = 5;
		const { value = '' } = inputProps;

		inputProps.size = value.length > minChars ? value.length : minChars;
	}

	return (
		<label className={classnames(classes.label, className)}>
			{type === 'textarea' ? (
				<textarea
					className={classnames(classes.field, rounded && classes.rounded, classes[type], {
						active,
					})}
					{...props}
				/>
			) : (
				<input
					type="text"
					className={classnames(classes.field, rounded && classes.rounded, classes[type], {
						active,
					})}
					{...inputProps}
				/>
			)}
		</label>
	);
};
