import React, { useState, Fragment } from 'react';
import { wire } from 'react-hot-wire';

import View from 'components/_/common/view/list/ListView';

import useFetch from 'components/_/hooks/useFetch';

import { CafeteriaFilters } from './filters/CafeteriaFilters';
import { Cafeteria } from './Cafeteria';
import { FormattedMessage } from 'react-intl';

const CafeteriaView = props => {
	const rewardCafeteriaService = props['services.rewardCafeteriaService'];
	const userProfileService = props['services.userProfileService'];

	const wallet = userProfileService.user().wallet;

	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState({
		from: null,
		to: null,
		afford: false,
	});

	// Fetch first item for get cafeteria currency:
	const { response: currency } = useFetch(() =>
		rewardCafeteriaService.fetchList({ offset: 0, limit: 1 }).then(({ list }) => {
			const currencyName = list[0].price.codeName();

			return wallet.getCurrencyByName(currencyName);
		})
	);

	return (
		<View
			name="cafeteria"
			i18nKey="cafeteria"
			search={search}
			setSearch={setSearch}
			tabs={{
				url: '/reward-cafeteria', // eslint-disable-line no-template-curly-in-string
				list: [
					{
						tab: '',
						content: <FormattedMessage id="achievement-list.all-tab" />,
					},
				],
			}}
		>
			<Fragment>
				<hr className="d-none d-sm-block mb-4 mb-sm-5" />
				<div className="cafeteria">
					<div className="d-flex flex-column flex-sm-row fs-4">
						{currency && <CafeteriaFilters filters={filters} onChange={setFilters} currency={currency} />}
						<Cafeteria
							filters={filters}
							search={search}
							rewardCafeteriaService={rewardCafeteriaService}
							currency={currency}
						/>
					</div>
				</div>
			</Fragment>
		</View>
	);
};

export default wire(['services.rewardCafeteriaService', 'services.userProfileService'], CafeteriaView);
