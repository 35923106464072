import _ from 'lodash';

import BaseService from 'services/BaseService';

export default class IntegrationService extends BaseService {
	_type = 'integration';
	_typeKey = 'type';

	constructor(logService, userService) {
		super();

		this._logService = logService;
		this._userService = userService;
	}

	toModel(integration, ...rest) {
		try {
			return super.toModel(integration, ...rest);
		} catch (error) {
			this._logService.warn(error);
		}
	}

	init() {
		if (!this._userService.isLogged()) {
			return;
		}

		const integrations = this._applicationService.applicationConfig().integrations();

		_.each(integrations, integration => {
			const Adapter = this._adapters[integration.type];

			if (!Adapter) {
				this._logService.warn(`Not supported adapter type: "${integration.type}".`);

				return;
			}

			new Adapter(integration).init();
		});
	}
}
