import Task from 'models/task/Task';

/**
 * Class representing a content task.
 * @extends Task
 */
export default class ContentTask extends Task {
	/**
	 * @param {Object} task
	 */
	constructor(task) {
		super(task);

		this._content = task.content;
	}

	content() {
		return this._content;
	}
}
