import AchievementService from 'services/achievement/AchievementService';

import subServices from 'services/achievement/subServices';

export default {
	'services.achievementService': {
		class: AchievementService,
		deps: [{ $ref: 'services.APIService' }, { $ref: 'services.tagService' }, { $ref: 'services.currencyService' }],
		calls: [
			{
				name: 'registerSubService',
				args: ['standalone', { $ref: 'services.standaloneAchievementService' }],
			},
			{
				name: 'registerSubService',
				args: ['externallyAcquired', { $ref: 'services.externallyAcquiredAchievementService' }],
			},
		],
	},
	...subServices,
};
