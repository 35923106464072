import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import { isEmpty } from 'lodash';

import Login from './Login.template';
import loginIntegrations from './integrations';

import { Integration } from 'components/auth/Integration';
import OAuthIntegration from 'models/application/integration/OAuthIntegration';
import Logo from '../Logo';
import LanguageSwitcher from 'components/auth/LanguageSwitcher';

export class LoginComponent extends PureComponent {
	render() {
		return (
			<div className="integrations integrations__login">
				<div className="content">
					<LanguageSwitcher containerClassName="auth top-right" />
					<Logo />
					{this._view()}
				</div>
			</div>
		);
	}

	_view() {
		const integrations = this.props['services.applicationService'].applicationConfig().integrations();
		const { params } = this.props;

		if (params && params.id) {
			return Integration(loginIntegrations, integrations.get(params.id));
		}

		return (
			<Login
				OAuthIntegrations={integrations.loginIntegrations.filter(
					integration => integration instanceof OAuthIntegration
				)}
				otherIntegrations={integrations.loginIntegrations.filter(
					integration => !(integration instanceof OAuthIntegration)
				)}
				registrationEnabled={!isEmpty(integrations.registerIntegrations)}
			/>
		);
	}
}

export default wire(['services.applicationService'], LoginComponent);
