import KPIChallenge from 'views/challenge/types/KPI/KPIChallenge';

export default {
	'components.KPIChallenge': {
		factory: KPIChallenge,
		deps: [
			{ $ref: 'components.ChallengePrizeList' },
			{ $ref: 'components.ReactMarkdown' },
			{ $ref: 'components.Image' },
			{ $ref: 'services.navigationService' },
			{ $ref: 'services.ranking.rankingService' },
		],
	},
};
