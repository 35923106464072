import userProfileField from './userProfileField';
import UserService from 'services/user/UserService';
import UserProfileService from 'services/user/UserProfileService';

export default {
	...userProfileField,
	'services.userService': {
		class: UserService,
		deps: [{ $ref: 'services.APIService' }],
	},
	'services.userProfileService': {
		class: UserProfileService,
		deps: [
			{ $ref: 'services.APIService' },
			{ $ref: 'services.applicationService' },
			{ $ref: 'services.currencyService' },
			{ $ref: 'services.userProfileFieldService' },
		],
	},
};
