export default class ClosedQuestionService {
	constructor(questionAnswerService) {
		this._questionAnswerService = questionAnswerService;
	}

	toModel(question) {
		return {
			...question,
			answers: question.answers.map(answer => this._questionAnswerService.toModel(answer)),
		};
	}
}
