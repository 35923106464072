import common from 'components/task/common';
import layout from 'components/task/layout';
import taskTypes from 'components/task/types';
import TaskDetails from 'components/task/TaskDetails';

import UserProfileFieldsTask from './types/userProfileFields/UserProfileFieldsTask';

export default {
	...common,
	...layout,
	...taskTypes,
	'components.TaskDetails': {
		factory: TaskDetails,
		deps: [
			{ $ref: 'services.taskService' },
			{ $ref: 'services.browserHistoryService' },
			{ $ref: 'services.userProfileService' },
			{
				content_block: { $ref: 'components.task.Content' },
				document: { $ref: 'components.task.Document' },
				embedded_analytics: { $ref: 'components.task.EmbeddedAnalytics' },
				declaration: { $ref: 'components.DeclarationTask' },
				form: { $ref: 'components.FormTask' },
				join: { $ref: 'components.task.JoinTask' },
				mockup: { $ref: 'components.MockupTask' },
				visit_link: { $ref: 'components.VisitLinkTask' },
				image_multiple_choice_question: {
					$ref: 'components.ChoiceQuestionTask',
				},
				image_single_choice_question: {
					$ref: 'components.ChoiceQuestionTask',
				},
				text_multiple_choice_question: {
					$ref: 'components.ChoiceQuestionTask',
				},
				text_single_choice_question: {
					$ref: 'components.ChoiceQuestionTask',
				},
				// phrase
				complete_phrase_question: {
					$ref: 'components.CompletePhraseQuestionTask',
				},
				guess_phrase_question: {
					$ref: 'components.GuessPhraseQuestionTask',
				},
				retype_phrase_question: {
					$ref: 'components.RetypePhraseQuestionTask',
				},
				true_false_question: { $ref: 'components.TrueFalseTask' },
				order_question: { $ref: 'components.OrderTask' },
				user_profile_fields: UserProfileFieldsTask,
			},
		],
	},
};
