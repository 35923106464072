import MultipleChoiceQuestionTask from 'models/task/question/questionTask/choice/MultipleChoiceQuestionTask';

/**
 * Class representing a image multiple choice question task.
 * @extends MultipleChoiceQuestionTask
 */
export default class ImageMultipleChoiceQuestionTask extends MultipleChoiceQuestionTask {
	isImageQuestionTask() {
		return true;
	}
}
