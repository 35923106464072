import APIService from 'services/APIService';
import NavigationService from 'services/application/NavigationService';
import AccessTokenService from 'services/application/AccessTokenService';
import I18nService from 'services/application/I18nService';

import TokenIntegration, { TokenIntegrationDTO } from 'models/application/integration/TokenIntegration';

export default class TokenIntegrationService {
	public apiService: APIService;
	private _navigationService: NavigationService;
	private _accessTokenService: AccessTokenService;
	private _i18nService: I18nService;

	constructor(
		apiService: APIService,
		navigationService: NavigationService,
		accessTokenService: AccessTokenService,
		i18nService: I18nService
	) {
		this.apiService = apiService;
		this._navigationService = navigationService;
		this._accessTokenService = accessTokenService;
		this._i18nService = i18nService;
	}

	toModel(integration: TokenIntegrationDTO) {
		return new TokenIntegration(integration);
	}

	register(token: string, agreements: string[]): Promise<void> {
		return this.apiService
			.post<{ session: string }>(`v1/auth/token/register?authToken=${token}`, {
				agreements: agreements.map(agreement => ({ id: agreement })),
				locale: this._i18nService.locale(),
			})
			.then(({ session }) => {
				this._accessTokenService.setAccessToken('application', session);
				this._navigationService.load('/');
			});
	}
}
