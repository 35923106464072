import React, { FC, Fragment } from 'react';
import { TabPanelProps } from './TabPanel';

export const TabPanels: FC = ({ children }) => {
	return (
		<Fragment>
			{React.Children.toArray(children)
				.filter(child => React.isValidElement(child))
				.map((child, index) => {
					return React.cloneElement(child as React.ReactElement<TabPanelProps>, {
						id: index,
					});
				})}
		</Fragment>
	);
};
