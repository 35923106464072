import Notification from 'models/notification/Notification';

/**
 * Class representing a item notification.
 * @extends Notification
 */
export default class ItemNotification extends Notification {
	/**
	 * @param {Object} notification
	 * @param {Object} images
	 */
	constructor(notification, images) {
		super(notification);

		this._images = images;
		this._backpackItem = notification.backpack_item;
		this._item = this._backpackItem && this._backpackItem.item;
		this._boughtRewardCafeteriaItem = this._backpackItem && this._backpackItem.boughtRewardCafeteriaItem;
		this._earnings = notification.costs;

		this._removed = !this._backpackItem || (!this._item && !this._boughtRewardCafeteriaItem);
		this._type = 'item';
	}

	backpackItem() {
		return this._backpackItem || {};
	}

	item() {
		return this._item || this._boughtRewardCafeteriaItem || {};
	}

	getMessage() {
		if (this._removed) {
			return super.getMessage('null-states.item');
		}

		return super.getMessage('messages.item', {
			name: this.item().name,
		});
	}

	getDate(params) {
		return super.getDate('dates.item', {
			values: {
				date: Notification.getFormettedDate(this.backpackItem().bought_at),
			},
			...params,
		});
	}

	getIconPath() {
		return super.getIconPath(this._images.example.awardIcon);
	}
}
