import _ from 'lodash';
import moment from 'moment';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/pl';
import 'moment/locale/fr';

import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-GB';
import es from 'date-fns/locale/es';
import pl from 'date-fns/locale/pl';
import fr from 'date-fns/locale/fr';

import messages from 'i18n';

import flattenObject from 'utils/flattenObject';

export default class I18nService {
	constructor(localStorage, applicationService, userService, navigationService, translationService) {
		this._localStorage = localStorage;
		this._applicationService = applicationService;
		this._userService = userService;
		this._navigationService = navigationService;
		this._translationService = translationService;

		this._messages = {};
		this._filteredMessages = {};
		this._availableLocales = [];
		this._locale = this._localStorage.getItem('locale');

		for (let key in messages) {
			if (messages.hasOwnProperty(key)) {
				this._messages[key] = flattenObject(messages[key], undefined, [value => Array.isArray(value)]);
				this._filteredMessages[key] = flattenObject(messages[key]);
			}
		}
	}

	init() {
		let backendDefaultLocale = this._applicationService
			.settings()
			.locales()
			.defaultLocale(); // back-end default locale
		let navigatorLocales = navigator.languages;
		let frontendDefaultLocale = 'pl';
		let frontendAvailableLocales = _.keys(this._messages);

		this._availableLocales = this._applicationService
			.settings()
			.locales()
			.available();

		let locales = _.intersection(navigatorLocales, this._availableLocales);
		let currentNavigatorLocale = _.first(locales); // default locale == first in the locale list

		// date locales:
		registerLocale('de', de);
		registerLocale('en', en);
		registerLocale('es', es);
		registerLocale('pl', pl);
		registerLocale('fr', fr);

		//NOTE: order: user, browser-first, settings-default
		if (_.includes(this._availableLocales, this._locale)) {
			this.setLocale(this._locale);
		} else {
			let userLocale = this._userService.isLogged() && this._userService.user().locale();

			if (_.includes(this._availableLocales, userLocale)) {
				this.setLocale(userLocale);
			} else if (_.includes(this._availableLocales, currentNavigatorLocale)) {
				this.setLocale(currentNavigatorLocale);
			} else if (_.includes(this._availableLocales, backendDefaultLocale)) {
				this.setLocale(backendDefaultLocale);
			} else if (_.includes(this._availableLocales, frontendDefaultLocale)) {
				this.setLocale(frontendDefaultLocale);
			} else {
				throw new Error('Unsupported locale.');
			}
		}

		if (!_.includes(frontendAvailableLocales, this._locale)) {
			this._messages[this._locale] = _.merge(
				{},
				this._messages.en || this._messages[frontendAvailableLocales[0]]
			);
		}

		if (this._userService.isLogged()) {
			return this._userService.setLocale(this._locale);
		}
	}

	async fetchTranslation() {
		const translation = await this._translationService.fetchTranslation(this._locale);
		const messages = flattenObject(translation);

		this._messages[this._locale] = _.merge({}, this._messages[this._locale], messages);
	}

	filteredMessages(locale = this._locale) {
		return this._filteredMessages[locale];
	}

	locale() {
		return this._locale;
	}

	availableLocales() {
		return this._availableLocales;
	}

	setLocale(locale) {
		this._locale = locale;
		moment.locale(locale);
		setDefaultLocale(locale);
		this._localStorage.setItem('locale', locale);
	}

	fetchMessages(lang) {
		return this._messages[lang || this._locale];
	}

	changeLocale(locale, request = true) {
		this.setLocale(locale);

		if (request) {
			this._userService.setLocale(locale).then(() => this._navigationService.reload());
		} else {
			this._navigationService.reload();
		}
	}
}
