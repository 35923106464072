import { ImportedRankingEntry } from './ImportedRankingEntry';
import { Container } from '../../../container/Container';

/**
 * Class representing a container imported ranking entry.
 * @extends ImportedRankingEntry
 */
export class ContainerImportedRankingEntry extends ImportedRankingEntry {
	constructor(entry) {
		super(entry);

		/**
		 * @type {Container}
		 */
		this.container = new Container(entry.container);
	}
}
