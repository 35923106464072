import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { Markdown } from 'components/_/common/text/markdown/Markdown';

export default function(taskService, Layout) {
	return class VisitLinkExternal extends PureComponent {
		async componentDidMount() {
			const { task } = this.props;

			if (task.viewWhenComplete() && task.state().hasStatus('completed')) {
				return;
			}

			await taskService.processTask(task, { start: true });
		}

		render() {
			const { task, isCompleted, hasDescription, goBack, inProcess } = this.props;

			const infoAfterCompletion = (
				<div>
					<div className="fs-4">
						<FormattedMessage id="task-details.visit-link.content.instruction-after" />
					</div>
					<div className="fs-6" style={{ wordBreak: 'break-all' }}>
						<a href={task.linkUrl()} target="_blank" rel="noopener noreferrer">
							{task.linkUrl()}
						</a>
					</div>
				</div>
			);

			const infoBeforeCompletion = (
				<div>
					<div className="fs-4 mb-3">
						<FormattedMessage id="task-details.visit-link.content.instruction-before" />
					</div>
					<div className="fs-6" style={{ wordBreak: 'break-all' }}>
						{task.linkUrl()}
					</div>
				</div>
			);

			return (
				<Layout
					task={task}
					actions={{
						submit: {
							handler: () => this._finish(),
							buttonText: 'task-details.common.button.do',
						},
						goBack,
						tryAgain: () => this._tryAgain(),
						isSubmitting: inProcess,
					}}
				>
					<div className="task--visitLink visitLink--external">
						<div className="d-flex flex-column align-items-center text-center">
							<div className="mb-3 w-100">
								{hasDescription && <Markdown className="mb-4 fs-6">{task.description()}</Markdown>}
								{task.isCompleted() || isCompleted ? infoAfterCompletion : infoBeforeCompletion}
							</div>
							{task.image() && (
								<div className="flex-grow-1" style={{ flexBasis: 0 }}>
									<img
										src={task.image()}
										alt={task.image()}
										className="rounded"
										style={{
											maxWidth: '600px',
											minWidth: '250px',
											width: '100%',
										}}
									/>
								</div>
							)}
						</div>
					</div>
				</Layout>
			);
		}

		_finish() {
			const { task } = this.props;

			window.open(task.linkUrl(), '_blank');

			return this.props.process({ finish: true });
		}

		_tryAgain() {
			this.props.task.reset();
			this.forceUpdate();
		}
	};
}
