export default class GraphInteractionService {
	interactionHandlers = {};

	addInteractionHandler(type, handler) {
		if (type in this.interactionHandlers) {
			throw new Error(`A handler for type »${type}« is already registered.`);
		}

		this.interactionHandlers[type] = handler;
	}

	handleInteraction(interaction) {
		const { type } = interaction;

		if (type in this.interactionHandlers) {
			this.interactionHandlers[type].handle(interaction);
		}
	}
}
