import Notification from 'models/notification/Notification';

/**
 * Class representing a level up notification.
 * @extends Notification
 */
export default class LevelUpNotification extends Notification {
	/**
	 * @param {Object} notification
	 */
	constructor(notification) {
		super(notification);

		this._level = notification.level;
		this._type = 'levelUp';
	}

	level() {
		return this._level;
	}

	getMessage() {
		return super.getMessage(`messages.levelUp.${this._level.name() ? 'withName' : 'withoutName'}`, {
			name: this._level.name(),
			levelNumber: this._level.levelNumber(),
		});
	}

	getIconPath() {
		return this._level.image();
	}

	imageAlternativeText() {
		return this._level.levelNumber();
	}
}
