import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import { Formik } from 'formik';
import queryString from 'query-string';
import { injectIntl } from 'react-intl';

import ImportedId from './ImportedId.template';
import { schema } from './ImportedId.schema';

import VerifyToken from './verify-token/VerifyToken';
import Info from '../../../Info';
import AuthorizeCode from './authorize-code/AuthorizeCode';
import Register from '../../../login/integrations/imported-id/register/Register';

export class ImportedIdComponent extends PureComponent {
	render() {
		const { token } = queryString.parse(window.location.search);
		const { integration, intl } = this.props;

		if (token) {
			return <VerifyToken token={token} integration={integration} />;
		}

		return (
			<Formik
				initialValues={{
					identifier: '',
				}}
				validationSchema={schema}
				onSubmit={(...args) => this.reset(...args)}
				render={props => {
					if (props.status === 'email-sent') {
						return (
							<Info
								title={intl.formatMessage({
									id: 'auth.reset-password.integration.imported-id.status.email-sent.title',
								})}
								message={intl.formatMessage({
									id: 'auth.reset-password.integration.imported-id.status.email-sent.message',
								})}
							/>
						);
					}

					if (props.status === 'sms-sent') {
						return <AuthorizeCode integration={integration} identifier={props.values.identifier} />;
					}

					if (props.status === 'create-account') {
						return <Register integration={this.props.integration} identifier={props.values.identifier} />;
					}

					return <ImportedId {...props} {...this.props} />;
				}}
			/>
		);
	}

	reset(values, actions) {
		const { integration } = this.props;

		return this.props['services.importedIdIntegrationService']
			.check({
				id: integration.id,
				...values,
			})
			.then(() => {
				return this.props['services.importedIdIntegrationService']
					.resetPasswordStart({
						id: this.props.integration.id,
						...values,
					})
					.then(type => actions.setStatus(type === 'email' ? 'email-sent' : 'sms-sent'))
					.catch(error => actions.setFieldError('identifier', error));
			})
			.catch(error => {
				if (error === 'user-not-verified') {
					actions.setStatus('create-account');
				} else {
					actions.setFieldError('identifier', error);
				}
			})
			.finally(() => actions.setSubmitting(false));
	}
}

export default injectIntl(wire(['services.importedIdIntegrationService'], ImportedIdComponent));
