import RGBA from './color/RGBA';

export default class LandingPage {
	constructor(config) {
		this.action = config.action || 'login';
		this.headline = config.headline;
		this.description = config.description;
		this.buttonText = config.button_text;
		this.logo = config.logo;
		this.backgroundImage = config.background_image;

		const content = config.color_scheme.content;
		const button = config.color_scheme.button;

		this.colorScheme = {
			content: {
				foreground: new RGBA(content.foreground).toString(true),
				background: new RGBA(content.background).toString(true),
			},
			button: {
				foreground: new RGBA(button.foreground).toString(true),
				background: new RGBA(button.background).toString(true),
			},
		};
	}
}
