export interface GoogleAnalyticsDTO {
	id: string;
	name: string;
	cookieDomain: string;
	events: string[];
}

export default class GoogleAnalytics {
	private _id: string;
	private _name: string;
	private _cookieDomain: string;
	private _events: string[];

	constructor(googleAnalytics: GoogleAnalyticsDTO) {
		this._id = googleAnalytics.id;
		this._name = googleAnalytics.name;
		this._cookieDomain = googleAnalytics.cookieDomain || 'auto';
		this._events = ['authentication', 'logout'];
	}

	id() {
		return this._id;
	}

	name() {
		return this._name;
	}

	cookieDomain() {
		return this._cookieDomain;
	}

	events() {
		return this._events;
	}
}
