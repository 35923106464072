import { Component } from 'react';
import _ from 'lodash';

export default function() {
	return class BaseAutoScrollingText extends Component {
		_elementRefs = {};

		componentDidMount() {
			if (window.innerWidth > 640) {
				return;
			}

			setTimeout(() => {
				this._animateVerticalScroll();
			}, 500);
		}

		_animateVerticalScroll() {
			const { scrollingContainer, text } = this._elementRefs;
			const scrollWidth = text.offsetWidth - scrollingContainer.offsetWidth;

			if (scrollingContainer.scrollLeft >= scrollWidth) {
				this._stopScrollingAnimation();

				return;
			}

			scrollingContainer.scrollLeft = _.add(
				_.parseInt(scrollingContainer.scrollLeft),
				BaseAutoScrollingText.STEP
			);

			this.__cachedAnimation = requestAnimationFrame(() => this._animateVerticalScroll());
		}

		_setReference(name, element) {
			this._elementRefs[name] = element;
		}

		_stopScrollingAnimation() {
			window.cancelAnimationFrame(this.__cachedAnimation);
		}

		static STEP = 2;
	};
}
