import React from 'react';

import ImpersonateUserInfoBar from 'components/auth/ImpersonateUserInfoBar';

export default function Layout3({ children }) {
	return <section className="middle wrapper--col">{children}</section>;
}

export function buildLayout3(Component) {
	return props => (
		<ImpersonateUserInfoBar>
			<Layout3>
				<Component {...props} />
			</Layout3>
		</ImpersonateUserInfoBar>
	);
}
