import React from 'react';
import { wire } from 'react-hot-wire';

import { TeamProfileTask } from './Tasks.template';

import Loader from 'components/commons/_/loader/Loader';
import useFetch from 'components/_/hooks/useFetch';

const TeamProfileTasks = props => {
	const declarationTaskService = props['services.declarationTaskService'];
	const { response: list, loading, error } = useFetch(() => declarationTaskService.repetitionStates(), []);

	return loading ? (
		<Loader />
	) : error ? (
		<div>error {JSON.stringify(error, null, '    ')}</div>
	) : (
		<div className="tasks p-2 m-0 mr-md-4">
			{list
				.filter(repetitionState => repetitionState.acceptanceState() !== 'open')
				.map(repetitionState => (
					<TeamProfileTask
						key={repetitionState.id()}
						repetitionState={repetitionState}
						confirm={() => declarationTaskService.accept({ id: repetitionState.id() })}
						reject={reason =>
							declarationTaskService.reject({
								id: repetitionState.id(),
								rejectionReason: reason,
							})
						}
					/>
				))}
		</div>
	);
};

export default wire(['services.declarationTaskService'], TeamProfileTasks);
