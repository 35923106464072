import { addLocaleData } from 'react-intl';

//TODO: locale
import deLocaleData from 'react-intl/locale-data/de';
import enLocaleData from 'react-intl/locale-data/en';
import esLocaleData from 'react-intl/locale-data/es';
import plLocaleData from 'react-intl/locale-data/pl';
import frLocaleData from 'react-intl/locale-data/fr';

addLocaleData([
	...deLocaleData,
	...enLocaleData,
	...esLocaleData,
	...plLocaleData,
	...frLocaleData,
	{
		...plLocaleData[0],
		locale: 'szl',
	},
]);
