import TaskBaseService from 'services/task/TaskBaseService';

import VisitLinkTask from 'models/task/VisitLinkTask';

export default class VisitLinkTaskService extends TaskBaseService {
	toModel(task) {
		return new VisitLinkTask({
			...task,
			state: this._mapState(task),
		});
	}
}
