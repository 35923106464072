import { Component } from 'react';
import PropTypes from 'prop-types';

export default function(teamService) {
	return class ChooseContainer extends Component {
		state = {
			value: '',
			list: [],
		};

		componentDidMount() {
			if (this.props.disabled) {
				return;
			}

			this.search();
		}

		search({ search } = {}) {
			return teamService.searchContainers({ ...this.props, search }).then(list =>
				this.setState({
					list,
				})
			);
		}

		_onChange(value) {
			this.props.handler(value);
		}

		_autoCompleteProperties() {
			return {
				wrapperStyle: {},
				value: this.state.value,
				items: this.state.list,
				getItemValue: item => item.name,
				onSelect: (value, item) =>
					this.setState(
						{
							value: item.name,
							list: [],
						},
						() => this._onChange(item)
					),
				onChange: (event, value) => {
					this.setState({
						value,
						list: [],
					});

					if (!value) {
						this._onChange();
					}

					this.search({
						search: value,
					});
				},
			};
		}

		static propTypes = {
			group: PropTypes.string,
			codeName: PropTypes.string,
			disabled: PropTypes.bool,
			handler: PropTypes.func,
			placeholder: PropTypes.string,
		};
	};
}
