import TaskBaseService from 'services/task/TaskBaseService';

import CompletePhraseQuestionTask from 'models/task/question/questionTask/phrase/CompletePhraseQuestionTask';

export default class CompletePhraseQuestionTaskService extends TaskBaseService {
	toModel(task) {
		return new CompletePhraseQuestionTask({
			...task,
			state: this._mapState(task),
		});
	}
}
