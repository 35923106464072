import React from 'react';
import PropTypes from 'prop-types';

export default (NotificationTemplate, NextTaskButton, DefaultButton) => {
	function missionNotification(props) {
		const { notification } = props;
		const Button = notification.task().nextTask() ? NextTaskButton : DefaultButton;

		return <NotificationTemplate {...props} button={Button} />;
	}

	missionNotification.propTypes = {
		notification: PropTypes.object,
	};

	return missionNotification;
};
