import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default function(MatchingImageType) {
	return class BaseMatchingImageType extends Component {
		_image = React.createRef();
		_imageWrapper = React.createRef();
		state = {
			backgroundMode: 'background',
			containerSize: {},
			imageRatio: undefined,
		};

		componentDidMount() {
			window.addEventListener('resize', (this.__cachedResizeEvent = () => this._updateWrapperSize()));
		}

		componentWillUnmount() {
			window.removeEventListener('resize', this.__cachedResizeEvent);
		}

		render() {
			return (
				<MatchingImageType
					{...this.props}
					setImageType={e => this._setImageType(e)}
					imageRef={this._image}
					imageWrapperRef={this._imageWrapper}
					borderSize={this._borderSize()}
					backgroundMode={this.state.backgroundMode}
				/>
			);
		}

		_updateWrapperSize() {
			this.setState({
				containerSize: this._imageWrapper.current.getBoundingClientRect(),
			});
		}

		_borderSize() {
			const { width, height } = this.state.containerSize;
			const ratio = this.state.imageRatio;
			const isFullyHorizontalStretch = height * ratio > width;

			if (!ratio) {
				return;
			}

			return {
				width: (isFullyHorizontalStretch ? width : height * ratio).toFixed(),
				height: (isFullyHorizontalStretch ? width / ratio : height).toFixed(),
			};
		}

		_setImageType() {
			const image = this._image.current.ref().current;

			if (image.naturalWidth >= image.naturalHeight) {
				this.setState({
					backgroundMode: 'image',
				});
			} else {
				this.setState({
					containerSize: this._imageWrapper.current.getBoundingClientRect(),
					imageRatio: (image.naturalWidth / image.naturalHeight).toFixed(2),
				});
			}
		}

		static propTypes = {
			src: PropTypes.string,
			classes: PropTypes.string,
		};
	};
}
