import IsAMemberAlready from 'components/task/types/join/join/IsAMemberAlready';
import IsAnonymous from 'components/task/types/join/join/IsAnonymous';
import JoinTaskCrashed from 'components/task/types/join/join/JoinTaskCrashed';
import TeamJoinView from 'components/task/types/join/join/TeamJoinView';
import UserContainerJoinView from 'components/task/types/join/join/UserContainerJoinView';

export default {
	'components.task.JoinTask.IsAMemberAlready': {
		factory: IsAMemberAlready,
	},
	'components.task.JoinTask.IsAnonymous': {
		factory: IsAnonymous,
	},
	'components.task.JoinTask.JoinTaskCrashed': {
		factory: JoinTaskCrashed,
	},
	'components.task.JoinTask.TeamJoinView': {
		factory: TeamJoinView,
		deps: [
			{ $ref: 'components.EntryBlocks.TeamAssignment.ChooseContainer' },
			{ $ref: 'components.EntryBlocks.TeamAssignment.ChooseTeam' },
		],
	},
	'components.task.JoinTask.UserContainerJoinView': {
		factory: UserContainerJoinView,
		deps: [{ $ref: 'components.EntryBlocks.TeamAssignment.ChooseContainer' }],
	},
};
