import TextMultipleChoiceQuestionTaskService from 'services/task/subServices/questionTask/choice/text/TextMultipleChoiceQuestionTaskService';
import TextSingleChoiceQuestionTaskService from 'services/task/subServices/questionTask/choice/text/TextSingleChoiceQuestionTaskService';

export default {
	'services.textMultipleChoiceQuestionTaskService': {
		class: TextMultipleChoiceQuestionTaskService,
		deps: [{ $ref: 'services.taskStateService' }],
	},
	'services.textSingleChoiceQuestionTaskService': {
		class: TextSingleChoiceQuestionTaskService,
		deps: [{ $ref: 'services.taskStateService' }],
	},
};
