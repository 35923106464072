import React, { Fragment, PropsWithChildren } from 'react';
import classnames from 'classnames';

import useStyles from './ProgressBar.jss';

export interface ProgressBarProps {
	progress: number;
	duration?: string;
	transparent?: boolean;
	className?: string;
	tooltip?: boolean;
	progressBarColor?: string;
	border?: number;
	height?: number;
	innerPadding?: number;
}

export const ProgressBar = ({
	progress,
	transparent,
	className,
	children,
	tooltip,
	progressBarColor,
	border,
	height,
	innerPadding,
	duration = '1s',
}: PropsWithChildren<ProgressBarProps>) => {
	const classes = useStyles({
		progress: Math.ceil(progress),
		duration,
		progressBarColor,
		border,
		height,
		innerPadding,
	})();

	return (
		<Fragment>
			<div className={classnames(classes.container, transparent && classes.transparent, className)}>
				<div className={classes.progressBar}>
					{tooltip && <div className={classes.tooltip}>{Math.ceil(progress)}%</div>}
				</div>
			</div>
			{children && <div className={classnames(classes.text)}>{children}</div>}
		</Fragment>
	);
};
