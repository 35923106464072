import React, { Fragment, PureComponent } from 'react';
import { injectIntl } from 'react-intl';

import { Markdown } from 'components/_/common/text/markdown/Markdown';

export default function(ChooseContainer) {
	return injectIntl(
		class UserContainerJoinView extends PureComponent {
			state = {
				container: null,
			};

			render() {
				const { task, intl } = this.props;

				const userContainerGroup = task.containerGroup();
				const containerPrompt = task.containerPrompt();

				return (
					<Fragment>
						<div className="description fs-6">
							<Markdown>{task.description()}</Markdown>
						</div>
						<div
							className="title"
							data-text={intl.formatMessage({
								id: 'task-details.join.user-container-join-view.title',
							})}
						/>
						<div className="fields">
							<div className="field">
								<ChooseContainer
									group="userContainerGroup"
									placeholder={containerPrompt}
									codeName={userContainerGroup.id}
									disabled={task.isSubmitted()}
									handler={container => this._onChange(container)}
								/>
							</div>
						</div>
					</Fragment>
				);
			}

			_onChange(container) {
				this.setState({ container });
				this.props.onChange(container);
			}
		}
	);
}
