import Process from 'models/onboarding/process/Process';
import ProcessService from 'services/onboarding/process/ProcessService';
import { useQuery } from 'react-query';
import { EnsureResponseData } from 'utils/type/query/EnsureResponseData';

export type UseGetProgressProps = {
	'services.onboardingProcessService': ProcessService;
};

export type UseGetProgressResult = EnsureResponseData<{ items: Process[] }>;

const defaultData = { items: [] };

export const useGetProgress = (props: UseGetProgressProps): UseGetProgressResult => {
	const processService = props['services.onboardingProcessService'];
	const progress = useQuery<{ items: Process[] }>(
		['portal-progress'],
		() =>
			processService.fetchList().then(response => ({
				items: response.items.filter(process => process.progress < 100),
			})),
		{ cacheTime: 0 }
	);

	return {
		...progress,
		data: Object.assign({}, defaultData, progress.data),
	};
};

export default useGetProgress;
