import ChallengeSubmissionService from 'services/challenge/submission/ChallengeSubmissionService';
import ChallengeSubmissionJudgeService from 'services/challenge/submission/ChallengeSubmissionJudgeService';
import subServices from 'services/challenge/submission/subServices';

export default {
	'services.challengeSubmissionService': {
		class: ChallengeSubmissionService,
		deps: [{ $ref: 'services.APIService' }],
		calls: [
			{
				name: 'registerSubService',
				args: ['imagesubmission', { $ref: 'services.challengeImageSubmissionService' }],
			},
			{
				name: 'registerSubService',
				args: ['textsubmission', { $ref: 'services.challengeTextSubmissionService' }],
			},
		],
	},
	'services.challengeSubmissionJudgeService': {
		class: ChallengeSubmissionJudgeService,
		deps: [{ $ref: 'services.APIService' }],
	},
	...subServices,
};
