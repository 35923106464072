import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Media from 'react-media';

import RankingUserPosition from 'components/ranking/_/user-position/UserPosition';
import { Markdown } from 'components/_/common/text/markdown/Markdown';

export const KPIChallengeDetails = ({ challenge, ReactMarkdown }) => {
	return (
		<div style={{ fontSize: '1rem' }}>
			<div className="challengeDetails__details">
				<Media query={{ minWidth: 768 }}>
					{matches =>
						matches && (
							<Fragment>
								<div className="sectionTitle">
									<FormattedMessage id="challenge-details.kpi.section-title.challenge-info" />
								</div>
								<div className="challengeDetails__detailsRow wrapper--row justify--between align--center">
									<FormattedMessage id="challenge-details.kpi.content.challenge-info.challenge-type" />
									<FormattedMessage id="challenge-details.kpi.content.challenge-info.kpi-type" />
								</div>
							</Fragment>
						)
					}
				</Media>
				<div className="challengeDetails__detailsRow wrapper--row justify--between align--center">
					<FormattedMessage id="challenge-details.kpi.content.challenge-info.participation-mode" />
					<FormattedMessage id={`challenge-list.type.${challenge.participationMode()}`} />
				</div>
				{challenge.lastImportDate && (
					<div className="challengeDetails__detailsRow wrapper--row justify--between align--center">
						<FormattedMessage id="challenge-details.kpi.content.challenge-info.last-import-date" />
						<span>{challenge.lastImportDate}</span>
					</div>
				)}
				<div className="challengeDetails__detailsRow wrapper--row justify--between align--center hidden">
					<FormattedMessage id="challenge-details.kpi.content.challenge-info.submissions-limit" />
					<div>10</div>
				</div>
			</div>
			<RankingUserPosition ranking={challenge.ranking()} />
			{challenge.description() && (
				<div className="fs-4">
					<Markdown>{challenge.description()}</Markdown>
				</div>
			)}
		</div>
	);
};
