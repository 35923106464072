import BaseService from 'services/BaseService';

export default class QuestionAnswerService extends BaseService {
	_type = 'questionAnswer';
	_typeKey = 'type';

	constructor(logService) {
		super();

		this._logService = logService;
	}

	toModel(answer) {
		try {
			return super.toModel(answer);
		} catch (error) {
			this._logService.warn(error);
		}
	}
}
