import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';

import { SettingField } from 'components/_/form/setting-field/SettingField';
import { CustomUserProfileFieldDefinition } from 'models/user/userProfileField/definition/CustomUserProfileFieldDefinition';

function renderField({ field, intl, editable, onFieldChange, ...props }) {
	const userProfileService = props['services.userProfileService'];
	const fieldProps = {
		label: null,
		name: null,
		defaultValue: field.value(),
		editable: editable && field.definition().userEditable(),
		type: field.definition().valueType() === 'text' ? 'textarea' : 'input',
		valueType: field.definition().valueType(),
	};

	if (field.definition() instanceof CustomUserProfileFieldDefinition) {
		fieldProps.label = field.definition().name();
		fieldProps.name = field.definition().name();
		fieldProps.onSave = async value => {
			await userProfileService.editUserProfileField({ field, value });
			onFieldChange(field.definition().name(), value);
		};
	} else {
		fieldProps.label = intl.formatMessage({
			id: `user-profile.field.mapped.${field.definition().mappedProfileField()}`,
		});
		fieldProps.name = field.definition().mappedProfileField();
		fieldProps.onSave = async value => {
			await userProfileService.editUserProfileField({ field, value });
			onFieldChange(field.definition().mappedProfileField(), value);
		};
	}

	return <SettingField {...fieldProps} inForm={false} />;
}

export default injectIntl(({ fields, ...props }) => (
	<Fragment>
		<hr className="mb-2 mb-sm-3" />
		<div className="p-2 m-0 mr-md-4">
			<div className="row no-gutters">
				{fields.include(['text']).map(field =>
					!field.value() && !(props.editable && field.definition().userEditable()) ? null : (
						<div className="col-24 mb-4" key={field.definition().id()}>
							{renderField({
								field,
								...props,
							})}
						</div>
					)
				)}
			</div>
			<div className="row mb-4 pb-2">
				{fields.exclude(['text', 'image']).map(field =>
					!field.value() && !(props.editable && field.definition().userEditable()) ? null : (
						<div className="col-24 col-md-12 mb-2" key={field.definition().id()}>
							{renderField({
								field,
								...props,
							})}
						</div>
					)
				)}
			</div>
		</div>
	</Fragment>
));
