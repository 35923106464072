import TransactionLinkService from 'services/application/transaction/transactionLink/TransactionLinkService';

import subServices from 'services/application/transaction/transactionLink/subServices';

export default {
	'services.transactionLinkService': {
		class: TransactionLinkService,
		calls: [
			{
				name: 'registerSubService',
				args: ['target_challenge', { $ref: 'services.targetChallengeTransactionLinkService' }],
			},
		],
	},
	...subServices,
};
