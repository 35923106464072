import React from 'react';

export default () => ({ onCheck }) => (
	<div className="buttons buttons--plusMinus">
		<button type="button" onClick={() => onCheck(true)}>
			+
		</button>
		<button type="button" onClick={() => onCheck(false)}>
			-
		</button>
	</div>
);
