import TargetChallenge from 'models/challenge/challenge/TargetChallenge';
import TargetChallengeState from 'models/challenge/challenge/state/TargetChallengeState';

import ChallengeBaseService from 'services/challenge/challenge/ChallengeBaseService';

export default class TargetChallengeService extends ChallengeBaseService {
	constructor(currencyService) {
		super();
		this._currencyService = currencyService;
	}

	toModel(challenge) {
		return new TargetChallenge({
			...challenge,
			submission_earnings: this._currencyService.toModel(challenge.submission_earnings),
			state: challenge.state && new TargetChallengeState(challenge.state),
		});
	}
}
