import LandingPage from 'views/application/landingPage/LandingPage';
import Logo from 'views/application/landingPage/Logo';

export default {
	'component.LandingPage': {
		factory: LandingPage,
		deps: [{ $ref: 'component.Logo' }, { $ref: 'components.Image' }, { $ref: 'services.applicationService' }],
	},
	'component.Logo': {
		factory: Logo,
	},
};
