import React, { useCallback } from 'react';
import { wire } from 'react-hot-wire';
import { Formik } from 'formik';
import queryString from 'query-string';
import { injectIntl } from 'react-intl';

import Email from './Email.template';
import { schema } from './Email.schema';

import VerifyToken from './verify-token/VerifyToken';
import Info from '../../../Info';

export const EmailComponent = props => {
	const { integration, intl, 'services.emailIntegrationService': emailIntegrationService } = props;
	const { token } = queryString.parse(window.location.search);
	const reset = useCallback(
		(values, actions) => {
			emailIntegrationService
				.resetPasswordStart({
					id: integration.id,
					...values,
				})
				.then(() => actions.setStatus('ok'))
				.catch(error => {
					actions.setFieldError('email', error);
					actions.setSubmitting(false);
				});
		},
		[integration]
	);

	if (token) {
		return <VerifyToken token={token} integration={integration} action="reset-password" />;
	}

	return (
		<Formik
			initialValues={{
				email: '',
			}}
			validationSchema={schema}
			onSubmit={(...args) => reset(...args)}
			render={formikProps => {
				if (formikProps.status === 'ok') {
					const type = integration.annoying ? 'email-annoying' : 'email';

					return (
						<Info
							title={intl.formatMessage({
								id: `auth.reset-password.integration.${type}.status.ok.title`,
							})}
							message={intl.formatMessage({
								id: `auth.reset-password.integration.${type}.status.ok.message`,
							})}
						/>
					);
				}

				return <Email {...formikProps} {...props} />;
			}}
		/>
	);
};

export default injectIntl(wire(['services.emailIntegrationService'], EmailComponent));
