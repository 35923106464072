import choice from 'services/task/subServices/questionTask/choice';
import order from 'services/task/subServices/questionTask/order';
import phrase from 'services/task/subServices/questionTask/phrase';
import trueFalse from 'services/task/subServices/questionTask/trueFalse';

export default {
	...choice,
	...order,
	...phrase,
	...trueFalse,
};
