import Notification from 'models/notification/Notification';

/**
 * Class representing a challenge submission notification.
 * @extends Notification
 */
export default class ChallengeSubmissionAddedNotification extends Notification {
	constructor(notification, images) {
		super(notification);

		this._images = images;
		this._submission = notification.submission;
		this._removed = !this._submission;
		this._type = 'challenge_submission_added';
		this._earnings = notification.submission_earnings;
		this._noti = notification;
	}

	getIconPath() {
		return super.getIconPath(this._images.icon.staticNotificationTypes.challengeSubmission.accepted);
	}

	earnings() {
		return this._earnings;
	}

	getMessage() {
		if (this._removed) {
			return super.getMessage('null-states.submission');
		}

		const { challenge } = this._submission;

		return super.getMessage('messages.submission.addedSubmission', {
			submissionTitle: this._submission.title(),
			challengeTitle: challenge.title(),
		});
	}

	getDate(params) {
		return super.getDate(`dates.submission.${this._submission.status()}`, params);
	}
}
