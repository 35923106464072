import _ from 'lodash';
import queryString from 'query-string';
import chroma from 'chroma-js';

import ColorDefinition from 'models/application/color/ColorDefinition';

export default class ColorService {
	_definitions = {};
	_variables = {};

	constructor(applicationService, cssTransformService, colorsConfig) {
		this._applicationService = applicationService;
		this._cssTransformService = cssTransformService;
		this._colorsConfig = colorsConfig;
	}

	definitions() {
		return this._definitions;
	}

	variables() {
		return this._variables;
	}

	init() {
		return this.swapColorScheme(this._applicationService.settings().colorScheme());
	}

	swapColorScheme(colorScheme) {
		_.each(['content', 'primary', 'interactive'], name => {
			this._setDefinition(`color--${name}--background`, {
				color: colorScheme.get(name).background() || this._colorsConfig[name].background.fallback,
				alphas: this._colorsConfig[name].background.alphas,
			});
			this._setDefinition(`color--${name}--foreground`, {
				color: colorScheme.get(name).foreground() || this._colorsConfig[name].foreground.fallback,
				alphas: this._colorsConfig[name].foreground.alphas,
			});
		});

		return this._cssTransformService.runTransformersPipe(this.variables());
	}

	/**
	 * @param {string} fullName - e.g.: color--primary--background
	 * @param {string} color - #FFF
	 *
	 * @returns {Promise}
	 */
	update(fullName, color) {
		const [, name, type] = fullName.split('--');

		this._setDefinition(fullName, {
			color,
			alphas: this._colorsConfig[name][type].alphas,
		});

		return this._cssTransformService.runTransformersPipe(this.variables());
	}

	isPaletteEnabled() {
		return _.has(queryString.parse(window.location.search), 'palette');
	}

	reverseColor(color) {
		const luminance = chroma(color).luminance();

		if (luminance > 0.5) {
			return chroma(color).darken(5 * luminance);
		} else {
			return chroma(color).brighten(5 * (1 - luminance));
		}
	}

	_setDefinition(name, { color, alphas }) {
		const definition = new ColorDefinition(name, { color, alphas });
		this._definitions[name] = definition;
		this._variables[definition.name()] = definition.rgb().toString();

		_.each(definition.alphas(), alpha => {
			this._variables[`${definition.name()}${alpha.value()}`] = alpha.toString();
		});
	}
}
