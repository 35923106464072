import React, { PureComponent } from 'react';
import _ from 'lodash';
import { Form, Formik } from 'formik';

export default function(Layout, types) {
	return class BaseContentTask extends PureComponent {
		render() {
			const { task, goBack } = this.props;

			return (
				<Formik
					initialValues={{}}
					onReset={() => this.props.task.reset()}
					onSubmit={(values, actions) => this.props.process({}).then(() => actions.setSubmitting(false))}
				>
					{({ handleReset, isSubmitting }) => (
						<Form className="form d-flex flex-grow-1 w-100">
							<Layout
								task={task}
								actions={{
									submit: {
										buttonText: 'task-details.common.button.confirm',
									},
									goBack,
									tryAgain: handleReset,
									isSubmitting,
								}}
							>
								<div className="contentBlocks overflow--auto">
									{_.map(this.props.task.content().blocks(), (block, index) => {
										const Block = types[block.type()];

										return (
											<div key={index} className="contentBlocks__block el--shrink wrapper--row">
												<Block block={block} />
											</div>
										);
									})}
								</div>
							</Layout>
						</Form>
					)}
				</Formik>
			);
		}
	};
}
