import Notification from 'models/notification/Notification';

import images from 'assets/images';

/**
 * Class representing a challenge submission notification.
 * @extends Notification
 */
export default class ChallengeSubmissionStateChangedNotification extends Notification {
	constructor(notification) {
		super(notification);

		this._submission = notification.submission;
		this._removed = !this._submission;
		this._type = 'challengeSubmissionStateChanged';
		this._earnings = notification.submission_earnings;
		this._submissionState = notification.submission_state;
	}

	getIconPath() {
		return super.getIconPath(images.icon.staticNotificationTypes.challengeSubmission[this._submissionState]);
	}

	getMessage() {
		if (this._removed) {
			return super.getMessage('null-states.submission');
		}

		const { challenge } = this._submission;
		const { id, ...rest } = this._prepareMessageBySubmissionStatus();

		return super.getMessage(`messages.submission.${id}`, {
			submissionTitle: this._submission.title(),
			challengeTitle: challenge.title(),
			...rest,
		});
	}

	getDate(params) {
		return super.getDate(`dates.submission.${this._submissionState}`, params);
	}

	_prepareMessageBySubmissionStatus() {
		switch (this._submissionState) {
			case 'new':
				return { id: 'new' };
			case 'rejected':
				return this._submission.rejectionReason()
					? {
							id: 'rejected',
							reason: this._submission.rejectionReason(),
					  }
					: { id: 'rejected-without-reason' };
			case 'accepted':
				return { id: 'accepted' };
			default:
		}
	}
}
