import { injectIntl } from 'react-intl';
import debounce from 'lodash/debounce';
import classnames from 'classnames';
import React from 'react';

export const RankingPositionListSearch = injectIntl(({ intl, search, setSearch, className, inputProps, ...props }) => {
	const onChange = debounce(setSearch, 200);

	return (
		<form
			className={classnames('form d-flex align-items-center', className)}
			onSubmit={event => event.preventDefault()}
			{...props}
		>
			<label className="search">
				<input
					type="text"
					placeholder={intl.formatMessage({ id: 'common.view.list.search.placeholder' })}
					defaultValue={search}
					autoFocus
					onChange={event => onChange(event.target.value)}
					{...inputProps}
				/>
			</label>
		</form>
	);
});
