import React, { Dispatch, InputHTMLAttributes, SetStateAction } from 'react';
import { FieldArray } from 'formik';
import { FormikProps } from 'formik/dist/types';
import classnames from 'classnames';

import { default as AgreementModel } from 'models/application/config/Agreement';
import Agreement from './Agreement';

export type AgreementsProps<Values> = InputHTMLAttributes<HTMLInputElement> &
	Omit<FormikProps<Values>, 'values' | 'errors' | 'touched' | 'setTouched'> & {
		values: string[];
		touched: boolean[];
		setTouched: Dispatch<SetStateAction<boolean[]>>;
		agreements: AgreementModel[];
		errors?: string | string[];
		name?: string;
		className?: string;
	};

const Agreements = <Values extends unknown>({
	agreements,
	values,
	errors,
	className,
	setTouched,
	setFieldValue,
	touched,
	submitCount,
	name = 'agreements',
}: AgreementsProps<Values>) => {
	return (
		<FieldArray
			name={name}
			render={output =>
				agreements.map((agreement, index) => (
					<Agreement
						className={classnames('m-2 fs-3', className)}
						key={agreement.id()}
						name={`${output.name}.${index}`}
						agreement={agreement}
						checked={!!values[index]}
						error={
							(submitCount > 0 || (touched && touched[index])) &&
							(Array.isArray(errors) ? errors[index] : errors)
						}
						onChange={event => {
							if (event.target.checked) {
								// output.insert not working correctly -> result similar to push
								setFieldValue(`${output.name}.${index}`, agreement.id());

								// Formik touched state is bugged with array fields
								const arr = [...touched];

								arr[index] = true;
								setTouched(arr);
							} else {
								setFieldValue(`${output.name}.${index}`, undefined);
							}
						}}
					/>
				))
			}
		/>
	);
};

export default Agreements;
