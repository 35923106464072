import { includes } from 'lodash';
import UAParser from 'ua-parser-js';

export default class PlatformService {
	constructor() {
		this._userAgent = new UAParser(window.navigator.userAgent);
		this._device = this._userAgent.getDevice();
		this._os = this._userAgent.getOS();
	}

	isMobile() {
		return includes(['mobile', 'tablet'], this._device.type);
	}

	isIOS() {
		return this._os.name === 'iOS';
	}
}
