import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import randomInt from 'utils/Math/randomInt';

import Circle from './Circle';

export default class RandomCircles extends PureComponent {
	render() {
		const { config } = this.props;
		const circleCount = randomInt(25, 50);

		return Array.from({ length: circleCount }).map((circle, index) => (
			<Circle
				key={index}
				size={randomInt(30, 300)}
				top={randomInt(10, 800)}
				left={randomInt(10, 1920)}
				opacity={Math.random()}
				blur={randomInt(0, 6)}
				config={config}
			/>
		));
	}

	static propTypes = {
		config: PropTypes.object,
	};
}
