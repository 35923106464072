import ChatweeIntegrationService from 'services/application/integration/subServices/ChatweeIntegrationService';
import EmailIntegrationService from 'services/application/integration/subServices/EmailIntegrationService';
import FacebookIntegrationService from 'services/application/integration/subServices/FacebookIntegrationService';
import GenericOAuthIntegrationService from 'services/application/integration/subServices/GenericOAuthIntegrationService';
import GoogleIntegrationService from 'services/application/integration/subServices/GoogleIntegrationService';
import ImportedIdIntegrationService from 'services/application/integration/subServices/ImportedIdIntegrationService';
import LinkedInIntegrationService from 'services/application/integration/subServices/LinkedInIntegrationService';
import TokenIntegrationService from 'services/application/integration/subServices/TokenIntegrationService';

import OAuthIntegrationService from 'services/application/integration/subServices/OAuthIntegrationService';

export default {
	'services.chatweeIntegrationService': {
		class: ChatweeIntegrationService,
		deps: [{ $ref: 'services.APIService' }, { $ref: 'services.cookieService' }],
	},
	'services.emailIntegrationService': {
		class: EmailIntegrationService,
		deps: [
			{ $ref: 'services.APIService' },
			{ $ref: 'services.navigationService' },
			{ $ref: 'services.accessTokenService' },
			{ $ref: 'services.i18nService' },
		],
	},
	'services.facebookIntegrationService': {
		class: FacebookIntegrationService,
	},
	'services.genericOAuthIntegrationService': {
		class: GenericOAuthIntegrationService,
	},
	'services.googleIntegrationService': {
		class: GoogleIntegrationService,
	},
	'services.importedIdIntegrationService': {
		class: ImportedIdIntegrationService,
		deps: [
			{ $ref: 'services.APIService' },
			{ $ref: 'services.navigationService' },
			{ $ref: 'services.accessTokenService' },
			{ $ref: 'services.i18nService' },
		],
	},
	'services.linkedInIntegrationService': {
		class: LinkedInIntegrationService,
	},
	'services.OAuthIntegrationService': {
		class: OAuthIntegrationService,
		deps: [
			{ $ref: 'services.APIService' },
			{ $ref: 'services.navigationService' },
			{ $ref: 'services.accessTokenService' },
		],
	},
	'services.tokenIntegrationService': {
		class: TokenIntegrationService,
		deps: [
			{ $ref: 'services.APIService' },
			{ $ref: 'services.navigationService' },
			{ $ref: 'services.accessTokenService' },
			{ $ref: 'services.i18nService' },
		],
	},
};
