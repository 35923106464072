import moment from 'moment';

import { Ranking } from 'models/ranking/ranking/Ranking';
import ChallengeState from 'models/challenge/challenge/state/ChallengeState';

export type ChallengeDTO = {
	id: string;
	title: string;
	participant_count: number;
	type: string;
	tabs: string[];
	participation_mode: string;
	has_configured_awarded_places: boolean;
	end_at: string;
	start_at: string;
	resolve_at: string;
	status: string;
	image: string;
	description: string;
	state: ChallengeState;
	ranking: Ranking;
};

export default abstract class Challenge {
	static PARTICIPATION_MODES: { [key: string]: string } = {
		PARTICIPATION_MODE_TEAM: 'team',
		PARTICIPATION_MODE_INDIVIDUAL: 'user',
		PARTICIPATION_MODE_CONTAINER_TEAM: 'container-team',
		PARTICIPATION_MODE_CONTAINER_USER: 'container-user',
	};
	static STATUS_TYPES: { [key: string]: string } = {
		notStarted: 'STATUS_NOT_STARTED',
		inProgress: 'STATUS_IN_PROGRESS',
		beforeResolve: 'STATUS_BEFORE_RESOLVE',
		finished: 'STATUS_FINISHED',
	};
	static DATE_FORMAT = 'DD-MM-YYYY';

	public readonly id: string;
	private _title: string;
	private _participantCount: number;
	private _type: string;
	private _tabs: string[];
	private _participationMode: string;
	private _hasConfiguredAwardedPlaces: boolean;
	private _endAt: moment.Moment;
	private _startAt: moment.Moment;
	private _resolveAt: moment.Moment;
	private _status: string;
	private _image: string;
	private _description: string;
	private _state: ChallengeState;
	private _ranking: Ranking;

	constructor(challenge: ChallengeDTO) {
		this.id = challenge.id;
		this._title = challenge.title;
		this._participantCount = challenge.participant_count;
		this._type = challenge.type;
		this._tabs = [];
		this._participationMode = challenge.participation_mode;
		this._hasConfiguredAwardedPlaces = challenge.has_configured_awarded_places;
		this._endAt = moment(challenge.end_at);
		this._startAt = moment(challenge.start_at);
		this._resolveAt = moment(challenge.resolve_at);
		this._status = challenge.status;
		this._image = challenge.image;
		this._description = challenge.description;
		this._state = challenge.state;
		this._ranking = challenge.ranking;
	}

	title() {
		return this._title;
	}

	participantCount() {
		return this._participantCount;
	}

	/**
	 * @throws {Error}
	 * @returns {string}
	 */
	participationMode() {
		const mode = Challenge.PARTICIPATION_MODES[this._participationMode];

		if (!mode) {
			throw new Error(`Unrecognized challenge participation mode: '${this._participationMode}'.`);
		}

		return mode;
	}

	hasConfiguredAwardedPlaces() {
		return this._hasConfiguredAwardedPlaces;
	}

	type() {
		return this._type;
	}

	tabs() {
		return this._tabs;
	}

	/**
	 * @returns {string}
	 */
	startAt() {
		return this._startAt.format(Challenge.DATE_FORMAT);
	}

	/**
	 * @returns {string}
	 */
	endAt() {
		return this._endAt.format(Challenge.DATE_FORMAT);
	}
	/**
	 * @returns {string}
	 */

	/**
	 * @returns {boolean}
	 */
	hasFinished() {
		return moment().isAfter(this._endAt);
	}

	resolveAt() {
		return this._resolveAt.format(Challenge.DATE_FORMAT);
	}

	/**
	 * @throws {Error}
	 * @returns {Object}
	 */
	status() {
		const { notStarted, inProgress, beforeResolve, finished } = Challenge.STATUS_TYPES;

		switch (this._status) {
			case notStarted:
				return {
					type: 'notStarted',
					startAt: this._startAt.format(Challenge.DATE_FORMAT),
					remainingTime: moment().to(this._startAt, true),
				};
			case inProgress:
				return {
					type: 'inProgress',
					remainingTime: moment().to(this._endAt, true),
					progress: Math.round(
						((moment().valueOf() - this._startAt.valueOf()) /
							(this._endAt.valueOf() - this._startAt.valueOf())) *
							100
					),
				};
			case beforeResolve:
				return {
					type: 'beforeResolve',
					remainingTime: moment().to(this._resolveAt, true),
				};
			case finished:
				return {
					type: 'finished',
				};
			default:
				throw new Error(`Unrecognized challenge status '${this._status}'.`);
		}
	}

	/**
	 * @returns {image}
	 */
	image() {
		return this._image;
	}

	/**
	 * @returns {string}
	 */
	description() {
		return this._description;
	}

	state() {
		return this._state;
	}

	isParticipationTeamMode() {
		return this.participationMode() === 'team';
	}

	canJudgement() {
		return moment().isBefore(this._resolveAt);
	}

	ranking() {
		return this._ranking;
	}
}
