import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default function() {
	return class NodeConnector extends Component {
		componentDidMount() {
			_.delay(() => {
				this._initLineConnector();
			}, 50);
			window.addEventListener('resize', (this.__resizeEvent = () => this._initLineConnector()));
		}

		componentWillUnmount() {
			window.removeEventListener('resize', this.__resizeEvent);
		}

		render() {
			return (
				<div>
					<canvas className="connectedNodeLines" width="1" height="1"></canvas>
					{this.props.children}
				</div>
			);
		}

		_initLineConnector() {
			if (!document.querySelector('.connectedNodeLines + ul')) {
				_.delay(() => {
					this._initLineConnector();
				}, 50);
			} else {
				this._lineConnectorHandler();
			}
		}

		_lineConnectorHandler() {
			const nodeList = document.querySelector('.connectedNodeLines + ul');
			const canvas = document.querySelector('.connectedNodeLines');

			this._setCanvasDimensions(canvas, nodeList.getBoundingClientRect());
			this._connectNodes(this._nodesCoordinates(canvas.getBoundingClientRect(), nodeList));
		}

		_connectNodes(connectPoints) {
			const canvas = document.querySelector('.connectedNodeLines');
			const context = canvas.getContext('2d');

			context.strokeStyle = 'rgba(0, 0, 0, 0.2)';
			context.beginPath();
			context.moveTo(connectPoints[0].left, connectPoints[0].top);
			_.forEach(connectPoints, (currentPoint, index) => {
				let nextPoint = connectPoints[index + 1];

				if (!nextPoint) {
					context.moveTo(currentPoint.left, currentPoint.top);
					context.stroke();

					return false;
				}

				if (nextPoint.top > currentPoint.top) {
					let rowConnectTop = _.subtract(
						nextPoint.top,
						_.divide(_.subtract(nextPoint.top, currentPoint.top), 2)
					);
					context.lineTo(currentPoint.left, rowConnectTop);
					context.lineTo(nextPoint.left, rowConnectTop);
					context.lineTo(nextPoint.left, nextPoint.top);
				} else {
					context.lineTo(nextPoint.left, nextPoint.top);
				}
			});
		}

		_setCanvasDimensions(canvas, nodeListDimensions) {
			canvas.setAttribute('width', nodeListDimensions.width);
			canvas.setAttribute('height', nodeListDimensions.height);
		}

		_nodesCoordinates(canvasDimensions, nodeList) {
			const nodesCoordinates = [];

			for (let child of Array.from(nodeList.childNodes)) {
				let childCoords = child.getBoundingClientRect();

				nodesCoordinates.push({
					top: Math.floor(_.add(childCoords.top - canvasDimensions.top, _.divide(childCoords.height, 2))),
					left: Math.floor(_.add(childCoords.left - canvasDimensions.left, _.divide(childCoords.width, 2))),
				});
			}

			return nodesCoordinates;
		}

		static propTypes = {
			children: PropTypes.node,
		};
	};
}
