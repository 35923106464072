import InternalStaticAnchorAchievementListService from 'services/application/anchor/subServices/internalStatic/subServices/InternalStaticAnchorAchievementListService';
import InternalStaticAnchorChallengeListService from 'services/application/anchor/subServices/internalStatic/subServices/InternalStaticAnchorChallengeListService';
import InternalStaticAnchorContactListService from 'services/application/anchor/subServices/internalStatic/subServices/InternalStaticAnchorContactListService';
import InternalStaticAnchorFAQService from 'services/application/anchor/subServices/internalStatic/subServices/InternalStaticAnchorFAQService';
import InternalStaticAnchorLoginService from 'services/application/anchor/subServices/internalStatic/subServices/InternalStaticAnchorLoginService';
import InternalStaticAnchorLogoutService from 'services/application/anchor/subServices/internalStatic/subServices/InternalStaticAnchorLogoutService';
import InternalStaticAnchorMissionListService from 'services/application/anchor/subServices/internalStatic/subServices/InternalStaticAnchorMissionListService';
import InternalStaticAnchorNewsListService from 'services/application/anchor/subServices/internalStatic/subServices/InternalStaticAnchorNewsListService';
import InternalStaticAnchorNotificationListService from 'services/application/anchor/subServices/internalStatic/subServices/InternalStaticAnchorNotificationListService';
import InternalStaticAnchorPrizeListService from 'services/application/anchor/subServices/internalStatic/subServices/InternalStaticAnchorPrizeListService';
import InternalStaticAnchorRankingListService from 'services/application/anchor/subServices/internalStatic/subServices/InternalStaticAnchorRankingListService';
import InternalStaticAnchorRewardCafeteriaService from 'services/application/anchor/subServices/internalStatic/subServices/InternalStaticAnchorRewardCafeteriaService';
import InternalStaticAnchorRulesService from 'services/application/anchor/subServices/internalStatic/subServices/InternalStaticAnchorRulesService';
import InternalStaticAnchorTeamProfileService from 'services/application/anchor/subServices/internalStatic/subServices/InternalStaticAnchorTeamProfileService';
import InternalStaticAnchorUserProfileService from 'services/application/anchor/subServices/internalStatic/subServices/InternalStaticAnchorUserProfileService';
import InternalStaticAnchorPortalService from 'services/application/anchor/subServices/internalStatic/subServices/InternalStaticAnchorPortalService';

export default {
	'services.internalStaticAnchorAchievementListService': {
		class: InternalStaticAnchorAchievementListService,
	},
	'services.internalStaticAnchorChallengeListService': {
		class: InternalStaticAnchorChallengeListService,
	},
	'services.internalStaticAnchorContactListService': {
		class: InternalStaticAnchorContactListService,
	},
	'services.internalStaticAnchorPortalService': {
		class: InternalStaticAnchorPortalService,
	},
	'services.internalStaticAnchorFAQService': {
		class: InternalStaticAnchorFAQService,
	},
	'services.internalStaticAnchorLoginService': {
		class: InternalStaticAnchorLoginService,
	},
	'services.internalStaticAnchorLogoutService': {
		class: InternalStaticAnchorLogoutService,
	},
	'services.internalStaticAnchorMissionListService': {
		class: InternalStaticAnchorMissionListService,
	},
	'services.internalStaticAnchorNewsListService': {
		class: InternalStaticAnchorNewsListService,
	},
	'services.internalStaticAnchorNotificationListService': {
		class: InternalStaticAnchorNotificationListService,
	},
	'services.internalStaticAnchorPrizeListService': {
		class: InternalStaticAnchorPrizeListService,
	},
	'services.internalStaticAnchorRankingListService': {
		class: InternalStaticAnchorRankingListService,
	},
	'services.internalStaticAnchorRewardCafeteriaService': {
		class: InternalStaticAnchorRewardCafeteriaService,
	},
	'services.internalStaticAnchorRulesService': {
		class: InternalStaticAnchorRulesService,
	},
	'services.internalStaticAnchorTeamProfileService': {
		class: InternalStaticAnchorTeamProfileService,
	},
	'services.internalStaticAnchorUserProfileService': {
		class: InternalStaticAnchorUserProfileService,
	},
};
