import React from 'react';

export default () => ({ onCheck }) => (
	<div className="buttons buttons--happySad">
		<button type="button" onClick={() => onCheck(true)}>
			<i className="fa fa-smile-o" />
		</button>
		<button type="button" onClick={() => onCheck(false)}>
			<i className="fa fa-frown-o" />
		</button>
	</div>
);
