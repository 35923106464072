import Config from 'models/application/config/Config';

import LandingPage from 'models/application/LandingPage';
import Integrations from 'models/application/integration/Integrations';
import Menu from 'models/application/menu/Menu';
import { MenuPositionDTO } from 'models/application/menu/position/Position';
import Integration, { IntegrationDTO } from 'models/application/integration/Integration';

import APIService from 'services/APIService';
import MenuService from 'services/application/menu/MenuService';
import AnchorService from 'services/application/anchor/AnchorService';
import IntegrationService from 'services/application/integration/IntegrationService';

export default class ApplicationService {
	private _config: Config | null;
	private _locale: string | null;
	private _menu: Menu | null;

	constructor(
		public readonly apiService: APIService,
		private readonly _menuService: MenuService,
		private readonly _anchorService: AnchorService,
		private readonly _integrationService: IntegrationService,
		private readonly _localStorage: Storage
	) {
		this._config = null;
		this._locale = this._localStorage.getItem('locale');
		this._menu = null;
	}

	/**
	 * @returns {Promise}
	 */
	fetchConfig() {
		return this.apiService
			.get(`v1/application/config?_locale=${this._locale}`, null, undefined, {
				withoutToken: true,
			})
			.then((config: any) => {
				this._config = new Config({
					...config,
					application: {
						...config.application,
						integrations: new Integrations(
							config.application.integrations
								.map((integration: IntegrationDTO) => this._integrationService.toModel(integration))
								.filter(
									(integration: Integration) => integration && integration.destination === 'portal'
								)
						),
					},
				});

				return this;
			});
	}

	/**
	 * @returns {Promise}
	 */
	fetchLandingPage() {
		return this.apiService
			.get(`v1/application/landingPage?_locale=${this._locale}`)
			.then((config: any) => config && new LandingPage(config));
	}

	/**
	 * @returns {Promise}
	 */
	fetchMenu() {
		return this.apiService.get('v1/menu').then((positions: MenuPositionDTO[]) => {
			const menu = this._menuService.toModel(positions);

			this._menu = menu;

			return menu;
		});
	}

	menu() {
		return this._menu;
	}

	warmup() {
		return this.apiService.get('v1/warmup');
	}

	config() {
		return this._config;
	}

	applicationConfig() {
		return this._config!.application();
	}

	settings() {
		return this._config!.application().settings();
	}

	vapidPublicKey() {
		return this._config!.application().vapidPublicKey();
	}
}
