import React, { useEffect, useState } from 'react';
import { wire } from 'react-hot-wire';

export function Challenge(props) {
	const { id, view } = props.match.params;
	const challengeService = props['services.challengeService'];
	const [challenge, setChallenge] = useState(null);
	const [refresh, setState] = useState({});

	const types = {
		creative: props['components.CreativeChallenge'],
		kpi: props['components.KPIChallenge'],
		target: props['components.TargetChallenge'],
	};

	useEffect(() => {
		(async () => {
			const challenge = await challengeService.fetchDetails({ id });

			setChallenge(challenge);
		})();
	}, [challengeService, id, refresh]);

	if (challenge && types[challenge.type()]) {
		const Component = types[challenge.type()];

		return <Component view={view} challenge={challenge} reload={() => setState({})} />;
	} else {
		return null;
	}
}

export default wire(
	[
		'services.challengeService',
		'components.KPIChallenge',
		'components.CreativeChallenge',
		'components.TargetChallenge',
	],
	Challenge
);
