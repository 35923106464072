import { HappySadAnswerMode } from 'models/task/question/questionTask/trueFalse/answer/mode/HappySadAnswerMode';

export default class HappySadAnswerModeService {
	/**
	 * @param {Object} answer
	 * @returns {HappySadAnswerMode}
	 */
	toModel(answer) {
		return new HappySadAnswerMode(answer);
	}
}
