import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import { wire } from 'react-hot-wire';

import imageCropper from 'components/commons/imageCropper';
import CreateImageSubmission from './ImageSubmission.template';
import { schema } from './ImageSubmission.schema';

export class CreateImageSubmissionComponent extends PureComponent {
	render() {
		return (
			<Formik
				initialValues={{
					title: '',
				}}
				validationSchema={schema}
				onSubmit={(...args) => this._onSubmit(...args)}
				render={props => (
					<CreateImageSubmission
						{...props}
						{...this.props}
						{...this.state}
						onChange={(...args) => this._onImageChanged(...args)}
					/>
				)}
			/>
		);
	}

	_onSubmit(values, actions) {
		const { challenge, activateModal } = this.props;
		const userProfileService = this.props['services.userProfileService'];

		if (!this.state.avatar.image) {
			actions.setSubmitting(false);

			return;
		}

		this.setBlob().then(() => {
			this.props['services.creativeChallengeService']
				.addSubmission({
					id: challenge.id,
					//TODO: change avatar state property to universal (also used in the profiles)
					image: this.state.avatar.blob,
					title: values.title,
					submission_type: 'imagesubmission',
				})
				.then(() => {
					userProfileService.addToWalletQueue(challenge.pointsForNothing());

					if (challenge.autoAccept()) {
						this.props.onSubmit();
						this.props.closeModal();
					} else {
						activateModal('confirmation');
					}
				})
				.catch(reason => activateModal('error', reason));
		});
	}
}

export default wire(
	['services.creativeChallengeService', 'services.userProfileService'],
	imageCropper(CreateImageSubmissionComponent)
);
