import React, { Fragment } from 'react';
import classnames from 'classnames';
import { Field } from 'formik';

import useStyles from '../../BuyModal.form.jss';

import { ErrorMessage } from 'components/_/form/error/Error';

export const Physical = () => {
	const classes = useStyles();

	return (
		<Fragment>
			<div className={classnames('pl-2 py-3', classes.label)}>Dane teleadresowe:</div>
			<div className="row">
				<div className="col-24 col-xs-12 mb-2">
					<Field
						name="address.forename"
						type="text"
						placeholder="Imię*"
						autoComplete="given-name"
						autoFocus
					/>
					<ErrorMessage name="address.forename" />
				</div>
				<div className="col-24 col-xs-12 mb-2">
					<Field name="address.surname" type="text" placeholder="Nazwisko*" autoComplete="family-name" />
					<ErrorMessage name="address.surname" />
				</div>
				<div className="col-24 mb-2">
					<Field
						name="address.companyName"
						type="text"
						placeholder="Nazwa firmy (opcjonalnie)"
						autoComplete="organization"
					/>
					<ErrorMessage name="address.companyName" />
				</div>
				<div className="col-24 col-xs-12 mb-2">
					<Field name="address.streetName" type="text" placeholder="Ulica*" autoComplete="address-line1" />
					<ErrorMessage name="address.streetName" />
				</div>
				<div className="col-24 col-xs-6 mb-2">
					<Field
						name="address.buildingNumber"
						type="text"
						placeholder="Nr budynku*"
						autoComplete="address-line2"
					/>
					<ErrorMessage name="address.buildingNumber" />
				</div>
				<div className="col-24 col-xs-6 mb-2">
					<Field name="address.flatNumber" type="text" placeholder="Nr lokalu" />
					<ErrorMessage name="address.flatNumber" />
				</div>
				<div className="col-24 col-xs-12 mb-2">
					<Field
						name="address.postalCode"
						type="text"
						placeholder="Kod pocztowy*"
						autoComplete="postal-code"
					/>
					<ErrorMessage name="address.postalCode" />
				</div>
				<div className="col-24 col-xs-12 mb-2">
					<Field name="address.city" type="text" placeholder="Miejscowość*" autoComplete="address-level2" />
					<ErrorMessage name="address.city" />
				</div>
				<div className="col-24 col-xs-12 mb-2">
					<Field name="address.phone" type="text" placeholder="Numer telefonu*" autoComplete="tel" />
					<ErrorMessage name="address.phone" />
				</div>
				<div className="col-24 col-xs-12 mb-2">
					<Field name="address.email" type="text" placeholder="Adres e-mail*" autoComplete="email" />
					<ErrorMessage name="address.email" />
				</div>
			</div>
			<div className={classnames('pl-2 py-3', classes.label)}>Informacje dodatkowe (opcjonalnie):</div>
			<Field as="textarea" name="address.note" />
			<ErrorMessage name="address.note" />
		</Fragment>
	);
};
