import Task from 'models/task/Task';

/**
 * Class representing a join task.
 * @extends Task
 */
export default class JoinTask extends Task {
	constructor(task) {
		super(task);

		/**
		 * @type {Object}
		 * @example
		 *   {
		 *   	// "teamContainerGroup" | "userContainerGroup"
		 *      container_group_type: "teamContainerGroup",
		 *      id: "XYZ123",
		 *      name: "team container group 1"
		 *   }
		 */
		this._containerGroup = task.container_group;
		/**
		 * @type {string} [task.container_prompt=null]
		 */
		this._containerPrompt = task.container_prompt;
		/**
		 * @type {string} [task.team_prompt=null]
		 */
		this._teamPrompt = task.team_prompt;
		/**
		 * @type {string}
		 * @example "container" | "team"
		 */
		this._joinType = task.join_type;
		/**
		 * Contains selected team / container
		 * @type {string|null}
		 * @private
		 */
		this._membership = null;
	}

	/**
	 * @returns {Object}
	 */
	containerGroup() {
		return this._containerGroup;
	}

	/**
	 * @returns {string}
	 */
	containerPrompt() {
		return this._containerPrompt;
	}

	/**
	 * @returns {string}
	 */
	teamPrompt() {
		return this._teamPrompt;
	}

	/**
	 * @returns {string}
	 */
	joinType() {
		return this._joinType;
	}

	setMembership(membership) {
		this._membership = membership;
	}

	/**
	 * @returns {string}
	 */
	membership() {
		return this._membership;
	}

	/**
	 * @returns {boolean}
	 */
	canBeSubmitted() {
		return !!this._membership;
	}

	reset() {
		super.reset();
		this._membership = null;
	}
}
