import Notification from 'models/notification/Notification';

import images from 'assets/images';

/**
 * Class representing a declaration task acceptance changed notification.
 * @extends Notification
 */
export default class DeclarationTaskAcceptanceChangedNotification extends Notification {
	/**
	 * @param {Object} notification
	 */
	constructor(notification) {
		super(notification);

		this._task = notification.task;
		this._type = 'declarationTaskAcceptanceChanged';
		this._accepted = notification.accepted;
		this._rejectionReason = notification.rejection_reason;

		this._removed = !this._task;
	}

	getMessage() {
		if (this._removed) {
			return super.getMessage('null-states.task');
		}

		const { id, ...rest } = this._prepareMessageByAcceptanceStatus();

		return super.getMessage(`messages.declaration-task.${id}`, {
			taskName: this._task.name(),
			...rest,
		});
	}

	getDate(params) {
		return super.getDate(`dates.declaration-task.${this._state()}`, params);
	}

	getIconPath() {
		return super.getIconPath(images.icon.staticNotificationTypes.declarationTask[this._state()]);
	}

	task() {
		return this._task;
	}

	accepted() {
		return this._accepted;
	}

	rejectionReason() {
		return this._rejectionReason;
	}

	_state() {
		return this._accepted ? 'accepted' : 'rejected';
	}

	_prepareMessageByAcceptanceStatus() {
		if (this._accepted) {
			return { id: 'accepted' };
		}

		if (this._rejectionReason) {
			return { id: 'rejected-reason', reason: this._rejectionReason };
		}

		return { id: 'rejected' };
	}
}
