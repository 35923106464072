import React, { PureComponent } from 'react';

import { Markdown } from 'components/_/common/text/markdown/Markdown';

export default function() {
	return class TextBlock extends PureComponent {
		render() {
			const { block } = this.props;

			return (
				<div className="fs-6 d-flex">
					<Markdown className="w-100">{block.body()}</Markdown>
				</div>
			);
		}
	};
}
