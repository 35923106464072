import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import images from 'assets/images';

import Submission from 'components/challenge/_/challenge/creative/submission/list/Submission.template';

export default function(StateManager, challengeAwardedPlaceService) {
	return class ChallengeWinners extends StateManager {
		request() {
			return challengeAwardedPlaceService.fetchWinners({
				id: this.props.challenge.id,
			});
		}

		view({ response: awardedPlaces }) {
			const { challenge } = this.props;

			return (
				<div className="contentSection__contentWrapper submissionAwards wrapper--col overflow--auto">
					{awardedPlaces.map(awardedPlace => (
						<div
							key={awardedPlace.id}
							className={classnames('wrapper--row submissionAward__section el--shrink', {
								isEmpty: _.isEmpty(awardedPlace.submissions()),
							})}
						>
							<div className="submissionAward__placeInfo el--shrink">
								<img src={images.example.icon.challenge.ranking} alt="" />
								<p>
									<FormattedMessage
										id={`challenge-details.common.ranked.${awardedPlace.place().id}`}
										values={awardedPlace.place().values}
									/>
								</p>
							</div>
							{challenge.status().type !== 'finished' && (
								<div className="emptyMessage">
									<FormattedMessage id="challenge-details.creative.content.not-selected-winners" />
								</div>
							)}
							<ul
								className={classnames('wrapper--row wrapper--row--wrap', {
									hidden: _.isEmpty(awardedPlace.submissions()),
								})}
							>
								{awardedPlace.submissions().map(submission => (
									<Submission
										submission={submission}
										visibility={{
											status: false,
											judgement: true,
											author: true,
										}}
									/>
								))}
							</ul>
						</div>
					))}
				</div>
			);
		}
	};
}
