import subServices from 'services/task/subServices';

import TaskService from 'services/task/TaskService';
import TaskStateService from 'services/task/TaskStateService';

export default {
	...subServices,
	'services.taskService': {
		class: TaskService,
		deps: [{ $ref: 'services.APIService' }, { $ref: 'services.logService' }, { $ref: 'services.currencyService' }],
		calls: [
			{
				name: 'registerSubService',
				args: ['content_block', { $ref: 'services.contentTaskService' }],
			},
			{
				name: 'registerSubService',
				args: ['document', { $ref: 'services.documentTaskService' }],
			},
			{
				name: 'registerSubService',
				args: ['embedded_analytics', { $ref: 'services.embeddedAnalyticsTaskService' }],
			},
			{
				name: 'registerSubService',
				args: ['collection_mission', { $ref: 'services.collectionMissionTaskService' }],
			},
			{
				name: 'registerSubService',
				args: ['form', { $ref: 'services.formTaskService' }],
			},
			{
				name: 'registerSubService',
				args: ['true_false', { $ref: 'services.trueFalseTaskService' }],
			},
			{
				name: 'registerSubService',
				args: ['order', { $ref: 'services.orderTaskService' }],
			},
			{
				name: 'registerSubService',
				args: ['graph', { $ref: 'services.graphTaskService' }],
			},
			{
				name: 'registerSubService',
				args: ['map', { $ref: 'services.mapTaskService' }],
			},
			{
				name: 'registerSubService',
				args: ['mockup', { $ref: 'services.mockupTaskService' }],
			},
			{
				name: 'registerSubService',
				args: ['visit_link', { $ref: 'services.visitLinkTaskService' }],
			},
			{
				name: 'registerSubService',
				args: ['declaration', { $ref: 'services.declarationTaskService' }],
			},
			{
				name: 'registerSubService',
				args: ['join', { $ref: 'services.joinTaskService' }],
			},
			// # question task services:
			// ## choice
			// ### image
			{
				name: 'registerSubService',
				args: ['image_multiple_choice_question', { $ref: 'services.imageMultipleChoiceQuestionTaskService' }],
			},
			{
				name: 'registerSubService',
				args: ['image_single_choice_question', { $ref: 'services.imageSingleChoiceQuestionTaskService' }],
			},
			// ### text
			{
				name: 'registerSubService',
				args: ['text_multiple_choice_question', { $ref: 'services.textMultipleChoiceQuestionTaskService' }],
			},
			{
				name: 'registerSubService',
				args: ['text_single_choice_question', { $ref: 'services.textSingleChoiceQuestionTaskService' }],
			},
			// ## phrase
			// ### guess
			{
				name: 'registerSubService',
				args: ['guess_phrase_question', { $ref: 'services.guessPhraseQuestionTaskService' }],
			},
			// ## true false
			// ### true false
			{
				name: 'registerSubService',
				args: ['true_false_question', { $ref: 'services.task.trueFalse' }],
			},
			// ## order
			// ### order
			{
				name: 'registerSubService',
				args: ['order_question', { $ref: 'services.task.order' }],
			},
			// ### complete
			{
				name: 'registerSubService',
				args: ['complete_phrase_question', { $ref: 'services.completePhraseQuestionTaskService' }],
			},
			// ### retype
			{
				name: 'registerSubService',
				args: ['retype_phrase_question', { $ref: 'services.retypePhraseQuestionTaskService' }],
			},
			// ### user_profile_fields_task
			{
				name: 'registerSubService',
				args: ['user_profile_fields', { $ref: 'services.userProfileFieldsTaskService' }],
			},
		],
	},
	'services.taskStateService': {
		class: TaskStateService,
		deps: [
			{ $ref: 'services.currencyService' },
			{ $ref: 'services.taskService' },
			{ $ref: 'services.userService' },
		],
	},
};
