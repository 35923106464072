/**
 * Class representing a question.
 * @abstract
 */
export default class Question {
	/**
	 * @param {Object} question
	 */
	constructor(question = {}) {
		/**
		 * @type {string}
		 */
		this._body = question.body;
		/**
		 * @type {string}
		 */
		this._image = question.image;
		/**
		 * @type {string}
		 */
		this._videoUrl = question.video_url;
		/**
		 * @type {string}
		 */
		this._type = question.type;
	}

	body() {
		return this._body;
	}

	image() {
		return this._image;
	}

	videoUrl() {
		return this._videoUrl;
	}

	type() {
		return this._type;
	}
}
