import APIService from 'services/APIService';
import KnowledgeBase, { KnowledgeBaseDTO } from 'models/onboarding/knowledgeBase/KnowledgeBase';

export default class KnowledgeBaseService {
	constructor(private readonly ApiService: APIService) {}

	fetchList() {
		return this.ApiService.get<{ items: KnowledgeBaseDTO[] }>(`/knowledge-base`, undefined, undefined, {
			version: 'v2',
			controller: 'workflows',
		}).then(response => ({
			items: response.items.map(process => this.toModel(process)),
		}));
	}

	toModel(knowledgeBaseDTO: KnowledgeBaseDTO): KnowledgeBase {
		return new KnowledgeBase(knowledgeBaseDTO);
	}
}
