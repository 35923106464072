import CustomAnswerModeService from 'services/task/subServices/questionTask/trueFalse/answer/mode/subServices/CustomAnswerModeService';
import HappySadAnswerModeService from 'services/task/subServices/questionTask/trueFalse/answer/mode/subServices/HappySadAnswerModeService';
import PlusMinusAnswerModeService from 'services/task/subServices/questionTask/trueFalse/answer/mode/subServices/PlusMinusAnswerModeService';
import TrueFalseAnswerModeService from 'services/task/subServices/questionTask/trueFalse/answer/mode/subServices/TrueFalseAnswerModeService';

export default {
	'services.task.trueFalse.answer.mode.custom': {
		class: CustomAnswerModeService,
	},
	'services.task.trueFalse.answer.mode.happySad': {
		class: HappySadAnswerModeService,
	},
	'services.task.trueFalse.answer.mode.plusMinus': {
		class: PlusMinusAnswerModeService,
	},
	'services.task.trueFalse.answer.mode.trueFalse': {
		class: TrueFalseAnswerModeService,
	},
};
