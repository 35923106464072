import SingleChoiceQuestionTask from 'models/task/question/questionTask/choice/SingleChoiceQuestionTask';

/**
 * Class representing a image single choice question task.
 * @extends SingleChoiceQuestionTask
 */
export default class ImageSingleChoiceQuestionTask extends SingleChoiceQuestionTask {
	isImageQuestionTask() {
		return true;
	}
}
