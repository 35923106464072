import { createUseStyles } from 'react-jss';

const modal = {
	maxWidth: 600,
};

export default createUseStyles(theme => ({
	modal: {},
	backdrop: {
		backgroundColor: theme.color.system['color--content--foreground-20'],
		zIndex: theme.zIndex.modal,
		overflow: 'auto',
		position: 'fixed',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backdropFilter: config => (config.blur ? 'blur(2px)' : 'none'),

		[`@media (max-width: ${theme.grid.sm}px)`]: {
			padding: '0 1rem',
		},
	},
	content: {
		width: '100%',
		maxWidth: `${modal.maxWidth}px`,
		minHeight: config => (config.autoHeight ? 'auto' : '200px'),
		fontSize: '0.9rem',
		backgroundColor: theme.color.system['color--content--background'],
		position: 'relative',
		margin: '2rem auto',

		[`@media (max-width: ${theme.grid.sm}px)`]: {
			maxWidth: 'initial',
			margin: '1rem auto',
		},

		'& > section': {
			display: 'flex',
			backgroundColor: theme.color.system['color--content--foreground-3'],
			color: theme.color.system['color--content--foreground-80'],
			minHeight: 'inherit',

			'& > div': {
				width: '100%',
			},
		},
	},
}));
