export class TextAnswer {
	/**
	 * @param {Object} answer
	 */
	constructor(answer) {
		/**
		 * @type {string} id
		 */
		this._id = answer.id;
		/**
		 * @type {string} id
		 */
		this._body = answer.body;
		/**
		 * @type {string} id
		 */
		this._type = answer.type;
	}

	/**
	 * @returns {string}
	 */
	id() {
		return this._id;
	}

	/**
	 * @returns {string}
	 */
	body() {
		return this._body;
	}
}
