export default class TrueFalseAnswerService {
	/**
	 * @param {TrueFalseAnswerModeService} trueFalseAnswerModeService
	 */
	constructor(trueFalseAnswerModeService) {
		/**
		 * @type {TrueFalseAnswerModeService}
		 * @private
		 */
		this._trueFalseAnswerModeService = trueFalseAnswerModeService;
	}

	/**
	 * @param {Object} answer
	 * @returns {TrueFalseAnswerModeService}
	 */
	toModel(answer) {
		return this._trueFalseAnswerModeService.toModel(answer);
	}
}
