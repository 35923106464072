export default class ContactListService {
	constructor(APIService, userProfileService, userProfileFieldService) {
		this.APIService = APIService;
		this._userProfileService = userProfileService;
		this._userProfileFieldService = userProfileFieldService;
	}

	list({ active, search, offset, limit }) {
		const filters = {};

		if (active) {
			filters['filters[active]'] = 1;
		}

		const query = {
			...filters,
			search,
			offset,
			limit,
		};

		return this.APIService.get('v1/contact-list', query).then(({ count, list = [] }) => ({
			count,
			list: list.map(contactEntry => ({
				...contactEntry,
				userProfile: this._userProfileService.toModel(contactEntry.userProfile),
				fields: this._userProfileFieldService.toModel(contactEntry.fields),
			})),
		}));
	}
}
