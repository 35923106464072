import TaskState from 'models/task/state/TaskState';

/**
 * @extends {TaskState}
 */
export default class DeclarationTaskState extends TaskState {
	/**
	 * @param {Object} state
	 */
	constructor(state) {
		super(state);

		if (!state) {
			return;
		}

		/**
		 * @type {null|DeclarationTaskRepetitionState}
		 * @private
		 */
		this._currentRepetitionState = state.current_repetition_state;
		/**
		 * @type {DeclarationTaskRepetitionState[]}
		 * @private
		 */
		this._repetitionStates = state.repetition_states;
	}

	status() {
		const acceptanceState = this._currentRepetitionState && this._currentRepetitionState.acceptanceState();

		if (acceptanceState) {
			if (['awaiting', 'rejected'].includes(acceptanceState)) {
				return acceptanceState;
			}
		}

		return super.status();
	}
}
