import Integration from './Integration';

export default class EmailIntegration extends Integration {
	constructor(integration) {
		super(integration);

		/**
		 * @type {boolean}
		 */
		this.annoying = integration.annoying;
	}
}
