import React from 'react';
import { Form, Field, ErrorMessage } from 'formik';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import CreateSubmission from '../../Create.template';

export default ({
	intl,
	challenge,
	activateModal,
	avatar,
	inProgress,
	isValid,
	isSubmitting,
	onChange,
	closeModal,
}) => (
	<CreateSubmission
		closeModal={closeModal}
		title={intl.formatMessage({
			id: 'create-submission.image-submission.title',
		})}
	>
		<Form className="form">
			<div className="create-image-submission m-0 m-md-3">
				<Field
					name="title"
					render={({ field }) => (
						<input
							{...field}
							className="w-100"
							disabled={isSubmitting}
							placeholder={intl.formatMessage({
								id: 'create-submission.image-submission.form.title.placeholder',
							})}
						/>
					)}
				/>
				<ErrorMessage name="title">
					{({ id, ...values }) => (
						<div className="error">
							<FormattedMessage id={`create-submission.form.error.${id}`} values={values} />
						</div>
					)}
				</ErrorMessage>
				<div className="text-center d-flex flex-column align-items-center">
					{avatar.image ? (
						<div
							className="image-preview d-flex flex-column align-items-center mt-2 mt-md-4"
							style={{
								maxWidth: '100%',
								minHeight: '1px' /* IE IMAGE HACK */,
							}}
						>
							<img src={avatar.image} style={{ minHeight: '1px' /* IE IMAGE HACK */ }} alt="" />
							<button
								type="submit"
								disabled={isSubmitting}
								className={classnames('btn__basic mt-2 mt-sm-4', {
									'btn__basic--disabled': !isValid,
									loading: isSubmitting,
								})}
							>
								<FormattedMessage id="create-submission.image-submission.form.button" />
							</button>
						</div>
					) : (
						<div className="select-image px-4 px-md-6 py-4 my-2 my-md-4 rounded">
							{inProgress && (
								<div className="loader d-flex align-items-center justify-content-center">
									<i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
								</div>
							)}
							<div className="d-flex icons justify-content-center">
								<i className="fa fa-file-image-o mr-6 d-none d-sm-block" />
								<i className="fa fa-camera d-block" />
							</div>
							<p className="mt-4">
								{intl.formatMessage({
									id: 'create-submission.image-submission.form.file',
								})}
							</p>
							<input type="file" accept="image/*" onChange={onChange} />
						</div>
					)}
					{challenge.submissionTypes().length > 1 && (
						<p className="return mt-4">
							{intl.formatMessage({
								id: 'create-submission.return.or',
							})}
							<span onClick={() => activateModal('selection')}>
								{intl.formatMessage({
									id: 'create-submission.return.link',
								})}
							</span>
							{intl.formatMessage({
								id: 'create-submission.return.rest',
							})}
						</p>
					)}
				</div>
			</div>
		</Form>
	</CreateSubmission>
);
