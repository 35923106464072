import { createUseStyles } from 'react-jss';

export default (() => {
	return createUseStyles(theme => ({
		view: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
		},
		header: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: '0.6rem 1.2rem',
			height: '4rem',
			minHeight: '4rem',
			color: theme.color.system['color--primary--foreground'],
			backgroundColor: theme.color.system['color--primary--background'],
			fontSize: '1.2rem',

			[`@media (max-width: ${theme.grid.sm}px)`]: {
				padding: '0.6rem',
				height: '3rem',
				minHeight: '3rem',
			},

			'& h1, & h2': {
				fontSize: '1.7rem',

				[`@media (max-width: ${theme.grid.sm}px)`]: {
					fontSize: '1.5rem',
				},

				[`@media (max-width: ${theme.grid.xs}px)`]: {
					fontSize: '1.4rem',
				},
			},

			'& h2': {
				opacity: 0.5,
				fontWeight: 100,
			},
		},
		line: {
			borderRight: `1px solid ${theme.color.system['color--primary--foreground-10']}`,
			margin: '0 1.6rem 0 1.4rem',

			[`@media (max-width: ${theme.grid.xs}px)`]: {
				margin: '0 1rem 0 0.8rem',
			},
		},
	}));
})();
