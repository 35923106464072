import { createUseStyles } from 'react-jss';

export default () => {
	return createUseStyles(() => {
		return {
			container: {
				position: 'relative',
				'& input[type="file"]': {
					position: 'absolute',
					left: 0,
					top: 0,
					right: 0,
					bottom: 0,
					opacity: 0,
					zIndex: 300,

					'&, &::-webkit-file-upload-button': {
						cursor: 'pointer',
					},
				},
			},
		};
	});
};
