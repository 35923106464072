import React from 'react';
import { Link } from 'react-router-dom';

export default ({ item, renderAvailability, renderButton, renderPrice }) => (
	<div className="col-12 col-md-8 col-lg-6 col-xl-4 mb-4 mb-sm-5">
		<div className="mx-0 mx-sm-3 h-100">
			<Link to={`/store/item/${item.id}/details`}>
				<div className="tile store__item rounded h-100">
					<div className="tile__front d-flex flex-column justify-content-between">
						<div
							className="tile__image background-image size--cover rounded-top"
							style={{
								backgroundImage: `url(${item.image()})`,
							}}
						/>
						<div className="d-flex flex-column flex-grow-1 align-items-center justify-content-between p-3 px-sm-4 pt-sm-4">
							<div className="item__name mb-3 fs-4 fs-sm-5 font-weight-bold text-center">{item.name}</div>
							<div className="item__price fs-4">{renderPrice}</div>
						</div>
					</div>
					<div className="tile__back d-none d-sm-flex flex-column justify-content-between">
						<div className="p-4 fs-4 d-flex flex-column justify-content-between flex-grow-1">
							<div className="item__key-value">{renderAvailability}</div>
						</div>
						<div className="item__button d-flex flex-column align-items-center py-3">{renderButton}</div>
					</div>
				</div>
			</Link>
		</div>
	</div>
);
