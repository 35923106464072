import { createUseStyles } from 'react-jss';

export default (() => {
	return createUseStyles({
		container: {
			textAlign: 'center',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			margin: '1.4rem 0',

			'& h1': {
				fontSize: '1.6rem',
			},

			'& h2': {
				fontSize: '1.2rem',
			},

			'& button': {
				fontSize: '1rem',
			},
		},
	});
})();
