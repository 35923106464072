import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ExternalAnchor from 'models/application/anchor/types/external/External';

/**
 * @example
 *     <MenuAnchor position={position} {...rest}>{children}</MenuAnchor>
 *
 * @returns {ReactElement}
 */
function MenuAnchor({ position, children, ...rest }) {
	const target = position.anchor.target;
	const url = position.anchor.url();

	const params = { ...rest };
	let AnchorTag = null;

	if (position.anchor instanceof ExternalAnchor) {
		params.href = url;
		params.target = target;
		AnchorTag = 'a';
	} else {
		params.to = url;
		AnchorTag = NavLink;
	}

	return <AnchorTag {...params}>{children}</AnchorTag>;
}

MenuAnchor.propTypes = {
	position: PropTypes.object,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default () => MenuAnchor;
