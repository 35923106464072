import TargetChallenge from 'views/challenge/types/target/TargetChallenge';

export default {
	'components.TargetChallenge': {
		factory: TargetChallenge,
		deps: [
			{
				Tabs: {
					ChallengeDetails: { $ref: 'components.TargetChallengeDetails' },
				},
			},
		],
	},
};
