import { FormContentBlock } from './FormContentBlock';

export class HeaderFormBlock extends FormContentBlock {
	constructor(formBlock) {
		super(formBlock);

		this._body = formBlock.body;
		this._level = formBlock.level;
	}

	body() {
		return this._body;
	}

	level() {
		return this._level;
	}
}
