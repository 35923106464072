import TaskBaseService from 'services/task/TaskBaseService';

import TextMultipleChoiceQuestionTask from 'models/task/question/questionTask/choice/text/TextMultipleChoiceQuestionTask';

export default class TextMultipleChoiceQuestionTaskService extends TaskBaseService {
	toModel(task) {
		return new TextMultipleChoiceQuestionTask({
			...task,
			state: this._mapState(task),
		});
	}
}
