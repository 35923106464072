export type RankingDTO = {
	id: string;
	name: string;
	description: string;
	image: string;
	ranking_type: string;
};

export abstract class Ranking {
	public id: string;
	public name: string;
	public description: string;
	public image: string;
	public type: string;

	constructor(ranking: RankingDTO) {
		this.id = ranking.id;
		this.name = ranking.name;
		this.description = ranking.description;
		this.image = ranking.image;
		this.type = ranking.ranking_type;
	}
}
