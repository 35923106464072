import Task from 'models/task/Task';

export default class EmbeddedAnalyticsTask extends Task {
	constructor(task) {
		super(task);

		this._linkUrl = task.link_url;
	}

	linkUrl() {
		return this._linkUrl;
	}
}
