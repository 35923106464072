import React from 'react';
import classnames from 'classnames';

import useStyles from './FilterByEmployeeModal.jss';
import FilterByEmployee, { FilterByEmployeeProps } from '../FilterByEmployee';
import { InjectedIntlProps, injectIntl } from 'react-intl';

export type FilterByEmployeeModalProps = InjectedIntlProps & {
	onSelect?: FilterByEmployeeProps['onSelect'];
};

const FilterByEmployeeModal = ({ intl, onSelect }: FilterByEmployeeModalProps) => {
	const classes = useStyles({});

	return (
		<div>
			<div className={classnames(classes.backdrop)}>
				<div className={classnames(classes.content, 'd-flex flex-column align-items-center')}>
					<div className="mb-3 font-weight-bold">{intl.formatMessage({ id: 'portal.process.filter' })}:</div>
					<FilterByEmployee onSelect={onSelect} />
				</div>
			</div>
		</div>
	);
};

export default injectIntl(FilterByEmployeeModal);
