import ChallengeSubmissionStateChangedNotification from 'models/notification/ChallengeSubmissionStateChangedNotification';

import NotificationBaseService from 'services/notification/NotificationBaseService';

export default class ChallengeSubmissionStateChangedNotificationService extends NotificationBaseService {
	constructor(submissionService, currencyService) {
		super();
		this._submissionService = submissionService;
		this._currencyService = currencyService;
	}

	toModel({ submission, submission_earnings, ...rest }) {
		return new ChallengeSubmissionStateChangedNotification({
			submission: submission && this._submissionService.toModel(submission),
			submission_earnings: submission_earnings && this._currencyService.toModel(submission_earnings),
			...rest,
		});
	}
}
