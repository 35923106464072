import QuestionTask from 'models/task/question/questionTask/QuestionTask';
import GuessQuestion from 'models/task/question/GuessQuestion';

export default class GuessPhraseQuestionTask extends QuestionTask {
	constructor(task) {
		super(task);

		/**
		 * @type {GuessQuestion}
		 */
		this._question = new GuessQuestion(task.question);
	}
}
