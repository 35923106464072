import React, { PureComponent } from 'react';

import TimeProgressBar from 'views/commons/progress/TimeProgressBar';

import { Markdown } from 'components/_/common/text/markdown/Markdown';

export default function(taskService, Layout) {
	return class VisitLinkInternal extends PureComponent {
		_startTime = null;
		_rafRef = null;

		constructor(...args) {
			super(...args);

			const { isCompleted, hasDescription } = this.props;

			this.state = {
				...this.state,
				elapsedTime: 0,
				iframeVisible: isCompleted || !hasDescription,
			};
		}

		componentDidMount() {
			this._initCountdown();
		}

		render() {
			const { task, goBack, inProcess } = this.props;
			const { iframeVisible, elapsedTime } = this.state;
			const progressIndicator = (
				<TimeProgressBar totalTime={task.requiredTime() * 1000} elapsedTime={elapsedTime} />
			);

			return (
				<Layout
					task={task}
					progressIndicator={iframeVisible && progressIndicator}
					options={{
						hideCheckButton: iframeVisible,
					}}
					actions={{
						submit: {
							handler: () => (iframeVisible ? this._finish() : this._startTask()),
							buttonText: 'task-details.common.button.do',
						},
						goBack,
						isSubmitting: inProcess,
					}}
				>
					<div className="task--visitLink visitLink--internal">
						{iframeVisible ? (
							<iframe
								src={task.linkUrl()}
								className="w-100 h-100 position-absolute"
								frameBorder="0"
								style={{
									left: 0,
									top: 0,
									right: 0,
									bottom: 0,
								}}
								title="linkUrl"
							/>
						) : (
							<div className="mx-0-auto text-center">
								<div className="mb-3" style={{ fontSize: '1rem' }}>
									<Markdown>{task.description()}</Markdown>
								</div>
								{task.image() && (
									<div className="flex-grow-1" style={{ flexBasis: 0 }}>
										<img
											src={task.image()}
											alt={task.image()}
											className="rounded"
											style={{
												maxWidth: '600px',
												minWidth: '250px',
												width: '100%',
											}}
										/>
									</div>
								)}
							</div>
						)}
					</div>
				</Layout>
			);
		}

		componentWillUnmount() {
			cancelAnimationFrame(this._rafRef);
		}

		_startTask() {
			this.setState(
				{
					iframeVisible: true,
				},
				() => this._initCountdown()
			);
		}

		async _initCountdown() {
			const { task, isCompleted } = this.props;
			const { iframeVisible } = this.state;

			if ((task.viewWhenComplete() && isCompleted) || !iframeVisible) {
				return;
			}

			if (task.requiredTime()) {
				await taskService.processTask(task, { start: true });
				this._startCountdown();
			} else {
				this._finishCountdown();
			}
		}

		// TODO: MOVE THIS TO MODEL
		_startCountdown() {
			this._startTime = performance.now();
			this._rafRef = requestAnimationFrame(timestamp => this._countdownTick(timestamp));
		}

		_countdownTick(timestamp) {
			const elapsedTime = timestamp - this._startTime;
			this.setState({ elapsedTime });

			if (elapsedTime > Number(this.props.task.requiredTime()) * 1000) {
				this._finishCountdown();
			} else {
				this._rafRef = requestAnimationFrame(newTimestamp => this._countdownTick(newTimestamp));
			}
		}

		_finishCountdown() {
			cancelAnimationFrame(this._rafRef);

			return this.props.process({ finish: true });
		}

		_tryAgain() {
			this.props.task.reset();
			this.forceUpdate();
		}
	};
}
