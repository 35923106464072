import DeclarationTask from 'components/task/types/declaration/DeclarationTask';

export default {
	'components.DeclarationTask': {
		factory: DeclarationTask,
		deps: [
			{ $ref: 'services.taskService' },
			{ $ref: 'components.task.layout.BaseLayout' },
			{ $ref: 'components.MatchingImageType' },
		],
	},
};
