import React, { PureComponent } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import { Form, Formik } from 'formik';

import { Markdown } from 'components/_/common/text/markdown/Markdown';

export default function(Layout, SortableAnswerList, platformService, MatchingImageType) {
	return class OrderTask extends PureComponent {
		state = {
			answers: null,
		};

		componentDidMount() {
			this._setAnswers();
		}

		render() {
			const { task, goBack } = this.props;
			const question = task.question();

			return (
				<Formik
					initialValues={{}}
					onReset={() => this._tryAgain()}
					onSubmit={(values, actions) => this._submit().then(() => actions.setSubmitting(false))}
				>
					{({ handleReset, isSubmitting }) => (
						<Form className="form d-flex flex-grow-1">
							<Layout
								task={task}
								actions={{
									submit: {},
									goBack,
									tryAgain: handleReset,
									isSubmitting,
								}}
							>
								<div className="task__order wrapper--row el--grow standardPreview__columnWrapper overflow--auto noInstructionQuestion">
									<div className="wrapper--col standardPreview__column standardPreview__column--left el--shrink">
										<div className="wrapper--col el--grow el--shrink">
											<div className="fs-6 standardText questionBody el--shrink">
												<Markdown>{question.body()}</Markdown>
											</div>
											{question.image() && (
												<MatchingImageType
													classes="el--grow rounded el--shrink"
													src={question.image()}
												/>
											)}
											{question.videoUrl() && (
												<iframe
													width="100%"
													height="350px"
													src={question.videoUrl()}
													frameBorder="0"
													title="videoUrl"
													allowFullScreen
												/>
											)}
										</div>
									</div>
									<div className="standardPreview__column standardPreview__column--middle wrapper--row justify--center">
										<div className="wrapper--col el--grow">
											<SortableAnswerList
												answers={this.state.answers || []}
												onSortEnd={(...args) => this._onSortEnd(...args)}
												lockAxis="y"
												lockToContainerEdges={true}
												disabled={task.isSubmitted()}
												pressDelay={platformService.isMobile() ? 300 : 0}
											/>
										</div>
									</div>
								</div>
							</Layout>
						</Form>
					)}
				</Formik>
			);
		}

		_setAnswers() {
			this.setState({
				answers: this.props.task.question().answers(),
			});
		}

		_onSortEnd({ oldIndex, newIndex }) {
			this.setState({
				answers: arrayMove(this.state.answers, oldIndex, newIndex),
			});
		}

		_submit() {
			const answers = this.state.answers;

			return this.props.process({
				answers: answers.map(answer => ({ id: answer.id() })),
			});
		}

		_tryAgain() {
			this.props.task.reset();
			this._setAnswers();
		}
	};
}
