import BaseService from 'services/BaseService';

export default class TaskService extends BaseService {
	_type = 'task';
	_typeKey = 'task_type';

	constructor(APIService, logService, currencyService) {
		super();
		this.APIService = APIService;
		this._logService = logService;
		this._currencyService = currencyService;
		this._suggestedList = [];
	}

	toModel(task) {
		return (
			task &&
			super.toModel({
				...task,
				state: task.state && {
					...task.state,
					earnings_information: {
						...task.state.earnings_information,
						completion: this._currencyService.toModel(task.state.earnings_information.completion),
					},
				},
				next_task: task.next_task && super.toModel(task.next_task),
				help_task: task.help_task && super.toModel(task.help_task),
			})
		);
	}

	/**
	 *
	 * @param {string} place
	 * @returns {Promise}
	 */
	fetchList({ place } = {}) {
		return this.APIService.get('v1/task', {
			place,
		}).then((list = []) =>
			list.map(task => {
				try {
					return this.toModel(task);
				} catch (error) {
					return this._logService.warn(error);
				}
			})
		);
	}

	fetchSuggestedList() {
		return this.fetchList({ place: 'suggested_actions_menu' }).then(list => {
			this._suggestedList = list;

			return list;
		});
	}

	suggestedList() {
		return this._suggestedList;
	}

	/**
	 *
	 * @param {string|number} id
	 * @returns {Promise}
	 */
	fetchDetails({ id } = {}) {
		return this.APIService.get(`v1/task/${id}`).then(task => this.toModel(task));
	}

	processTask(task, input, headers, actionId) {
		const query = actionId ? `?process_action_id=${actionId}` : '';

		return this.APIService.post(`v1/task/${task.id()}/process${query}`, input, headers).then(response => ({
			completion: response.completion,
			failure: response.failure,
			task: this.toModel(response.task),
			feedback: response.feedback,
		}));
	}
}
