import React from 'react';
import ReactStars from 'react-stars';
import Media from 'react-media';

export default ({ userCanJudgement, userScore, onScore, averageScore, color }) => (
	<article className="rateSection__stars d-flex">
		<Media query={{ maxWidth: 575 }}>
			{matches =>
				matches ? (
					<ReactStars
						onChange={onScore}
						count={5}
						size={28}
						value={userScore}
						edit={userCanJudgement}
						color1={color.mobile.color1}
						color2={color.mobile.color2}
						half={false}
						className="d-flex"
						// react-starts module do not react to edit prop changes
						//   so...we must regenerate component with every state change.
						key={Math.random()}
					/>
				) : (
					<ReactStars
						onChange={onScore}
						count={5}
						size={28}
						value={userScore}
						edit={userCanJudgement}
						color1={color.desktop.color1}
						color2={color.desktop.color2}
						half={false}
						className="d-flex"
						// react-starts module do not react to edit prop changes
						//   so...we must regenerate component with every state change.
						key={Math.random()}
					/>
				)
			}
		</Media>
		<p className="rateSection__number">{averageScore}</p>
	</article>
);
