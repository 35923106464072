import InternalDynamicAnchorNews from 'models/application/anchor/types/internalDynamic/types/News';

export default class InternalDynamicAnchorNewsService {
	constructor(newsService) {
		this._newsService = newsService;
	}

	toModel({ news, ...rest }) {
		return new InternalDynamicAnchorNews({
			news: this._newsService.toModel(news),
			...rest,
		});
	}
}
