import InternalDynamicAnchorMission from 'models/application/anchor/types/internalDynamic/types/Mission';

export default class InternalDynamicAnchorMissionService {
	constructor(taskService) {
		this._taskService = taskService;
	}

	toModel({ task, ...rest }) {
		return new InternalDynamicAnchorMission({
			task: this._taskService.toModel(task),
			...rest,
		});
	}
}
