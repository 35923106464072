import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import map from 'lodash/map';

//import SVGImage from 'components/commons/SVGImage';
import { Currency } from 'components/_/common/currency/Currency';

export function Notification(props) {
	const { notification } = props;

	return (
		<div className="col-24">
			<div className="mx-0 mx-sm-3">
				<div
					className={classnames('notification d-flex align-items-center pl-3 pl-sm-5 py-3 py-sm-0', {
						removed: notification.removed(),
					})}
				>
					{/*<div className="notification__icon px-3 px-sm-5">
						<SVGImage
							src={notification.getIconPath()}
							className={classnames({
								invisible: !notification.getIconPath(),
							})}
						/>
					</div>*/}
					<div className="notification__content d-flex flex-column flex-sm-row flex-grow-1 align-items-start align-items-sm-center">
						<div className="flex-grow-1 py-0 py-sm-3">
							<div className="notification__title mb-1">
								{notification.title() ? (
									notification.title()
								) : (
									<FormattedMessage {...notification.getMessage()} />
								)}
							</div>
							<div className="notification__date mb-1 mb-sm-0">
								<div className="d-none d-sm-block">
									<FormattedMessage
										{...notification.getDate({
											format: 'LLLL',
										})}
									/>
								</div>
								<div className="d-block d-sm-none">
									<FormattedMessage {...notification.getDate()} />
								</div>
							</div>
						</div>
						{notification.hasEarnings() && (
							<div className="notification__points d-flex align-items-center px-0 px-sm-3 align-self-stretch rounded-right">
								<div className="d-flex align-items-center h-100">
									{map(notification.earnings().values(), currency => (
										<Currency
											key={currency.codeName()}
											currency={currency.codeName()}
											value={currency.value()}
											className="mr-3 mx-sm-3"
										/>
									))}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
