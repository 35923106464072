import Task from 'models/task/Task';

/**
 * Class representing a form task.
 * @extends Task
 */
export default class FormTask extends Task {
	constructor(task) {
		super(task);

		this._form = task.form;
	}

	form() {
		return this._form;
	}
}
