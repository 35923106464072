export default {
	'team-roles': {
		admin: 'Admin',
		manager: 'Manager',
		user: 'Utilisateur',
	},
	error: {
		title: "Vous n'avez pas accès au contenu ou le contenu n'est pas encore disponible !",
		button: "Retour à la page d'accueil",
		info: 'En cas de problèmes, veuillez contacter votre administrateur technique.',
	},
	'general-error': {
		title: 'Une erreur est survenue',
		refresh: 'Actualiser',
		contact: "Contactez l'administrateur de la plateforme pour plus d'informations.",
		details: 'Détails',
	},
	'landing-page': {
		link: {
			'sign-up': 'Inscrivez-vous',
			'sign-in': 'Connectez-vous',
		},
		copyright: 'Gamfi Sp. z o.o.',
		acceptation: "L'utilisation de l'application signifie l'acceptation des",
		'terms-and-conditions': {
			'cookie-policy': 'Politique de Cookies',
			'privacy-policy': 'Politique de confidentialité',
			'terms-and-conditions': 'Règlement',
		},
	},
	auth: {
		form: {
			error: {
				required: 'Ce champ est requis.',
				'invalid-email': "L'adresse e-mail fournie n'est pas valide.",
				'invalid-identifier': "L'identifiant fourni n'est pas valide.",
				'user-not-exists': 'L’utilisateur ou le mot de passe saisi est incorrect.',
				'user-not-verified': "L'utilisateur spécifié n'est pas vérifié.",
				'user-inactive': "L'utilisateur spécifié est inactif.",
				'user-not-found': 'L’utilisateur ou le mot de passe saisi est incorrect.',
				'user-profile-not-active': "Le compte de l'utilisateur n'est pas actif.",
				'invalid-password-reset-token': "Le jeton de réinitialisation du mot de passe fourni n'est pas valide.",
				'invalid-password': "Le mot de passe fourni n'est pas valide.",
				'passwords-differ': 'Le mot de passe fourni ne correspond pas au précédent.',
				'invalid-input': 'Les données fournies sont incorrectes.',
				'user-already-registered': "L'utilisateur avec l'adresse e-mail spécifiée est déjà enregistré.",
				'required-agreements': 'Cette autorisation est obligatoire.',
				'agreements-required': "Les autorisations marquées d'une étoile sont obligatoires.",
				'empty-password': 'Le champ du mot de passe est obligatoire.',
				'password-minimal-length': 'Longueur minimale requise : {length}.',
				'password-minimal-lowercase-occurance': 'Nombre minimal de minuscules requis : {occurance}.',
				'password-minimal-uppercase-occurance': 'Nombre minimal de majuscules requis : {occurance}.',
				'password-minimal-number-occurance': 'Nombre minimal de chiffres requis : {occurance}.',
				'password-minimal-special-occurance': 'Nombre minimal de caractères spéciaux requis : {occurance}.',
				'disabled-in-configuration': "L'intégration a été désactivée",
				'user-profile-does-not-match': "Les données fournies ne correspondent pas au profil de l'utilisateur.",
				'email-domain-not-on-whitelist': "L'inscription de compte n'est pas possible dans ce domaine.",
				'not-allowed': {
					'user-registration-not-complete': "L'enregistrement de l'utilisateur n'est pas encore complet.",
					'user-not-verified': "L'utilisateur spécifié n'est pas vérifié.",
				},
				'one-of-two': 'Au moins un de ces champs est requis.',
				'resource-error': "Une erreur s'est produite lors de la connexion.",
				email: {
					'invalid-domain': 'Domaine non valide.',
				},
				phone: {
					'invalid-format': 'Format de numéro de téléphone non valide.',
					'invalid-length': 'Longueur de numéro de téléphone non valide.',
				},
			},
		},
		login: {
			navigate: {
				'not-account-yet': "Vous n'avez pas encore de compte?",
				register: 'Inscrivez-vous',
				'do-not-remember-data': 'Définir ou réinitialiser le mot de passe',
				'do-not-remember-identifier': "Vous ne vous souvenez pas de l'identifiant ou du mot de passe?",
			},
			integration: {
				email: {
					title: 'Connectez-vous en utilisant votre adresse e-mail',
					field: {
						email: {
							placeholder: 'Entrez votre adresse e-mail',
						},
						password: {
							placeholder: 'Entrez votre mot de passe',
						},
					},
					button: {
						login: 'Se connecter',
						check: 'Se connecter',
					},
					status: {
						'reset-password': {
							title: 'Activation du compte',
							message:
								"Le lien pour définir un mot de passe a été envoyé à l'adresse e-mail spécifiée. Pour des raisons de sécurité, le lien est valable pendant 60 minutes seulement.",
						},
					},
				},
				'imported-id': {
					title: 'Connectez-vous en utilisant votre adresse e-mail ou votre identifiant',
					field: {
						identifier: {
							placeholder: 'Entrez votre adresse e-mail ou votre identifiant',
						},
						password: {
							placeholder: 'Entrez votre mot de passe',
						},
					},
					button: {
						login: 'Se connecter',
						register: 'Inscrivez-vous',
						check: 'Se connecter',
					},
					status: {
						'reset-password': {
							title: 'Réinitialisation du mot de passe',
							message:
								"Le lien pour définir un mot de passe a été envoyé à l'adresse e-mail spécifiée. Pour des raisons de sécurité, le lien est valable pendant 60 minutes seulement.",
						},
					},
				},
				oauth: {
					facebook: 'Connectez-vous avec Facebook',
					generic: 'Connectez-vous avec un système externe',
					google: 'Connectez-vous avec Google',
					'linked-in': 'Connectez-vous avec LinkedIn',
					error: {
						title: "Une erreur inattendue s'est produite lors de la connexion",
						type: {
							general: 'La connexion via OAuth a été interrompue.',
							'user-already-registered': "L'utilisateur avec l'adresse e-mail spécifiée existe déjà.",
							'invalid-one-time-token': "Le jeton unique fourni n'est pas valide.",
							'not-allowed': {
								'connect-profiles-before-authentication':
									"L'e-mail associé au compte d'intégration est déjà dans notre base de données. Connectez-vous à la plateforme en utilisant votre adresse e-mail.",
							},
						},
					},
					return: "Cliquez ici pour revenir à la page d'accueil.",
					loading: 'Connexion en cours, veuillez patienter...',
				},
			},
		},
		register: {
			navigate: {
				'already-have-account': 'Vous avez déjà un compte?',
				login: 'Se connecter',
			},
			integration: {
				email: {
					title: 'Inscrivez-vous en utilisant votre adresse e-mail',
					field: {
						email: {
							placeholder: 'Entrez votre adresse e-mail',
						},
					},
					button: "S'inscrire",
					status: {
						ok: {
							title: 'Votre compte a été enregistré',
							message:
								"Veuillez vérifier votre boîte de réception, vous y trouverez un e-mail avec un lien d'activation.",
						},
					},
				},
				'imported-id': {
					title: "Processus d'inscription",
					field: {
						forename: {
							placeholder: 'Entrez votre prénom',
						},
						surname: {
							placeholder: 'Entrez votre nom de famille',
						},
						email: {
							placeholder: 'Entrez votre adresse e-mail',
						},
						phone: {
							placeholder: 'Entrez votre numéro de téléphone',
						},
					},
					button: {
						register: "S'inscrire",
					},
					status: {
						'email-sent': {
							title: 'Votre compte a été enregistré',
							message:
								"Veuillez vérifier votre boîte de réception, vous y trouverez un e-mail avec un lien d'activation.",
						},
					},
				},
				oauth: {
					facebook: 'Inscrivez-vous avec Facebook',
					generic: 'Inscrivez-vous avec un système externe',
					google: 'Inscrivez-vous avec Google',
					'linked-in': 'Inscrivez-vous avec LinkedIn',
				},
				token: {
					title: 'Créer un compte',
					submit: "S'inscrire",
					error: {
						'no-token': 'Jeton invalide',
					},
					status: {
						ok: {
							title: 'Votre compte a été enregistré',
						},
					},
				},
			},
		},
		'reset-password': {
			navigate: {
				'remember-password': 'Vous vous souvenez de votre mot de passe ?',
				login: 'Se connecter',
			},
			integration: {
				email: {
					title: 'Définir ou réinitialiser le mot de passe',
					instruction: {
						text:
							'Saisissez votre {email}, à laquelle nous vous enverrons un courriel contenant un lien pour définir votre mot de passe.',
						email: 'adresse électronique',
					},
					field: {
						email: {
							placeholder: 'Entrez votre adresse e-mail',
						},
					},
					button: 'Envoyer',
					status: {
						ok: {
							title: 'Réinitialisation du mot de passe',
							message:
								"Le lien pour définir un mot de passe a été envoyé à l'adresse e-mail spécifiée. Pour des raisons de sécurité, le lien est valable seulement pendant 60 minutes.",
						},
					},
				},
				'email-annoying': {
					status: {
						ok: {
							title: 'Réinitialisation du mot de passe',
							message:
								"Si l'adresse e-mail spécifiée est dans la base de données des utilisateurs, le lien pour définir un mot de passe a été envoyé.",
						},
					},
				},
				'imported-id': {
					title: 'Réinitialisation du mot de passe',
					instruction: {
						text:
							"Entrez votre {identifier}, que vous avez utilisé pour vous inscrire. Nous vous enverrons un e-mail avec un lien ou un code d'autorisation SMS pour réinitialiser votre mot de passe.",
						identifier: 'adresse e-mail ou identifiant',
					},
					field: {
						identifier: {
							placeholder: 'Entrez votre adresse e-mail ou identifiant',
						},
					},
					button: 'Envoyer',
					status: {
						'email-sent': {
							title: 'Réinitialisation du mot de passe',
							message:
								"Le lien pour définir un mot de passe a été envoyé à l'adresse e-mail spécifiée. Pour des raisons de sécurité, le lien est valable seulement pendant 60 minutes.",
						},
					},
				},
			},
			action: {
				'verify-token': {
					title: 'Problème avec le lien reçu',
					message: 'Votre lien a expiré ou est invalide',
					'reset-link': '{link} puis saisissez votre adresse e-mail.',
					'reset-link-text': 'Cliquez ici',
				},
				'set-password': {
					title: {
						reset: 'Définissez votre nouveau mot de passe',
						activate: 'Activez votre compte en définissant votre mot de passe',
					},
					field: {
						password: {
							placeholder: 'Entrez votre mot de passe',
						},
						'confirm-password': {
							placeholder: 'Confirmez votre mot de passe',
						},
					},
					button: {
						reset: 'Définir un nouveau mot de passe',
						activate: 'Activer le compte',
					},
				},
				'authorize-code': {
					title: {
						reset: "Entrez le code d'autorisation",
					},
					field: {
						'authorization-code': {
							placeholder: "Entrez le code d'autorisation",
						},
					},
				},
			},
		},
	},
	'achievement-list': {
		title: 'Réalisations',
		subtitle: 'description',
		'all-tab': 'Toutes',
		'section-title': {
			progress: 'Votre progression',
			'gain-info': 'En gagnant cette réalisation, vous recevrez :',
		},
		content: {
			'progress-description':
				'Parmi les réalisations disponibles dans la catégorie "Toutes", vous avez réussi à en obtenir :',
			requirements: 'Exigences',
		},
		error: {
			empty: 'La liste des réalisations est vide.',
		},
	},
	'achievement-details': {
		'section-title': {
			'achievement-description': 'Description de la réalisation :',
			requirements: 'Exigences pour obtenir la réalisation :',
		},
	},
	faq: {
		title: 'FAQ',
		subtitle: 'Description',
		'all-tab': 'Toutes',
		content: {
			category: 'Catégorie :',
		},
		error: {
			empty: 'La FAQ est vide.',
		},
	},
	'mission-list': {
		title: 'Missions',
		content: {
			'tasks-execution': 'Activités {completed} sur {all}',
			'earnings-information': {
				mission: ' En accomplissant toutes les tâches, vous recevrez :',
				task: ' En accomplissant cette tâche, vous recevrez :',
			},
		},
		button: {
			do: 'Exécuter',
		},
		error: {
			empty: 'Aucune mission.',
		},
	},
	'news-details': {
		subtitle: 'Ajouté : ',
		'section-title': {
			comments: 'Commentaires',
		},
	},
	'news-list': {
		title: 'Actualités',
		subtitle: 'description',
		content: {
			added: 'Ajouté',
		},
		error: {
			empty: 'La liste des actualités est vide.',
		},
	},
	'notification-list': {
		title: 'Notifications',
		subtitle: 'description',
		'all-tab': 'Toutes',
		error: {
			empty: 'Aucune notification.',
		},
	},
	'prize-list': {
		title: 'Récompenses',
		subtitle: 'description',
		'all-tab': 'Toutes',
		'section-title': {
			'items-in-your-range': 'Récompenses à votre portée',
		},
		button: {
			'buy-now': 'Acheter',
			'check-out': 'Voir',
			soon: 'Bientôt',
		},
		content: {
			amount: 'Disponible',
			count: '{remaining} sur {start}',
			'availability-items': 'Articles disponibles',
			'sold-out': 'Épuisé',
			'from-date': 'Du',
			'missing-points': 'Il vous manque :',
			price: 'Prix',
		},
		error: {
			empty: 'Aucune récompense dans cette catégorie.',
		},
	},
	'prize-details': {
		'section-title': {
			'prize-description': 'Description',
			price: 'Prix :',
			'available-currently': 'Actuellement disponible',
		},
		button: {
			'buy-now': 'Commander',
			confirm: 'Confirmer',
			resign: 'Renoncer',
			soon: 'Bientôt',
		},
		content: {
			'not-available': 'Récompense non disponible !',
			missing: 'Il vous manque :',
			calendar: {
				monday: 'l',
				tuesday: 'm',
				wednesday: 'm',
				thursday: 'j',
				friday: 'v',
				saturday: 's',
				sunday: 'd',
				'info-cloud': {
					'available-on-day': 'Récompenses disponibles ce jour-là :',
					'not-available': 'Récompense non disponible ce jour-là.',
				},
			},
			'buy-item': {
				'confirm-purchase': 'Commander',
				cancel: 'Renoncer',
				close: 'Fermer',
				ordered: 'La commande a été passée ! Merci !',
				error: "Une erreur s'est produite lors de la commande",
				address: {
					create: 'Créer une nouvelle adresse...',
				},
			},
			'sold-out': 'Épuisé',
		},
	},
	ranking: {
		title: 'Classement',
		subtitle: 'Description',
		content: {
			'your-place': {
				point__user: 'Votre position',
				user_imported: 'Votre position',
				commitment__user: 'Votre position',
				point__team: 'Position de votre équipe',
				team_imported: 'Position de votre équipe',
				point__container: '{rankingName} - Votre position',
				container_imported: '{rankingName} - Votre position',
			},
			button: {
				'log-in': 'Se connecter',
			},
		},
		error: {
			empty: 'Le classement est vide',
		},
	},
	'task-details': {
		'collection-mission': {
			title: '{name}',
			button: {
				repeat: 'Répéter',
				do: 'Exécuter',
			},
			header: {
				'your-progress': 'Votre progression :',
				'progress-status': '{completed} sur {all}',
			},
			content: {
				'gain-for-complete-mission': 'En accomplissant toutes les tâches, vous recevrez :',
				'gain-for-complete-task': 'En accomplissant cette tâche, vous recevrez :',
				'new-tasks': `{
					newTasks,
					plural,
					one {Nouvelle tâche}
					other {Nouvelles tâches}
					zero {Nouvelles tâches}
				}`,
				'done-tasks': `{
					doneTasks,
					plural,
					one {Tâche accomplie}
					other {Tâches accomplies}
					zero {Tâches accomplies}
				}`,
			},
		},
		graph: {
			content: {
				progress: 'Votre progression dans la mission',
				progressStatus: '{completed} sur {all}',
			},
		},
		mockup: {
			button: {
				leave: 'Quitter',
				continue: 'Continuer',
			},
			content: {
				'answer-status': {
					'wrong-answer': 'Mauvaise réponse - réessayez',
					'correct-answer': 'Tâche correctement exécutée',
					'try-again-in': 'Réessayez dans : ',
					gaining: 'Vous gagnez',
				},
			},
		},
		'complete-phrase-question': {
			content: {
				'task-description': 'Votre tâche est de compléter les mots manquants dans les champs.',
			},
		},
		declaration: {
			content: {
				'task-description': "Votre tâche est de déclarer l'exécution des actions suivantes.",
			},
		},
		'guess-phrase-question': {
			input: {
				'enter-answer': 'Saisissez votre réponse ici',
			},
			content: {
				'task-description': "Votre tâche est d'entrer la bonne réponse.",
			},
		},
		'retype-phrase-question': {
			content: {
				'task-description': 'Votre tâche est de retaper les mots indiqués.',
			},
		},
		'visit-link': {
			content: {
				'instruction-after': 'La tâche consistait à consulter la page :',
				'instruction-before':
					'Votre tâche est de parcourir le contenu. Cliquez sur "Exécuter" pour valider la tâche.',
			},
		},
		document: {
			button: {
				'download-pdf': 'Télécharger le PDF',
			},
		},
		'true-false': {
			button: {
				'true-claim': 'Vrai',
				'false-claim': 'Faux',
			},
		},
		join: {
			button: {
				join: 'Rejoindre',
			},
			'is-a-member-already': {
				description: 'Vous avez déjà fait un choix précédemment :',
			},
			'is-anonymous': {
				description: 'Vous ne pouvez pas effectuer cette tâche car :',
				title: "vous n'êtes pas connecté !",
				'click-button': 'Cliquez sur le bouton ci-dessous pour participer pleinement au jeu.',
				'button-text': "S'inscrire",
			},
			'join-task-crashed': {
				description: "Une erreur système s'est produite !",
				title: "Veuillez contacter l'administrateur",
			},
			'team-join-view': {
				title: 'Choisissez ci-dessous',
			},
			'user-container-join-view': {
				title: 'Choisissez ci-dessous',
			},
		},
		'user-profile-fields': {
			instruction:
				'Votre tâche est de remplir toutes les positions. Ensuite, cliquez sur "Confirmer" pour valider la tâche.',
		},
		common: {
			button: {
				continue: 'Continuer',
				check: 'Vérifier',
				do: 'Exécuter',
				'do-again': 'Exécuter à nouveau',
				leave: 'Quitter',
				confirm: 'Confirmer',
				help: 'Aide',
			},
			content: {
				'answer-status': {
					'remaining-static': 'Reste : ',
					remaining: `À accomplir {
						seconds,
						plural,
						one {reste}
						other {restent}
					}`,
					'remaining-seconds': `{
						seconds,
						plural,
						one {seconde}
						other {secondes}
					}`,
					'already-completed': 'Cette tâche a déjà été effectuée !',
					'wrong-answer-try-again': 'Vous avez mal répondu à la question - essayez à nouveau',
					'wrong-answer': 'Malheureusement, réponse incorrecte',
					'correct-answer': 'Tâche correctement réalisée',
					'try-again-in': 'Réessayez dans : ',
					gaining: 'Vous obtenez',
					crashed: "Une erreur inattendue s'est produite. Réessayez dans un moment.",
					awaiting: "La tâche sera validée dès qu'elle sera acceptée par le gestionnaire d'équipe.",
					rejected: "La tâche n'a pas été validée.",
					'rejected-reason': "La tâche n'a pas été validée. {reason}",
				},
				repetition: {
					'repetition-time-last': 'La tâche à refaire sera débloquée dans',
					'repetition-approval': 'Vous pouvez effectuer une nouvelle tâche',
					'gained-points': 'Points gagnés',
					'completion-time': "Temps d'exécution",
					completion: 'Réalisation {times}',
					days: `{
						days,
						plural,
						one {jour}
						other {jours}
					}`,
				},
			},
		},
	},
	'user-profile': {
		field: {
			mapped: {
				forename: 'Prénom',
				surname: 'Nom de famille',
				nickname: 'Pseudo',
				description: 'Description',
				email: 'E-mail',
				phone: 'Téléphone',
			},
		},
	},
	'user-details': {
		button: {
			'mobile-mode': {
				edit: 'Enregistrer',
				preview: 'Éditer',
			},
			mode: {
				edit: 'Enregistrer les modifications',
				preview: 'Éditer le profil',
			},
			edit: 'Éditer',
			'choose-photo': 'Choisir une photo',
		},
		content: {
			'user-data': {
				position: 'Position :',
				'last-activity': 'Dernière activité',
			},
			level: 'Niveau ',
			'remaining-signs': 'Reste de caractères : ',
			'edit-in-progress': 'Vous éditez votre profil',
			editField: {
				nickname: 'Pseudo',
				description: 'Description',
			},
		},
		tabs: {
			informations: 'Informations',
			notifications: 'Notifications',
		},
		agreements: {
			title: 'Vos accords',
			gdpr: {
				title: 'RGPD',
				warning:
					"Si vous retirez votre consentement, nous supprimerons votre compte dans l'application ainsi que vos données personnelles, les points gagnés et la progression dans les tâches. Un compte supprimé ne peut pas être récupéré. Cependant, vous pouvez en créer un nouveau.",
			},
			required: 'Requis',
			optional: 'Facultatif',
			confirm: 'Je confirme les modifications de consentement !',
			saveButton: 'Enregistrer',
			'accept-requirements': 'Vous devez confirmer les modifications',
			'your-agreements': 'Vos accords',
		},
	},
	'team-details': {
		button: {
			'mobile-mode': {
				edit: 'Enregistrer',
				preview: 'Éditer',
			},
			mode: {
				edit: 'Enregistrer les modifications',
				preview: 'Éditer le profil',
			},
			edit: 'Éditer',
		},
		content: {
			'remaining-signs': 'Reste de caractères : ',
			'edit-in-progress': 'Vous éditez le profil',
		},
		tabs: {
			members: 'Membres',
			tasks: 'Tâches',
			settings: 'Paramètres',
		},
		tasks: {
			status: {
				awaiting: 'a effectué la tâche {task}. Est-ce vrai ? Décidez !{br}le {date}',
				accepted: 'a effectué la tâche {task}{br}le {date}',
				rejected: "n'a pas correctement effectué la tâche {task}{br}le {date}",
			},
			button: {
				confirm: 'Confirmer',
				reject: 'Rejeter',
			},
			'rejection-modal': {
				title: 'Rejet de la tâche',
				'reason-placeholder': 'Indiquez la raison du rejet (facultatif)',
				button: 'Rejeter',
			},
		},
	},
	'create-submission': {
		return: {
			or: 'Cliquez ',
			link: 'ici',
			rest: ', pour changer le type de soumission !',
		},
		form: {
			error: {
				'too-short': 'Le nombre minimal de caractères est : {value}',
				'too-long': 'Le nombre maximal de caractères est : {value}',
				required: 'Ce champ est requis !',
			},
		},
		confirmation: {
			title: 'Soumission ajoutée',
			button: 'Terminer',
			content: {
				'line-1': 'Votre soumission a été ajoutée et est en modération.',
				'line-2': 'Nous vous informerons du changement de statut.',
			},
		},
		error: {
			title: "Une erreur inattendue s'est produite",
			content: 'Réessayez dans quelques instants.',
			button: 'Terminer',
		},
		'image-submission': {
			title: 'Votre soumission',
			form: {
				title: {
					placeholder: 'Entrez un titre',
				},
				file: 'Cliquez ici pour ajouter une photo',
				button: 'Ajouter',
			},
		},
		'submission-type-selection': {
			title: 'Participez au défi',
			subtitle: 'Choisissez le type de soumission :',
			type: {
				text: 'Ajouter un texte',
				image: 'Ajouter une image',
			},
		},
		'text-submission': {
			title: 'Votre soumission',
			form: {
				title: {
					placeholder: 'Entrez un titre',
				},
				text: {
					placeholder: 'Entrez le contenu',
				},
				button: 'Ajouter',
			},
		},
	},
	'challenge-list': {
		title: 'Défis',
		subtitle: 'description',
		loading: 'Chargement de la liste des défis en cours...',
		tabs: {
			open: 'En cours',
			future: 'À venir',
			finished: 'Terminés',
			all: 'Tous',
		},
		content: {
			'empty-list': 'Pas de défis pour le moment. Revenez bientôt ! ;-)',
			'challenge-status': {
				notStarted: 'À démarrer dans : {remainingTime}',
				inProgress: 'En cours :',
				beforeResolve: 'En attente des résultats',
				finished: 'Terminé',
			},
			'reverse-status': {
				notStarted: 'Début dans',
				inProgress: 'Il reste',
				beforeResolve: 'Résultats annoncés dans',
			},
			earnings: 'À gagner',
		},
		button: {
			takePart: 'Participer',
		},
		error: {
			empty: 'La liste des défis est vide.',
		},
		type: {
			user: 'Individuel',
			team: 'Équipe',
			'container-team': 'Par structure - Équipe',
			'container-user': 'Par structure - Individuel',
		},
	},
	'challenge-details': {
		creative: {
			type: {
				image: 'Image',
				text: 'Texte',
				video: 'Vidéo',
				gif: 'GIF',
			},
			'section-title': {
				'challenge-info': 'Détails :',
			},
			button: {
				'take-part': 'Participer',
				'add-submission-in': 'Ajouter une nouvelle soumission dans',
				'limit-reached': 'Votre limite de soumissions a été atteinte',
				'only-for-manager': "Réservé au gestionnaire d'équipe",
			},
			content: {
				'challenge-info': {
					'challenge-type': 'Type de défi',
					'creative-type': 'Créatif',
					'submission-type': 'Type de soumission',
					'submission-types': {
						video: 'Vidéo',
						image: 'Image',
						text: 'Texte',
						gif: 'GIF',
					},
					'participation-mode': 'Mode de participation',
					'submissions-limit': 'Limite de soumissions',
					'points-for-added-submission': 'Points pour une soumission ajoutée',
					'points-for-accepted-submission': 'Points pour une soumission acceptée',
				},
				'not-selected-winners': "Le défi n'a pas encore été résolu",
				remains: 'Il reste',
			},
			'menu-tabs': {
				details: 'Détails',
				'my-submissions': 'Mes soumissions',
				'recently-added-submissions': 'Récentes',
				ranking: 'Classement',
				prizes: 'Récompenses',
				winners: 'Gagnants',
			},
			'submission-details': {
				author: 'Auteur :',
				date: 'Date :',
			},
			'submission-list': {
				error: {
					empty: {
						user: 'Vous n\'avez encore rien ajouté. Changez cela, cliquez sur "Participer" ! ;-)',
						common: 'Les soumissions acceptées apparaîtront ici.',
					},
					'publication-at': {
						'line-1': 'Les soumissions ajoutées seront publiées le {date}.',
						'line-2':
							'Ne restez pas en arrière, ajoutez votre soumission dès maintenant - cliquez sur "Participer" !',
					},
				},
				content: {
					'submission-status': {
						rejected: 'Rejetée',
						accepted: 'Acceptée',
						new: 'En attente de modération',
						'reject-reason': 'Raison du rejet : {reason}',
					},
					remains: 'Il reste : {remaining-time}',
					removed: "L'utilisateur a quitté le jeu et a retiré son consentement à la publication du contenu",
				},
				button: {
					'remove-submission': 'Supprimer cette soumission',
				},
			},
		},
		kpi: {
			'section-title': {
				'challenge-info': 'Détails',
			},
			button: {
				'take-part': 'Participer',
			},
			content: {
				'challenge-info': {
					'challenge-type': 'Type de défi',
					'kpi-type': 'KPI',
					'submission-type': 'Type de soumission',
					'participation-mode': 'Mode de participation',
					'submissions-limit': 'Limite de soumissions',
					'last-import-date': 'Dernière mise à jour',
				},
				remains: 'Il reste : {remaining-time}',
			},
			'menu-tabs': {
				ranking: 'Classement',
				details: 'Détails',
				prizes: 'Récompenses',
			},
		},
		target: {
			'section-title': {
				'challenge-info': 'Détails :',
			},
			content: {
				'challenge-info': {
					'challenge-type': 'Type de défi',
					'creative-type': 'Objectif',
					'participation-mode': 'Mode de participation',
					'submission-count': 'Réalisé',
					'submissions-limit': 'Limite',
					'points-for-accepted-submission': 'Points pour une soumission acceptée',
					completed: 'Réalisé :',
				},
			},
			'menu-tabs': {
				details: 'Détails',
			},
		},
		common: {
			ranked: {
				place: 'Place {placeFrom}',
				'place-range': 'Places de {placeFrom} à {placeTo}',
			},
			prizes: {
				'no-prize-set': 'Aucune récompense.',
				points: 'Points',
			},
		},
	},
	cafeteria: {
		title: 'Cafétéria des récompenses',
		error: {
			empty: 'La cafétéria des récompenses est vide.',
			'internal-server-error': "Une erreur s'est produite lors de l'affichage de la cafétéria.",
			'access-denied': "Vous n'avez pas accès à la cafétéria.",
			'disabled-in-configuration': 'La cafétéria des récompenses est désactivée.',
			'no-budget': "Malheureusement, la récompense n'est plus disponible.",
			'too-few-points': 'Nombre insuffisant de points. Achat impossible.',
			'invalid-input': 'Le formulaire contient des erreurs.',
			'item-sold-out': 'Récompense épuisée.',
		},
		filter: {
			price: 'Prix',
			afford: 'Afficher les récompenses que je peux me permettre.',
			from: 'de',
			to: 'à',
		},
		item: {
			'buy-now': 'Acheter maintenant',
			'not-available': 'Non disponible',
			missing: 'Il vous manque',
			price: 'Prix',
		},
	},
	notification: {
		static: {
			messages: {
				submission: {
					new: 'Vous avez ajouté une soumission "{submissionTitle}" au défi "{challengeTitle}"',
					rejected:
						'Votre soumission "{submissionTitle}" dans le défi "{challengeTitle}" a été rejetée en raison de : {reason}',
					'rejected-without-reason':
						'Votre soumission "{submissionTitle}" dans le défi "{challengeTitle}" a été rejetée',
					accepted: 'Votre soumission "{submissionTitle}" dans le défi "{challengeTitle}" a été acceptée',
					awardedPlace:
						'Vous avez remporté une récompense pour avoir atteint la place {awardedPlace} dans le défi "{challengeTitle}".',
					addedSubmission: 'Soumission "{submissionTitle}" ajoutée dans le défi "{challengeTitle}".',
				},
				'declaration-task': {
					accepted: 'Votre soumission dans la tâche "{taskName}" a été acceptée',
					'rejected-reason': 'Votre soumission dans la tâche "{taskName}" a été rejetée',
					rejected: 'Votre soumission dans la tâche "{taskName}" a été rejetée',
				},
				email: {
					'remember-pass': "Envoi d'e-mail : Réinitialisation du mot de passe",
					'reset-pass': "Envoi d'e-mail : Réinitialisation du mot de passe",
					registration: "Envoi d'e-mail : Inscription au compte",
					'submission-rejected': "Envoi d'e-mail : la soumission a été rejetée",
					'submission-accepted': "Envoi d'e-mail : la soumission a été acceptée",
				},
				bonusTransaction: "Changement d'état des points",
				task: '{name}',
				item: '{name}',
				levelUp: {
					withName: 'Niveau {levelNumber} - {name}',
					withoutName: 'Niveau {levelNumber}',
				},
				achievement: '{name}',
				challenge: {
					target: '{name}',
				},
			},
			dates: {
				task: {
					'task-completed': 'Tâche accomplie : {date}',
					'mission-completed': 'Mission accomplie : {date}',
					'task-progress': 'Progression de la tâche : {date}',
				},
				achievement: "Réussite de l'exploit : {date}",
				item: "Achat de l'objet : {date}",
				email: "Réception de l'e-mail : {date}",
				submission: {
					new: 'Soumission ajoutée : {date}',
					accepted: 'Soumission acceptée : {date}',
					rejected: 'Soumission rejetée : {date}',
					awardedPlace: 'Place attribuée : {date}',
				},
				'declaration-task': {
					accepted: 'Soumission acceptée : {date}',
					rejected: 'Soumission rejetée : {date}',
				},
				bonusTransaction: 'Points recalculés : {date}',
				challenge: {
					target: 'Réalisé : {date}',
				},
			},
			'null-states': {
				task: '[Tâche supprimée]',
				achievement: '[Exploit supprimé]',
				item: '[Objet supprimé]',
				submission: '[Supprimé]',
				awardedPlace: '[Supprimé]',
			},
		},
		'real-time': {
			achievement: {
				title: 'Félicitations !',
				subtitle: 'Vous avez débloqué un exploit',
				button: {
					default: 'Recevoir',
					'without-earnings': 'Continuer',
				},
				'achievement-unlocked': 'Exploit débloqué !',
				'earn-achievement': 'Vous gagnez un exploit',
				earn: 'Vous gagnez',
			},
			challengeSubmissionStateChanged: {
				title: 'Félicitations !',
				subtitle: 'Le statut de votre soumission dans le défi a changé',
				button: {
					default: 'Continuer',
					'without-earnings': 'Continuer',
				},
				earn: 'Vous gagnez',
			},
			declarationTaskAcceptanceChanged: {
				title: 'Félicitations !',
				subtitle: 'Le statut de votre tâche a changé',
				button: {
					default: 'Continuer',
					task: 'Vérifier pourquoi',
				},
				earn: 'Vous gagnez',
			},
			task: {
				title: 'Félicitations !',
				subtitle: 'Vous avez accompli une tâche',
				button: {
					default: 'Obtenir des points',
					'without-earnings': 'Continuer',
					nextTask: 'Continuer',
				},
				congratulations: 'Félicitations !',
				'for-execution': "En échange de l'exécution de la tâche, vous recevez :",
			},
			levelUp: {
				title: 'Félicitations !',
				subtitle: 'Vous avez atteint un nouveau niveau',
				button: {
					default: 'Continuer',
					'without-earnings': 'Continuer',
				},
				earn: 'Vous gagnez',
			},
			mission: {
				title: 'Félicitations !',
				subtitle: 'Mission accomplie',
				button: {
					default: 'Recevoir',
					'without-earnings': 'Continuer',
					nextTask: 'Continuer',
				},
				congratulations: 'Félicitations !',
				'for-execution': 'En accomplissant la mission, vous recevez :',
				earn: 'En accomplissant la mission, vous recevez :',
			},
		},
	},
	currency: {
		bucks: `{
			bucks,
			plural,
			one {buck}
			other {bucksy}
		}`,
		exp: `{
			exp,
			plural,
			one {point}
			other {punkty}
		} d'expérience`,
	},
	graph: {
		tooltip: {
			mission: {
				tasks: 'Tâches',
				progress: '{completed} sur {all}',
			},
			button: {
				repeat: 'Répéter',
				execute: 'Exécuter',
			},
		},
	},
	loader: {
		text: ['Chargement des paramètres utilisateur...'],
		loading: {
			settings: "Téléchargement de l'application...",
		},
	},
	time: {
		days: `{
			time,
			plural,
			one {# jour}
			other {# jours}
		}`,
		hours: `{
			time,
			plural,
			one {# heure}
			other {# heures}
		}`,
		minutes: `{
			time,
			plural,
			one {# minute}
			other {# minutes}
		}`,
		seconds: `{
			time,
			plural,
			one {# seconde}
			other {# secondes}
		}`,
	},
	markdown: {
		'show-more': '(afficher plus)',
		'show-less': '(réduire)',
	},
	'terms-and-conditions': {
		'terms-and-conditions': 'Conditions générales',
		'privacy-policy': 'Politique de confidentialité',
		'cookie-policy': 'Politique de cookies',
	},
	'contact-list': {
		title: 'Contacts',
		search: {
			placeholder: 'Rechercher...',
		},
		tab: {
			all: 'Tous',
			active: 'Actifs',
		},
		error: {
			empty: 'Aucun résultat.',
			'internal-server-error': "Une erreur s'est produite lors de l'affichage du carnet d'adresses.",
			'access-denied': "Vous n'avez pas accès au carnet d'adresses.",
			'disabled-in-configuration': "Le carnet d'adresses est désactivé.",
		},
		pagination: {
			of: 'de',
		},
	},
	// traductions claires :
	app: {
		installation: {
			title: "Installez l'application sur votre appareil",
			subtitle: "Ajoutez l'application à l'écran d'accueil, {separator}pour un accès rapide et facile.",
			button: 'Cliquez pour installer',
		},
	},
	// traductions réutilisables pour les composants :
	common: {
		'go-back': 'Retour',
		form: {
			block: {
				fileUpload: {
					placeholder: 'Choisissez un fichier...',
					chooseButton: 'Choisir un fichier',
					deleteButton: 'Supprimer',
					processing: 'Traitement en cours...',
					addAnotherFile: 'Ajouter un autre fichier',
				},
				dropdownDefault: 'Choisissez...',
			},
		},
		view: {
			list: {
				search: {
					placeholder: 'Rechercher...',
				},
			},
		},
	},
	component: {
		form: {
			'setting-field': {
				'click-here': 'Cliquez ici',
				'click-here-to-set': 'Cliquez ici pour définir...',
			},
		},
	},
	form: {
		error: {
			required: 'Ce champ est obligatoire.',
			'check-required': 'La déclaration est obligatoire.',
			'too-short': 'Trop peu de caractères (minimum {min}).',
			'too-long': 'Trop de caractères (maximum {max}).',
			'invalid-email': 'Adresse e-mail invalide.',
			'invalid-url': 'URL invalide.',
			'invalid-postal-code': 'Code postal invalide.',
			'invalid-mobile-phone': 'Numéro de téléphone mobile invalide.',
			'min-elements': 'Trop peu de réponses (minimum {min}).',
		},
	},
	portal: {
		title: 'Bonjour {name}, voici votre plan',
		process: {
			placeholder: "Wow, c'est à quoi ressemble le tableau sans tâches. Super !",
			title: {
				duration: `Pour terminer le processus {
						duration,
						plural,
						one {reste : <span class="processList__text--highlighted"># jour</span>}
						other {restent : <span class="processList__text--highlighted"># jours</span>}
					}.&nbsp;`,
				overdue:
					'<span class="processList__text--highlighted">Déjà en retard ! Accomplissez toutes les tâches !</span>&nbsp;',
				'overdue-for-manager':
					'<span class="processList__text--highlighted">La date limite du processus est déjà passée.</span>&nbsp;',
				date: {
					'first-day': `Jusqu'au premier jour {
						time,
						plural,
						one {reste : <span class="processList__text--highlighted">#&nbsp;jour</span>}
						other {restent : <span class="processList__text--highlighted">#&nbsp;jours</span>}
					}.`,
					'key-date': `Jusqu'au début du processus {
						time,
						plural,
						one {reste : <span class="processList__text--highlighted"># jour</span>}
						other {restent : <span class="processList__text--highlighted"># jours</span>}
					}.`,
					'last-day': `Jusqu'au dernier jour {
						time,
						plural,
						one {reste : <span class="processList__text--highlighted"># jour</span>}
						other {restent : <span class="processList__text--highlighted"># jours</span>}
					}.`,
				},
			},
			report: {
				downloadReport: 'Télécharger le rapport',
				button: {
					processReport: 'Processus',
					processActionsReport: 'Actions',
				},
			},
			filter: 'Filtrer',
			'filter-all': 'Tous',
		},
		action: {
			type: {
				task: 'Tâche',
				mission: 'Mission',
				form: 'Formulaire',
			},
			deadline: {
				general: '{time} pour {type}',
				overdue: 'En retard de : {time}',
				'solidsnake-task': 'exécution',
				'solidsnake-mission': 'exécution',
				'solidsnake-form': 'remplissage',
				form: 'remplissage',
				story: 'exécution',
				page: 'exécution',
				'knowledge-check': 'exécution',
			},
			placeholder: {
				messages: {
					1: "C'est tout pour aujourd'hui, liste de tâches vidée !",
					2: "Houston, plus de tâches ! On termine pour aujourd'hui !",
					3: 'De nouvelles tâches arriveront bientôt, continuez simplement à nager !',
					4: "Si les tâches sont des nuages, alors aujourd'hui s'annonce ensoleillé ! Profitez-en !",
					5: "Abracadabra ! Et toutes les tâches disparaissent ! Ne vous inquiétez pas, il y en aura d'autres.",
					6: "Il n'y a plus de tâches ici pour le moment. Mais nous avons un super cactus. Regardez comme il est cool !",
					7: "Il n'y a pas de sens à creuser plus profondément, plus de tâches ici pour le moment.",
				},
				nextAction:
					'Attendez-vous à de nouvelles tâches : <span class="color-blue font-weight-normal">{date}</span>',
				nextActionUnknownDate: 'Attendez-vous à d’autres tâches prochainement !',
			},
			'assign-role': {
				placeholder: 'Attribuer le rôle de {roleName}',
				confirm: 'Confirmer',
			},
		},
		tasks: {
			title: 'Votre plan',
		},
		contacts: {
			title: 'Contacts',
			roleTitle: 'Rôle',
			role: {
				manager: 'Manager',
			},
		},
		'knowledge-base': {
			title: 'Knowledge base',
		},
		employees: {
			yours: 'Votre équipe',
		},
	},
};
