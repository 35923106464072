import { UserProfileFields } from 'models/user/userProfileField/UserProfileFields';
import { UserProfileField } from 'models/user/userProfileField/UserProfileField';

export class UserProfileFieldService {
	constructor(userProfileFieldDefinitionService) {
		this._userProfileFieldDefinitionService = userProfileFieldDefinitionService;
	}

	toModel(fields) {
		return new UserProfileFields(
			...fields.map(
				field =>
					new UserProfileField({
						definition:
							field.definition && this._userProfileFieldDefinitionService.toModel(field.definition),
						value: field.value,
					})
			)
		);
	}
}
