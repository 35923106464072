import Submission from 'models/challenge/submission/Submission';

/**
 * Class representing a text submission.
 * @extends Submission
 */
export default class TextSubmission extends Submission {
	constructor(submission) {
		super(submission);

		this._text = submission.text;
	}

	text() {
		return this._text;
	}

	static MAX_TEXT_LENGTH = 135;
}
