import { UserPointRanking } from 'models/ranking/pointRanking/UserPointRanking';
import { PointRankingService } from 'services/ranking/pointRanking/PointRankingService';

export class UserPointRankingService extends PointRankingService {
	toModel(ranking) {
		return new UserPointRanking({
			...ranking,
			filterable_container_group:
				ranking.filterable_container_group && this.containerService.toModel(ranking.filterable_container_group),
		});
	}
}
