import React from 'react';
import shortid from 'shortid';
import classnames from 'classnames';

import images from 'assets/images';
import TermsAndConditions from './TermsAndConditions';

export default function(BaseWrapperMenu, LanguageSwitcher, Anchor, accessPopupService, Image, applicationService) {
	return class WrapperMenu extends BaseWrapperMenu {
		render() {
			const termsAndConditions = applicationService.settings().termsAndConditions();
			const menu = applicationService.menu();

			return (
				<section
					className="header el--shrink"
					ref={e => {
						this.elementRefs.header = e;
					}}
				>
					<div
						className={classnames({
							transparent: applicationService
								.settings()
								.colorScheme()
								.isMenuTransparent(),
						})}
					>
						<div className="containerHeader wrapper--row align--center justify--end mx-3 mx-sm-5">
							{/*<div className="returnArrow__wrapper wrapper--row align--center justify--center el--shrink">
								<NavLink
									to="/"
									className="returnArrow el--shrink"
									onClick={event => this.goBack(event)}
								/>
							</div>*/}
							{menu._logo && (
								<div className="containerHeader__logoWrapper wrapper--row align--center">
									<Anchor position={menu._logo} className="el--grow wrapper--row align--center">
										<Image className="logo__desktop" src={menu._logo.image} alt="[logo]" />
										<Image
											className="logo__mobile"
											src={menu._logo.mobileImage || menu._logo.image}
											alt="[logo]"
										/>
									</Anchor>
								</div>
							)}
							<div className="mobileMask" />
							<div className="containerHeader__menu wrapper--row align--center justify--end">
								<nav
									className="menu wrapper--row justify--end"
									ref={e => {
										this.elementRefs.nav = e;
									}}
								>
									<div
										className="wrapper--row"
										ref={e => {
											this.elementRefs.menu = e;
										}}
									>
										<ul className="wrapper--row align--center desktopMenu">
											{menu._desktopPositions.map(position => (
												<li className="wrapper--col mr-2" key={shortid.generate()}>
													<Anchor
														position={position}
														className={classnames(
															'el--grow wrapper--row align--center justify--center fs-5',
															{
																hasIcon: !!position.image,
															}
														)}
														activeClassName="active"
														onClick={event => {
															if (position.anchor.anonymous) {
																event.preventDefault();
																accessPopupService.open();
															}
														}}
													>
														{position.image && <Image src={position.image} />}
														<span>{position.title}</span>
													</Anchor>
												</li>
											))}
										</ul>
										<LanguageSwitcher />
										{menu._more && (
											<div
												className={classnames('more wrapper--row align--center pl-4', {
													iconWrapper: menu._more.image,
												})}
												ref={e => {
													this.elementRefs.more = e;
												}}
											>
												<div
													title={menu._more.title}
													className="wrapper--row align--center justify--center desktopMenuPosition"
												>
													{menu._more.image && <Image src={menu._more.image} />}
													{!menu._more.image && (
														<div className="more__text">
															<span className="moreTitle">{menu._more.title}</span>
														</div>
													)}
												</div>
												<div
													className="mobileMenuWrapper"
													ref={e => {
														this.elementRefs.mobileMenu = e;
													}}
												>
													<div className="mobileMenu__wrapper wrapper--col el--shrink">
														<div className="mobileMenu__wrapper__header"></div>
														<ul
															className="mobileMenu__list wrapper--col overflow--auto"
															ref={e => {
																this.elementRefs.mobileMenuList = e;
															}}
														>
															{menu._mobilePositions.map(position => (
																<li
																	className="mobileMenu__item"
																	key={shortid.generate()}
																>
																	<Anchor
																		position={position}
																		className="wrapper--row align--center"
																		activeClassName="active"
																		onClick={event => {
																			if (position.anchor.anonymous) {
																				event.preventDefault();
																				accessPopupService.open();
																			}
																		}}
																	>
																		{position.image && (
																			<Image src={position.image} />
																		)}
																		<span
																			className={classnames(
																				'd-block',
																				'w-100',
																				!!position.image ? 'pl-3' : 'pl-5'
																			)}
																		>
																			{position.title}
																		</span>
																	</Anchor>
																</li>
															))}
														</ul>
														<div className="mobileMenu__footer d-flex flex-grow-1 align-items-end">
															<div className="d-flex w-100 flex-column align-items-center">
																<div
																	className="image image--contain"
																	style={{
																		backgroundImage: `url(${images.example.gamfiLogo})`,
																	}}
																></div>
																<TermsAndConditions
																	termsAndConditions={termsAndConditions}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
								</nav>
							</div>
							{menu._imagePositions &&
								menu._imagePositions.map(imagePosition => (
									<div
										className="additionalImages wrapper--row align--center"
										key={shortid.generate()}
									>
										<Anchor
											position={imagePosition}
											className="el--grow wrapper--row align--center"
										>
											<Image
												className="visible--mobile"
												src={imagePosition.mobileImage || imagePosition.image}
												alt="[logo]"
												title={imagePosition.title}
											/>
											<Image
												className="hidden--mobile"
												src={imagePosition.image}
												alt="[logo]"
												title={imagePosition.title}
											/>
										</Anchor>
									</div>
								))}
						</div>
					</div>
				</section>
			);
		}
	};
}
