import FormBlock from 'models/common/form/FormBlock';

export default class FileUploadFormBLock extends FormBlock {
	constructor(formBlock) {
		super(formBlock);

		this._body = formBlock.body;
	}

	body() {
		return this._body;
	}
}
