import React, { PureComponent } from 'react';
import Async from 'react-async-action';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import GamfiGraph from 'gamfi-graph/dist/app';

export default function(taskService, graphService, graphInteractionService, colorService, Currencies) {
	return class MapTask extends PureComponent {
		_graphElement = React.createRef();

		constructor(...args) {
			super(...args);
			this.state = {
				viewport: null,
			};

			this.__cachedResizeHandler = () => this._changeViewport();
			this.__cachedHandleInteraction = (...params) => graphInteractionService.handleInteraction(...params);

			window.addEventListener('resize', this.__cachedResizeHandler);
		}

		properties() {
			return {
				viewport: this.state.viewport,
				ref: this._graphElement,
				interactionHandler: this.__cachedHandleInteraction,
				colorService,
			};
		}

		_action() {
			return graphService.fetchDetails({ id: this.props.task.graphId() });
		}

		_increase() {
			if (this._graphElement) {
				this._graphElement.current.zoomIn();
			}
		}

		_decrease() {
			if (this._graphElement) {
				this._graphElement.current.zoomOut();
			}
		}

		_assignGraphParent(graphParent) {
			if (graphParent) {
				this._graphParent = graphParent;
				this.__cachedResizeHandler();
			}
		}

		_changeViewport() {
			if (this._graphParent) {
				const newViewport = {
					x: this._graphParent.offsetWidth,
					y: this._graphParent.offsetHeight,
				};

				if (
					!this.state.viewport ||
					this.state.viewport.x !== newViewport.x ||
					this.state.viewport.y !== newViewport.y
				) {
					this.setState({ viewport: newViewport });
				}
			}
		}

		componentWillUnmount() {
			window.removeEventListener('resize', this.__cachedResizeHandler);
		}

		render() {
			const { task } = this.props;

			return (
				<Async action={() => this._action()}>
					<Async.Resolved>
						{({ response: graph }) => (
							<div className="wrapper--col el--grow">
								<div className="view view__missionPreview wrapper--col">
									<div className="wrapper--row el--shrink">
										<div className="viewTitleBox wrapper--row">
											<div className="el--grow viewTitleBox__contentWrapper">
												<div className="wrapper--row justify--between align--center">
													<div className="viewTitleBox__titleSection wrapper--row justify--between el--grow">
														<h1 className="mb-0 mb-md-3">{task.name()}</h1>
													</div>
												</div>
												<div className="viewTitleBox__descriptionSection wrapper--col d-none d-md-flex">
													<div className="subtitle">{task.description()}</div>
												</div>
											</div>
											<div className="missionInfoWrapper wrapper--row el--shrink">
												<div className="missionProgress wrapper--col hidden--mobile el--shrink hidden--desktop hidden--mobile">
													<p className="standardText standardText--noMargin">
														<FormattedMessage id="task-details.graph.content.progress" />
													</p>
													<div className="progressBar">
														<div
															className={classnames('progressBar__content', {
																followProgress: task.state().progress() > 0.35,
															})}
															style={{
																width: `${Math.round(task.state().progress() * 100)}%`,
															}}
														>
															<div className="progressBar__number wrapper--row">
																<div className="el--shrink">{`${
																	task.state().counts().completed
																} z ${task.state().counts().all}`}</div>
															</div>
														</div>
													</div>
												</div>
												<div className="wrapper--row hidden--mobile hidden--laptop el--shrink hidden--mobile">
													{task.state().counts().unseen ? (
														<div className="statsWrapper wrapper--row align--start">
															<div className="statsWrapper__text el--shrink wrapper--row align--center">
																<FormattedMessage
																	id="task-details.collection-mission.content.new-tasks"
																	values={{
																		newTasks: task.state().counts().unseen,
																	}}
																/>
															</div>
															<div className="wrapper--row align--center">
																<div className="statsWrapper__number">
																	{task.state().counts().unseen}
																</div>
															</div>
														</div>
													) : null}
													{task.state().counts().completed ? (
														<div className="statsWrapper wrapper--row align--start">
															<div className="statsWrapper__text el--shrink wrapper--row align--center align--start">
																<FormattedMessage
																	id="task-details.collection-mission.content.done-tasks"
																	values={{
																		doneTasks: task.state().counts().completed,
																	}}
																/>
															</div>
															<div className="wrapper--row align--center">
																<div className="statsWrapper__number">
																	{task.state().counts().completed}
																</div>
															</div>
														</div>
													) : null}
												</div>
												{task.hasCompletionEarnings() && (
													<div className="statsWrapper wrapper--row align--start el--shrink hidden--mobile">
														<div className="statsWrapper__text el--shrink wrapper--row align--center align--start">
															<FormattedMessage id="task-details.collection-mission.content.gain-for-complete-mission" />
														</div>
														<Currencies
															parentClasses="currenciesWrapper wrapper--row"
															currencies={task.earningsInformation().completion}
														/>
													</div>
												)}
											</div>
										</div>
									</div>
									<div
										className="mainContentWrapper wrapper--col el--grow"
										ref={graphParent => this._assignGraphParent(graphParent)}
									>
										<div>
											<div className="graphContainer wrapper--col justify--between">
												<GamfiGraph graph={graph} {...this.properties()} />
												<div className="sectionZoom wrapper--row align--center">
													<div className="roundNumber" onClick={() => this._increase()}>
														<div className="number plus" />
													</div>
													<div className="roundNumber" onClick={() => this._decrease()}>
														<div className="number minus" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</Async.Resolved>
				</Async>
			);
		}
	};
}
