import moment from 'moment';
import _ from 'lodash';

/**
 * Class representing a news.
 */
export default class News {
	/**
	 * @param {Object} news
	 */
	constructor(news = {}) {
		/**
		 * @type {string}
		 */
		this.id = news.id;
		/**
		 * @type {string}
		 */
		this._title = news.title;
		/**
		 * @type {string}
		 */
		this._body = news.body;
		/**
		 * @type {string}
		 */
		this._listImage = news.list_image;

		/**
		 * @type {Date}
		 * @private
		 */
		this._createdAt = moment(news.created_at);

		/**
		 * @type {NewsTag[]}
		 * @private
		 */
		this._tags = news.tags;
	}

	title() {
		return this._title;
	}

	body() {
		return this._body;
	}

	tags() {
		return _.join(
			this._tags.map(tag => tag.name),
			', '
		);
	}

	listImage() {
		return this._listImage;
	}

	/**
	 * @param {string} format
	 * @returns {string}
	 */
	createdAt(format = News.DATE_FORMAT) {
		return this._createdAt.format(format);
	}

	/**
	 * @type {string}
	 */
	static DATE_FORMAT = 'DD-MM-YYYY HH:mm:ss';
}
