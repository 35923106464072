import RequiredAgreements from 'components/application/requiredAgreements/RequiredAgreements';

export default {
	'components.RequiredAgreements': {
		factory: RequiredAgreements,
		deps: [
			{ $ref: 'services.userService' },
			{ $ref: 'services.navigationService' },
			{ $ref: 'components.RequiredAgreements.Template' },
		],
	},
};
