import React, { Fragment } from 'react';
import { ThemeProvider as JSSProvider } from 'react-jss';

import theme from 'components/_/theme';

import Fonts from 'components/_/Fonts';

export const ThemeProvider = ({ colorService, children }) => {
	return (
		<Fragment>
			<Fonts />
			<JSSProvider theme={theme(colorService)}>{children}</JSSProvider>
		</Fragment>
	);
};
