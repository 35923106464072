import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';

import UserPanel from './UserPanel.template';

export class UserPanelComponent extends PureComponent {
	componentDidMount() {
		this._unregisterListener = this.props['services.userProfileService'].addChangeListener(() =>
			this.forceUpdate()
		);
	}

	render() {
		const user = this.props['services.userProfileService'].user();
		const level = this.props['services.levelService'].level(user.level, user.wallet);

		return <UserPanel user={user} level={level} />;
	}

	componentWillUnmount() {
		this._unregisterListener();
	}
}

export default wire(['services.userProfileService', 'services.levelService'], UserPanelComponent);
