import BaseChallengeSubmissionService from 'services/challenge/submission/BaseChallengeSubmissionService';

import TextSubmission from 'models/challenge/submission/TextSubmission';

export default class ChallengeTextSubmissionService extends BaseChallengeSubmissionService {
	toModel({ challenge, user_profile, ...rest }) {
		return new TextSubmission({
			challenge: challenge && this._challengeService.toModel(challenge),
			userProfile: user_profile && this._userProfileService.toModel(user_profile),
			...rest,
		});
	}
}
