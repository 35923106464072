import BaseLayout from 'components/task/layout/BaseLayout';

export default {
	'components.task.layout.BaseLayout': {
		factory: BaseLayout,
		deps: [
			{ $ref: 'components.task.common.Mobile' },
			{ $ref: 'components.task.common.Content' },
			{ $ref: 'components.task.common.Footer' },
			{ $ref: 'components.base.DegressionCurrencies' },
		],
	},
};
