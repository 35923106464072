import BaseChallengeAwardedPlaceService from 'services/challenge/awardedPlace/BaseChallengeAwardedPlaceService';

import ChallengeUserAwardedPlace from 'models/challenge/awardedPlace/ChallengeUserAwardedPlace';

export default class ChallengeUserAwardedPlaceService extends BaseChallengeAwardedPlaceService {
	constructor(userService, ...services) {
		super(...services);

		this._userService = userService;
	}

	toModel({ users, ...rest }) {
		return new ChallengeUserAwardedPlace({
			...super.toModel(rest),
			users: users.map(user => this._userService.toModel(user)),
		});
	}
}
