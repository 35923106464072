import React from 'react';
import { injectIntl } from 'react-intl';

import { Modal } from 'components/_/common/modal/Modal';

export const RejectionModal = injectIntl(({ intl, close, onSubmit }) => {
	const formRef = React.createRef();

	return (
		<Modal close={close}>
			{() => (
				<div className="task__modal">
					<form className="form px-3 px-sm-6">
						<h1 className="my-3 my-sm-4">
							{intl.formatMessage({ id: 'team-details.tasks.rejection-modal.title' })}
						</h1>
						<textarea
							ref={formRef}
							placeholder={intl.formatMessage({
								id: 'team-details.tasks.rejection-modal.reason-placeholder',
							})}
						/>
						<button
							className="btn__basic btn__basic--red my-3 my-sm-4"
							onClick={() => onSubmit(formRef.current.value)}
						>
							<span>{intl.formatMessage({ id: 'team-details.tasks.rejection-modal.button' })}</span>
						</button>
					</form>
				</div>
			)}
		</Modal>
	);
});
