export default class TaskRepetitionState {
	/**
	 * @param {Object} repetitionState
	 */
	constructor(repetitionState) {
		/**
		 * @type {string}
		 * @private
		 */
		this._id = repetitionState.id;
		/**
		 * @type {Currencies}
		 * @private
		 */
		this._earned = repetitionState.earned;
		/**
		 * @type {boolean}
		 * @private
		 */
		this._failed = repetitionState.failed;
		/**
		 * @type {number}
		 * @private
		 */
		this._failedAttempts = repetitionState.failed_attempts;
		/**
		 * @type {TaskState}
		 * @private
		 */
		this._taskState = repetitionState.task_state;

		/**
		 * "open" | "awaiting" | "accepted" | "rejected"
		 * @type {string}
		 * @private
		 */
		this._acceptanceState = repetitionState.acceptance_state;

		/**
		 * @type {string}
		 * @private
		 */
		this._rejectionReason = repetitionState.rejection_reason;

		/**
		 * @type {string}
		 * @private
		 */
		this._completedAt = repetitionState.completed_at;
	}

	/**
	 * @returns {string}
	 */
	id() {
		return this._id;
	}

	/**
	 * @returns {Object}
	 */
	earned() {
		return this._earned;
	}

	/**
	 * @returns {boolean}
	 */
	failed() {
		return this._failed;
	}

	/**
	 * @returns {number}
	 */
	failedAttempts() {
		return this._failedAttempts;
	}

	/**
	 * @returns {TaskState}
	 */
	taskState() {
		return this._taskState;
	}

	acceptanceState() {
		return this._acceptanceState;
	}

	rejectionReason() {
		return this._rejectionReason;
	}

	completedAt() {
		return this._completedAt;
	}
}
