import React from 'react';
import { useQuery } from 'react-query';
import { wire } from 'react-hot-wire';
import { IntlProvider } from 'react-intl';

import Loader from 'components/commons/_/loader/Loader';
import { ThemeProvider } from 'providers/ThemeProvider';

const useQueryOptions = {
	refetchOnWindowFocus: false,
	refetchOnmount: false,
	refetchOnReconnect: false,
	retry: false,
};

/**
 * /application/config must be fire unfortunately BEFORE user - so user request must wait for settings.
 * The reason for this is that the "ThemeProvider" and "IntlProvider" before render must have app settings (e.g. Fonts).
 * Also Error component (catcher errors from API) must looking as nice view (with colors and fonts from settings).
 */
export const Init = ({ children, ...props }) => {
	const masterService = props['services.masterService'];
	const i18nService = props['services.i18nService'];
	const colorService = props['services.colorService'];

	const { isLoading: isConfigLoading } = useQuery('applicationConfig', () => masterService.config(), useQueryOptions);

	return isConfigLoading ? (
		<Loader />
	) : (
		<IntlProvider locale={i18nService.locale()} messages={i18nService.fetchMessages()}>
			<ThemeProvider colorService={colorService}>{children()}</ThemeProvider>
		</IntlProvider>
	);
};

export default wire(['services.masterService', 'services.i18nService', 'services.colorService'], Init);
