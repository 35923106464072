export interface GoogleTagManagerDTO {
	id: string;
	events: string[];
}

export default class GoogleTagManager {
	private _id: string;
	private _events: string[];

	constructor(googleTagManager: GoogleTagManagerDTO) {
		this._id = googleTagManager.id;
		this._events = ['authentication', 'logout'];
	}

	id() {
		return this._id;
	}

	events() {
		return this._events;
	}
}
