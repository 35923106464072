import TaskBaseService from 'services/task/TaskBaseService';

import UserProfileFieldsTask from 'models/task/userProfileFields/UserProfileFieldsTask';

export default class UserProfileFieldsTaskService extends TaskBaseService {
	constructor(taskStateService, userProfileFieldService) {
		super(taskStateService);

		this._userProfileFieldService = userProfileFieldService;
	}

	toModel(task) {
		return new UserProfileFieldsTask({
			...task,
			state: this._mapState(task),
			user_profile_fields:
				task.user_profile_fields && this._userProfileFieldService.toModel(task.user_profile_fields),
		});
	}
}
