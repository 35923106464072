import { FormContentBlock } from './FormContentBlock';

export class ImageFormBlock extends FormContentBlock {
	constructor(formBlock) {
		super(formBlock);

		this._image = formBlock.image;
	}

	image() {
		return this._image;
	}
}
