import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remark from 'remark';
import stripMarkdown from 'strip-markdown';
import { FormattedMessage } from 'react-intl';

import useStyles from './Markdown.jss';

export const Markdown = ({
	children,
	className,
	containerTagName = 'div',
	strip = false,
	// limit must be set with "strip" flag
	limit = Infinity,
}) => {
	const classes = useStyles();
	const [showMore, setShowMore] = useState(false);

	if (!children) {
		return null;
	}

	let source = remark()
		.use(strip ? stripMarkdown : null)
		.processSync(children).contents;

	if (source.length > limit) {
		if (!strip) {
			throw new Error('Limit without strip property can not be set.');
		}

		source = source.slice(0, limit);

		// ignore last char if exists - one of forbidden
		if ([' ', ',', '.'].includes(source[source.length - 1])) {
			source = source.slice(0, -1);
		}

		source += '...';
	}

	const [initialText, moreText] = source.split('[more]');

	return (
		<div className={className}>
			<ReactMarkdown
				source={showMore ? initialText + moreText : initialText}
				containerTagName={containerTagName}
				className={classes.markdown}
				renderers={{
					link: props => (
						<a href={props.href} target="_blank" rel="noopener noreferrer">
							{props.children}
						</a>
					),
				}}
			/>
			{moreText && (
				<a
					href="/"
					onClick={event => {
						event.preventDefault();
						setShowMore(prevState => !prevState);
					}}
				>
					<FormattedMessage id={showMore ? 'markdown.show-less' : 'markdown.show-more'} />
				</a>
			)}
		</div>
	);
};
