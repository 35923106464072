import FormBlock from 'models/common/form/FormBlock';

export default class Question extends FormBlock {
	constructor(question) {
		super(question);

		this._body = question.body;
	}

	body() {
		return this._body;
	}
}
