import ExternallyAcquiredAchievementService from 'services/achievement/subServices/ExternallyAcquiredAchievementService';
import StandaloneAchievementService from 'services/achievement/subServices/StandaloneAchievementService';

export default {
	'services.externallyAcquiredAchievementService': {
		class: ExternallyAcquiredAchievementService,
	},
	'services.standaloneAchievementService': {
		class: StandaloneAchievementService,
	},
};
