import React from 'react';
import { useQuery } from 'react-query';
import { wire } from 'react-hot-wire';

import Loader from 'components/commons/_/loader/Loader';
import Installation from 'components/_/app/installation/Installation';

import Error from './error/Error';

const useQueryOptions = {
	refetchOnWindowFocus: false,
	refetchOnmount: false,
	refetchOnReconnect: false,
	retry: false,
};

export const InitMaster = ({ children, ...props }) => {
	const masterService = props['services.masterService'];
	const PWAInstallationService = props['services.PWAInstallationService'];

	const AccessPopup = props['components.AccessPopup'];
	const RealTimeNotifications = props['components.RealTimeNotifications'];

	const { isLoading: isInitLoading } = useQuery('mainQuery', () => masterService.init(), useQueryOptions);

	// IntlProvider must be before Error component (for translate general errors)
	return (
		<Error>
			{() =>
				isInitLoading ? (
					<Loader />
				) : (
					<main className="wrapper--col justify--end">
						<AccessPopup />
						{children()}
						<RealTimeNotifications />
						{PWAInstallationService.canInstall() && <Installation />}
					</main>
				)
			}
		</Error>
	);
};

export default wire(
	[
		'services.masterService',
		'services.PWAInstallationService',
		// components (TODO (poc: rootsher, 16.06.2021): rewrite to use react-hot-wire):
		'components.AccessPopup',
		'components.RealTimeNotifications',
	],
	InitMaster
);
