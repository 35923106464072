import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import when from 'when';
import _ from 'lodash';
import uuid from 'uuid/v4';

import AccessTokenService from './application/AccessTokenService';
import NavigationService from './application/NavigationService';
import AppConfig from 'models/AppConfig';

export type RequestOptions = {
	withoutToken?: boolean;
	omitErrorHandlers?: boolean;
	version?: 'v1' | 'v2';
	controller?: 'application' | 'workflows';
};
export type RedirectResponse = {
	url: string;
};
export type BackendResponse<T = any> = {
	code: number;
	response?: T;
	error?: any;
	redirect?: RedirectResponse;
};
export type ErrorHandler = () => any;

export enum ContentType {
	JSON = 'application/json',
	FORM_DATA = 'multipart/form-data',
	XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export default class APIService {
	private windowId: string;
	private axios: AxiosInstance;
	private errorHandlers: ErrorHandler[];

	constructor(
		private readonly accessTokenService: AccessTokenService,
		private readonly navigationService: NavigationService,
		private readonly applicationConfig: AppConfig
	) {
		this.windowId = uuid();
		this.axios = axios.create({
			baseURL: this.applicationConfig.BASE_URL,
		});
		this.errorHandlers = [];
	}

	get<T = any>(url: string, data?: any, requestConfig?: AxiosRequestConfig, options?: RequestOptions, params?: any) {
		return this.sendRequest<T>('get', url, data, requestConfig, options, params);
	}

	post<T = any>(url: string, data?: any, requestConfig?: AxiosRequestConfig, options?: RequestOptions, params?: any) {
		return this.sendRequest<T>('post', url, data, requestConfig, options, params);
	}

	addErrorHandler(onReject: ErrorHandler) {
		this.errorHandlers.push(onReject);

		return () => {
			this.errorHandlers = this.errorHandlers.filter(handler => handler !== onReject);
		};
	}

	private handleRejection(promise: Promise<any>, omit?: boolean) {
		return omit ? promise : this.errorHandlers.reduce((_promise, handler) => _promise.catch(handler), promise);
	}

	private sendRequest<T = any>(
		method: string,
		url: string,
		data = {},
		requestConfig: AxiosRequestConfig = {},
		options: RequestOptions = { withoutToken: false, omitErrorHandlers: false },
		_params: any = {}
	): Promise<T> {
		const params: any = _params;
		const splitUrl = url.split('/');
		let version = options?.version || splitUrl[0] || splitUrl[1];

		if (!_.includes(['post', 'put', 'path'], method)) {
			Object.assign(params, data);
		}

		const accessToken = this.accessTokenService.accessToken('application');
		const impersonateUserId = this.accessTokenService.impersonateUserId();
		const impersonateUserProfileId = this.accessTokenService.impersonateUserProfileId();

		const headers = {
			'X-Window-Id': this.windowId,
			'X-Samus-Authorization': accessToken && !options.withoutToken && `Bearer ${accessToken}`,
			...requestConfig.headers,
		};

		requestConfig.headers = headers;

		if (impersonateUserId && !options.withoutToken) {
			params._switch_user = impersonateUserId;
		}

		if (options.controller === 'workflows') {
			requestConfig.baseURL = this.applicationConfig.WORKFLOWS_BASE_URL;

			if (params._switch_user) {
				params._switch_user = impersonateUserProfileId;
			}
		}

		switch (version) {
			case 'v1':
			default:
				return this.handleV1Response(method, url, params, requestConfig, data, options);
			case 'v2':
				return this.handleV2Response(method, url, params, requestConfig, data, options);
		}
	}

	private logout() {
		this.accessTokenService.clear();
		this.navigationService.load('/auth/login');
	}

	private handleV1Response<T>(
		method: string,
		url: string,
		params: any,
		requestConfig: AxiosRequestConfig,
		data: Record<string, unknown>,
		options: RequestOptions
	): Promise<T> {
		const request: any = when<AxiosResponse>(
			this.axios.request({
				method,
				url,
				params,
				data,
				...requestConfig,
			})
		).catch(
			({ response }) => _.includes([401, 403], response.status),
			() => this.logout()
		);

		return this.handleRejection(
			request.then((backendResponse: AxiosResponse) => {
				const {
					data: { code, response, error, redirect },
					headers,
				} = backendResponse;

				if (headers['content-type'] === ContentType.XLSX) {
					return Promise.resolve(backendResponse.data);
				} else {
					if (code === 204) {
						return Promise.resolve(undefined);
					} else if (code === 200) {
						return Promise.resolve(response);
					} else if (code === 303) {
						return Promise.resolve(redirect);
					} else if (_.includes([400, 401, 403, 404], code)) {
						return Promise.reject({ error, url });
					} else {
						throw new Error('resource-error');
					}
				}
			}),
			options.omitErrorHandlers
		);
	}

	private handleV2Response<T>(
		method: string,
		url: string,
		params: any,
		requestConfig: AxiosRequestConfig,
		data: Record<string, unknown>,
		options: RequestOptions
	): Promise<T> {
		return this.handleRejection(
			new Promise((resolve, reject) =>
				this.axios
					.request({ method, url, params, data, ...requestConfig })
					.then(response => resolve(response.data))
					.catch(error => {
						if (_.includes([401, 403], error.response.code)) {
							this.logout();
						}

						reject({ error: error.message, url });
					})
			)
		);
	}
}
