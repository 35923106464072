import moment from 'moment';

import MockupBoundEvent from 'models/task/mockup/mockupTask/MockupBoundEvent';

/**
 * Class representing a mockup.
 */
export default class Mockup {
	/**
	 * @param {Object} mockup
	 */
	constructor(mockup = {}) {
		/**
		 * @type {string}
		 */
		this.id = mockup.id;
		/**
		 * @type {string}
		 */
		this.pathPrefix = mockup.path_prefix;
		/**
		 * @type {Moment}
		 */
		this.lastUpdate = moment(mockup.last_update);
		/**
		 * @type {string}
		 */
		this.startPath = mockup.start_path;
		/**
		 * @type {MockupBoundEvent[]}
		 */
		this.boundEvents = (mockup.bound_events || []).map(event => new MockupBoundEvent(event));
	}
}
