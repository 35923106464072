import { ContainerPointRankingEntryService } from './ContainerPointRankingEntryService';
import { TeamPointRankingEntryService } from './TeamPointRankingEntryService';
import { UserPointRankingEntryService } from './UserPointRankingEntryService';

export default {
	'services.ranking.containerPointRankingEntryService': {
		class: ContainerPointRankingEntryService,
	},
	'services.ranking.teamPointRankingEntryService': {
		class: TeamPointRankingEntryService,
	},
	'services.ranking.userPointRankingEntryService': {
		class: UserPointRankingEntryService,
	},
};
