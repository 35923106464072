import React from 'react';
import { wire } from 'react-hot-wire';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';

import useStyles from './GoBack.jss';

const GoBack = props => {
	const {
		className,
		intl,
		goBack: { defaultLocation, location },
	} = props;
	const browserHistoryService = props['services.browserHistoryService'];
	const goBack = () =>
		location ? browserHistoryService.replace(location) : browserHistoryService.goBack({ defaultLocation });
	const classes = useStyles({
		tooltip: intl.formatMessage({ id: 'common.go-back' }),
	})();

	return (
		<div className={classnames(className, classes.goBack)} onClick={goBack}>
			<div className={classes.arrows} />
		</div>
	);
};

export default wire(['services.browserHistoryService'], injectIntl(GoBack));
