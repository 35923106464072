import modes from 'components/task/types/question/trueFalse/modes';
import TrueFalseTask from 'components/task/types/question/trueFalse/TrueFalseTask';
import TrueFalseAnswer from 'components/task/types/question/trueFalse/TrueFalseAnswer';

export default {
	...modes,
	'components.TrueFalseTask': {
		factory: TrueFalseTask,
		deps: [
			{
				custom: { $ref: 'components.TrueFalseTask.mode.custom' },
				'happy-sad': { $ref: 'components.TrueFalseTask.mode.happySad' },
				'plus-minus': {
					$ref: 'components.TrueFalseTask.mode.plusMinus',
				},
				'true-false': {
					$ref: 'components.TrueFalseTask.mode.trueFalse',
				},
			},
			{ $ref: 'components.task.layout.BaseLayout' },
			{ $ref: 'components.TrueFalseTask.mode' },
			{ $ref: 'components.MatchingImageType' },
		],
	},
	'components.TrueFalseTask.mode': {
		factory: TrueFalseAnswer,
	},
};
