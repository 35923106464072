import { createUseStyles } from 'react-jss';

export default ({ size = 80 }) => {
	const teamSize = size / 3.2;
	const levelSize = size / 3.2;

	const miniCircle = ({ size }) => ({
		position: 'absolute',
		width: `${size}px`,
		height: `${size}px`,
		borderRadius: `${size}px`,
	});

	return createUseStyles(theme => ({
		container: {
			position: 'relative',
			width: `${size}px`,
			height: `${size}px`,
			fontSize: '1rem',
		},
		userAvatar: {
			width: '100%',
			height: '100%',
			borderRadius: `${size}px`,
			backgroundColor: theme.color.system['color--interactive--background'],
		},
		teamAvatar: {
			...miniCircle({ size: teamSize }),
			bottom: 0,
			left: 0,
			backgroundColor: theme.color.system['color--primary--background'],
		},
		userLevel: {
			...miniCircle({ size: levelSize }),
			bottom: 0,
			right: 0,
			backgroundColor: theme.color.system['color--primary--background'],
			color: theme.color.system['color--interactive--foreground'],
			textAlign: 'center',
			//TODO: create responsive font size
			fontSize: '0.9em',
			fontWeight: 'bold',
			lineHeight: `${levelSize}px`,
		},
	}));
};
