import GraphService from 'services/graph/GraphService';
import interaction from 'services/graph/interaction';

export default {
	'services.graphService': {
		class: GraphService,
		deps: [{ $ref: 'services.APIService' }],
	},
	...interaction,
};
