import ChallengeSubmissionAddedNotification from 'models/notification/ChallengeSubmissionAddedNotification';

import NotificationBaseService from 'services/notification/NotificationBaseService';

import images from 'assets/images';

export default class ChallengeSubmissionAddedNotificationService extends NotificationBaseService {
	constructor(submissionService, currencyService) {
		super();
		this._submissionService = submissionService;
		this._currencyService = currencyService;
	}

	toModel({ submission, submission_earnings, ...rest }) {
		return new ChallengeSubmissionAddedNotification(
			{
				submission: this._submissionService.toModel(submission),
				submission_earnings: this._currencyService.toModel(submission_earnings),
				...rest,
			},
			images
		);
	}
}
