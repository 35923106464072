export default class Message {
	/**
	 *
	 * @param {string} id
	 * @param {Object} values
	 */
	constructor(id, values = {}) {
		/**
		 *
		 * @type {string}
		 */
		this.id = id;
		/**
		 *
		 * @type {Object}
		 */
		this.values = values;
	}
}
