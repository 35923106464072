import React from 'react';
import { Field } from 'formik';

import { Radio } from 'components/_/form/radio/Radio';
import { Input } from 'components/_/form/input/Input';
import { ErrorMessage } from 'components/_/form/error/Error';

import useStyles from 'components/task/types/form/formBlock/question/Question.jss';

import CustomQuestionAnswer from 'models/common/form/questionAnswer/CustomQuestionAnswer';
import StandardQuestionAnswer from 'models/common/form/questionAnswer/StandardQuestionAnswer';

export const SingleChoiceClosedQuestion = ({ formBlock, disabled }) => {
	const classes = useStyles();

	return (
		<div className="fs-4">
			<p className={classes.question}>{formBlock.body()}</p>
			<div>
				{formBlock.answers().map(answer => (
					<div className="mb-2" key={answer.id()}>
						{answer instanceof StandardQuestionAnswer && (
							<Field name={`${formBlock.id()}.id`}>
								{({ field, form }) => (
									<Radio
										{...field}
										value={answer.id()}
										disabled={disabled}
										onClick={event => {
											form.setFieldTouched(field.name, true);
											form.setFieldValue(`${formBlock.id()}.custom`, '');
											field.onChange(event);
										}}
									>
										{answer.body()}
									</Radio>
								)}
							</Field>
						)}
						{answer instanceof CustomQuestionAnswer && (
							<div className="w-100 w-sm-50">
								<Field name={`${formBlock.id()}.id`}>
									{({ field, form }) => (
										<Radio
											{...field}
											disabled={disabled}
											checked={field.value === answer.id()}
											value={answer.id()}
										>
											<Field name={`${formBlock.id()}.custom`}>
												{({ field: customField }) => (
													<Input
														{...customField}
														rounded
														active={field.value === answer.id()}
														disabled={disabled}
														onChange={({ target: { value } }) => {
															form.setFieldValue(customField.name, value ? value : null);
															form.setFieldValue(field.name, value ? answer.id() : null);
														}}
													/>
												)}
											</Field>
										</Radio>
									)}
								</Field>
							</div>
						)}
					</div>
				))}
			</div>
			<ErrorMessage name={`${formBlock.id()}.id`} />
			<ErrorMessage name={`${formBlock.id()}.custom`} />
		</div>
	);
};
