import { PlusMinusAnswerMode } from 'models/task/question/questionTask/trueFalse/answer/mode/PlusMinusAnswerMode';

export default class PlusMinusAnswerModeService {
	/**
	 * @param {Object} answer
	 * @returns {PlusMinusAnswerMode}
	 */
	toModel(answer) {
		return new PlusMinusAnswerMode(answer);
	}
}
