import React from 'react';

//import logo from 'assets/images/_/logo.svg';

export default function Loader({ fixed = true }) {
	return (
		<div className={fixed ? 'loader' : ''}>
			<div className="loader__progress">{/*<img src={logo} alt="loading..." />*/}</div>
		</div>
	);
}
