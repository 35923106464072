import BaseService from 'services/BaseService';

export default class ContentBlocksService extends BaseService {
	_type = 'content-block';
	_typeKey = 'type';

	constructor(logService) {
		super();

		this._logService = logService;
	}

	toModel(block) {
		try {
			return super.toModel(block);
		} catch (error) {
			this._logService.warn(error);
		}
	}
}
