import InternalDynamicAnchor from 'models/application/anchor/types/internalDynamic/InternalDynamic';

export default class InternalDynamicAnchorChallenge extends InternalDynamicAnchor {
	/**
	 * @type {string}
	 * @private
	 */
	_pattern = '/challenge/:id/:view?';

	constructor(anchor) {
		super(anchor);

		this.challenge = anchor.challenge;

		this._params = {
			id: this.challenge.id,
		};
	}
}
