import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Mission from 'models/task/Mission';

import { Button } from 'components/_/common/button/Button';
import { Currency } from 'components/_/common/currency/Currency';
import { ProgressBar } from 'components/_/common/progress-bar/ProgressBar';

export const MissionTile = props => {
	const { task } = props;

	return (
		<div className="col-12 col-md-8 col-lg-6 col-xl-4 mb-4 mb-sm-5">
			<div className="mx-0 mx-sm-3 h-100">
				<NavLink to={`/${task instanceof Mission ? 'mission' : 'task'}/${task.id()}/details`}>
					<div className="tile missionList__tile rounded h-100">
						<div className="tile__front">
							<div className="p-3 pb-4 p-sm-4 d-flex flex-column justify-content-between h-100">
								<div>
									{task.listImage() && (
										<div
											className="tile__image mx-auto mb-3 mb-sm-4 rounded-circle background-image size--cover"
											style={{
												backgroundImage: `url(${task.listImage()})`,
											}}
										/>
									)}
									<p className="fs-5 font-weight-bold text-center task-name">{task.name()}</p>
								</div>
								{task instanceof Mission && (
									<div>
										<p className="fs-4 task-activity text-center mt-3 mt-sm-5 mb-2">
											<FormattedMessage
												id="mission-list.content.tasks-execution"
												values={{
													completed: <strong>{task.state().counts().completed}</strong>,
													all: <strong>{task.state().counts().all}</strong>,
												}}
											/>
										</p>
										<ProgressBar
											progress={task.state().progress() * 100}
											tooltip={true}
											className="fs-5 w-100"
										/>
									</div>
								)}
							</div>
						</div>
						{task.hasCompletionEarnings() && (
							<div className="tile__back d-none d-sm-flex flex-column fs-4">
								<div className="px-4 pt-4 pb-3 flex-grow-1">
									{task.listImage() && (
										<div
											className="tile__image mx-auto mb-4 rounded-circle background-image size--cover"
											style={{
												backgroundImage: `url(${task.listImage()})`,
											}}
										/>
									)}
									<p className="text-center mb-2">
										<FormattedMessage
											id={`mission-list.content.earnings-information.${
												task instanceof Mission ? 'mission' : 'task'
											}`}
										/>
									</p>
									<div className="d-flex flex-wrap justify-content-center">
										{task
											.earningsInformation()
											.completion.values()
											.map(price => (
												<Currency
													key={price.codeName()}
													className="mr-2"
													currency={price.codeName()}
													value={price.value()}
												/>
											))}
									</div>
								</div>
								<div className="task__button fs-5 text-center pt-3 pb-4 px-4">
									<Button color="interactive">
										<FormattedMessage id="mission-list.button.do" />
									</Button>
								</div>
							</div>
						)}
					</div>
				</NavLink>
			</div>
		</div>
	);
};
