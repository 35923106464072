import { find } from 'lodash';

export default class Integrations {
	constructor(integrations) {
		this.integrations = integrations.filter(integration => integration);
		this.loginIntegrations = this._extractLoginIntegrations(this.integrations);
		this.registerIntegrations = this._extractRegisterIntegrations(this.integrations);
	}

	get(id) {
		return find(this.integrations, { id });
	}

	_extractLoginIntegrations(integrations) {
		return integrations.filter(integration => integration.authEnabled);
	}

	_extractRegisterIntegrations(integrations) {
		return integrations.filter(integration => integration.registrationEnabled);
	}
}
