import TaskBaseService from 'services/task/TaskBaseService';

import TextSingleChoiceQuestionTask from 'models/task/question/questionTask/choice/text/TextSingleChoiceQuestionTask';

export default class TextSingleChoiceQuestionTaskService extends TaskBaseService {
	toModel(task) {
		return new TextSingleChoiceQuestionTask({
			...task,
			state: this._mapState(task),
		});
	}
}
