import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';

export default function(BaseImageViewer) {
	return class ImageViewer extends BaseImageViewer {
		render() {
			const { imageAssets } = this.props;
			const { currentImage, leftArrowDisabled, rightArrowDisabled } = this.state;

			return (
				<div className="imageViewer el--grow wrapper--col">
					<div className="imageViewer__mainImageWrapper el--grow wrapper--col">
						<div
							className={classnames('image image--contain el--grow', {
								singleImage: !this._hasMultipleImages(),
								'hidden--mobile': this._hasMultipleImages(),
							})}
							style={{
								backgroundImage: `url(${currentImage}?w=1024)`,
							}}
						></div>
					</div>
					{this._hasMultipleImages() && (
						<div className={classnames('wrapper--row justify--between align--center')}>
							<div
								className={classnames('returnArrow returnArrow--left hidden--mobile', {
									hidden: leftArrowDisabled,
								})}
								onClick={() => this._arrowScrolling(-1)}
							/>
							<div className="imageViewer__miniatureContainer el--grow">
								<ul
									className="miniatureContainer__technical wrapper--row align--center el--grow"
									ref={element => this._setRef('scrollableArea', element)}
									//onTouchEnd={() => this._enableScrollPositioning()}
									//onTouchEnd={() => this._enableScrollPositioning()}
									//onScroll={() => this._onSwipe()}
									onTouchStart={event => this._initMobileSwipe(event)}
									onTouchMove={event => this._mobileSwipeMove(event)}
									onTouchEnd={() => this._mobileSwipeEnd()}
								>
									{_.map(imageAssets, (imageObject, index) => (
										<li
											className="imageViewer__miniatureWrapper wrapper--row el--shrink"
											key={index}
										>
											<div
												className="imageViewer__miniature image image--contain el--grow el--shrink"
												style={{
													backgroundImage: `url(${imageObject.image}?w=1024)`,
												}}
												onClick={() => this._setMainImage(index)}
											/>
										</li>
									))}
								</ul>
							</div>
							<div
								className={classnames('returnArrow returnArrow--right hidden--mobile', {
									hidden: rightArrowDisabled,
								})}
								onClick={() => this._arrowScrolling(1)}
							/>
						</div>
					)}
				</div>
			);
		}

		static propTypes = {
			imageAssets: PropTypes.array,
		};
	};
}
