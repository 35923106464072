export default class UserJudgement {
	constructor(userJudgement) {
		/**
		 * @type {boolean}
		 */
		this.liked = Boolean(userJudgement.liked);
		/**
		 * @type {number}
		 */
		this.scored = userJudgement.scored || 0;
	}
}
