import React, { useState, ChangeEvent, KeyboardEvent } from 'react';
import { FieldConfig } from 'formik';

import { SettingInputTextField } from './fields/SettingInputTextField';
import { SettingTextAreaField } from './fields/SettingTextAreaField';

const ENTER_KEY = 'Enter';

type SettingFieldProps = {
	name: string;
	label?: string;
	defaultValue?: string;
	valueType?: string;
	type?: string;
	editable?: boolean;
	onSave?: Function;
	inForm?: boolean;
	onChange?: (value: string) => void;
};

export type HTMLFormFieldElement = HTMLInputElement | HTMLTextAreaElement;

export type SettingFormFieldProps = {
	label?: string;
	valueType: string;
	editable: boolean;
	editMode: boolean;
	value: string;
	saving: boolean;
	onClick: () => void;
	onChange: (event: ChangeEvent<HTMLFormFieldElement>) => void;
	onKeyDown: (event: KeyboardEvent) => void;
	onBlur: () => void;
	inForm?: boolean;
} & FieldConfig;

export function SettingField(props: SettingFieldProps) {
	const { defaultValue = '', valueType = 'text', type = 'input', editable = false, onSave, ...fieldProps } = props;
	const [editMode, setEditMode] = useState<boolean>(false);
	const [value, setValue] = useState<string>(defaultValue);
	const [saving, setSaving] = useState<boolean>(false);

	const Field = type === 'textarea' ? SettingTextAreaField : SettingInputTextField;

	function save() {
		if (!onSave) {
			setEditMode(false);

			return;
		}

		(async () => {
			setSaving(true);

			await onSave(value);

			setEditMode(false);
			setSaving(false);
		})();
	}

	function onClick() {
		if (editMode || saving || !editable) {
			return;
		}

		setEditMode(true);
	}

	const onChange = (event: ChangeEvent<HTMLFormFieldElement>) => {
		setValue(event.target.value);

		if (props.inForm && props.onChange) {
			props.onChange(event.target.value);
		}
	};
	const onKeyDown = (event: KeyboardEvent) => event.key === ENTER_KEY && save();
	const onBlur = () => save();

	return (
		<Field
			{...fieldProps}
			valueType={valueType}
			editable={editable}
			editMode={editMode}
			value={value}
			saving={saving}
			onClick={onClick}
			onChange={onChange}
			onKeyDown={onKeyDown}
			onBlur={onBlur}
		/>
	);
}
