import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

import RandomCircles from './randomCircles/RandomCircles';
import LanguageSwitcher from 'components/auth/LanguageSwitcher';

export default function(Logo, Image, applicationService) {
	return class LandingPage extends PureComponent {
		state = {
			menuOpen: false,
		};

		render() {
			const { config, allowRender, style } = this.props;
			const {
				headline,
				description,
				buttonText,
				logo,
				colorScheme: { button },
				textStyles,
			} = config;
			const { loginIntegrations, registerIntegrations } = applicationService.applicationConfig().integrations();
			const termsAndConditions = applicationService.settings().termsAndConditions();

			return (
				<div
					className={classnames('view__landingPage overflow--hidden', {
						menuOpen: this.state.menuOpen,
					})}
					style={style}
				>
					{!config.backgroundImage && <RandomCircles config={config} />}
					<div className="landingPage__header">
						<div className="logo">{logo ? <Image src={logo} /> : <Logo />}</div>
						<nav className="d-flex align-items-center">
							<ul>
								{!isEmpty(registerIntegrations) && (
									<li>
										<NavLink to="/auth/register" onClick={allowRender}>
											<FormattedMessage id="landing-page.link.sign-up" />
										</NavLink>
									</li>
								)}
								{!isEmpty(loginIntegrations) && (
									<li>
										<NavLink to="/auth/login" onClick={allowRender}>
											<FormattedMessage id="landing-page.link.sign-in" />
										</NavLink>
									</li>
								)}
							</ul>
							<LanguageSwitcher />
						</nav>
						{isEmpty(registerIntegrations) && isEmpty(loginIntegrations) ? null : (
							<button
								className="menu"
								style={{ color: style.color }}
								onClick={() =>
									this.setState({
										menuOpen: !this.state.menuOpen,
									})
								}
							/>
						)}
					</div>
					<div className="landingPage__content">
						<h1 className="mb-0" style={textStyles}>
							{headline}
						</h1>
						<h2 className="mb-0" style={textStyles}>
							{description}
						</h2>
						{buttonText && config.action !== 'none' && (
							<NavLink
								to={`/auth/${config.action}`}
								className="btn__basic btn__basic--transparent"
								style={{
									color: button.foreground,
									backgroundColor: button.background,
								}}
								onClick={allowRender}
							>
								<span>{buttonText}</span>
							</NavLink>
						)}
					</div>
					<div className="landingPage__footer">
						<div className="skew">
							<div className="content">
								<p>
									{new Date().getFullYear()} &copy; <FormattedMessage id="landing-page.copyright" />
								</p>
								{termsAndConditions.hasSetValues() && (
									<Fragment>
										<p>
											<FormattedMessage id="landing-page.acceptation" />{' '}
											{termsAndConditions.values().map(([type, url], index) => (
												<Fragment key={index}>
													<a href={url} target="_blank" rel="noopener noreferrer">
														<FormattedMessage
															id={`landing-page.terms-and-conditions.${type}`}
														/>
													</a>
													{index < termsAndConditions.values().length - 1 && ', '}
												</Fragment>
											))}
										</p>
									</Fragment>
								)}
							</div>
						</div>
					</div>
				</div>
			);
		}

		static propTypes = {
			config: PropTypes.object.isRequired,
			allowRender: PropTypes.func.isRequired,
			style: PropTypes.object.isRequired,
		};
	};
}
