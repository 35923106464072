import GoogleAnalytics from 'services/trackers/adapters/GoogleAnalytics';
import GoogleTagManager from 'services/trackers/adapters/GoogleTagManager';

export default {
	'services.trackers.adapters.googleAnalytics': {
		factory: GoogleAnalytics,
	},
	'services.trackers.adapters.googleTagManager': {
		factory: GoogleTagManager,
		deps: [{ $ref: 'services.userService' }],
	},
};
