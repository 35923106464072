import DocumentTask from 'components/task/types/document/DocumentTask';

export default {
	'components.task.Document': {
		factory: DocumentTask,
		deps: [
			{ $ref: 'components.task.layout.BaseLayout' },
			{ $ref: 'components.ScalableIframe' },
			{ $ref: 'components.MatchingImageType' },
		],
	},
};
