/**
 * Class representing a container.
 */
export class Container {
	constructor(container) {
		/**
		 * @type {string}
		 */
		this.name = (container.name || '');
	}
}
