import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { wire } from 'react-hot-wire';
import isEmpty from 'lodash/isEmpty';

import Loader from 'components/commons/_/loader/Loader';

import useFetch from 'components/_/hooks/useFetch';

function NewsList(props) {
	const i18nKey = 'news-list';
	const newsService = props['services.newsService'];

	const {
		loading,
		error,
		response: { list },
	} = useFetch(() => newsService.fetchList({ tag: 'news' }), { list: [] });

	return (
		<div className="p-3 py-sm-0">
			{loading ? (
				<Loader />
			) : error ? (
				<p className="fs-6 p-3 p-sm-0">
					<FormattedMessage id={`${i18nKey}.error.${error}`} />
				</p>
			) : isEmpty(list) ? (
				<p className="fs-6 p-3 p-sm-0">
					<FormattedMessage id={`${i18nKey}.error.empty`} />
				</p>
			) : (
				<div className="view__news">
					<ul className="wrapper--col standardList">
						{list.map(news => (
							<li className="wrapper--row el--grow" key={news.id}>
								<NavLink
									to={`/news/${news.id}/details`}
									className="standardListRow wrapper--row justify--between el--grow"
								>
									<div className="standardListRow__mainContent wrapper--col el--grow">
										<div className="row__title">{news.title()}</div>
										<div className="visible--mobile wrapper--row visible--mobile">
											<div className="row__text row__text--light">
												<FormattedMessage id="news-list.content.added" />
											</div>
											<div className="row__text">{news.createdAt()}</div>
										</div>
									</div>
									<div className="standardListRow__additionalContent wrapper--row align--center el--shrink hidden--mobile">
										<div className="row__text row__text--light">
											<FormattedMessage id="news-list.content.added" />
										</div>
										<div className="row__text visible--laptop">{news.createdAt('LLLL')}</div>
										<div className="row__text visible--mobile">{news.createdAt()}</div>
									</div>
								</NavLink>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
}

export default wire(['services.newsService'], NewsList);
