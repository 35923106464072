import { PointRankingEntry } from './PointRankingEntry';
import UserProfile from '../../../user/UserProfile';

/**
 * Class representing a user point ranking entry.
 * @extends PointRankingEntry
 */
export class UserPointRankingEntry extends PointRankingEntry {
	constructor(entry) {
		super(entry);

		/**
		 * @type {UserProfile}
		 */
		this.userProfile = new UserProfile(entry.user_profile);
	}
}
