import Settings, { SettingsDTO } from 'models/application/config/Settings';
import Level, { LevelDTO } from 'models/user/Level';
import Integrations from 'models/application/integration/Integrations';

export interface ApplicationDTO {
	name: string;
	settings: SettingsDTO;
	visible_modules: string[];
	integrations: Integrations;
	levels: LevelDTO[];
	vapid_public_key: string;
}

export default class Application {
	private _name: string;
	private _settings: Settings;
	private _visibleModules: string[];
	private _integrations: Integrations;
	private _levels: Level[];
	private _vapidPublicKey: string;

	constructor(application: ApplicationDTO) {
		this._name = application.name;
		this._settings = new Settings(application.settings);
		this._visibleModules = application.visible_modules || [];
		this._integrations = application.integrations;
		this._levels = (application.levels || []).map(level => new Level(level));
		this._vapidPublicKey = application.vapid_public_key;
	}

	name() {
		return this._name;
	}

	settings() {
		return this._settings;
	}

	trackingCodes() {
		return this._settings.trackingCodes();
	}

	visibleModules() {
		return this._visibleModules;
	}

	integrations() {
		return this._integrations;
	}

	levels() {
		return this._levels;
	}

	vapidPublicKey() {
		return this._vapidPublicKey;
	}
}
