import _ from 'lodash';

export default function(tabFactory, tabPanelFactory) {
	return class TabManager {
		_tabs = {};
		_panels = {};
		_names = [];

		constructor(options = {}) {
			this.options = options;
			/**
			 * Object stores references to other TabManager's.
			 * Example usage: performing operations on another tabManager (e.g.: activate / close).
			 * @type {Object<string,TabManager>}
			 */
			this.managers = options.managers || {};
			this.components = {
				Tab: tabFactory(this),
				TabPanel: tabPanelFactory(this),
			};
			this._activeTab = '';

			if (options.allowKeys) {
				this._registerArrowHandler();
			}
		}

		activate(name, data) {
			this._activeTab = name;

			_.each(this._panels, panel => {
				panel.setState({
					active: panel.props.name === name,
					data: panel.props.name === name ? data : panel.state.data,
				});
			});
			_.each(this._tabs, tab => {
				tab.setState({ active: tab.props.name === name });
			});
		}

		_registerArrowHandler() {
			window.addEventListener('keydown', (this.__arrowKeyEvent = event => this._arrowsHandler(event)));
		}

		_arrowsHandler(e) {
			const keyCode = e.keyCode;

			if (keyCode === 39) {
				this.activate(this._siblingName());
			}

			if (keyCode === 37) {
				this.activate(this._siblingName(false));
			}
		}

		_siblingName(isNext = true) {
			let index = _.indexOf(this.names(), this._activeTab);

			if (isNext) {
				return this.names()[this.names().length - 1 > index ? index + 1 : index];
			} else {
				return this.names()[index > 0 ? index - 1 : index];
			}
		}

		_hasNoSibling(next = true) {
			let index = _.indexOf(this.names(), this._activeTab);

			return next ? this.names().length - 1 === index : index === 0;
		}

		manager(name) {
			return this.managers[name];
		}

		names() {
			return this._names;
		}

		registerName(name) {
			this._names.push(name);
		}

		register(type, context) {
			const name = context.props.name;
			const typeRef = this[`_${type}s`];

			typeRef[name] = context;

			if (name === this.options.active || name === this.options.default) {
				this.activate(name);
			}
		}

		reload(name) {
			this.activate(name);
			this._panels[name].forceUpdate();
		}

		close(name, confirmation = true) {
			if (confirmation && !window.confirm('Czy na pewno?')) {
				// eslint-disable-line no-alert
				return;
			}

			if (this._tabs[name]) {
				this._tabs[name].setState({ active: false });
			}

			if (this._panels[name]) {
				this._panels[name].setState({ active: false });
			}

			if (this.__arrowKeyEvent) {
				window.removeEventListener('keydown', this.__arrowKeyEvent);
			}
		}
	};
}
