import { Component } from 'react';

export default function(teamService) {
	return class TeamAssignment extends Component {
		constructor(...args) {
			super(...args);

			this.state = {
				disabledTeam: this.props.block.container(),
				container: null,
				team: null,
			};
		}

		_submit(event) {
			event.preventDefault();

			if (!this.state.team) {
				return;
			}

			teamService.join(this.state.team.id).then(() => this.props.nextStep());
		}

		_onContainerChanged(container) {
			this.setState({
				disabledTeam: false,
				container,
				team: null,
			});
		}

		_onTeamChanged(team) {
			this.setState({
				team,
			});
		}
	};
}
