export default class AuthService {
	constructor(APIService, navigationService, accessTokenService) {
		this.APIService = APIService;
		this._navigationService = navigationService;
		this._accessTokenService = accessTokenService;
	}

	/**
	 * @returns {Promise}
	 */
	anonymous() {
		return this.APIService.post('v1/auth/anonymous').then(accessToken => {
			this._accessTokenService.setAccessToken('application', accessToken);
		});
	}

	/**
	 * @returns {Promise}
	 */
	changePassword() {
		return this.APIService.post('v1/auth/change-password');
	}

	/**
	 * @returns {Promise}
	 */
	passwordRequirements() {
		return this.APIService.post('v1/auth/password-requirements');
	}

	/**
	 * @returns {Promise}
	 */
	logout() {
		return this.APIService.post('v1/auth/logout').then(() => {
			this._accessTokenService.clear();
			this._navigationService.load('/auth/login');
		});
	}
}
