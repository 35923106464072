export default class BaseChallengeAwardedPlaceService {
	constructor(storeItemService, challengeService, currencyService) {
		this._storeItemService = storeItemService;
		this._challengeService = challengeService;
		this._currencyService = currencyService;
	}

	toModel({ items, challenge, points, ...rest }) {
		const itemList = items.map(item => this._storeItemService.toModel(item));

		return {
			items: itemList,
			challenge: challenge && this._challengeService.toModel(challenge),
			points: this._currencyService.toModel(points),
			...rest,
		};
	}
}
