import { createUseStyles } from 'react-jss';

export default createUseStyles(theme => {
	const textSize = 1;
	const borderSize = 0.0625; // 1 / 16
	const verticalPaddings = 0.3;
	const size = textSize + verticalPaddings * 2 + borderSize * 2; // text + (py * 2) + (border * 2)
	const dotSize = size * 0.4;

	return {
		label: {
			display: 'flex',
			alignItems: 'center',
			position: 'relative',
			cursor: 'pointer',
		},
		input: {
			position: 'absolute',
			opacity: 0,
			height: 0,
			width: 0,
			'&:checked ~ $check': {
				borderColor: theme.color.system['color--interactive--background'],

				'&::after': {
					transform: 'translate(-50%, -50%) scale(1)',
					backgroundColor: theme.color.system['color--interactive--background'],
				},
			},
			'&:checked ~ $content': {
				color: theme.color.system['color--interactive--background'],
			},
		},
		disabled: {
			'& $check, &': {
				cursor: 'default',
			},
			'& $check': {
				backgroundColor: `${theme.color.system['color--content--foreground-20']}`,
			},
		},
		check: {
			width: `${size}em`,
			height: `${size}em`,
			minWidth: `${size}em`,
			maxWidth: `${size}em`,
			border: `${borderSize}em solid ${theme.color.system['color--content--foreground-20']}`,
			borderRadius: `${size}em`,
			position: 'relative',
			alignSelf: 'start',
			'&::after': {
				content: '""',
				position: 'absolute',
				transition: 'all 0.2s ease-out',
				left: '50%',
				top: '50%',
				transform: 'translate(-50%, -50%) scale(0)',
				width: `${dotSize}em`,
				height: `${dotSize}em`,
				borderRadius: `${dotSize}em`,
				backgroundColor: theme.color.system['color--content--foreground-80'],
			},
		},
		content: {
			width: '100%',
			color: `${theme.color.system['color--content--foreground-50']}`,
			marginLeft: '0.3em',
			//border: `${borderSize}em solid transparent`,
			fontSize: `${textSize}em`,
		},
	};
});
