import React from 'react';
import { injectIntl } from 'react-intl';
import { wire } from 'react-hot-wire';
import queryString from 'query-string';
import { NavLink } from 'react-router-dom';

import OAuthCallback from './OAuthCallback';
import Info from '../../../Info';

export default function OAuth(OAuthComponent) {
	const OAuthIntegration = ({ integration, intl, ...props }) => {
		const { state, code, error, error_code } = queryString.parse(window.location.search);

		function login() {
			props['services.OAuthIntegrationService'].authorize({
				id: integration.id,
			});
		}

		if (error || error_code) {
			return (
				<Info
					title={intl.formatMessage({
						id: 'auth.login.integration.oauth.error.type.general',
					})}
					message={
						<NavLink to="/auth/login">
							{intl.formatMessage({
								id: 'auth.login.integration.oauth.return',
							})}
						</NavLink>
					}
				/>
			);
		}

		if (state && code) {
			return <OAuthCallback id={integration.id} state={state} code={code} />;
		}

		return <OAuthComponent integration={integration} login={login} {...props} />;
	};

	return injectIntl(wire(['services.OAuthIntegrationService'], OAuthIntegration));
}
