import BaseChallengeSubmissionService from 'services/challenge/submission/BaseChallengeSubmissionService';

import ImageSubmission from 'models/challenge/submission/ImageSubmission';

export default class ChallengeImageSubmissionService extends BaseChallengeSubmissionService {
	toModel({ challenge, user_profile, ...rest }) {
		return new ImageSubmission({
			challenge: challenge && this._challengeService.toModel(challenge),
			userProfile: user_profile && this._userProfileService.toModel(user_profile),
			...rest,
		});
	}
}
