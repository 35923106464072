import { ConfettiParticle } from './ConfettiParticle';

export class Confetti {
	static MAX_CONFETTIS = 150;

	constructor(canvas) {
		this.width = window.innerWidth;
		this.height = window.innerHeight;
		this.canvas = canvas;
		this.context = canvas.getContext('2d');
		this.particles = [];

		for (let i = 0; i < this.constructor.MAX_CONFETTIS; i++) {
			this.particles.push(
				new ConfettiParticle({
					x: Math.random() * this.width,
					y: Math.random() * this.height - this.height,
					r: this._randomFromTo({ from: 11, to: 33 }),
				})
			);
		}

		this.canvas.width = this.width;
		this.canvas.height = this.height;
	}

	draw() {
		const results = [];
		let particle = {};

		requestAnimationFrame(() => this.draw());

		this.context.clearRect(0, 0, this.width, window.innerHeight);

		for (let i = 0; i < this.constructor.MAX_CONFETTIS; i++) {
			results.push(this._drawParticle(this.particles[i]));
		}

		for (let i = 0; i < this.constructor.MAX_CONFETTIS; i++) {
			particle = this.particles[i];

			particle.tiltAngle += particle.tiltAngleIncremental;
			particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2;
			particle.tilt = Math.sin(particle.tiltAngle - i / 3) * 15;

			if (particle.x > this.width + 30 || particle.x < -30 || particle.y > this.height) {
				particle.x = Math.random() * this.width;
				particle.y = -30;
				particle.tilt = Math.floor(Math.random() * 10) - 20;
			}
		}

		return results;
	}

	registerResizeHandler() {
		const handler = () => {
			this.width = window.innerWidth;
			this.height = window.innerHeight;
			this.canvas.width = window.innerWidth;
			this.canvas.height = window.innerHeight;
		};

		window.addEventListener('resize', handler, false);

		return () => window.removeEventListener('resize', handler, false);
	}

	_drawParticle({ r, color, x, y, tilt }) {
		this.context.beginPath();
		this.context.lineWidth = r / 2;
		this.context.strokeStyle = color;
		this.context.moveTo(x + tilt + r / 3, y);
		this.context.lineTo(x + tilt, y + tilt + r / 15);

		return this.context.stroke();
	}

	_randomFromTo({ from, to }) {
		return Math.floor(Math.random() * (to - from + 1) + from);
	}
}
