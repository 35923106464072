import React, { useState } from 'react';
import { wire } from 'react-hot-wire';

import CreativeChallengeRecentlyAddedSubmissions from './RecentlyAdded.template';
import Loader from 'components/commons/_/loader/Loader';
import useFetch from 'components/_/hooks/useFetch';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import Pagination from 'components/_/common/pagination/Pagination';

export function CreativeChallengeRecentlyAddedSubmissionsComponent({
	'services.challengeSubmissionService': challengeSubmissionService,
	challenge,
}) {
	const i18nKey = 'challenge-details.creative.submission-list';

	const [rowsPerPage] = useState(50);
	const [page, setPage] = useState(0);

	const {
		loading,
		error,
		response: { list, count },
	} = useFetch(
		() =>
			challengeSubmissionService.fetchSubmissionList({
				id: challenge.id,
				offset: page * rowsPerPage,
				limit: rowsPerPage,
			}),
		{
			list: [],
			count: 0,
		},
		[page, rowsPerPage]
	);

	return (
		<>
			{loading ? (
				<Loader />
			) : error ? (
				<p className="fs-6 mx-0 mx-sm-3">
					<FormattedMessage id={`${i18nKey}.error.${error}`} />
				</p>
			) : isEmpty(list) ? (
				<p className="fs-6 mx-0 mx-sm-3">
					<FormattedMessage id={`${i18nKey}.error.empty.common`} />
				</p>
			) : (
				<CreativeChallengeRecentlyAddedSubmissions list={list} challenge={challenge} />
			)}

			{count > 0 && !loading && (
				<Pagination count={count} page={page} rowsPerPage={rowsPerPage} onChange={page => setPage(page)} />
			)}
		</>
	);
}

export default wire(['services.challengeSubmissionService'], CreativeChallengeRecentlyAddedSubmissionsComponent);
