import ChallengeAwardedPlaceNotification from 'models/notification/ChallengeAwardedPlaceNotification';

import NotificationBaseService from 'services/notification/NotificationBaseService';

import images from 'assets/images';

export default class ChallengeAwardedPlaceNotificationService extends NotificationBaseService {
	constructor(challengeAwardedPlaceService) {
		super();
		this._challengeAwardedPlaceService = challengeAwardedPlaceService;
	}

	toModel({ awarded_place, ...rest }) {
		return new ChallengeAwardedPlaceNotification(
			{
				awarded_place: this._challengeAwardedPlaceService.toModel(awarded_place),
				...rest,
			},
			images
		);
	}
}
