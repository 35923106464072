import TargetChallengeTransactionLink from 'models/application/transaction/transactionLink/TargetChallengeTransactionLink';

import TargetChallengeState from 'models/challenge/challenge/state/TargetChallengeState';

export default class TargetChallengeTransactionLinkService {
	toModel({ challenge_state, ...rest }) {
		return new TargetChallengeTransactionLink({
			challenge_state: new TargetChallengeState(challenge_state),
			...rest,
		});
	}
}
