import News from 'models/news/News';

export default class NewsService {
	constructor(APIService, tagService) {
		this.APIService = APIService;
		this._tagService = tagService;
	}

	/**
	 *
	 * @param {number} offset
	 * @param {number} limit
	 * @param {string} tag
	 * @returns {Promise}
	 */
	fetchList({ offset = 0, limit = 100, tag } = {}) {
		const url = 'v1/news/list';

		return this.APIService.get(tag ? `${url}/${tag}` : url, {
			limit,
			offset,
		}).then(({ list, has_next_page }) => ({
			list: list.map(news => this.toModel(news)),
			has_next_page,
		}));
	}

	/**
	 *
	 * @param {string|number} id
	 * @returns {Promise}
	 */
	fetchDetails({ id } = {}) {
		return this.APIService.get(`v1/news/${id}`).then(news => this.toModel(news));
	}

	/**
	 *
	 * @private
	 * @param {Object[]} tags
	 * @param {*[]} rest
	 * @returns {News}
	 */
	toModel({ tags, ...rest }) {
		return new News({
			tags: tags.map(tag => this._tagService.toModel(tag)),
			...rest,
		});
	}
}
