export default colorService => {
	const spacer = 1; // rem

	return {
		color: {
			base: {
				green: '#B0CD00',
				red: '#DF0000',
			},
			system: colorService.variables(),
		},
		grid: {
			xxs: 380,
			xs: 576,
			sm: 768,
			md: 992,
			lg: 1280,
			xl: 1600,
			xxl: 1920,
		},
		fontSizes: {
			1: spacer * 0.25,
			2: spacer * 0.5,
			3: spacer,
			4: spacer * 1.5,
			5: spacer * 2,
			6: spacer * 3,
		},
		spacers: {
			0: 0,
			1: spacer * 0.25,
			2: spacer * 0.5,
			3: spacer,
			4: spacer * 1.5,
			5: spacer * 2,
			6: spacer * 3,
		},
		zIndex: {
			sticky: 1000,
			modal: 1100,
		},
	};
};
