import React, { useState } from 'react';
import { wire } from 'react-hot-wire';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import queryString from 'query-string';
import { useMutation } from 'react-query';

import TokenIntegrationService from 'services/application/integration/subServices/TokenIntegrationService';
import ApplicationService from 'services/application/ApplicationService';

import Info from 'components/auth/Info';
import TokenTemplate from './Token.template';

export type TokenIntegrationProps = InjectedIntlProps & {
	'services.applicationService': ApplicationService;
	'services.tokenIntegrationService': TokenIntegrationService;
};

export type Fields = { agreements: string[] };

const Token = ({
	intl,
	'services.applicationService': applicationService,
	'services.tokenIntegrationService': tokenIntegrationService,
	...props
}: TokenIntegrationProps) => {
	const { authToken } = queryString.parse(window.location.search);
	const [touched, setTouched] = useState<boolean[]>([]);
	const mutation = useMutation<
		void,
		void,
		{
			values: Fields;
			actions: FormikHelpers<Fields>;
		},
		void
	>(async ({ actions, values }) => {
		tokenIntegrationService
			.register(
				authToken,
				values.agreements.filter(_ => _)
			)
			.then(() => {
				actions.setStatus('ok');
			})
			.catch(error => {
				actions.setSubmitting(false);

				if (error instanceof Error) {
					return actions.setStatus('general-error');
				}

				if (error.error.messageId === 'agreements-required') {
					return actions.setStatus('agreements-error');
				}
			});
	});

	if (!authToken) {
		return <FormattedMessage id="auth.register.integration.token.error.no-token" tagName="h2" />;
	}

	return (
		<Formik<Fields>
			initialValues={{
				agreements: [],
			}}
			onSubmit={(values, actions) => mutation.mutate({ values, actions })}
			validate={fields => {
				const errors: { [key: string]: string[] } = {};

				applicationService
					.settings()
					.agreements()
					.forEach((agreement, index) => {
						if (agreement.required() && !fields.agreements.includes(agreement.id())) {
							if (!errors.agreements) {
								errors.agreements = [];
							}

							errors.agreements[index] = intl.formatMessage({ id: 'form.error.required' });
						}
					});

				return errors;
			}}
			render={renderProps => {
				if (renderProps.status === 'ok') {
					return (
						<Info
							title={intl.formatMessage({
								id: 'auth.register.integration.token.status.ok.title',
							})}
							message=""
						/>
					);
				}

				return (
					<TokenTemplate
						{...renderProps}
						{...props}
						agreements={applicationService.settings().agreements()}
						touched={touched}
						setTouched={setTouched}
					/>
				);
			}}
		/>
	);
};

export default injectIntl(wire(['services.tokenIntegrationService', 'services.applicationService'], Token));
