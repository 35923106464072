import _ from 'lodash';

export default class AuthenticationTrackerService {
	constructor(trackerService, accessTokenService, userService) {
		this._trackerService = trackerService;
		this._accessTokenService = accessTokenService;
		this._userService = userService;
	}

	init() {
		let changeListener = accessToken => this._userChanged(accessToken);

		this._accessTokenService.addChangeListener(changeListener);
		changeListener(this._accessTokenService.accessToken('application'));
	}

	_userChanged(accessToken) {
		let user = this._userService.user();
		let trackedUserProfile = null;
		let trackedUser = null;

		if (user.id) {
			trackedUser = {
				id: user.id,
			};

			if (user.userProfile) {
				trackedUserProfile = {};
				let { importedExternalId, externalId } = user.userProfile;
				let id = user.userProfile.id();

				if (id) {
					if (importedExternalId) {
						trackedUserProfile.importedId = this._buildUserIdentifier(id, importedExternalId);
					}

					if (externalId) {
						trackedUserProfile.externalId = this._buildUserIdentifier(id, externalId);
					}

					trackedUserProfile.id = id;
				}
			}
		}

		if (accessToken) {
			this._trackerService.track('authentication', {
				userProfile: trackedUserProfile,
				user: trackedUser,
			});
		} else {
			this._trackerService.track('logout', {
				userProfile: trackedUserProfile,
				user: trackedUser,
			});
		}
	}

	_buildUserIdentifier(userProfileId, externalId) {
		return this._isEmailAddress(externalId) ? 'sensitive_data_replaced-' + userProfileId : externalId;
	}

	_isEmailAddress(id) {
		return _.includes(id, '@');
	}
}
