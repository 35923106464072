import pathToRegexp from 'path-to-regexp';

export default class Anchor {
	target = '_self';
	_params = {};

	url() {
		if (this._pattern) {
			return pathToRegexp.compile(this._pattern)(this._params);
		}
	}
}
