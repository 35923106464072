import React, { PropsWithChildren } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export type ConditionalLinkProps = {
	to?: NavLinkProps['to'];
	as?: React.ElementType;
} & Omit<NavLinkProps, 'to'>;

const ConditionalLink = ({ children, as: Link = NavLink, to, ...props }: PropsWithChildren<ConditionalLinkProps>) => {
	const destination = { [Link === 'a' ? 'href' : 'to']: to };

	if (to) {
		return (
			<Link {...props} {...destination}>
				{children}
			</Link>
		);
	}

	return <>{children}</>;
};

export default ConditionalLink;
