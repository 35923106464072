import anchorServices from 'services/application/anchor';
import colorServices from 'services/application/color';
import CSSTransformServices from 'services/application/CSSTransform';
import menuServices from 'services/application/menu';
import imageServices from 'services/application/image';
import initServices from 'services/application/init';
import integrationServices from 'services/application/integration';
import PWAServices from 'services/application/PWA';
import storageServices from 'services/application/storage';
import transactionServices from 'services/application/transaction';
import translationServices from 'services/application/translation';

import AccessPopupService from 'services/application/AccessPopupService';
import AccessTokenService from 'services/application/AccessTokenService';
import ApplicationService from 'services/application/ApplicationService';
import BrowserHistoryService from 'services/application/BrowserHistoryService';
import ContactListService from 'services/application/ContactListService';
import CookieService from 'services/application/CookieService';
import MetadataConfigurationService from 'services/application/MetadataConfigurationService';
import CurrencyService from 'services/application/CurrencyService';
import EventQueue from 'services/application/EventQueue';
import I18nService from 'services/application/I18nService';
import LevelService from 'services/application/LevelService';
import ModalService from 'services/application/ModalService';
import LogService from 'services/application/LogService';
import NavigationService from 'services/application/NavigationService';
import PlatformService from 'services/application/PlatformService';
import RealTimeNotificationService from 'services/application/RealTimeNotificationService';

export default {
	...anchorServices,
	...colorServices,
	...CSSTransformServices,
	...imageServices,
	...initServices,
	...integrationServices,
	...menuServices,
	...PWAServices,
	...storageServices,
	...transactionServices,
	...translationServices,
	'services.accessPopupService': {
		class: AccessPopupService,
	},
	'services.accessTokenService': {
		class: AccessTokenService,
		deps: [window.sessionStorage, { $ref: 'services.LocalStorageService' }],
	},
	'services.applicationService': {
		class: ApplicationService,
		deps: [
			{ $ref: 'services.APIService' },
			{ $ref: 'services.menuService' },
			{ $ref: 'services.anchorService' },
			{ $ref: 'services.integrationService' },
			window.localStorage,
		],
	},
	'services.browserHistoryService': {
		class: BrowserHistoryService,
		deps: [{ $ref: 'browserHistory' }, { $ref: 'services.applicationService' }],
	},
	'services.contactListService': {
		class: ContactListService,
		deps: [
			{ $ref: 'services.APIService' },
			{ $ref: 'services.userProfileService' },
			{ $ref: 'services.userProfileFieldService' },
		],
	},
	'services.cookieService': {
		class: CookieService,
	},
	'services.metadataConfigurationService': {
		class: MetadataConfigurationService,
		deps: [{ $ref: 'document' }, { $ref: 'services.applicationService' }, { $ref: 'services.platformService' }],
	},
	'services.modalService': {
		class: ModalService,
	},
	'services.currencyService': {
		class: CurrencyService,
		deps: [{ $ref: 'services.applicationService' }],
	},
	'services.eventQueueService': {
		factory: () => ({
			createEventQueue: () => new EventQueue(),
		}),
	},
	'services.i18nService': {
		class: I18nService,
		deps: [
			window.localStorage,
			{ $ref: 'services.applicationService' },
			{ $ref: 'services.userService' },
			{ $ref: 'services.navigationService' },
			{ $ref: 'services.translationService' },
		],
	},
	'services.levelService': {
		class: LevelService,
		deps: [{ $ref: 'services.applicationService' }],
	},
	'services.logService': {
		class: LogService,
	},
	'services.navigationService': {
		class: NavigationService,
		deps: [window.location, { $ref: 'browserHistory' }],
	},
	'services.realTimeNotificationService': {
		class: RealTimeNotificationService,
		deps: [
			{ $ref: 'config.application' },
			{ $ref: 'services.accessTokenService' },
			{ $ref: 'services.notificationService' },
		],
	},
	'services.platformService': {
		class: PlatformService,
	},
};
