import User from 'models/user/User';

export default class UserService {
	constructor(APIService) {
		this.APIService = APIService;
		this._user = null;
	}

	user() {
		return this._user;
	}

	isLogged() {
		return !!this._user;
	}

	isNonAnonymous() {
		return Boolean(this._user && this._user.userProfile);
	}

	toModel(user) {
		return new User(user);
	}

	/**
	 *
	 * @returns {Promise}
	 */
	markWelcomePageAsSeen() {
		this._user.hasSeenWelcomePage = true;

		return this.APIService.get('v1/user/markWelcomePageAsSeen');
	}

	/**
	 *
	 * @returns {Promise}
	 */
	fetchDetails() {
		return this.APIService.get('v1/user').then(user => {
			let result = user && this.toModel(user);

			this._user = result;

			return result;
		});
	}

	/**
	 * @param {string} locale
	 * @returns {Promise}
	 */
	setLocale(locale) {
		return this.APIService.post('v1/user/setLocale', { locale });
	}

	setAgreements({ agreements }) {
		return this.APIService.post('v1/user/setAgreements', { agreements });
	}
}
