import _ from 'lodash';

import TaskBaseService from 'services/task/TaskBaseService';
import FormTask from 'models/task/form/FormTask';
import Form from 'models/common/form/Form';
import FormTaskState from 'models/task/state/FormTaskState';
import FormTaskRepetitionState from 'models/task/repetitionState/FormTaskRepetitionState';

export default class FormTaskService extends TaskBaseService {
	constructor(taskStateService, formBlockService) {
		super(taskStateService);

		this._formBlockService = formBlockService;
	}

	toModel(task) {
		return new FormTask({
			...task,
			form: new Form({
				...task.form,
				form_blocks:
					task.form && _.map(task.form.form_blocks, formBlock => this._formBlockService.toModel(formBlock)),
			}),
			state: task.state && this._taskStateService.mapState(task.state, FormTaskState, FormTaskRepetitionState),
		});
	}
}
