import FileFormResponse from './FileFormResponse';

export default class FormResponse {
	constructor(formResponse) {
		this._givenAnswers = formResponse.given_answers;
		this._fileUploads = (formResponse.file_uploads || []).map(upload => new FileFormResponse(upload));
	}

	givenAnswers() {
		return this._givenAnswers;
	}

	fileUploads() {
		return this._fileUploads;
	}
}
