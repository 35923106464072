import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import Loader from 'components/commons/_/loader/Loader';

import useFetch from 'components/_/hooks/useFetch';

import { MissionTile } from './tile/MissionTile';

function MissionList({ taskService }) {
	const i18nKey = 'mission-list';

	const {
		loading,
		error,
		response: { list },
	} = useFetch(() => taskService.fetchList({ place: 'task_page_list' }).then(list => ({ list })), { list: [] });

	return (
		<Fragment>
			<hr className="mb-4 mb-sm-5" />
			<div className="fs-4">
				{loading ? (
					<Loader />
				) : error ? (
					<p className="fs-6 mx-0 mx-sm-3">
						<FormattedMessage id={`${i18nKey}.error.${error}`} />
					</p>
				) : isEmpty(list) ? (
					<p className="fs-6 mx-0 mx-sm-3">
						<FormattedMessage id={`${i18nKey}.error.empty`} />
					</p>
				) : (
					<div className="row">
						{list.map(task => (
							<MissionTile key={task.id()} task={task} />
						))}
					</div>
				)}
			</div>
		</Fragment>
	);
}

export default MissionList;
