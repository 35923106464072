import React, { FC } from 'react';

import SelectContainer from 'components/_/app/container/SelectContainer';
import SelectTeam from 'components/_/app/team/SelectTeam';
import { InputSearchItem } from 'components/_/form/input-search/input-search';
import TeamContainerGroup from 'models/container/TeamContainerGroup';
import { PointRanking } from 'models/ranking/pointRanking/PointRanking';

export type RankingFiltersProps = {
	ranking: PointRanking;
	selectedContainer?: InputSearchItem;
	selectedTeam?: InputSearchItem;
	searchContainer: string;
	searchTeam: string;
	setSelectedContainer: (selected?: InputSearchItem) => void;
	setSelectedTeam: (selected?: InputSearchItem) => void;
	setSearchContainer: (value: string) => void;
	setSearchTeam: (value: string) => void;
};

const RankingFilters: FC<RankingFiltersProps> = ({
	ranking,
	selectedContainer,
	selectedTeam,
	setSelectedContainer,
	setSelectedTeam,
	searchContainer,
	setSearchContainer,
	searchTeam,
	setSearchTeam,
}) => {
	if (!ranking?.enableFilters) {
		return null;
	}

	return (
		<div className="d-flex fs-4 flex-grow-1 flex-wrap flex-sm-nowrap">
			{ranking.filterableContainerGroup && (
				<SelectContainer
					className="mr-0 mr-sm-2 my-2"
					codeName={ranking.filterableContainerGroup.id}
					group={ranking.filterableContainerGroup.containerGroupType}
					search={searchContainer}
					selectedContainer={selectedContainer}
					setSearch={value => {
						if (selectedContainer && selectedContainer.content !== value) {
							setSelectedTeam();
							setSearchTeam('');
							setSelectedContainer();
						}

						setSearchContainer(value);
					}}
					onSelect={(value, item) => {
						setSelectedTeam();
						setSearchTeam('');
						setSelectedContainer(item);
					}}
				/>
			)}
			{(!ranking.filterableContainerGroup || ranking.filterableContainerGroup instanceof TeamContainerGroup) && (
				<SelectTeam
					className="my-2"
					onlyOpen={false}
					containerId={selectedContainer?.value}
					selectedTeam={selectedTeam}
					disabled={!selectedContainer}
					search={searchTeam}
					setSearch={setSearchTeam}
					onSelect={(value, item) => setSelectedTeam(item)}
				/>
			)}
		</div>
	);
};

export default RankingFilters;
