import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { wire } from 'react-hot-wire';

import CreateSubmissionConfirmation from './modal/confirmation/Confirmation';
import CreateSubmissionError from './modal/error/Error';
import CreateTextSubmission from './modal/text-submission/TextSubmission';
import CreateImageSubmission from './modal/image-submission/ImageSubmission';
import SubmissionTypeSelection from './modal/submisison-type-selection/SubmissionTypeSelection';

export class CreateSubmissionComponent extends PureComponent {
	static propTypes = {
		challenge: PropTypes.object.isRequired,
		intl: PropTypes.object.isRequired,
		onSubmit: PropTypes.func,
		onClose: PropTypes.func,
	};

	static defaultProps = {
		onSubmit: () => {},
		onClose: () => {},
	};

	_modals = {
		confirmation: CreateSubmissionConfirmation,
		error: CreateSubmissionError,
		selection: SubmissionTypeSelection,
		text: CreateTextSubmission,
		image: CreateImageSubmission,
	};

	constructor(...args) {
		super(...args);

		this.state = {
			modalProps: null,
			visible: false,
			activeModal: this._recognizeModal(),
		};
	}

	componentDidMount() {
		this._unregisterListener = this.props['services.modalService'].addChangeListener('create-submission', () =>
			this.setState({ visible: true })
		);
	}

	render() {
		const { activeModal, visible, modalProps } = this.state;
		const Modal = this._modals[activeModal];

		if (!visible) {
			return null;
		}

		return (
			<Modal
				{...modalProps}
				{...this.props}
				activateModal={(...args) => this._activateModal(...args)}
				closeModal={() => this._closeModal()}
			/>
		);
	}

	componentWillUnmount() {
		this._unregisterListener();
	}

	_activateModal(modal, props = {}) {
		this.setState({
			activeModal: modal,
			modalProps: props,
		});
	}

	_closeModal() {
		this.setState({
			visible: false,
			activeModal: this._recognizeModal(),
		});

		this.props.onClose();
	}

	_recognizeModal() {
		const submissionTypes = this.props.challenge.submissionTypes();

		if (_.isEmpty(submissionTypes)) {
			return 'error';
		} else if (submissionTypes.length > 1) {
			return 'selection';
		} else if (submissionTypes.includes('text')) {
			return 'text';
		} else if (submissionTypes.includes('image')) {
			return 'image';
		}

		throw new Error('Not supported submission type');
	}
}

export default injectIntl(wire(['services.modalService'], CreateSubmissionComponent));
