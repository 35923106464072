import AchievementTagService from 'services/tag/subServices/AchievementTagService';
import ItemTagService from 'services/tag/subServices/ItemTagService';
import NewsTagService from 'services/tag/subServices/NewsTagService';

export default {
	'services.achievementTagService': {
		class: AchievementTagService,
	},
	'services.itemTagService': {
		class: ItemTagService,
	},
	'services.newsTagService': {
		class: NewsTagService,
	},
};
