import Question from 'models/common/form/question/Question';
import CustomQuestionAnswer from 'models/common/form/questionAnswer/CustomQuestionAnswer';

export default class ClosedQuestion extends Question {
	constructor(question) {
		super(question);

		this._answers = question.answers || [];
	}

	answers() {
		return this._answers;
	}

	customAnswer() {
		return this._answers.find(answer => answer instanceof CustomQuestionAnswer);
	}
}
