import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import { Formik } from 'formik';

import { schema } from './SetPassword.schema';

import SetPassword from './SetPassword.template';

export class SetPasswordComponent extends PureComponent {
	static actions = {
		'reset-password': 'resetPasswordComplete',
		register: 'registerComplete',
	};

	render() {
		return (
			<Formik
				initialValues={{
					password: '',
					confirmPassword: '',
				}}
				validationSchema={schema}
				onSubmit={(...args) => this.setPassword(...args)}
				render={props => <SetPassword {...props} {...this.props} />}
			/>
		);
	}

	setPassword(values, actions) {
		const { integration, token, action } = this.props;
		const actionName = this.constructor.actions[action];

		if (values.password !== values.confirmPassword) {
			actions.setFieldError('confirmPassword', {
				messageId: 'passwords-differ',
			});
			actions.setSubmitting(false);

			return;
		}

		this.props['services.emailIntegrationService']
			// eslint-disable-next-line no-unexpected-multiline
			[actionName]({
				id: integration.id,
				reset_token: token,
				password: values.password,
			})
			.catch(({ error }) => {
				actions.setFieldError('confirmPassword', error);
				actions.setSubmitting(false);
			});
	}
}

export default wire(['services.emailIntegrationService'], SetPasswordComponent);
