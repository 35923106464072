import _ from 'lodash';

export default class Currencies {
	constructor(currencies, options) {
		this._values = _.filter(currencies, currency => currency.absValue() > 0 || options.allowZeroValues);
	}

	values() {
		return this._values;
	}

	hasValues() {
		return !_.isEmpty(this.values());
	}

	getCurrencyByName(name) {
		return _.find(this._values, currency => currency.codeName() === name);
	}
}
