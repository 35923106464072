import React, { useEffect, useState } from 'react';
import { wire } from 'react-hot-wire';
import { FormattedMessage } from 'react-intl';

import View from 'components/_/common/view/list/ListView';

import AchievementList from './AchievementList';

function AchievementListView(props) {
	const achievementService = props['services.achievementService'];
	const {
		match: {
			params: { tag },
		},
	} = props;
	const [tags, setTags] = useState([]);

	useEffect(() => {
		(async () => {
			setTags(await achievementService.fetchTagList());
		})();
	}, [setTags, achievementService]);

	return (
		<View
			name="achievementList"
			i18nKey="achievement-list"
			config={{
				search: false,
			}}
			tabs={{
				url: '/achievement/list/${tab}', // eslint-disable-line no-template-curly-in-string
				list: [
					{
						tab: 'all',
						content: <FormattedMessage id="achievement-list.all-tab" />,
					},
					...tags.map(_tag => ({
						tab: _tag.name,
						content: _tag.description,
					})),
				],
			}}
		>
			<AchievementList tag={tag} achievementService={achievementService} />
		</View>
	);
}

export default wire(['services.achievementService'], AchievementListView);
