import AssignRoleAction from 'models/onboarding/process/action/AssignRoleAction';
import { useMemo } from 'react';

export type UseRequiredRoleListProps = {
	actions: AssignRoleAction[];
};

const useRequiredRoleList = ({ actions }: UseRequiredRoleListProps) => {
	return useMemo(() => {
		const roles = new Set<string>();

		actions.forEach(action => roles.add(action.roleId));

		return Array.from(roles.values());
	}, [actions]);
};

export default useRequiredRoleList;
