import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import Message from './Message';

/**
 * Class representing a notification.
 * @abstract
 */
export default class Notification {
	/**
	 * @type {boolean}
	 * @protected
	 */
	_removed = false;

	/**
	 * @param {Object} notification
	 */
	constructor(notification) {
		/**
		 * @type {number}
		 * @private
		 */
		this._id = Math.random() * 0x1000;
		/**
		 * @type {string}
		 * @private
		 */
		this._category = notification.category;
		/**
		 * @type {Date}
		 * @private
		 */
		this._created = moment(notification.created);
		/**
		 * @type {boolean}
		 * @private
		 */
		this._wasSeen = !!notification.was_seen;
		/**
		 * @type {string}
		 * @private
		 */
		this._templateMachineName = notification.template_machine_name;
		/**
		 * @type {string}
		 * @private
		 */
		this._discriminator = notification.discriminator;
		/**
		 * @type {Object}
		 * @private
		 */
		this._earnings = notification.earnings;
		/**
		 * @type {string|null}
		 * @private
		 */
		this._type = null;
		/**
		 * @type {string|null}
		 * @private
		 */
		this._title = null;
	}

	id() {
		return this._id;
	}

	category() {
		return this._category;
	}

	type() {
		return this._type;
	}

	/**
	 * @param {string} type
	 * @param {Object} values
	 * @returns {Message}
	 */
	getMessage(type, values) {
		return new Message(`notification.static.${type}`, values);
	}

	/**
	 * @returns {boolean}
	 */
	removed() {
		return this._removed;
	}

	/**
	 * @param {string} type
	 * @param {Object} values
	 * @param {string} format
	 * @return {Message}
	 */
	getDate(type, { values = {}, format } = {}) {
		return new Message(`notification.static.${type}`, {
			date: <strong>{Notification.getFormettedDate(this._created, format)}</strong>,
			...values,
		});
	}

	/**
	 * Types:
	 *   * achievement: achievement.image
	 *   * challengeSubmission: new/accepted/rejected icon
	 *   * email: images.staticNotificationTypes.email
	 *   * item: assets[].image
	 *   * task: task.icon
	 *   * default: NULL; PROPOSAL: images.staticNotificationTypes.default
	 *
	 * @param {string} path
	 * @returns {string}
	 */
	getIconPath(path) {
		if (!path) {
			return null;
		}

		return path;
	}

	imageAlternativeText() {
		return null;
	}

	earnings() {
		return this._earnings;
	}

	hasEarnings() {
		return this.earnings() && _.some(this.earnings().values(), currency => currency.value() !== 0);
	}

	title() {
		return this._title;
	}

	/**
	 * @param {string} value
	 * @param {string} format
	 * @returns {string}
	 */
	static getFormettedDate(value, format = Notification.DATE_FORMAT) {
		return moment(value).format(format);
	}

	/**
	 * @type {string}
	 */
	static DATE_FORMAT = 'DD-MM-YYYY HH:mm:ss';
}
