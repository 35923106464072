import React, { PureComponent } from 'react';

export default function(Image) {
	return class ImageBlock extends PureComponent {
		render() {
			const { block } = this.props;

			return (
				<div className="wrapper--row el--grow justify--center">
					<div className="block--image imageWrapper">
						<Image src={block.image()} alt={block.image()} />
					</div>
				</div>
			);
		}
	};
}
