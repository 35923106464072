import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';

import Loader from 'components/commons/_/loader/Loader';

import { ProgressBar } from 'components/_/common/progress-bar/ProgressBar';
import { Currency } from 'components/_/common/currency/Currency';
import { Markdown } from 'components/_/common/text/markdown/Markdown';

import useFetch from 'components/_/hooks/useFetch';

function calculateProgress(achieved, all) {
	if (!all) {
		return 0;
	}

	return Math.round((achieved / all) * 100);
}

function AchievementList(props) {
	const i18nKey = 'achievement-list';
	const { achievementService } = props;

	const {
		loading,
		error,
		response: { list },
	} = useFetch(() => achievementService.fetchList().then(list => ({ list })), { list: [] });

	const achieved = reduce(list, (sum, achievement) => sum + achievement.isAchieved(), 0);
	const progress = {
		achieved,
		percent: calculateProgress(achieved, list.length),
		all: list.length,
	};

	return (
		<Fragment>
			<hr className="d-none d-sm-block mb-4 mb-sm-5" />
			<div className="achievementList">
				<div className="d-flex flex-column flex-sm-row fs-4">
					<div className="achievementList__information px-3 py-4 py-sm-0 pr-sm-4">
						<div>
							<div>
								<strong className="fs-5 fs-sm-6">
									<FormattedMessage id="achievement-list.section-title.progress" />
								</strong>
								<p className="py-3 pb-sm-5">
									<FormattedMessage id="achievement-list.content.progress-description" />
								</p>
								<ProgressBar key={progress.percent} progress={progress.percent} className="fs-5" />
								<div className="mt-3">
									<div className="d-flex justify-content-between">
										<div>
											<strong>{progress.achieved}</strong> <span>z</span>{' '}
											<strong>{progress.all}</strong>
										</div>
										<div>{progress.percent}%</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="achievementList__list flex-grow-1 my-4 my-sm-0">
						{loading ? (
							<Loader />
						) : error ? (
							<p className="fs-6 mx-0 mx-sm-3">
								<FormattedMessage id={`${i18nKey}.error.${error}`} />
							</p>
						) : isEmpty(list) ? (
							<p className="fs-6 mx-0 mx-sm-3">
								<FormattedMessage id={`${i18nKey}.error.empty`} />
							</p>
						) : (
							<div className="row">
								{list.map(achievement => (
									<div className="col-12 col-sm-24 col-md-12 col-lg-6 col-xxl-4 mb-4 mb-sm-5">
										<div className="mx-0 mx-sm-3 h-100">
											<NavLink
												to={`/achievement/${achievement.id}/details`}
												className="position-relative d-block h-100"
											>
												{achievement.isAchieved() && (
													<div className="status d-flex align-items-center justify-content-center">
														<i className="fa fa-check" />
													</div>
												)}
												<div
													className={classnames('tile achievementList__tile rounded h-100', {
														disable:
															achievement.isAchieved() ||
															(!achievement.requirementsDescription() &&
																!achievement.hasAcquirementEarnings()),
														notAchieved: !achievement.isAchieved(),
													})}
												>
													<div className="tile__front fs-sm-5">
														<div className="d-flex flex-column h-100">
															<div className="p-4">
																<div
																	className="tile__image background-image size--cover rounded"
																	style={{
																		backgroundImage: `url(${achievement.image()})`,
																	}}
																/>
															</div>
															<p className="p-3 mt-auto text-center">
																{achievement.name}
															</p>
														</div>
													</div>
													<div className="tile__back d-none d-sm-block">
														<div className="d-flex flex-column justify-content-between px-4 pt-4 h-100">
															<Markdown className="mb-3" strip={true} limit={300}>
																{achievement.requirementsDescription()}
															</Markdown>
															{achievement.hasAcquirementEarnings() && (
																<div>
																	<FormattedMessage id="achievement-list.section-title.gain-info" />
																	<div className="d-flex flex-wrap py-3">
																		{achievement
																			.acquirementEarnings()
																			.values()
																			.map(price => (
																				<Currency
																					key={price.codeName()}
																					className="mr-2"
																					currency={price.codeName()}
																					value={price.value()}
																				/>
																			))}
																	</div>
																</div>
															)}
														</div>
													</div>
												</div>
											</NavLink>
										</div>
									</div>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		</Fragment>
	);
}

export default AchievementList;
