import achievementDetails from 'components/achievement/achievementDetails';

export default {
	'components.base.AchievementDetails': {
		factory: achievementDetails,
		deps: [
			{ $ref: 'components.Async' },
			{ $ref: 'components.AchievementDetails' },
			{ $ref: 'services.achievementService' },
		],
	},
};
