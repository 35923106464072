import BaseService from 'services/BaseService';

export default class AchievementService extends BaseService {
	_type = 'achievement';
	_typeKey = 'achievement_type';

	constructor(APIService, tagService, currencyService) {
		super();
		this.APIService = APIService;
		this._tagService = tagService;
		this._currencyService = currencyService;
	}

	/**
	 *
	 * @param {number} offset
	 * @param {number} limit
	 * @param {string} tag
	 * @returns {Promise}
	 */
	fetchList({ offset = 0, limit = 100, tag } = {}) {
		const url = 'v1/achievement/list';

		return this.APIService.get(tag ? `${url}/${tag}` : url, {
			offset,
			limit,
		}).then((list = []) => list.map(achievement => this.toModel(achievement)));
	}

	/**
	 * @returns {Promise}
	 */
	fetchTagList() {
		return []; /*this.APIService.get('v1/achievement/tagList')
			.then(tags => tags.map(tag => this._tagService.toModel(tag)));*/
	}

	/**
	 *
	 * @param {string|number} id
	 * @returns {Promise}
	 */
	fetchDetails({ id } = {}) {
		return this.APIService.get(`v1/achievement/${id}`).then(achievement => this.toModel(achievement));
	}

	toModel(achievement) {
		return super.toModel({
			...achievement,
			acquirement_earnings:
				achievement.acquirement_earnings && this._currencyService.toModel(achievement.acquirement_earnings),
		});
	}
}
