export interface AgreementDTO {
	id: string;
	body: string;
	required: boolean | null;
	is_gdpr: boolean | null;
}

export default class Agreement {
	private _id: string;
	private _body: string;
	private _required: boolean;
	private _isGDPR: boolean;
	private _checked: boolean;

	constructor(agreement: AgreementDTO) {
		this._id = agreement.id;
		this._body = agreement.body;
		this._required = !!agreement.required;
		this._isGDPR = !!agreement.is_gdpr;
		this._checked = false;
	}

	id() {
		return this._id;
	}

	body() {
		return this._body;
	}

	required() {
		return this._required;
	}

	isGDPR() {
		return this._isGDPR;
	}

	checked() {
		return this._checked;
	}

	isValid() {
		return this._required ? this._checked : true;
	}

	setCheckStatus(checked: boolean | null) {
		this._checked = !!checked;
	}
}
