import React, { PureComponent } from 'react';

export default function() {
	return class ButtonBlock extends PureComponent {
		render() {
			const { block } = this.props;

			return (
				<div className="block--button el--grow wrapper--row justify--center">
					<button type="button" className="btn__basic" onClick={() => this._navigate()}>
						<span>{block.text()}</span>
					</button>
				</div>
			);
		}

		_navigate() {
			const { block } = this.props;

			window.open(block.link(), block.openInNewWindow() ? '_blank' : '_self');
		}
	};
}
