import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import Async from 'react-async-action';
import _ from 'lodash';
import moment from 'moment';

import CalendarDay from 'models/store/item/CalendarDay';

import StoreItemDetails from './Details.template';

import Loader from 'components/commons/_/loader/Loader';

export class StoreItemDetailsComponent extends PureComponent {
	constructor(...args) {
		super(...args);

		this._wallet = this.props['services.userProfileService'].user().wallet;
		this._availability = null;
	}

	render() {
		return (
			<Async
				action={() =>
					this.props['services.storeItemService'].fetchItemDetails({
						id: this.props.match.params.id,
					})
				}
			>
				{({ isPending, isLoading, response: item, reload }) => {
					if (isPending || isLoading) {
						return <Loader />;
					}

					this._availability = item.currentAvailabilityWithStock() || item.nearestAvailability();

					return (
						<StoreItemDetails
							item={item}
							wallet={this._wallet}
							reload={reload}
							availability={this._availability}
							infoCloudText={day => this._infoCloudText(item, day)}
							prepareCalendar={this._prepareCalendar(item)}
							missingCurrencies={this._missingCurrencies()}
							userProfileService={this.props['services.userProfileService']}
							accessPopupService={this.props['services.accessPopupService']}
							ImageViewer={this.props['components.ImageViewer']}
							Currencies={this.props['components.Currencies']}
							ReactMarkdown={this.props['components.ReactMarkdown']}
						/>
					);
				}}
			</Async>
		);
	}

	_infoCloudText(item, day) {
		if (day.isAvailable()) {
			const remainingQuantityOnDay = _(item.availabilitiesOnDay(day.date()))
				.flatMap(availability => availability.intervalsOnDate(day.date()))
				.map(interval => interval.currentQuantity())
				.sum();
			return {
				rightContent: String(remainingQuantityOnDay),
				message: {
					id: 'prize-details.content.calendar.info-cloud.available-on-day',
				},
			};
		} else {
			return {
				message: {
					id: 'prize-details.content.calendar.info-cloud.not-available',
				},
			};
		}
	}

	_prepareCalendar(item) {
		const startCalendarAt = moment()
			.startOf('week')
			.subtract(1, 'week');
		const daysInCalendar = numberOfDays => moment(startCalendarAt).add(numberOfDays, 'days');

		return _.times(35, daysInCalendar).map(date => new CalendarDay(date, item.isAvailableOnDate(date)));
	}

	//TODO: do not duplicate!
	_missingCurrencies() {
		const availability = this._availability;
		const missingCurrencies = [];

		if (!availability) {
			return;
		}

		_.forEach(availability.price().values(), currency => {
			const name = currency.codeName();
			const value = currency.value();
			const walletValue = this._wallet.getCurrencyByName(name).value();

			if (value > walletValue && value !== 0) {
				missingCurrencies.push({
					currency,
					value: value - walletValue,
					progress: walletValue / value,
				});
			}
		});

		return missingCurrencies;
	}
}

export default wire(
	[
		'services.storeItemService',
		'services.userProfileService',
		'services.accessPopupService',
		'components.ImageViewer',
		'components.Currencies',
		'components.ReactMarkdown',
	],
	StoreItemDetailsComponent
);
