import { GroupPointRankingEntry } from './GroupPointRankingEntry';
import { Container } from '../../../container/Container';

/**
 * Class representing a container point ranking entry.
 * @extends GroupPointRankingEntry
 */
export class ContainerPointRankingEntry extends GroupPointRankingEntry {
	constructor(entry) {
		super(entry);

		/**
		 * @type {Container}
		 */
		this.container = new Container(entry.container);
	}
}
