import BaseService from 'services/BaseService';

export default class NotificationService extends BaseService {
	_type = 'notification';
	_typeKey = 'category';

	constructor(APIService, logService) {
		super();
		this.APIService = APIService;
		this._logService = logService;
	}

	toModel(notification) {
		try {
			return super.toModel(notification);
		} catch (error) {
			this._logService.warn(error);
		}
	}

	/**
	 *
	 * @param {number} offset
	 * @param {number} limit
	 * @param {number} id - user id
	 * @param {string} filter - unseen|seen
	 * @returns {Promise}
	 */
	fetchList({ offset = 0, limit = 100, id, filter } = {}) {
		let url;

		filter = filter ? `/${filter}` : '';

		if (id) {
			url = `v1/notification/${id}/list${filter}`;
		} else {
			url = `v1/notification/list${filter}`;
		}

		return this.APIService.get(url, {
			offset,
			limit,
		}).then(({ notifications = [], has_next_page = false }) => ({
			has_next_page,
			list: notifications.map(notification => this.toModel(notification)),
		}));
	}
}
