import React from 'react';
import PropTypes from 'prop-types';

export default function(DegressionCurrency) {
	function DegressiveCurrencies({ bigIcons, parentClasses, currencies }) {
		return (
			<div className={parentClasses}>
				{currencies.map(currency => (
					<DegressionCurrency
						bigIcons={bigIcons}
						currency={currency}
						/*
							Key combined from currency name and value changes only on the value change.
							Change in value means degression.
							Newly created key causes rerender of DegressionCurrency component so it can fire animation and countdown.
							Animation on DegressionCurrency componentDidMount does not occure because of lack of previous currency.
						*/
						key={`${currency.current.value()}${currency.current.codeName()}`}
					/>
				))}
			</div>
		);
	}

	DegressiveCurrencies.propTypes = {
		bigIcons: PropTypes.bool,
		parentClasses: PropTypes.string,
		currencies: PropTypes.array,
	};

	return DegressiveCurrencies;
}
