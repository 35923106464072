import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { ReactAutoMosaic } from 'react-auto-mosaic';

import Submission from '../Submission.template';

export default ({ list }) => {
	const i18nKey = 'challenge-details.creative.submission-list';

	return (
		<div className="my-3">
			{isEmpty(list) ? (
				<p className="fs-6 pl-2 pt-2 pl-md-0">
					<FormattedMessage id={`${i18nKey}.error.empty.user`} />
				</p>
			) : (
				<ReactAutoMosaic
					gridColumns={24}
					gridGutterWidth={20}
					gridBreakpoints={{
						380: 24,
						576: 12,
						768: 6,
						992: 6,
						1280: 6,
						1600: 4,
						1920: 3,
						3840: 2,
						Infinity: 1,
					}}
				>
					{list.map(submission => (
						<Submission
							key={submission.id()}
							submission={submission}
							visibility={{
								status: true,
								judgement: true,
								author: false,
							}}
						/>
					))}
				</ReactAutoMosaic>
			)}
		</div>
	);
};
