import React, { PureComponent } from 'react';
import Async from 'react-async-action';

export default function(userService, navigationService, RequiredAgreements) {
	return class RequiredAgreementsComponent extends PureComponent {
		_agreementsRef = React.createRef();

		render() {
			return (
				<Async {...this._setAgreementsProps()}>
					{props => <RequiredAgreements agreementsRef={this._agreementsRef} {...props} />}
				</Async>
			);
		}

		_setAgreementsProps() {
			return {
				action: () => {
					if (!this._agreementsRef.current.isValid()) {
						return Promise.reject();
					}

					return userService.setAgreements({
						agreements: this._agreementsRef.current.status(),
					});
				},
				onResolve: () => navigationService.reload(),
				onDemand: true,
			};
		}
	};
}
