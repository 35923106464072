import React from 'react';
import ReactDatePicker, { getDefaultLocale } from 'react-datepicker';

import { Input } from 'components/_/form/input/Input';

export const DatePicker = ({
	format = 'yyyy-MM-dd',
	value,
	onChange,
	placeholder = 'Click to select date...',
	...inputProps
}) => {
	const props = {
		selected: value,
		onChange,
		dateFormat: format,
		shouldCloseOnSelect: true,
		locale: getDefaultLocale(),
	};

	if (format === 'yyyy') {
		props.showYearPicker = true;
	} else if (format === 'yyyy-LL') {
		props.showMonthYearPicker = true;
		props.showFullMonthYearPicker = true;
	} else if (format === 'yyyy-MM-dd') {
		props.showWeekNumbers = true;
		props.showYearDropdown = true;
		props.showMonthDropdown = true;
	} else if (format === 'yyyy-MM-dd HH:mm') {
		props.showWeekNumbers = true;
		props.showTimeSelect = true;
		props.timeIntervals = 15;
		props.showYearDropdown = true;
		props.showMonthDropdown = true;
		props.timeFormat = 'HH:mm';
		props.dateFormat = 'yyyy-MM-dd HH:mm';
	} else {
		throw new Error(`Unsupported date format: "${format}".`);
	}

	const CustomInput = ({ value, onClick, placeholder }) => {
		return <Input {...inputProps} value={value} readOnly onClick={onClick} placeholder={placeholder} />;
	};

	return <ReactDatePicker {...props} placeholderText={placeholder} customInput={<CustomInput />} />;
};
