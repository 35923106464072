import moment from 'moment';
import _ from 'lodash';

import Attributes from 'models/task/state/Attributes';

export default class TaskState {
	/**
	 * @param {Object} state
	 */
	constructor(state) {
		if (!state) {
			return;
		}

		/**
		 * @type {Attributes}
		 * @private
		 */
		this._attributes = new Attributes(state.attributes);
		/**
		 * @type {number}
		 * @private
		 */
		this._completionTimesCount = state.completion_times_count || 0;
		/**
		 * @type {null|TaskRepetitionState}
		 * @private
		 */
		this._currentRepetitionState = state.current_repetition_state;
		/**
		 * @type {Moment}
		 * @private
		 */
		this._lastCompletionTime = moment(state.last_completion_time);
		/**
		 * @type {boolean}
		 * @private
		 */
		this._repetitionInProgress = state.repetition_in_progress;
		/**
		 * @type {TaskRepetitionState[]}
		 * @private
		 */
		this._repetitionStates = state.repetition_states;
		/**
		 * @type {number}
		 * @private
		 */
		this._seen = Boolean(state.seen_at);
		/**
		 * @type {Object}
		 */
		this._earningsInformation = state.earnings_information;
		/**
		 * @type {Task}
		 */
		this._task = state.task;
		/**
		 * @type {User}
		 */
		this._player = state.player;
	}

	/**
	 * @returns {string}
	 */
	status() {
		let result = TaskState.STATUS_UNAVAILABLE;

		if (this._attributes.periodAt()) {
			if (moment(this._attributes.periodAt() * 1000).isBefore(moment())) {
				result = TaskState.STATUS_PERIODICITY;
			} else {
				result = TaskState.STATUS_COMPLETED;
			}
		} else {
			if (this._attributes.startRepetition() || this.repetitionInProgress()) {
				result = this.completionTimesCount() ? TaskState.STATUS_RETENTION : TaskState.STATUS_AVAILABLE;
			} else if (this.completionTimesCount() > 0) {
				result = TaskState.STATUS_COMPLETED;
			}
		}

		return result;
	}

	/**
	 * @returns {boolean}
	 */
	isNew() {
		return !this._seen;
	}

	/**
	 * @param {TaskRepetitionState} currentRepetitionState
	 */
	setCurrentRepetitionState(currentRepetitionState) {
		this._currentRepetitionState = currentRepetitionState;
	}

	/**
	 * @returns {boolean}
	 */
	hasLastEarnedPoints() {
		if (!this._currentRepetitionState) {
			return false;
		}

		return !_.isEmpty(this._currentRepetitionState.earned().values());
	}

	/**
	 * Checks whether task has status passed in the argument list.
	 * @param {string[]} args
	 * @returns {boolean}
	 */
	hasStatus(...args) {
		return _.includes(args, this.status());
	}

	/**
	 * @returns {Object}
	 */
	lastEarnedPoints() {
		return this._currentRepetitionState.earned();
	}

	/**
	 * @returns {Attributes}
	 */
	attributes() {
		return this._attributes;
	}

	/**
	 * @returns {number}
	 */
	completionTimesCount() {
		return this._completionTimesCount;
	}

	/**
	 * @returns {null|TaskRepetitionState}
	 */
	currentRepetitionState() {
		return this._currentRepetitionState;
	}

	/**
	 * @returns {string}
	 */
	lastCompletionTime() {
		return this._lastCompletionTime.format(TaskState.DATE_FORMAT);
	}

	/**
	 * @returns {boolean}
	 */
	repetitionInProgress() {
		return this._repetitionInProgress;
	}

	/**
	 * @returns {TaskRepetitionState[]}
	 */
	repetitionStates() {
		return this._repetitionStates;
	}

	earningsInformation() {
		return this._earningsInformation;
	}

	hasCompletionEarnings() {
		return this.earningsInformation().completion.hasValues();
	}

	task() {
		return this._task;
	}

	player() {
		return this._player;
	}

	static STATUS_RETENTION = 'retention';
	static STATUS_COMPLETED = 'completed';
	static STATUS_AVAILABLE = 'available';
	static STATUS_UNAVAILABLE = 'unavailable';
	static STATUS_PERIODICITY = 'periodicity';

	static DATE_FORMAT = 'DD-MM-YYYY HH:mm:ss';
}
