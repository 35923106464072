import answerServices from 'services/task/subServices/questionTask/trueFalse/answer';
import TrueFalseQuestionTaskService from 'services/task/subServices/questionTask/trueFalse/TrueFalseQuestionTaskService';

export default {
	...answerServices,
	'services.task.trueFalse': {
		class: TrueFalseQuestionTaskService,
		deps: [{ $ref: 'services.taskStateService' }, { $ref: 'services.task.trueFalse.answer' }],
	},
};
