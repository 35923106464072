/**
 * Class representing a achievement.
 * @abstract
 */
export default class Achievement {
	/**
	 * @param {Object} achievement
	 */
	constructor(achievement = {}) {
		/**
		 * @type {string}
		 */
		this.id = achievement.id;
		/**
		 * @type {string}
		 */
		this.name = achievement.name;
		/**
		 * @type {string}
		 */
		this.description = achievement.description;
		/**
		 * @type {string}
		 */
		this._image = achievement.image;
		/**
		 * @type {Object}
		 */
		this._acquirementEarnings = achievement.acquirement_earnings;
		/**
		 * @type {string}
		 */
		this._requirementsDescription = achievement.requirements_description;
		/**
		 * @type {Tag}
		 */
		this.tags = achievement.tags;
		/**
		 * @type {Object}
		 */
		this.state = achievement.state;
		/**
		 * @type {Array}
		 */
		this.condition = achievement.condition;
		/**
		 * @type {string}
		 */
		this.achievementType = achievement.achievement_type;
	}

	acquirementEarnings() {
		return this._acquirementEarnings;
	}

	hasAcquirementEarnings() {
		return this.acquirementEarnings().hasValues();
	}

	requirementsDescription() {
		return this._requirementsDescription;
	}

	isAchieved() {
		return !!this.state.is_achieved;
	}

	isAvailable() {
		return !this.state;
	}

	image() {
		return this._image;
	}
}
