import React, { Fragment, useState } from 'react';
import classnames from 'classnames';
import map from 'lodash/map';
import _ from 'lodash';

import { FormattedMessage } from 'react-intl';

import InfoCloud from 'components/commons/InfoCloud';
import SVGImage from 'components/commons/SVGImage';
import images from 'assets/images';

import View, { Header, Content } from 'components/_/common/view/item/ItemView';
import { Currency } from 'components/_/common/currency/Currency';
import BuyModal from './BuyModal';

export default ({
	item,
	wallet,
	reload,
	availability,
	infoCloudText,
	prepareCalendar,
	missingCurrencies,
	userProfileService,
	accessPopupService,
	ImageViewer,
	Currencies,
	ReactMarkdown,
}) => {
	const [buyModal, setBuyModalState] = useState(false);

	return (
		<View>
			{() => (
				<Fragment>
					<Header title={item.name} goBack={{ defaultLocation: '/store' }}>
						{availability && (
							<div className="d-flex">
								{map(availability.price().values(), currency => (
									<div className="ml-3">
										<Currency currency={currency.codeName()} value={currency.value()} />
									</div>
								))}
							</div>
						)}
					</Header>
					<Content>
						<div className="w-100 h-100 standardPreview prizeDetails wrapper--col">
							<div className="standardPreview__mainWrapper el--grow wrapper--col">
								<div className="wrapper--row el--grow standardPreview__columnWrapper">
									<div className="standardPreview__column standardPreview__column--left">
										<div className="sectionTitle">
											<FormattedMessage id="prize-details.section-title.prize-description" />
										</div>
										{item.description && (
											<div className="standardText markdown tests--itemDescription">
												<ReactMarkdown source={item.description} />
											</div>
										)}
										{availability && availability.price().hasValues() && (
											<div className="itemPrice">
												<div className="sectionTitle">
													<FormattedMessage id="prize-details.section-title.price" />
												</div>
												<Currencies
													parentClasses="wrapper--row align--center tests-itemPrice currencies"
													currencies={availability.price()}
												/>
											</div>
										)}
										<div className="wrapper--col el--grow missingCurrencies">
											{wallet &&
												_.map(missingCurrencies, missingCurrency => (
													<div
														key={missingCurrency.currency.codeName()}
														className={`${missingCurrency.currency.codeName()}`}
													>
														<div className="wrapper--row wrapper--row--wrap align--center">
															<p className="markdown">
																<FormattedMessage id="prize-list.content.missing-points" />
															</p>
															<div className="currencyContainer">
																<SVGImage
																	src={
																		missingCurrency.currency.icon() ||
																		images.currency[
																			missingCurrency.currency.codeName()
																		]
																	}
																	className="currencyContainer__icon image--contain"
																/>
																<span className="currencyContainer__number">
																	{missingCurrency.value}
																</span>
															</div>
														</div>
														<div className="progressBar">
															<div
																className="progressBar__content"
																style={{
																	width: `${missingCurrency.progress * 100}%`,
																}}
															/>
														</div>
													</div>
												))}
										</div>
									</div>
									<div className="standardPreview__column standardPreview__column--middle wrapper--col justify--between align--center imageViewer">
										{!_.isEmpty(item.imageAssets()) && (
											<ImageViewer imageAssets={item.imageAssets()} />
										)}
									</div>
									<div className="standardPreview__column standardPreview__column--right">
										{item.currentQuantities().remaining > 0 &&
										availability &&
										availability.inBudget() ? (
											<React.Fragment>
												<div className="sectionTitle">
													<FormattedMessage id="prize-details.section-title.available-currently" />
												</div>
												<div>
													<div className="availabilityInfo">
														<span>{Math.max(0, item.currentQuantities().remaining)}</span>
														<span className="slash" />
														<span>{item.currentQuantities().start}</span>
													</div>
												</div>
											</React.Fragment>
										) : (
											<div className="availabilityInfo">
												<FormattedMessage id="prize-details.content.sold-out" />
											</div>
										)}
										<div className="availabilityCalendar">
											<div className="availabilityCalendar__legend wrapper--row align--center justify--around">
												<div>
													<FormattedMessage id="prize-details.content.calendar.monday" />
												</div>
												<div>
													<FormattedMessage id="prize-details.content.calendar.tuesday" />
												</div>
												<div>
													<FormattedMessage id="prize-details.content.calendar.wednesday" />
												</div>
												<div>
													<FormattedMessage id="prize-details.content.calendar.thursday" />
												</div>
												<div>
													<FormattedMessage id="prize-details.content.calendar.friday" />
												</div>
												<div>
													<FormattedMessage id="prize-details.content.calendar.saturday" />
												</div>
												<div>
													<FormattedMessage id="prize-details.content.calendar.sunday" />
												</div>
											</div>
											<div className="availabilityCalendar__contentWrapper">
												<ul className="wrapper--row wrapper--row--wrap">
													{_.map(prepareCalendar, (day, index) => (
														<li
															key={index}
															className={classnames('align--center justify--center', {
																presentMonth: day.isInCurrentMonth(),
																available: day.isAvailable(),
																currentDay: day.isToday(),
																past: day.past(),
															})}
														>
															<InfoCloud
																classes="wrapper--row justify--between align--center noMargin"
																{...infoCloudText(day)}
															/>
															<span>{day.dayInMonth()}</span>
														</li>
													))}
												</ul>
											</div>
										</div>

										{userProfileService.isAnonymous() ? (
											<button
												className="btn__basic buyItem"
												onClick={() => accessPopupService.open()}
											>
												<FormattedMessage id="prize-details.button.buy-now" />
											</button>
										) : (
											Boolean(availability) && (
												<button
													className={classnames('btn__basic', {
														'btn__basic--disabled': !availability.canBuy(),
													})}
													onClick={() => availability.canBuy() && setBuyModalState(true)}
												>
													<FormattedMessage id="prize-details.button.buy-now" />
												</button>
											)
										)}
									</div>
								</div>
							</div>
							<div className="standardPreview__footer visible--mobile wrapper--row justify--center align--center">
								{userProfileService.isAnonymous() ? (
									<button className="btn__basic" onClick={() => accessPopupService.open()}>
										<FormattedMessage id="prize-details.button.buy-now" />
									</button>
								) : (
									Boolean(availability) && (
										<button
											className={classnames('btn__basic', {
												'btn__basic--disabled': !availability.canBuy(),
											})}
											onClick={() => availability.canBuy() && setBuyModalState(true)}
										>
											<FormattedMessage id="prize-details.button.buy-now" />
										</button>
									)
								)}
							</div>
							{buyModal && <BuyModal item={item} close={() => setBuyModalState(false)} reload={reload} />}
						</div>
					</Content>
				</Fragment>
			)}
		</View>
	);
};
