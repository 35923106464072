import React, { Fragment, useState } from 'react';
import { wire } from 'react-hot-wire';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import useFetch from 'components/_/hooks/useFetch';

import { ProgressBar } from 'components/_/common/progress-bar/ProgressBar';
import { Markdown } from 'components/_/common/text/markdown/Markdown';
import { Currency } from 'components/_/common/currency/Currency';
import View, { Header, Content } from 'components/_/common/view/item/ItemView';

import useStyles from './CefeteriaItemDetails.view.jss';

import BuyModal from './BuyModal.cafeteria.modal.jsx';

function CafeteriaItemDetails(props) {
	const i18nKey = 'cafeteria';
	const rewardCafeteriaService = props['services.rewardCafeteriaService'];
	const userProfileService = props['services.userProfileService'];
	const accessPopupService = props['services.accessPopupService'];
	const { id } = props.match.params;
	const [buyModal, setBuyModalState] = useState(false);
	const classes = useStyles();

	const wallet = userProfileService.user().wallet;

	const { loading, error, response: item } = useFetch(() => rewardCafeteriaService.fetchDetails({ id }));

	return (
		<View i18nKey="cafeteria-item-details" loading={loading} error={error}>
			{() => {
				const currency = wallet.getCurrencyByName(item.price.codeName());
				const canBuy = currency && currency.value() >= item.price.value();

				return (
					<Fragment>
						<Header title={item.name}>
							<Currency currency={item.price.codeName()} value={item.price.value()} />
						</Header>
						<Content>
							<div className="w-100 h-100 row no-gutters fs-6">
								<div className="col-24 col-md-7">
									<div className="p-3 pl-md-4 py-md-6 pl-lg-5">
										<div className="mb-4">
											<div className={classnames('mb-3', classes.label)}>
												<FormattedMessage id="prize-details.section-title.prize-description" />
											</div>
											<div className={classes.value}>
												<Markdown>{item.description}</Markdown>
											</div>
										</div>
									</div>
								</div>
								<div className={classnames('col-24 col-md-10', classes.middle)}>
									<div className="m-3 px-6 mx-md-4 my-md-6 m-lg-6 text-center">
										<img src={item.image} alt="" />
									</div>
								</div>
								<div className="col-24 col-md-7">
									<div className="p-3 pr-md-4 py-md-6 pr-lg-6 py-lg-6">
										{!item.isAvailable && (
											<h3 className="mb-4 text-center">
												<FormattedMessage id="prize-details.content.not-available" />
											</h3>
										)}
										<div className="mb-4">
											<div className={classnames('mb-2', classes.label)}>
												<FormattedMessage id="prize-details.section-title.price" />
											</div>
											<div className={classes.price}>
												<Currency currency={item.price.codeName()} value={item.price.value()} />
											</div>
										</div>
										{currency && !canBuy && (
											<div className={classnames('mb-4', classes.progress)}>
												<div className="d-flex align-items-center">
													<FormattedMessage id="prize-details.content.missing" />
													<Currency
														currency={currency.codeName()}
														value={item.price.value() - currency.value()}
														className="ml-1"
													/>
												</div>
												<ProgressBar
													progress={(currency.value() / item.price.value()) * 100}
													className="mt-2"
												/>
											</div>
										)}
										<div className="text-center">
											{userProfileService.isAnonymous() && item.isAvailable && canBuy ? (
												<button
													className="btn__basic"
													onClick={() => accessPopupService.open()}
												>
													<FormattedMessage id={`${i18nKey}.item.buy-now`} />
												</button>
											) : (
												<button
													className={classnames('btn__basic', {
														'btn__basic--disabled': !item.isAvailable || !canBuy,
													})}
													onClick={() => canBuy && item.isAvailable && setBuyModalState(true)}
												>
													<FormattedMessage
														id={`${i18nKey}.item.${
															item.isAvailable ? 'buy-now' : 'not-available'
														}`}
													/>
												</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</Content>
						{buyModal && <BuyModal item={item} close={() => setBuyModalState(false)} />}
					</Fragment>
				);
			}}
		</View>
	);
}

export default wire(
	['services.rewardCafeteriaService', 'services.userProfileService', 'services.accessPopupService'],
	CafeteriaItemDetails
);
