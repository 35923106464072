import React, { useMemo } from 'react';
import ActionDelayTime from './components/ActionDelayTime';
import SVGImage from 'components/commons/SVGImage';
import ActionListPlaceholder from './components/placeholder/ActionListPlaceholder';
import TaskAction, { isTaskAction } from 'models/onboarding/process/action/TaskAction';
import ActionView from './components/ActionView';
import AssignRoleAction, { isAssignRoleAction } from 'models/onboarding/process/action/AssignRoleAction';
import ActionDescription from './components/ActionDescription';
import AssignRoleActionView from './components/assignRole/AssignRoleActionView';
import useRequiredRoleList from './hooks/useRequiredRoleList';
import RoleService from 'services/onboarding/role/RoleService';
import { wire } from 'react-hot-wire';
import { useQuery } from 'react-query';
import Role from 'models/onboarding/role/Role';
import images from 'assets/images';
import useEmployeePortalContext from '../../context/employeePortalContext';
import AccessTokenService from 'services/application/AccessTokenService';
import { getActionUrl } from '../../util/getActionUrl';

export type ActionListProps = {};

export type ActionListWiredProps = ActionListProps & {
	'services.onboardingRoleService': RoleService;
	'services.accessTokenService': AccessTokenService;
};

export const ActionList = ({
	'services.onboardingRoleService': roleService,
	'services.accessTokenService': accessTokenService,
}: ActionListWiredProps) => {
	const {
		actions: { isFetching: actionListLoading, data: actionList = { items: [] }, refetch: refetchActions },
		nextActionDate,
	} = useEmployeePortalContext();
	const taskActionList = useMemo<TaskAction[]>(
		() => actionList.items.filter(action => isTaskAction(action)) as TaskAction[],
		[actionList]
	);
	const assignRoleActionList = useMemo<AssignRoleAction[]>(
		() => actionList.items.filter(action => isAssignRoleAction(action)) as AssignRoleAction[],
		[actionList]
	);
	const requiredRoles = useRequiredRoleList({ actions: assignRoleActionList });
	const roleQuery = useQuery<Role[]>(['roles', ...requiredRoles], () => {
		const requests = requiredRoles.map(role => roleService.fetchRole({ roleId: role }));

		return Promise.all(requests);
	});
	const impersonateUserProfileId = accessTokenService.impersonateUserProfileId();

	return (
		<div className="actionList">
			{!actionList.items.length && !actionListLoading && (
				<ActionListPlaceholder nextActionDate={nextActionDate} />
			)}
			{assignRoleActionList.map((action, index) => (
				<ActionView
					key={`${action.processId}-${action.roleId}`}
					action={action}
					icon={<SVGImage className="action__icon" src={images.portal.users} />}
					content={
						<AssignRoleActionView
							action={action}
							refetchActions={refetchActions}
							roles={roleQuery.data || []}
							zIndex={assignRoleActionList.length - index}
						/>
					}
					description={<ActionDescription elements={[action.user?.fullName, action.workflowName]} />}
				/>
			))}
			{taskActionList.map(action => (
				<ActionView
					key={action.id}
					action={action}
					isDelayed={action.isDelayed}
					url={getActionUrl(action, impersonateUserProfileId)}
					disableLink={!!action.closedAt}
					icon={
						(!!action.contentIcon || !!action.icon) && (
							<SVGImage
								className="action__icon"
								src={
									action.contentIcon ||
									(action.icon &&
										images.portal.sectionIcons[
											action.icon as keyof typeof images.portal.sectionIcons
										])
								}
							/>
						)
					}
					contentImage={action.contentImage}
					content={<div className="action__contentName fs-4">{action.contentName}</div>}
					description={
						<ActionDescription
							elements={[
								action.deadline && <ActionDelayTime action={action} />,
								action.isRecipientManagerLike && action.userFullName,
								action.workflowName,
							]}
							alwaysColumn={!!action.contentImage}
						/>
					}
				/>
			))}
		</div>
	);
};

export default wire<ActionListWiredProps, ActionListProps>(
	['services.onboardingRoleService', 'services.accessTokenService'],
	ActionList
);
