export interface HeadMetadataDTO {
	logo: string;
	favicon: string;
	title: string;
}

export default class HeadMetadata {
	private _logo: string;
	private _favicon: string;
	private _title: string;

	constructor(headMetadata: HeadMetadataDTO) {
		this._logo = headMetadata.logo;
		this._favicon = headMetadata.favicon;
		this._title = headMetadata.title;
	}

	logo() {
		return this._logo;
	}

	favicon() {
		return this._favicon;
	}

	title() {
		return this._title;
	}
}
