import React, { FC, useEffect, useState } from 'react';
import { wire } from 'react-hot-wire';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';

import InputSearch, { InputSearchItem, InputSearchProps } from 'components/_/form/input-search/input-search';
import TeamService from 'services/team/TeamService';

export type SelectContainerProps = Omit<InputSearchProps, 'items'> & {
	search: string;
	codeName: string;
	selectedContainer?: InputSearchItem;
	group?: string;
};

type SelectContainerWiredProps = SelectContainerProps & { 'services.teamService': TeamService };

const SelectContainer: FC<SelectContainerWiredProps> = ({
	onSelect,
	search,
	codeName,
	selectedContainer,
	group,
	'services.teamService': teamService,
	...props
}) => {
	const [data, setData] = useState<{ value: string; content: string }[] | undefined>();
	const [debouncedSearch] = useDebounce(search, 200);
	const query = useQuery(['containers', codeName, group, debouncedSearch], () => {
		if (debouncedSearch && debouncedSearch === selectedContainer?.content) {
			return [];
		}

		return teamService
			.searchContainers({ codeName, group, search: debouncedSearch })
			.then(list =>
				list
					.map(container => ({ value: container.id, content: container.name }))
					.sort((a, b) => a.content.localeCompare(b.content))
			);
	});

	useEffect(() => setData(query.data), [query.data]);

	return (
		<InputSearch
			items={data || []}
			search={search}
			onSelect={(...args) => {
				setData([]);
				onSelect && onSelect(...args);
			}}
			{...props}
		/>
	);
};

export default wire<SelectContainerWiredProps, SelectContainerProps>(['services.teamService'], SelectContainer);
