import React from 'react';

export default () => ({ answer, onCheck }) => (
	<div className="buttons">
		<button type="button" onClick={() => onCheck(true)}>
			{answer.customTrue()}
		</button>
		<button type="button" onClick={() => onCheck(false)}>
			{answer.customFalse()}
		</button>
	</div>
);
