import React, { useState } from 'react';
import randomInt from 'utils/Math/randomInt';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Moment } from 'moment';
import ActionListPlaceholderView from './ActionListPlaceholder.view';

export type ActionListPlaceholderProps = InjectedIntlProps & {
	nextActionDate: Moment | null;
};

const messageList = [
	{
		icon: '👍',
	},
	{
		icon: '🚀',
	},
	{
		icon: '🏊',
	},
	{
		icon: '🌤️',
	},
	{
		icon: '🪄',
	},
	{
		icon: '🌵',
	},
	{
		icon: '⛏',
	},
];

export const ActionListPlaceholder = ({ nextActionDate, intl }: ActionListPlaceholderProps) => {
	const [messageNumber] = useState(randomInt(1, 7));
	const message = {
		icon: messageList[messageNumber - 1].icon,
		message: intl.formatMessage({ id: `portal.action.placeholder.messages.${messageNumber}` }),
	};

	return <ActionListPlaceholderView message={message.message} icon={message.icon} nextActionDate={nextActionDate} />;
};

export default injectIntl(ActionListPlaceholder);
