import React from 'react';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import classnames from 'classnames';

export type TabOption = {
	to?: string;
	content?: string | JSX.Element;
	badge?: string | JSX.Element;
};
export type NavTabListProps<T extends TabOption> = {
	url?: string;
	list?: T[];
	render?: (item: T, jsx: JSX.Element) => JSX.Element;
	className?: string;
};

export function NavTabList<T extends TabOption>({ url, list = [], render, className }: NavTabListProps<T>) {
	return (
		<div className={classnames('tabs', className)}>
			<ul className="d-flex" onScroll={event => event.stopPropagation()}>
				{list
					.filter(item => item)
					.map((item, index) => {
						const jsx = (
							<li key={index} className="d-flex align-items-stretch mr-1 mr-md-3">
								<NavLink
									to={item.to || _.template(url)(item)}
									className="px-3 py-2 rounded-pill"
									replace
								>
									{item.content}
									{item.badge ? <span className="badge ml-2">{item.badge}</span> : null}
								</NavLink>
							</li>
						);

						return render ? render(item, jsx) : jsx;
					})}
			</ul>
		</div>
	);
}
