import React from 'react';
import PropTypes from 'prop-types';

import SVGImage from 'components/commons/SVGImage';
import images from 'assets/images';

export default function() {
	function Currency({ bigIcons, currency, currencyClasses }) {
		return (
			<div
				title={currency.description()}
				className={`currencyContainer wrapper--row align--center ${currency.codeName()} ${currencyClasses}`}
			>
				<SVGImage
					src={
						currency.icon() || images.currency[bigIcons ? `${currency.codeName()}Big` : currency.codeName()]
					}
					className="currencyContainer__icon image--contain currency"
				/>
				<span className="currencyContainer__number">{currency.value()}</span>
			</div>
		);
	}

	Currency.propTypes = {
		bigIcons: PropTypes.bool,
		currency: PropTypes.object,
	};

	return Currency;
}
