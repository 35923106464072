import entryBlocks from 'components/application/entryBlocks';
import landingPage from 'components/application/landingPage';
import realTimeNotifications from 'components/application/realTimeNotifications';
import requiredAgreements from 'components/application/requiredAgreements';
import languageSwitcher from 'components/application/languageSwitcher';
import MenuAnchor from 'components/application/MenuAnchor';
import Palette from 'components/application/Palette';

export default {
	...entryBlocks,
	...landingPage,
	...realTimeNotifications,
	...requiredAgreements,
	'components.base.LanguageSwitcher': {
		factory: languageSwitcher,
		deps: [{ $ref: 'services.i18nService' }],
	},
	'components.MenuAnchor': {
		factory: MenuAnchor,
	},
	'components.Palette': {
		factory: Palette,
		deps: [{ $ref: 'services.colorService' }],
	},
};
