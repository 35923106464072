import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';

/**
 * TODO: make this into route component or into onEntry hook, once
 * TODO: a) react-router is upgraded (route component)
 * TODO: b) router is no longer initiating the whole application, and instead first application is loaded, and THEN any
 * TODO:    routing is done, able to authorize users (onEntry hook)
 */
class AnonymousOnly extends PureComponent {
	constructor(props) {
		super(props);

		if (this.props['services.userService'].isNonAnonymous()) {
			this.props['services.navigationService'].replace('/');
		}
	}

	render() {
		return this.props['services.userService'].isNonAnonymous() ? null : (
			<React.Fragment>{this.props.children}</React.Fragment>
		);
	}
}

export default wire(['services.navigationService', 'services.userService'], AnonymousOnly);
