import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';

import Like from './Like.template';

export class LikeComponent extends PureComponent {
	constructor(...args) {
		super(...args);

		this._userProfileService = this.props['services.userProfileService'];
		this._loggedUser = this._userProfileService.user();

		const {
			userJudgement: { liked },
			submission,
			submission: { challenge },
		} = this.props;
		let userCanJudgement = !liked;

		// anonymous user can't vote for submission
		if (this._userProfileService.isAnonymous()) {
			userCanJudgement = true;
		} else {
			const submissionUserProfile = submission.author();
			const loggedUserProfile = this._loggedUser.userProfile;

			// user can't vote for his submission
			if (submissionUserProfile.id() === loggedUserProfile.id()) {
				userCanJudgement = false;
			}
		}

		if (!challenge.canJudgement()) {
			userCanJudgement = false;
		}

		let userLiked = userCanJudgement ? false : liked;

		this.state = {
			userCanJudgement,
			userLiked,
			likeCount: submission.likeCount(),
		};
	}

	render() {
		return <Like {...this.state} challenge={this.props.submission.challenge} onLike={() => this._onLike()} />;
	}

	_onLike() {
		if (!this.state.userCanJudgement) {
			return;
		}

		// if anonymous click heart - open access popup
		if (this._userProfileService.isAnonymous()) {
			this.props['services.accessPopupService'].open();

			return;
		}

		this.setState({ userLiked: true, userCanJudgement: false });

		this.props['services.challengeSubmissionJudgeService']
			.like({
				id: this.props.submission.id(),
			})
			.then(({ likeCount }) => this.setState({ likeCount }));
	}
}

export default wire(
	['services.challengeSubmissionJudgeService', 'services.userProfileService', 'services.accessPopupService'],
	LikeComponent
);
