import { Ranking } from '../ranking/Ranking';

/**
 * Class representing a submission ranking.
 * @extends Ranking
 */
export class SubmissionRanking extends Ranking {
	constructor(ranking) {
		super(ranking);

		/**
		 * @type {"score"|"likes"}
		 */
		this.rankBy = ranking.rank_by;
	}
}
