import mode from 'services/task/subServices/questionTask/trueFalse/answer/mode';
import subServices from 'services/task/subServices/questionTask/trueFalse/answer/subServices';
import TrueFalseAnswerService from 'services/task/subServices/questionTask/trueFalse/answer/TrueFalseAnswerService';

export default {
	...mode,
	...subServices,
	'services.task.trueFalse.answer': {
		class: TrueFalseAnswerService,
		deps: [{ $ref: 'services.logService' }],
		calls: [
			{
				name: 'registerSubService',
				args: ['true_false', { $ref: 'services.task.trueFalse.answer.trueFalse' }],
			},
		],
	},
};
