import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import classnames from 'classnames';

import images from 'assets/images';

export class Logo extends PureComponent {
	render() {
		const logo = this.props['services.applicationService']
			.settings()
			.headMetadata()
			.logo();

		return (
			<div className="logo d-flex mb-4 py-2 justify-content-between align-items-center">
				<div className="line flex-grow-1" />
				<img
					className={classnames('d-block m-auto position-relative', {
						grayscale: !!logo,
					})}
					src={logo || images.example.gamfiLogo}
					alt=""
				/>
				<div className="line flex-grow-1" />
			</div>
		);
	}
}

export default wire(['services.applicationService'], Logo);
