import React from 'react';

export const HeaderContent = ({ formBlock }) => {
	const Level = `h${formBlock.level()}`;

	return (
		<div>
			<Level className="mb-0">{formBlock.body()}</Level>
		</div>
	);
};
