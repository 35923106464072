import contacts from 'services/onboarding/contacts';
import employee from 'services/onboarding/employee';
import knowledgeBase from 'services/onboarding/knowledgeBase';
import process from 'services/onboarding/process';
import role from 'services/onboarding/role';

export default {
	...contacts,
	...employee,
	...knowledgeBase,
	...process,
	...role,
};
