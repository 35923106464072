export default function() {
	return class GoogleAnalytics {
		constructor(config) {
			if (typeof window !== 'undefined') {
				if (!window.ga) {
					// boilerplate from google below makes window.ga queue added events before
					// the real analytics script is loaded, so there is no need to asynchronous
					// handling of that script
					(function(i, s, o, g, r, a, m) {
						i.GoogleAnalyticsObject = r;
						i[r] =
							i[r] ||
							function(...args) {
								(i[r].q = i[r].q || []).push(...args);
							};
						i[r].l = 1 * new Date();
						a = s.createElement(o);
						m = s.getElementsByTagName(o)[0];
						a.async = 1;
						a.src = g;
						m.parentNode.insertBefore(a, m);
					})(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
				}

				this._name =
					(config.name() || '') +
					Math.random()
						.toString()
						.slice(2);

				window.ga('create', {
					trackingId: config.id(),
					cookieDomain: config.cookieDomain(),
					name: this._name,
				});
			}
		}

		track(eventName) {
			if (typeof window !== 'undefined' && window.ga) {
				window.ga(this._name + '.send', eventName);
			}
		}
	};
}
