import CSSTransformAdapter from 'services/application/CSSTransform/adapters/CSSTransformAdapter';
import Style from 'models/application/color/Style';
import { each } from 'lodash';

export default class CSSVariableTransformAdapter extends CSSTransformAdapter {
	transform(content, variables) {
		if (this._isNativeSupport()) {
			return this._nativeTransform(content, variables);
		} else {
			return this._polyfillTransform(content, variables);
		}
	}

	_isNativeSupport() {
		return window.CSS && window.CSS.supports && window.CSS.supports('(--foo: red)');
	}

	_nativeTransform(content, variables) {
		const style = new Style();
		each(variables, (value, name) => style.addVariable(name, value));

		return content;
	}

	_polyfillTransform(content, variables) {
		return content.replace(
			/var\(([^)]+)\)/g,
			// slice(2), e.g. --variable--name => variable--name
			(match, variable) => variables[variable.slice(2)] || match
		);
	}
}
