import when from 'when';
import { wire } from 'react-hot-wire';

export default function(ImageComponent) {
	//TODO: render ImageComponent instead of extends
	class ImageCropper extends ImageComponent {
		state = {
			avatar: {
				blob: null,
				image: null,
			},
			inProgress: false,
			error: null,
		};

		setBlob() {
			return new Promise((resolve, reject) => {
				if (!this.state.avatar.image) {
					return reject();
				} else {
					let dataURI = this.state.avatar.image;
					let byteString = atob(dataURI.split(',')[1]);

					// separate out the mime component
					let mimeString = dataURI
						.split(',')[0]
						.split(':')[1]
						.split(';')[0];

					// write the bytes of the string to an ArrayBuffer
					let ab = new ArrayBuffer(byteString.length);

					// create a view into the buffer
					let ia = new Uint8Array(ab);

					// set the bytes of the buffer to the correct values
					for (let i = 0; i < byteString.length; i++) {
						ia[i] = byteString.charCodeAt(i);
					}

					// write the ArrayBuffer to a blob, and you're done
					let blob = new Blob([ab], { type: mimeString });

					this.setState(
						{
							avatar: {
								...this.state.avatar,
								blob,
							},
							inProgress: false,
						},
						resolve
					);
				}
			});
		}

		_onImageChanged(event) {
			event.preventDefault();

			const image = event.currentTarget.files[0];

			this.setState({
				avatar: {
					...this.state.avatar,
					blob: null,
				},
				inProgress: true,
				error: null,
			});

			return when
				.promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onload = () => resolve(reader.result);
					reader.onerror = error => reject(error);
					reader.readAsDataURL(image);
				})
				.then(result =>
					this.props['services.imageScalerService'].scaleImage(result, {
						convertToType: 'image/jpeg',
					})
				)
				.delay(1000)
				.then(scaledImage =>
					this.setState({
						avatar: {
							...this.state.avatar,
							image: scaledImage,
						},
					})
				)
				.catch(error => this.setState({ error, inProgress: false }));
		}

		_cropperParameters() {
			return {
				src: this.state.image,
				...ImageCropper.CROPPER_PARAMETERS,
			};
		}

		static CROPPER_PARAMETERS = {
			viewMode: 3,
			dragMode: 'none',
			autoCrop: false,
			zoomable: false,
			autoCropArea: 1,
			toggleDragModeOnDblclick: false,
			background: false,
		};
	}

	return wire(['services.imageScalerService'], ImageCropper);
}
