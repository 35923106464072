import { ImportedRankingEntry } from './ImportedRankingEntry';
import Team from '../../../team/Team';

/**
 * Class representing a team imported ranking entry.
 * @extends ImportedRankingEntry
 */
export class TeamImportedRankingEntry extends ImportedRankingEntry {
	constructor(entry) {
		super(entry);

		/**
		 * @type {Team}
		 */
		this.team = new Team(entry.team);
	}
}
