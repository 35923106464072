import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Formik, Form, Field } from 'formik';

import { Markdown } from 'components/_/common/text/markdown/Markdown';

export default function(Layout) {
	return injectIntl(
		class GuessPhraseQuestionTask extends PureComponent {
			render() {
				const { intl, task, goBack } = this.props;

				return (
					<Formik
						initialValues={{
							answer: '',
						}}
						onReset={() => this.props.task.reset()}
						onSubmit={({ answer }, actions) =>
							this.props
								.process({
									answer,
								})
								.then(() => actions.setSubmitting(false))
						}
					>
						{({ handleReset, isSubmitting }) => (
							<Form className="form d-flex flex-grow-1">
								<Layout
									task={task}
									actions={{
										submit: {},
										goBack,
										tryAgain: handleReset,
										isSubmitting,
									}}
								>
									<div className="p-2 p-md-0">
										<p className="fs-4 text-center mb-3 mb-md-4 color-content">
											<FormattedMessage id="task-details.guess-phrase-question.content.task-description" />
										</p>
										<div className="row no-gutters">
											<div className="col-24 col-md-12 order-last order-md-first text-center mx-auto fs-6">
												<Markdown className="mb-2 color-content">
													{task.question().body()}
												</Markdown>

												<div className="row no-gutters">
													<div className="col-24 col-md-16 mx-auto">
														<Field
															type="text"
															name="answer"
															autoFocus
															placeholder={intl.formatMessage({
																id:
																	'task-details.guess-phrase-question.input.enter-answer',
															})}
															className="text-center p-2"
															style={{ fontSize: '1.2rem' }}
															disabled={
																task.state().hasStatus('completed') ||
																task.isCompleted() ||
																task.isSubmitted()
															}
														/>
													</div>
												</div>
											</div>
											{task.question().image() ? (
												<div className="col-24 col-md-12 order-first order-md-last mb-3">
													<div className="ml-0 ml-md-3">
														<img
															src={task.question().image()}
															alt=""
															className="mw-100 d-block m-auto rounded"
														/>
													</div>
												</div>
											) : null}
										</div>
									</div>
								</Layout>
							</Form>
						)}
					</Formik>
				);
			}
		}
	);
}
