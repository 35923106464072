import Employee, { EmployeeDTO } from '../../employee/Employee';

export interface ActionDTO extends Omit<EmployeeDTO, 'user_id'> {
	workflow_name: string;
	content_type: string;
	user_id: string | null;
	process_id: string;
}

export default abstract class Action {
	protected _workflowName: string;
	protected _contentType: string;
	protected _user: Employee | null;
	protected _processId: string;

	protected constructor(actionDTO: ActionDTO) {
		this._workflowName = actionDTO.workflow_name;
		this._contentType = actionDTO.content_type;
		this._user = actionDTO.user_id ? new Employee(actionDTO as EmployeeDTO) : null;
		this._processId = actionDTO.process_id;
	}

	get workflowName() {
		return this._workflowName;
	}

	get contentType() {
		return this._contentType;
	}

	get user() {
		return this._user;
	}

	get processId() {
		return this._processId;
	}
}
