import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import registerIntegrations from './integrations';
import { Integration } from 'components/auth/Integration';

export const Or = () => <div className="or" data-text="lub" />;

export default ({ OAuthIntegrations, otherIntegrations, loginEnabled }) => (
	<Fragment>
		{OAuthIntegrations.length > 0 && (
			<div className="integrations--oauth">
				{OAuthIntegrations.map(integration => Integration(registerIntegrations, integration, 'register'))}
				{otherIntegrations.length > 0 && <Or />}
			</div>
		)}
		<div className="integrations--other">
			{otherIntegrations.map((integration, index) => (
				<Fragment key={index}>
					{Integration(registerIntegrations, integration)}
					{index < otherIntegrations.length - 1 && <Or />}
				</Fragment>
			))}
		</div>
		{loginEnabled && (
			<p>
				<FormattedMessage id="auth.register.navigate.already-have-account" tagName={React.Fragment} />{' '}
				<NavLink to="/auth/login?disableLandingPage">
					<FormattedMessage id="auth.register.navigate.login" tagName={React.Fragment} />
				</NavLink>
			</p>
		)}
	</Fragment>
);
