import { ContainerImportedRankingService } from './ContainerImportedRankingService';
import { TeamImportedRankingService } from './TeamImportedRankingService';
import { UserImportedRankingService } from './UserImportedRankingService';

export default {
	'services.ranking.containerImportedRankingService': {
		class: ContainerImportedRankingService,
	},
	'services.ranking.teamImportedRankingService': {
		class: TeamImportedRankingService,
	},
	'services.ranking.userImportedRankingService': {
		class: UserImportedRankingService,
	},
};
