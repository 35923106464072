import realTimeNotifications from 'components/application/realTimeNotifications/realTimeNotifications';

export default {
	'components.base.RealTimeNotifications': {
		factory: realTimeNotifications,
		deps: [
			{ $ref: 'services.realTimeNotificationService' },
			{ $ref: 'services.userProfileService' },
			{
				achievement: {
					$ref: 'components.RealTimeNotifications.type.achievement',
				},
				challengeSubmissionStateChanged: {
					$ref: 'components.RealTimeNotifications.type.challengeSubmissionStateChanged',
				},
				declarationTaskAcceptanceChanged: {
					$ref: 'components.RealTimeNotifications.type.declarationTaskAcceptanceChanged',
				},
				levelUp: {
					$ref: 'components.RealTimeNotifications.type.levelUp',
				},
				mission: {
					$ref: 'components.RealTimeNotifications.type.mission',
				},
			},
		],
	},
};
