import React, { Component } from 'react';
import { wire } from 'react-hot-wire';
import Async from 'react-async-action';

import CreativeChallengeMySubmissions from './My.template';
import Loader from 'components/commons/_/loader/Loader';

export class CreativeChallengeMySubmissionsComponent extends Component {
	render() {
		return (
			<Async action={() => this._action()} key={Math.random()}>
				<Async.Loading>
					<Loader />
				</Async.Loading>
				<Async.Resolved>
					{({ response: { list } }) => <CreativeChallengeMySubmissions list={list} />}
				</Async.Resolved>
			</Async>
		);
	}

	_action() {
		return this.props['services.challengeSubmissionService'].fetchSubmissionList({
			id: this.props.challenge.id,
			user: true,
		});
	}
}

export default wire(['services.challengeSubmissionService'], CreativeChallengeMySubmissionsComponent);
