import { TextAnswer } from 'models/task/question/questionTask/order/answer/TextAnswer';

export default class TextAnswerService {
	/**
	 * @param {Object} answer
	 * @returns {TextAnswer}
	 */
	toModel(answer) {
		return new TextAnswer(answer);
	}
}
