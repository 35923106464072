import React, { Component } from 'react';
import classnames from 'classnames';

export default function() {
	return class Content extends Component {
		render() {
			const { task, noPadding } = this.props;

			return (
				<div
					className={classnames(
						'standardPreview__mainWrapper el--grow wrapper--col overflow-auto position-relative',
						noPadding ? 'p-0' : 'p-3 p-md-5',
						{
							correct: task.isCompleted(),
							incorrect: task.isFailed(),
							'visible--laptop': task.isSubmitted(),
							...(task.acceptanceRequired() && {
								[task.state().currentRepetitionState() &&
								task
									.state()
									.currentRepetitionState()
									.acceptanceState()]: true,
							}),
						}
					)}
				>
					{this.props.children}
				</div>
			);
		}
	};
}
