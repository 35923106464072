export default class ChallengeSubmissionJudgeService {
	constructor(APIService) {
		this.APIService = APIService;
	}

	score({ id, score }) {
		return this.APIService.post(`v1/dizzy/challenge/submission/${id}/judge/score`, { score });
	}

	like({ id }) {
		return this.APIService.post(`v1/dizzy/challenge/submission/${id}/judge/like`);
	}
}
