import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';

export default SortableAnswer =>
	SortableContainer(({ answers, disabled }) => (
		<ul className="wrapper--row wrapper--row--wrap align--top answerList el--shrink">
			{answers.map((answer, index) => (
				<SortableAnswer key={`index-${index}`} index={index} answer={answer} disabled={disabled} />
			))}
		</ul>
	));
