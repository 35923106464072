import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';

import Wallet from './Wallet.template';

export class WalletComponent extends PureComponent {
	render() {
		const newPoints = this.props['services.userProfileService'].newPoints();

		return <Wallet wallet={this.props.wallet} newPoints={newPoints} />;
	}
}

export default wire(['services.currencyService', 'services.userProfileService'], WalletComponent);
