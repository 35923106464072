import React from 'react';
import { wire } from 'react-hot-wire';

import StoreBuyModal from './BuyModal.store.modal';
import BuyAcceptation from './buy-acceptation/BuyAcceptation';

export const BuyModal = ({ item, close, reload }) => {
	return item.deliveryType === 'none' ? (
		<BuyAcceptation item={item} close={close} reload={reload} />
	) : (
		<StoreBuyModal item={item} close={close} reload={reload} />
	);
};

export default wire([], BuyModal);
