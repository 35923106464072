import React, { Fragment, PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

export default function() {
	return injectIntl(
		class JoinTaskCrashed extends PureComponent {
			render() {
				const { intl } = this.props;

				return (
					<Fragment>
						<p className="description fs-6">
							<FormattedMessage id="task-details.join.join-task-crashed.description" />
						</p>
						<div
							className="title"
							data-text={intl.formatMessage({
								id: 'task-details.join.join-task-crashed.title',
							})}
						/>
					</Fragment>
				);
			}
		}
	);
}
