export class UserProfileFieldDefinition {
	constructor(definition) {
		this._id = definition.id;
		this._type = definition.type;
		this._valueType = definition.value_type;
		this._public = definition.public;
		this._userEditable = definition.user_editable;
		this._visible = definition.visible;
		this._onContactList = definition.on_contact_list;
	}

	id() {
		return this._id;
	}

	type() {
		return this._type;
	}

	valueType() {
		return this._valueType;
	}

	userEditable() {
		return this._userEditable;
	}

	onContactList() {
		return this._onContactList;
	}
}
