import agreements from 'components/auth/register/agreements';
import application from 'components/application';
import achievement from 'components/achievement';
import challenge from 'components/challenge';
import commons from 'components/commons';
import mission from 'components/mission';
import news from 'components/news';
import profile from 'components/profile';
import task from 'components/task';
import wrapper from 'components/wrapper';

export default function({ components }) {
	return {
		...agreements,
		...application,
		...achievement,
		...challenge,
		...commons,
		...mission,
		...news,
		...profile,
		...task,
		...wrapper,
		// implementation components:
		...components,
	};
}
