import Routing from 'views/routing/Routing';

export default {
	'components.Routing': {
		factory: Routing,
		deps: [
			{
				//achievement:
				AchievementDetails: {
					$ref: 'components.base.AchievementDetails',
				},
				//landingPage:
				LandingPage: { $ref: 'component.container.LandingPage' },
				//mission:
				MissionDetails: { $ref: 'components.MissionDetails' },
				//news:
				NewsDetails: { $ref: 'components.NewsDetails' },
				//task:
				TaskDetails: { $ref: 'components.TaskDetails' },
				//team:
				TeamProfileDetails: { $ref: 'components.base.TeamProfile' },
			},
			{ $ref: 'services.applicationService' },
			{ $ref: 'services.masterService' },
			{ $ref: 'browserHistory' },
		],
	},
};
