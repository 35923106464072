import * as Yup from 'yup';

export const schema = Yup.object().shape({
	title: Yup.string()
		.min(2, { id: 'too-short', value: 2 })
		.max(100, { id: 'too-long', value: 100 })
		.required({ id: 'required' }),
	text: Yup.string()
		.min(2, { id: 'too-short', value: 2 })
		.max(10000, { id: 'too-long', value: 10000 })
		.required({ id: 'required' }),
});
