import _ from 'lodash';

import AvailabilityInterval from 'models/store/AvailabilityInterval';

/**
 * Class representing a availability.
 */
export default class Availability {
	/**
	 * @param {Object} availability
	 */
	constructor(availability) {
		/**
		 * @type {string}
		 */
		this.id = availability.id;
		/**
		 * @type {boolean}
		 */
		this._canBuy = !!availability.canBuy;

		/**
		 * @type {AvailabilityInterval[]}
		 * @private
		 */
		this._intervals = (availability.intervals || []).map(interval => new AvailabilityInterval(interval));

		/**
		 * @type {AvailabilityInterval}
		 * @private
		 */
		this._minFromInterval = _.minBy(this._intervals, 'from');
		/**
		 * @type {AvailabilityInterval}
		 * @private
		 */
		this._maxToInterval = _.some(this._intervals, interval => interval.to === null).length
			? _.some(this._intervals, interval => interval.to === null)[0]
			: _.maxBy(this._intervals, 'to');
		/**
		 * @type {Currencies}
		 * @private
		 */
		this._price = availability.price;
		/**
		 * @type {Currencies}
		 * @private
		 */
		this._cost = availability.cost;
		/**
		 * @type {boolean}
		 * @private
		 */
		this._inBudget = availability.inBudget;
	}

	// Returns interval on specific date
	intervalsOnDate(date) {
		return _(this._intervals)
			.filter(interval => {
				return interval.to
					? date.isBetween(interval.from, interval.to, 'day', '[]')
					: date.isSameOrAfter(interval.from, 'day');
			})
			.value();
	}

	price() {
		return this._price;
	}

	cost() {
		return this._cost;
	}

	currentStartQuantity() {
		return _(this._intervals)
			.filter(interval => interval.isNow())
			.reduce((reduced, interval) => reduced + interval.startQuantity(), 0);
	}

	currentRemainingQuantity() {
		return _(this._intervals)
			.filter(interval => interval.isNow())
			.reduce((reduced, interval) => reduced + interval.currentQuantity(), 0);
	}

	futureRemainingQuantity() {
		return _(this._intervals)
			.filter(interval => interval.isFuture())
			.reduce((reduced, interval) => reduced + interval.currentQuantity(), 0);
	}

	canBuy() {
		return this._canBuy;
	}

	/**
	 * @returns {Date|string}
	 */
	from() {
		return this._minFromInterval.from;
	}

	/**
	 * @returns {Date|string}
	 */
	to() {
		return this._maxToInterval.to;
	}

	/**
	 * @returns {boolean}
	 */
	isNow() {
		return _.some(this._intervals, interval => interval.isNow());
	}

	isFuture() {
		return _.some(this._intervals, interval => interval.isFuture());
	}

	/**
	 * @param {Date|string} dateToCheck
	 * @returns {boolean}
	 */
	isAvailableOnDate(dateToCheck) {
		return _.some(this._intervals, interval =>
			interval.to
				? dateToCheck.isBetween(interval.from, interval.to, 'day', '[]')
				: dateToCheck.isSameOrAfter(interval.from, 'day')
		);
	}

	/**
	 * @returns {boolean}
	 */
	inBudget() {
		return this._inBudget;
	}
}
