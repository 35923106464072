import { useQuery } from 'react-query';
import { EnsureResponseData } from 'utils/type/query/EnsureResponseData';
import KnowledgeBaseService from 'services/onboarding/knowledgeBase/KnowledgeBaseService';
import KnowledgeBase from 'models/onboarding/knowledgeBase/KnowledgeBase';

export type UseGetKnowledgeBaseListProps = {
	'services.onboardingKnowledgeBaseService': KnowledgeBaseService;
};

export type UseGetKnowledgeBaseListResult = EnsureResponseData<{ items: KnowledgeBase[] }>;

const defaultData = { items: [] };

export const useGetKnowledgeBaseList = (props: UseGetKnowledgeBaseListProps): UseGetKnowledgeBaseListResult => {
	const knowledgeBaseService = props['services.onboardingKnowledgeBaseService'];
	const actions = useQuery<{ items: KnowledgeBase[] }>(
		['portal-knowledge-base'],
		() => knowledgeBaseService.fetchList(),
		{
			cacheTime: 0,
		}
	);

	return {
		...actions,
		data: Object.assign({}, defaultData, actions.data),
	};
};

export default useGetKnowledgeBaseList;
