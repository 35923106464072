import QuestionTask from 'models/task/question/questionTask/QuestionTask';
import TrueFalseQuestion from 'models/task/question/TrueFalseQuestion';

/**
 * Class representing a true-false question task.
 * @extends QuestionTask
 */
export default class TrueFalseQuestionTask extends QuestionTask {
	/**
	 * @param {Object} task
	 */
	constructor(task) {
		super(task);

		/**
		 * @type {ChooseQuestion}
		 */
		this._question = new TrueFalseQuestion(task.question);
	}

	/**
	 * @param {AnswerMode} answer
	 * @param {string} value
	 */
	setAnswerValue(answer, value) {
		this._question.setAnswerValue(answer, value);
	}

	/**
	 * @returns {boolean}
	 */
	canBeSubmitted() {
		return super.canBeSubmitted() && this._question.answersHasValue();
	}

	reset() {
		this._question.reset();
		super.reset();
	}
}
