import React, { CSSProperties, FC, useRef } from 'react';
import Autocomplete from 'react-autocomplete';
import classnames from 'classnames';
import { InjectedIntlProps, injectIntl } from 'react-intl';

export type InputSearchItem = { content: string; value: number | string | undefined };

export type InputSearchProps = Partial<Omit<Autocomplete.Props, 'items' | 'value' | 'onSelect'>> & {
	items: Array<InputSearchItem>;
	search: string;
	setSearch: (search: string) => void;
	onSelect?: (value: number | string | undefined, item?: InputSearchItem) => void;
	className?: string;
	style?: CSSProperties;
	disabled?: boolean;
	placeholder?: string;
};

const InputSearch: FC<InjectedIntlProps & InputSearchProps> = ({
	intl,
	items,
	search,
	setSearch,
	onSelect,
	className,
	style,
	disabled,
	placeholder,
	...props
}) => {
	const ref = useRef<Autocomplete>(null);

	return (
		<label className={classnames('autocomplete', className)} style={style}>
			<Autocomplete
				ref={ref}
				items={items}
				getItemValue={item => item.value}
				value={search}
				wrapperStyle={props.wrapperStyle || {}}
				inputProps={{
					placeholder: placeholder || intl.formatMessage({ id: 'common.form.block.dropdownDefault' }),
				}}
				renderMenu={children => (
					<div className="menu">
						<ul className="menu__scrollContainer">{children}</ul>
					</div>
				)}
				renderItem={(item, isHighlighted) => (
					<li className={`item ${isHighlighted ? 'item--highlighted' : ''}`} key={item.value}>
						<span className="d-block">{item.content}</span>
					</li>
				)}
				renderInput={props => (
					<div>
						<input {...props} type="text" style={{ textAlign: 'left' }} disabled={disabled} />
						<div className={classnames('arrow', { 'arrow--expanded': props['aria-expanded'] })}></div>
					</div>
				)}
				onSelect={(value, item) => {
					setSearch(item.content);
					onSelect && onSelect(value, item);
					setTimeout(() => ref.current && ref.current.blur(), 100);
				}}
				onChange={(event, value) => setSearch(value)}
				{...props}
			/>
		</label>
	);
};

export default injectIntl(InputSearch);
