import TaskAction from 'models/onboarding/process/action/TaskAction';

const solidsnakeContentTypeToUrlTag = {
	[TaskAction.contentTypes['solidsnake-task']]: 'task',
	[TaskAction.contentTypes['solidsnake-mission']]: 'mission',
	[TaskAction.contentTypes['solidsnake-form']]: 'task',
};

export function getActionUrl(
	action: Pick<TaskAction, 'contentType' | 'processId' | 'contentId' | 'id'>,
	switchUser: string
) {
	if (['form', 'story', 'page', 'knowledge-check'].includes(action.contentType)) {
		let url =
			action.contentType === 'form'
				? `/panel/task/process/${action.processId}/action/${action.id}/form`
				: action.contentType === 'story'
				? `/panel/task/process/${action.processId}/action/${action.id}/story`
				: action.contentType === 'page'
				? `/panel/task/process/${action.processId}/action/${action.id}/page`
				: action.contentType === 'knowledge-check'
				? `/panel/task/process/${action.processId}/action/${action.id}/knowledge-check`
				: '';

		if (switchUser) {
			url = url + `?_switch_user=${switchUser}`;
		}

		return url;
	}

	return `/${solidsnakeContentTypeToUrlTag[action.contentType]}/${action.contentId}/details?actionId=${action.id}`;
}
