import Integration from './Integration';

export default class OAuthIntegration extends Integration {
	constructor(integration) {
		super(integration);

		/**
		 * @type {boolean}
		 */
		this.authEnabled = integration.auth_enabled;
		/**
		 * @type {boolean}
		 */
		this.registrationEnabled = integration.registration_enabled;
	}
}
