export default function(userService) {
	return class GoogleTagManager {
		constructor(config) {
			if (typeof window !== 'undefined') {
				const { userProfile } = userService.user() || {};

				// dataLayer object is overwritten by google's scripts, so instead of keeping a reference to it,
				//  we have to keep the name of the variable in global scope instead.
				this._dataLayerName =
					'gtm_dataLayer_' +
					Math.random()
						.toString()
						.slice(2);

				(function(w, d, s, l, i) {
					var f = d.getElementsByTagName(s)[0];
					var j = d.createElement(s);
					var dl = l !== 'dataLayer' ? '&l=' + l : '';
					var data = {
						'gtm.start': new Date().getTime(),
						event: 'gtm.js',
					};

					if (userProfile) {
						data.user = {
							id: userProfile.id(),
							forename: userProfile.forename(),
							surname: userProfile.surname(),
							importedExternalId: userProfile.importedExternalId,
						};
					}

					w[l] = w[l] || [];
					w[l].push(data);

					j.async = true;
					j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
					f.parentNode.insertBefore(j, f);
				})(window, document, 'script', this._dataLayerName, config.id());
			}
		}

		track(eventName, eventData) {
			if (typeof window !== 'undefined' && this._dataLayerName) {
				window[this._dataLayerName].push({
					event: eventName,
					eventData,
				});
			}
		}
	};
}
