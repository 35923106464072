export default class QuestionAnswer {
	constructor(answer) {
		this._id = answer.id;
		this._type = answer.type;
	}

	id() {
		return this._id;
	}

	type() {
		return this._type;
	}
}
