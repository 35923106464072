import React, { Fragment, PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import map from 'lodash/map';

import View, { Header, Content } from 'components/_/common/view/item/ItemView';

import { Currency } from 'components/_/common/currency/Currency';
import { Markdown } from 'components/_/common/text/markdown/Markdown';

export default function(Currencies, AutoScrollingText, ReactMarkdown, Image) {
	return class AchievementDetails extends PureComponent {
		render() {
			const { achievement } = this.props;

			return (
				<View>
					{() => (
						<Fragment>
							<Header
								title={achievement.name}
								goBack={{
									defaultLocation: '/achievement/list',
								}}
							>
								<div className="d-flex">
									{map(achievement.acquirementEarnings().values(), currency => (
										<div className="ml-3">
											<Currency currency={currency.codeName()} value={currency.value()} />
										</div>
									))}
								</div>
							</Header>
							<Content>
								<div className="w-100 h-100 standardPreview achievementDetails wrapper--col">
									<div className="standardPreview__mainWrapper el--grow wrapper--col">
										<div className="wrapper--row el--grow standardPreview__columnWrapper">
											<div className="standardPreview__column standardPreview__column--left">
												{achievement.description && (
													<div>
														<div className="mb-3">
															<FormattedMessage id="achievement-details.section-title.achievement-description" />
														</div>
														<div className="standardText">
															<Markdown>{achievement.description}</Markdown>
														</div>
													</div>
												)}
											</div>
											<div
												className={classnames(
													'standardPreview__column standardPreview__column--middle wrapper--col justify--start align--center el--shrink',
													{
														'hidden--mobile': !achievement.image(),
													}
												)}
											>
												<div
													className={classnames(
														'image image--contain el--grow wrapper--col align--center',
														{
															notAchieved: !achievement.isAchieved(),
														}
													)}
													style={{
														backgroundImage: `url(${achievement.image()}?w=1024)`,
													}}
												>
													<Image className="visible--mobile" src={achievement.image()} />
												</div>
											</div>
											<div className="standardPreview__column standardPreview__column--right">
												{achievement.requirementsDescription() && (
													<div>
														<div className="mb-3">
															<FormattedMessage id="achievement-details.section-title.requirements" />
														</div>
														<div className="standardText">
															<Markdown>{achievement.requirementsDescription()}</Markdown>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</Content>
						</Fragment>
					)}
				</View>
			);
		}
	};
}
