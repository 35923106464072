/**
 * Class representing a challenge awarded place.
 * @abstract
 */
export default class ChallengeAwardedPlace {
	/**
	 * @param {Object} awardedPlace
	 */
	constructor(awardedPlace) {
		/**
		 * @type {string}
		 */
		this.id = awardedPlace.id;
		/**
		 * @type {StoreItem[]}
		 */
		this._items = awardedPlace.items;
		/**
		 * @type {Challenge}
		 */
		this.challenge = awardedPlace.challenge;

		/**
		 * @type {number}
		 * @private
		 */
		this._placeFrom = awardedPlace.place_from;
		/**
		 * @type {number}
		 * @private
		 */
		this._placeTo = awardedPlace.place_to;
		/**
		 * @type {string}
		 * @private
		 */
		this._awardedPlaceType = awardedPlace.awarded_place_type;
		/**
		 * @type {Currency}
		 * @private
		 */
		this._points = awardedPlace.points;
	}

	placeInDecimal() {
		return this._placeFrom === this._placeTo || !this._placeTo
			? this._placeFrom
			: `${this._placeFrom} - ${this._placeTo}`;
	}

	items() {
		return this._items;
	}

	/**
	 * @returns {Object}
	 */
	place() {
		return {
			id: this._placeFrom === this._placeTo || !this._placeTo ? 'place' : 'place-range',
			values: {
				placeFrom: this._placeFrom,
				placeTo: this._placeTo,
			},
		};
	}

	points() {
		return this._points;
	}
}
