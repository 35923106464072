import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Form, Formik } from 'formik';

import answers from 'components/task/types/question/choice/answers';

import { Markdown } from 'components/_/common/text/markdown/Markdown';

export default function(Layout, MatchingImageType) {
	return class QuestionTask extends PureComponent {
		render() {
			const { task, goBack } = this.props;

			return (
				<Formik
					initialValues={{}}
					onReset={() => this.props.task.reset()}
					onSubmit={(values, actions) => this._submit().then(() => actions.setSubmitting(false))}
				>
					{({ handleReset, isSubmitting }) => (
						<Form className="form d-flex flex-grow-1">
							<Layout
								task={task}
								actions={{
									submit: {},
									goBack,
									tryAgain: handleReset,
									isSubmitting,
								}}
							>
								<div className="wrapper--row el--grow standardPreview__columnWrapper overflow--auto noInstructionQuestion">
									<div className="wrapper--col standardPreview__column standardPreview__column--left el--shrink">
										<div className="wrapper--col el--grow el--shrink">
											<div className="fs-6 standardText questionBody el--shrink">
												<Markdown>{task.question().body()}</Markdown>
											</div>
											{task.question().image() && (
												<MatchingImageType
													classes="el--grow rounded el--shrink"
													src={task.question().image()}
												/>
											)}
											{task.question().videoUrl() && (
												<iframe
													width="100%"
													height="350px"
													src={task.question().videoUrl()}
													frameBorder="0"
													title="videoUrl"
													allowFullScreen
												/>
											)}
										</div>
									</div>
									<div
										className={classnames(
											'standardPreview__column standardPreview__column--middle wrapper--row justify--center',
											{
												'imageQuestions el--grow': task.isImageQuestionTask(),
											}
										)}
									>
										<ul className="wrapper--row wrapper--row--wrap align--top answerList el--shrink">
											{task
												.question()
												.answers()
												.map(answer =>
													React.createElement(answers[task.getAnswerType(answer)], {
														answer,
														component: this,
														key: answer.id(),
													})
												)}
										</ul>
									</div>
								</div>
							</Layout>
						</Form>
					)}
				</Formik>
			);
		}

		click(answer) {
			this.props.task.checkAnswer(answer);
			this.forceUpdate();
		}

		_submit() {
			let answers = this.props.task.question().getCheckedAnswers();

			if (answers.length) {
				return this.props.process({
					answers: answers.map(answer => ({ id: answer.id() })),
				});
			}
		}

		_tryAgain() {
			this.props.task.reset();
			this.forceUpdate();
		}
	};
}
