import React from 'react';
import PropTypes from 'prop-types';

export default function(BaseAutoScrollingText) {
	return class AutoScrollingText extends BaseAutoScrollingText {
		render() {
			const { text, classes } = this.props;

			return (
				<div className={`autoScrollingText wrapper--row el--grow ${classes}`}>
					<div
						className="autoScrollingText__wrapper wrapper--row"
						ref={element => this._setReference('scrollingContainer', element)}
						onTouchMove={() => this._stopScrollingAnimation()}
					>
						<h1 className="mb-0 text" ref={element => this._setReference('text', element)}>
							{text}
						</h1>
					</div>
				</div>
			);
		}

		static propTypes = {
			text: PropTypes.string,
		};
	};
}
