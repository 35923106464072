import React from 'react';

export default (NotificationTemplate, DeclarationTaskButton) => {
	return function declarationTaskAcceptanceChangedNotification(props) {
		return (
			<NotificationTemplate
				{...props}
				button={DeclarationTaskButton}
				disableConfetti={true}
				disableTitle={true}
			/>
		);
	};
};
