import TaskBaseService from 'services/task/TaskBaseService';
import EmbeddedAnalyticsTask from 'models/task/embeddedAnalytics/EmbeddedAnalyticsTask';

export default class EmbeddedAnalyticsTaskService extends TaskBaseService {
	toModel(task) {
		return new EmbeddedAnalyticsTask({
			...task,
			state: this._mapState(task),
		});
	}
}
