import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { Header } from 'components/_/common/view/item/ItemView';
import { NavTabList as Nav } from 'components/commons/_/tabs/NavTabList';
import { ProgressBar } from 'components/_/common/progress-bar/ProgressBar';

export default function({ Tabs }) {
	return class TargetChallenge extends Component {
		render() {
			const { challenge } = this.props;

			return (
				<div className="view__challengeDetails view__challengeDetails--creative el--grow">
					<div className="standardPreview wrapper--col challengeDetails">
						<Header title={challenge.title()} goBack={{ defaultLocation: '/challenge/list/open' }} />
						<div className="wrapper--col el--grow overflow--auto overflowContainer">
							<div className="standardPreview__mainWrapper el--grow wrapper--col">
								<Nav
									className="my-3"
									url="/challenge/${id}/${tab}" // eslint-disable-line no-template-curly-in-string
									list={[
										{
											id: challenge.id,
											tab: 'details',
											content: (
												<FormattedMessage id="challenge-details.creative.menu-tabs.details" />
											),
										},
									].filter(item => item)}
								/>
								<hr />
								<div className="challengeDetails__contentSection el--grow wrapper--col">
									<Route
										exact
										path={`/challenge/${challenge.id}/details`}
										render={() => <Tabs.ChallengeDetails challenge={challenge} user={true} />}
									/>
								</div>
							</div>
						</div>
						<div
							className={classnames(
								'challengeDetails__footer wrapper--row align--center el--shrink flex-row flex-wrap',
								{
									'justify--start': challenge.state(),
								}
							)}
						>
							{challenge.state() && challenge.isSubmissionLimit() && (
								<div className="d-flex align-items-center fs-4 w-75">
									<FormattedMessage id="challenge-details.target.content.challenge-info.completed" />
									<ProgressBar
										progress={
											(challenge.state().submissionCount() / challenge.submissionLimit()) * 100
										}
										className="fs-5 flex-grow-1 mx-2"
									/>
									{`${Math.ceil(
										(challenge.state().submissionCount() / challenge.submissionLimit()) * 100
									)}%`}
								</div>
							)}
						</div>
					</div>
				</div>
			);
		}
	};
}
