import { Component } from 'react';
import _ from 'lodash';

export default function() {
	return class TabBase extends Component {
		state = {
			active: false,
			data: {},
		};

		shouldComponentUpdate(nextProps, nextState) {
			return this.state.active !== nextState.active || !_.isEqual(this.props, nextProps);
		}
	};
}
