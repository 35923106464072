import ChooseContainer from 'views/application/entryBlocks/blocks/teamAssignment/ChooseContainer';
import ChooseTeam from 'views/application/entryBlocks/blocks/teamAssignment/ChooseTeam';
import TeamAssignment from 'views/application/entryBlocks/blocks/teamAssignment/TeamAssignment';

export default {
	'components.EntryBlocks.TeamAssignment.ChooseContainer': {
		factory: ChooseContainer,
		deps: [
			{
				$ref: 'components.base.EntryBlocks.TeamAssignment.ChooseContainer',
			},
		],
	},
	'components.EntryBlocks.TeamAssignment.ChooseTeam': {
		factory: ChooseTeam,
		deps: [{ $ref: 'components.base.EntryBlocks.TeamAssignment.ChooseTeam' }],
	},
	'components.EntryBlocks.TeamAssignment': {
		factory: TeamAssignment,
		deps: [
			{ $ref: 'components.base.EntryBlocks.TeamAssignment' },
			{ $ref: 'components.EntryBlocks.TeamAssignment.ChooseContainer' },
			{ $ref: 'components.EntryBlocks.TeamAssignment.ChooseTeam' },
		],
	},
};
