import ContentBlock from 'models/common/content/block/ContentBlock';
import AttributeView from 'models/common/content/block/attributes/AttributeView';

export default class AttributesContentBlock extends ContentBlock {
	constructor(block) {
		super(block);

		this._attributeViews = (block.attribute_views || []).map(attributeView => new AttributeView(attributeView));
	}

	attributeViews() {
		return this._attributeViews;
	}
}
