import React, { PureComponent } from 'react';

export default function(FetchAchievementDetails, AchievementDetails, achievementService) {
	return class AchievementDetailsComponent extends PureComponent {
		render() {
			return (
				<FetchAchievementDetails action={() => this._action()}>
					<FetchAchievementDetails.Resolved>
						{({ response: achievement }) => <AchievementDetails achievement={achievement} />}
					</FetchAchievementDetails.Resolved>
				</FetchAchievementDetails>
			);
		}

		_action() {
			return achievementService.fetchDetails({
				id: this.props.match.params.id,
			});
		}
	};
}
