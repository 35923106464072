import { useQuery } from 'react-query';
import { EnsureResponseData } from 'utils/type/query/EnsureResponseData';
import Action from 'models/onboarding/process/action/Action';
import ActionService from 'services/onboarding/process/action/ActionService';

export type UseGetActionListProps = {
	'services.onboardingActionService': ActionService;
	employeeId?: string;
};

export type UseGetActionListResult = EnsureResponseData<{ items: Action[] }>;

const defaultData = { items: [] };

export const useGetActionList = (props: UseGetActionListProps): UseGetActionListResult => {
	const actionService = props['services.onboardingActionService'];
	const actions = useQuery<{ items: Action[] }>(
		['portal-actions', props.employeeId],
		() => actionService.fetchActions(props.employeeId),
		{
			cacheTime: 0,
		}
	);

	return {
		...actions,
		data: Object.assign({}, defaultData, actions.data),
	};
};

export default useGetActionList;
