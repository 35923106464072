import { MasterService } from 'services/application/init/MasterService';

export default {
	'services.masterService': {
		class: MasterService,
		deps: [
			{ $ref: 'services.logService' },
			{ $ref: 'services.applicationService' },
			{ $ref: 'services.userService' },
			{ $ref: 'services.colorService' },
			{ $ref: 'services.metadataConfigurationService' },
			{ $ref: 'services.PWAWebPushService' },
			{ $ref: 'services.trackerService' },
			{ $ref: 'services.i18nService' },
			{ $ref: 'services.browserHistoryService' },
			{ $ref: 'services.authService' },
			{ $ref: 'services.userProfileService' },
			{ $ref: 'services.authenticationTrackerService' },
			{ $ref: 'services.navigationService' },
			{ $ref: 'services.taskService' },
		],
	},
};
