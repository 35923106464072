import Question from 'models/task/question/Question';

/**
 * Class representing a choose question.
 * @extends Question
 */
export default class TrueFalseQuestion extends Question {
	/**
	 * @param {Object} question
	 */
	constructor(question = {}) {
		super(question);

		/**
		 * @type {AnswerMode[]}
		 */
		this._answers = (question.answers || []);
	}

	/**
	 * @returns {AnswerMode[]}
	 */
	answers() {
		return this._answers;
	}

	/**
	 * @returns {boolean}
	 */
	answersHasValue() {
		return this._answers.every(answer => answer.hasValue());
	}

	/**
	 * @returns {{id: string, response: boolean}[]}
	 */
	values() {
		return this._answers.map(answer => ({
			id: answer.id(),
			response: answer.value(),
		}));
	}

	reset() {
		this._answers.forEach(answer => answer.reset());
	}

	/**
	 * @param {AnswerMode} answer
	 * @param {boolean} value
	 */
	setAnswerValue(answer, value) {
		this._answers.forEach(_answer => {
			if (_answer.id() === answer.id()) {
				_answer.setValue(value);
			}
		});
	}
}
