import de from 'i18n/de';
import en from 'i18n/en';
import es from 'i18n/es';
import pl from 'i18n/pl';
import szl from 'i18n/szl';
import fr from 'i18n/fr';

export default {
	de,
	en,
	es,
	pl,
	szl,
	fr,
};
