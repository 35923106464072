import React, { Component } from 'react';
import _ from 'lodash';

import Loader from 'components/commons/_/loader/Loader';

export default function() {
	return class StateManager extends Component {
		state = {
			response: null,
			error: null,
			loaded: false,
		};

		_isMounted = false;

		render() {
			if (!this.state.loaded) {
				if (this.state.error) {
					return this.errorView({ ...this.state });
				}

				return this.pendingView ? this.pendingView({ ...this.state }) : null;
			}

			return this.view({ ...this.state });
		}

		errorView({ error }) {
			return (
				<div data-error-message={JSON.stringify(error, null, '    ')}>
					<Loader />
				</div>
			);
		}

		pendingView() {
			return <Loader />;
		}

		componentDidMount() {
			this._isMounted = true;

			return this._setState(this.request(this.props));
		}

		rerender() {
			return this._setState(this.request(this.props));
		}

		UNSAFE_componentWillReceiveProps(nextProps) {
			if (!_.isEqual(this.props, nextProps)) {
				return this._setState(this.request(nextProps));
			}
		}

		componentWillUnmount() {
			this._isMounted = false;
		}

		request() {
			return new Promise(resolve => resolve());
		}

		_setState(pendingPromise) {
			this.setState({
				error: null,
				response: null,
				loaded: false,
			});

			return Promise.resolve(pendingPromise)
				.then(response => {
					if (this._isMounted) {
						this.setState({
							response,
							loaded: true,
						});
					}
				})
				.catch(error => {
					if (this._isMounted) {
						this.setState({
							error,
						});
					}

					throw error;
				});
		}
	};
}
