export default class CookieService {
	setCookie(name, value, days) {
		const date = new Date();

		date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days);

		document.cookie = `${name}=${value};path=/;expires=${date.toGMTString()}`;
	}

	getCookie(name) {
		const value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');

		return value ? value[2] : null;
	}

	deleteCookie(name) {
		this.setCookie(name, '', -1);
	}
}
