import { AnswerMode } from './AnswerMode';

/**
 * Class representing a custom answer mode.
 * @extends AnswerMode
 */
export class CustomAnswerMode extends AnswerMode {
	/**
	 * @param {Object} answer
	 */
	constructor(answer) {
		super(answer);

		/**
		 * @type {string}
		 * @private
		 */
		this._customTrue = answer.custom_true;
		/**
		 * @type {string}
		 * @private
		 */
		this._customFalse = answer.custom_false;
	}

	/**
	 * @returns {string}
	 */
	customTrue() {
		return this._customTrue;
	}

	/**
	 * @returns {string}
	 */
	customFalse() {
		return this._customFalse;
	}
}
