/**
 * Class representing a tracked mockup event.
 */
export default class TrackedMockupEvent {
	/**
	 * @param {string} name
	 * @param {Object} payload
	 */
	constructor(name, payload) {
		/**
		 * @type {string}
		 */
		this.name = name;
		/**
		 * @type {Object}
		 */
		this.payload = payload;
	}
}
