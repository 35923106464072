import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { ReactAutoMosaic } from 'react-auto-mosaic';

import Submission from '../Submission.template';

export default ({ list, challenge }) => {
	const i18nKey = 'challenge-details.creative.submission-list';

	return (
		<div className="my-3">
			{challenge.submissionPublicationAt() && !challenge.isPublicationReady() ? (
				<Fragment>
					<p className="fs-6 pl-2 pt-2 pl-md-0">
						<FormattedMessage
							id={`${i18nKey}.error.publication-at.line-1`}
							values={{
								date: challenge.submissionPublicationAt(),
							}}
						/>
					</p>
					<p className="fs-6 pl-2 pt-2 pl-md-0">
						<FormattedMessage id={`${i18nKey}.error.publication-at.line-2`} />
					</p>
				</Fragment>
			) : isEmpty(list) ? (
				<p className="fs-6 pl-2 pt-2 pl-md-0">
					<FormattedMessage id={`${i18nKey}.error.empty.common`} />
				</p>
			) : (
				<ReactAutoMosaic
					gridColumns={24}
					gridGutterWidth={20}
					gridBreakpoints={{
						380: 24,
						576: 12,
						768: 6,
						992: 6,
						1280: 6,
						1600: 4,
						1920: 3,
						3840: 2,
						Infinity: 1,
					}}
				>
					{list.map(submission => (
						<Submission
							key={submission.id()}
							submission={submission}
							visibility={{
								status: false,
								judgement: true,
								author: true,
							}}
						/>
					))}
				</ReactAutoMosaic>
			)}
		</div>
	);
};
