import answerServices from 'services/task/subServices/questionTask/order/answer';
import OrderQuestionTaskService from 'services/task/subServices/questionTask/order/OrderQuestionTaskService';

export default {
	...answerServices,
	'services.task.order': {
		class: OrderQuestionTaskService,
		deps: [{ $ref: 'services.taskStateService' }, { $ref: 'services.task.order.answer' }],
	},
};
