import moment from 'moment';

/**
 * Class representing one day in calendar.
 * TODO: Support timezones explicitly!
 */
export default class CalendarDay {
	/**
	 * @param {moment.Date} date
	 * @param {boolean} isAvailable
	 */
	constructor(date, isAvailable) {
		/**
		 * @type {string}
		 */
		this._date = moment(date);
		/**
		 * @type {boolean}
		 */
		this._isAvailable = isAvailable;
	}

	date() {
		return this._date;
	}

	dayInMonth() {
		return this._date.date();
	}

	isInCurrentMonth() {
		return this._date.isSame(moment(), 'month');
	}

	isAvailable() {
		return this._isAvailable;
	}

	isToday() {
		return this._date.isSame(moment(), 'day');
	}

	past() {
		return !this.isToday() && this._date.isBefore(moment());
	}
}
