export interface TermsAndConditionsDTO {
	cookie_policy: string;
	privacy_policy: string;
	terms_and_conditions: string;
}

export default class TermsAndConditions {
	public readonly cookiePolicy: string;
	public readonly privacyPolicy: string;
	public readonly termsAndConditions: string;

	constructor(termsAndConditions: TermsAndConditionsDTO) {
		/**
		 * @type {string}
		 */
		this.cookiePolicy = termsAndConditions.cookie_policy;
		/**
		 * @type {string}
		 */
		this.privacyPolicy = termsAndConditions.privacy_policy;
		/**
		 * @type {string}
		 */
		this.termsAndConditions = termsAndConditions.terms_and_conditions;
	}

	hasSetValues() {
		return this.values().length > 0;
	}

	values() {
		return [
			['terms-and-conditions', this.termsAndConditions],
			['cookie-policy', this.cookiePolicy],
			['privacy-policy', this.privacyPolicy],
		].filter(([, value]) => value);
	}
}
