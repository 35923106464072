import { map, every } from 'lodash';

import Task from 'models/task/Task';
import Declaration from 'models/task/declaration/Declaration';

/**
 * Class representing a declaration task.
 * @extends Task
 */
export default class DeclarationTask extends Task {
	/**
	 * @type {Declaration[]}
	 * @private
	 */
	_declarations = [];

	/**
	 * @param {Object} task
	 */
	constructor(task) {
		super(task);

		this._activeDeclarations = task.active_declarations;

		this._setDeclarations();
	}

	declarations() {
		return this._declarations;
	}

	allDeclarationsChecked() {
		return every(this._declarations, declaration => declaration.isChecked());
	}

	canBeSubmitted() {
		return super.canBeSubmitted() && this.allDeclarationsChecked();
	}

	_setDeclarations() {
		if (!this.state()) {
			return;
		}

		const currentRepetitionState = this.state().currentRepetitionState();

		this._declarations = map(
			this._activeDeclarations,
			declaration =>
				new Declaration(
					declaration,
					currentRepetitionState && currentRepetitionState.isCheckedOnServer(declaration.id)
				)
		);
	}
}
