import { createContext, useContext, useState } from 'react';

export type TTab = {
	id?: string | number;
} & Record<string, unknown>;

export type TabsOptions<T extends TTab> = {
	tabs: T[];
	initialTab?: string | number;
};

type TabsContextData = ReturnType<typeof useTabs>;

export const TabsContext = createContext<TabsContextData | null>(null);

export const useTabsContext = <T extends Record<string, unknown>>() => {
	const context = useContext(TabsContext);

	if (!context) {
		throw new Error('`useTabsContext` must be used inside Tabs provider');
	}

	return {
		...context,
		tabs: context.tabs as (T & TTab)[],
	};
};

export const useTabs = <T extends TTab>({ tabs, initialTab = tabs[0].id || 0 }: TabsOptions<T>) => {
	const [current, setCurrent] = useState<string | number | undefined>(initialTab);

	return {
		tabs: tabs.map((tab, index) => ({ ...tab, id: tab.id || index })),
		current,
		setCurrent,
	};
};
