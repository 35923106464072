import { createUseStyles } from 'react-jss';

export default createUseStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.modal,
		overflow: 'auto',
		position: 'fixed',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		background: 'var(--color--content--foreground-80)',
		padding: '0 2.375rem',
	},
	content: {
		width: '100%',
		fontSize: '1rem',
		color: theme.color.system['color--content--background'],
		position: 'relative',
		marginTop: '7.75rem',
	},
}));
