/**
 * Class representing a imported ranking entry.
 * @abstract
 */
export class ImportedRankingEntry {
	constructor(entry) {
		/**
		 * @type {string|null}
		 */
		this.description = entry.description;
		/**
		 * @type {int|float}
		 */
		this.score = entry.score;
		/**
		 * @type {(int|float)[]}
		 */
		this.scores = [this.score];
	}
}
