import adapters from 'services/application/CSSTransform/adapters';
import CSSTransformService from 'services/application/CSSTransform/CSSTransformService';

export default {
	...adapters,
	'services.CSSTransformService': {
		class: CSSTransformService,
		deps: [
			[
				{
					$ref: 'services.CSSTransformService.adapter.CSSVariableTransformAdapter',
				},
				{
					$ref: 'services.CSSTransformService.adapter.CSSColorModTransformAdapter',
				},
			],
		],
	},
};
