import React, { useState } from 'react';
import { wire } from 'react-hot-wire';
import { FormattedMessage } from 'react-intl';

import { Modal } from 'components/_/common/modal/Modal';
import { Button } from 'components/_/common/button/Button';

export const BuyAcceptation = ({ item, close, reload, ...props }) => {
	const [modal, setModal] = useState('buy');
	const [loading, setLoading] = useState(false);

	const storeItemService = props['services.storeItemService'];
	const userProfileService = props['services.userProfileService'];

	function _close() {
		close();
		reload();
	}

	function buy() {
		setLoading(true);
		storeItemService
			.buyItemFromAvailability(item.currentAvailabilityWithStock().id)
			.then(() => {
				setModal('confirmation');
				userProfileService.addToWalletQueue(item.currentAvailabilityWithStock().cost());
			})
			.catch(() => setModal('error'))
			.finally(() => setLoading(false));
	}

	return (
		<Modal close={_close} loading={loading} config={{ autoHeight: !loading }}>
			{() =>
				modal === 'buy' ? (
					<div className="text-center my-5">
						<Button onClick={_close} className="mr-3">
							<FormattedMessage id="prize-details.content.buy-item.cancel" />
						</Button>
						<Button onClick={buy} color="interactive">
							<FormattedMessage id="prize-details.content.buy-item.confirm-purchase" />
						</Button>
					</div>
				) : modal === 'confirmation' ? (
					<div className="text-center my-5">
						<h3 className="mb-4">
							<FormattedMessage id="prize-details.content.buy-item.ordered" />
						</h3>
						<Button onClick={_close} className="mr-3">
							<FormattedMessage id="prize-details.content.buy-item.close" />
						</Button>
					</div>
				) : modal === 'error' ? (
					<div className="text-center my-5">
						<h3 className="mb-4">
							<FormattedMessage id="prize-details.content.buy-item.error" />
						</h3>
						<Button onClick={_close} className="mr-3">
							<FormattedMessage id="prize-details.content.buy-item.close" />
						</Button>
					</div>
				) : null
			}
		</Modal>
	);
};

export default wire(['services.storeItemService', 'services.userProfileService'], BuyAcceptation);
