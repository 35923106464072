import footer from 'components/task/common/footer';
import Content from 'components/task/common/Content';
import Mobile from 'components/task/common/Mobile';

export default {
	...footer,
	'components.task.common.Content': {
		factory: Content,
	},
	'components.task.common.Mobile': {
		factory: Mobile,
		deps: [{ $ref: 'components.Currencies' }],
	},
};
