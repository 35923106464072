export default class BrowserHistoryService {
	history = [window.location.pathname];

	constructor(browserHistory, applicationService) {
		this._browserHistory = browserHistory;
		this._applicationService = applicationService;
	}

	push(location) {
		return this._browserHistory.push(location);
	}

	replace(location) {
		return this._browserHistory.replace(location);
	}

	goBack({ defaultLocation } = {}) {
		const previous = this.previousLocation();

		if (previous) {
			// remove current location (last element in history)
			this.history.splice(-1, 1);

			this._browserHistory.replace(previous);
		} else {
			this._browserHistory.push(this.defaultLocation({ defaultLocation }));
		}
	}

	listen(handler) {
		this._browserHistory.listen((location, action) => {
			if (action === 'PUSH') {
				this.history.push(window.location.pathname);
			} else if (action === 'POP') {
				this.history.pop();
			}

			handler();
		});
	}

	// If the previous element does exist => return it.
	//   (history should have minimum 2 visited locations)
	previousLocation() {
		return this.history[this.history.length - 1 - 1];
	}

	// Go to: defaultLocation or mainPage or "/"
	defaultLocation({ defaultLocation }) {
		const mainPage = this._applicationService.menu().mainPage();

		if (defaultLocation) {
			return defaultLocation;
		}

		if (!mainPage || mainPage.url() === this.history[0]) {
			return '/';
		}

		return mainPage.url();
	}
}
