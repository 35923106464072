import challengePrizeList from 'components/challenge/tabs/challengePrizeList';
import challengeWinners from 'components/challenge/tabs/challengeWinners';

export default {
	'components.ChallengePrizeList': {
		factory: challengePrizeList,
		deps: [
			{ $ref: 'components.StateManager' },
			{ $ref: 'services.challengeAwardedPlaceService' },
			{ $ref: 'components.Currencies' },
			{ $ref: 'components.Image' },
		],
	},
	'components.ChallengeWinners': {
		factory: challengeWinners,
		deps: [{ $ref: 'components.StateManager' }, { $ref: 'services.challengeAwardedPlaceService' }],
	},
};
