import InternalDynamicAnchorTask from 'models/application/anchor/types/internalDynamic/types/Task';

export default class InternalDynamicAnchorTaskService {
	constructor(taskService) {
		this._taskService = taskService;
	}

	toModel({ task, ...rest }) {
		return new InternalDynamicAnchorTask({
			task: this._taskService.toModel(task),
			...rest,
		});
	}
}
