import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import { Formik } from 'formik';
import queryString from 'query-string';
import { injectIntl } from 'react-intl';
import { map } from 'lodash';

import Email from './Email.template';
import { schema } from './Email.schema';

import VerifyToken from 'components/auth/reset-password/integrations/email/verify-token/VerifyToken';
import Info from '../../../Info';

export class EmailComponent extends PureComponent {
	render() {
		const { token } = queryString.parse(window.location.search);
		const { integration, intl } = this.props;

		if (token) {
			return <VerifyToken token={token} integration={integration} action="register" />;
		}

		return (
			<Formik
				initialValues={{
					email: '',
					password: '',
					agreements: [],
				}}
				validationSchema={schema}
				onSubmit={(...args) => this.register(...args)}
				render={props => {
					if (props.status === 'ok') {
						return (
							<Info
								title={intl.formatMessage({
									id: 'auth.register.integration.email.status.ok.title',
								})}
								message={intl.formatMessage({
									id: 'auth.register.integration.email.status.ok.message',
								})}
							/>
						);
					}

					return (
						<Email
							{...props}
							{...this.props}
							agreements={this.props['services.applicationService'].settings().agreements()}
							ReactMarkdown={this.props['components.ReactMarkdown']}
						/>
					);
				}}
			/>
		);
	}

	register(values, actions) {
		this.props['services.emailIntegrationService']
			.registerStart({
				id: this.props.integration.id,
				email: values.email,
				agreements: map(values.agreements, id => ({ id })),
			})
			.then(() => actions.setStatus('ok'))
			.catch(({ error }) => {
				if (error.messageId === 'agreements-required') {
					actions.setStatus('agreements-error');
				} else {
					actions.setFieldError('email', error.messageId);
				}

				actions.setSubmitting(false);
			});
	}
}

export default injectIntl(
	wire(
		['services.emailIntegrationService', 'services.applicationService', 'components.ReactMarkdown'],
		EmailComponent
	)
);
