import React, { Fragment, PureComponent } from 'react';
import { injectIntl } from 'react-intl';

import { Markdown } from 'components/_/common/text/markdown/Markdown';

export default function(ChooseContainer, ChooseTeam) {
	return injectIntl(
		class TeamJoinView extends PureComponent {
			state = {
				container: null,
				team: null,
			};

			render() {
				const { task, intl } = this.props;
				const { container } = this.state;

				const teamContainerGroup = task.containerGroup();
				const containerPrompt = task.containerPrompt();
				const teamPrompt = task.teamPrompt();

				return (
					<Fragment>
						<div className="description fs-6">
							<Markdown>{task.description()}</Markdown>
						</div>
						<div
							className="title"
							data-text={intl.formatMessage({
								id: 'task-details.join.team-join-view.title',
							})}
						/>
						<div className="fields">
							<div className="field">
								{teamContainerGroup && (
									<ChooseContainer
										group="teamContainerGroup"
										placeholder={containerPrompt}
										disabled={task.isSubmitted()}
										codeName={teamContainerGroup.id}
										handler={_container =>
											this.setState({
												container: _container,
											})
										}
									/>
								)}
							</div>
							<div className="field">
								<ChooseTeam
									placeholder={teamPrompt}
									disabled={(!container && !!teamContainerGroup) || task.isSubmitted()}
									container={container}
									handler={team => this._onChange(team)}
								/>
							</div>
						</div>
					</Fragment>
				);
			}

			_onChange(team) {
				this.setState({ team });
				this.props.onChange(team);
			}
		}
	);
}
