import CollectionMission from 'components/mission/_/mission/collection/CollectionMission';

import GraphTask from 'components/mission/types/GraphTask';
import MapTask from 'components/mission/types/MapTask';

export default {
	'components.CollectionMission': {
		factory: () => CollectionMission,
	},
	'components.GraphTask': {
		factory: GraphTask,
		deps: [
			{ $ref: 'services.taskService' },
			{ $ref: 'services.graphService' },
			{ $ref: 'services.graphInteractionService' },
			{ $ref: 'services.colorService' },
			{ $ref: 'components.Currencies' },
		],
	},
	'components.MapTask': {
		factory: MapTask,
		deps: [{ $ref: 'components.GraphTask' }],
	},
};
