export default {
	'team-roles': {
		admin: 'Admin',
		manager: 'Manager',
		user: 'Benutzer',
	},
	error: {
		title: 'Du hast keinen Zugriff auf den Inhalt oder der Inhalt ist noch nicht verfügbar!',
		button: 'Zurück zur Startseite',
		info: 'Bei Problemen wende dich  bitte an deinen technischen Administrator.',
	},
	'general-error': {
		title: 'Ein Fehler ist aufgetreten',
		refresh: 'Aktualisieren',
		contact: 'Wenden Sie sich für weitere Informationen an den Administrator.',
		details: 'Einzelheiten',
	},
	'landing-page': {
		link: {
			'sign-up': 'Registrieren',
			'sign-in': 'Einloggen',
		},
		copyright: 'Gamfi Spółka z o.o.',
		acceptation: 'Durch die Nutzung der Anwendung akzeptierst Du:',
		'terms-and-conditions': {
			'cookie-policy': 'die Cookie-Richtlinie',
			'privacy-policy': 'die Datenschutzrichtlinie',
			'terms-and-conditions': 'die Bestimmungen',
		},
	},
	auth: {
		form: {
			error: {
				required: 'Dieses Feld ist erforderlich.',
				'invalid-email': 'Die angegebene E-Mail-Adresse ist inkorrekt.',
				'invalid-identifier': 'Die angegebene ID ist inkorrekt.',
				'user-not-exists': 'Der eingegebene Benutzer oder das Kennwort ist falsch.',
				'user-not-verified': 'Der angegebene Benutzer ist nicht verifiziert.',
				'user-inactive': 'Der angegebene Benutzer ist inaktiv.',
				'user-not-found': 'Der eingegebene Benutzer oder das Kennwort ist falsch.',
				'user-profile-not-active': 'Das Benutzerkonto ist inaktiv.',
				'invalid-password-reset-token': 'Der angegebene Token ist inkorrekt.',
				'invalid-password': 'Das angegebene Passwort ist inkorrekt.',
				'passwords-differ': 'Das angegebene Passwort stimmt nicht mit dem vorherigen überein.',
				'invalid-input': 'Die eingegebenen Daten sind inkorrekt.',
				'user-already-registered': 'Der angegebene Benutzer ist bereits registriert.',
				'required-agreements': 'Diese Zustimmung ist erforderlich.',
				'agreements-required': 'Mit einem Sternchen gekennzeichnete Zustimmungen sind erforderlich.',
				'empty-password': 'Das Passwortfeld ist erforderlich.',
				'password-minimal-length': 'Erforderliche Mindestanzahl an Zeichen: {length}.',
				'password-minimal-lowercase-occurance': 'Erforderliche Mindestanzahl von Kleinbuchstaben: {occurance}.',
				'password-minimal-uppercase-occurance': 'Erforderliche Mindestanzahl von Großbuchstaben: {occurance}.',
				'password-minimal-number-occurance': 'Erforderliche Mindestanzahl von Ziffern: {occurance}.',
				'password-minimal-special-occurance': 'Erforderliche Mindestanzahl von Sonderzeichen: {occurance}.',
				'disabled-in-configuration': 'Die Integration wurde deaktiviert.',
				'user-profile-does-not-match':
					'Die angegebenen Daten stimmen nicht mit dem Profil des Benutzers überein.',
				'email-domain-not-on-whitelist': 'Kontoregistrierung ist in dieser Domain nicht möglich.',
				'not-allowed': {
					'user-registration-not-complete': 'Der Benutzer hat die Registrierung noch nicht abgeschlossen.',
					'user-not-verified': 'Der angegebene Benutzer ist nicht verifiziert.',
				},
				'one-of-two': 'Mindestens eines dieser Felder ist erforderlich.',
				'resource-error': 'Beim Anmelden ist ein Fehler aufgetreten.',
				email: {
					'invalid-domain': 'Falscher Domänenname.',
				},
				phone: {
					'invalid-format': 'Falsches Telefonnummernformat.',
					'invalid-length': 'Falsche Länge der Telefonnummer.',
				},
			},
		},
		login: {
			navigate: {
				'not-account-yet': 'Hast Du noch kein Konto?',
				register: 'Registrieren',
				'do-not-remember-data': 'Passwort festlegen oder zurücksetzen',
				'do-not-remember-identifier': 'Hast Du ID oder Passwort vergessen?',
			},
			integration: {
				email: {
					title: 'Mit deiner E-Mail-Adresse anmelden',
					field: {
						email: {
							placeholder: 'E-Mail-Adresse eingeben',
						},
						password: {
							placeholder: 'Passwort eingeben',
						},
					},
					button: {
						login: 'Einloggen',
						check: 'Einloggen',
					},
					status: {
						'reset-password': {
							title: 'Kontoaktivierung',
							message:
								'Link zum Festlegen des Passworts wurde an die angegebene E-Mail-Adresse gesendet.',
						},
					},
				},
				'imported-id': {
					title: 'Melde dich mit deiner E-Mail-Adresse oder ID an.',
					field: {
						identifier: {
							placeholder: 'Gebe deine E-Mail-Adresse oder ID ein',
						},
						password: {
							placeholder: 'Passwort eingeben',
						},
					},
					button: {
						login: 'Einloggen',
						register: 'Registrieren',
						check: 'Einloggen',
					},
					status: {
						'reset-password': {
							title: 'Passwort zurücksetzen',
							message:
								'Link zum Festlegen des Passworts wurde an die angegebene E-Mail-Adresse gesendet.',
						},
					},
				},
				oauth: {
					facebook: 'Login mit Facebook',
					generic: 'Login mit einem externen System',
					google: 'Login mit Google ',
					'linked-in': 'Login mit LinkedIn ',
					error: {
						title: 'Während der Anmeldung ist ein unerwarteter Fehler aufgetreten',
						type: {
							general: 'OAuth-Login wurde abgebrochen.',
							'user-already-registered':
								'Der Benutzer mit der angegebenen E-Mail-Adresse existiert bereits.',
							'invalid-one-time-token': 'Der angegebene Token ist inkorrekt.',
							'not-allowed': {
								'connect-profiles-before-authentication':
									'Die dem Integrationskonto zugewiesene E-Mail ist bereits in unserem Datenbank. Melde dich mit deiner E-Mail-Adresse auf der Plattform an.',
							},
						},
					},
					return: 'Klicke hier, um zur Startseite zurückzukehren.',
					loading: 'Einloggen, bitte warten...',
				},
			},
		},
		register: {
			navigate: {
				'already-have-account': 'Hast Du bereits ein Konto?',
				login: 'Einloggen',
			},
			integration: {
				email: {
					title: 'Registriere dich mit deiner E-Mail-Adresse',
					field: {
						email: {
							placeholder: 'E-Mail-Adresse eingeben',
						},
					},
					button: 'Registrieren',
					status: {
						ok: {
							title: 'Dein Konto wurde registriert',
							message:
								'Überprüfe deine E-Mail, Du findest eine E-Mail mit einem Link zur Aktivierung. Aus Sicherheitsgründen ist der Link nur 60 Minuten gültig.',
						},
					},
				},
				'imported-id': {
					title: 'Anmeldevorgang',
					field: {
						forename: {
							placeholder: 'Vorname eingeben',
						},
						surname: {
							placeholder: 'Nachname eingeben',
						},
						email: {
							placeholder: 'E-Mail-Adresse eingeben',
						},
						phone: {
							placeholder: 'Telefonnummer eingeben',
						},
					},
					button: {
						register: 'Registrieren',
					},
					status: {
						'email-sent': {
							title: 'Dein Konto wurde registriert',
							message:
								'Überprüfe deine E-Mail, Du findest eine E-Mail mit einem Link zur Aktivierung. Aus Sicherheitsgründen ist der Link nur 60 Minuten gültig.',
						},
					},
				},
				oauth: {
					facebook: 'Registrieren mit Facebook',
					generic: 'Registrieren mit einem externen System',
					google: 'Registrieren mit Google',
					'linked-in': 'Registrieren mit LinkedIn',
				},
				token: {
					title: 'Ein Konto erstellen',
					submit: 'Registrieren',
					error: {
						'no-token': 'Ungültiges Token',
					},
					status: {
						ok: {
							title: 'Dein Konto wurde registriert',
						},
					},
				},
			},
		},
		'reset-password': {
			navigate: {
				'remember-password': 'Passwort vergessen?',
				login: 'Einloggen',
			},
			integration: {
				email: {
					title: 'Passwort festlegen oder zurücksetzen',
					instruction: {
						text:
							'Geben Sie Ihre {email} ein, an die wir Ihnen eine E-Mail mit einem Link zum Einrichten Ihres Passworts senden werden.',
						email: 'E-Mail-Adresse',
					},
					field: {
						email: {
							placeholder: 'E-Mail-Adresse eingeben',
						},
					},
					button: 'Senden',
					status: {
						ok: {
							title: ' Passwort zurücksetzen',
							message: 'Melde dich mit deiner E-Mail-Adresse oder ID an.',
						},
					},
				},
				'email-annoying': {
					status: {
						ok: {
							title: ' Passwort zurücksetzen',
							message:
								'Wenn die angegebene E-Mail-Adresse in der Benutzerdatenbank vorhanden ist, wurde der Link zum Festlegen des Passworts gesendet.',
						},
					},
				},
				'imported-id': {
					title: ' Passwort zurücksetzen',
					instruction: {
						text:
							'Gebe deine {identifier} ein, die du für die Registrierung verwendet hast. Wir senden dir eine E-Mail mit einem Link oder einem Autorisierungscode per SMS, um dein Passwort zurückzusetzen.',
						identifier: 'E-Mail-Adresse oder ID',
					},
					field: {
						identifier: {
							placeholder: 'Gebe deine E-Mail-Adresse oder ID ein',
						},
					},
					button: 'Senden',
					status: {
						'email-sent': {
							title: ' Passwort zurücksetzen',
							message:
								'Link zum Festlegen des Passworts wurde an die angegebene E-Mail-Adresse gesendet.',
						},
					},
				},
			},
			action: {
				'verify-token': {
					title: 'Beim erhaltenen Link ist ein Problem aufgetreten',
					message: 'Dein Link ist abgelaufen oder ungültig',
					'reset-link':
						'{link} und geben Sie dann Ihre E-Mail-Adresse ein, um einen neuen Link zu generieren.',
					'reset-link-text': 'Klicken Sie hier',
				},
				'set-password': {
					title: {
						reset: 'Setze dein neues Passwort',
						activate: 'Aktiviere dein Konto mit Festlegen des Passworts',
					},
					field: {
						password: {
							placeholder: 'Passwort eingeben',
						},
						'confirm-password': {
							placeholder: 'Passwort wiederholen',
						},
					},
					button: {
						reset: 'Neues Passwort festlegen',
						activate: 'Konto aktivieren',
					},
				},
				'authorize-code': {
					title: {
						reset: 'Autorisierungscode eingeben',
					},
					field: {
						'authorization-code': {
							placeholder: 'Autorisierungscode eingeben',
						},
					},
				},
			},
		},
	},
	'achievement-list': {
		title: 'Abzeichen',
		subtitle: 'Beschreibung',
		'all-tab': 'Alle',
		'section-title': {
			progress: 'Dein Fortschritt',
			'gain-info': 'Für dieses Abzeichen erhältst du:',
		},
		content: {
			'progress-description': 'Aus den verfügbaren Abzeichen in der Kategorie "Alle", du hast erhalten:',
			requirements: 'Anforderungen',
		},
		error: {
			empty: 'Die Liste der Abzeichen ist leer.',
		},
	},
	'achievement-details': {
		'section-title': {
			'achievement-description': 'Beschreibung des Abzeichens:',
			requirements: 'Voraussetzungen für den Erhalt des Abzeichens:',
		},
	},
	faq: {
		title: 'FAQ',
		subtitle: 'Beschreibung',
		'all-tab': 'Alle',
		content: {
			category: 'Kategorie:',
		},
		error: {
			empty: 'FAQ ist leer.',
		},
	},
	'mission-list': {
		title: 'Missionen',
		content: {
			'tasks-execution': ' Aktivitäten {completed} von {all} ',
			'earnings-information': {
				mission: 'Für die erfüllung aller Aufgaben erhältst du:',
				task: ' Für das Abschließen dieser Aufgabe erhältst Du:',
			},
		},
		button: {
			do: 'Erledigen ',
		},
		error: {
			empty: 'Keine Missionen.',
		},
	},
	'news-details': {
		subtitle: 'Hinzugefügt: ',
		'section-title': {
			comments: 'Kommentare',
		},
	},
	'news-list': {
		title: ' Nachrichten',
		subtitle: 'Beschreibung',
		content: {
			added: 'Hinzugefügt:',
		},
		error: {
			empty: 'Nachrichtenliste ist leer',
		},
	},
	'notification-list': {
		title: 'Benachrichtigungen',
		subtitle: 'Beschreibung',
		'all-tab': 'Alle',
		error: {
			empty: 'Keine Benachrichtigungen.',
		},
	},
	'prize-list': {
		title: 'Auszeichnungen',
		subtitle: 'Beschreibung',
		'all-tab': 'Alle',
		'section-title': {
			'items-in-your-range': 'Auszeichnungen in deiner Reichweite',
		},
		button: {
			'buy-now': 'Jetzt kaufen',
			'check-out': 'Anzeigen',
			soon: 'Bald',
		},
		content: {
			amount: 'Verfügbar',
			count: '{remaining} von {start}',
			'availability-items': 'Verfügbare Stücke',
			'sold-out': 'Die Auszeichnung ist ausverkauft',
			'from-date': 'Von',
			'missing-points': 'Dir fehlt:',
			price: 'Preis',
		},
		error: {
			empty: 'Keine Auszeichnungen in der Kategorie.',
		},
	},
	'prize-details': {
		'section-title': {
			'prize-description': 'Auszeichnungsbeschreibung',
			price: 'Preis',
			'available-currently': 'Derzeit verfügbare Auszeichnungen',
		},
		button: {
			'buy-now': 'Jetzt kaufen',
			confirm: 'Bestätigen',
			resign: 'Zurücktreten',
			soon: 'Bald',
		},
		content: {
			'not-available': 'Auszeichnung nicht verfügbar!',
			missing: 'Ihnen fehlen:',
			calendar: {
				monday: 'M',
				tuesday: 'D',
				wednesday: 'M',
				thursday: 'D',
				friday: 'F',
				saturday: 'S',
				sunday: 'S',
				'info-cloud': {
					'available-on-day': 'Verfügbare Auszeichnungen an diesem Tag:',
					'not-available': 'Auszeichnung an diesem Tag nicht verfügbar.',
				},
			},
			'buy-item': {
				'confirm-purchase': 'Möchtest du eine Auszeichnung kaufen?',
				cancel: 'Abbrechen',
				close: 'Schließe es',
				ordered: 'Deine Bestellung wurde aufgenommen. Danke schön!',
				error: 'Ein Fehler ist aufgetreten',
				address: {
					create: 'Erstellen Sie eine neue Adresse...',
				},
			},
			'sold-out': 'Die Auszeichnung ist ausverkauft',
		},
	},
	ranking: {
		title: 'Rangfolge',
		subtitle: 'Beschreibung',
		content: {
			'your-place': {
				point__user: 'Deine Position',
				user_imported: 'Deine Position',
				commitment__user: 'Deine Position',
				point__team: 'Position deines Teams',
				team_imported: 'Position deines Teams',
				point__container: '{rankingName} – Deine Position',
				container_imported: '{rankingName} – Deine Position',
			},
			button: {
				'log-in': 'Einloggen',
			},
		},
		error: {
			empty: 'Rangliste ist leer',
		},
	},
	'task-details': {
		'collection-mission': {
			title: '{name}',
			button: {
				repeat: 'Wiederholen',
				do: 'Erledigen',
			},
			header: {
				'your-progress': 'Dein Fortschritt:',
				'progress-status': '{completed} von {all}',
			},
			content: {
				'gain-for-complete-mission': 'Für die erfüllung aller Aufgaben erhältst du:',
				'gain-for-complete-task': 'Für die Erledigung dieser Aufgabe  erhältst du: ',
				'new-tasks': `{
					newTasks,
					plural,
					one {Neue Aufgabe}
					other {Neue Aufgaben}
				}`,
				'done-tasks': `{
					doneTasks,
					plural,
					one {erledigte Aufgabe}
					other {erledigte Aufgabe}
				}`,
			},
		},
		graph: {
			content: {
				progress: 'Dein Missionsfortschritt',
				progressStatus: '{completed} von {all}',
			},
		},
		mockup: {
			button: {
				leave: 'Herauskommen',
				continue: 'Fortsetzen',
			},
			content: {
				'answer-status': {
					'wrong-answer': 'Du hast die Frage falsch beantwortet - versuche erneut',
					'correct-answer': 'Ordnungsgemäß erledigte Aufgabe',
					'try-again-in': 'Erneut versuchen in: ',
					gaining: 'Du erhältst',
				},
			},
		},
		'complete-phrase-question': {
			content: {
				'task-description': 'Deine Aufgabe ist es, die fehlenden Wörter in den Feldern zu ergänzen.',
			},
		},
		declaration: {
			content: {
				'task-description': 'Deine Aufgabe ist es, die folgenden Aktionen zu deklarieren.',
			},
		},
		'guess-phrase-question': {
			input: {
				'enter-answer': 'Gebe hier deine Antwort ein',
			},
			content: {
				'task-description': 'Deine Aufgabe ist es, die richtige Antwort einzugeben.',
			},
		},
		'retype-phrase-question': {
			content: {
				'task-description': 'Deine Aufgabe ist es, die angegebenen Wörter umzuschreiben.',
			},
		},
		'visit-link': {
			content: {
				'instruction-after': 'Die Aufgabe bestand darin, die Seite durchzusehen:',
				'instruction-before':
					'Deine Aufgabe ist, den Inhalt zu überprüfen. Klicke auf "Erledigen", um die Aufgabe abzuschließen. ',
			},
		},
		document: {
			button: {
				'download-pdf': 'PDF Herunterladen',
			},
		},
		'true-false': {
			button: {
				'true-claim': 'Richtig',
				'false-claim': 'Falsch',
			},
		},
		join: {
			button: {
				join: 'Beitreten',
			},
			'is-a-member-already': {
				description: 'Du hast schon einmal eine Wahl getroffen:',
			},
			'is-anonymous': {
				description: 'Du kannst diese Aufgabe nicht abschließen, weil:',
				title: 'du bist nicht eingeloggt!',
				'click-button': 'Klicke auf die Schaltfläche unten, um vollständig am Spiel teilzunehmen.',
				'button-text': 'Registrieren',
			},
			'join-task-crashed': {
				description: 'Ein Systemfehler ist aufgetreten!',
				title: 'Verwalter kontaktieren',
			},
			'team-join-view': {
				title: 'Wähle unten',
			},
			'user-container-join-view': {
				title: 'Wähle unten',
			},
		},
		'user-profile-fields': {
			instruction:
				'Deine Aufgabe ist es, alle Punkte zu erledigen. Klicke dann auf "Bestätigen", um die Aufgabe abzuschließen.',
		},
		common: {
			button: {
				continue: 'Fortsetzen',
				check: 'Prüfen',
				do: 'Erledigen',
				'do-again': 'Wiederholen',
				leave: 'Herauskommen',
				confirm: 'Bestätigen',
				help: 'Hilfe',
			},
			content: {
				'answer-status': {
					'remaining-static': 'Es ist geblieben: ',
					remaining: `Bis die Aufgabe abgeschlossen ist {
						seconds,
						plural,
						one {verbleibend}
						other {verbleibend}
					}`,
					'remaining-seconds': `{
						seconds,
						plural,
						one {Sekunde}
						other {Sekunden}
					}`,
					'already-completed': 'Diese Aufgabe ist bereits erledigt!',
					'wrong-answer-try-again': 'Du hast die Frage falsch beantwortet - versuche erneut',
					'wrong-answer': 'Leider falsche Antwort',
					'correct-answer': 'Ordnungsgemäß erledigte Aufgabe',
					'try-again-in': 'Bitte versuche erneut in: ',
					gaining: 'Du erhältst',
					crashed: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche bald noch einmal. ',
					awaiting: 'Die Aufgabe wird nach Genehmigung durch den Teammanager abgeschlossen.',
					rejected: 'Die Aufgabe wurde nicht abgeschlossen.',
					'rejected-reason': 'Zadanie nie zostało zaliczone. {reason}',
				},
				repetition: {
					'repetition-time-last': 'Die erneute Freigabe der Aufgabe wird in',
					'repetition-approval': 'Du kannst eine neue Aufgabe machen',
					'gained-points': 'Erzielte Punkte',
					'completion-time': 'Ausführungszeit',
					completion: 'Ausführung {times}',
					days: `{
						days,
						plural,
						one {Tag}
						other {Tage}
					}`,
				},
			},
		},
	},
	'user-profile': {
		field: {
			mapped: {
				forename: 'Vorname',
				surname: 'Nachname',
				nickname: 'Nickname',
				description: 'Beschreibung',
				email: 'E-mail',
				phone: 'Telefon',
			},
		},
	},
	'user-details': {
		button: {
			'mobile-mode': {
				edit: 'Speichern',
				preview: 'Bearbeiten',
			},
			mode: {
				edit: 'Änderungen speichern',
				preview: 'Profil bearbeiten',
			},
			edit: 'Bearbeiten',
			'choose-photo': 'Wähle ein Bild',
		},
		content: {
			'user-data': {
				position: 'Position:',
				'last-activity': 'Letzte Aktivität',
			},
			level: 'Niveau ',
			'remaining-signs': 'Zeichen übrig: ',
			'edit-in-progress': 'Profil bearbeiten',
			editField: {
				nickname: 'Nickname',
				description: 'Beschreibung',
			},
		},
		tabs: {
			informations: 'Information',
			notifications: 'Benachrichtigungen',
		},
		agreements: {
			title: 'Deine Zustimmungen',
			gdpr: {
				title: 'DSGVO',
				warning:
					'Wenn Sie Ihre Einwilligung widerrufen, werden wir Ihr Konto in der Anwendung löschen und Ihre persönlichen Daten, gesammelten Punkte und Fortschritte bei der Aufgabenerfüllung löschen. Ein gelöschtes Konto kann nicht wiederhergestellt werden. Sie können jedoch eine neue erstellen.',
			},
			required: 'Erforderlich',
			optional: 'Optional',
			confirm: 'Ich bestätige die Änderung der Zustimmung!',
			saveButton: 'Speichern',
			'accept-requirements': 'Du musst  Änderungen bestätigen',
			'your-agreements': 'Deine Zustimmungen',
		},
	},
	'team-details': {
		button: {
			'mobile-mode': {
				edit: 'Speichern',
				preview: 'Bearbeiten',
			},
			mode: {
				edit: 'Änderungen speichern',
				preview: 'Profil bearbeiten',
			},
			edit: 'Bearbeiten',
		},
		content: {
			'remaining-signs': 'Zeichen übrig: ',
			'edit-in-progress': 'Profil bearbeiten',
		},
		tabs: {
			members: 'Mitglieder',
			tasks: 'Aufgaben',
			settings: 'Die Einstellungen',
		},
		tasks: {
			status: {
				awaiting: '{task} abgeschlossen. Ist es wahr? Treffe deine Entscheidung! {br} am {date} ',
				accepted: 'hat die {task} {br} am {date} abgeschlossen',
				rejected: 'hat die Aufgabe {task} {br} am {date} nicht abgeschlossen',
			},
			button: {
				confirm: 'Bestätigen',
				reject: 'Ablehnen',
			},
			'rejection-modal': {
				title: 'Ablehnung der Aufgabe',
				'reason-placeholder': 'Ablehnungsgrund eingeben (optional)',
				button: 'Ablehnen',
			},
		},
	},
	'create-submission': {
		return: {
			or: 'Klicke ',
			link: 'hier',
			rest: ' um den Benachrichtigungstyp zu ändern!',
		},
		form: {
			error: {
				'too-short': 'Die Mindestanzahl an Zeichen beträgt: {value}',
				'too-long': 'Die maximale Anzahl von Zeichen beträgt: {value}',
				required: 'Dieses Feld  ist erforderlich!',
			},
		},
		confirmation: {
			title: 'Wettbewerbsarbeit hinzugefügt',
			button: 'Beenden',
			content: {
				'line-1': 'Dein Wettbewerbsarbeit wurde hinzugefügt und befindet sich in der Moderation.',
				'line-2': 'Wir werden dich über die Statusänderung informieren.',
			},
		},
		error: {
			title: 'Ein unerwarteter Fehler ist aufgetreten',
			content: 'Bitte versuche in ein Moment noch einmal.',
			button: 'Deine Wettbewerbsarbeit',
		},
		'image-submission': {
			title: 'Deine Wettbewerbsarbeit',
			form: {
				title: {
					placeholder: 'Titel eingeben',
				},
				file: 'Klicke hier, um ein Foto hinzuzufügen',
				button: 'Hinzufügen',
			},
		},
		'submission-type-selection': {
			title: 'Nehme an der Herausforderung teil',
			subtitle: 'Den Typ die Wettbewerbsarbeit auswählen:',
			type: {
				text: 'Text hinzufügen',
				image: 'Bild hinzufügen',
			},
		},
		'text-submission': {
			title: 'Deine Wettbewerbsarbeit',
			form: {
				title: {
					placeholder: 'Titel eingeben',
				},
				text: {
					placeholder: 'Text eingeben',
				},
				button: 'Hinzufügen',
			},
		},
	},
	'challenge-list': {
		title: 'Herausforderungen',
		subtitle: 'Beschreibung',
		loading: 'Liste der Herausforderungen wird geladen...',
		tabs: {
			open: 'Laufend',
			future: 'Eingehend',
			finished: 'Beendet',
			all: 'Alle',
		},
		content: {
			'empty-list': 'Keine Herausforderungen. Komm bald wieder! ;-) ',
			'challenge-status': {
				notStarted: 'Verbleibende Zeit zum Starten: {remainingTime}',
				inProgress: 'Während:',
				beforeResolve: 'Auf Ergebnisse warten',
				finished: 'Beendet',
			},
			'reverse-status': {
				notStarted: 'Wir fangen an in',
				inProgress: 'Ist geblieben',
				beforeResolve: 'Bekanntgabe der Ergebnisse in',
			},
			earnings: 'Zu bekommen',
		},
		button: {
			takePart: 'Teilnehmen',
		},
		error: {
			empty: 'Die Herausforderungsliste ist leer.',
		},
		type: {
			user: 'Individuell',
			team: 'Team',
			'container-team': 'Nach der Struktur - Team',
			'container-user': 'Nach der Struktur - individuell',
		},
	},
	'challenge-details': {
		creative: {
			type: {
				image: 'Bild',
				text: 'Text',
				video: 'Film',
				gif: 'Gif',
			},
			'section-title': {
				'challenge-info': 'Einzelheiten:',
			},
			button: {
				'take-part': 'Teilnehmen',
				'add-submission-in': 'Weitere Wettbeverbsarbeit hinzufügen in',
				'limit-reached': 'Dein Limit von Wettbewerbsarbeiten wurde erreicht',
				'only-for-manager': 'Nur für den Teammanager',
			},
			content: {
				'challenge-info': {
					'challenge-type': 'Herausforderungstyp',
					'creative-type': 'Kreativ',
					'submission-type': 'Art der Wettbewerbsarbeit',
					'submission-types': {
						video: 'Film',
						image: 'Bild',
						text: 'Text',
						gif: 'Gif',
					},
					'participation-mode': 'Teilnahmeart',
					'submissions-limit': 'Wettbewerbsarbeitlimit',
					'points-for-added-submission': 'Punkte für Wettbewerbsarbeit',
					'points-for-accepted-submission': 'Punkte für akzeptierte Wettbewerbsarbeit',
				},
				'not-selected-winners': 'Die Herausforderung ist noch nicht gelöst',
				remains: 'Ist geblieben',
			},
			'menu-tabs': {
				details: 'Einzelheiten',
				'my-submissions': 'Meine',
				'recently-added-submissions': 'Zuletzt hinzugefügt',
				ranking: 'Rangfolge',
				prizes: 'Auszeichnungen',
				winners: 'Gewinner',
			},
			'submission-details': {
				author: 'Autor:',
				date: 'Datum:',
			},
			'submission-list': {
				error: {
					empty: {
						user: 'Du hast noch nichts hinzugefügt. Ändere es, klicke auf "Teilnehmen"! ;-)',
						common: 'Genehmigte Einreichungen werden hier angezeigt.',
					},
					'publication-at': {
						'line-1': 'Hinzugefügte Wettbeverbsarbeit werden am {date} veröffentlicht.',
						'line-2':
							'Lasse dich nicht zurück, füge jetzt deine Wettbeverbsarbeit hinzu - klicke auf "Teilnehmen"!',
					},
				},
				content: {
					'submission-status': {
						rejected: 'Abgelehnt',
						accepted: 'Akzeptiert',
						new: 'Warten auf Moderation',
						'reject-reason': 'Ablehnungsgrund: {reason}',
					},
					remains: 'Ist geblieben: {remaining-time}',
					removed:
						'Benutzer hat das Spiel verlassen und die Zustimmung zur Veröffentlichung des Inhalts zurückgezogen',
				},
				button: {
					'remove-submission': 'Diese Wettbeverbsarbeit löschen',
				},
			},
		},
		kpi: {
			'section-title': {
				'challenge-info': 'Einzelheiten',
			},
			button: {
				'take-part': 'Teilnehmen',
			},
			content: {
				'challenge-info': {
					'challenge-type': 'Herausforderungstyp',
					'kpi-type': 'KPI',
					'submission-type': 'Art die Wettbewerbsarbeit',
					'participation-mode': 'Teilnahmeart',
					'submissions-limit': 'Limit der Wettbewerbsarbeiten',
					'last-import-date': 'Letzte Aktualisierung',
				},
				remains: 'Ist geblieben: {remaining-time}',
			},
			'menu-tabs': {
				ranking: 'Rangfolge',
				details: 'Einzelheiten',
				prizes: 'Einzelheiten',
			},
		},
		target: {
			'section-title': {
				'challenge-info': 'Einzelheiten',
			},
			content: {
				'challenge-info': {
					'challenge-type': 'Herausforderungstyp',
					'creative-type': 'Ziel',
					'participation-mode': 'Teilnahmeart',
					'submission-count': 'Abgeschlossen',
					'submissions-limit': 'Limit',
					'points-for-accepted-submission': 'Punkte für die Wettbeverbsarbeit',
					completed: 'Abgeschlossen',
				},
			},
			'menu-tabs': {
				details: 'Einzelheiten',
			},
		},
		common: {
			ranked: {
				place: 'Ort {placeFrom}',
				'place-range': 'Orte von {placeFrom} bis {placeTo}',
			},
			prizes: {
				'no-prize-set': 'Keine Belohnungen.',
				points: 'Punkten',
			},
		},
	},
	cafeteria: {
		title: 'Preiscafeteria ',
		error: {
			empty: 'Die Preiscafeteria ist leer.',
			'internal-server-error': 'Beim Anzeigen der Cafeteria ist ein Fehler aufgetreten.',
			'access-denied': 'Du hast  keinen Zugriff auf Cafeteria.',
			'disabled-in-configuration': 'Preiscafeteria ist deaktiviert.',
			'no-budget': 'Entschuldigung, die Auszeichnung ist nicht mehr verfügbar.',
			'too-few-points': 'Nicht genügend Punkte. Kauf nicht möglich. ',
			'invalid-input': 'Das Formular enthält Fehler.',
			'item-sold-out': 'Auszeichnung ausverkauft.',
		},
		filter: {
			price: 'Preis',
			afford: 'Zeig mir die Auszeichnungen, die ich mir leisten kann.',
			from: 'von',
			to: 'bis',
		},
		item: {
			'buy-now': 'Jetzt kaufen',
			'not-available': 'Nicht verfügbar',
			missing: 'Dir fehlt',
			price: 'Preis',
		},
	},
	notification: {
		static: {
			messages: {
				submission: {
					new:
						'Du hast die Wettbeverbsarbeit "{submissionTitle}" zur Herausforderung "{challengeTitle}" hinzugefügt',
					rejected:
						'Deine Wettbeverbsarbeit  "{submissionTitle}" in der Herausforderung "{challengeTitle}" wurde aus folgenden Gründen abgelehnt: {reason}',
					'rejected-without-reason':
						'Deine Wettbeverbsarbeit "{submissionTitle}" in der Herausforderung "{challengeTitle}" wurde abgelehnt',
					accepted:
						'Deine Wettbeverbsarbeit "{submissionTitle}" in der Herausforderung "{challengeTitle}" wurde angenommen',
					awardedPlace:
						'Du hast eine Auszeichnung für das Erreichen von {awardedPlace} in der Herausforderung gewonnen',
					addedSubmission: 'Dodano zgłoszenie "{submissionTitle}" w wyzwaniu "{challengeTitle}".',
				},
				'declaration-task': {
					accepted: 'Deine Wettbeverbsarbeit für die Aufgabe "{taskName}" wurde akzeptiert',
					'rejected-reason': 'Deine Wettbeverbsarbeit für die Aufgabe "{taskName}" wurde abgelehnt ',
					rejected: 'Deine Wettbeverbsarbeit für die Aufgabe "{taskName}" wurde abgelehnt ',
				},
				email: {
					'remember-pass': 'E-Mail senden: Passwort zurücksetzen',
					'reset-pass': 'E-Mail senden: Passwort zurücksetzen',
					registration: 'E-Mail senden: Wettbeverbsarbeit wurde abgelehnt',
					'submission-rejected': 'E-Mail senden: Wettbeverbsarbeit wurde abgelehnt',
					'submission-accepted': 'E-Mail senden: Wettbeverbsarbeit wurde angenommen',
				},
				bonusTransaction: 'Änderung des Punktestatus',
				task: '{name}',
				item: '{name}',
				levelUp: {
					withName: 'Niveau {levelNumber} - {name}',
					withoutName: 'Niveau {levelNumber}',
				},
				achievement: '{name}',
				challenge: {
					target: '{name}',
				},
			},
			dates: {
				task: {
					'task-completed': 'Aufgabe abgeschlossen: {date}',
					'mission-completed': 'Mission abgeschlossen: {date}',
					'task-progress': 'Weitere Fortschritte wurden erzielt: {date}',
				},
				achievement: 'Abzeichen wurde erhalten:{date}',
				item: 'Artikel wurde gekauft:{date}',
				email: 'E-Mail erhalten: {date}',
				submission: {
					new: 'Wettbeverbsarbeit hinzugefügt: {date}',
					accepted: 'Wettbeverbsarbeit angenommen: {date}',
					rejected: 'Wettbeverbsarbeit abgelehnt: {date}',
					awardedPlace: 'Platz vergeben: {date}',
				},
				'declaration-task': {
					accepted: 'Wettbeverbsarbeit angenommen: {date}',
					rejected: 'Wettbeverbsarbeit abgelehnt: {date}',
				},
				bonusTransaction: 'Umgerechnete Punkte: {date}',
				challenge: {
					target: 'Fertig: {date}',
				},
			},
			'null-states': {
				task: '[Aufgabe gelöscht]',
				achievement: '[Abzeichen gelöscht]',
				item: '[Element entfernt]',
				submission: '[Gelöscht]',
				awardedPlace: '[Gelöscht]',
			},
		},
		'real-time': {
			achievement: {
				title: 'Herzliche Glückwünsche!',
				subtitle: 'Du hast ein Abzeichen bekommen',
				button: {
					default: 'Empfangen',
					'without-earnings': 'Fortsetzen',
				},
				'achievement-unlocked': 'Abzeichen freigeschaltet!',
				'earn-achievement': 'Du bekommst eine Abzeichen',
				earn: 'Du gewinnst',
			},
			challengeSubmissionStateChanged: {
				title: 'Herzliche Glückwünsche!',
				subtitle: 'Deine Wettbeverbsarbeit hat sich geändert in der Herausforderung',
				button: {
					default: 'Fortsetzen',
					'without-earnings': 'Fortsetzen',
				},
				earn: 'Du gewinnst',
			},
			declarationTaskAcceptanceChanged: {
				title: 'Herzliche Glückwünsche!',
				subtitle: 'Dein Aufgabenstatus hat sich geändert',
				button: {
					default: 'Fortsetzen',
					task: 'Überprüfe warum',
				},
				earn: 'Du gewinnst',
			},
			task: {
				title: 'Herzliche Glückwünsche!',
				subtitle: 'Du hast die Aufgabe abgeschlossen',
				button: {
					default: 'Punkte erhalten',
					'without-earnings': 'Fortsetzen',
					nextTask: 'Fortsetzen',
				},
				congratulations: 'Herzliche Glückwünsche!',
				'for-execution': 'Für die Erledigung der Aufgabe erhälst Du:',
			},
			levelUp: {
				title: 'Herzliche Glückwünsche!',
				subtitle: 'Osiągnąłeś nowy poziom',
				button: {
					default: 'Fortsetzen',
					'without-earnings': 'Fortsetzen',
				},
				earn: 'Du gewinnst',
			},
			mission: {
				title: 'Herzliche Glückwünsche!',
				subtitle: 'Mission erfüllt',
				button: {
					default: 'Empfangen',
					'without-earnings': 'Fortsetzen',
					nextTask: 'Fortsetzen',
				},
				congratulations: 'Herzliche Glückwünsche!',
				'for-execution': 'Für die Erledigung der Mission erhälst Du:',
				earn: 'Für die Erledigung der Mission erhälst Du:',
			},
		},
	},
	currency: {
		bucks: `{
			bucks,
			plural,
			one {Buck}
			other {Bucks}
		}`,
		exp: `{
			exp,
			plural,
			one {Punkt}
			other {Punkte}
		} Erfahrungen`,
	},
	graph: {
		tooltip: {
			mission: {
				tasks: 'Aufgaben',
				progress: '{completed} von {all}',
			},
			button: {
				repeat: 'Wiederholen',
				execute: 'Erledigen',
			},
		},
	},
	loader: {
		text: ['Anwendung wird geladen...'],
	},
	time: {
		days: `{
			time,
			plural,
			one {# Tag}
			other {# Tage}
		}`,
		hours: `{
			time,
			plural,
			one {# Stunde}
			other {# Stunden}
		}`,
		minutes: `{
			time,
			plural,
			one {# Minute}
			other {# Minuten}
		}`,
		seconds: `{
			time,
			plural,
			one {# Sekunde}
			other {# Sekunden}
		}`,
	},
	markdown: {
		'show-more': '(Mehr zeigen)',
		'show-less': '(ausblenden)',
	},
	'terms-and-conditions': {
		'terms-and-conditions': 'Vorschriften',
		'privacy-policy': 'Datenschutz-Bestimmungen',
		'cookie-policy': 'Cookie-Richtlinie',
	},
	'contact-list': {
		title: 'Kontaktbuch',
		search: {
			placeholder: 'Suchen...',
		},
		tab: {
			all: 'Alle',
			active: 'Aktiv',
		},
		error: {
			empty: 'Keine Ergebnisse.',
			'internal-server-error': 'Beim Anzeigen des Kontaktbuchs ist ein Fehler aufgetreten.',
			'access-denied': 'Du hast keinen Zugriff auf das Kontaktbuch.',
			'disabled-in-configuration': 'Kontaktbuch ist deaktiviert.',
		},
		pagination: {
			of: 'von',
		},
	},
	// clear translations:
	app: {
		installation: {
			title: 'Installiere die Anwendung auf dem Gerät',
			subtitle: 'Füge die App zum Startbildschirm hinzu, {separator}, um schnell und einfach darauf zuzugreifen.',
			button: 'Zum Installieren anklicken',
		},
	},
	// reusable components translations:
	common: {
		'go-back': 'Rückkehr',
		form: {
			block: {
				fileUpload: {
					placeholder: 'Eine Datei wählen…',
					chooseButton: 'Eine Datei wählen',
					deleteButton: 'Eine Datei wählen',
					processing: 'Verarbeitung...',
					addAnotherFile: 'Weitere Datei hinzufügen',
				},
				dropdownDefault: ' Antwort wählen...',
			},
		},
		view: {
			list: {
				search: {
					placeholder: 'Suchen...',
				},
			},
		},
	},
	component: {
		form: {
			'setting-field': {
				'click-here': 'Hier klicken',
				'click-here-to-set': 'Klicke hier, um hinzufügen…',
			},
		},
	},
	form: {
		error: {
			required: 'Dieses Feld ist erforderlich.',
			'check-required': 'Die Deklaration ist obligatorisch.',
			'too-short': 'Nicht genügend Zeichen (min. {min} Zeichen).',
			'too-long': 'Zu viele Zeichen (max. {max} Zeichen).',
			'invalid-email': 'E-mail Adresse ist nicht korrekt.',
			'invalid-url': 'URL ist ungültig.',
			'invalid-postal-code': 'Postleitzahl falsch.',
			'invalid-mobile-phone': 'Telefonnummer falsch.',
			'min-elements': 'Nicht genügend Antworten (min. {min}).',
		},
	},
	portal: {
		title: 'Hallo {name}, hier ist dein Plan',
		process: {
			placeholder: 'Wow, so sieht es also hinter all diesen Aufgaben aus. Cool!',
			title: {
				duration: `Verbleibender Prozess: <span class="processList__text--highlighted">{
						duration,
						plural,
						one {#&nbsp;tag}
						other {#&nbsp;tage}
					}</span>.&nbsp;`,
				overdue:
					'<span class="processList__text--highlighted">Aufgaben überfällig! Vervollständigen Sie sie so schnell wie möglich.</span>&nbsp;',
				'overdue-for-manager':
					'<span class="processList__text--highlighted">Die Frist für den Abschluss des Verfahrens ist bereits abgelaufen.</span>&nbsp;',
				date: {
					'first-day': `Tag 1 übrig: <span class="processList__text--highlighted">{
						time,
						plural,
						one {#&nbsp;Tag}
						other {#&nbsp;Tage}
					}</span>.`,
					'key-date': `Noch <span class="processList__text--highlighted">{
						time,
						plural,
						one {#&nbsp;Tag}
						other {#&nbsp;Tage}
					}</span> bis zum Prozessstart.`,
					'last-day': `Bis zum letzten Tag: <span class="processList__text--highlighted">{
						time,
						plural,
						one {#&nbsp;Tag}
						other {#&nbsp;Tage}
					}</span>.`,
				},
			},
			report: {
				downloadReport: 'Bericht herunterladen',
				button: {
					processReport: 'Prozesse',
					processActionsReport: 'Aktionen',
				},
			},
			filter: 'Filter',
			'filter-all': 'Alle',
		},
		action: {
			type: {
				task: 'Aufgabe',
				mission: 'Mission',
				form: 'Formular',
			},
			deadline: {
				general: '{time} bis {type}',
				overdue: 'Überfällig: {time}',
				'solidsnake-task': 'complet',
				'solidsnake-mission': 'complet',
				'solidsnake-form': 'complet',
				form: 'complet',
				story: 'complet',
				page: 'complet',
				'knowledge-check': 'complet',
			},
			placeholder: {
				messages: {
					1: 'Aufgabenliste gelöscht, alles für heute erledigt!',
					2: 'Houston, wir haben keine Aufgaben mehr! Machen wir Schluss.',
					3: 'Neue Aufgaben stehen bevor, also schwimm einfach weiter!',
					4: 'Wenn Aufgaben wie Wolken sind, ist heute ein sonniger Tag! Nur zu, nutzen Sie es!',
					5: 'Abra cadabra! Und all diese Aufgaben sind verschwunden! Keine Sorge, es werden noch mehr!',
					6: 'Hier gibt es keine weiteren Aufgaben. Aber wir haben einen coolen Kaktus. Schau es dir an, es ist so cool!',
					7: 'Kein Grund, tiefer zu graben, hier gibt es vorerst keine Aufgaben.',
				},
				nextAction: 'Erwarten Sie weitere Aufgaben <span class="color-blue font-weight-normal">{date}</span>',
				nextActionUnknownDate: 'Erwarten Sie bald weitere Aufgaben!',
			},
			'assign-role': {
				placeholder: 'Rolle zuweisen {roleName}',
				confirm: 'Bestätigen',
			},
		},
		tasks: {
			title: 'Dein Plan.',
		},
		contacts: {
			title: 'Kontakte',
			roleTitle: 'Rolle',
			role: {
				manager: 'Manager',
			},
		},
		'knowledge-base': {
			title: 'Knowledge base',
		},
		employees: {
			yours: 'Dein Team',
		},
	},
};
