import CSSTransformAdapter from 'services/application/CSSTransform/adapters/CSSTransformAdapter';
import chroma from 'chroma-js';
import { includes } from 'lodash';

export default class CSSColorModTransformAdapter extends CSSTransformAdapter {
	transform(content, variables) {
		const {
			COLOR_MOD_REGEXP: regexp,
			ALLOWED_METHODS: methods,
			CHROMA_METHODS: chromaMethods,
		} = CSSColorModTransformAdapter;

		return content.replace(regexp, (match, method, color, funcName, args) => {
			color = this._transformColor(variables, color);

			if (methods[method] && includes(methods[method], funcName)) {
				return chroma(color)
					[chromaMethods[funcName]](args) // eslint-disable-line no-unexpected-multiline
					.css();
			} else {
				return match;
			}
		});
	}

	_transformColor(variables, color) {
		const { VARIABLE_REGEXP: regexp } = CSSColorModTransformAdapter;

		// if color is CSS variable
		if (regexp.test(color)) {
			// slice(2), e.g. --variable--name => variable--name
			return variables[color.match(regexp)[1].slice(2)];
		} else {
			return color;
		}
	}

	static COLOR_MOD_REGEXP = /([\w-]+)\(((?:#\w{3,6})|(?:var|rgba?)+\([^)]+\)) (?:(\w+)\(([^)]+)\))\)/g;
	static VARIABLE_REGEXP = /var\(([^)]+)\)/;
	// Allowed for usage methods from chroma library
	static ALLOWED_METHODS = {
		'color-mod': ['shade', 'brighten'],
	};
	static CHROMA_METHODS = {
		shade: 'darken',
		brighten: 'brighten',
	};
}
