import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = (() => {
	return createUseStyles({
		container: {
			width: '100%',
			whiteSpace: 'nowrap',
			overflowX: 'scroll',
			overflowY: 'hidden',
			'-ms-overflow-style': 'none',
			'scrollbar-width': 'none',

			'&::-webkit-scrollbar': {
				display: 'none',
			},
		},
		text: {
			display: 'inline-flex',
			alignItems: 'center',
		},
	});
})();

export const AutoScroll = ({ children }) => {
	const delay = 500;
	const classes = useStyles();

	const requestRef = React.createRef();
	const containerRef = React.createRef();
	const textRef = React.createRef();

	const scroll = useCallback(() => {
		if (!textRef.current || !containerRef.current) {
			cancelAnimationFrame(requestRef.current);

			return;
		}

		const scrollWidth = textRef.current.offsetWidth - containerRef.current.offsetWidth;

		if (containerRef.current.scrollLeft >= scrollWidth) {
			cancelAnimationFrame(requestRef.current);

			return;
		}

		containerRef.current.scrollLeft += 1;

		requestRef.current = requestAnimationFrame(scroll);
	}, [requestRef, containerRef, textRef]);

	React.useEffect(() => {
		setTimeout(() => {
			requestRef.current = requestAnimationFrame(scroll);
		}, delay);

		return () => cancelAnimationFrame(requestRef.current);
	}, [requestRef, scroll]);

	return (
		<div className={classes.container} onClick={scroll} ref={containerRef}>
			<div className={classes.text} ref={textRef}>
				{children}
			</div>
		</div>
	);
};
