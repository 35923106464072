import adapters from 'services/trackers/adapters';
import AuthenticationTrackerService from 'services/trackers/AuthenticationTrackerService';
import TrackerService from 'services/trackers/TrackerService';

export default {
	...adapters,
	'services.authenticationTrackerService': {
		class: AuthenticationTrackerService,
		deps: [
			{ $ref: 'services.trackerService' },
			{ $ref: 'services.accessTokenService' },
			{ $ref: 'services.userService' },
		],
	},
	'services.trackerService': {
		class: TrackerService,
		deps: [{ $ref: 'services.applicationService' }],
		calls: [
			{
				name: 'registerAdapter',
				args: ['GoogleAnalytics', { $ref: 'services.trackers.adapters.googleAnalytics' }],
			},
			{
				name: 'registerAdapter',
				args: ['GoogleTagManager', { $ref: 'services.trackers.adapters.googleTagManager' }],
			},
		],
	},
};
