import TaskBaseService from 'services/task/TaskBaseService';

import ImageMultipleChoiceQuestionTask from 'models/task/question/questionTask/choice/image/ImageMultipleChoiceQuestionTask';

export default class ImageMultipleChoiceQuestionTaskService extends TaskBaseService {
	toModel(task) {
		return new ImageMultipleChoiceQuestionTask({
			...task,
			state: this._mapState(task),
		});
	}
}
