import React, { Dispatch, SetStateAction } from 'react';
import { Form } from 'formik';
import { FormikProps } from 'formik/dist/types';
import { injectIntl, FormattedMessage, InjectedIntlProps } from 'react-intl';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

import Agreements from 'components/_/app/application/agreements/Agreements';
import Agreement from 'models/application/config/Agreement';
import { Fields } from './Token';

export type TokenTemplateProps = InjectedIntlProps &
	Omit<FormikProps<Fields>, 'touched' | 'setTouched'> & {
		agreements: Agreement[];
		touched: boolean[];
		setTouched: Dispatch<SetStateAction<boolean[]>>;
	};

export default injectIntl(
	({ intl, agreements, values, status, errors, touched, setTouched, ...props }: TokenTemplateProps) => {
		const { isSubmitting, isValid, submitCount } = props;

		return (
			<div className="integration">
				<Form>
					<FormattedMessage id="auth.register.integration.token.title" tagName="h2" />
					{!isEmpty(agreements) && (
						<div className="agreements pb-3 pt-2">
							<Agreements<Fields>
								values={values.agreements}
								agreements={agreements}
								errors={errors.agreements}
								{...props}
								touched={touched}
								setTouched={setTouched}
							/>
							{status === 'agreements-error' && (
								<div className="error mb-0 pt-2">
									<FormattedMessage id="auth.form.error.agreements-required" />
								</div>
							)}
						</div>
					)}
					<button
						className={classnames('btn__basic btn__basic--fullWidth', {
							'btn__basic--disabled': !isValid && submitCount,
							loading: isSubmitting,
						})}
						type="submit"
					>
						<FormattedMessage id="auth.register.integration.token.submit" />
					</button>
				</Form>
			</div>
		);
	}
);
