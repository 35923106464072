import React, { PureComponent } from 'react';
import guard from 'when/guard';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { Markdown } from 'components/_/common/text/markdown/Markdown';

export default function(taskService, Layout, MatchingImageType) {
	return class DeclarationTask extends PureComponent {
		_debouncedSubmit = guard(guard.n(1), finish =>
			taskService.processTask(this.props.task, this._prepareRequest(finish))
		);

		render() {
			const { task, goBack, inProcess } = this.props;

			return (
				<Layout
					task={task}
					actions={{
						submit: {
							handler: () => this.props.process(this._prepareRequest(true)),
							buttonText: 'task-details.common.button.confirm',
						},
						resetTask: () => this.props.process({ reset: true }).then(() => this._tryAgain()),
						goBack,
						tryAgain: () => this._tryAgain(),
						isSubmitting: inProcess,
					}}
				>
					<div className="wrapper--col overflow--auto declarationTask el--grow">
						<div className="standardText fs-4 standardText--noMargin taskInstructions el--shrink">
							<FormattedMessage id="task-details.declaration.content.task-description" />
						</div>
						{task.image() && (
							<MatchingImageType classes="rounded visible--mobile el--shrink" src={task.image()} />
						)}
						<div className="el--shrink">
							<div className="fs-6 standardText declarationTask__textWrapper wrapper--col justify--center">
								<Markdown>{task.description()}</Markdown>
							</div>
						</div>
						<div
							className={classnames(
								'declarationTask__contentWrapper wrapper--row el--shrink justify--center',
								{ hasImage: task.image() }
							)}
						>
							{task.image() && <MatchingImageType classes="rounded hidden--mobile" src={task.image()} />}
							<div className="declarationTask__checkboxListWrapper el--grow">
								<div className="declarationTask__checkboxList">
									<ul className="wrapper--col wrapper--row--wrap align--top answerList el--grow">
										{task.declarations().map(declaration => (
											<li
												key={declaration.id()}
												className={classnames('answer answer__textType wrapper--row', {
													correct: task.state().hasStatus('completed') || task.isCompleted(),
													incorrect: task.isFailed(),
												})}
											>
												<label className="wrapper--row el--grow">
													<input
														type="checkbox"
														className="wrapper--row el--grow technicalInput"
														name="declaration"
														checked={declaration.isChecked()}
														onChange={() => this._click(declaration)}
														disabled={
															task.isSubmitted() ||
															(task.acceptanceRequired() &&
																task.state().currentRepetitionState() &&
																!['open', 'accepted'].includes(
																	task
																		.state()
																		.currentRepetitionState()
																		.acceptanceState()
																))
														}
													/>
													<div className="answer__selectArea wrapper--row align--center justify--center el--shrink">
														<div className="customInput">
															<div className="checked" />
														</div>
													</div>
													<div className="answer__contentArea wrapper--row el--grow align--middle">
														<div className="standardText standardText--noMargin wrapper--col justify--center el--grow">
															{declaration.body()}
														</div>
													</div>
												</label>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</Layout>
			);
		}

		_click(declaration) {
			declaration.toggleCheck();
			this.forceUpdate();

			return this._debouncedSubmit(false);
		}

		_prepareRequest(finish) {
			return {
				declarations: this.props.task.declarations().map(declaration => ({
					id: declaration.id(),
					declared: declaration.isChecked(),
				})),
				finish,
			};
		}

		_tryAgain() {
			this.props.task.reset();
			this.forceUpdate();
		}
	};
}
