import MenuService from 'services/application/menu/MenuService';
import menuPositionServices from 'services/application/menu/position';

export default {
	...menuPositionServices,
	'services.menuService': {
		class: MenuService,
		deps: [{ $ref: 'services.menuPositionService' }],
	},
};
