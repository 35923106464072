import TagService from 'services/tag/TagService';

import subServices from 'services/tag/subServices';

export default {
	'services.tagService': {
		class: TagService,
		calls: [
			{
				name: 'registerSubService',
				args: ['item', { $ref: 'services.itemTagService' }],
			},
			{
				name: 'registerSubService',
				args: ['news', { $ref: 'services.newsTagService' }],
			},
			{
				name: 'registerSubService',
				args: ['achievement', { $ref: 'services.achievementTagService' }],
			},
		],
	},
	...subServices,
};
