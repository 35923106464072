import _, { filter } from 'lodash';

import Question from 'models/task/question/Question';
import answerTypes from 'models/task/question/answer';

/**
 * Class representing a choose question.
 */
export default class ChooseQuestion extends Question {
	/**
	 * @param {Object} question
	 */
	constructor(question = {}) {
		super(question);

		/**
		 * @type {Answer[]}
		 */
		this._answers = (question.answers || []).map(
			(answer, index) => new answerTypes[answer.type](answer, this._prepareAlphabeticalIndex(index))
		);
	}

	answers() {
		return this._answers;
	}

	/**
	 *
	 * @returns {Answer[]}
	 */
	getCheckedAnswers() {
		return filter(this._answers, answer => answer.isChecked());
	}

	/**
	 * @returns {boolean}
	 */
	hasCheckedAnswers() {
		return this.getCheckedAnswers().length > 0;
	}

	resetAnswers() {
		_.each(this._answers, answer => answer.uncheck());
	}

	_prepareAlphabeticalIndex(index) {
		return String.fromCharCode(index + 'A'.charCodeAt());
	}
}
