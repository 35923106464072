import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import images from 'assets/images';

import UserProfileDetails from './details/Details';

import Notifications from 'components/notification/Notifications';

import View, { Content, Header } from 'components/_/common/view/item/ItemView';

import { NavTabList } from 'components/commons/_/tabs/NavTabList';

export default ({
	agreementsPopup,
	canEdit,
	fields,
	userProfile,
	membership,
	level,
	wallet,
	exp,
	params,
	Currencies,
	UserAgreements,
	Avatar,
	avatarFieldDefinition,
	onFieldChange,
	onAvatarChange,
	onCloseAgreementsPopup,
	openAgreementsPopup,
	hasAgreements,
}) => (
	<View>
		{() => (
			<Fragment>
				<Header title={userProfile.name()} subtitle={userProfile.nickname()}>
					{hasAgreements && canEdit && (
						<Fragment>
							<div className="d-flex align-items-center" onClick={openAgreementsPopup}>
								<p className="your-agreements mr-2">
									<FormattedMessage id="user-details.agreements.your-agreements" />
								</p>
								<button className="user-agreements-button">
									<i className="fa fa-check-square-o" />
								</button>
							</div>
							{agreementsPopup && <UserAgreements close={onCloseAgreementsPopup} />}
						</Fragment>
					)}
				</Header>
				<Content>
					<section
						className={classnames('user-profile d-flex flex-grow-1 flex-column flex-md-row w-100 h-100', {
							editable: canEdit,
						})}
					>
						<section className="summary flex-shrink-0 p-4 pl-md-4 pr-md-0">
							<div className="membership d-flex mb-4 justify-content-between">
								<Avatar
									name="avatar"
									currentImage={userProfile.avatar()}
									defaultImage={images.default.avatar.user}
									editMode={
										canEdit &&
										(avatarFieldDefinition ? avatarFieldDefinition.userEditable() : false)
									}
									onChange={onAvatarChange}
								/>
								<div>
									<div className="d-flex flex-column align-items-end">
										{membership && (
											<NavLink
												to={`/team/${membership.team().id()}/members`}
												className="d-flex flex-column align-items-end"
											>
												<div
													className="team-avatar rounded-circle mb-2"
													style={{
														backgroundImage: `url(${membership.team().avatar() ||
															images.default.avatar.team})`,
													}}
												/>
												<div className="team-name mt-1 mb-2 text-right">
													{membership.team().name()}
												</div>
											</NavLink>
										)}
									</div>
									<div className="text-right">
										{userProfile.name() && (
											<div className="user-name mb-1">{userProfile.name()}</div>
										)}
										{userProfile.nickname() && (
											<div className="user-nickname">@{userProfile.nickname()}</div>
										)}
									</div>
								</div>
							</div>
							{level.current && (
								<div className="level mb-4 py-3 px-4 rounded text-center">
									{level.current && (
										<h5 className="mb-0">
											<FormattedMessage id="user-details.content.level" />{' '}
											{level.current.levelNumber()}
											{level.current.name() && `, ${level.current.name()}`}
										</h5>
									)}
									{level.next && (
										<div className="progressBar mt-2">
											<div
												className="progressBar__content"
												style={{
													width: `${level.progress.percentage}%`,
												}}
											/>
										</div>
									)}
									{level.progress.percentage < 100 && (
										<small className="mt-2">
											{`${level.progress.current} / ${level.progress.max}`}{' '}
											{exp ? exp.name() || exp.codeName() : null}
										</small>
									)}
								</div>
							)}
							{wallet && !isEmpty(wallet) && (
								<div className="points">
									<Currencies parentClasses="d-flex justify-content-center" currencies={wallet} />
								</div>
							)}
						</section>
						<section className="info flex-grow-1 ml-0 ml-md-6 position-relative">
							<NavTabList
								className="px-3 mt-3 mb-sm-3"
								url={'/team/${id}/${tab}'} // eslint-disable-line no-template-curly-in-string
								list={[
									fields && {
										to: userProfile.id() ? `/user/${userProfile.id()}/details` : '/user/settings',
										content: <FormattedMessage id="user-details.tabs.informations" />,
									},
									{
										to: userProfile.id()
											? `/user/${userProfile.id()}/notifications`
											: '/user/notifications',
										content: <FormattedMessage id="user-details.tabs.notifications" />,
									},
								]}
							/>
							{params.tab === 'details' && fields && (
								<div className="px-3 px-sm-0 py-3 py-sm-0">
									<UserProfileDetails
										fields={fields}
										userProfile={userProfile}
										onFieldChange={onFieldChange}
										editable={canEdit}
									/>
								</div>
							)}
							{params.tab === 'notifications' && (
								<div className="px-3 py-3 py-sm-0">
									<Notifications id={params.id} />
								</div>
							)}
						</section>
					</section>
				</Content>
			</Fragment>
		)}
	</View>
);
