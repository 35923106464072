import Notification from 'models/notification/Notification';

export default class TargetChallengeCalculatedNotification extends Notification {
	constructor(notification) {
		super(notification);

		this._challenge = notification.challenge;
		this._transactionLink = notification.target_challenge_transaction_link;
		this._transaction = notification.transaction;
		this._earnings = notification.transaction.amounts();
		this._type = 'targetChallengeCalculated';
	}

	getMessage() {
		const progress = `${this._transactionLink.progressCount()}/${this._challenge.submissionLimit()}`;

		return super.getMessage('messages.challenge.target', {
			name: `${this._challenge.title()} ${progress}`,
		});
	}

	getDate(params) {
		return super.getDate('dates.challenge.target', params);
	}
}
