import React from 'react';
import { injectIntl } from 'react-intl';

import Info from '../../../../Info';

export default injectIntl(({ intl, isValid, children }) =>
	isValid ? (
		children
	) : (
		<Info
			title={intl.formatMessage({
				id: 'auth.reset-password.action.verify-token.title',
			})}
			message={intl.formatMessage({
				id: 'auth.reset-password.action.verify-token.message',
			})}
		/>
	)
);
