import avatar from 'assets/images/icons/sectionIcons/Avatar.png';
import bell from 'assets/images/icons/sectionIcons/Bell.png';
import chart from 'assets/images/icons/sectionIcons/Chart.png';
import doc from 'assets/images/icons/sectionIcons/Doc.png';
import done1 from 'assets/images/icons/sectionIcons/Done-1.png';
import done2 from 'assets/images/icons/sectionIcons/Done-2.png';
import done3 from 'assets/images/icons/sectionIcons/Done-3.png';
import done4 from 'assets/images/icons/sectionIcons/Done-4.png';
import done5 from 'assets/images/icons/sectionIcons/Done-5.png';
import done6 from 'assets/images/icons/sectionIcons/Done-6.png';
import envelope from 'assets/images/icons/sectionIcons/Envelope.png';
import folderLocker from 'assets/images/icons/sectionIcons/Folder-locker.png';
import folderUser from 'assets/images/icons/sectionIcons/Folder-user.png';
import folder from 'assets/images/icons/sectionIcons/Folder.png';
import heart from 'assets/images/icons/sectionIcons/Heart.png';
import hello from 'assets/images/icons/sectionIcons/Hello.png';
import locker from 'assets/images/icons/sectionIcons/Locker.png';
import mapPin from 'assets/images/icons/sectionIcons/Map-pin.png';
import photo from 'assets/images/icons/sectionIcons/Photo.png';
import picture from 'assets/images/icons/sectionIcons/Picture.png';
import roadPin from 'assets/images/icons/sectionIcons/Road-pin.png';
import settings from 'assets/images/icons/sectionIcons/Settings.png';
import settings1 from 'assets/images/icons/sectionIcons/Settings-1.png';
import settings2 from 'assets/images/icons/sectionIcons/Settings-2.png';
import settings3 from 'assets/images/icons/sectionIcons/Settings-3.png';
import settings4 from 'assets/images/icons/sectionIcons/Settings-4.png';
import star from 'assets/images/icons/sectionIcons/Star.png';
import userCard from 'assets/images/icons/sectionIcons/User-card.png';
import users from 'assets/images/icons/sectionIcons/Users.png';
import boxWithPin from 'assets/images/icons/sectionIcons/Box-with-pin.png';
import boxWithShield from 'assets/images/icons/sectionIcons/Box-with-shield.png';
import boxWithShield1 from 'assets/images/icons/sectionIcons/Box-with-shield-1.png';
import calendar1 from 'assets/images/icons/sectionIcons/Calendar-1.png';
import calendar2 from 'assets/images/icons/sectionIcons/Calendar-2.png';
import calendar3 from 'assets/images/icons/sectionIcons/Calendar-3.png';
import creditCard from 'assets/images/icons/sectionIcons/Credit-Card.png';
import hat from 'assets/images/icons/sectionIcons/Hat.png';
import hello2 from 'assets/images/icons/sectionIcons/Hello-2.png';
import integration from 'assets/images/icons/sectionIcons/Integration.png';
import notes from 'assets/images/icons/sectionIcons/Notes.png';
import phone from 'assets/images/icons/sectionIcons/Phone.png';
import questionCheck from 'assets/images/icons/sectionIcons/Question-check.png';
import raportowanie from 'assets/images/icons/sectionIcons/Raportowanie.png';
import ring from 'assets/images/icons/sectionIcons/Ring.png';
import shieldPlus from 'assets/images/icons/sectionIcons/Shield-plus.png';
import tag1 from 'assets/images/icons/sectionIcons/Tag-1.png';
import tag2 from 'assets/images/icons/sectionIcons/Tag-2.png';
import tag3 from 'assets/images/icons/sectionIcons/Tag-3.png';

export default {
	avatar,
	bell,
	'box-with-pin': boxWithPin,
	'box-with-shield': boxWithShield,
	'box-with-shield-1': boxWithShield1,
	'calendar-1': calendar1,
	'calendar-2': calendar2,
	'calendar-3': calendar3,
	chart,
	'credit-card': creditCard,
	doc,
	'done-1': done1,
	'done-2': done2,
	'done-3': done3,
	'done-4': done4,
	'done-5': done5,
	'done-6': done6,
	envelope,
	'folder-locker': folderLocker,
	'folder-user': folderUser,
	hat,
	folder,
	heart,
	hello,
	'hello-2': hello2,
	integration,
	locker,
	'map-pin': mapPin,
	notes,
	phone,
	photo,
	picture,
	'question-check': questionCheck,
	raportowanie,
	ring,
	'road-pin': roadPin,
	settings,
	'settings-1': settings1,
	'settings-2': settings2,
	'settings-3': settings3,
	'settings-4': settings4,
	'shield-plus': shieldPlus,
	star,
	'tag-1': tag1,
	'tag-2': tag2,
	'tag-3': tag3,
	'user-card': userCard,
	users,
};
