import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default function(RemainingChars) {
	return class ResizableTextField extends Component {
		_elementRefs = {};

		render() {
			const {
				classes,
				maxChars,
				text,
				editMode,
				description,
				minRows,
				enableRemainingChars,
				showOnDisplayMode,
				placeholder,
				onChangeOccures,
				onKeyUpOccures,
			} = this.props;

			return (
				<div className="resizableTextField">
					{editMode && description && <div className="description">{description}</div>}
					{(!showOnDisplayMode || editMode) && (
						<textarea
							maxLength={maxChars}
							disabled={!editMode}
							style={{ overflow: 'hidden' }}
							defaultValue={text}
							className={classes}
							onChange={event => onChangeOccures(event.target.value)}
							ref={element => {
								if (element) {
									element.style.height = 'auto';
									element.style.height = `${element.scrollHeight}px`;
								}
							}}
							onKeyUp={event => onKeyUpOccures(event)}
							rows={minRows}
							placeholder={placeholder}
						/>
					)}
					{editMode && enableRemainingChars && (
						<RemainingChars maxLength={this.props.maxChars} text={this.state.text} />
					)}
					{!editMode && showOnDisplayMode && <p className="markdown">{text}</p>}
				</div>
			);
		}

		static propTypes = {
			editMode: PropTypes.bool,
			classes: PropTypes.string,
			text: PropTypes.string,
			maxChars: PropTypes.number,
			onChangeOccures: PropTypes.func,
			onKeyUpOccures: PropTypes.func,
			name: PropTypes.string,
			description: PropTypes.node,
			minRows: PropTypes.number,
			enableRemainingChars: PropTypes.bool,
			showOnDisplayMode: PropTypes.bool,
			placeholder: PropTypes.string,
		};

		static defaultProps = {
			minRows: 1,
			enableRemainingChars: false,
			maxChars: 2000,
			showOnDisplayMode: false,
			text: '',
			onChangeOccures: () => {},
			onKeyUpOccures: () => {},
		};
	};
}
