import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import _ from 'lodash';

import Entry from './Entry.template';

export default class EntryComponent extends PureComponent {
	static propTypes = {
		ranking: PropTypes.object.isRequired,
		position: PropTypes.number.isRequired,
		entry: PropTypes.object,
		team: PropTypes.object,
		container: PropTypes.bool,
		name: PropTypes.string,
		shortName: PropTypes.string,
	};

	render() {
		return <Entry {...this.props} scores={this._scores()} />;
	}

	_scores() {
		const { ranking, entry } = this.props;

		return entry.scores.map(score => {
			if (_.isString(score)) {
				return score;
			}

			return (
				<FormattedNumber
					value={score}
					minimumFractionDigits={ranking.average ? 2 : 0}
					maximumFractionDigits={2}
				/>
			);
		});
	}
}
