import { ContainerPointRankingService } from './ContainerPointRankingService';
import { TeamPointRankingService } from './TeamPointRankingService';
import { UserPointRankingService } from './UserPointRankingService';

export default {
	'services.ranking.containerPointRankingService': {
		class: ContainerPointRankingService,
		deps: [{ $ref: 'services.containerService' }],
	},
	'services.ranking.teamPointRankingService': {
		class: TeamPointRankingService,
		deps: [{ $ref: 'services.containerService' }],
	},
	'services.ranking.userPointRankingService': {
		class: UserPointRankingService,
		deps: [{ $ref: 'services.containerService' }],
	},
};
