import { Ranking } from '../ranking/Ranking';
import { Currency } from '../../application/Currency';

/**
 * Class representing a point ranking.
 * @extends Ranking
 * @abstract
 */
export class PointRanking extends Ranking {
	constructor(ranking) {
		super(ranking);

		/**
		 * @type {Currency|null}
		 */
		this.currency = ranking.currency && new Currency(ranking.currency);
		/**
		 * @type {boolean}
		 */
		this.enableFilters = ranking.enable_filters;
		/**
		 * @type {ContainerGroup|null}
		 */
		this.filterableContainerGroup = ranking.filterable_container_group;
	}
}
