import { each } from 'lodash';
import ChoiceQuestionTask from 'models/task/question/questionTask/choice/ChoiceQuestionTask';

/**
 * Class representing a single choice question task.
 * @abstract
 * @extends QuestionTask
 */
export default class SingleChoiceQuestionTask extends ChoiceQuestionTask {
	/**
	 * @returns {boolean}
	 */
	hasMultipleAnswers() {
		return !!this._multipleAnswers;
	}

	/**
	 *
	 * @param {Answer} answer
	 */
	checkAnswer(answer) {
		each(this.question().answers(), _answer => _answer.uncheck());
		answer.check();
	}

	/**
	 *
	 * @param {Answer} answer
	 * @returns {string}
	 */
	getAnswerType(answer) {
		return `${answer.type()}_single`;
	}
}
