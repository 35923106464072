import Notification from 'models/notification/Notification';

/**
 * Class representing a achievement notification.
 * @extends Notification
 */
export default class AchievementNotification extends Notification {
	/**
	 * @param {Object} notification
	 */
	constructor(notification) {
		super(notification);

		this._achievement = notification.achievement;
		this._removed = !this._achievement;
		this._type = 'achievement';
	}

	getMessage() {
		if (this._removed) {
			return super.getMessage('null-states.achievement');
		}

		return super.getMessage('messages.achievement', {
			name: this.achievement().name,
		});
	}

	getDate(params) {
		return super.getDate('dates.achievement', params);
	}

	getIconPath() {
		return super.getIconPath(this.achievement().image());
	}

	achievement() {
		return this._achievement || {};
	}
}
