import CreativeChallengeService from 'services/challenge/challenge/subServices/CreativeChallengeService';
import KPIIndexedChallengeService from 'services/challenge/challenge/subServices/KPIIndexedChallengeService';
import TargetChallengeService from 'services/challenge/challenge/subServices/TargetChallengeService';

export default {
	'services.creativeChallengeService': {
		class: CreativeChallengeService,
		deps: [
			{ $ref: 'services.APIService' },
			{ $ref: 'services.currencyService' },
			{ $ref: 'services.ranking.rankingService' },
		],
	},
	'services.KPIIndexedChallengeService': {
		class: KPIIndexedChallengeService,
		deps: [{ $ref: 'services.ranking.rankingService' }],
	},
	'services.targetChallengeService': {
		class: TargetChallengeService,
		deps: [{ $ref: 'services.currencyService' }],
	},
};
