import React, { Fragment, PureComponent } from 'react';
import { injectIntl } from 'react-intl';

import { Markdown } from 'components/_/common/text/markdown/Markdown';

export default function() {
	return injectIntl(
		class IsAMemberAlready extends PureComponent {
			render() {
				const { task, intl, name } = this.props;

				return (
					<Fragment>
						<div className="description fs-6">
							<Markdown>{task.description()}</Markdown>
						</div>
						<div
							className="title"
							data-text={intl.formatMessage({
								id: 'task-details.join.is-a-member-already.description',
							})}
						/>
						<h2 className="mb-0">{name}</h2>
					</Fragment>
				);
			}
		}
	);
}
