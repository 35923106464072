import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import useStyles from './ItemView.jss';

import GoBack from './go-back/GoBack';

import Loader from 'components/commons/_/loader/Loader';

import { AutoScroll } from 'components/_/common/text/auto-scroll/AutoScroll';

export const Header = props => {
	const { title, subtitle, goBack = {}, children } = props;
	const classes = useStyles();

	return (
		<div className={classes.header}>
			<GoBack goBack={goBack} />
			<div className={classnames('align-self-stretch', classes.line)} />
			<AutoScroll>
				<h1 className="m-0">{title}</h1>
				{subtitle && <h2 className="mb-0 ml-3">{subtitle}</h2>}
			</AutoScroll>
			{children && <div className="ml-2">{children}</div>}
		</div>
	);
};

export const Content = ({ children }) => {
	return <div className="flex-grow-1 w-100 h-100 overflow-auto">{children}</div>;
};

export default ({ children, className, i18nKey, loading = false, error = false }) => {
	const classes = useStyles();

	return loading ? (
		<Loader />
	) : error ? (
		<p className="fs-6 pl-2 pt-2 pl-md-0">
			<FormattedMessage id={`${i18nKey}.error.${error}`} />
		</p>
	) : (
		<div className={classnames(className, classes.view)}>{children()}</div>
	);
};
