export type ChallengeStateDTO = {
	can_participate: boolean;
};

export default abstract class ChallengeState {
	private _canParticipate: boolean;

	constructor(state: ChallengeStateDTO) {
		this._canParticipate = state.can_participate;
	}

	/**
	 * @returns {boolean}
	 */
	canParticipate() {
		return this._canParticipate;
	}

	setCanParticipate() {
		this._canParticipate = true;
	}
}
