import React from 'react';
import Logo from './Logo';

export type InfoProps = {
	title?: React.ReactNode;
	message?: React.ReactNode;
	additional?: React.ReactNode;
};

export default ({ title, message, additional }: InfoProps) => (
	<div className="infoLayer position-fixed">
		<div className="integrations">
			<div className="content">
				<Logo />
				{title && <h2>{title}</h2>}
				{message && <p>{message}</p>}
				{additional}
			</div>
		</div>
	</div>
);
