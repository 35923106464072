import APIService, { ContentType } from 'services/APIService';
import Process, { ProcessDTO } from 'models/onboarding/process/Process';

export type AssignRoleParams = { processId: string; roleId: string; userId: string };

export default class ProcessService {
	constructor(private readonly ApiService: APIService) {}

	fetchList() {
		return this.ApiService.get<{ items: ProcessDTO[] }>(`/process/progress`, undefined, undefined, {
			version: 'v2',
			controller: 'workflows',
		}).then(response => ({
			items: response.items.map(process => this.toModel(process)),
		}));
	}

	fetchEmployeeList(employeeId?: string) {
		let url = '/my-users/process/progress';

		if (employeeId) {
			url += `?employeeId=${employeeId}`;
		}

		return this.ApiService.get<{ items: ProcessDTO[] }>(url, undefined, undefined, {
			version: 'v2',
			controller: 'workflows',
		}).then(response => ({
			items: response.items.map(process => this.toModel(process)),
		}));
	}

	async fetchReport(type: 'process-report' | 'process-actions', employeeId?: string) {
		let url = `/report/${type}`;

		if (employeeId) {
			url += `?employeeId=${employeeId}`;
		}

		const data = await this.ApiService.get(
			url,
			null,
			{
				responseType: 'arraybuffer',
			},
			{ version: 'v2', controller: 'workflows' }
		);

		const file = new Blob([data], {
			type: ContentType.XLSX,
		});

		window.open(window.URL.createObjectURL(file));
	}

	async assignRole({ processId, roleId, userId }: AssignRoleParams) {
		await this.ApiService.post(`/process/${processId}/role/${roleId}`, { user_id: userId }, undefined, {
			version: 'v2',
			controller: 'workflows',
		});

		return true;
	}

	toModel(process: ProcessDTO): Process {
		return new Process(process);
	}
}
