import subServices from 'services/task/subServices/questionTask/order/answer/subServices';
import OrderAnswerService from 'services/task/subServices/questionTask/order/answer/OrderAnswerService';

export default {
	...subServices,
	'services.task.order.answer': {
		class: OrderAnswerService,
		deps: [{ $ref: 'services.logService' }],
		calls: [
			{
				name: 'registerSubService',
				args: ['text', { $ref: 'services.task.order.answer.text' }],
			},
		],
	},
};
