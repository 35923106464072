import React from 'react';
import { wire } from 'react-hot-wire';

import View from 'components/_/common/view/list/ListView';

import NewsList from './NewsList';

function NewsListView(props) {
	const newsService = props['services.newsService'];

	return (
		<View
			name="newsList"
			i18nKey="news-list"
			config={{
				search: false,
			}}
		>
			<NewsList newsService={newsService} />
		</View>
	);
}

export default wire(['services.newsService'], NewsListView);
