import ExternalAnchor from 'models/application/anchor/types/external/External';

export default class ExternalAnchorUrl extends ExternalAnchor {
	constructor(anchor) {
		super(anchor);

		this._url = anchor.url;
		this.target = /^mailto:([^?]+)/.test(this.url()) ? '_self' : '_blank';
	}

	url() {
		return this._url;
	}
}
