import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';

export default injectIntl(
	class Countdown extends PureComponent {
		static propTypes = {
			endHandler: PropTypes.func,
		};

		_dateEnd = null;
		_rafId = null;

		state = {
			distance: null,
			timestamp: moment(),
		};

		componentDidMount() {
			this._dateEnd = moment(this.props.date);

			this._tick(moment());
		}

		render() {
			return moment().to(this.props.date, true);
		}

		_tick(lastTick) {
			const distance = moment(this._dateEnd).diff(moment());
			const currentTimestamp = moment();

			if (distance < 1000) {
				window.cancelAnimationFrame(this._rafId);
				this.props.endHandler();

				return;
			}

			/* Optimalization. Increase setState frequency when more precise time measurement is needed (below one minute) */
			if (lastTick.diff(this.state.timestamp) > (distance < 1000 * 60 * 60 ? 100 : 1000)) {
				this.setState({
					distance,
					timestamp: currentTimestamp,
				});
			}

			this._rafId = window.requestAnimationFrame(() => this._tick(currentTimestamp));
		}
	}
);
