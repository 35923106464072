import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import map from 'lodash/map';

import Mission from 'models/task/Mission';
import TaskState from 'models/task/state/TaskState';

//import SVGImage from 'components/commons/SVGImage';

import { Currency } from 'components/_/common/currency/Currency';
import { ProgressBar } from 'components/_/common/progress-bar/ProgressBar';

export const CollectionList = ({ task }) => {
	return (
		<div className="collectionMission__rowList flex-grow-1 my-2 my-sm-0">
			<div className="row">
				{task.tasks().map(_task => (
					<div key={_task.id()} className="col-24">
						<div className="mx-0 mx-sm-3">
							<NavLink
								to={`/${_task instanceof Mission ? 'mission' : 'task'}/${_task.id()}/details`}
								onClick={event =>
									_task.state().status() === TaskState.STATUS_UNAVAILABLE && event.preventDefault()
								}
								className={classnames('position-relative d-block', {
									[`task--${_task.state().status()}`]: true,
								})}
							>
								<div className="collectionMission__listRow d-flex py-3 py-sm-0 fs-4">
									<div className="d-flex align-items-center">
										<div className="listRow__status px-3 px-sm-5 py-3">
											<div className="task__status rounded-circle" />
										</div>
										{/*<div className="listRow__icon pr-3 pr-sm-5">
											{_task.icon() && <SVGImage src={_task.icon()} className="icon mr-2 mr-xs-3" />}
										</div>*/}
									</div>
									<div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center flex-grow-1">
										<div className="listRow__name pr-0 pr-sm-5 flex-grow-1 order-1 order-sm-1">
											{_task.name()}
										</div>
										{_task instanceof Mission && (
											<div className="listRow__progressBar d-block d-sm-flex pt-2 pt-sm-0 pr-0 pr-sm-5 order-3 order-sm-2 flex-grow-1">
												<p className="mr-3 d-none d-sm-block">Twój postęp w misji</p>
												<ProgressBar
													progress={_task.state().progress() * 100}
													tooltip={true}
													className="align-self-center flex-grow-1"
												/>
											</div>
										)}
										{_task.state().hasCompletionEarnings() && (
											<div className="listRow__points d-flex align-items-center px-0 px-sm-3 pt-2 py-sm-5 order-2 order-sm-3 rounded-right">
												{map(_task.earningsInformation().completion.values(), currency => (
													<Currency
														key={currency.codeName()}
														currency={currency.codeName()}
														value={currency.value()}
														className="mr-4 mx-sm-3"
													/>
												))}
											</div>
										)}
									</div>
								</div>
							</NavLink>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
