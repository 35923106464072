import WrapperMenu from 'views/wrapper/WrapperMenu';

export default {
	'components.WrapperMenu': {
		factory: WrapperMenu,
		deps: [
			{ $ref: 'components.base.WrapperMenu' },
			{ $ref: 'components.LanguageSwitcher' },
			{ $ref: 'components.MenuAnchor' },
			{ $ref: 'services.accessPopupService' },
			{ $ref: 'components.Image' },
			{ $ref: 'services.applicationService' },
		],
	},
};
