import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bowser from 'bowser';
import { FormattedMessage } from 'react-intl';

export default class InfoCloud extends Component {
	elementRefs = {};

	componentDidMount() {
		window.addEventListener('resize', (this.__resizeWindowEvent = () => this._handlePosition()));

		if (bowser.mobile) {
			this.elementRefs.cloudBox.parentElement.addEventListener(
				'touchstart',
				(this.__mouseEnterEvent = () => this._handlePosition())
			);
		} else {
			this.elementRefs.cloudBox.parentElement.addEventListener(
				'mouseenter',
				(this.__mouseEnterEvent = () => this._handlePosition())
			);
		}
	}

	render() {
		return (
			<div
				className={`infoCloud ${this.props.classes}`}
				ref={element => {
					this.elementRefs.cloudBox = element;
				}}
			>
				<div className="infoCloud__textWrapper">
					{this.props.title && (
						<div className="title standardText">
							<FormattedMessage id={this.props.title} />
						</div>
					)}
					{this.props.message && this._message(this.props.message)}
				</div>
				{this.props.rightContent && (
					<div className="wrapper--col justify--center rightContent">{this.props.rightContent}</div>
				)}
				{this.props.children}
			</div>
		);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.__resizeWindowEvent);
		this.elementRefs.cloudBox.parentElement.removeEventListener('mouseenter', this.__mouseEnterEvent);
		this.elementRefs.cloudBox.parentElement.removeEventListener('touchstart', this.__mouseEnterEvent);
	}

	_message(message) {
		return (
			<div className="message standardText">
				<FormattedMessage id={message.id} values={message.values} />
			</div>
		);
	}

	_handlePosition() {
		const { cloudBox } = this.elementRefs;
		const cloudBoxStylesWidth = cloudBox.getBoundingClientRect().width;
		const cloudBoxStylesHeight = cloudBox.getBoundingClientRect().height;
		const parentStyles = this.elementRefs.cloudBox.parentElement.getBoundingClientRect();
		const freeSpaceAtRight = window.innerWidth - parentStyles.left - parentStyles.width;
		const freeSpaceAtLeft = parentStyles.left;

		cloudBox.style.position = 'fixed';
		cloudBox.style.top = '-10px';
		cloudBox.style.position = 'absolute';
		cloudBox.classList.add('hide');

		if (freeSpaceAtRight > cloudBoxStylesWidth + 20) {
			cloudBox.style.left = `${parentStyles.width + 4}px`;
			cloudBox.classList.add('right');
			cloudBox.classList.remove('left');
			cloudBox.classList.remove('bottom');
		}

		if (freeSpaceAtLeft > cloudBoxStylesWidth + 20 && freeSpaceAtRight < cloudBoxStylesWidth + 20) {
			cloudBox.style.left = `${cloudBoxStylesWidth * -1 - 4}px`;
			cloudBox.classList.remove('right');
			cloudBox.classList.add('left');
			cloudBox.classList.remove('bottom');
		}

		if (freeSpaceAtLeft < cloudBoxStylesWidth + 20 && freeSpaceAtRight <= cloudBoxStylesWidth + 20) {
			cloudBox.style.left = `${-(0.5 * cloudBoxStylesWidth) + 0.5 * parentStyles.width}px`;
			cloudBox.style.top = `${-cloudBoxStylesHeight - 10}px`;
			cloudBox.classList.remove('right');
			cloudBox.classList.remove('left');
			cloudBox.classList.add('bottom');
		}
	}

	static propTypes = {
		message: PropTypes.object,
		classes: PropTypes.string,
		title: PropTypes.string,
		topAlign: PropTypes.bool,
		leftAlign: PropTypes.bool,
		rightContent: PropTypes.string,
		children: PropTypes.node,
	};
}
