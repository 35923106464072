import TaskAnchor from 'models/application/anchor/types/internalDynamic/types/Task';
import MissionAnchor from 'models/application/anchor/types/internalDynamic/types/Mission';
import MapAnchor from 'models/application/anchor/types/internalDynamic/types/Map';

import AchievementAnchor from 'models/application/anchor/types/internalDynamic/types/Achievement';
import PortalAnchor from 'models/application/anchor/types/internalStatic/types/Portal';
import UserProfileAnchor from 'models/application/anchor/types/internalStatic/types/UserProfile';
import TeamProfileAnchor from 'models/application/anchor/types/internalStatic/types/TeamProfile';
import NewsListAnchor from 'models/application/anchor/types/internalStatic/types/NewsList';
import MissionListAnchor from 'models/application/anchor/types/internalStatic/types/MissionList';
import AchievementListAnchor from 'models/application/anchor/types/internalStatic/types/AchievementList';
import NotificationListAnchor from 'models/application/anchor/types/internalStatic/types/NotificationList';
import PrizeListAnchor from 'models/application/anchor/types/internalStatic/types/PrizeList';
import ChallengeListAnchor from 'models/application/anchor/types/internalStatic/types/ChallengeList';
import RankingListAnchor from 'models/application/anchor/types/internalStatic/types/RankingList';
import RewardCafeteriaAnchor from 'models/application/anchor/types/internalStatic/types/RewardCafeteria';

const missionTypes = {
	collection_mission: MissionAnchor,
	graph: MissionAnchor,
	map: MapAnchor,
};

export default class NodeClickHandler {
	// graph is not using really the new anchors, as in menu etc. But we can use the returned "anchor_ref" to select
	// appropriate anchor to hide that legacy behaviour
	static ANCHOR_URL_MAP = {
		portal: PortalAnchor,
		user_profile: UserProfileAnchor,
		team_profile: TeamProfileAnchor,
		news: NewsListAnchor,
		mission: MissionListAnchor,
		achievement: AchievementListAnchor,
		notification: NotificationListAnchor,
		shop: PrizeListAnchor,
		challenge: ChallengeListAnchor,
		ranking: RankingListAnchor,
		dizzy_challenge: ChallengeListAnchor,
		reward_cafeteria: RewardCafeteriaAnchor,
	};

	constructor(navigationService, taskService, achievementService) {
		this.navigationService = navigationService;
		this.taskService = taskService;
		this.achievementService = achievementService;
		this.routes = {
			task: ({ reached, task }) => {
				if (reached) {
					return new (missionTypes[task.task_type] || TaskAnchor)({
						task: taskService.toModel(task),
					});
				}
			},
			achievement: ({ achievement }) =>
				new AchievementAnchor({
					achievement: achievementService.toModel(achievement),
				}),
			anchor: ({ anchor_ref }) => new this.constructor.ANCHOR_URL_MAP[anchor_ref](),
		};
	}

	handle({ properties }) {
		const { node } = properties;
		const nodeType = node.node_type || node.type;

		if (nodeType in this.routes) {
			let route = this.routes[nodeType](node);

			if (route) {
				this.navigationService.goTo(route);
			}
		}
	}

	addRoute(nodeType, routeGenerator) {
		this.routes[nodeType] = routeGenerator;
	}
}
