import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Field, ErrorMessage, FieldArray } from 'formik';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

export default ({ intl, values, status, isValid, isSubmitting, errors, touched, agreements, ReactMarkdown }) => (
	<div className="integration">
		<Form>
			<h2>
				<FormattedMessage id="auth.register.integration.imported-id.title" tagName={React.Fragment} />
			</h2>
			<div
				className={classnames('field', {
					invalid: errors.forename && touched.forename,
				})}
			>
				<Field
					name="forename"
					render={({ field }) => (
						<input
							{...field}
							type="text"
							autoComplete="given-name"
							placeholder={intl.formatMessage({
								id: 'auth.register.integration.imported-id.field.forename.placeholder',
							})}
						/>
					)}
				/>
				<ErrorMessage
					name="forename"
					render={error => (
						<div className="error">
							<FormattedMessage id={`auth.form.error.${error}`} />
						</div>
					)}
				/>
			</div>
			<div
				className={classnames('field', {
					invalid: errors.surname && touched.surname,
				})}
			>
				<Field
					name="surname"
					render={({ field }) => (
						<input
							{...field}
							type="text"
							autoComplete="family-name"
							placeholder={intl.formatMessage({
								id: 'auth.register.integration.imported-id.field.surname.placeholder',
							})}
						/>
					)}
				/>
				<ErrorMessage
					name="surname"
					render={error => (
						<div className="error">
							<FormattedMessage id={`auth.form.error.${error}`} />
						</div>
					)}
				/>
			</div>
			<div
				className={classnames('field', {
					invalid: errors.email && touched.email,
					empty: errors.email === 'empty',
				})}
			>
				<Field
					name="email"
					render={({ field }) => (
						<input
							{...field}
							type="text"
							autoComplete="email"
							placeholder={intl.formatMessage({
								id: 'auth.register.integration.imported-id.field.email.placeholder',
							})}
						/>
					)}
				/>
				<ErrorMessage
					name="email"
					render={error =>
						error === 'empty' ? (
							''
						) : (
							<div className="error">
								<FormattedMessage id={`auth.form.error.${error}`} />
							</div>
						)
					}
				/>
			</div>
			<div
				className={classnames('field', {
					invalid: errors.phone && touched.phone,
				})}
			>
				<Field
					name="phone"
					render={({ field }) => (
						<input
							{...field}
							type="text"
							autoComplete="tel"
							placeholder={intl.formatMessage({
								id: 'auth.register.integration.imported-id.field.phone.placeholder',
							})}
						/>
					)}
				/>
				<ErrorMessage
					name="phone"
					render={error => (
						<div className="error">
							<FormattedMessage id={`auth.form.error.${error}`} />
						</div>
					)}
				/>
			</div>
			{status === 'user-profile-does-not-match' && (
				<div className="error">
					<FormattedMessage id={`auth.form.error.${status}`} />
				</div>
			)}
			{!isEmpty(agreements) && (
				<div className="agreements pb-3 pt-2">
					<FieldArray
						name="agreements"
						render={output => (
							<Fragment>
								{agreements.map(agreement => (
									<label key={agreement.id()} className="selectWrapper noMargin wrapper--row mb-2">
										<div className="checkboxWrapper el--shrink">
											<input
												type="checkbox"
												name="agreements"
												value={agreement.id()}
												checked={values.agreements.includes(agreement.id())}
												onChange={event => {
													if (event.target.checked) {
														output.push(agreement.id());
													} else {
														output.remove(values.agreements.indexOf(agreement.id()));
													}
												}}
											/>
											<div className="checked" />
										</div>
										<div className="standardText standardText--noMargin standardText--leftAlign">
											<ReactMarkdown
												source={agreement.body()}
												className="inline"
												render={({ text }) => (
													<Fragment>
														{text}
														{agreement.required() ? '*' : ''}
													</Fragment>
												)}
											/>
										</div>
									</label>
								))}
							</Fragment>
						)}
					/>
					{status === 'agreements-required' && (
						<div className="error mb-0 pt-2">
							<FormattedMessage id="auth.form.error.agreements-required" />
						</div>
					)}
				</div>
			)}
			<button
				className={classnames('btn__basic btn__basic--fullWidth', {
					'btn__basic--disabled': !isValid,
					loading: isSubmitting,
				})}
				type="submit"
			>
				<FormattedMessage id="auth.register.integration.imported-id.button.register" />
			</button>
		</Form>
	</div>
);
