import React, { Component } from 'react';

import { Header } from 'components/_/common/view/item/ItemView';

export default function(Mobile, Content, Footer, DegressionCurrencies) {
	return class BaseLayout extends Component {
		render() {
			const { task, options = {}, children, actions, progressIndicator, contentProps } = this.props;

			return (
				<div className="taskDetails wrapper--col el--grow w-100">
					<div className="standardPreview wrapper--col el--grow">
						<Header title={task.name()} goBack={{ defaultLocation: '/mission/list' }}>
							<DegressionCurrencies
								parentClasses="wrapper--row align--center currencyWrapper"
								currencies={task.state().earningsInformation().completion}
							/>
						</Header>
						{task.isSubmitted() ||
						(task.state().currentRepetitionState() &&
							['awaiting', 'rejected'].includes(
								task
									.state()
									.currentRepetitionState()
									.acceptanceState()
							)) ? (
							<Mobile task={task} actions={actions} />
						) : null}
						<Content task={task} {...contentProps}>
							{children}
						</Content>
						{(!options.hideFooterBeforeSubmit || task.isSubmitted()) && (
							<Footer
								task={task}
								actions={actions}
								progressIndicator={progressIndicator}
								options={options}
							/>
						)}
					</div>
				</div>
			);
		}
	};
}
