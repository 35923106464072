import commitmentRanking from './commitmentRanking';
import importedRanking from './importedRanking';
import pointRanking from './pointRanking';
import submissionRanking from './submissionRanking';

import { RankingService } from './RankingService';

export default {
	...commitmentRanking,
	...importedRanking,
	...pointRanking,
	...submissionRanking,
	'services.ranking.rankingService': {
		class: RankingService,
		deps: [{ $ref: 'services.APIService' }],
		calls: [
			{
				name: 'registerRankingService',
				args: [
					'commitment__user',
					{ $ref: 'services.ranking.userCommitmentRankingService' },
					{
						$ref: 'services.ranking.userCommitmentRankingEntryService',
					},
				],
			},
			{
				name: 'registerRankingService',
				args: [
					'container_imported',
					{
						$ref: 'services.ranking.containerImportedRankingService',
					},
					{
						$ref: 'services.ranking.containerImportedRankingEntryService',
					},
				],
			},
			{
				name: 'registerRankingService',
				args: [
					'team_imported',
					{ $ref: 'services.ranking.teamImportedRankingService' },
					{ $ref: 'services.ranking.teamImportedRankingEntryService' },
				],
			},
			{
				name: 'registerRankingService',
				args: [
					'user_imported',
					{ $ref: 'services.ranking.userImportedRankingService' },
					{ $ref: 'services.ranking.userImportedRankingEntryService' },
				],
			},
			{
				name: 'registerRankingService',
				args: [
					'point__container',
					{ $ref: 'services.ranking.containerPointRankingService' },
					{
						$ref: 'services.ranking.containerPointRankingEntryService',
					},
				],
			},
			{
				name: 'registerRankingService',
				args: [
					'point__team',
					{ $ref: 'services.ranking.teamPointRankingService' },
					{ $ref: 'services.ranking.teamPointRankingEntryService' },
				],
			},
			{
				name: 'registerRankingService',
				args: [
					'point__user',
					{ $ref: 'services.ranking.userPointRankingService' },
					{ $ref: 'services.ranking.userPointRankingEntryService' },
				],
			},
			{
				name: 'registerRankingService',
				args: [
					'submission',
					{ $ref: 'services.ranking.submissionRankingService' },
					{ $ref: 'services.ranking.submissionRankingEntryService' },
				],
			},
		],
	},
};
