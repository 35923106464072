import React from 'react';
import { wire } from 'react-hot-wire';
import classnames from 'classnames';

function LanguageSwitcher(props) {
	const i18nService = props['services.i18nService'];
	const locales = i18nService.availableLocales();

	return locales.length > 1 ? (
		<div className={classnames('languageSwitcher', props.containerClassName)}>
			<select
				onChange={event => i18nService.changeLocale(event.target.value, false)}
				defaultValue={i18nService.locale()}
			>
				{locales.map(locale => (
					<option key={locale} value={locale}>
						{locale}
					</option>
				))}
			</select>
		</div>
	) : null;
}

export default wire(['services.i18nService'], LanguageSwitcher);
