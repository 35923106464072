import React, { Component } from 'react';
import _ from 'lodash';

export default function(realTimeNotificationService, userProfileService, notificationTypes) {
	return class BaseRealTimeNotifications extends Component {
		state = {
			notification: null,
			closeFunction: null,
		};

		render() {
			if (this.state.notification) {
				const Notification = notificationTypes[this.state.notification.type()];

				return <Notification {...this.state} />;
			} else {
				return null;
			}
		}

		componentDidMount() {
			realTimeNotificationService.setRecipient(({ notification, closeFunction }) => {
				const close = () => {
					const earnings = notification.earnings();

					this.setState({ notification: null });

					if (earnings) {
						userProfileService.addToWalletQueue(earnings);
					}

					closeFunction();
				};

				if (!_.includes(BaseRealTimeNotifications.SUPPORTED, notification.type())) {
					closeFunction();

					return;
				}

				this.setState({
					notification,
					close,
				});
			});
		}

		componentWillUnmount() {
			realTimeNotificationService.removeRecipient();
		}

		static SUPPORTED = [
			'achievement',
			'mission',
			'levelUp',
			'challengeSubmissionStateChanged',
			'declarationTaskAcceptanceChanged',
		];
	};
}
