import React from 'react';

import { ErrorMessage } from 'components/_/form/error/Error';

import { Checkbox } from 'components/_/form/checkbox/Checkbox';

export const DeclarationQuestion = ({ formBlock, disabled }) => {
	return (
		<div className="fs-4">
			<div className="w-100 w-sm-50">
				<Checkbox name={formBlock.id()} disabled={disabled}>
					{formBlock.body()}
				</Checkbox>
			</div>
			<ErrorMessage name={formBlock.id()} />
		</div>
	);
};
