import Container from 'components/ranking/_/entry/types/Container';
import Submission from 'components/ranking/_/entry/types/Submission';
import Team from 'components/ranking/_/entry/types/Team';
import User from 'components/ranking/_/entry/types/User';

export default {
	container: Container,
	team: Team,
	user: User,
	commitment__user: User,
	point__container: Container,
	point__team: Team,
	point__user: User,
	user_imported: User,
	team_imported: Team,
	container_imported: Container,
	submission: Submission,
};
