import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Media from 'react-media';

import SVGImage from 'components/commons/SVGImage';

import Confetti from 'components/application/animation/confetti/Confetti';

export default function(Currencies) {
	return class Notification extends Component {
		render() {
			const { notification, close, button: Button, disableConfetti, disableTitle } = this.props;

			return (
				<div className="realTimeNotification wrapper--col justify--center align--center">
					<div className="background" />
					{!disableConfetti && (
						<Media query={{ maxWidth: 640 }}>{matches => (matches ? null : <Confetti />)}</Media>
					)}
					<section className="content">
						{!disableTitle && (
							<h2 className="mb-0">
								<FormattedMessage id={`notification.real-time.${notification.type()}.title`} />
							</h2>
						)}
						<h2 className="mb-0">
							<FormattedMessage id={`notification.real-time.${notification.type()}.subtitle`} />
						</h2>
						{notification.getIconPath() ? (
							<div className="box wrapper--col align--center">
								<SVGImage src={notification.getIconPath()} className="image image--contain el--grow" />
							</div>
						) : (
							<div
								className="box wrapper--col align--center justify--center"
								style={{ fontSize: '112px' }}
							>
								{notification.imageAlternativeText()}
							</div>
						)}
						<h3 className="mb-3">
							<FormattedMessage {...notification.getMessage()} />
						</h3>
						{notification.hasEarnings() && (
							<React.Fragment>
								<p>
									<FormattedMessage id={`notification.real-time.${notification.type()}.earn`} />
								</p>
								<Currencies
									parentClasses="wrapper--row currencyWrapper"
									currencies={notification.earnings()}
								/>
							</React.Fragment>
						)}
						<Button notification={notification} close={close} />
					</section>
				</div>
			);
		}

		static propTypes = {
			notification: PropTypes.object,
			close: PropTypes.func,
			button: PropTypes.element,
		};
	};
}
