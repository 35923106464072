import ChallengeSubmissionAwardedPlaceService from 'services/challenge/awardedPlace/subServices/ChallengeSubmissionAwardedPlaceService';
import ChallengeTeamAwardedPlaceService from 'services/challenge/awardedPlace/subServices/ChallengeTeamAwardedPlaceService';
import ChallengeUserAwardedPlaceService from 'services/challenge/awardedPlace/subServices/ChallengeUserAwardedPlaceService';
import ChallengeUserContainerAwardedPlaceService from 'services/challenge/awardedPlace/subServices/ChallengeUserContainerAwardedPlaceService';

export default {
	'services.challengeSubmissionAwardedPlaceService': {
		class: ChallengeSubmissionAwardedPlaceService,
		deps: [
			{ $ref: 'services.storeItemService' },
			{ $ref: 'services.challengeService' },
			{ $ref: 'services.currencyService' },
		],
	},
	'services.challengeTeamAwardedPlaceService': {
		class: ChallengeTeamAwardedPlaceService,
		deps: [
			{ $ref: 'services.teamService' },
			{ $ref: 'services.storeItemService' },
			{ $ref: 'services.challengeService' },
			{ $ref: 'services.currencyService' },
		],
	},
	'services.challengeUserAwardedPlaceService': {
		class: ChallengeUserAwardedPlaceService,
		deps: [
			{ $ref: 'services.userService' },
			{ $ref: 'services.storeItemService' },
			{ $ref: 'services.challengeService' },
			{ $ref: 'services.currencyService' },
		],
	},
	'services.challengeUserContainerAwardedPlaceService': {
		class: ChallengeUserContainerAwardedPlaceService,
		deps: [
			{ $ref: 'services.storeItemService' },
			{ $ref: 'services.challengeService' },
			{ $ref: 'services.currencyService' },
		],
	},
};
