import _ from 'lodash';

import CreativeChallenge from 'models/challenge/challenge/CreativeChallenge';
import CreativeChallengeState from 'models/challenge/challenge/state/CreativeChallengeState';

import ChallengeBaseService from 'services/challenge/challenge/ChallengeBaseService';

export default class CreativeChallengeService extends ChallengeBaseService {
	constructor(APIService, currencyService, rankingService) {
		super();
		this.APIService = APIService;
		this._currencyService = currencyService;
		this._rankingService = rankingService;
	}

	toModel(challenge, submissionCount) {
		return new CreativeChallenge({
			...challenge,
			submissionCount,
			state: challenge.state && new CreativeChallengeState(challenge.state),
			points_for_nothing: this._currencyService.toModel(challenge.points_for_nothing),
			submission_earnings: this._currencyService.toModel(challenge.submission_earnings),
			ranking: challenge.ranking && this._rankingService.toModel(challenge.ranking),
		});
	}

	addSubmission({ id, ...rest }) {
		let data = new FormData();

		_.each(rest, (value, key) => data.append(key, value));

		return this.APIService.post(`v1/dizzy/challenge/${id}/submission/create`, data);
	}
}
