import UserProfile from 'models/user/UserProfile';
import Roles from 'models/user/Roles';

// @todo Roles and UserProfile DTO types
export type TeamMemberDTO = {
	roles: any;
	user_profile: any;
};

export default class TeamMember {
	roles: Roles;
	userProfile: UserProfile;

	constructor(teamMember: TeamMemberDTO) {
		this.roles = new Roles(...teamMember.roles);

		this.userProfile = new UserProfile(teamMember.user_profile);
	}

	static CHOOSABLE_ROLES = ['user', 'manager'];
}
