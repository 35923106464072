import React, { PureComponent, createElement } from 'react';
import Async from 'react-async-action';

import Loader from 'components/commons/_/loader/Loader';

export default function(taskService, types) {
	return class MissionDetails extends PureComponent {
		_action() {
			return taskService.fetchDetails({ id: this.props.match.params.id });
		}

		render() {
			return (
				<Async action={() => this._action()} params={{ id: this.props.match.params.id }}>
					<Async.Loading>
						<div className="map">
							<Loader />
						</div>
					</Async.Loading>
					<Async.Resolved>
						{({ response: task }) =>
							createElement(types[task.taskType()], {
								task,
							})
						}
					</Async.Resolved>
				</Async>
			);
		}
	};
}
