import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import { Button } from 'components/_/common/button/Button';
import { Currency } from 'components/_/common/currency/Currency';
import Pagination from 'components/_/common/pagination/Pagination';

import useFetch from 'components/_/hooks/useFetch';

import Loader from 'components/commons/_/loader/Loader';

export const Cafeteria = ({ search, filters, rewardCafeteriaService, currency }) => {
	const i18nKey = 'cafeteria';

	const [rowsPerPage] = useState(24);
	const [page, setPage] = useState(0);

	const {
		loading,
		error,
		response: { list, count },
	} = useFetch(
		() =>
			rewardCafeteriaService.fetchList({
				filters: {
					...filters,
					...(filters.afford
						? {
								from: 1,
								to: currency.value(),
						  }
						: {}),
				},
				search,
				offset: page * rowsPerPage,
				limit: rowsPerPage,
			}),
		{ list: [], count: 0 },
		[filters, search, page, rowsPerPage]
	);

	useEffect(() => {
		setPage(0);
	}, [search, filters]);

	return (
		<div className="flex-grow-1 my-4 my-sm-0">
			{loading ? (
				<Loader />
			) : error ? (
				<p className="fs-6 mx-0 mx-sm-3">
					<FormattedMessage id={`${i18nKey}.error.${error}`} />
				</p>
			) : isEmpty(list) ? (
				<p className="fs-6 mx-0 mx-sm-3">
					<FormattedMessage id={`${i18nKey}.error.empty`} />
				</p>
			) : (
				<div className="row">
					{list.map(item => {
						const canBuy = currency && currency.value() >= item.price.value();

						return (
							<div className="col-12 col-xs-8 col-sm-24 col-md-12 col-lg-6 col-xxl-4 mb-4 mb-sm-5">
								<div className="mx-0 mx-sm-3 h-100">
									<Link to={`/reward-cafeteria/item/${item.id}`}>
										<div className="tile store__item rounded h-100">
											<div className="tile__front d-flex flex-column justify-content-between">
												<div
													className="tile__image background-image size--cover rounded-top"
													style={{
														backgroundImage: `url(${item.image})`,
													}}
												/>
												<div className="d-flex flex-column flex-grow-1 align-items-center justify-content-between p-3 px-sm-4 pt-sm-4">
													<div className="item__name mb-3 fs-4 fs-sm-5 font-weight-bold text-center">
														{item.name}
													</div>
													<div className="item__price fs-4">
														<div className="d-flex flex-wrap">
															<Currency
																key={item.price.codeName()}
																className="mx-1"
																currency={item.price.codeName()}
																value={item.price.value()}
															/>
														</div>
													</div>
												</div>
											</div>
											<div className="tile__back d-none d-sm-flex flex-column justify-content-between">
												<div className="p-4 fs-4 d-flex flex-column justify-content-between flex-grow-1">
													<div className="item__key-value">
														<div className="d-flex justify-content-between py-2">
															<div className="mr-2">
																<FormattedMessage id={`${i18nKey}.item.price`} />
															</div>
															<div className="d-flex flex-wrap">
																<Currency
																	className="mx-1"
																	currency={item.price.codeName()}
																	value={item.price.value()}
																/>
															</div>
														</div>
														{currency && !canBuy && (
															<div className="d-flex justify-content-between py-2">
																<div className="mr-2">
																	<FormattedMessage id={`${i18nKey}.item.missing`} />
																</div>
																<div className="d-flex flex-wrap">
																	<Currency
																		className="mx-1"
																		currency={currency.codeName()}
																		value={item.price.value() - currency.value()}
																	/>
																</div>
															</div>
														)}
													</div>
												</div>
												<div className="item__button d-flex flex-column align-items-center py-3">
													<Button
														className="fs-5"
														color={
															!item.isAvailable || !canBuy ? 'disabled' : 'interactive'
														}
													>
														<FormattedMessage
															id={`${i18nKey}.item.${
																item.isAvailable ? 'buy-now' : 'not-available'
															}`}
														/>
													</Button>
												</div>
											</div>
										</div>
									</Link>
								</div>
							</div>
						);
					})}
				</div>
			)}

			{count > 0 && !loading && (
				<Pagination count={count} page={page} rowsPerPage={rowsPerPage} onChange={page => setPage(page)} />
			)}
		</div>
	);
};
