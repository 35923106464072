import { PWAInstallationService } from 'services/application/PWA/PWAInstallationService';
import { PWAWebPushService } from 'services/application/PWA/PWAWebPushService';

export default {
	'services.PWAInstallationService': {
		class: PWAInstallationService,
		deps: [{ $ref: 'services.LocalStorageService' }],
	},
	'services.PWAWebPushService': {
		class: PWAWebPushService,
		deps: [
			{ $ref: 'services.APIService' },
			{ $ref: 'services.applicationService' },
			{ $ref: 'services.userService' },
		],
	},
};
