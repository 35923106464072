import * as Yup from 'yup';

export const DigitalSchema = Yup.object().shape({
	forename: Yup.string()
		.max(255)
		.required(),
	surname: Yup.string()
		.max(255)
		.required(),
	email: Yup.string()
		.max(255)
		.email()
		.required(),
});
