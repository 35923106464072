import React, { Fragment } from 'react';
import classnames from 'classnames';
import { Field } from 'formik';

import useStyles from '../../BuyModal.form.jss';

import { ErrorMessage } from 'components/_/form/error/Error';

export const Digital = () => {
	const classes = useStyles();

	return (
		<Fragment>
			<div className={classnames('pl-2 py-3', classes.label)}>Dane teleadresowe:</div>
			<div className="row">
				<div className="col-24 col-xs-12 mb-2">
					<Field
						name="address.forename"
						type="text"
						placeholder="Imię*"
						autoComplete="given-name"
						autoFocus
					/>
					<ErrorMessage name="address.forename" />
				</div>
				<div className="col-24 col-xs-12 mb-2">
					<Field name="address.surname" type="text" placeholder="Nazwisko*" autoComplete="family-name" />
					<ErrorMessage name="address.surname" />
				</div>
				<div className="col-24 mb-2">
					<Field name="address.email" type="text" placeholder="Adres e-mail*" autoComplete="email" />
					<ErrorMessage name="address.email" />
				</div>
			</div>
		</Fragment>
	);
};
