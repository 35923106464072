import StoreItemService from 'services/store/item/StoreItemService';

export default {
	'services.storeItemService': {
		class: StoreItemService,
		deps: [
			{ $ref: 'services.APIService' },
			{ $ref: 'services.tagService' },
			{ $ref: 'services.currencyService' },
			{ $ref: 'services.itemAssetService' },
		],
	},
};
