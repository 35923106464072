import BaseService from 'services/BaseService';

export default class ChallengeAwardedPlaceService extends BaseService {
	_type = 'challenge-awarded-place';
	_typeKey = 'awarded_place_type';

	constructor(APIService, challengeSubmissionService) {
		super();
		this.APIService = APIService;
		this._challengeSubmissionService = challengeSubmissionService;
	}

	/**
	 *
	 * @param {string|number} id
	 * @returns {Promise}
	 */
	fetchAwardedPlaces({ id } = {}) {
		return this.APIService.get(`v1/dizzy/challenge/${id}/awards`).then(({ awards }) =>
			awards.map(awardedPlace => this.toModel(awardedPlace))
		);
	}

	/**
	 *
	 * @param {string|number} id
	 * @returns {Promise}
	 */
	fetchWinners({ id } = {}) {
		//TODO: after backend fix - modify request address and models
		return this.APIService.get(`v1/dizzy/challenge/${id}/awards`).then(({ awards }) =>
			awards.map(awardedPlace =>
				this.toModel({
					...awardedPlace,
					submissions: awardedPlace.submissions.map(submission =>
						this._challengeSubmissionService.toModel(submission)
					),
				})
			)
		);
	}
}
