import { UserProfileFieldDefinition } from './UserProfileFieldDefinition';

export class CustomUserProfileFieldDefinition extends UserProfileFieldDefinition {
	constructor(definition) {
		super(definition);

		this._name = definition.name;
	}

	name() {
		return this._name;
	}
}
