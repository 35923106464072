import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import 'assets/scss/style.scss';
import 'assets/scss/pre-style.scss';

import { Provider } from 'react-hot-wire';

import 'modules/yup/yup';
import 'modules/react-intl/react-intl';

import wiring from 'modules/wiring';

// services:
import APIService from 'services/APIService';
import services from 'services';

// components:
import components from 'components';

import implementationComponents from 'views';

import colorsConfig from 'config/colors.config';
import ColorScheme from 'models/application/config/colorScheme/ColorScheme';

export default function init(overwrittenModules = {}) {
	let container = {
		window: {
			factory: () => window,
		},
		document: {
			factory: window => window.document,
			deps: [{ $ref: 'window' }],
		},
		'config.application': {
			factory: window =>
				window.appConfig || {
					BASE_URL: '/api/app.php',
					WORKFLOWS_BASE_URL: '/api/workflows/user',
					WEBSOCKET_SERVER_ADDRESS: '',
				},
			deps: [{ $ref: 'window' }],
		},
		'config.colors': {
			factory: () => colorsConfig(),
		},
		'services.APIService': {
			class: APIService,
			deps: [
				{ $ref: 'services.accessTokenService' },
				{ $ref: 'services.navigationService' },
				{ $ref: 'config.application' },
			],
		},
		...services,
		...components({
			components: implementationComponents,
		}),
		...overwrittenModules,
	};

	let resolved = wiring(container);

	window.$$resolved = resolved;
	window.swapColorScheme = colorScheme => {
		return resolved['services.colorService'].swapColorScheme(new ColorScheme(colorScheme));
	};

	const Routing = resolved['components.Routing'];
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
			},
		},
	});

	ReactDOM.render(
		<Provider services={resolved}>
			<QueryClientProvider client={queryClient}>
				<Routing />
			</QueryClientProvider>
		</Provider>,
		document.querySelector('.app')
	);
}
