import MenuPositionService from 'services/application/menu/position/MenuPositionService';
import subServices from 'services/application/menu/position/subServices';

export default {
	...subServices,
	'services.menuPositionService': {
		class: MenuPositionService,
		calls: [
			{
				name: 'registerSubService',
				args: ['image', { $ref: 'services.imageMenuPositionService' }],
			},
			{
				name: 'registerSubService',
				args: ['link', { $ref: 'services.linkMenuPositionService' }],
			},
			{
				name: 'registerSubService',
				args: ['logo', { $ref: 'services.logoMenuPositionService' }],
			},
			{
				name: 'registerSubService',
				args: ['more', { $ref: 'services.moreMenuPositionService' }],
			},
		],
	},
};
