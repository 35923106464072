/**
 * Class representing a submission ranking entry.
 * @abstract
 */
export class SubmissionRankingEntry {
	constructor(entry) {
		/**
		 * @type {Submission}
		 */
		this.submission = entry.submission;
		/**
		 * @type {string[]}
		 */
		this.scores = [entry.score];
		/**
		 *
		 * @type {string}
		 */
		this.currency = this.submission.challenge.likeType();
	}
}
