import ChallengeState from './ChallengeState';

export default class TargetChallengeState extends ChallengeState {
	constructor(state) {
		super(state);

		this._submissionCount = state.submission_count || 0;
	}

	submissionCount() {
		return this._submissionCount;
	}
}
