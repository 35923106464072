import TaskBaseService from 'services/task/TaskBaseService';
import CollectionMission from 'models/task/CollectionMission';
import MissionTaskState from 'models/task/state/MissionTaskState';
import TaskRepetitionState from 'models/task/repetitionState/TaskRepetitionState';

export default class CollectionMissionTaskService extends TaskBaseService {
	constructor(taskStateService, taskService) {
		super(taskStateService);

		this._taskService = taskService;
	}

	toModel(collectionMission) {
		return new CollectionMission({
			...collectionMission,
			state:
				collectionMission.state &&
				this._taskStateService.mapState(collectionMission.state, MissionTaskState, TaskRepetitionState),
			tasks: (collectionMission.tasks || []).map(({ task }) => this._taskService.toModel(task)),
		});
	}
}
