import Notification from 'views/application/realTimeNotifications/Notification';
import RealTimeNotifications from 'views/application/realTimeNotifications/RealTimeNotifications';
import buttons from 'views/application/realTimeNotifications/button';
import types from 'views/application/realTimeNotifications/type';

export default {
	'components.RealTimeNotifications.Notification': {
		factory: Notification,
		deps: [{ $ref: 'components.Currencies' }],
	},
	'components.RealTimeNotifications': {
		factory: RealTimeNotifications,
		deps: [{ $ref: 'components.base.RealTimeNotifications' }],
	},
	...buttons,
	...types,
};
