import React, { InputHTMLAttributes } from 'react';

import { Checkbox } from 'components/_/form/checkbox/Checkbox';
import { Markdown } from 'components/_/common/text/markdown/Markdown';
import { default as AgreementModel } from 'models/application/config/Agreement';

export type AgreementProps = InputHTMLAttributes<HTMLInputElement> & {
	agreement: AgreementModel;
	name?: string;
	error?: string | false;
	className?: string;
};

const Agreement = ({ name, agreement, className, ...props }: AgreementProps) => (
	<Checkbox name={name} className={className} contentClassName="d-flex" {...props}>
		<div className="ml-1">{agreement.required() && '*'}</div>
		<Markdown>{agreement.body()}</Markdown>
	</Checkbox>
);

export default Agreement;
