import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function() {
	return class ImageViewerEnhancer extends Component {
		_elementRefs = {};
		_scrollPositioningEnabled = false;

		constructor(...args) {
			super(...args);

			this.mobileSwipe = {};
			this.state = {
				currentImage: this.props.imageAssets ? this._getInitialImage() : null,
				leftArrowDisabled: true,
				rightArrowDisabled: true,
			};
		}

		componentWillUnmount() {
			document.removeEventListener('keyup', this.__arrowsEvent);
		}

		componentDidMount() {
			if (_.size(this.props.imageAssets) > 4) {
				this._arrowsVisibility();
			}
			document.addEventListener(
				'keyup',
				(this.__arrowsEvent = event => {
					this._arrowScrolling(event.keyCode === 37 ? -1 : event.keyCode === 39 ? 1 : null);
				})
			);
		}

		_setMainImage(index) {
			this.setState({
				currentImage: this.props.imageAssets[index].image,
			});
		}

		_getInitialImage() {
			return this.props.imageAssets[0].image;
		}

		_hasMultipleImages() {
			return _.size(this.props.imageAssets) > 1;
		}

		_setRef(name, element) {
			this._elementRefs[name] = element;
		}

		_arrowScrolling(direction) {
			const { scrollableArea } = this._elementRefs;
			const scrollPosition = scrollableArea.scrollLeft;
			const scrollStep = _.divide(scrollableArea.scrollWidth, _.size(this.props.imageAssets));

			if (!direction) {
				return;
			}

			const isAllowed = {
				left: direction < 0 && scrollPosition !== 0,
				right: direction > 0 && scrollPosition < scrollableArea.scrollWidth,
			};

			if (isAllowed.left || isAllowed.right) {
				scrollableArea.scrollLeft =
					Math.round(_.divide(scrollableArea.scrollLeft, scrollStep)) * scrollStep + direction * scrollStep;
				this._arrowsVisibility();
			}
		}

		_arrowsVisibility() {
			const { scrollableArea } = this._elementRefs;
			const scrollStep = _.divide(scrollableArea.scrollWidth, _.size(this.props.imageAssets));
			const maxScrollLeft =
				_.size(this.props.imageAssets) > 4 ? (_.size(this.props.imageAssets) - 4) * scrollStep : 0;

			this.setState({
				...this.state,
				leftArrowDisabled: scrollableArea.scrollLeft === 0 || _.size(this.props.imageAssets) < 5,
				rightArrowDisabled:
					scrollableArea.scrollLeft > maxScrollLeft - 10 || _.size(this.props.imageAssets) < 5,
			});
		}

		_initMobileSwipe(event) {
			const { scrollableArea } = this._elementRefs;
			this.mobileSwipe.childrenWidth = scrollableArea.querySelector('.imageViewer__miniatureWrapper').offsetWidth;
			this.mobileSwipe.currentLeft = scrollableArea.style.left ? _.parseInt(scrollableArea.style.left) : 0;
			this.mobileSwipe.startTouchPosition = event.changedTouches[0].clientX;
			this.mobileSwipe.maxLeft = _.multiply(
				_.subtract(_.size(Array.from(scrollableArea.querySelectorAll('.imageViewer__miniatureWrapper'))), 1),
				-100
			);
			scrollableArea.classList.remove('positioning');
		}

		_mobileSwipeMove(event) {
			const { scrollableArea } = this._elementRefs;
			const currentPosition = event.changedTouches[0].clientX;
			const difference = _.multiply(
				_.divide(
					Math.abs(this.mobileSwipe.startTouchPosition - currentPosition),
					this.mobileSwipe.childrenWidth
				),
				100
			);
			const direction = this.mobileSwipe.startTouchPosition < currentPosition;
			const left = direction
				? Math.floor(this.mobileSwipe.currentLeft + difference)
				: Math.floor(this.mobileSwipe.currentLeft - difference);

			if (left > 0 || left < this.mobileSwipe.maxLeft) {
				return;
			}

			scrollableArea.style.left = `${left}%`;
		}

		_mobileSwipeEnd() {
			const { scrollableArea } = this._elementRefs;
			const decimal = _.divide(Math.abs(_.parseInt(scrollableArea.style.left)), 100);
			const indicator = _.subtract(decimal, Math.floor(decimal)) > 0.5 ? 1 : 0;

			scrollableArea.classList.add('positioning');
			scrollableArea.style.left = indicator
				? `-${_.multiply(Math.round(decimal), 100)}%`
				: `-${_.multiply(Math.floor(decimal), 100)}%`;
		}

		_onSwipe() {
			if (window.innerWidth > ImageViewerEnhancer.MOBILE_WIDTH) {
				return;
			}

			this._scrollSetValidator();
		}

		_enableScrollPositioning() {
			this._scrollPositioningEnabled = true;
		}

		_scrollSetValidator() {
			if (!this._scrollPositioningEnabled) {
				_.delay(() => this._scrollSetValidator(), 100);
			} else {
				_.delay(() => this._scrollPosition(), 500);
			}
		}

		_scrollPosition() {
			const { scrollableArea } = this._elementRefs;
			const scrollStep = _.divide(scrollableArea.scrollWidth, _.size(this.props.imageAssets));
			const scrollLeft = scrollableArea.scrollLeft;
			const currentStep = _.divide(scrollLeft, scrollStep);

			const targetScrollLeft =
				_.subtract(currentStep, Math.floor(currentStep)) > 0.5
					? Math.round(currentStep) * scrollStep
					: Math.floor(currentStep) * scrollStep;

			this._scrollPositioningEnabled = false;
			window.cancelAnimationFrame(this._requestAnimationFrame);

			this._smoothScrollAnimate(targetScrollLeft);
		}

		_smoothScrollAnimate(targetScrollLeft) {
			const { scrollableArea } = this._elementRefs;
			const scrollLeft = scrollableArea.scrollLeft;

			if (scrollLeft === targetScrollLeft) {
				window.cancelAnimationFrame(this._requestAnimationFrame);

				return;
			}

			if (scrollLeft < targetScrollLeft) {
				let currentTarget = scrollLeft + 2;

				scrollableArea.scrollLeft = currentTarget >= targetScrollLeft ? targetScrollLeft : currentTarget;
			} else {
				let currentTarget = scrollLeft - 2;

				scrollableArea.scrollLeft = currentTarget <= targetScrollLeft ? targetScrollLeft : currentTarget;
			}

			this._requestAnimationFrame = window.requestAnimationFrame(() =>
				this._smoothScrollAnimate(targetScrollLeft)
			);
		}

		static MOBILE_WIDTH = 640;
		static propTypes = {
			imageAssets: PropTypes.array,
		};
	};
}
