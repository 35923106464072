import React from 'react';
import classnames from 'classnames';

export default ({ userCanJudgement, userLiked, likeCount, challenge, onLike }) => (
	<article
		className={classnames('rateSection__hearts d-flex align-items-center', {
			selected: userLiked,
			edit: userCanJudgement,
		})}
		onClick={onLike}
	>
		<i
			className={classnames('fas', {
				[`fa-${challenge.likeType()}`]: true,
			})}
		/>
		<p className="rateSection__number">{likeCount}</p>
	</article>
);
