import React from 'react';
import images from 'assets/images';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Contact from 'models/onboarding/contact/Contact';

export type ContactsProps = InjectedIntlProps & {
	contactList: Contact[];
};

const Contacts = ({ contactList, intl }: ContactsProps) => {
	const EmailIcon = images.portal.email;
	const PhoneIcon = images.portal.phone;

	return (
		<div className="row my-3">
			{!!contactList.length &&
				contactList.map(contact => (
					<div key={contact.userId} className="col-12 col-sm-6 col-md-12 col-lg-12 col-xl-8 mb-4 mb-sm-5">
						<div className="h-100">
							<div className="tile contacts__tile rounded h-100">
								<div className="tile__front">
									<div className="p-3 pb-4 p-sm-4 d-flex flex-column justify-content-between h-100">
										<Link to={`/user/${contact.userId}/details`}>
											<div
												className="tile__image mx-auto mb-3 mb-sm-4 rounded-circle background-image size--cover"
												style={{
													backgroundImage: `url(${contact.avatar ||
														images.default.avatar.user})`,
												}}
											/>

											<p className="fs-5 font-weight-bold text-center">
												{contact.forename}
												<br />
												{contact.surname}
											</p>
										</Link>
										<div className="mt-3">
											<p className="fs-4 text-center mb-2 tile__role">
												{intl.formatMessage({ id: 'portal.contacts.roleTitle' })}
											</p>
											<p className="fs-4 text-center">
												{contact.role
													.map(role =>
														role === 'manager'
															? intl.formatMessage({
																	id: `portal.contacts.role.${role}`,
															  })
															: role
													)
													.join(', ')}
											</p>
										</div>
										{!!(contact.email || contact.phone) && (
											<div className="d-flex justify-content-center mt-4">
												{!!contact.phone && (
													<span className="general-tooltip mr-3">
														<a href={`tel:${contact.phone}`}>
															<PhoneIcon className="tile__contactIcon rounded-circle " />
														</a>
														<span className="tooltip-text--top">
															{contact.phone}
															<i></i>
														</span>
													</span>
												)}
												{!!contact.email && (
													<span className="general-tooltip">
														<a href={`mailto:${contact.email}`}>
															<EmailIcon className="tile__contactIcon rounded-circle" />
														</a>
														<span className="tooltip-text--top">
															{contact.email}
															<i></i>
														</span>
													</span>
												)}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
		</div>
	);
};

export default injectIntl(Contacts);
