import React from 'react';
import { wire } from 'react-hot-wire';
import { FormattedMessage } from 'react-intl';

import View from 'components/_/common/view/list/ListView';

import FAQ from './FAQ';

function FAQView(props) {
	const i18nKey = 'faq';
	const newsService = props['services.newsService'];

	return (
		<View
			name="faq"
			i18nKey="faq"
			config={{
				search: false,
			}}
			tabs={{
				url: '/game/faq/${tab}', // eslint-disable-line no-template-curly-in-string
				list: [
					{
						tab: 'all',
						content: <FormattedMessage id={`${i18nKey}.all-tab`} />,
					},
				],
			}}
		>
			<FAQ i18nKey={i18nKey} newsService={newsService} />
		</View>
	);
}

export default wire(['services.newsService'], FAQView);
