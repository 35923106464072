import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';

import TeamProfileSettings from './Settings.template';

class TeamProfileSettingsComponent extends PureComponent {
	render() {
		return <TeamProfileSettings {...this.props} />;
	}
}

export default wire(['services.teamService'], TeamProfileSettingsComponent);
