export default class GraphService {
	constructor(APIService) {
		this.APIService = APIService;
	}

	/**
	 *
	 * @param {string|number} id
	 * @returns {Promise}
	 */
	fetchDetails({ id } = {}) {
		return this.APIService.get(`v1/graph/${id}`);
	}
}
