import Question from 'models/task/question/Question';

/**
 * Class representing a word based question.
 */
export default class WordBasedQuestion extends Question {
	constructor(question = {}) {
		super(question);

		this._words = question.words || [];
	}

	words() {
		let highlightFound = false;

		return this._words.map(word => {
			if (word.highlight && !highlightFound) {
				highlightFound = true;

				return {
					...word,
					firstHighlight: word.highlight,
				};
			}

			return word;
		});
	}
}
