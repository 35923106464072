import UserProfile from 'models/user/UserProfile';

import Level from './Level';

/**
 * Class representing a user.
 */
export default class User {
	/**
	 * @param {Object} user
	 */
	constructor(user) {
		if (!user) {
			return;
		}

		/**
		 * @type {string}
		 */
		this.id = user.id;
		/**
		 * @type {UserProfile}
		 */
		this.userProfile = user.user_profile && new UserProfile(user.user_profile);
		/**
		 * @type {boolean}
		 */
		this.hasSeenWelcomePage = !!user.has_seen_welcome_page;
		/**
		 * @type {string}
		 */
		this._locale = user.locale;
		this._wallet = user.wallet;
		this._level = user.level && new Level(user.level);
	}

	locale() {
		return this._locale;
	}

	wallet() {
		return this._wallet;
	}

	level() {
		return this._level;
	}
}
