import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import map from 'lodash/map';

import Mission from 'models/task/Mission';
import TaskState from 'models/task/state/TaskState';

import { Currency } from 'components/_/common/currency/Currency';
import { Button } from '../../../../../_/common/button/Button';

export const CollectionTiles = ({ task }) => {
	return (
		<div className="collectionMission__list flex-grow-1 my-4 my-sm-0">
			<div className="row">
				{task.tasks().map(_task => (
					<div
						key={_task.id()}
						className="col-12 col-xs-8 col-sm-24 col-md-12 col-lg-6 col-xxl-4 mb-4 mb-sm-5"
					>
						<div className="mx-0 mx-sm-3">
							<NavLink
								to={`/${_task instanceof Mission ? 'mission' : 'task'}/${_task.id()}/details`}
								onClick={event =>
									_task.state().status() === TaskState.STATUS_UNAVAILABLE && event.preventDefault()
								}
								className={classnames('position-relative d-block', {
									[`task--${_task.state().status()}`]: true,
								})}
							>
								<div className="task__status rounded-circle" />
								<div
									className={classnames('tile collectionMission__tile rounded', {
										disable: !_task.state().hasStatus('available', 'retention', 'periodicity'),
									})}
								>
									<div className="tile__front fs-5">
										<div
											className="tile__image background-image size--cover rounded-top"
											style={{
												backgroundImage: `url(${_task.listImage()})`,
											}}
										/>
										<p className="px-3 py-3 py-sm-4 text-center">{_task.name()}</p>
									</div>
									<div className="tile__back d-none d-sm-block">
										<div className="d-flex flex-column h-100">
											<div className="d-flex flex-column justify-content-between px-4 pt-4 pb-3 flex-grow-1">
												<p className="task-name fs-5 text-center mb-4">{_task.name()}</p>
												{_task.hasCompletionEarnings() && (
													<div>
														<p className="task-earnings text-center mb-3">
															<FormattedMessage
																id={`task-details.collection-mission.content.gain-for-complete-${
																	_task instanceof Mission ? 'mission' : 'task'
																}`}
															/>
														</p>
														<div className="d-flex flex-wrap justify-content-center fs-4">
															{map(
																_task.earningsInformation().completion.values(),
																currency => (
																	<Currency
																		key={currency.codeName()}
																		currency={currency.codeName()}
																		value={currency.value()}
																		className="mr-2"
																	/>
																)
															)}
														</div>
													</div>
												)}
											</div>
											{!_task.state().hasStatus('completed') && (
												<div className="tile__button fs-5 text-center px-4 py-3">
													<Button color="interactive">
														{_task.state().hasStatus('retention') ? (
															<FormattedMessage id="task-details.collection-mission.button.repeat" />
														) : (
															<FormattedMessage id="task-details.collection-mission.button.do" />
														)}
													</Button>
												</div>
											)}
										</div>
									</div>
								</div>
							</NavLink>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
