import RGB, { RGBDTO } from 'models/application/color/RGB';

export interface SingleColorSchemeDTO {
	background: RGBDTO;
	foreground: RGBDTO;
}

export default class SingleColorScheme {
	private _background: RGB;
	private _foreground: RGB;

	constructor(scheme: SingleColorSchemeDTO) {
		this._background = new RGB(scheme.background);
		this._foreground = new RGB(scheme.foreground);
	}

	background() {
		return this._background;
	}

	foreground() {
		return this._foreground;
	}
}
