import CSSColorModTransformAdapter from 'services/application/CSSTransform/adapters/CSSColorModTransformAdapter';
import CSSVariableTransformAdapter from 'services/application/CSSTransform/adapters/CSSVariableTransformAdapter';

export default {
	'services.CSSTransformService.adapter.CSSColorModTransformAdapter': {
		class: CSSColorModTransformAdapter,
	},
	'services.CSSTransformService.adapter.CSSVariableTransformAdapter': {
		class: CSSVariableTransformAdapter,
	},
};
