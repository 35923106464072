import MenuPositionBaseService from 'services/application/menu/position/MenuPositionBaseService';
import LogoMenuPosition from 'models/application/menu/position/types/Logo';

export default class LogoMenuPositionService extends MenuPositionBaseService {
	toModel({ anchor, ...rest }) {
		return new LogoMenuPosition({
			anchor: anchor && this._anchorService.toModel(anchor),
			...rest,
		});
	}
}
