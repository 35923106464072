import HeaderFormBlockService from 'services/common/form/formContentBlock/HeaderFormBlockService';
import ImageFormBlockService from 'services/common/form/formContentBlock/ImageFormBlockService';
import TextFormBlockService from 'services/common/form/formContentBlock/TextFormBlockService';

export default {
	'services.headerFormBlockService': {
		class: HeaderFormBlockService,
	},
	'services.imageFormBlockService': {
		class: ImageFormBlockService,
	},
	'services.textFormBlockService': {
		class: TextFormBlockService,
	},
};
