import blocks from 'components/task/types/content/blocks';
import ContentTask from 'components/task/types/content/ContentTask';

export default {
	...blocks,
	'components.task.Content': {
		factory: ContentTask,
		deps: [
			{ $ref: 'components.task.layout.BaseLayout' },
			{
				attributes: {
					$ref: 'components.task.Content.block.Attributes',
				},
				button: { $ref: 'components.task.Content.block.Button' },
				header: { $ref: 'components.task.Content.block.Header' },
				image: { $ref: 'components.task.Content.block.Image' },
				text: { $ref: 'components.task.Content.block.Text' },
			},
		],
	},
};
