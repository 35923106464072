import React from 'react';
import { FormattedMessage } from 'react-intl';

export default ({ /*integration, */ login, action }) => (
	<div className="integration integration__oauth--facebook">
		<button onClick={login}>
			<FormattedMessage id={`auth.${action}.integration.oauth.facebook`} tagName={React.Fragment} />
		</button>
	</div>
);
