import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import CreateSubmission from '../../Create.template';

export default class SubmissionTypeSelectionComponent extends PureComponent {
	render() {
		const { intl, closeModal, activateModal } = this.props;

		return (
			<CreateSubmission
				closeModal={closeModal}
				title={intl.formatMessage({
					id: 'create-submission.submission-type-selection.title',
				})}
				subtitle={intl.formatMessage({
					id: 'create-submission.submission-type-selection.subtitle',
				})}
			>
				<ul className="submission-types d-flex flex-wrap justify-content-center">
					{this.props.challenge.submissionTypes().map(type => {
						if (!SubmissionTypeSelectionComponent.types[type]) {
							return null;
						}

						const { icon, help } = SubmissionTypeSelectionComponent.types[type];

						return (
							<li
								className="d-flex flex-column p-3 text-center mb-3 mb-sm-0"
								onClick={() => activateModal(type)}
								key={type}
							>
								<i className={`fa ${icon}`} />
								<button className="mt-3 btn__basic">
									<FormattedMessage id={`create-submission.submission-type-selection.type.${type}`} />
								</button>
								{help && <p className="mt-2">{help}</p>}
							</li>
						);
					})}
				</ul>
			</CreateSubmission>
		);
	}

	static types = {
		text: {
			icon: 'fa-file-text-o',
		},
		image: {
			icon: 'fa-file-image-o',
			help: 'jpg, gif, png',
		},
	};
}
