import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Formik } from 'formik';

import { Markdown } from 'components/_/common/text/markdown/Markdown';

export default function(Layout, ScalableIframe, MatchingImageType) {
	return class DocumentTask extends PureComponent {
		render() {
			const { task, goBack } = this.props;

			return (
				<Formik
					initialValues={{}}
					onReset={() => this.props.task.reset()}
					onSubmit={(values, actions) => this.props.process({}).then(() => actions.setSubmitting(false))}
				>
					{({ handleReset, isSubmitting }) => (
						<Form className="form d-flex flex-grow-1">
							<Layout
								task={task}
								actions={{
									submit: {
										buttonText: 'task-details.common.button.confirm',
									},
									goBack,
									tryAgain: handleReset,
									isSubmitting,
								}}
							>
								<div className="wrapper--row el--grow standardPreview__columnWrapper overflow--auto noInstructionQuestion documentTask">
									<div className="wrapper--col standardPreview__column standardPreview__column--left el--shrink">
										<div className="wrapper--col el--grow el--shrink">
											<div className="fs-6 standardText questionBody el--shrink">
												<Markdown>{task.description()}</Markdown>
											</div>
											{task.image() && (
												<MatchingImageType
													classes="el--grow rounded el--shrink"
													src={task.image()}
												/>
											)}
										</div>
									</div>
									<div className="d-flex standardPreview__column documentTask--rightColumn standardPreview__column--middle wrapper--col justify--center">
										<div className="wrapper--col el--grow justify--between">
											<ScalableIframe src={`${task.document()}#view=FitH`} />
											{task.downloadable() && (
												<div className="wrapper--row el--shrink">
													<a href={task.document()} className="btn__basic" download>
														<FormattedMessage
															id={'task-details.document.button.download-pdf'}
														/>
													</a>
												</div>
											)}
										</div>
									</div>
								</div>
							</Layout>
						</Form>
					)}
				</Formik>
			);
		}
	};
}
