import * as serviceWorker from '../../../serviceWorker';

export class MasterService {
	constructor(
		logService,
		applicationService,
		userService,
		colorService,
		metadataConfigurationService,
		PWAWebPushService,
		trackerService,
		i18nService,
		browserHistoryService,
		authService,
		userProfileService,
		authenticationTrackerService,
		navigationService,
		taskService
	) {
		this._logService = logService;
		this._applicationService = applicationService;
		this._userService = userService;
		this._colorService = colorService;
		this._metadataConfigurationService = metadataConfigurationService;
		this._PWAWebPushService = PWAWebPushService;
		this._trackerService = trackerService;
		this._i18nService = i18nService;
		this._browserHistoryService = browserHistoryService;
		this._authService = authService;
		this._userProfileService = userProfileService;
		this._authenticationTrackerService = authenticationTrackerService;
		this._navigationService = navigationService;
		this._taskService = taskService;
	}

	config() {
		// request: /application/config
		return Promise.all([
			this._applicationService.fetchConfig().then(() => {
				this._colorService.init();
				this._metadataConfigurationService.init();
				this._i18nService.init();
			}),
			this._i18nService.fetchTranslation(),
		]);
	}

	init() {
		return (
			// request: /user
			this._userService
				.fetchDetails()
				.then(async () => {
					let registration = null;

					try {
						registration = await serviceWorker.register();
					} catch (error) {
						this._logService.error(error);
					}

					this._PWAWebPushService.setRegistration(registration);
				})
				.then(() => {
					//TODO (poc: rootsher, 19.05.2021): services.integrationService.init()
					this._trackerService.init();
					// i18n init again after fetch user locale.....
					this._i18nService.init();
					this._browserHistoryService.listen(() => this._trackerService.track('pageview'));
				})
				/*.then(() => {
				//TODO (poc: rootsher, 19.05.2021): moved palette to correct component
				// To include palette - insert "palette" parameter in query string.
				if (this._colorService.isPaletteEnabled()) {
					const palette = document.createElement('div');

					palette.setAttribute('class', 'palette');
					document.body.appendChild(palette);

					ReactDOM.render(<Palette />, palette);
				}
				});*/
				.then(() => {
					const promise = Promise.resolve();

					if (this._applicationService.settings().anonymousUsersEnabled() && !this._userService.isLogged()) {
						return (
							promise
								.then(() => this._authService.anonymous())
								// after anonymous request must be re-fetch /v1/user details.....
								.then(() => this._userService.fetchDetails())
								// i18n init again again after re-fetch user locale.....
								.then(() => this._i18nService.init())
								//TODO (poc: rootsher, 18.06.2021): do not duplicate with logged user
								.then(() => this._userProfileService.fetchDetails())
								.then(() => this._applicationService.warmup())
								.then(() => this._applicationService.fetchMenu())
								.then(() => this._taskService.fetchSuggestedList())
								.then(() => this._authenticationTrackerService.init())
								.then(() => {
									this._PWAWebPushService.subscribe();
								})
								.then(() => this._redirect())
						);
					} else if (this._userService.isLogged()) {
						return promise
							.then(() => this._userProfileService.fetchDetails())
							.then(() => this._applicationService.warmup())
							.then(() => this._applicationService.fetchMenu())
							.then(() => this._taskService.fetchSuggestedList())
							.then(() => this._authenticationTrackerService.init())
							.then(() => {
								this._PWAWebPushService.subscribe();
							})
							.then(() => this._redirect());
					} else {
						this._navigationService.goTo('/auth/login');
					}
				})
		);
	}

	mainPage() {
		const menu = this._applicationService.menu();
		let path = '/mission/list';

		// the previous location cannot be the same as the main page url (when you go to "/")
		if (menu.mainPage() && this._browserHistoryService.previousLocation() !== menu.mainPage().url()) {
			path = menu.mainPage().url();
		}

		return path;
	}

	_redirect() {
		const location = this._navigationService.currentLocation().pathname;
		const menu = this._applicationService.menu();

		let path = null;

		return new Promise(async resolve => {
			if (this._userService.user() && !this._userService.user().hasSeenWelcomePage && menu.welcomePage()) {
				await this._userService.markWelcomePageAsSeen();

				path = menu.welcomePage();
			} else if (location === '/') {
				path = this.mainPage();
			}

			if (path) {
				this._navigationService.goTo(path);
			}

			resolve();
		});
	}
}
