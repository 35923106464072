import React, { FC, useEffect, useState } from 'react';
import { wire } from 'react-hot-wire';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';

import InputSearch, { InputSearchItem, InputSearchProps } from 'components/_/form/input-search/input-search';
import TeamService from 'services/team/TeamService';

export type SelectTeamProps = Omit<InputSearchProps, 'items'> & {
	containerId?: string | number;
	selectedTeam?: InputSearchItem;
	onlyOpen?: boolean;
};

type SelectContainerWiredProps = SelectTeamProps & { 'services.teamService': TeamService };

const SelectTeam: FC<SelectContainerWiredProps> = ({
	containerId,
	search,
	onSelect,
	selectedTeam,
	onlyOpen,
	'services.teamService': teamService,
	...props
}) => {
	const [data, setData] = useState<{ value: string; content: string }[] | undefined>();
	const [debouncedSearch] = useDebounce(search, 200);
	const query = useQuery(['teams', containerId, debouncedSearch], () => {
		if (debouncedSearch && debouncedSearch === selectedTeam?.content) {
			return [];
		}

		return teamService
			.searchTeams({
				id: containerId,
				search: debouncedSearch,
				open: onlyOpen,
			})
			.then(list =>
				list
					.map(team => ({ value: team.id, content: team.name }))
					.sort((a, b) => a.content.localeCompare(b.content))
			);
	});

	useEffect(() => setData(query.data), [query.data]);

	return (
		<InputSearch
			items={data || []}
			search={search}
			onSelect={(...args) => {
				setData([]);
				onSelect && onSelect(...args);
			}}
			{...props}
		/>
	);
};

export default wire<SelectContainerWiredProps, SelectTeamProps>(['services.teamService'], SelectTeam);
