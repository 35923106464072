import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';

import Score from './Score.template';

export class ScoreComponent extends PureComponent {
	constructor(...args) {
		super(...args);

		this._userProfileService = this.props['services.userProfileService'];
		this._loggedUser = this._userProfileService.user();

		const {
			userJudgement: { scored },
			submission,
			submission: { challenge },
		} = this.props;
		let userCanJudgement = !scored;
		let userScore = userCanJudgement ? 0 : scored;

		const averageScore = submission.averageScore();

		// anonymous user can't vote for submission
		if (this._userProfileService.isAnonymous()) {
			userCanJudgement = true;
		} else {
			const submissionUserProfile = submission.author();
			const loggedUserProfile = this._loggedUser.userProfile;

			// user can't vote for his submission
			if (submissionUserProfile.id() === loggedUserProfile.id()) {
				userCanJudgement = false;
			}
		}

		if (!challenge.canJudgement()) {
			userCanJudgement = false;
		}

		this.state = {
			userCanJudgement,
			userScore,
			averageScore,
		};
	}

	render() {
		const definitions = this.props['services.colorService'].definitions();

		return (
			<Score
				{...this.state}
				color={{
					mobile: {
						color1: definitions['color--content--foreground'].alpha(30),
						color2: definitions['color--interactive--background'].rgb().toString(),
					},
					desktop: {
						color1: definitions['color--primary--foreground'].alpha(30),
						color2: definitions['color--interactive--background'].rgb().toString(),
					},
				}}
				onScore={score => this._onScore(score)}
			/>
		);
	}

	_onScore(score) {
		// if anonymous click star - open access popup
		if (this._userProfileService.isAnonymous()) {
			this.props['services.accessPopupService'].open();

			return;
		}

		if (!this.state.userCanJudgement) {
			return;
		}

		this.setState({ userScore: score, userCanJudgement: false });

		this.props['services.challengeSubmissionJudgeService']
			.score({
				id: this.props.submission.id(),
				score,
			})
			.then(({ averageScore }) => this.setState({ averageScore }));
	}
}

export default wire(
	[
		'services.challengeSubmissionJudgeService',
		'services.userProfileService',
		'services.accessPopupService',
		'services.colorService',
	],
	ScoreComponent
);
