export interface CurrencyDTO {
	code_name: string;
	name: string;
	description: string;
	image: string;
	value: number;
}

export default class Currency {
	private _codeName: string;
	private _name: string;
	private _description: string;
	private _icon: string;
	private _value: number;

	constructor(currency: CurrencyDTO) {
		this._codeName = currency.code_name;
		this._name = currency.name;
		this._description = currency.description;
		this._icon = currency.image;
		this._value = currency.value;
	}

	codeName() {
		return this._codeName;
	}

	name() {
		return this._name;
	}

	description() {
		return this._description;
	}

	icon() {
		return this._icon;
	}

	value() {
		return this._value;
	}

	absValue() {
		return Math.abs(this._value);
	}
}
