import Integration from './Integration';

export default class ImportedIdIntegration extends Integration {
	constructor(integration) {
		super(integration);

		/**
		 * @type {boolean}
		 */
		this.registrationEnabled = false;
		/**
		 * @type {string|null}
		 */
		this.authenticationTitle = integration.authentication_title;
		/**
		 * @type {string|null}
		 */
		this.loginFieldPlaceholder = integration.login_field_placeholder;
	}
}
