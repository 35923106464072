export interface LocalesDTO {
	available: string[];
	default: string;
}

export default class Locales {
	private _available: string[];
	private _default: string;

	constructor(locales: LocalesDTO) {
		this._available = locales.available || [];
		this._default = locales.default || Locales.DEFAULT_LOCALE;
	}

	available() {
		return this._available;
	}

	defaultLocale() {
		return this._default;
	}

	static DEFAULT_LOCALE = 'pl';
}
