import React, { createContext, Fragment, useContext, useLayoutEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import useStyles from './react-sticky.jss';

const defaultValue = {
	scrollTop: 0,
	top: 0,
};

const Context = createContext(defaultValue);

export function Container({ className, children }) {
	const [value, setValue] = useState(defaultValue);
	const classes = useStyles();

	function onScroll({ target }) {
		setValue({
			scrollTop: target.scrollTop,
			top: target.getBoundingClientRect().top,
		});
	}

	return (
		<Context.Provider value={value}>
			<div className={classnames(className, classes.container)} onScroll={onScroll}>
				{children}
			</div>
		</Context.Provider>
	);
}

export function Sticky({ children }) {
	const [isSticky, setSticky] = useState(false);
	const [stickyHeight, setStickyHeight] = useState(0);
	const [cachedScrollTop, setCachedScrollTop] = useState(0);
	const stickyRef = useRef(null);
	const container = useContext(Context);
	const classes = useStyles({
		isSticky,
		top: container.top,
	});

	useLayoutEffect(() => {
		const { scrollTop, top } = container;
		const { height, top: stickyTop } = stickyRef.current.getBoundingClientRect();

		setStickyHeight(height);

		if (isSticky && scrollTop <= cachedScrollTop) {
			setSticky(false);
		} else if (stickyTop < top) {
			setCachedScrollTop(scrollTop);
			setSticky(true);
		}
	}, [container]);

	return (
		<Fragment>
			<div style={{ height: `${isSticky ? stickyHeight : 0}px` }} />
			<div ref={stickyRef} className={classes.sticky}>
				{children({ isSticky })}
			</div>
		</Fragment>
	);
}
