import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Media from 'react-media';

import { Header } from 'components/_/common/view/item/ItemView';
import { NavTabList } from 'components/commons/_/tabs/NavTabList';

import ChallengeProgressStatus from './KPIProgressStatus';
import { KPIChallengeDetails } from './tabs/KIPChallengeDetails';
import { RankingPositionList } from 'components/ranking/_/RankingPositionList';

export default function(ChallengePrizeList, ReactMarkdown, Image, navigationService, rankingService) {
	return class KPIChallenge extends PureComponent {
		UNSAFE_componentWillMount() {
			navigationService.replace(`/challenge/${this.props.challenge.id}/ranking`);
		}

		render() {
			const { challenge } = this.props;

			return (
				<div className="view__challengeDetails view__challengeDetails--kpi el--grow">
					<div className="standardPreview wrapper--col challengeDetails">
						<Header title={challenge.title()} goBack={{ defaultLocation: '/challenge/list/open' }}>
							<div className="hidden--mobile">
								<ChallengeProgressStatus challenge={challenge} />
							</div>
						</Header>
						<div className="wrapper--col el--grow overflow--auto">
							<div className="challengeDetails__mainImage el--shrink overflow--hidden heightToInclude">
								<Image src={challenge.image()} />
							</div>
							<div className="d-flex px-0 px-sm-4 flex-column flex-md-row">
								<div className="challengeDetails__descriptionSection el--shrink heightToInclude d-none d-md-block">
									<KPIChallengeDetails challenge={challenge} ReactMarkdown={ReactMarkdown} />
								</div>
								<div className="challengeDetails__contentSection el--grow wrapper--col">
									<Media query={{ maxWidth: 768 }}>
										{matches =>
											matches ? (
												<NavTabList
													className="m-3"
													url="/challenge/${id}/${tab}" // eslint-disable-line no-template-curly-in-string
													list={[
														challenge.ranking() && {
															id: challenge.id,
															tab: 'ranking',
															content: (
																<FormattedMessage id="challenge-details.kpi.menu-tabs.ranking" />
															),
														},
														{
															id: challenge.id,
															tab: 'details',
															content: (
																<FormattedMessage id="challenge-details.kpi.menu-tabs.details" />
															),
														},
														challenge.hasConfiguredAwardedPlaces() && {
															id: challenge.id,
															tab: 'prizes',
															content: (
																<FormattedMessage id="challenge-details.kpi.menu-tabs.prizes" />
															),
														},
													].filter(item => item)}
												/>
											) : (
												<NavTabList
													className="m-3"
													url="/challenge/${id}/${tab}" // eslint-disable-line no-template-curly-in-string
													list={[
														challenge.ranking() && {
															id: challenge.id,
															tab: 'ranking',
															content: (
																<FormattedMessage id="challenge-details.kpi.menu-tabs.ranking" />
															),
														},
														challenge.hasConfiguredAwardedPlaces() && {
															id: challenge.id,
															tab: 'prizes',
															content: (
																<FormattedMessage id="challenge-details.kpi.menu-tabs.prizes" />
															),
														},
													].filter(item => item)}
												/>
											)
										}
									</Media>
									<hr className="mx-3" />
									<div className="px-4 px-sm-0 py-sm-4">
										{challenge.ranking() && (
											<Route
												exact
												path={`/challenge/${challenge.id}/ranking`}
												render={() => (
													<RankingPositionList
														rankingId={challenge.ranking().id}
														rankingService={rankingService}
														showSearch
													/>
												)}
											/>
										)}
										<Media query={{ maxWidth: 768 }}>
											{matches =>
												matches ? (
													<Route
														exact
														path={`/challenge/${challenge.id}/details`}
														render={() => (
															<KPIChallengeDetails
																challenge={challenge}
																ReactMarkdown={ReactMarkdown}
															/>
														)}
													/>
												) : null
											}
										</Media>
										{challenge.hasConfiguredAwardedPlaces() && (
											<Route
												exact
												path={`/challenge/${challenge.id}/prizes`}
												render={() => <ChallengePrizeList challenge={challenge} />}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="challengeDetails__footer visible--mobile wrapper--row justify--between align--center el--shrink">
							<div className="progressBarWrapper">
								<div className="progressBar">
									<div
										className="progressBar__content"
										style={{
											width: `${challenge.status().progress}%`,
										}}
									/>
								</div>
								<div className="progressBar__status">
									<div className="standardText standardText--noMargin">
										{challenge.status().remainingTime ? (
											<div>
												<FormattedMessage
													id="challenge-details.kpi.content.remains"
													values={{
														'remaining-time': (
															<strong>{` ${challenge.status().remainingTime}`}</strong>
														),
													}}
												/>
											</div>
										) : (
											<strong>
												<FormattedMessage
													id={`challenge-list.content.challenge-status.${
														challenge.status().type
													}`}
												/>
											</strong>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	};
}
