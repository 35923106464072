import TaskBaseService from 'services/task/TaskBaseService';
import MapTask from 'models/task/MapTask';
import MissionTaskState from 'models/task/state/MissionTaskState';
import TaskRepetitionState from 'models/task/repetitionState/TaskRepetitionState';

export default class MapTaskService extends TaskBaseService {
	toModel(task) {
		return new MapTask({
			...task,
			state: task.state && this._taskStateService.mapState(task.state, MissionTaskState, TaskRepetitionState),
		});
	}
}
