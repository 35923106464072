export class PWAInstallationService {
	static MAX_INSTALLATION_PROMPT_COUNT = 3;

	constructor(LocalStorageService) {
		this._localStorageService = new LocalStorageService({
			key: 'PWA_installation_count',
			initValue: 0,
		});
		this._deferredPrompt = null;
		this._eventHandlers = {};

		this._onBeforeInstallPrompt();
		this._onAppInstalled();
	}

	canInstall() {
		const value = Number(this._localStorageService.value());

		if (this.isInstalled()) {
			return false;
		}

		if (value < PWAInstallationService.MAX_INSTALLATION_PROMPT_COUNT) {
			this._localStorageService.setValue(value + 1);

			return true;
		}

		return false;
	}

	prompt() {
		// Update UI notify the user they can install the PWA
		this._deferredPrompt.prompt();
	}

	registerEventHandler(event, handler) {
		if (!this._eventHandlers[event]) {
			this._eventHandlers[event] = [];
		}

		this._eventHandlers[event].push(handler);
	}

	isInstalled() {
		return (
			!!(window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) ||
			navigator.standalone ||
			!this._deferredPrompt
		);
	}

	_fireEventHandlers(eventName, result) {
		const handlers = this._eventHandlers[eventName] || [];

		handlers.forEach(handler => handler(result));
	}

	_onBeforeInstallPrompt() {
		window.addEventListener('beforeinstallprompt', event => {
			// Prevent the mini-infoBar from appearing on mobile
			event.preventDefault();
			// Stash the event so it can be triggered later.
			this._deferredPrompt = event;

			this._deferredPrompt.userChoice.then(result => {
				this._fireEventHandlers('installation-result', result.outcome);
				this._deferredPrompt = null;
			});
		});
	}

	_onAppInstalled() {
		window.addEventListener('appinstalled', event => {
			this._fireEventHandlers('installation-result', 'accepted');
		});
	}
}
