export abstract class ContainerGroup implements ContainerGroupInterface {
	public id: string;
	public name: string;
	public containerGroupType: ContainerGroupType;

	constructor(containerGroup: ContainerGroupDTO) {
		this.id = containerGroup.id;
		this.name = containerGroup.name;
		this.containerGroupType = containerGroup.container_group_type;
	}
}

export enum ContainerGroupType {
	TeamContainerGroup = 'teamContainerGroup',
	UserContainerGroup = 'userContainerGroup',
}

export interface ContainerGroupInterface {
	id: string;
	name: string;
	containerGroupType: ContainerGroupType;
}

export interface ContainerGroupDTO {
	id: string;
	name: string;
	container_group_type: ContainerGroupType;
}
