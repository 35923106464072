import TaskBaseService from 'services/task/TaskBaseService';
import DocumentTask from 'models/task/document/DocumentTask';

export default class DocumentTaskService extends TaskBaseService {
	toModel(task) {
		return new DocumentTask({
			...task,
			state: this._mapState(task),
		});
	}
}
