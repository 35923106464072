import React, { PureComponent } from 'react';

export default function(Layout) {
	return class BaseContentTask extends PureComponent {
		render() {
			const { task, goBack } = this.props;

			return (
				<Layout
					task={task}
					options={{ hideFooterBeforeSubmit: true }}
					actions={{
						submit: {},
						goBack,
					}}
					contentProps={{ noPadding: true }}
				>
					<iframe width="100%" height="100%" src={task.linkUrl()} frameBorder="0" title="embeddedAnalytics" />
				</Layout>
			);
		}
	};
}
