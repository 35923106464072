import { some } from 'lodash';

import TaskRepetitionState from 'models/task/repetitionState/TaskRepetitionState';

/**
 * @extends {TaskRepetitionState}
 */
export default class DeclarationTaskRepetitionState extends TaskRepetitionState {
	/**
	 * @param {Object} repetitionState
	 */
	constructor(repetitionState) {
		super(repetitionState);

		/**
		 * @type {Declaration[]}
		 * @private
		 */
		this._declarations = repetitionState.declarations || [];
	}

	isCheckedOnServer(declarationId) {
		return some(this._declarations, declaration => String(declaration.id) === String(declarationId));
	}
}
