import { createUseStyles } from 'react-jss';

export default (() => {
	return createUseStyles(theme => ({
		markdown: {
			fontSize: '1em',
			lineHeight: '1.3em',
			color: 'inherit',

			'& p, & ul, & ol, & pre': {
				marginBottom: '0.75em',
			},

			'& p': {
				textAlign: 'left',

				'&:last-of-type': {
					marginBottom: 0,
				},
			},

			'& strong': {
				fontWeight: 600,
			},

			'& em': {
				fontStyle: 'italic',
			},

			'& a': {
				color: theme.color.system['color--primary--background'],
				wordBreak: 'break-word',
				'&:hover': {
					textDecoration: 'underline',
				},
			},

			'& hr': {
				width: 'auto',
				height: 0,
				border: 0,
				borderTop: `1px solid ${theme.color.system['color--content--foreground-10']}`,
				backgroundColor: 'transparent',
				margin: 0,
				marginBottom: '1em',
			},

			'& img': {
				maxWidth: '100%',
				margin: '0.5em 0',
			},

			'& ul, & ol': {
				listStylePosition: 'inside',
			},

			'& ul': {
				paddingLeft: '0.8em',
				listStyle: 'none',

				'& li': {
					paddingLeft: '0.5em',
					position: 'relative',

					'&::before': {
						content: '""',
						position: 'absolute',
						left: 0,
						transform: 'translate(-50%, -40%)',
						top: '0.5em',
						width: '0.3em',
						height: '0.3em',
						backgroundColor: theme.color.system['color--interactive--background'],
						borderRadius: '50%',
					},
				},
			},

			'& ol': {
				paddingLeft: '0.5em',
				counterReset: 'item',

				'& li': {
					'&::before': {
						content: 'counter(item) ". "',
						counterIncrement: 'item',
						color: theme.color.system['color--interactive--background'],
					},
				},
			},
		},
	}));
})();
