import Team, { TeamDTO } from 'models/team/Team';
import APIService from 'services/APIService';
import TeamMember from 'models/team/TeamMember';
import UserProfile from 'models/user/UserProfile';
import { ContainerGroupDTO } from 'models/container/ContainerGroup';

export default class TeamService {
	constructor(protected readonly apiService: APIService) {}

	toModel(team: TeamDTO) {
		return new Team(team);
	}

	/**
	 *
	 * @param {string|number} id
	 * @returns {Promise}
	 */
	fetchDetails({ id }: { id?: string | number } = {}) {
		const url = 'v1/team';

		return this.apiService.get(id ? `${url}/${id}` : url).then(({ canEdit, canEditRoles, team }) => ({
			canEdit,
			canEditRoles,
			team: this.toModel(team),
		}));
	}

	editProfile(
		profile: UserProfile,
		{
			avatar,
			nickname,
			description,
		}: {
			avatar?: {
				image: null | string;
				blob: Blob;
			};
			nickname?: string;
			description?: string;
		}
	) {
		const data = new FormData();

		if (avatar) {
			data.append('avatar', avatar.blob);
		}

		data.append('nickname', nickname || profile.nickname());
		data.append('description', description || profile.description());

		return this.apiService.post('v1/team/edit', data);
	}

	editRole(member: TeamMember) {
		return this.apiService.post('v1/team/editRoles', {
			members: [
				{
					id: member.userProfile.id(),
					roles: member.roles,
				},
			],
		});
	}

	searchContainers({
		group,
		codeName,
		search,
		offset = 0,
		limit = 100,
	}: {
		group?: string | number;
		codeName?: string;
		search?: string;
		offset?: number;
		limit?: number;
	}) {
		if (!codeName) {
			return Promise.reject('"codeName" can not be empty');
		}

		const groupType = group === 'teamContainerGroup' ? 'team' : 'user';

		return this.apiService
			.get<{ list: ContainerGroupDTO[] }>(`v1/container-group/${groupType}/${codeName}/search`, {
				search,
				offset,
				limit,
			})
			.then(({ list }) => list);
	}

	searchTeams({
		id,
		search,
		offset = 0,
		limit = 100,
		open = true,
	}: {
		id?: string | number;
		search?: string;
		offset?: number;
		limit?: number;
		open?: boolean;
	}) {
		if (!id) {
			return this.apiService.get<Array<{ id: string; name: string }>>(
				`v1/autocomplete/${open ? 'open-team' : 'team'}`,
				{
					searchValue: search,
					offset,
					limit,
				}
			);
		}

		return this.apiService
			.get<{ list: TeamDTO[] }>(`v1/container/team/${id}/search`, {
				search,
				offset,
				limit,
			})
			.then(({ list }) => list);
	}

	join(id: string) {
		return this.apiService.post(`v1/team/${id}/join`);
	}
}
