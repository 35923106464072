export class LocalStorageService {
	constructor(config) {
		this._key = config.key;
		this._initValue = config.initValue;

		this._localStorage = window.localStorage;
		this._changeListeners = [];

		if (!this._localStorage) {
			throw new Error('localStorage is not supported in your browser.');
		}

		if (!this.value() && config.hasOwnProperty('initValue')) {
			this.setValue(this._initValue);
		}
	}

	value() {
		return this._localStorage.getItem(this._key);
	}

	setValue(value) {
		if (value === this.value()) {
			return;
		}

		try {
			this._localStorage.setItem(this._key, String(value));
			this._changeListeners.forEach(listener => listener(value));
		} catch (error) {
			throw new Error(`Unable to save ${this._key}. Is there space in localStorage left?`);
		}
	}

	addChangeListener(listener) {
		this._changeListeners.push(listener);
	}
}
