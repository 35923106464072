import React, { Fragment, useState } from 'react';
import { wire } from 'react-hot-wire';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import Loader from 'components/commons/_/loader/Loader';
import Pagination from 'components/_/common/pagination/Pagination';

import useFetch from 'components/_/hooks/useFetch';

import ChallengeListTile from './ChallengeList.tile';

function ChallengeList(props) {
	const i18nKey = 'challenge-list';
	const { tab, search } = props;
	const challengeService = props['services.challengeService'];
	const status = {
		open: 'STATE_PRESENT',
		future: 'STATE_FUTURE',
		finished: 'STATE_PAST',
	};

	const [rowsPerPage] = useState(24);
	const [page, setPage] = useState(0);

	const {
		loading,
		error,
		response: { list, count },
	} = useFetch(
		() =>
			challengeService.fetchList({
				filters: { status: status[tab] },
				offset: page * rowsPerPage,
				limit: rowsPerPage,
				search,
			}),
		{
			list: [],
			count: 0,
		},
		[tab, page, rowsPerPage, search]
	);

	return (
		<Fragment>
			<hr className="mb-4 mb-sm-5" />
			<div className="fs-4">
				{loading ? (
					<Loader />
				) : error ? (
					<p className="fs-6 mx-0 mx-sm-3">
						<FormattedMessage id={`${i18nKey}.error.${error}`} />
					</p>
				) : isEmpty(list) ? (
					<p className="fs-6 mx-0 mx-sm-3">
						<FormattedMessage id={`${i18nKey}.error.empty`} />
					</p>
				) : (
					<div className="row">
						{list.map(challenge => (
							<ChallengeListTile key={challenge.id} challenge={challenge} />
						))}
					</div>
				)}

				{count > 0 && !loading && (
					<Pagination count={count} page={page} rowsPerPage={rowsPerPage} onChange={page => setPage(page)} />
				)}
			</div>
		</Fragment>
	);
}

export default wire(['services.challengeService'], ChallengeList);
