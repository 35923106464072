import BonusTransactionNotification from 'models/notification/BonusTransactionNotification';

import NotificationBaseService from 'services/notification/NotificationBaseService';

import images from 'assets/images';

export default class BonusTransactionNotificationService extends NotificationBaseService {
	constructor(currencyService) {
		super();
		this._currencyService = currencyService;
	}

	toModel(notification) {
		return new BonusTransactionNotification(
			{
				...notification,
				earnings: this._currencyService.toModel(notification.earnings),
			},
			images
		);
	}
}
