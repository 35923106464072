import RGB from 'models/application/color/RGB';

export default class RGBA extends RGB {
	constructor(color) {
		super(color);

		this.alpha = color.alpha;
	}

	value() {
		return this.alpha < 100 ? `-${this.alpha}` : '';
	}

	//TODO: alpha / 100 GRR
	toString(flag) {
		return `rgba(${this.red}, ${this.green}, ${this.blue}, ${flag ? this.alpha : this.alpha / 100})`;
	}
}
