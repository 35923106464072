import InternalDynamicAnchorMap from 'models/application/anchor/types/internalDynamic/types/Map';

export default class InternalDynamicAnchorMapService {
	constructor(taskService) {
		this._taskService = taskService;
	}

	toModel({ task, ...rest }) {
		return new InternalDynamicAnchorMap({
			task: this._taskService.toModel(task),
			...rest,
		});
	}
}
