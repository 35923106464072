import React from 'react';
import PropTypes from 'prop-types';

export default function(Currency) {
	function Currencies({ bigIcons, parentClasses, currencyClasses, currencies }) {
		return (
			<div className={parentClasses}>
				{currencies.values().map(currency => (
					<Currency
						currency={currency}
						bigIcons={bigIcons}
						currencyClasses={currencyClasses}
						key={currency.codeName()}
					/>
				))}
			</div>
		);
	}

	Currencies.propTypes = {
		currencies: PropTypes.object,
		bigIcons: PropTypes.bool,
		parentClasses: PropTypes.string,
	};

	return Currencies;
}
