import subServices from 'services/application/integration/subServices';

import IntegrationService from 'services/application/integration/IntegrationService';

export default {
	...subServices,
	'services.integrationService': {
		class: IntegrationService,
		deps: [{ $ref: 'services.logService' }, { $ref: 'services.userService' }],
		calls: [
			{
				name: 'registerSubService',
				args: ['chatwee', { $ref: 'services.chatweeIntegrationService' }],
			},
			{
				name: 'registerSubService',
				args: ['email', { $ref: 'services.emailIntegrationService' }],
			},
			{
				name: 'registerSubService',
				args: ['facebook', { $ref: 'services.facebookIntegrationService' }],
			},
			{
				name: 'registerSubService',
				args: ['linked_in', { $ref: 'services.linkedInIntegrationService' }],
			},
			{
				name: 'registerSubService',
				args: ['generic_oauth', { $ref: 'services.genericOAuthIntegrationService' }],
			},
			{
				name: 'registerSubService',
				args: ['google', { $ref: 'services.googleIntegrationService' }],
			},
			{
				name: 'registerSubService',
				args: ['imported_id', { $ref: 'services.importedIdIntegrationService' }],
			},
			{
				name: 'registerSubService',
				args: ['token', { $ref: 'services.tokenIntegrationService' }],
			},
		],
	},
};
