import { Component } from 'react';

/**
 * Class representing a single attribute.
 */
export default class Attribute extends Component {
	/**
	 * @param {Object} attribute
	 */
	constructor(attribute) {
		super();

		this._attributeDefinition = attribute._attributeDefinition;
		this._attributeValue = attribute._attributeValue;
		this._attributeMaxValue = attribute._maxValue;
	}

	name() {
		return this._attributeDefinition.name;
	}

	description() {
		return this._attributeDefinition.description;
	}

	color() {
		return this._attributeDefinition.color;
	}

	image() {
		return this._attributeDefinition.image;
	}

	value() {
		return this._attributeValue;
	}

	maxValue() {
		return this._attributeMaxValue;
	}

	progress() {
		if (this.maxValue()) {
			return this.value() / this.maxValue();
		}
	}
}
