import achievement from 'views/achievement';
import application from 'views/application';
import challenge from 'views/challenge';
import commons from 'views/commons';
import news from 'views/news';
import profile from 'views/profile';
import routing from 'views/routing';
import wrapper from 'views/wrapper';

export default {
	...achievement,
	...application,
	...challenge,
	...commons,
	...news,
	...profile,
	...routing,
	...wrapper,
};
