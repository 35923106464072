import React, { PureComponent } from 'react';
import { createInstance } from 'react-async-action';

import Loader from 'components/commons/_/loader/Loader';

export default function(userProfileService, IsAMemberAlready, JoinTaskCrashed, UserContainerJoinView) {
	const UserContainerGroup = createInstance({
		action: ({ id }) => userProfileService.fetchContainerFromUserContainerGroup({ id }),
	});

	return class JoinToUserContainer extends PureComponent {
		render() {
			const { task, onChange } = this.props;

			return (
				<UserContainerGroup
					id={task.containerGroup().id}
					onResolve={container => this.props.checkMembership(container)}
				>
					<UserContainerGroup.Loading>
						<Loader />
					</UserContainerGroup.Loading>
					<UserContainerGroup.Resolved>
						{({ response: container }) =>
							container ? (
								<IsAMemberAlready type="container" task={this.props.task} name={container.name} />
							) : (
								<UserContainerJoinView onChange={onChange} task={task} />
							)
						}
					</UserContainerGroup.Resolved>
					<UserContainerGroup.Rejected>
						{({ error }) => <JoinTaskCrashed reason={error} />}
					</UserContainerGroup.Rejected>
				</UserContainerGroup>
			);
		}
	};
}
