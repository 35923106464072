import _, { chain } from 'lodash';
import when from 'when';

/**
 * Class representing a event queue.
 */
export default class EventQueue {
	/**
	 *
	 * @type {Array}
	 * @private
	 */
	_eventQueue = [];

	/**
	 * @param {string} eventName
	 * @param {Function} handler
	 */
	addEventToQueue(eventName, handler) {
		if (eventName === this.__cachedEventInProgress) {
			return;
		}

		this._eventQueue = chain(this._eventQueue)
			.push({ eventName, handler })
			.uniqWith(event => event.eventName === eventName)
			.value();

		this._releaseQueue();
	}

	clear() {
		this._eventQueue = [];
	}

	/**
	 * @private
	 */
	_releaseQueue() {
		if (_.isEmpty(this._eventQueue)) {
			return;
		}

		if (this.__cachedEventInProgress) {
			return;
		}

		const event = this._eventQueue.shift();

		this.__cachedEventInProgress = event.eventName;

		when(event.handler()).finally(() => {
			this.__cachedEventInProgress = null;
			this._releaseQueue();
		});
	}
}
