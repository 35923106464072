import React from 'react';
import { FormattedMessage } from 'react-intl';

export default () => {
	return function defaultButton(args) {
		const { notification, close } = args;

		return (
			<button onClick={() => close()} className="btn__basic">
				<FormattedMessage
					id={`notification.real-time.${notification.type()}.button.${
						notification.hasEarnings() ? 'default' : 'without-earnings'
					}`}
				/>
			</button>
		);
	};
};
