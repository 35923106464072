import InternalDynamicAnchor from 'models/application/anchor/types/internalDynamic/InternalDynamic';

export default class InternalDynamicAnchorNews extends InternalDynamicAnchor {
	/**
	 * @type {string}
	 * @private
	 */
	_pattern = '/news/:id/details';

	constructor(anchor) {
		super(anchor);

		this.news = anchor.news;

		this._params = {
			id: this.news.id,
		};
	}
}
