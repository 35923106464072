export default function() {
	return function inProgress(Component) {
		return class InProgressEnhancer extends Component {
			constructor(...args) {
				super(...args);

				this.state = this.state || {};
				this.state.process = {};
			}

			/**
			 * @param {string} name
			 * @protected
			 */
			_startProcess(name) {
				this.setState({
					process: {
						...this.state.process,
						[name]: true,
					},
				});
			}

			/**
			 * @param {string} name
			 * @protected
			 */
			_endProcess(name) {
				this.setState({
					process: {
						...this.state.process,
						[name]: false,
					},
				});
			}

			_isInProgress(name) {
				return this.state.process[name];
			}
		};
	};
}
