export class RankingService {
	/**
	 * @type {Object<string,object>}
	 * @private
	 */
	_services = {};

	constructor(APIService) {
		/**
		 * @type {APIService}
		 */
		this.APIService = APIService;
	}

	/**
	 * @param {string} id
	 * @param {string|null} container
	 * @param {string|null} team
	 * @param {number|null} offset
	 * @param {number|null} limit
	 * @param {string|undefined} search
	 * @returns {Promise}
	 */
	fetchRanking({ id, container, team, offset = 0, limit = 100, search }) {
		let url = `v1/ranking/${id}`;

		if (team) {
			url += `/team/${team}`;
		} else if (container) {
			url += `/container/${container}`;
		}

		return this.APIService.get(url, { offset, limit, search }).then(({ count, ranking, list = [] }) => {
			const { rankingService, rankingEntryService } = this._recognizeRankingService(ranking);

			return {
				count,
				ranking: ranking && rankingService.toModel(ranking),
				list: list.map(({ position, entry }) => ({
					position,
					entry: rankingEntryService.toModel(entry),
				})),
			};
		});
	}

	/**
	 * @returns {Promise}
	 */
	fetchRankingList() {
		return this.APIService.get('v1/ranking').then((rankingList = []) =>
			rankingList.map(ranking => this.toModel(ranking))
		);
	}

	/**
	 * @param {Ranking} ranking
	 * @returns {Promise}
	 */
	fetchLoggedUserPosition({ ranking }) {
		return this.APIService.get(`v1/ranking/${ranking.id}/user`).then(response => {
			if (!response) {
				return null;
			}

			const { rankingEntryService } = this._recognizeRankingService({
				ranking_type: ranking.type,
			});

			return {
				position: response.position,
				entry: rankingEntryService.toModel(response.entry),
			};
		});
	}

	registerRankingService(type, rankingService, rankingEntryService) {
		this._services[type] = {
			rankingService,
			rankingEntryService,
		};
	}

	toModel(ranking) {
		const { rankingService } = this._recognizeRankingService(ranking);

		return rankingService.toModel(ranking);
	}

	/**
	 * @param {Object} ranking
	 * @returns {Object}
	 * @private
	 */
	_recognizeRankingService(ranking) {
		const service = this._services[ranking.ranking_type];

		if (!service) {
			throw new Error(`Unrecognized ranking type: '${ranking.ranking_type}'.`);
		}

		return service;
	}
}
