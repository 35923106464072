import BaseService from 'services/BaseService';

/**
 * @extends BaseService
 */
export default class OrderAnswerService extends BaseService {
	/**
	 * @type {string}
	 * @private
	 */
	_type = 'orderAnswer';
	/**
	 * @type {string}
	 * @private
	 */
	_typeKey = 'type';

	/**
	 * @param {LogService} logService
	 */
	constructor(logService) {
		super();

		/**
		 * @type {LogService}
		 * @private
		 */
		this._logService = logService;
	}

	/**
	 * @param {Object} answer
	 * @returns {Object|undefined}
	 */
	toModel(answer) {
		try {
			return super.toModel(answer);
		} catch (error) {
			this._logService.warn(error);
		}
	}
}
