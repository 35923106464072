import React, { Component, Fragment } from 'react';
import Markdown from 'react-markdown';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import More from 'components/commons/text/More';

export default function() {
	return class ReactMarkdown extends Component {
		static propTypes = {
			source: PropTypes.string,
			class: PropTypes.string,
			disableMarkdown: PropTypes.bool,
		};

		render() {
			const { source, render } = this.props;

			if (!source) {
				return null;
			}

			const [initialText, moreText] = source.split('[more]');
			const text = (
				<Fragment>
					{this._markdown(initialText)}
					{moreText && (
						<Fragment>
							{' '}
							<More text={this._markdown(moreText)} />
						</Fragment>
					)}
				</Fragment>
			);

			return (
				<div
					className={classnames(this.props.className, {
						markdown: !this.props.disableMarkdown,
					})}
				>
					{render ? render({ text }) : text}
				</div>
			);
		}

		_markdown(source) {
			return (
				<Markdown
					source={source}
					renderers={{
						link: props => (
							<a href={props.href} target="_blank" rel="noopener noreferrer">
								{props.children}
							</a>
						),
					}}
				/>
			);
		}
	};
}
