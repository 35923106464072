import React, { PureComponent } from 'react';

export default function(External, Internal) {
	return class VisitLinkTask extends PureComponent {
		render() {
			const { task, goBack, process, inProcess } = this.props;
			const isCompleted = task.state().hasStatus('completed');
			const hasDescription = task.description();
			const View = task.openInFrame() ? Internal : External;

			return (
				<View
					task={task}
					goBack={goBack}
					process={process}
					inProcess={inProcess}
					isCompleted={isCompleted}
					hasDescription={hasDescription}
				/>
			);
		}
	};
}
