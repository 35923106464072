import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import CreateSubmission from '../../Create.template';

export default class CreateSubmissionConfirmationComponent extends PureComponent {
	render() {
		const { intl, onSubmit, closeModal } = this.props;

		const close = () => {
			onSubmit();
			closeModal();
		};

		return (
			<CreateSubmission
				closeModal={close}
				title={intl.formatMessage({
					id: 'create-submission.confirmation.title',
				})}
			>
				<div className="confirmation-view text-center">
					<i className="fa fa-check-circle my-3" />
					<p>
						{intl.formatMessage({
							id: 'create-submission.confirmation.content.line-1',
						})}
						<br />
						{intl.formatMessage({
							id: 'create-submission.confirmation.content.line-2',
						})}
					</p>
					<button className="btn__basic my-4" onClick={close}>
						<FormattedMessage id="create-submission.confirmation.button" />
					</button>
				</div>
			</CreateSubmission>
		);
	}
}
