import TransactionLink from './TransactionLink';

export default class TargetChallengeTransactionLink extends TransactionLink {
	constructor(transactionLink) {
		super(transactionLink);

		this._progressCount = transactionLink.progress_count;
		this._challengeState = transactionLink.challenge_state;
	}

	progressCount() {
		return this._progressCount;
	}

	challengeState() {
		return this._challengeState;
	}
}
