import React, { PureComponent } from 'react';
import Async from 'react-async-action';

import Loader from 'components/commons/_/loader/Loader';

export default function() {
	return class AsyncComponent extends PureComponent {
		static Resolved = Async.Resolved;

		render() {
			const { children, ...props } = this.props;

			return (
				<Async {...props}>
					<Async.Loading>
						<Loader />
					</Async.Loading>
					{children}
				</Async>
			);
		}
	};
}
