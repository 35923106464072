import CustomQuestionAnswerService from 'services/common/form/questionAnswer/CustomQuestionAnswerService';
import QuestionAnswerService from 'services/common/form/questionAnswer/QuestionAnswerService';
import StandardQuestionAnswerService from 'services/common/form/questionAnswer/StandardQuestionAnswerService';

export default {
	'services.customQuestionAnswerService': {
		class: CustomQuestionAnswerService,
	},
	'services.questionAnswerService': {
		class: QuestionAnswerService,
		deps: [{ $ref: 'services.logService' }],
		calls: [
			{
				name: 'registerSubService',
				args: ['custom', { $ref: 'services.customQuestionAnswerService' }],
			},
			{
				name: 'registerSubService',
				args: ['standard', { $ref: 'services.standardQuestionAnswerService' }],
			},
		],
	},
	'services.standardQuestionAnswerService': {
		class: StandardQuestionAnswerService,
	},
};
