import MenuPositionBaseService from 'services/application/menu/position/MenuPositionBaseService';
import LinkMenuPosition from 'models/application/menu/position/types/Link';

export default class LinkMenuPositionService extends MenuPositionBaseService {
	toModel({ anchor, ...rest }) {
		return new LinkMenuPosition({
			anchor: anchor && this._anchorService.toModel(anchor),
			...rest,
		});
	}
}
