import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';

import images from 'assets/images';

export default function(StateManager, challengeAwardedPlaceService, Currencies, Image) {
	return class ChallengePrizeList extends StateManager {
		request() {
			return challengeAwardedPlaceService.fetchAwardedPlaces({
				id: this.props.challenge.id,
			});
		}

		view({ response: awardedPlaces }) {
			return (
				<div className="contentSection__contentWrapper submissionAwards wrapper--col overflow--auto">
					{awardedPlaces.map(awardedPlace => (
						<div
							key={awardedPlace.id}
							className={classnames('wrapper--row submissionAward__section el--shrink', {
								isEmpty: _.isEmpty(awardedPlace.items()),
							})}
						>
							<div className="submissionAward__placeInfo el--shrink">
								<img src={images.example.icon.challenge.ranking} alt="" />
								<p>
									<FormattedMessage
										id={`challenge-details.common.ranked.${awardedPlace.place().id}`}
										values={awardedPlace.place().values}
									/>
								</p>
							</div>
							{_.isEmpty(awardedPlace.items()) && !awardedPlace.points().hasValues && (
								<div className="emptyMessage">
									<FormattedMessage id="challenge-details.common.prizes.no-prize-set" />
								</div>
							)}
							<ul
								className={classnames('wrapper--row wrapper--row--wrap', {
									hidden: _.isEmpty(awardedPlace.items()) && !awardedPlace.points().hasValues(),
								})}
							>
								{awardedPlace.points().hasValues() && (
									<li key="points" className="challengeTile submissionAward__tile wrapper--col">
										<div className="wrapper--col justify--between el--grow">
											<Currencies
												parentClasses="prizeCurrencies wrapper--row wrapper--row--wrap justify--between el--shrink"
												currencies={awardedPlace.points()}
											/>
											<p>
												<FormattedMessage id="challenge-details.common.prizes.points" />
											</p>
										</div>
									</li>
								)}
								{awardedPlace.items().map(item => (
									<li key={item.id} className="challengeTile submissionAward__tile">
										<NavLink to={`/store/item/${item.id}/details`} className="wrapper--col">
											<div
												className="image image--contain hidden--mobile"
												style={{
													backgroundImage: `url(${item.image()}?w=256)`,
												}}
											/>
											<Image className="visible--mobile image el--shrink" src={item.image()} />
											<p>{item.name}</p>
										</NavLink>
									</li>
								))}
							</ul>
						</div>
					))}
				</div>
			);
		}
	};
}
