import MenuPositionBaseService from 'services/application/menu/position/MenuPositionBaseService';
import MoreMenuPosition from 'models/application/menu/position/types/More';

export default class MoreMenuPositionService extends MenuPositionBaseService {
	toModel({ anchor, ...rest }) {
		return new MoreMenuPosition({
			anchor: anchor && this._anchorService.toModel(anchor),
			...rest,
		});
	}
}
