import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import StoreItem from './store-item/Item';

import Loader from 'components/commons/_/loader/Loader';

import useFetch from 'components/_/hooks/useFetch';

export const ItemList = ({ tag, storeItemService }) => {
	const i18nKey = 'prize-list';

	const {
		loading,
		error,
		response: { list },
	} = useFetch(() => storeItemService.fetchItemList({ tag }), { list: [] }, [tag]);

	return (
		<Fragment>
			<hr className="mb-4 mb-sm-5" />
			<div className="store fs-4">
				{loading ? (
					<Loader />
				) : error ? (
					<p className="fs-6 mx-0 mx-sm-3">
						<FormattedMessage id={`${i18nKey}.error.${error}`} />
					</p>
				) : isEmpty(list) ? (
					<p className="fs-6 mx-0 mx-sm-3">
						<FormattedMessage id={`${i18nKey}.error.empty`} />
					</p>
				) : (
					<div className="store-items row">
						{list.map(item => (
							<StoreItem item={item} key={item.id} />
						))}
					</div>
				)}
			</div>
		</Fragment>
	);
};
