import Employee, { EmployeeDTO } from '../employee/Employee';

export interface ProcessRoleDTO extends Omit<EmployeeDTO, 'user_id'> {
	role_id: string;
	name: string;
	user_id: string | null;
}

export default class ProcessRole {
	private _roleId: string;
	private _name: string;
	private _user: Employee | null;

	constructor(processRole: ProcessRoleDTO) {
		this._roleId = processRole.role_id;
		this._name = processRole.name;
		this._user = processRole.user_id ? new Employee(processRole as EmployeeDTO) : null;
	}

	get roleId() {
		return this._roleId;
	}

	get name() {
		return this._name;
	}

	get user() {
		return this._user;
	}
}
