import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import ChallengeProgressStatus from 'views/challenge/types/creative/CreativeProgressStatus';

import CreateSubmission from 'components/challenge/_/challenge/creative/submission/create/Create';

import { Header } from 'components/_/common/view/item/ItemView';
import { Markdown } from 'components/_/common/text/markdown/Markdown';

import Score from './score/Score';
import Like from './like/Like';

const SubmissionDetails = ({ next, previous, submission, userJudgement, services, button, reload }) => {
	return (
		<div className="view__submissionDetails view__challengeDetails view__challengeDetails--creative el--grow">
			<CreateSubmission challenge={submission.challenge} onSubmit={() => reload()} />
			<div className="standardPreview wrapper--col challengeDetails">
				<Header
					title={submission.title()}
					goBack={{ location: `/challenge/${submission.challenge.id}/recentlyAddedSubmissions` }}
				>
					<section className="rateSection d-none d-xs-flex">
						{submission.challenge.canScore() && (
							<Score userJudgement={userJudgement} submission={submission} />
						)}
						{submission.challenge.canLike() && (
							<Like userJudgement={userJudgement} submission={submission} />
						)}
					</section>
				</Header>
				<div className="wrapper--row el--grow overflow--auto overflowContainer">
					{previous && (
						<div className="navigationArrow navigationArrow--left">
							<NavLink to={`/challenge/submission/${previous.id()}/recentlyAddedSubmissions`}>
								<i className="fa fa-angle-left" />
							</NavLink>
						</div>
					)}
					<div className="flex-grow-1 m-4">
						<section className="rateSection d-flex d-xs-none">
							{submission.challenge.canScore() && (
								<Score userJudgement={userJudgement} submission={submission} />
							)}
							{submission.challenge.canLike() && (
								<Like userJudgement={userJudgement} submission={submission} />
							)}
						</section>
						<div className="info d-flex pb-4 mb-4 border-bottom">
							<div className="author d-flex mr-auto">
								<span className="d-none d-md-block mr-1">
									<FormattedMessage id="challenge-details.creative.submission-details.author" />
								</span>
								<span className="author__name">
									<NavLink to={`/user/${submission.author().id()}/details`} className="author">
										{submission.author().fullName()}
									</NavLink>
								</span>
							</div>
							<div className="date d-flex">
								<span className="d-none d-md-block mr-1">
									<FormattedMessage id="challenge-details.creative.submission-details.date" />
								</span>
								<span className="date__value">{submission.createdAt()}</span>
							</div>
						</div>
						<div className="content">
							{submission.submissionType() === 'imagesubmission' ? (
								<img
									src={`${submission.image()}?w=1024`}
									className="image d-block m-auto mw-100"
									alt=""
								/>
							) : (
								<p className="text">
									<Markdown>{submission.text()}</Markdown>
								</p>
							)}
						</div>
					</div>
					{next && (
						<div className="navigationArrow navigationArrow--right">
							<NavLink to={`/challenge/submission/${next.id()}/details`}>
								<i className="fa fa-angle-right" />
							</NavLink>
						</div>
					)}
				</div>
				<div
					className={classnames(
						'challengeDetails__footer wrapper--row align--center el--shrink flex-row flex-wrap',
						{
							'justify-content-around': submission.challenge.state(),
							'justify--end': !submission.challenge.state(),
							canParticipate:
								submission.challenge.state() && submission.challenge.state().canParticipate(),
						}
					)}
				>
					{submission.challenge.state() && (
						<ChallengeProgressStatus
							challenge={submission.challenge}
							canParticipate={submission.challenge.state().canParticipate()}
						/>
					)}
					<div className="pt-2 pt-sm-0 pl-0 pl-sm-3">
						{services.userProfileService.isAnonymous() ? (
							<button className="btn__basic" onClick={() => services.accessPopupService.open()}>
								<FormattedMessage id="challenge-details.creative.button.take-part" />
							</button>
						) : (
							button() && (
								<button
									className={classnames('btn__basic', {
										'btn__basic--disabled': !submission.challenge.state().canParticipate(),
									})}
									onClick={() =>
										submission.challenge.state().canParticipate() &&
										services.modalService.runChangeListeners('create-submission')
									}
								>
									{button()}
								</button>
							)
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SubmissionDetails;
