import ExternalAnchorService from 'services/application/anchor/subServices/external/ExternalAnchorService';
import subServices from 'services/application/anchor/subServices/external/subServices';

export default {
	'services.externalAnchorService': {
		class: ExternalAnchorService,
		calls: [
			{
				name: 'registerSubService',
				args: ['url', { $ref: 'services.externalAnchorUrlService' }],
			},
		],
	},
	...subServices,
};
