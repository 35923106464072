import { GroupPointRankingEntry } from './GroupPointRankingEntry';
import Team from '../../../team/Team';

/**
 * Class representing a team point ranking entry.
 * @extends GroupPointRankingEntry
 */
export class TeamPointRankingEntry extends GroupPointRankingEntry {
	constructor(entry) {
		super(entry);

		/**
		 * @type {Team}
		 */
		this.team = new Team(entry.team);
	}
}
