import _ from 'lodash';

class NavigationService {
	constructor(location, browserHistory) {
		this._location = location;
		this._browserHistory = browserHistory;
	}

	/**
	 * @param {string|Anchor} address
	 */
	load(address) {
		address = this._anchorToAddress(address);

		if (address) {
			this._location.href = address;
		} else {
			this.reload();
		}
	}

	/**
	 * @param {string|Anchor} address
	 */
	reset(address) {
		address = this._anchorToAddress(address);

		this._browserHistory.push(address);
		this.reload();
	}

	/**
	 * @param {string|Anchor} address
	 */
	goTo(address) {
		address = this._anchorToAddress(address);

		this._browserHistory.push(address);
	}

	/**
	 * @param {string|Anchor} address
	 */
	replace(address) {
		address = this._anchorToAddress(address);

		this._browserHistory.replace(address);
	}

	goBack() {
		this._browserHistory.goBack();
	}

	reload() {
		this._location.reload();
	}

	currentLocation() {
		return this._browserHistory.location;
	}

	/**
	 * @param {string|Anchor} address
	 * @return {string}
	 * @private
	 */
	_anchorToAddress(address) {
		let result = null;

		if (_.isString(address)) {
			result = address;
		} else if (_.isFunction(address.url)) {
			result = address.url();
		}

		return result;
	}
}

export default NavigationService;
