import { Component } from 'react';

export default function(i18nService) {
	return class BaseLanguageSwitcher extends Component {
		state = {
			opened: false,
		};

		_open() {
			this.setState({
				opened: true,
			});
		}

		_close() {
			this.setState({
				opened: false,
			});
		}

		_onLocaleChange(locale) {
			i18nService.changeLocale(locale);
		}
	};
}
