import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

export default function() {
	return class FooterInteractive extends PureComponent {
		render() {
			const { task, options, actions, progressIndicator } = this.props;

			return (
				<div className="footer__interactiveSection wrapper--row justify--end flex-shrink-0">
					{!(task.state().hasStatus('completed') && task.viewWhenComplete()) && (
						<React.Fragment>
							{!task.isSubmitted() && progressIndicator}
							{(task.isFailed() || task.isCrashed()) && task.helpEnabled() && (
								<a
									href={task.helpUrl()}
									className="btn__basic btn__basic--help mr-3"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FormattedMessage id="task-details.common.button.help" />
								</a>
							)}
							<div className="align-self-end">
								{task.isCompleted() ? (
									<button
										key="continue"
										type="button"
										onClick={event => actions.goBack(event)}
										className="btn__basic btn__basic--basicGreen continueButton"
									>
										<FormattedMessage id="task-details.common.button.continue" />
									</button>
								) : task.isFailed() || task.isCrashed() ? (
									<button
										key="tryAgain"
										type="button"
										className={classnames('btn__basic btn__basic--basicGreen tryAgainButton', {
											loading: actions.isSubmitting,
										})}
										onClick={() => actions.tryAgain()}
									>
										<FormattedMessage id="task-details.common.button.do-again" />
									</button>
								) : task.state().currentRepetitionState() &&
								  task
										.state()
										.currentRepetitionState()
										.acceptanceState() === 'rejected' ? (
									<button
										key="tryAgain"
										type="button"
										className={classnames('btn__basic btn__basic--basicGreen tryAgainButton', {
											loading: actions.isSubmitting,
										})}
										onClick={() => actions.resetTask()}
									>
										<FormattedMessage id="task-details.common.button.do-again" />
									</button>
								) : task.state().currentRepetitionState() &&
								  task
										.state()
										.currentRepetitionState()
										.acceptanceState() === 'awaiting' ? null : task.canBeSubmitted() ? (
									options.hideCheckButton ? null : actions.submit.handler ? (
										<button
											key="check"
											type="button"
											className={classnames('btn__basic checkAnswer', {
												loading: actions.isSubmitting,
											})}
											onClick={() => actions.submit.handler()}
										>
											<FormattedMessage
												id={actions.submit.buttonText || 'task-details.common.button.check'}
											/>
										</button>
									) : (
										<button
											key="check"
											type="submit"
											className={classnames('btn__basic checkAnswer', {
												loading: actions.isSubmitting,
												'btn__basic--disabled': !actions.isValid,
											})}
											disabled={!actions.isValid}
										>
											<FormattedMessage
												id={actions.submit.buttonText || 'task-details.common.button.check'}
											/>
										</button>
									)
								) : null}
							</div>
						</React.Fragment>
					)}
				</div>
			);
		}
	};
}
