import TaskBaseService from 'services/task/TaskBaseService';

import MockupTask from 'models/task/mockup/MockupTask';

export default class MockupTaskService extends TaskBaseService {
	toModel(task) {
		return new MockupTask({
			...task,
			state: this._mapState(task),
		});
	}
}
