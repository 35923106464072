import TaskActionService from './TaskActionService';
import AssignRoleActionService from './AssignRoleActionService';

export default {
	'services.onboardingTaskActionService': {
		class: TaskActionService,
	},
	'services.onboardingAssignRoleActionService': {
		class: AssignRoleActionService,
	},
};
