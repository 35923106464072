import { ContainerImportedRankingEntryService } from './ContainerImportedRankingEntryService';
import { TeamImportedRankingEntryService } from './TeamImportedRankingEntryService';
import { UserImportedRankingEntryService } from './UserImportedRankingEntryService';

export default {
	'services.ranking.containerImportedRankingEntryService': {
		class: ContainerImportedRankingEntryService,
	},
	'services.ranking.teamImportedRankingEntryService': {
		class: TeamImportedRankingEntryService,
	},
	'services.ranking.userImportedRankingEntryService': {
		class: UserImportedRankingEntryService,
	},
};
