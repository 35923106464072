import React from 'react';
import classnames from 'classnames';

export default function(BaseTeamAssignment, ChooseContainer, ChooseTeam) {
	return class TeamAssignment extends BaseTeamAssignment {
		render() {
			const { block } = this.props;

			return (
				<form className="block__teamAssignment" onSubmit={event => this.submit(event)}>
					<div className="sectionTitle">Dopisz się od odpowiedniego zespołu</div>
					<div className="standardText standardText--bottomMargin">
						Wybierz właściwy dla Ciebie zespół, abyś mógł brać udział w konkursie w ramach swojej uczelni /
						szkoły.
					</div>
					{block.container() && (
						<ChooseContainer
							group="teamContainerGroup"
							placeholder="Wybierz uczelnię / szkołę"
							codeName={block.container()}
							handler={(...args) => this._onContainerChanged(...args)}
						/>
					)}
					<ChooseTeam
						placeholder="wybierz nazwę zespołu / grupy"
						disabled={this.state.disabledTeam}
						container={this.state.container}
						handler={(...args) => this._onTeamChanged(...args)}
					/>
					<div className="textLine wrapper--row justify--between align--center hidden">
						<div className="textLine__line el--grow"></div>
						<div className="textLine__text">Wybrany zespół</div>
						<div className="textLine__line el--grow"></div>
					</div>
					<div className="regionContainer wrapper--row justify--between align--center hidden">
						<div>
							<div className="sectionTitle">Oddział Poznań</div>
							<div className="standardText">Region Wielkopolska</div>
						</div>
						<div className="teamIcon"></div>
					</div>
					<div className="wrapper--row justify--between align--center">
						<button
							className={classnames('btn__basic btn__basic--fullWidth noMargin', {
								'btn__basic--disabled': !this.state.team,
							})}
							onClick={event => this._submit(event)}
						>
							<span>Następny krok</span>
						</button>
					</div>
				</form>
			);
		}
	};
}
