import APIService from 'services/APIService';

type TranslationMessage = { [key: string]: string | TranslationMessage };

export default class TranslationService {
	constructor(protected readonly apiService: APIService) {}

	public fetchTranslation(locale: string): Promise<TranslationMessage> {
		return this.apiService.get<TranslationMessage>('v1/translation', { _locale: locale }, undefined, {
			withoutToken: true,
		});
	}
}
