import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, Link } from 'react-router-dom';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import images from 'assets/images';

import { MappedUserProfileFieldDefinition } from 'models/user/userProfileField/definition/MappedUserProfileFieldDefinition';
import { CustomUserProfileFieldDefinition } from 'models/user/userProfileField/definition/CustomUserProfileFieldDefinition';

import Pagination from 'components/_/common/pagination/Pagination';
import useFetch from 'components/_/hooks/useFetch';

import Loader from 'components/commons/_/loader/Loader';

export const ContactList = ({ tab, search, contactListService }) => {
	const i18nKey = 'contact-list';

	const [rowsPerPage] = useState(24);
	const [page, setPage] = useState(0);

	const {
		loading,
		error,
		response: { list, count },
	} = useFetch(
		() =>
			contactListService.list({
				active: tab === 'active',
				search,
				offset: page * rowsPerPage,
				limit: rowsPerPage,
			}),
		{ list: [], count: 0 },
		[tab, search, page, rowsPerPage]
	);

	useEffect(() => {
		setPage(0);
	}, [search]);

	return (
		<Fragment>
			<hr className="mb-4 mb-sm-5" />
			<div className="contactList fs-4">
				{loading ? (
					<Loader />
				) : error ? (
					<p className="fs-6 mx-0 mx-sm-3">
						<FormattedMessage id={`${i18nKey}.error.${error}`} />
					</p>
				) : isEmpty(list) ? (
					<p className="fs-6 mx-0 mx-sm-3">
						<FormattedMessage id={`${i18nKey}.error.empty`} />
					</p>
				) : (
					<div className="contactList__list">
						<div className="row">
							{list.map(({ userProfile, fields }) => {
								const team = userProfile.membership() && userProfile.membership().team();

								return (
									<div
										key={userProfile.id()}
										className="col-12 col-md-8 col-lg-6 col-xl-4 mb-4 mb-sm-5"
									>
										<div className="mx-0 mx-sm-3 h-100">
											<NavLink
												to={`/user/${userProfile.id()}/details`}
												className="position-relative d-block h-100"
											>
												<div className="contactList__tile rounded h-100">
													<div className="tile__front text-center px-3 pt-3 px-sm-4 pt-sm-4">
														{fields.contactList.avatar && (
															<div className="mb-3 mb-sm-4">
																<div className="tile__avatar">
																	<div
																		className="user-avatar m-auto rounded-circle position-relative background-image size--cover"
																		style={{
																			backgroundImage: `url(${
																				fields.contactList.avatarValue
																					? `${fields.contactList.avatarValue}?w=512`
																					: images.default.avatar.user
																			})`,
																		}}
																	>
																		{team && (
																			<Link to={`/team/${team.id()}/members`}>
																				<div
																					className="team-avatar rounded-circle position-absolute background-image size--cover"
																					style={{
																						backgroundImage: `url(${
																							team.avatar()
																								? `${team.avatar()}?w=128`
																								: images.default.avatar
																										.team
																						})`,
																					}}
																				/>
																			</Link>
																		)}
																	</div>
																</div>
															</div>
														)}

														<p className="name fs-4 fs-sm-5 mb-3">
															{fields.contactList.name}
														</p>

														{fields.contactList.hasFields && (
															<div>
																{fields.contactList.fields.map(
																	field =>
																		field.value() && (
																			<p className="mb-3 mb-sm-4">
																				<span className="field__name mb-1">
																					{field.definition() instanceof
																						MappedUserProfileFieldDefinition && (
																						<Fragment>
																							<FormattedMessage
																								id={`user-profile.field.mapped.${field
																									.definition()
																									.mappedProfileField()}`}
																							/>
																							:
																						</Fragment>
																					)}
																					{field.definition() instanceof
																						CustomUserProfileFieldDefinition &&
																						`${field.definition().name()}:`}
																				</span>
																				<span
																					className={classnames(
																						'field__value',
																						{
																							link:
																								field
																									.definition()
																									.valueType() ===
																								'link',
																						}
																					)}
																				>
																					{field.definition().valueType() ===
																					'link' ? (
																						<a
																							href={field.value()}
																							h
																							target="_blank"
																							rel="noopener noreferrer"
																						>
																							{field.value()}
																						</a>
																					) : (
																						field.value()
																					)}
																				</span>
																			</p>
																		)
																)}
															</div>
														)}
													</div>
												</div>
											</NavLink>
										</div>
									</div>
								);
							})}
						</div>

						{count > 0 && !loading && (
							<Pagination
								count={count}
								page={page}
								rowsPerPage={rowsPerPage}
								onChange={page => setPage(page)}
							/>
						)}
					</div>
				)}
			</div>
		</Fragment>
	);
};
