import TargetChallengeCalculatedNotification from 'models/notification/TargetChallengeCalculatedNotification';
import Transaction from 'models/application/transaction/Transaction';

import NotificationBaseService from 'services/notification/NotificationBaseService';

export default class TargetChallengeCalculatedNotificationService extends NotificationBaseService {
	constructor(challengeService, currencyService, transactionLinkService) {
		super();
		this._challengeService = challengeService;
		this._currencyService = currencyService;
		this._transactionLinkService = transactionLinkService;
	}

	toModel({ challenge, transaction, target_challenge_transaction_link, ...rest }) {
		return new TargetChallengeCalculatedNotification({
			challenge: this._challengeService.toModel(challenge),
			target_challenge_transaction_link: this._transactionLinkService.toModel(target_challenge_transaction_link),
			transaction: new Transaction({
				...transaction,
				amounts: this._currencyService.toModel(transaction.amounts),
				links: transaction.links.map(link => this._transactionLinkService.toModel(link)),
			}),
			...rest,
		});
	}
}
