import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Info from '../../../../Info';
import { Link } from 'react-router-dom';

export default injectIntl(({ intl, isValid, integrationId, children }) =>
	isValid ? (
		children
	) : (
		<Info
			title={intl.formatMessage({
				id: 'auth.reset-password.action.verify-token.title',
			})}
			message={intl.formatMessage({
				id: 'auth.reset-password.action.verify-token.message',
			})}
			additional={
				<FormattedMessage
					id="auth.reset-password.action.verify-token.reset-link"
					tagName="p"
					values={{
						link: (
							<Link to={`/auth/reset/${integrationId}`}>
								{intl.formatMessage({
									id: 'auth.reset-password.action.verify-token.reset-link-text',
								})}
							</Link>
						),
					}}
				/>
			}
		/>
	)
);
