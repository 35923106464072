import { FormContentBlock } from './FormContentBlock';

export class TextFormBlock extends FormContentBlock {
	constructor(formBlock) {
		super(formBlock);

		this._body = formBlock.body;
	}

	body() {
		return this._body;
	}
}
