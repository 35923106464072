import MenuPositionBaseService from 'services/application/menu/position/MenuPositionBaseService';
import ImageMenuPosition from 'models/application/menu/position/types/Image';

export default class ImageMenuPositionService extends MenuPositionBaseService {
	toModel({ anchor, ...rest }) {
		return new ImageMenuPosition({
			anchor: anchor && this._anchorService.toModel(anchor),
			...rest,
		});
	}
}
