export interface EmployeeDTO {
	user_id: string;
	user_avatar: string | null;
	user_email: string;
	user_forename: string;
	user_surname: string;
	user_phone?: string;
}

export default class Employee {
	private _userId: string;
	private _avatar: string | null;
	private _email: string;
	private _forename: string;
	private _surname: string;
	private _phone?: string;

	constructor(employee: EmployeeDTO) {
		this._userId = employee.user_id;
		this._avatar = employee.user_avatar;
		this._email = employee.user_email;
		this._forename = employee.user_forename;
		this._surname = employee.user_surname;
		this._phone = employee.user_phone;
	}

	get userId() {
		return this._userId;
	}

	get avatar() {
		return this._avatar;
	}

	get email() {
		return this._email;
	}

	get forename() {
		return this._forename;
	}

	get surname() {
		return this._surname;
	}

	get fullName() {
		return `${this._forename} ${this._surname}`;
	}

	get phone() {
		return this._phone;
	}
}
