import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';

export default function(inProgress, ReactMarkdown, Agreement, applicationService, userService, navigationService) {
	return injectIntl(
		inProgress(
			class UserAgreements extends Component {
				_agreements = {};

				state = {
					confirmCheckboxVisibility: false,
					confirmCheckboxError: false,
				};

				_save() {
					if (!this.state.confirmCheckboxVisibility) {
						this.setState({
							confirmCheckboxVisibility: true,
						});

						return;
					}

					if (!this._confirmCheckbox.checked) {
						this.setState({
							confirmCheckboxError: true,
						});

						return;
					}

					if (this.state.confirmCheckboxError) {
						return;
					}

					if (this._isInProgress('update-agreements')) {
						return;
					}

					this._startProcess('update-agreements');

					const agreements = applicationService
						.settings()
						.agreements()
						.filter(agreement => agreement.checked())
						.map(agreement => ({ id: agreement.id() }));

					userService
						.setAgreements({ agreements })
						.then(() => navigationService.reload())
						.catch(() => this._endProcess('update-agreements'));
				}

				_onConfirmCheckboxChanged(event) {
					this.setState({
						confirmCheckboxError: !event.target.checked,
					});
				}

				render() {
					const { intl } = this.props;

					return (
						<div className="user-agreements wrapper--col align--center justify--center overflow--auto">
							<div className="background" onClick={() => this.props.close()}></div>
							<div className="wrapper--col align--center" style={{ width: '100%' }}>
								<div className="content wrapper--col">
									<form className="wrapper--col el--shrink">
										<section className="agreements-content">
											<h2 className="mb-0">
												<FormattedMessage id="user-details.agreements.title" />
											</h2>
											<button
												type="button"
												className="btn btn-interactive btn-close top-right"
												onClick={() => this.props.close()}
											/>
											{applicationService.settings().GDPRAgreements().length > 0 && (
												<section className="agreements rodo">
													<h3 className="mb-0">
														<FormattedMessage id="user-details.agreements.gdpr.title" />
													</h3>
													<div className="agreements">
														{applicationService
															.settings()
															.GDPRAgreements()
															.map(agreement => (
																<Agreement
																	key={agreement.id()}
																	agreement={agreement}
																	ref={_agreement => {
																		this._agreements[agreement.id()] = _agreement;
																	}}
																	errorJSX={
																		<div className="warning">
																			<p>
																				<FormattedMessage id="user-details.agreements.gdpr.warning" />
																			</p>
																		</div>
																	}
																/>
															))}
													</div>
												</section>
											)}
											{applicationService.settings().requiredAgreements().length > 0 && (
												<section className="agreements">
													<h3 className="mb-0">
														<FormattedMessage id="user-details.agreements.required" />
													</h3>
													<div className="agreements">
														{applicationService
															.settings()
															.requiredAgreements()
															.map(agreement => (
																<Agreement
																	key={agreement.id()}
																	agreement={agreement}
																	ref={_agreement => {
																		this._agreements[agreement.id()] = _agreement;
																	}}
																/>
															))}
													</div>
												</section>
											)}
											{applicationService.settings().optionalAgreements().length > 0 && (
												<section className="agreements">
													<h3 className="mb-0">
														<FormattedMessage id="user-details.agreements.optional" />
													</h3>
													<div className="agreements">
														{applicationService
															.settings()
															.optionalAgreements()
															.map(agreement => (
																<Agreement
																	key={agreement.id()}
																	agreement={agreement}
																	ref={_agreement => {
																		this._agreements[agreement.id()] = _agreement;
																	}}
																/>
															))}
													</div>
												</section>
											)}
										</section>
										<section className="confirm">
											{this.state.confirmCheckboxVisibility && (
												<label className="selectWrapper noMargin wrapper--row justify--center">
													<div className="checkboxWrapper el--shrink">
														<input
															type="checkbox"
															onChange={event => this._onConfirmCheckboxChanged(event)}
															ref={checkbox => {
																this._confirmCheckbox = checkbox;
															}}
														/>
														<div className="checked"></div>
													</div>
													<div className="standardText standardText--noMargin standardText--leftAlign">
														<ReactMarkdown
															source={intl.formatMessage({
																id: 'user-details.agreements.confirm',
															})}
															disableMarkdown
														/>
													</div>
												</label>
											)}
											{this.state.confirmCheckboxError && (
												<div className="errorWrapper align--center">
													<div className="errorMessage rulesError">
														<FormattedMessage id="user-details.agreements.accept-requirements" />
													</div>
												</div>
											)}
											<button
												type="button"
												style={{ marginTop: '10px' }}
												className={classnames('btn__basic', {
													loading: this._isInProgress('update-agreements'),
												})}
												onClick={() => this._save()}
											>
												<FormattedMessage id="user-details.agreements.saveButton" />
											</button>
										</section>
									</form>
								</div>
							</div>
						</div>
					);
				}

				static propTypes = {
					close: PropTypes.func,
					intl: PropTypes.func,
				};
			}
		)
	);
}
