import { useQuery } from 'react-query';
import { EnsureResponseData } from 'utils/type/query/EnsureResponseData';
import Contact from 'models/onboarding/contact/Contact';
import ContactsService from 'services/onboarding/contacts/ContactsService';

export type UseGetContactsProps = {
	'services.onboardingContactsService': ContactsService;
};

export type UseGetContactsResult = EnsureResponseData<{ items: Contact[] }>;

const defaultData = { items: [] };

export const useGetContacts = (props: UseGetContactsProps): UseGetContactsResult => {
	const contactsService = props['services.onboardingContactsService'];
	const contacts = useQuery<{ items: Contact[] }>(['portal-contacts'], () => contactsService.fetchList(), {
		cacheTime: 0,
	});

	return {
		...contacts,
		data: Object.assign({}, defaultData, contacts.data),
	};
};

export default useGetContacts;
