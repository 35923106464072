import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import map from 'lodash/map';

import { CollectionList } from './list/CollectionList';
import { CollectionTiles } from './tiles/CollectionTiles';

import View from 'components/_/common/view/list/ListView';
import { ProgressBar } from 'components/_/common/progress-bar/ProgressBar';
import { Currency } from 'components/_/common/currency/Currency';

const CollectionMission = ({ task }) => {
	return (
		<View
			name="collectionMission"
			i18n={{
				key: 'task-details.collection-mission',
				values: {
					name: task.name(),
				},
			}}
			config={{
				search: false,
			}}
		>
			<Fragment>
				<hr className="d-none d-sm-block mb-4 mb-sm-5" />
				<div className="collectionMission">
					<div className="d-flex flex-column flex-sm-row fs-4">
						<div className="collectionMission__info px-3 py-4 py-sm-0 pr-sm-4">
							<div className="d-flex flex-column d-sm-block">
								<div className="order-2">
									{task.description() && (
										<p className="mb-3 mb-sm-5 description">{task.description()}</p>
									)}
									<div>
										<p className="d-none d-sm-block">
											<FormattedMessage id="task-details.collection-mission.header.your-progress" />
										</p>
										<ProgressBar
											progress={task.state().progress() * 100}
											className="w-100 mt-0 mt-sm-3 mb-0 mb-sm-5"
											tooltip={true}
										/>
									</div>
								</div>
								{task.hasCompletionEarnings() && (
									<div className="order-1 mb-3 mb-sm-0">
										<p className="mb-3 d-none d-sm-block">
											<FormattedMessage id="task-details.collection-mission.content.gain-for-complete-mission" />
										</p>
										<div className="d-flex flex-wrap">
											{map(task.earningsInformation().completion.values(), currency => (
												<Currency
													key={currency.codeName()}
													currency={currency.codeName()}
													value={currency.value()}
													className="mr-3"
												/>
											))}
										</div>
									</div>
								)}
							</div>
						</div>
						{task.viewType() === 'list' ? <CollectionList task={task} /> : <CollectionTiles task={task} />}
					</div>
				</div>
			</Fragment>
		</View>
	);
};

export default CollectionMission;
