import _ from 'lodash';

/**
 *
 */
class Wiring {
	/**
	 *
	 * @param {string} $ref
	 */
	constructor($ref) {
		/**
		 *
		 * @type {string|string}
		 * @private
		 */
		this._$ref = $ref || '$ref';
		/**
		 *
		 * @type {Object}
		 * @private
		 */
		this._resolvedModules = {};
	}

	/**
	 *
	 * @param {Object} modules
	 * @returns {Object}
	 * @throws {Error}
	 */
	resolve(modules) {
		if (!_.isPlainObject(modules)) {
			throw new Error("Passed 'modules' variable must be a plain object.");
		}

		this._modules = this._createMap(modules);

		if (this._resolve()) {
			_.each(this._modules, module => {
				_.each(module.calls, call => {
					const resolvedArgs = _.map(call.args, arg => this._assignReferenceToResolvedModules(arg));

					this._resolvedModules[module.name][call.name](...resolvedArgs);
				});
			});

			return this._resolvedModules;
		} else {
			throw new Error('Error while resolving modules.');
		}
	}

	getResolvedModules() {
		return this._resolvedModules;
	}

	/**
	 *
	 * @param {Object} modules
	 * @returns {Object}
	 * @private
	 */
	_createMap(modules) {
		return _.mapValues(modules, (module, moduleName) => {
			let instance = {
				name: moduleName,
				deps: module.deps || [],
				enhancers: module.enhancers || [],
				class: module.class,
				factory: module.factory,
				calls: module.calls || [],
			};

			instance.canResolve = _.isEmpty(instance.deps);

			if (instance.canResolve) {
				this._resolveModule(instance);
			}

			return instance;
		});
	}

	/**
	 *
	 * @param {Object} module
	 * @private
	 */
	_resolveModule(module) {
		const resolvedDependencies = _.map(module.deps, dependency =>
			this._assignReferenceToResolvedModules(dependency)
		);

		if (!module.class && !module.factory) {
			throw new Error(
				`Error while resolving module: '${module.name}'. Passed 'class' / 'factory' property is undefined or not exported.`
			);
		}

		this._resolvedModules[module.name] = module.class
			? new module.class(...resolvedDependencies)
			: module.factory.apply(module.factory, resolvedDependencies);
	}

	/**
	 *
	 * @returns {boolean}
	 * @private
	 */
	_resolve() {
		_.each(this._modules, module => {
			if (this._resolvedModules[module.name]) {
				return;
			}

			if (
				_.every(module.deps, dependency =>
					_.every(this._collectNestedModules(dependency, []), dependencyModule => {
						if (!dependencyModule) {
							throw new Error(`Error while resolving dependency: '${JSON.stringify(dependency)}'.`);
						}

						return !!dependencyModule.canResolve;
					})
				)
			) {
				module.canResolve = true;

				this._resolveModule(module);
			}
		});

		return _.every(this._modules, module => module.canResolve) ? true : this._resolve();
	}

	/**
	 *
	 * @param {Object} dependency
	 * @returns {Object}
	 * @private
	 */
	_assignReferenceToResolvedModules(dependency) {
		if (dependency && dependency[this._$ref]) {
			return this._resolvedModules[dependency[this._$ref]];
		}

		if (_.isPlainObject(dependency) || _.isArray(dependency)) {
			_.each(dependency, (value, key) => {
				if (value[this._$ref]) {
					dependency[key] = this._resolvedModules[value[this._$ref]];
				} else {
					this._assignReferenceToResolvedModules(value);
				}
			});
		}

		return dependency;
	}

	/**
	 *
	 * @param {Object} dependency
	 * @param {Array} modules
	 * @returns {Array}
	 * @private
	 */
	_collectNestedModules(dependency, modules) {
		if (dependency && this._modules[dependency[this._$ref]]) {
			modules.push(this._modules[dependency[this._$ref]]);

			return modules;
		}

		if (_.isPlainObject(dependency) || _.isArray(dependency)) {
			_.each(dependency, value => {
				if (value[this._$ref] && this._modules[value[this._$ref]]) {
					modules.push(this._modules[value[this._$ref]]);
				} else {
					this._collectNestedModules(value, modules);
				}
			});
		}

		return modules;
	}
}

export default function(modules, $ref) {
	const wiring = new Wiring($ref);

	wiring.resolve(modules);

	return wiring.getResolvedModules();
}
