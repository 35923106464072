import TaskState from 'models/task/state/TaskState';

/**
 * @extends {TaskState}
 */
export default class FormTaskState extends TaskState {
	/**
	 * @param {Object} state
	 */
	constructor(state) {
		super(state);

		if (!state) {
			return;
		}

		/**
		 * @type {null|FormTaskRepetitionState}
		 * @private
		 */
		this._currentRepetitionState = state.current_repetition_state;
		/**
		 * @type {FormTaskRepetitionState[]}
		 * @private
		 */
		this._repetitionStates = state.repetition_states;
	}
}
