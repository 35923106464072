import { Component } from 'react';
import PropTypes from 'prop-types';

export default function(teamService) {
	return class ChooseTeam extends Component {
		state = {
			value: '',
			list: [],
			container: null,
		};

		componentDidMount() {
			if (this.props.disabled) {
				return;
			}

			teamService
				.searchTeams({
					id: this.state.container && this.state.container.id,
				})
				.then(list =>
					this.setState({
						list,
					})
				);
		}

		UNSAFE_componentWillReceiveProps(nextProps) {
			if (nextProps.container === this.props.container) {
				return;
			}

			teamService
				.searchTeams({
					id: nextProps.container && nextProps.container.id,
				})
				.then(list =>
					this.setState({
						container: nextProps.container,
						value: '',
						list,
					})
				);
		}

		_onChange(value) {
			this.props.handler(value);
		}

		_autoCompleteProperties() {
			return {
				wrapperStyle: {},
				value: this.state.value,
				items: this.state.list,
				getItemValue: item => item.name,
				onSelect: (value, item) =>
					this.setState(
						{
							value: item.name,
							list: [],
						},
						() => this._onChange(item)
					),
				onChange: (event, value) => {
					this.setState({
						value,
						list: [],
					});

					if (!value) {
						this._onChange();
					}

					teamService
						.searchTeams({
							id: this.state.container && this.state.container.id,
							search: value,
						})
						.then(list =>
							this.setState({
								list,
							})
						);
				},
			};
		}

		static propTypes = {
			handler: PropTypes.func,
			container: PropTypes.object,
			disabled: PropTypes.bool,
			placeholder: PropTypes.string,
		};
	};
}
