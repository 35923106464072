import React, { PureComponent, createRef } from 'react';

import { Confetti } from './confetti/Confetti';

export default class ConfettiComponent extends PureComponent {
	_canvasRef = createRef();
	_unregisterResizeHandler = null;

	componentDidMount() {
		const confetti = new Confetti(this._canvasRef.current);

		confetti.draw();

		this._unregisterResizeHandler = confetti.registerResizeHandler();
	}

	render() {
		return <canvas id="canvas" className="position-absolute" ref={this._canvasRef} />;
	}

	componentWillUnmount() {
		this._unregisterResizeHandler();
	}
}
