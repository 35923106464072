import QuestionTask from 'models/task/question/questionTask/QuestionTask';
import ChooseQuestion from 'models/task/question/ChooseQuestion';

/**
 * Class representing a question task.
 * @abstract
 * @extends Task
 */
export default class ChoiceQuestionTask extends QuestionTask {
	/**
	 * @param {Object} task
	 */
	constructor(task) {
		super(task);

		/**
		 * @type {boolean}
		 */
		this._multipleAnswers = task.multiple_answers;
		/**
		 * @type {ChooseQuestion}
		 */
		this._question = new ChooseQuestion(task.question);
	}

	/**
	 * @abstract
	 * @returns {boolean}
	 * @throws if method does not set
	 */
	hasMultipleAnswers() {
		throw new Error('hasMultipleAnswers::abstract');
	}

	reset() {
		super.reset();
		this._question.resetAnswers();
	}

	canBeSubmitted() {
		return super.canBeSubmitted() && this._question.hasCheckedAnswers();
	}
}
