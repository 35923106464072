import axios from 'axios';
import { reduce } from 'lodash';
import Style from 'models/application/color/Style';

export default class CSSTransformService {
	constructor(transformers) {
		this._transformers = transformers || [];
	}

	runTransformersPipe(variables) {
		if (process.env.NODE_ENV === 'production') {
			this._runProductionTransformersPipe(variables);
		} else if (process.env.NODE_ENV === 'development') {
			this._runDevelopmentTransformersPipe(variables);
		}
	}

	_runProductionTransformersPipe(variables) {
		const stylesheet = document.head.querySelector('link[rel="stylesheet"]');

		return axios.get(stylesheet.getAttribute('href')).then(({ data: content }) => {
			this._transformStylesheet(variables, content);
		});
	}

	_runDevelopmentTransformersPipe(variables) {
		[].forEach.call(document.head.querySelectorAll('style'), tag => {
			this._transformStylesheet(variables, tag.innerHTML);
		});
	}

	_transformStylesheet(variables, content) {
		const style = new Style();

		const css = reduce(
			this._transformers,
			(result, transformer) => transformer.transform(result, variables),
			content
		);

		style.innerHTML(css);
	}
}
