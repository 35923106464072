import InternalDynamicAnchorAchievement from 'models/application/anchor/types/internalDynamic/types/Achievement';

export default class InternalDynamicAnchorAchievementService {
	constructor(achievementService) {
		this._achievementService = achievementService;
	}

	toModel({ achievement, ...rest }) {
		return new InternalDynamicAnchorAchievement({
			achievement: this._achievementService.toModel(achievement),
			...rest,
		});
	}
}
