import _ from 'lodash';

export default class SwipeMenu {
	_swipeMenuData = {};

	constructor(elementRefs, container) {
		this._elementRefs = elementRefs;
		this._container = container;
	}

	startTouch(passedEvent, element, isContainer) {
		let edgeDistance = window.innerWidth - this._getXCoordinate(passedEvent);
		this._elementRefs.mobileMenu.classList.add('expanding');
		this._swipeMenuData.cursorOutOccured = false;
		this._swipeMenuData.startTouchTime = passedEvent.timeStamp;
		this._swipeMenuData.clientWidth = window.innerWidth;

		if (isContainer && this._swipeMenuData.expanded) {
			passedEvent.preventDefault();
		}

		if (!this._swipeMenuData.expanded && isContainer) {
			if (edgeDistance > SwipeMenu.SWIPE_MENU_CONFIG.ACTIVE_EDGE_AREA) {
				return;
			} else {
				this._swipeMenuData.swipeFromEdge = true;
			}
		}

		if (!this._swipeMenuData.expanded && element === this._elementRefs.mobileMenu) {
			return;
		}

		passedEvent.stopPropagation();

		this._swipeMenuData.startX = this._getXCoordinate(passedEvent);
		element.addEventListener('touchmove', (this.__cachedMoveEvent = event => this._registerMoveHandler(event)));
	}

	_registerMoveHandler(event) {
		this._elementRefs.mobileMenu.classList.remove('expanding');

		if (!this._swipeMenuData.blockEvents) {
			this._menuSwipeHandle(event);
		}
	}

	_menuSwipeHandle(event) {
		let currentMove;
		let edgeDistance = this._swipeMenuData.clientWidth - this._getXCoordinate(event);

		if (edgeDistance > SwipeMenu.SWIPE_MENU_CONFIG.MOBILE_MENU_WIDTH) {
			this._swipeMenuData.cursorOut = true;
		} else {
			currentMove = this._currentMove(event);

			if (currentMove < 0 && (this._swipeMenuData.expanded || this._swipeMenuData.cursorOutOccured)) {
				return;
			}

			if (this._swipeMenuData.swipeFromEdge) {
				this._elementRefs.mobileMenu.style.right = this._swipeMenuData.cursorOutOccured
					? `${-currentMove}px`
					: `${-SwipeMenu.SWIPE_MENU_CONFIG.MOBILE_MENU_WIDTH - currentMove}px`;
			} else if (
				this._swipeMenuData.expanded &&
				0 - currentMove < 0 &&
				0 - currentMove >= -SwipeMenu.SWIPE_MENU_CONFIG.MOBILE_MENU_WIDTH
			) {
				this._elementRefs.mobileMenu.style.right = `${-currentMove}px`;
			}
		}
	}

	_currentMove(event) {
		let currentPosition = this._getXCoordinate(event);

		if (this._swipeMenuData.cursorOut) {
			this._swipeMenuData.startX = this._getXCoordinate(event);
			this._swipeMenuData.cursorOut = false;
			this._swipeMenuData.cursorOutOccured = true;
		}

		return currentPosition - this._swipeMenuData.startX;
	}

	scrollHandler() {
		this._swipeMenuData.blockEvents = true;
		this._elementRefs.mobileMenu.style.right = '0px';
	}

	containerEndTouchController(event) {
		this._elementRefs.mobileMenu.classList.add('expanding');

		if (this._clickOccured(event) && this._swipeMenuData.expanded) {
			this._swipeMenuData.cursorOut = false;
			this._menuVisibilitySwitch(true);
		} else {
			this._endSwipeHandle();
		}
	}

	endTouchController(event) {
		this._elementRefs.mobileMenu.classList.add('expanding');
		event.stopPropagation();

		if (this._clickOccured(event)) {
			this._swipeMenuData.cursorOut = false;
			this._menuVisibilitySwitch(this._swipeMenuData.expanded);
		} else {
			this._endSwipeHandle();
		}
	}

	_endSwipeHandle() {
		let currentMenuPosition;
		this._swipeMenuData.swipeFromEdge = false;
		this._unregisterMoveEvent();

		if (this._swipeMenuData.blockEvents) {
			this._swipeMenuData.blockEvents = false;
		} else {
			currentMenuPosition =
				_.parseInt(this._elementRefs.mobileMenu.style.right) || -SwipeMenu.SWIPE_MENU_CONFIG.MOBILE_MENU_WIDTH;
			this._menuVisibilitySwitch(currentMenuPosition < -(SwipeMenu.SWIPE_MENU_CONFIG.MOBILE_MENU_WIDTH / 2));
		}
	}

	_menuVisibilitySwitch(hide) {
		this._elementRefs.mobileMenu.style.right = null;
		this._swipeMenuData.expanded = !hide;

		if (hide && this._elementRefs.mobileMenu.classList.contains('expanded')) {
			this._elementRefs.mobileMenu.classList.remove('expanded');
			this._unregisterMoveEvent();
		} else if (!hide && !this._elementRefs.mobileMenu.classList.contains('expanded')) {
			this._elementRefs.mobileMenu.classList.add('expanded');
		}
	}

	_unregisterMoveEvent() {
		const {
			parent: { container },
		} = this._container;

		container.removeEventListener('touchmove', this.__cachedMoveEvent, false);
	}

	_clickOccured(event) {
		return event.timeStamp - this._swipeMenuData.startTouchTime < 150;
	}

	_getXCoordinate(event) {
		return event.changedTouches[0].clientX;
	}

	static SWIPE_MENU_CONFIG = {
		MOBILE_MENU_WIDTH: 375,
		ACTIVE_EDGE_AREA: 30,
	};
}
