import entryBlocks from 'views/application/entryBlocks';
import landingPage from 'views/application/landingPage';
import realTimeNotifications from 'views/application/realTimeNotifications';
import requiredAgreements from 'views/application/requiredAgreements';
import AccessPopup from 'views/application/AccessPopup';
import LanguageSwitcher from 'views/application/LanguageSwitcher';

export default {
	...entryBlocks,
	...landingPage,
	...realTimeNotifications,
	...requiredAgreements,
	'components.AccessPopup': {
		factory: AccessPopup,
		deps: [{ $ref: 'services.accessPopupService' }],
	},
	'components.LanguageSwitcher': {
		factory: LanguageSwitcher,
		deps: [{ $ref: 'components.base.LanguageSwitcher' }, { $ref: 'services.i18nService' }],
	},
};
