import { createUseStyles } from 'react-jss';

export default (() => {
	const size = 1;

	return createUseStyles({
		currency: {},
		img: {
			height: `${size * 1.1}em`,
		},
		amount: {
			fontSize: `${size}em`,
			fontWeight: 600,
			marginLeft: `${size / 3}em`,
		},
	});
})();
