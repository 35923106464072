import React, { Component } from 'react';
import _ from 'lodash';
import RGB from 'models/application/color/RGB';
import shortid from 'shortid';

export default function(colorService) {
	return class Palette extends Component {
		render() {
			let definitions = _.chunk(_.values(colorService.definitions()), 2);

			return (
				<table>
					{_.map(definitions, definition => (
						<tbody key={shortid()}>
							<tr>
								<th>{this._label(definition[0])}</th>
								<th>{this._label(definition[1])}</th>
							</tr>
							<tr>
								<td>{this._input(definition[0])}</td>
								<td>{this._input(definition[1])}</td>
							</tr>
						</tbody>
					))}
				</table>
			);
		}

		_label(definition) {
			if (!definition) {
				return;
			}

			return `${definition.name().slice(7)}:`;
		}

		_input(definition) {
			if (!definition) {
				return;
			}

			return (
				<input
					type="color"
					defaultValue={definition.rgb().toHex()}
					onInput={event => this._onChange(definition.name(), event.target.value)}
				/>
			);
		}

		_onChange(name, color) {
			color = RGB.hexToRgb(color);

			colorService.update(name, color);
		}
	};
}
