import { createUseStyles } from 'react-jss';

export default (() => {
	return createUseStyles(theme => ({
		middle: {
			'& > div': {
				[`@media (min-width: ${theme.grid.md}px)`]: {
					borderLeft: `1px solid ${theme.color.system['color--content--foreground-10']}`,
					borderRight: `1px solid ${theme.color.system['color--content--foreground-10']}`,
				},
			},
			'& img': {
				maxWidth: '100%',
			},
		},
		label: {
			color: theme.color.system['color--content--foreground'],
			fontSize: '1rem',
		},
		value: {
			color: theme.color.system['color--content--foreground-60'],
			fontSize: '0.9rem',
		},
		price: {
			fontSize: '1rem',
		},
		progress: {
			fontSize: '1rem',
		},
	}));
})();
