import React, { Fragment } from 'react';
import classnames from 'classnames';

export type ActionDescriptionProps = {
	elements: Array<React.ReactElement | string | null | undefined | false>;
	alwaysColumn?: boolean;
};

const ActionDescription = ({ elements, alwaysColumn }: ActionDescriptionProps) => {
	return (
		<div
			className={classnames('action__description d-flex flex-column flex-wrap fs-mobile fs-sm-4', {
				'flex-sm-row': !alwaysColumn,
			})}
		>
			{elements
				.filter(_ => _)
				.map((component, index, arr) => (
					<Fragment key={index}>
						<div className="mt-1 mt-sm-0">
							{component}
							{index < arr.length - 1 && (
								<span
									className={classnames('action__description__separator d-none mx-2', {
										'd-sm-inline-block': !alwaysColumn,
									})}
								>
									|
								</span>
							)}
						</div>
					</Fragment>
				))}
		</div>
	);
};

export default ActionDescription;
