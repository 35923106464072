export async function register() {
	if (!('serviceWorker' in navigator)) {
		throw new Error('Service workers are not supported.');
	}

	const serviceWorkerLocation = '/serviceWorker.js';
	const { NODE_ENV } = process.env;
	const scriptURL =
		NODE_ENV === 'production' ? `${serviceWorkerLocation}?${window.buildNonce}` : serviceWorkerLocation;

	return await navigator.serviceWorker.register(scriptURL, {
		scope: '/',
	});
}

export async function unregister() {
	if (!('serviceWorker' in navigator)) {
		throw new Error('Service workers are not supported.');
	}

	const registration = await navigator.serviceWorker.ready;

	return registration.unregister();
}
