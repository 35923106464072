import { Confetti } from './Confetti';

export class ConfettiParticle {
	static POSSIBLE_COLORS = [
		'rgb(85,71,106)',
		'rgb(174,61,99)',
		'rgb(244,92,68)',
		'rgb(219,56,83)',
		'rgb(248,182,70)',
	];

	constructor({ x, y, r }) {
		this.x = x;
		this.y = y;
		this.r = r;
		this.d = Math.random() * Confetti.MAX_CONFETTIS + 11;
		this.color = this.constructor.POSSIBLE_COLORS[
			Math.floor(Math.random() * this.constructor.POSSIBLE_COLORS.length)
		];
		this.tilt = Math.floor(Math.random() * 33) - 11;
		this.tiltAngleIncremental = Math.random() * 0.12 + 0.05;
		this.tiltAngle = 0;
	}
}
