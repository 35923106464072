import AnchorService from 'services/application/anchor/AnchorService';
import subServices from 'services/application/anchor/subServices';

export default {
	'services.anchorService': {
		class: AnchorService,
		calls: [
			{
				name: 'registerSubService',
				args: ['dynamic-internal', { $ref: 'services.internalDynamicAnchorService' }],
			},
			{
				name: 'registerSubService',
				args: ['static-internal', { $ref: 'services.internalStaticAnchorService' }],
			},
			{
				name: 'registerSubService',
				args: ['external', { $ref: 'services.externalAnchorService' }],
			},
		],
	},
	...subServices,
};
