import moment, { Moment } from 'moment';
import map from 'lodash/map';
import ProcessRole, { ProcessRoleDTO } from './ProcessRole';
import { WorkflowCategory } from 'models/onboarding/workflow/WorkflowCategory';

export interface ProcessDTO {
	process_id: string;
	duration_in_days: number;
	progress: number;
	first_day: string;
	process_should_end_at: number;
	workflow_name?: string;
	workflow_category: WorkflowCategory;
	next_action_date: string | null;
	actions_count: number;
	actions_done: number;
	actions_delayed?: number;
	user_id: string;
	user_avatar?: string | null;
	user_forename?: string | null;
	user_surname?: string | null;
	manager_id: string;
	manager_avatar?: string | null;
	manager_forename?: string | null;
	manager_surname?: string | null;
	roles?: ProcessRoleDTO[];
}

export default class Process {
	private _id: string;
	private _durationInDays: number;
	private _progress: number;
	private _firstDay: Moment;
	private _processShouldEndAt: Moment;
	private _name?: string;
	private _workflowCategory: WorkflowCategory;
	private _nextActionDate: Moment | null;
	private _actionsCount: number;
	private _actionsDone: number;
	private _actionsDelayed: number;
	private _userId: string;
	private _userAvatar?: string | null;
	private _userForename?: string | null;
	private _userSurname?: string | null;
	private _managerId: string;
	private _managerAvatar?: string | null;
	private _managerForename?: string | null;
	private _managerSurname?: string | null;
	private _roles: ProcessRole[];

	constructor(process: ProcessDTO) {
		this._id = process.process_id;
		this._durationInDays = process.duration_in_days;
		this._progress = process.progress;
		this._firstDay = moment(process.first_day);
		this._processShouldEndAt = moment(process.process_should_end_at);
		this._name = process.workflow_name;
		this._workflowCategory = process.workflow_category;
		this._nextActionDate = process.next_action_date ? moment(process.next_action_date) : null;
		this._actionsCount = process.actions_count;
		this._actionsDone = process.actions_done;
		this._actionsDelayed = process.actions_delayed || 0;
		this._userId = process.user_id;
		this._userAvatar = process.user_avatar;
		this._userForename = process.user_forename;
		this._userSurname = process.user_surname;
		this._managerId = process.manager_id;
		this._managerAvatar = process.manager_avatar;
		this._managerForename = process.manager_forename;
		this._managerSurname = process.manager_surname;
		this._roles = map(process.roles, role => new ProcessRole(role));
	}

	get id() {
		return this._id;
	}

	get durationInDays() {
		return this._durationInDays;
	}

	get progress() {
		return this._progress;
	}

	get firstDay() {
		return this._firstDay;
	}

	get processShouldEndAt() {
		return this._processShouldEndAt;
	}

	get name() {
		return this._name;
	}

	get workflowCategory() {
		return this._workflowCategory;
	}

	get nextActionDate() {
		return this._nextActionDate;
	}

	get actionsCount() {
		return this._actionsCount;
	}

	get actionsDone() {
		return this._actionsDone;
	}

	get actionsDelayed() {
		return this._actionsDelayed;
	}

	get userId() {
		return this._userId;
	}

	get user() {
		return {
			avatar: this._userAvatar,
			forename: this._userForename,
			surname: this._userSurname,
			fullName: `${this._userForename || ''} ${this._userSurname || ''}`,
		};
	}

	get managerId() {
		return this._managerId;
	}

	get manager() {
		return {
			avatar: this._managerAvatar,
			forename: this._managerForename,
			surname: this._managerSurname,
			fulName: `${this._managerForename || ''} ${this._managerSurname || ''}`,
		};
	}

	get roles() {
		return this._roles;
	}
}
