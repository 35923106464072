export default class Declaration {
	constructor(declaration, checked) {
		this._id = declaration.id;
		this._body = declaration.body;
		this._checked = checked || false;
	}

	id() {
		return this._id;
	}

	body() {
		return this._body;
	}

	isChecked() {
		return this._checked;
	}

	toggleCheck() {
		this._checked = !this._checked;
	}
}
