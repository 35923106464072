import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import Async from 'react-async-action';
import { FormattedMessage } from 'react-intl';

import CreativeChallengeSubmissionDetails from './Details.template';
import Loader from 'components/commons/_/loader/Loader';
import Countdown from 'components/commons/Countdown';

export class CreativeChallengeSubmissionDetailsComponent extends PureComponent {
	render() {
		return (
			<Async action={() => this._action()} params={{ id: this.props.match.params.id }}>
				{({ reload }) => (
					<React.Fragment>
						<Async.Loading>
							<Loader />
						</Async.Loading>
						<Async.Resolved>
							{({ response }) => (
								<CreativeChallengeSubmissionDetails
									{...response}
									Image={this.props['components.Image']}
									services={{
										userProfileService: this.props['services.userProfileService'],
										modalService: this.props['services.modalService'],
										accessPopupService: this.props['services.accessPopupService'],
									}}
									reload={reload}
									button={() => this._button(response.submission)}
									onScore={score => this._onScore(score, response.submission, reload)}
									onLike={() =>
										this._onLike(response.userJudgement.liked, response.submission, reload)
									}
								/>
							)}
						</Async.Resolved>
					</React.Fragment>
				)}
			</Async>
		);
	}

	_action() {
		return this.props['services.challengeSubmissionService'].fetchSubmissionDetails({
			id: this.props.match.params.id,
		});
	}

	_button({ challenge }) {
		const userProfileService = this.props['services.userProfileService'];
		const isOwner =
			userProfileService.user().membership &&
			(userProfileService
				.user()
				.membership.roles()
				.isManager() ||
				userProfileService
					.user()
					.membership.roles()
					.isAdmin());
		const isParticipationTeamMode = challenge.isParticipationTeamMode();

		if (challenge.hasFinished()) {
			return null;
		}

		return isParticipationTeamMode && !isOwner ? (
			<FormattedMessage id="challenge-details.creative.button.only-for-manager" />
		) : challenge.limitReached() ? (
			<FormattedMessage id="challenge-details.creative.button.limit-reached" />
		) : challenge.state().canParticipate() ? (
			<FormattedMessage id="challenge-details.creative.button.take-part" />
		) : challenge.state().cooldownEnd() ? (
			<React.Fragment>
				<FormattedMessage id="challenge-details.creative.button.add-submission-in" />
				<span className="countdown">
					<Countdown date={challenge.state().cooldownEnd()} endHandler={() => this._countDownEndHandler()} />
				</span>
			</React.Fragment>
		) : null;
	}
}

export default wire(
	[
		'services.challengeSubmissionService',
		'services.userProfileService',
		'services.modalService',
		'services.accessPopupService',
		'components.Image',
	],
	CreativeChallengeSubmissionDetailsComponent
);
