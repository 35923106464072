import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

export default () => {
	return function declarationTaskButton(args) {
		const { notification, close } = args;
		const task = notification.task();

		const navProps = {
			className: 'btn__basic',
		};

		if (notification.accepted()) {
			navProps.to = '';
			navProps.onClick = event => {
				event.preventDefault();

				close();
			};
		} else {
			navProps.to = `/task/${task.id()}/details`;
			navProps.onClick = () => close();
		}

		return (
			<NavLink {...navProps}>
				<FormattedMessage
					id={`notification.real-time.${notification.type()}.button.${
						notification.accepted() ? 'default' : 'task'
					}`}
				/>
			</NavLink>
		);
	};
};
