/**
 * @abstract
 */
export class AnswerMode {
	/**
	 * @param {Object} answer
	 */
	constructor(answer) {
		/**
		 * @type {string} id
		 */
		this._id = answer.id;
		/**
		 * @type {string} id
		 */
		this._body = answer.body;
		/**
		 * @type {string} id
		 */
		this._mode = answer.mode;
		/**
		 * @type {string} id
		 */
		this._type = answer.type;
		/**
		 * @type {null|boolean} id
		 */
		this._value = null;
	}

	/**
	 * @returns {string}
	 */
	id() {
		return this._id;
	}

	/**
	 * @returns {string}
	 */
	body() {
		return this._body;
	}

	/**
	 * @returns {string}
	 */
	mode() {
		return this._mode;
	}

	/**
	 * @returns {boolean}
	 */
	value() {
		return this._value;
	}

	setValue(value) {
		this._value = value;
	}

	reset() {
		this.setValue(null);
	}

	/**
	 * @returns {boolean}
	 */
	hasValue() {
		return this._value !== null;
	}
}
