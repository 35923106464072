import choice from 'components/task/types/question/choice';
import order from 'components/task/types/question/order';
import phrase from 'components/task/types/question/phrase';
import trueFalse from 'components/task/types/question/trueFalse';

export default {
	...choice,
	...order,
	...phrase,
	...trueFalse,
};
