// adapted from: https://stackoverflow.com/questions/10333971/html5-pre-resize-images-before-uploading

import EXIF from 'exif-js';

export default class ImageScaler {
	scaleImage(imagePromise, opts = {}) {
		const defaults = {
			maxWidth: 1920,
			maxHeight: 1200,
			quality: 0.85,
			maxSize: 2 * 1000 * 1000,
			autoRotate: true,
			quick: true,
		};
		const { maxWidth, maxHeight, quality, convertToType, maxSize, autoRotate, quick } = Object.assign(
			{},
			defaults,
			opts
		);

		if (typeof imagePromise === 'string' && imagePromise.slice(0, 10) === 'data:image') {
			imagePromise = new Promise(resolve => {
				var image = new Image();
				image.src = imagePromise;
				image.onload = () => resolve(image);
			});
		}

		let canvas = document.createElement('canvas');

		return Promise.resolve(imagePromise)
			.then(img => {
				this._prepareCanvas(canvas, img, autoRotate);

				return img;
			})
			.then(img => {
				if (quick) {
					if (canvas.width > maxWidth || canvas.height > maxHeight) {
						canvas = this._scaleCanvasToTarget(canvas, {
							maxWidth,
							maxHeight,
						});
					}
				} else {
					while (canvas.width >= 2 * maxWidth && canvas.height >= 2 * maxHeight) {
						canvas = this._getHalfScaleCanvas(canvas);
					}

					if (canvas.width > maxWidth || canvas.height > maxHeight) {
						canvas = this._scaleCanvasToTarget(canvas, {
							maxWidth,
							maxHeight,
						});
					}
				}

				let dataUrl = canvas.toDataURL(convertToType || img.type, quality);

				// 4/3 is size penalty for base64, in which dataUrl is encoded
				while (dataUrl.length > (maxSize * 4) / 3) {
					canvas = this._getHalfScaleCanvas(canvas);
					dataUrl = canvas.toDataURL(convertToType || img.type, quality);
				}

				return dataUrl;
			});
	}

	_prepareCanvas(canvas, img, autoRotate) {
		return new Promise(resolve => {
			// apparently EXIF.getData calls callback with `this` set...
			// and it can sometimes return false, instead of calling callback
			if (
				!autoRotate ||
				!EXIF.getData(img, function() {
					resolve(EXIF.getTag(this, 'Orientation') || 1); // eslint-disable-line no-invalid-this
				})
			) {
				resolve(1);
			}
		}).then(orientation => {
			canvas.width = orientation <= 4 ? img.width : img.height;
			canvas.height = orientation <= 4 ? img.height : img.width;
			let ctx = canvas.getContext('2d');
			ctx.save();

			switch (orientation) {
				case 2:
					ctx.transform(-1, 0, 0, 1, img.width, 0);
					break;
				case 3:
					ctx.transform(-1, 0, 0, -1, img.width, img.height);
					break;
				case 4:
					ctx.transform(1, 0, 0, -1, 0, img.height);
					break;
				case 5:
					ctx.transform(0, 1, 1, 0, 0, 0);
					break;
				case 6:
					ctx.transform(0, 1, -1, 0, img.height, 0);
					break;
				case 7:
					ctx.transform(0, -1, -1, 0, img.height, img.width);
					break;
				case 8:
					ctx.transform(0, -1, 1, 0, 0, img.width);
					break;
				default:
					ctx.transform(1, 0, 0, 1, 0, 0);
			}

			ctx.drawImage(img, 0, 0, img.width, img.height);
			ctx.restore();
		});
	}

	_getHalfScaleCanvas(canvas) {
		var halfCanvas = document.createElement('canvas');
		halfCanvas.width = canvas.width / 2;
		halfCanvas.height = canvas.height / 2;

		halfCanvas.getContext('2d').drawImage(canvas, 0, 0, halfCanvas.width, halfCanvas.height);

		return halfCanvas;
	}

	_scaleCanvasToTarget(canvas, { maxWidth, maxHeight }) {
		let scaledCanvas = document.createElement('canvas');
		let scale = Math.min(maxWidth / canvas.width, maxHeight / canvas.height);

		scaledCanvas.width = canvas.width * scale;
		scaledCanvas.height = canvas.height * scale;

		scaledCanvas.getContext('2d').drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height);

		return scaledCanvas;
	}
}
