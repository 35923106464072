import React, { Fragment, useMemo } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

const TermsAndConditions = ({ termsAndConditions }) => {
	const elements = useMemo(() => {
		const list = [];

		if (termsAndConditions.termsAndConditions) {
			list.push(
				<a
					href={termsAndConditions.termsAndConditions}
					target="_blank"
					rel="noopener noreferrer"
					className="align--center justify--between"
				>
					<FormattedMessage id={'terms-and-conditions.terms-and-conditions'} />
				</a>
			);
		}

		if (termsAndConditions.privacyPolicy) {
			list.push(
				<a
					href={termsAndConditions.privacyPolicy}
					target="_blank"
					rel="noopener noreferrer"
					className="align--center justify--between"
				>
					<FormattedMessage id={'terms-and-conditions.privacy-policy'} />
				</a>
			);
		}

		if (termsAndConditions.cookiePolicy) {
			list.push(
				<a
					href={termsAndConditions.cookiePolicy}
					target="_blank"
					rel="noopener noreferrer"
					className="align--center justify--between"
				>
					<FormattedMessage id={'terms-and-conditions.cookie-policy'} />
				</a>
			);
		}

		return list;
	}, [termsAndConditions]);

	if (!elements.length) {
		return null;
	}

	return (
		<div className="d-flex flex-wrap justify--center mt-3">
			{elements.map((component, index, arr) => (
				<Fragment key={index}>
					<div className="mt-1 mt-sm-0">
						{component}
						{index < arr.length - 1 && <span className={classnames('inline-block mx-2')}>|</span>}
					</div>
				</Fragment>
			))}
		</div>
	);
};

export default TermsAndConditions;
