import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Formik, Form, FieldArray, Field } from 'formik';

export default function(Layout) {
	return class RetypePhraseQuestionTask extends PureComponent {
		render() {
			const { task, goBack } = this.props;

			return (
				<Formik
					initialValues={{
						answers: {},
					}}
					onReset={() => this.props.task.reset()}
					onSubmit={({ answers }, actions) =>
						this.props
							.process({
								answers,
							})
							.then(() => actions.setSubmitting(false))
					}
				>
					{({ values: { answers }, handleReset, isSubmitting }) => (
						<Form className="form d-flex flex-grow-1">
							<Layout
								task={task}
								actions={{
									submit: {},
									goBack,
									tryAgain: handleReset,
									isSubmitting,
								}}
							>
								<div className="p-2 p-md-0">
									<p className="fs-4 text-center mb-3 mb-md-4 color-content">
										<FormattedMessage id="task-details.retype-phrase-question.content.task-description" />
									</p>
									<div className="row no-gutters">
										<div className="col-24 col-md-12 order-last order-md-first text-center mx-auto">
											<p className="fs-7 color-content" style={{ lineHeight: 1.2 }}>
												<FieldArray
													render={() =>
														task
															.question()
															.words()
															.map((word, index) =>
																word.highlight ? (
																	<Field
																		type="text"
																		className="text-center p-1 my-1 mx-2"
																		size={word.body.length}
																		key={index}
																		placeholder={word.body}
																		value={`${answers[index] || ''}`}
																		name={`answers.${index}`}
																		autoFocus={word.firstHighlight}
																		disabled={
																			task.state().hasStatus('completed') ||
																			task.isCompleted() ||
																			task.isSubmitted()
																		}
																	/>
																) : (
																	word.body
																)
															)
													}
												/>
											</p>
										</div>
										{task.question().image() ? (
											<div className="col-24 col-md-12 order-first order-md-last mb-3">
												<div className="ml-0 ml-md-3">
													<img
														src={task.question().image()}
														alt=""
														className="mw-100 d-block m-auto rounded"
													/>
												</div>
											</div>
										) : null}
									</div>
								</div>
							</Layout>
						</Form>
					)}
				</Formik>
			);
		}
	};
}
