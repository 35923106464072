import React from 'react';
import PropTypes from 'prop-types';
import chroma from 'chroma-js';

const Circle = ({ size, top, left, opacity, blur, config }) => {
	const style = {
		width: `${size}px`,
		height: `${size}px`,
		top: `${top}px`,
		left: `${left}px`,
		opacity,
		filter: `blur(${blur}px)`,
	};

	if (config.colorScheme && config.colorScheme.content) {
		style.backgroundColor = chroma(config.colorScheme.content.background).brighten();
	}

	return <div className="circle" style={style} />;
};

Circle.propTypes = {
	size: PropTypes.number,
	top: PropTypes.number,
	left: PropTypes.number,
	opacity: PropTypes.number,
	blur: PropTypes.number,
	config: PropTypes.object,
};

export default Circle;
