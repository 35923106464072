import NotificationService from 'services/notification/NotificationService';

import subServices from 'services/notification/subServices';

export default {
	'services.notificationService': {
		class: NotificationService,
		deps: [{ $ref: 'services.APIService' }, { $ref: 'services.logService' }],
		calls: [
			{
				name: 'registerSubService',
				args: ['AchievementNotification', { $ref: 'services.achievementNotificationService' }],
			},
			{
				name: 'registerSubService',
				args: ['BonusTransactionNotification', { $ref: 'services.bonusTransactionNotificationService' }],
			},
			{
				name: 'registerSubService',
				args: [
					'ChallengeAwardedPlaceNotification',
					{
						$ref: 'services.challengeAwardedPlaceNotificationService',
					},
				],
			},
			{
				name: 'registerSubService',
				args: [
					'ChallengeSubmissionAddedNotification',
					{ $ref: 'services.challengeSubmissionAddedNotification' },
				],
			},
			{
				name: 'registerSubService',
				args: [
					'ChallengeSubmissionStateChangedNotification',
					{
						$ref: 'services.challengeSubmissionStateChangedNotificationService',
					},
				],
			},
			{
				name: 'registerSubService',
				args: [
					'DeclarationTaskAcceptanceChangedNotification',
					{
						$ref: 'services.declarationTaskAcceptanceChangedNotificationService',
					},
				],
			},
			{
				name: 'registerSubService',
				args: ['EmailNotification', { $ref: 'services.emailNotificationService' }],
			},
			{
				name: 'registerSubService',
				args: ['ItemNotification', { $ref: 'services.itemNotificationService' }],
			},
			{
				name: 'registerSubService',
				args: ['LevelUpNotification', { $ref: 'services.levelUpNotificationService' }],
			},
			{
				name: 'registerSubService',
				args: [
					'TargetChallengeCalculatedNotification',
					{ $ref: 'services.targetChallengeCalculatedNotificationService' },
				],
			},
			{
				name: 'registerSubService',
				args: ['TaskNotification', { $ref: 'services.taskNotificationService' }],
			},
		],
	},
	...subServices,
};
