import { CommitmentRankingEntry } from './CommitmentRankingEntry';
import UserProfile from '../../../user/UserProfile';

/**
 * Class representing a user commitment ranking entry.
 * @extends CommitmentRankingEntry
 */
export class UserCommitmentRankingEntry extends CommitmentRankingEntry {
	constructor(entry) {
		super(entry);

		/**
		 * @type {UserProfile}
		 */
		this.userProfile = new UserProfile(entry.user_profile);
	}
}
