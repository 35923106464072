import React from 'react';

import { Markdown } from 'components/_/common/text/markdown/Markdown';

export const TextContent = ({ formBlock }) => {
	return (
		<div className="fs-4">
			<Markdown className="w-100">{formBlock.body()}</Markdown>
		</div>
	);
};
