import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function creativeProgressStatus({ challenge, canParticipate }) {
	return (
		<div className="wrapper--row align--center el--grow justify--between statusWrapper">
			{challenge.status().type === 'notStarted' ? (
				<div className="status wrapper--row notStarted el--shrink">
					<div className="standardText standardText--noMargin">
						<FormattedMessage
							id={`challenge-list.content.challenge-status.${challenge.status().type}`}
							values={{
								remainingTime: <strong>{` ${challenge.status().remainingTime}`}</strong>,
							}}
						/>
					</div>
				</div>
			) : (
				<div
					className={classnames('progressBarWrapper wrapper--row align--center el--grow', {
						canParticipate: canParticipate,
					})}
				>
					<div className="progressBar__status el--shrink">
						<div className="standardText standardText--noMargin">
							{challenge.status().type === 'inProgress' ? (
								<React.Fragment>
									<FormattedMessage id="challenge-details.creative.content.remains" />
									{canParticipate && (
										<strong className="visible--mobile">{challenge.status().remainingTime}</strong>
									)}
								</React.Fragment>
							) : (
								<FormattedMessage
									id={`challenge-list.content.challenge-status.${challenge.status().type}`}
								/>
							)}
						</div>
					</div>
					<div className="progressBar">
						<div
							className="progressBar__content"
							style={{ width: `${challenge.status().progress}%` }}
						></div>
					</div>
					<strong
						className={classnames('el--shrink', {
							'hidden--mobile': canParticipate,
						})}
					>
						{challenge.status().remainingTime}
					</strong>
				</div>
			)}
		</div>
	);
}

creativeProgressStatus.propTypes = {
	challenge: PropTypes.object,
	canParticipate: PropTypes.bool,
};

export default creativeProgressStatus;
