export class PWAWebPushService {
	constructor(APIService, applicationService, userService) {
		this.APIService = APIService;

		this._applicationService = applicationService;
		this._userService = userService;
		this._applicationServerKey = null;
		this._registartion = null;
	}

	setRegistration(registration) {
		this._registartion = registration;
	}

	async subscribe() {
		if (!navigator.serviceWorker || !window.Notification || !navigator.permissions || !this._registartion) {
			return;
		}

		this._applicationServerKey = this._urlB64ToUint8Array(this._applicationService.vapidPublicKey());

		const permissionState = await window.Notification.requestPermission();

		this._handlePermissionState(permissionState);

		this._registartion.onupdatefound = async () => {
			await this._registartion.update();

			window.location.reload();
		};

		this._registartion.oncontrollerchange = () => {
			window.location.reload();
		};

		navigator.permissions.query({ name: 'notifications' }).then(permission => {
			permission.onchange = () => this._handlePermissionState(permission.state);
		});
	}

	async _handlePermissionState(permissionState) {
		if (permissionState === 'denied' || permissionState === 'default') {
			console.warn('User did not allow web-push notification.');
		} else if (permissionState === 'granted') {
			console.info('Notification permissions granted.');

			const subscription =
				(await this._registartion.pushManager.getSubscription()) ||
				(await this._registartion.pushManager.subscribe({
					userVisibleOnly: true,
					applicationServerKey: this._applicationServerKey,
				}));

			if (this._userService.isLogged() && this._userService.isNonAnonymous()) {
				this._push(subscription);
			}
		}
	}

	_push(subscription) {
		this.APIService.post('v1/push-subscription', subscription);
	}

	_urlB64ToUint8Array(base64String) {
		const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
		const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

		const rawData = window.atob(base64);
		const outputArray = new Uint8Array(rawData.length);

		for (let i = 0; i < rawData.length; ++i) {
			outputArray[i] = rawData.charCodeAt(i);
		}
		return outputArray;
	}
}
