import ColorService from 'services/application/color/ColorService';

export default {
	'services.colorService': {
		class: ColorService,
		deps: [
			{ $ref: 'services.applicationService' },
			{ $ref: 'services.CSSTransformService' },
			{ $ref: 'config.colors' },
		],
	},
};
