export default class Transaction {
	constructor(transaction) {
		this._amounts = transaction.amounts;
		this._links = transaction.links || [];
		this._transactionTimestamp = transaction.transaction_timestamp;
	}

	amounts() {
		return this._amounts;
	}

	transactionTimestamp() {
		return this._transactionTimestamp;
	}
}
