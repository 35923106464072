import React from 'react';
import Async from 'react-async-action';

import GamfiGraph from 'gamfi-graph/dist/app';

import Loader from 'components/commons/_/loader/Loader';

export default function(GraphTask) {
	return class MapTask extends GraphTask {
		render() {
			return (
				<Async action={() => this._action()}>
					<Async.Loading>
						<div className="map">
							<Loader />
						</div>
					</Async.Loading>
					<Async.Resolved>
						{({ response: graph }) => (
							<div className="map" ref={graphParent => this._assignGraphParent(graphParent)}>
								<GamfiGraph graph={graph} {...this.properties()} />
								<div className="sectionZoom wrapper--row align--center">
									<div className="roundNumber" onClick={() => this._increase()}>
										<div className="number plus" />
									</div>
									<div className="roundNumber" onClick={() => this._decrease()}>
										<div className="number minus" />
									</div>
								</div>
							</div>
						)}
					</Async.Resolved>
				</Async>
			);
		}
	};
}
