export interface RGBDTO {
	red: number;
	green: number;
	blue: number;
}

export default class RGB {
	public red: number;
	public green: number;
	public blue: number;

	constructor({ red, green, blue }: RGBDTO) {
		this.red = red;
		this.green = green;
		this.blue = blue;
	}

	update({ red, green, blue }: RGBDTO) {
		this.red = red;
		this.green = green;
		this.blue = blue;
	}

	toString() {
		return `rgb(${this.red}, ${this.green}, ${this.blue})`;
	}

	toHex() {
		return '#' + this._toHex(this.red) + this._toHex(this.green) + this._toHex(this.blue);
	}

	_toHex(c: number) {
		var hex = c.toString(16);

		return hex.length === 1 ? `0${hex}` : hex;
	}

	static hexToRgb(hex: string) {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

		return result
			? {
					red: parseInt(result[1], 16),
					green: parseInt(result[2], 16),
					blue: parseInt(result[3], 16),
			  }
			: null;
	}
}
