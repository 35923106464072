import { mapValues } from 'lodash';

import Task from 'models/task/Task';
import Mockup from 'models/task/mockup/mockupTask/Mockup';
import TrackedMockupEvent from 'models/task/mockup/mockupTask/TrackedMockupEvent';

/**
 * Class representing a mockup task.
 * @extends Task
 */
export default class MockupTask extends Task {
	/**
	 * @param {Object} task
	 */
	constructor(task) {
		super(task);

		/**
		 * @type {Mockup}
		 */
		this.mockup = new Mockup(task.mockup);
		/**
		 * @type {Object<string,TrackedMockupEvent>}
		 */
		this.trackedMockupEvents = mapValues(
			(task.tracked_mockup_events || []),
			(event, eventName) => new TrackedMockupEvent(eventName, event)
		);
	}

	/**
	 * @returns {string}
	 */
	getMockupPath() {
		let { pathPrefix, lastUpdate, startPath } = this.mockup;
		let timestamp = lastUpdate ? lastUpdate.valueOf().toString(36) : 0;

		return `/mockup/${timestamp}/${pathPrefix}/${startPath.replace(/^\/+/, '') || 'index.html'}`;
	}
}
