export class UserProfileField {
	constructor(field) {
		this._definition = field.definition;
		this._value = field.value;
	}

	definition() {
		return this._definition;
	}

	value() {
		return this._value;
	}
}
