import OAuthIntegration from './OAuthIntegration';

export default class GenericOAuthIntegration extends OAuthIntegration {
	constructor(integration) {
		super(integration);

		this.authorizeButtonText = integration.authorize_button_text;
		this.autoRedirect = integration.auto_redirect;
	}
}
