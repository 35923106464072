/**
 * Class representing a answer.
 * @abstract
 */
export default class Answer {
	/**
	 * @param {Object} answer
	 * @param {string} index
	 */
	constructor(answer, index) {
		/**
		 * @type {string}
		 */
		this._id = answer.id;
		/**
		 * @type {string}
		 */
		this._type = answer.type;
		/**
		 *
		 * @type {boolean}
		 */
		this._checked = false;
		/**
		 * @type {string}
		 */
		this._index = index;
	}

	id() {
		return this._id;
	}

	type() {
		return this._type;
	}

	index() {
		return this._index;
	}

	check() {
		this._checked = true;
	}

	uncheck() {
		this._checked = false;
	}

	invertCheck() {
		this._checked = !this._checked;
	}

	/**
	 *
	 * @returns {boolean}
	 */
	isChecked() {
		return Boolean(this._checked);
	}
}
