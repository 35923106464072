import React, { Component } from 'react';

/**
 * @deprecated use _/app/application/agreements
 */
export default function(applicationService, Agreement) {
	return class Agreements extends Component {
		_agreements = {};

		render() {
			return (
				<div>
					{applicationService
						.settings()
						.agreements()
						.map(agreement => (
							<Agreement
								key={agreement.id()}
								agreement={agreement}
								ref={_agreement => {
									this._agreements[agreement.id()] = _agreement;
								}}
							/>
						))}
				</div>
			);
		}

		isValid() {
			applicationService
				.settings()
				.agreements()
				.forEach(agreement => {
					if (!agreement.isValid()) {
						this._agreements[agreement.id()].check();
					}
				});

			return applicationService
				.settings()
				.agreements()
				.every(agreement => agreement.isValid());
		}

		status() {
			return applicationService
				.settings()
				.agreements()
				.filter(agreement => agreement.checked())
				.map(agreement => ({ id: agreement.id() }));
		}
	};
}
