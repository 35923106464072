import _ from 'lodash';

/**
 * Class representing a user roles.
 * @extends Array
 */
export default class Roles extends Array {
	static ADMIN = 'TEAM_ROLE_ADMIN';
	static IMPORTED_ADMIN = 'TEAM_ROLE_IMPORTED_ADMIN';
	static MANAGER = 'TEAM_ROLE_MANAGER';
	static USER = 'TEAM_ROLE_USER';

	constructor(...args) {
		super(...args);

		this.constructor = Roles;
		this.__proto__ = Roles.prototype; // eslint-disable-line no-proto
	}

	toggleRole() {
		if (this.isAdmin()) {
			throw new Error('Not allowed operation. Member has admin role.');
		}

		const roles = _.xor(this, [this.constructor.MANAGER]);

		this.length = 0;
		this.splice(0, 0, ...roles);
	}

	/**
	 * @returns {boolean}
	 */
	isAdmin() {
		return !!~_.indexOf(this, this.constructor.ADMIN) || !!~_.indexOf(this, this.constructor.IMPORTED_ADMIN);
	}

	/**
	 * @returns {boolean}
	 */
	isManager() {
		return !!~_.indexOf(this, this.constructor.MANAGER);
	}

	/**
	 * @returns {boolean}
	 */
	isUser() {
		return !!~_.indexOf(this, this.constructor.USER);
	}

	/**
	 *
	 * @returns {string}
	 */
	role() {
		if (this.isAdmin()) {
			return 'admin';
		}

		if (this.isManager()) {
			return 'manager';
		}

		return 'user';
	}
}
