import BaseService from 'services/BaseService';
import UserJudgement from 'models/challenge/submission/UserJudgement';

export default class ChallengeSubmissionService extends BaseService {
	_type = 'challenge-submission';
	_typeKey = 'submission_type';

	constructor(APIService) {
		super();
		this.APIService = APIService;
	}

	/**
	 *
	 * @param {string|number} id
	 * @param {number} offset
	 * @param {number} limit
	 * @param {boolean} user - to fetch user submission
	 * @returns {Promise}
	 */
	fetchSubmissionList({ id, offset = 0, limit = 50, user = false } = {}) {
		return this.APIService.get(`v1/dizzy/challenge/${id}/submission${user ? '/user' : ''}`, {
			offset,
			limit,
		}).then(response =>
			//NOTE: backend must fix response format
			user
				? {
						list: (response || []).map(submission => this.toModel(submission)),
						has_next_page: false,
				  }
				: {
						...response,
						list: (response.list || []).map(submission => this.toModel(submission)),
				  }
		);
	}

	/**
	 *
	 * @param {string|number} id - submission id
	 * @returns {Promise}
	 */
	fetchSubmissionDetails({ id } = {}) {
		return this.APIService.get(`v1/dizzy/challenge/submission/${id}`).then(
			({ next, previous, submission, user_judgement }) => ({
				next: next && this.toModel(next),
				previous: previous && this.toModel(previous),
				submission: submission && this.toModel(submission),
				userJudgement: new UserJudgement(user_judgement || {}),
			})
		);
	}
}
