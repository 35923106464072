import { HeaderContent } from 'components/task/types/form/formBlock/contentBlock/headerContent/HeaderContent';
import { ImageContent } from 'components/task/types/form/formBlock/contentBlock/imageContent/ImageContent';
import { TextContent } from 'components/task/types/form/formBlock/contentBlock/textContent/TextContent';

import { DropdownClosedQuestion } from 'components/task/types/form/formBlock/question/closed/dropdownClosedQuestion/DropdownClosedQuestion';
import { DeclarationQuestion } from 'components/task/types/form/formBlock/question/declarationQuestion/DeclarationQuestion';
import { MultipleChoiceClosedQuestion } from 'components/task/types/form/formBlock/question/multipleChoiceClosedQuestion/MultipleChoiceClosedQuestion';
import { DateOpenQuestion } from 'components/task/types/form/formBlock/question/open/dateOpenQuestion/DateOpenQuestion';
import { EmailOpenQuestion } from 'components/task/types/form/formBlock/question/open/emailOpenQuestion/EmailOpenQuestion';
import { TextOpenQuestion } from 'components/task/types/form/formBlock/question/open/textOpenQuestion/TextOpenQuestion';
import { UrlOpenQuestion } from 'components/task/types/form/formBlock/question/open/urlOpenQuestion/UrlOpenQuestion';
import { SingleChoiceClosedQuestion } from 'components/task/types/form/formBlock/question/singleChoiceClosedQuestion/SingleChoiceClosedQuestion';
import { FileUploadFormBlock } from 'components/task/types/form/formBlock/fileUpload/FileUploadFormBlock';

export const formBlocks = {
	'header-content': HeaderContent,
	'image-content': ImageContent,
	'text-content': TextContent,
	// question:
	'dropdown-closed-question': DropdownClosedQuestion,
	'declaration-question': DeclarationQuestion,
	'multiple-choice-closed-question': MultipleChoiceClosedQuestion,
	'date-open-question': DateOpenQuestion,
	'email-open-question': EmailOpenQuestion,
	'text-open-question': TextOpenQuestion,
	'url-open-question': UrlOpenQuestion,
	'single-choice-closed-question': SingleChoiceClosedQuestion,
	'file-upload': FileUploadFormBlock,
};
