import React, { PureComponent } from 'react';

export default function() {
	/**
	 * @extends PureComponent
	 */
	return class BaseTrueFalseAnswer extends PureComponent {
		state = {
			value: null,
		};

		render() {
			const { answer, Mode } = this.props;

			return (
				<div className={`answer ${this.state.value}--checked`}>
					<div className="body wrapper--row align--center">
						<p>{answer.body()}</p>
						<Mode answer={answer} onCheck={value => this._onCheck(value)} />
					</div>
				</div>
			);
		}

		/**
		 * Used mainly to reset a value.
		 * @param {Object} nextProps
		 */
		UNSAFE_componentWillReceiveProps(nextProps) {
			this.setState({
				value: nextProps.answer.value(),
			});
		}

		/**
		 * @returns {boolean|null}
		 */
		value() {
			return this.state.value;
		}

		/**
		 * @param {boolean} value
		 * @private
		 */
		_onCheck(value) {
			this.props.click(value);
			this.setState({
				value,
			});
		}
	};
}
