export default class Form {
	constructor(form) {
		this._id = form.id;
		this._formBlocks = form.form_blocks || [];
	}

	id() {
		return this._id;
	}

	formBlocks() {
		return this._formBlocks;
	}
}
