import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Field as FormikField } from 'formik';

import { SettingFormFieldProps } from '../SettingField';
import { ErrorMessage } from '../../error/Error';

export const SettingInputTextField = ({
	label,
	valueType,
	value,
	editable,
	editMode,
	saving,
	onClick,
	inForm,
	...props
}: SettingFormFieldProps) => {
	const Field = inForm ? FormikField : 'input';
	const fieldProps = props;

	if (inForm) {
		fieldProps.as = 'input';
	}

	return !value && !editable ? null : (
		<div
			className={classnames('settingField settingField--text', {
				editMode,
				editable,
			})}
			onClick={onClick}
		>
			<div className="settingField__container d-flex justify-content-between align-items-center py-2 px-3">
				{label && <div className="settingField__name mr-3">{label}</div>}
				<div className="d-flex align-items-center flex-grow-1">
					{editMode ? (
						<Field
							{...fieldProps}
							className="border-0 text-right flex-grow-1 p-0 m-0 rounded-0 h-auto"
							value={value}
							autoFocus
							disabled={saving}
							spellCheck={false}
						/>
					) : (
						<p className="settingField__value flex-grow-1 text-right">
							{value ? (
								valueType === 'link' ? (
									editable && !editMode ? (
										<FormattedMessage id="component.form.setting-field.click-here" />
									) : (
										<a href={value} target="_blank" rel="noopener noreferrer">
											<FormattedMessage id="component.form.setting-field.click-here" />
										</a>
									)
								) : (
									value
								)
							) : (
								<FormattedMessage id="component.form.setting-field.click-here-to-set" />
							)}
						</p>
					)}
					{editable && (
						<div className="ml-3">
							{saving ? <i className="fa fa-cog fa-spin" /> : <i className="fa fa-pencil" />}
						</div>
					)}
				</div>
			</div>
			<ErrorMessage name={fieldProps.name} inForm={inForm} />
		</div>
	);
};
