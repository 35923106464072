import React from 'react';
import { FormattedMessage } from 'react-intl';
import { wire } from 'react-hot-wire';

import View from 'components/_/common/view/list/ListView';

import Notifications from './Notifications';

function NotificationsView() {
	return (
		<View
			name="notifications"
			i18nKey="notification-list"
			config={{
				search: false,
			}}
			tabs={{
				url: '/notification/list/${id}', // eslint-disable-line no-template-curly-in-string
				list: [
					{
						id: 'all',
						content: <FormattedMessage id="notification-list.all-tab" />,
					},
				],
			}}
		>
			<Notifications />
		</View>
	);
}

export default wire(['services.notificationService'], NotificationsView);
