import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default function() {
	return class RemainingChars extends Component {
		render() {
			const { maxLength, text } = this.props;

			return (
				<div>
					<FormattedMessage id="user-details.content.remaining-signs" />
					<strong>{maxLength - text.length}</strong>
				</div>
			);
		}

		static propTypes = {
			maxLength: PropTypes.number,
			text: PropTypes.string,
		};
	};
}
