import React, { useState } from 'react';
import { wire } from 'react-hot-wire';

import ExtendedEntry from './ExtendedEntry.template';

export const ExtendedEntryComponent = props => {
	const [visible, setVisible] = useState(false);

	function _open(event) {
		if (event) {
			event.preventDefault();
		}

		setVisible(true);
	}

	function _close(event) {
		if (event) {
			event.preventDefault();
		}

		setVisible(false);
	}

	return (
		<ExtendedEntry
			{...props}
			ReactMarkdown={props['components.ReactMarkdown']}
			open={_open}
			close={_close}
			visible={visible}
		/>
	);
};

export default wire(['components.ReactMarkdown'], ExtendedEntryComponent);
