import { useCallback, useEffect, useMemo, useState } from 'react';

export default function useFetch<S = any>(promise: () => Promise<S>, initial: S, deps: any[] = []) {
	const [loading, setLoading] = useState<boolean>(true);
	const [response, setResponse] = useState<S>(initial);
	const [error, setError] = useState<string | null>(null);

	const request = useCallback(promise, deps);
	const initialResponse = useMemo(() => initial, []);

	useEffect(() => {
		let cancel = false;

		setError(null);
		setLoading(true);

		(async () => {
			try {
				const response = await request();

				if (!cancel) {
					setResponse(response);
				}
			} catch (error) {
				if (!cancel) {
					setResponse(initialResponse);
					setError(((error as unknown) as { messageId: string }).messageId);
				}
			}

			setLoading(false);
		})();

		return () => {
			cancel = true;
		};
	}, [request, initialResponse]);

	return { loading, response, error, setResponse };
}
