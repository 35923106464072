import Submission from 'models/challenge/submission/Submission';

/**
 * Class representing a image submission.
 * @extends Submission
 */
export default class ImageSubmission extends Submission {
	constructor(submission) {
		super(submission);

		this._image = submission.image;
	}

	image() {
		return this._image;
	}
}
