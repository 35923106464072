import React from 'react';

import { SettingField } from 'components/_/form/setting-field/SettingField';

export default ({ profile, editable, onFieldChange, ...props }) => {
	const teamService = props['services.teamService'];

	return (
		<div className="p-2 m-0 mr-md-4">
			<div className="row mb-4 pb-2">
				<div className="col-24 mb-2">
					<SettingField
						label="Nickname"
						name="nickname"
						defaultValue={profile.nickname()}
						editable={editable}
						type="input"
						valueType="text"
						onSave={nickname =>
							teamService
								.editProfile(profile, {
									nickname,
								})
								.then(() => onFieldChange('nickname', nickname))
						}
						inForm={false}
					/>
				</div>
				<div className="col-24">
					<SettingField
						label="Description"
						name="description"
						defaultValue={profile.description()}
						editable={editable}
						type="textarea"
						valueType="text"
						onSave={description =>
							teamService
								.editProfile(profile, {
									description,
								})
								.then(() => onFieldChange('description', description))
						}
						inForm={false}
					/>
				</div>
			</div>
		</div>
	);
};
