import _ from 'lodash';

import Agreement, { AgreementDTO } from 'models/application/config/Agreement';
import ColorScheme from 'models/application/config/colorScheme/ColorScheme';
import HeadMetadata, { HeadMetadataDTO } from 'models/application/config/HeadMetadata';
import Locales, { LocalesDTO } from 'models/application/config/Locales';
import TrackingCodes, { TrackingCodesDTO } from 'models/application/config/trackingCodes/TrackingCodes';
import TermsAndConditions, { TermsAndConditionsDTO } from 'models/application/config/TermsAndConditions';
import { SingleColorSchemeDTO } from './colorScheme/SingleColorScheme';

export interface SettingsDTO {
	agreements: AgreementDTO[];
	color_scheme: SingleColorSchemeDTO;
	metadata: HeadMetadataDTO;
	locales: LocalesDTO;
	trackingCodes: TrackingCodesDTO;
	anonymous_users_enabled: boolean;
	terms_and_conditions: TermsAndConditionsDTO;
	font: string;
}

export default class Settings {
	private _agreements: Agreement[];
	private _colorScheme: ColorScheme;
	private _headMetadata: HeadMetadata;
	private _locales: Locales;
	private _trackingCodes: TrackingCodes;
	private _anonymousUsersEnabled: boolean;
	private _termsAndConditions: TermsAndConditions;
	private _font: string;

	constructor(settings: SettingsDTO) {
		this._agreements = (settings.agreements || []).map(agreement => new Agreement(agreement));
		this._colorScheme = new ColorScheme(settings.color_scheme);
		this._headMetadata = new HeadMetadata(settings.metadata);
		this._locales = new Locales(settings.locales);
		this._trackingCodes = settings.trackingCodes && new TrackingCodes(settings.trackingCodes);
		this._anonymousUsersEnabled = Boolean(settings.anonymous_users_enabled);
		this._termsAndConditions = new TermsAndConditions(settings.terms_and_conditions);
		this._font = settings.font;
	}

	agreements() {
		return this._agreements;
	}

	hasAgreements() {
		return !_.isEmpty(this._agreements);
	}

	GDPRAgreements() {
		return this._agreements.filter(agreement => agreement.isGDPR());
	}

	requiredAgreements() {
		return this._agreements.filter(agreement => agreement.required() && !agreement.isGDPR());
	}

	optionalAgreements() {
		return this._agreements.filter(agreement => !agreement.required());
	}

	colorScheme() {
		return this._colorScheme;
	}

	headMetadata() {
		return this._headMetadata;
	}

	locales() {
		return this._locales;
	}

	trackingCodes() {
		return this._trackingCodes;
	}

	anonymousUsersEnabled() {
		return this._anonymousUsersEnabled;
	}

	termsAndConditions() {
		return this._termsAndConditions;
	}

	font() {
		return this._font;
	}
}
