import OrderTask from 'components/task/types/question/order/OrderTask';
import SortableAnswer from 'components/task/types/question/order/SortableAnswer';
import SortableAnswerList from 'components/task/types/question/order/SortableAnswerList';

export default {
	'components.OrderTask': {
		factory: OrderTask,
		deps: [
			{ $ref: 'components.task.layout.BaseLayout' },
			{ $ref: 'components.OrderTask.sortableAnswerList' },
			{ $ref: 'services.platformService' },
			{ $ref: 'components.MatchingImageType' },
		],
	},
	'components.OrderTask.sortableAnswer': {
		factory: SortableAnswer,
	},
	'components.OrderTask.sortableAnswerList': {
		factory: SortableAnswerList,
		deps: [{ $ref: 'components.OrderTask.sortableAnswer' }],
	},
};
