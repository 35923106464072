import Answer from 'models/task/question/answer/Answer';

/**
 * Class representing a image answer.
 * @extends Answer
 */
export default class ImageAnswer extends Answer {
	/**
	 * @param {Object} answer
	 * @param {string} index
	 */
	constructor(answer, index) {
		super(answer, index);

		/**
		 * @type {string}
		 */
		this._image = answer.image;
	}

	image() {
		return this._image;
	}
}
