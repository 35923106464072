import formContentBlockServices from 'services/common/form/formContentBlock';
import questionServices from 'services/common/form/question';
import questionAnswerServices from 'services/common/form/questionAnswer';

import FileUploadFormBlockService from 'services/common/form/FileUploadFormBlockService';
import FormBlockService from 'services/common/form/FormBlockService';

export default {
	...formContentBlockServices,
	...questionServices,
	...questionAnswerServices,
	'services.fileUploadFormBlockService': {
		class: FileUploadFormBlockService,
	},
	'services.formBlockService': {
		class: FormBlockService,
		deps: [{ $ref: 'services.logService' }],
		calls: [
			{
				name: 'registerSubService',
				args: ['date-open-question', { $ref: 'services.dateOpenQuestionService' }],
			},
			{
				name: 'registerSubService',
				args: ['email-open-question', { $ref: 'services.emailOpenQuestionService' }],
			},
			{
				name: 'registerSubService',
				args: ['text-open-question', { $ref: 'services.textOpenQuestionService' }],
			},
			{
				name: 'registerSubService',
				args: ['url-open-question', { $ref: 'services.urlOpenQuestionService' }],
			},
			{
				name: 'registerSubService',
				args: ['declaration-question', { $ref: 'services.declarationQuestionService' }],
			},
			{
				name: 'registerSubService',
				args: ['dropdown-closed-question', { $ref: 'services.dropdownClosedQuestionService' }],
			},
			{
				name: 'registerSubService',
				args: ['multiple-choice-closed-question', { $ref: 'services.multipleChoiceClosedQuestionService' }],
			},
			{
				name: 'registerSubService',
				args: ['single-choice-closed-question', { $ref: 'services.singleChoiceClosedQuestionService' }],
			},
			{
				name: 'registerSubService',
				args: ['header-content', { $ref: 'services.headerFormBlockService' }],
			},
			{
				name: 'registerSubService',
				args: ['image-content', { $ref: 'services.imageFormBlockService' }],
			},
			{
				name: 'registerSubService',
				args: ['text-content', { $ref: 'services.textFormBlockService' }],
			},
			{
				name: 'registerSubService',
				args: ['file-upload', { $ref: 'services.fileUploadFormBlockService' }],
			},
		],
	},
};
