import TaskState from 'models/task/state/TaskState';

/**
 * @extends {TaskState}
 */
export default class MissionTaskState extends TaskState {
	/**
	 * @param {Object} state
	 */
	constructor(state) {
		super(state);

		if (!state) {
			return;
		}

		/**
		 * @type {Object}
		 * @private
		 */
		this._elementCounts = state.mission.element_counts;
	}

	/**
	 * @returns {Object}
	 */
	elementCounts() {
		return this._elementCounts;
	}

	/**
	 * @returns {{
	 * 	   all: number,
	 * 	   completed: number,
	 * 	   seen: number
	 * }}
	 */
	counts() {
		return this._elementCounts || MissionTaskState.DEFAULT_COUNTS;
	}

	/**
	 * @returns {number}
	 */
	progress() {
		return this._elementCounts.all ? this._elementCounts.completed / this._elementCounts.all : 0;
	}

	/**
	 * @returns {boolean}
	 */
	hasUnseenTasks() {
		return !!this._elementCounts.seen;
	}

	/**
	 * @type {{
	 * 	   all: number,
	 * 	   completed: number,
	 * 	   seen: number
	 * }}
	 */
	static DEFAULT_COUNTS = {
		all: 0,
		completed: 0,
		seen: 0,
	};
}
