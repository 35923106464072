import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Moment } from 'moment';

export type ActionListPlaceholderViewProps = {
	icon: string;
	message: string;
	nextActionDate?: Moment | null;
};

export const ActionListPlaceholderView = ({ message, icon, nextActionDate }: ActionListPlaceholderViewProps) => {
	return (
		<div className="d-flex mt-5 justify-content-center">
			<div className="actionList__placeholder d-flex flex-column">
				<div className="actionList__placeholder__icon d-flex justify-content-center">{icon}</div>
				<div className="d-flex justify-content-center mt-4 fs-6 text-center">{message}</div>
				{nextActionDate && (
					<div className="d-flex justify-content-center mt-6 fs-4 text-center font-weight-light">
						<FormattedHTMLMessage
							id={
								nextActionDate.diff(new Date()) > 0
									? 'portal.action.placeholder.nextAction'
									: 'portal.action.placeholder.nextActionUnknownDate'
							}
							values={{ date: nextActionDate.format('DD.MM.YYYY, HH:mm') }}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default ActionListPlaceholderView;
