import moment from 'moment';

export default class Attributes {
	/**
	 * @param {Object} attributes
	 */
	constructor(attributes) {
		/**
		 * @type {boolean}
		 * @private
		 */
		this._missionHasTasksReadyForRepeat = attributes.mission_has_tasks_ready_for_repeat;
		/**
		 * @type {*}
		 * @private
		 */
		this._missionNearestTaskRepetition = attributes.mission_nearest_task_repetition;
		/**
		 * @type {Moment}
		 * @private
		 */
		this._repetitionAt = attributes.repetition_at ? moment(attributes.repetition_at) : null;
		/**
		 * @type {boolean}
		 * @private
		 */
		this._startRepetition = attributes.start_repetition;
		/**
		 * @type {boolean}
		 * @private
		 */
		this._view = attributes.view;
		/**
		 * @type {number}
		 * @private
		 */
		this._periodAt = attributes.period_at;
	}

	/**
	 * @returns {boolean}
	 */
	missionHasTasksReadyForRepeat() {
		return this._missionHasTasksReadyForRepeat;
	}

	/**
	 * @returns {*}
	 */
	missionNearestTaskRepetition() {
		return this._missionNearestTaskRepetition;
	}

	/**
	 * @returns {Moment}
	 */
	repetitionAt() {
		return this._repetitionAt.format(Attributes.DATE_FORMAT);
	}

	/**
	 * @returns {boolean}
	 */
	startRepetition() {
		return this._startRepetition;
	}

	/**
	 * @returns {boolean}
	 */
	view() {
		return this._view;
	}

	/**
	 * @returns {number}
	 */
	periodAt() {
		return this._periodAt;
	}

	static DATE_FORMAT = 'DD-MM-YYYY HH:mm:ss';
}
