import InternalDynamicAnchor from 'models/application/anchor/types/internalDynamic/InternalDynamic';

export default class InternalDynamicAnchorPrize extends InternalDynamicAnchor {
	/**
	 * @type {string}
	 * @private
	 */
	_pattern = '/store/item/:id/details';

	constructor(anchor) {
		super(anchor);

		this.item = anchor.item;

		this._params = {
			id: this.item.id,
		};
	}
}
