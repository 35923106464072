/**
 * Class representing a mockup bound event.
 */
export default class MockupBoundEvent {
	/**
	 * @param {Object} event
	 */
	constructor(event) {
		/**
		 * @type {string}
		 */
		this.DOMEventName = event.dom_event_name;
		/**
		 * @type {string}
		 */
		this.mockupEventName = event.mockup_event_name;
		/**
		 * @type {string}
		 */
		this.notificationMessage = event.notification_message;
		/**
		 * @type {string}
		 */
		this.selector = event.selector;
		/**
		 * @type {string}
		 */
		this.uriPath = event.uri_path;
	}
}
