import Menu from 'models/application/menu/Menu';
import { MenuPositionDTO } from 'models/application/menu/position/Position';
import MenuPositionService from './position/MenuPositionService';

export default class MenuService {
	constructor(private readonly _menuPositionService: MenuPositionService) {}

	toModel(positions: MenuPositionDTO[]) {
		return new Menu(positions.map(position => this._menuPositionService.toModel(position)));
	}
}
