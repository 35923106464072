import React from 'react';
import classnames from 'classnames';

import useStyles from './Button.jss';

export const Button = ({
	className = '',
	type = 'button',
	children,
	color = 'primary',
	variant = 'flat',
	isLoading,
	...props
}) => {
	const classes = useStyles({ variant })();

	return (
		<button
			type={type}
			className={classnames(className, classes.button, classes[color], { [classes.loading]: isLoading })}
			{...props}
		>
			<span>{children}</span>
		</button>
	);
};
