import React from 'react';
import { Field } from 'formik';

import useStyles from 'components/task/types/form/formBlock/question/Question.jss';

import { ErrorMessage } from 'components/_/form/error/Error';
import { Select } from 'components/_/form/select/Select';

export const DropdownClosedQuestion = ({ formBlock, disabled }) => {
	const classes = useStyles();

	return (
		<div className="fs-4">
			<p className={classes.question}>{formBlock.body()}</p>
			<div className="w-100 w-sm-50">
				<Field name={`${formBlock.id()}.id`} type="select">
					{({ field }) => (
						<Select
							{...field}
							disabled={disabled}
							list={formBlock.answers().map(answer => ({
								value: answer.id(),
								content: answer.body(),
							}))}
						/>
					)}
				</Field>
			</div>
			<ErrorMessage name={`${formBlock.id()}.id`} />
		</div>
	);
};
