import { SubmissionRankingEntry } from 'models/ranking/submissionRanking/entry/SubmissionRankingEntry';

export class SubmissionRankingEntryService {
	constructor(challengeSubmissionService) {
		this._challengeSubmissionService = challengeSubmissionService;
	}

	toModel(entry) {
		return new SubmissionRankingEntry({
			...entry,
			submission: this._challengeSubmissionService.toModel(entry.submission),
		});
	}
}
