import CreativeChallenge from 'views/challenge/types/creative/CreativeChallenge';

export default {
	'components.CreativeChallenge': {
		factory: CreativeChallenge,
		deps: [
			{
				Tabs: {
					ChallengeDetails: { $ref: 'components.CreativeChallengeDetails' },
					ChallengePrizeList: { $ref: 'components.ChallengePrizeList' },
					ChallengeWinners: {
						$ref: 'components.ChallengeWinners',
					},
				},
			},
			{ $ref: 'components.base.CreativeChallenge' },
			{ $ref: 'services.userProfileService' },
			{ $ref: 'services.accessPopupService' },
			{ $ref: 'components.ReactMarkdown' },
			{ $ref: 'components.AutoScrollingText' },
			{ $ref: 'services.modalService' },
			{ $ref: 'services.navigationService' },
			{ $ref: 'services.ranking.rankingService' },
		],
	},
};
