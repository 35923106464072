import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function() {
	return class AutoWidthInput extends Component {
		_refs = {};
		state = {
			size: 0,
			textContent: null,
		};

		componentDidMount() {
			this._initialInputSize();
		}

		render() {
			const { classes, disabled, defaultValue } = this.props;

			return (
				<input
					className={classnames(classes)}
					disabled={disabled}
					size={this.state.size}
					defaultValue={defaultValue}
					onChange={event => this._onChange(event)}
					onKeyUp={event => this._onKeyUp(event)}
					ref={element => {
						this._refs.input = element;
					}}
				/>
			);
		}

		inputValue() {
			return this._refs.input.value;
		}

		focus() {
			this._refs.input.focus();
		}

		_onKeyUp(event) {
			const { onKeyUpHandler, ...args } = this.props;

			if (onKeyUpHandler) {
				onKeyUpHandler(event, ...args);
			}
		}

		_initialInputSize() {
			const { defaultValue, defaultSize } = this.props;

			const size = defaultSize ? defaultSize : defaultValue ? defaultValue.length : 5;

			this.setState({
				size,
			});
		}

		_onChange(event, defaultSize) {
			const { onChange, name } = this.props;
			const textLength = event.target.value.length;
			const size = defaultSize
				? textLength > defaultSize
					? textLength
					: defaultSize
				: textLength > 5
				? textLength
				: 5;

			if (onChange) {
				onChange(name, event.target.value);
			}

			this.setState({
				size,
			});
		}

		static propTypes = {
			classes: PropTypes.string,
			disabled: PropTypes.bool,
			defaultValue: PropTypes.string,
			defaultSize: PropTypes.number,
			onChange: PropTypes.func,
			name: PropTypes.string,
			onKeyUpHandler: PropTypes.func,
		};
	};
}
