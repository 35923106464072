import React from 'react';
import images from 'assets/images';
import { InjectedIntlProps } from 'react-intl';
import { default as KnowledgeBaseModel } from 'models/onboarding/knowledgeBase/KnowledgeBase';
import { NavLink } from 'react-router-dom';
import ConditionalLink from 'components/_/common/link/ConditionalLink';
import { getActionUrl } from '../../util/getActionUrl';
import { wire } from 'react-hot-wire';
import AccessTokenService from 'services/application/AccessTokenService';

export type KnowledgeBaseProps = {
	knowledgeBaseList: KnowledgeBaseModel[];
};

export type KnowledgeBaseWiredProps = KnowledgeBaseProps &
	InjectedIntlProps & {
		'services.accessTokenService': AccessTokenService;
	};

const KnowledgeBase = ({
	knowledgeBaseList,
	intl,
	'services.accessTokenService': accessTokenService,
}: KnowledgeBaseWiredProps) => {
	const KnowledgeBaseIcon = images.portal.knowledgeBaseIcon;
	const impersonateUserProfileId = accessTokenService.impersonateUserProfileId();

	return (
		<div className="row my-3 w-100">
			<div className="w-100">
				<div className="tile knowledgeBase__tile rounded w-100">
					<div className="tile__front w-100 p-3 pb-4 px-sm-5 py-sm-4">
						{!!knowledgeBaseList.length &&
							knowledgeBaseList.map((knowledgeBase, index) => (
								<div key={index}>
									<ConditionalLink
										as={
											['form', 'story', 'page', 'knowledge-check'].includes(
												knowledgeBase.contentType
											)
												? 'a'
												: NavLink
										}
										to={getActionUrl(knowledgeBase, impersonateUserProfileId)}
									>
										<div className="py-2 d-flex flex-row align-items-center">
											<div className="mr-5">
												<KnowledgeBaseIcon className="tile__icon rounded-circle" />
											</div>
											<div className="fs-4 font-weight-medium">{knowledgeBase.name}</div>
										</div>
									</ConditionalLink>
									{index < knowledgeBaseList.length - 1 && <div className="portal__spacer"></div>}
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default wire<KnowledgeBaseWiredProps, KnowledgeBaseProps>(['services.accessTokenService'], KnowledgeBase);
