import ProcessService from 'services/onboarding/process/ProcessService';
import action from './action';

export default {
	'services.onboardingProcessService': {
		class: ProcessService,
		deps: [{ $ref: 'services.APIService' }],
	},
	...action,
};
