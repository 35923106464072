import LevelUpNotification from 'models/notification/LevelUpNotification';

import NotificationBaseService from 'services/notification/NotificationBaseService';
import Level from 'models/user/Level';

export default class LevelUpNotificationService extends NotificationBaseService {
	constructor(currencyService) {
		super();
		this._currencyService = currencyService;
	}

	toModel({ level, earnings, ...rest }) {
		return new LevelUpNotification({
			level: new Level(level),
			earnings: this._currencyService.toModel(earnings),
			...rest,
		});
	}
}
