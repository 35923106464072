import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

function TimeProgressBar({ totalTime, elapsedTime }) {
	const progress = Math.min(elapsedTime / totalTime, 1);
	const remainingSeconds = Math.max(Math.ceil((totalTime - elapsedTime) / 1000), 0);

	if (!remainingSeconds) {
		return null;
	}

	return (
		<div className="progressBarWrapper el--grow wrapper--col">
			<div className="progressBar">
				<div className="progressBar__content" style={{ width: `${progress * 100}%` }} />
			</div>
			<div className="progressBar__status">
				<div className="standardText standardText--noMargin wrapper--row justify--between">
					<span className="hidden--mobile">
						<FormattedMessage
							id="task-details.common.content.answer-status.remaining"
							values={{ seconds: remainingSeconds }}
						/>
					</span>
					<span className="visible--mobile">
						<FormattedMessage id="task-details.common.content.answer-status.remaining-static" />
					</span>
					<strong className="wrapper--row">
						<strong
							style={{
								width: `${remainingSeconds.toString().length * 0.7}em`,
								textAlign: 'right',
								padding: 0,
								marginRight: '3px',
							}}
						>
							{remainingSeconds}
						</strong>
						<FormattedMessage
							id="task-details.common.content.answer-status.remaining-seconds"
							values={{ seconds: remainingSeconds }}
						/>
					</strong>
				</div>
			</div>
		</div>
	);
}

TimeProgressBar.propTypes = {
	totalTime: PropTypes.number,
	elapsedTime: PropTypes.number,
};

export default TimeProgressBar;
