import { AxiosResponse } from 'axios';

import BaseService from 'services/BaseService';
import APIService from 'services/APIService';
import Challenge, { ChallengeDTO } from 'models/challenge/challenge/Challenge';

export default class ChallengeService extends BaseService {
	_type = 'challenge';
	_typeKey = 'type';

	constructor(protected readonly apiService: APIService) {
		super();
	}

	fetchList({
		offset = 0,
		limit = 100,
		filters = {},
		search,
	}: {
		offset?: number;
		limit?: number;
		filters?: { status?: string };
		search?: string;
	} = {}): Promise<{ list: Challenge[]; count: number }> {
		const data: { [key: string]: string | number | undefined } = { offset, limit, search };

		if (filters.status) {
			data['filters[state]'] = filters.status;
		}

		return this.apiService
			.get('v1/dizzy/challenge', data)
			.then(({ list, count }: { list: ChallengeDTO[]; count: number }) => ({
				list: list.map(challenge => this.toModel(challenge)),
				count,
			}));
	}

	fetchDetails({ id }: { id?: string | number } = {}): Promise<Challenge> {
		return this.apiService
			.get(`v1/dizzy/challenge/${id}`)
			.then(({ challenge, submissionCount }: { challenge: ChallengeDTO; submissionCount: number }) => {
				return this.toModel(challenge, submissionCount);
			});
	}

	participate({ id }: { id?: string | number } = {}): Promise<AxiosResponse> {
		return this.apiService.get(`v1/dizzy/challenge/participate/${id}`);
	}
}
