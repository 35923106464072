import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';

import UserPanel from 'components/wrapper/_/user-panel/UserPanel';
import ImpersonateUserInfoBar from 'components/auth/ImpersonateUserInfoBar';

class Layout2 extends PureComponent {
	render() {
		const WrapperMenu = this.props['components.WrapperMenu'];
		const WrapperMissionList = this.props['components.WrapperMissionList'];
		const hideMissionList = this.props.hideMissionList;

		return (
			<div
				className="main-container el--grow wrapper--col"
				ref={container => {
					this.container = container;
				}}
			>
				<WrapperMenu parent={this} />
				<div className="wrapper--row el--grow mainContentWrapper mainContentContainer">
					{!hideMissionList && (
						<div className="missionListTechnical wrapper--col">
							<div className="wrapper--col el--grow">
								<WrapperMissionList />
							</div>
						</div>
					)}
					<div className="wrapper--col el--grow">{this.props.children}</div>
				</div>
				<UserPanel />
			</div>
		);
	}
}

const Layout = wire(['components.WrapperMenu', 'components.WrapperMissionList'], Layout2);

export function buildLayout2(Component, layoutProps) {
	return props => (
		<ImpersonateUserInfoBar>
			<Layout {...layoutProps}>
				<Component {...props} />
			</Layout>
		</ImpersonateUserInfoBar>
	);
}

export default Layout;
