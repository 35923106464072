import { snakeCase, transform } from 'lodash';

import { RewardCafeteriaItem } from 'models/reward-cafeteria/RewardCafeteriaItem';
import { DigitalAddress } from 'models/reward-cafeteria/address/DigitalAddress';
import { PhysicalAddress } from 'models/reward-cafeteria/address/PhysicalAddress';

export class RewardCafeteriaService {
	constructor(APIService, currencyService) {
		this.APIService = APIService;
		this._currencyService = currencyService;
	}

	fetchList({ offset = 0, limit = 100, filters = {}, search } = {}) {
		const queryFilters = {};

		if (filters.from) {
			queryFilters['filters[minPrice]'] = filters.from;
		}

		if (filters.to) {
			queryFilters['filters[maxPrice]'] = filters.to;
		}

		return this.APIService.get('v1/reward-cafeteria/item', {
			...queryFilters,
			search,
			limit,
			offset,
		}).then(({ list, count }) => ({
			list: list.map(item => this.toModel(item)),
			count,
		}));
	}

	fetchDetails({ id }) {
		return this.APIService.get(`v1/reward-cafeteria/item/${id}`).then(item => this.toModel(item));
	}

	addresses({ type }) {
		const mapper = {
			digital: ({ id, ...address }) => ({
				id,
				envelope_text: `${address.forename} ${address.surname} - ${address.email}`,
				address: new DigitalAddress(address),
			}),
			physical: ({ id, envelope_text, ...address }) => ({
				id,
				envelope_text,
				address: new PhysicalAddress(address),
			}),
		};

		return this.APIService.get(`v1/store/address/${type}`).then(addresses => ({
			addresses: addresses.map(address => mapper[type](address)),
		}));
	}

	buyItem(item, values) {
		const data = {};

		if (values.addressSelect === 'create') {
			data.new_address_dto = transform(values.address, (result, value, key) => {
				result[snakeCase(key)] = value;
			});
		} else {
			data.address = { id: values.addressSelect.id };
		}

		return this.APIService.post(`v1/reward-cafeteria/item/${item.id}/buy`, data);
	}

	toModel(item) {
		return new RewardCafeteriaItem({
			...item,
			price: item.price && this._currencyService.toModel(item.price).values()[0],
			cost:
				item.price &&
				this._currencyService.toModel(item.price, {
					reverse: true,
				}),
		});
	}
}
