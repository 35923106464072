import moment from 'moment';

/**
 * Class representing a availability interval.
 */
export default class AvailabilityInterval {
	/**
	 * @param {Object} interval
	 */
	constructor(interval) {
		/**
		 * @type {Date}
		 */
		this.from = moment(interval.from);
		/**
		 *
		 * @type {Date|number}
		 */
		this.to = interval.to && moment(interval.to);

		/**
		 * @type {number}
		 * @private
		 */
		this._currentQuantity = interval.current_quantity;

		/**
		 * @type {number}
		 * @private
		 */
		this._startQuantity = interval.start_quantity;
	}

	currentQuantity() {
		return this._currentQuantity;
	}

	startQuantity() {
		return this._startQuantity;
	}

	isFuture() {
		return this.from.isAfter();
	}

	isNow() {
		return this.from.isBefore() && (!this.to || this.to.isAfter());
	}

	isPast() {
		return this.to && this.to.isBefore();
	}
}
