import React, { Fragment } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import images from 'assets/images';

import View, { Header, Content } from 'components/_/common/view/item/ItemView';

import { NavTabList } from 'components/commons/_/tabs/NavTabList';

import { TeamMembers } from './members/Members';
import ProfileSettings from './settings/Settings';
import Tasks from './tasks/Tasks';

export default ({
	canEdit,
	canEditRoles,
	team: profile,
	params,
	Avatar,
	onAvatarChange,
	onFieldChange,
	teamService,
	taskCount,
}) => (
	<View>
		{() => (
			<Fragment>
				<Header title={profile.name()} subtitle={profile.nickname()} />
				<Content>
					<section
						className={classnames('team-profile d-flex flex-grow-1 flex-column flex-md-row w-100 h-100', {
							editable: canEdit,
						})}
					>
						<section className="summary flex-shrink-0 p-4 pl-md-4 pr-md-0">
							<div className="mb-4">
								<Avatar
									name="avatar"
									currentImage={profile.avatar()}
									defaultImage={images.default.avatar.team}
									editMode={canEdit}
									onChange={onAvatarChange}
								/>
							</div>
							<hr className="my-4" />
							<p>{profile.description()}</p>
						</section>
						<section className="info flex-grow-1 ml-0 ml-md-6 position-relative">
							<NavTabList
								className="m-3"
								url={'/team/${id}/${tab}'} // eslint-disable-line no-template-curly-in-string
								list={[
									{
										id: profile.id(),
										tab: 'members',
										content: <FormattedMessage id="team-details.tabs.members" />,
									},
									canEdit && {
										id: profile.id(),
										tab: 'tasks',
										content: <FormattedMessage id="team-details.tabs.tasks" />,
										badge: taskCount,
									},
									canEdit && {
										id: profile.id(),
										tab: 'settings',
										content: <FormattedMessage id="team-details.tabs.settings" />,
									},
								]}
							/>
							<hr className="mb-2 mb-sm-3" />
							{params.tab === 'members' && (
								<TeamMembers editable={canEditRoles} profile={profile} teamService={teamService} />
							)}
							{params.tab === 'tasks' && <Tasks profile={profile} teamService={teamService} />}
							{params.tab === 'settings' && (
								<ProfileSettings editable={canEdit} profile={profile} onFieldChange={onFieldChange} />
							)}
						</section>
					</section>
				</Content>
			</Fragment>
		)}
	</View>
);
