import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { Moment } from 'moment';
import useGetProgress, { UseGetProgressProps } from 'components/employeePortal/hook/api/useGetProgress';
import useGetEmployeeProgress, {
	UseGetEmployeeProgressProps,
} from 'components/employeePortal/hook/api/useGetEmployeeProgress';
import useGetActionList, { UseGetActionListProps } from 'components/employeePortal/hook/api/useGetActionList';
import useGetContacts, { UseGetContactsProps } from 'components/employeePortal/hook/api/useGetContacts';
import uniqWith from 'lodash/uniqWith';
import useGetKnowledgeBaseList, { UseGetKnowledgeBaseListProps } from '../hook/api/useGetKnowledgeBaseList';

export type UsePortalDataProps = UseGetActionListProps &
	UseGetContactsProps &
	UseGetEmployeeProgressProps &
	UseGetProgressProps &
	UseGetKnowledgeBaseListProps;

export type PortalContextPros = UsePortalDataProps;

export const usePortalData = ({ ...props }: UsePortalDataProps) => {
	const [nextActionDate, setNextActionDate] = useState<Moment | null>(null);
	const [filterByUser, setFilterByUser] = useState<{ id: string; name: string } | undefined>();
	const [employeeList, setEmployeeList] = useState<{ id: string; fullName: string }[]>([]);

	const progress = useGetProgress(props);
	const employeeProgress = useGetEmployeeProgress({ employeeId: filterByUser?.id, ...props });
	const actions = useGetActionList({ employeeId: filterByUser?.id, ...props });
	const contact = useGetContacts(props);
	const knowledgeBase = useGetKnowledgeBaseList(props);

	useEffect(() => {
		if (progress.data.items.length) {
			let _nextActionDate: Moment | null = null;

			progress.data.items.forEach(item => {
				if (
					item.nextActionDate &&
					(!_nextActionDate || item.nextActionDate.valueOf() < _nextActionDate.valueOf())
				) {
					_nextActionDate = item.nextActionDate;
				}
			});

			setNextActionDate(_nextActionDate);
		}
	}, [progress.data]);

	useEffect(() => {
		if (employeeProgress.data.items.length && !employeeList.length) {
			setEmployeeList(
				uniqWith(employeeProgress.data.items, (processA, processB) => processA.userId === processB.userId).map(
					employeeProcess => ({
						id: employeeProcess.userId,
						fullName: employeeProcess.user.fullName,
					})
				)
			);
		}
	}, [employeeProgress.data.items]);

	return {
		progress,
		employeeProgress,
		actions,
		contact,
		knowledgeBase,
		nextActionDate,
		employeeList,
		filterByUser,
		setFilterByUser,
	};
};

type EmployeePortalContextData = ReturnType<typeof usePortalData>;

const EmployeePortalContext = createContext<EmployeePortalContextData | null>(null);

export const EmployeePortalContextProvider: FC<PortalContextPros> = ({ children, ...props }) => {
	const data = usePortalData(props);

	return <EmployeePortalContext.Provider value={data}>{children}</EmployeePortalContext.Provider>;
};

export const useEmployeePortalContext = () => {
	const employeePortalContext = useContext(EmployeePortalContext);

	if (!employeePortalContext) {
		throw new Error('`useEmployeePortalContext` must be used inside EmployeePortalContextProvider');
	}

	return employeePortalContext;
};

export default useEmployeePortalContext;
