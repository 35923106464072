import React from 'react';
import { FormattedMessage } from 'react-intl';

export default () => ({ onCheck }) => (
	<div className="buttons">
		<button type="button" onClick={() => onCheck(true)}>
			<FormattedMessage id="task-details.true-false.button.true-claim" />
		</button>
		<button type="button" onClick={() => onCheck(false)}>
			<FormattedMessage id="task-details.true-false.button.false-claim" />
		</button>
	</div>
);
