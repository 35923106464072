import React from 'react';
import { Field } from 'formik';

import { Checkbox } from 'components/_/form/checkbox/Checkbox';
import { Input } from 'components/_/form/input/Input';
import { ErrorMessage } from 'components/_/form/error/Error';

import useStyles from 'components/task/types/form/formBlock/question/Question.jss';

import CustomQuestionAnswer from 'models/common/form/questionAnswer/CustomQuestionAnswer';
import StandardQuestionAnswer from 'models/common/form/questionAnswer/StandardQuestionAnswer';

export const MultipleChoiceClosedQuestion = ({ formBlock, disabled }) => {
	const classes = useStyles();

	return (
		<div className="fs-4">
			<p className={classes.question}>{formBlock.body()}</p>
			<div>
				{formBlock.answers().map(answer => (
					<div className="mb-2" key={answer.id()}>
						{answer instanceof StandardQuestionAnswer && (
							<Field name={`${formBlock.id()}.answers`}>
								{({ field, form }) => (
									<Checkbox
										{...field}
										fieldType="input"
										checked={field.value.includes(answer.id())}
										disabled={disabled}
										onChange={() => {
											const set = new Set(field.value);

											if (set.has(answer.id())) {
												set.delete(answer.id());
											} else {
												set.add(answer.id());
											}

											form.setFieldTouched(field.name, true);
											form.setFieldValue(field.name, Array.from(set));
										}}
									>
										{answer.body()}
									</Checkbox>
								)}
							</Field>
						)}
						{answer instanceof CustomQuestionAnswer && (
							<div className="w-100 w-sm-50">
								<Field name={`${formBlock.id()}.answers`}>
									{({ field, form }) => (
										<Checkbox
											{...field}
											fieldType="input"
											checked={field.value.includes(answer.id())}
											disabled={disabled}
											onChange={() => {
												const set = new Set(field.value);

												if (set.has(answer.id())) {
													set.delete(answer.id());
													form.setFieldValue(`${formBlock.id()}.custom`, '');
												} else {
													set.add(answer.id());
												}

												form.setFieldTouched(field.name, true);
												form.setFieldValue(field.name, Array.from(set));
											}}
										>
											<Field name={`${formBlock.id()}.custom`}>
												{({ field: customField }) => (
													<Input
														{...customField}
														rounded
														active={field.value.includes(answer.id())}
														disabled={disabled}
														onChange={({ target: { value } }) => {
															form.setFieldTouched(customField.name, value);
															form.setFieldValue(customField.name, value);

															if (value) {
																const set = new Set(field.value);

																if (!set.has(answer.id())) {
																	set.add(answer.id());
																}

																form.setFieldValue(field.name, Array.from(set));
															} else {
																const set = new Set(field.value);

																if (set.has(answer.id())) {
																	set.delete(answer.id());
																}

																form.setFieldValue(field.name, Array.from(set));
															}
														}}
													/>
												)}
											</Field>
										</Checkbox>
									)}
								</Field>
							</div>
						)}
					</div>
				))}
			</div>
			<ErrorMessage name={`${formBlock.id()}.answers`} />
			<ErrorMessage name={`${formBlock.id()}.custom`} />
		</div>
	);
};
