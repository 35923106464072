import Application, { ApplicationDTO } from 'models/application/config/Application';
import { CurrencyDTO } from '../currency/Currency';

export interface ConfigDTO {
	application: ApplicationDTO;
	currencies: CurrencyDTO;
}

export default class Config {
	private readonly _application: Application;
	private readonly _currencies: CurrencyDTO;

	constructor(config: ConfigDTO) {
		/**
		 * @type {Object}
		 */
		this._application = new Application(config.application);
		/**
		 * @type {Object[]}
		 */
		this._currencies = config.currencies;
	}

	application() {
		return this._application;
	}

	currencies() {
		return this._currencies;
	}
}
