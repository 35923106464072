export default {
	'team-roles': {
		admin: 'Admin',
		manager: 'Manager',
		user: 'User',
	},
	error: {
		title: 'No tienes acceso al contenido o el contenido aún no está disponible',
		button: 'Volver a la página principal',
		info: 'Ponte en contacto con tu administrador técnico en caso de problemas.',
	},
	'general-error': {
		title: 'Ocurrió un error',
		refresh: 'Refrescar',
		contact: 'Póngase en contacto con el administrador de la plataforma para obtener más información.',
		details: 'Detalles',
	},
	'landing-page': {
		link: {
			'sign-up': 'Regístrate',
			'sign-in': 'Inicia sesión',
		},
		copyright: 'Gamfi Spółka z o.o.',
		acceptation: 'El uso de la aplicación implica la aceptación de:',
		'terms-and-conditions': {
			'cookie-policy': 'Política de Cookies',
			'privacy-policy': 'Política de privacidad',
			'terms-and-conditions': 'Términos y condiciones',
		},
	},
	auth: {
		form: {
			error: {
				required: 'Este campo es obligatorio.',
				'invalid-email': 'La dirección de e-mail especificada no es válida.',
				'invalid-identifier': 'El identificador especificado no es válido.',
				'user-not-exists': 'El usuario o la contraseña introducidos son incorrectos.',
				'user-not-verified': 'El usuario especificado no ha sido comprobado.',
				'user-inactive': 'El usuario especificado está inactivo.',
				'user-not-found': 'El usuario o la contraseña introducidos son incorrectos.',
				'user-profile-not-active': 'La cuenta de usuario está inactiva.',
				'invalid-password-reset-token': 'El token especificado no es válido.',
				'invalid-password': 'La contraseña especificada no es válida.',
				'passwords-differ': 'La contraseña especificada no es la misma que la contraseña anterior.',
				'invalid-input': 'Los datos introducidos son incorrectos.',
				'user-already-registered': 'El usuario especificado ya está registrado.',
				'required-agreements': 'Este consentimiento es obligatorio.',
				'agreements-required': 'Los consentimientos marcados con un asterisco son obligatorios.',
				'empty-password': 'El campo contraseña es obligatorio.',
				'password-minimal-length': 'Cantidad mínima de caracteres requerida: {length}.',
				'password-minimal-lowercase-occurance': 'Cantidad mínima de minúsculas requerida: {occurance}.',
				'password-minimal-uppercase-occurance': 'Cantidad mínima de mayúsculas requerida: {occurance}.',
				'password-minimal-number-occurance': 'Cantidad mínima de cifras requerida: {occurance}.',
				'password-minimal-special-occurance':
					'Cantidad mínima de caracteres especiales requerida: {occurance}.',
				'disabled-in-configuration': 'La integración ha sido desactivada',
				'user-profile-does-not-match': 'Los datos proporcionados no coinciden con el perfil del usuario.',
				'email-domain-not-on-whitelist': 'No es posible registrar la cuenta en este dominio.',
				'not-allowed': {
					'user-registration-not-complete': 'El usuario aún no ha completado el registro.',
					'user-not-verified': 'El usuario especificado no ha sido comprobado.',
				},
				'one-of-two': 'Al menos uno de estos campos es obligatorio.',
				'resource-error': 'Se ha producido un error al iniciar la sesión.',
				email: {
					'invalid-domain': 'Nombre de dominio incorrecto.',
				},
				phone: {
					'invalid-format': 'Formato de número de teléfono no válido.',
					'invalid-length': 'Longitud del número de teléfono incorrecta.',
				},
			},
		},
		login: {
			navigate: {
				'not-account-yet': '¿Aún no tienes cuenta?',
				register: 'Regístrate',
				'do-not-remember-data': 'Establecer o restablecer la contraseña',
				'do-not-remember-identifier': '¿No recuerdas el identificador o la contraseña?',
			},
			integration: {
				email: {
					title: 'Inicia sesión con la dirección de email',
					field: {
						email: {
							placeholder: 'Introduce la dirección e-mail',
						},
						password: {
							placeholder: 'Introduce la contraseña',
						},
					},
					button: {
						login: 'Inicia sesión',
						check: 'Inicia sesión',
					},
					status: {
						'reset-password': {
							title: 'Activación de cuenta',
							message:
								'Se ha enviado un enlace para establecer tu contraseña a la dirección de email proporcionada.',
						},
					},
				},
				'imported-id': {
					title: 'Inicia sesión con la dirección de email o el identificador',
					field: {
						identifier: {
							placeholder: 'Introduce la dirección e-mail o el identificador',
						},
						password: {
							placeholder: 'Introduce la contraseña',
						},
					},
					button: {
						login: 'Inicia sesión',
						register: 'Regístrate',
						check: 'Inicia sesión',
					},
					status: {
						'reset-password': {
							title: 'Reinicio de la contraseña',
							message:
								'Se ha enviado un enlace para establecer tu contraseña a la dirección de email proporcionada.',
						},
					},
				},
				oauth: {
					facebook: 'Iniciar sesión a través de Facebook',
					generic: 'Iniciar sesión a través de un sistema externo',
					google: 'Iniciar sesión a través de Google',
					'linked-in': 'Iniciar sesión a través de LinkedIn',
					error: {
						title: 'Se ha producido un error inesperado al iniciar sesión',
						type: {
							general: 'El inicio de sesión a través de OAuth se ha pausado.',
							'user-already-registered': 'Ya existe un usuario con la dirección de email especificada.',
							'invalid-one-time-token': 'El token especificado no es válido.',
							'not-allowed': {
								'connect-profiles-before-authentication':
									'La dirección de email asignada a la cuenta de integración ya se encuentra en nuestra base. Inicia sesión en la plataforma con la dirección de email.',
							},
						},
					},
					return: 'Haz clic aquí para volver a la página principal.',
					loading: 'Iniciando sesión, espera por favor...',
				},
			},
		},
		register: {
			navigate: {
				'already-have-account': '¿Ya tienes una cuenta?',
				login: 'Inicia sesión',
			},
			integration: {
				email: {
					title: 'Regístrate con la dirección de email',
					field: {
						email: {
							placeholder: 'Introduce la dirección e-mail',
						},
					},
					button: 'Regístrate',
					status: {
						ok: {
							title: 'Tu cuenta ha sido registrada',
							message:
								'Comprueba tu buzón, encontrarás un email con un enlace de activación. Por razones de seguridad, el enlace solo es válido durante 60 minutos.',
						},
					},
				},
				'imported-id': {
					title: 'Proceso de registro',
					field: {
						forename: {
							placeholder: 'Introducir nombre',
						},
						surname: {
							placeholder: 'Introducir apellido',
						},
						email: {
							placeholder: 'Introduce la dirección e-mail',
						},
						phone: {
							placeholder: 'Introducir número de teléfono',
						},
					},
					button: {
						register: 'Regístrate',
					},
					status: {
						'email-sent': {
							title: 'Tu cuenta ha sido registrada',
							message:
								'Comprueba tu buzón, encontrarás un email con un enlace de activación. Por razones de seguridad, el enlace solo es válido durante 60 minutos.',
						},
					},
				},
				oauth: {
					facebook: 'Regístrese con Facebook',
					generic: 'Regístrate a través de un sistema externo',
					google: 'Regístrese con Google',
					'linked-in': 'Regístrate con LinkedIn',
				},
				token: {
					title: 'Crear una cuenta',
					submit: 'Regístrate',
					error: {
						'no-token': 'Token incorrecto',
					},
					status: {
						ok: {
							title: 'Tu cuenta ha sido registrada',
						},
					},
				},
			},
		},
		'reset-password': {
			navigate: {
				'remember-password': '¿Has olvidado la contraseña?',
				login: 'Inicia sesión',
			},
			integration: {
				email: {
					title: 'Establecer o restablecer la contraseña',
					instruction: {
						text:
							'Introduzca su {email}, a la que le enviaremos un mensaje con un enlace para establecer su contraseña.',
						email: 'dirección de correo electrónico',
					},
					field: {
						email: {
							placeholder: 'Introduce la dirección e-mail',
						},
					},
					button: 'Enviar',
					status: {
						ok: {
							title: 'Reinicio de la contraseña',
							message:
								'Se ha enviado un enlace para establecer tu contraseña a la dirección de email proporcionada.',
						},
					},
				},
				'email-annoying': {
					status: {
						ok: {
							title: 'Reinicio de la contraseña',
							message:
								'Si la dirección de email proporcionada se encuentra en la base de datos de usuarios, se ha enviado el enlace para establecer la contraseña.',
						},
					},
				},
				'imported-id': {
					title: 'Reinicio de la contraseña',
					instruction: {
						text:
							'Introduce el {identifier} que has utilizado para el registro. Te enviaremos un email con un enlace o un código de autorización por SMS para restablecer tu contraseña.',
						identifier: 'dirección de correo electrónico o identificación',
					},
					field: {
						identifier: {
							placeholder: 'Introduce tu dirección de e-mail o el identificador',
						},
					},
					button: 'Enviar',
					status: {
						'email-sent': {
							title: 'Reinicio de la contraseña',
							message:
								'Se ha enviado un enlace para establecer tu contraseña a la dirección de email proporcionada.',
						},
					},
				},
			},
			action: {
				'verify-token': {
					title: 'Ha habido un problema con el enlace recibido',
					message: 'Tu enlace ha perdido la validez o es incorrecto',
					'reset-link': '{link} luego ingrese su e-mail para generar un nuevo enlace.',
					'reset-link-text': 'Haga clic aquí y',
				},
				'set-password': {
					title: {
						reset: 'Establece una nueva contraseña',
						activate: 'Activa tu cuenta estableciendo tu contraseña',
					},
					field: {
						password: {
							placeholder: 'Introduce la contraseña',
						},
						'confirm-password': {
							placeholder: 'Repetir contraseña',
						},
					},
					button: {
						reset: 'Establecer nueva contraseña',
						activate: 'Activar cuenta',
					},
				},
				'authorize-code': {
					title: {
						reset: 'Introducir código de autorización',
					},
					field: {
						'authorization-code': {
							placeholder: 'introduce el código de autorizacióny',
						},
					},
				},
			},
		},
	},
	'achievement-list': {
		title: 'Logros',
		subtitle: 'descripción',
		'all-tab': 'Todos',
		'section-title': {
			progress: 'Tu progreso',
			'gain-info': 'Por obtener este logro recibirás:',
		},
		content: {
			'progress-description': 'De los logros disponibles en la categoría "{category}" has conseguido:',
			requirements: 'Requisitos',
		},
		error: {
			empty: 'La lista de logros está vacía',
		},
	},
	'achievement-details': {
		'section-title': {
			'achievement-description': 'Descripción del logro:',
			requirements: 'Requisitos para obtener el logro:',
		},
	},
	faq: {
		title: 'FAQ',
		subtitle: 'descripción',
		'all-tab': 'Todos',
		content: {
			category: 'Categoría:',
		},
		error: {
			empty: 'Las Preguntas Frecuentes están vacías.',
		},
	},
	'mission-list': {
		title: 'Misiones',
		content: {
			'tasks-execution': 'Actividad {completed} de {all}',
			'earnings-information': {
				mission: 'Por completar todas las tareas recibirás:',
				task: 'Por completar esta tarea recibirás:',
			},
		},
		button: {
			do: 'Realizar',
		},
		error: {
			empty: 'Sin misión.',
		},
	},
	'news-details': {
		subtitle: 'Añadido: ',
		'section-title': {
			comments: 'Comentarios',
		},
	},
	'news-list': {
		title: 'Noticias',
		subtitle: 'descripción',
		content: {
			added: 'Añadido',
		},
		error: {
			empty: 'La lista de noticias está vacía.',
		},
	},
	'notification-list': {
		title: 'Notificaciones',
		subtitle: 'descripción',
		'all-tab': 'Todos',
		error: {
			empty: 'No hay notificaciones.',
		},
	},
	'prize-list': {
		title: 'Premios',
		subtitle: 'descripción',
		'all-tab': 'Todos',
		'section-title': {
			'items-in-your-range': 'Premios a tu alcance',
		},
		button: {
			'buy-now': 'Comprar ahora',
			'check-out': 'Ver',
			soon: 'Próximamente',
		},
		content: {
			amount: 'Disponible',
			count: '{remaining} de {start}',
			'availability-items': 'Unidades disponibles',
			'sold-out': 'Premio vendido',
			'from-date': 'Desde',
			'missing-points': 'Te falta:',
			price: 'Precio',
		},
		error: {
			empty: 'No hay premios en la categoría.',
		},
	},
	'prize-details': {
		'section-title': {
			'prize-description': 'Descripción del premio',
			price: 'Precio:',
			'available-currently': 'Premios disponibles actualmente',
		},
		button: {
			'buy-now': 'Comprar ahora',
			confirm: 'Confirmo',
			resign: 'Abandona',
			soon: 'Próximamente',
		},
		content: {
			'not-available': '¡Premio no disponible!',
			missing: 'Estás perdido:',
			calendar: {
				monday: 'Lu',
				tuesday: 'Ma',
				wednesday: 'Mi',
				thursday: 'Ju',
				friday: 'Vi',
				saturday: 'Sa',
				sunday: 'Do',
				'info-cloud': {
					'available-on-day': 'Premios disponibles ese día:',
					'not-available': 'Premios no disponibles ese día.',
				},
			},
			'buy-item': {
				'confirm-purchase': 'Ordenar ahora',
				cancel: 'Cancelar',
				close: 'Cerca',
				ordered: 'Su orden ha sido puesta. ¡Gracias!',
				error: 'Un error ha ocurrido',
				address: {
					create: 'Crear una nueva dirección...',
				},
			},
			'sold-out': 'Vendido',
		},
	},
	ranking: {
		title: 'Ranking',
		subtitle: 'descripción',
		content: {
			'your-place': {
				point__user: 'Tu posición',
				user_imported: 'Tu posición',
				commitment__user: 'Tu posición',
				point__team: 'Posición de tu equipo',
				team_imported: 'Posición de tu equipo',
				point__container: '{rankingName} - Tu posición',
				container_imported: '{rankingName} - Tu posición',
			},
			button: {
				'log-in': 'Inicia sesión',
			},
		},
		error: {
			empty: 'La clasificación está vacía',
		},
	},
	'task-details': {
		'collection-mission': {
			title: '{name}',
			button: {
				repeat: 'Repetir',
				do: 'Realizar',
			},
			header: {
				'your-progress': 'Tu progreso:',
				'progress-status': '{completed} de {all}',
			},
			content: {
				'gain-for-complete-mission': 'Por completar todas las tareas recibirás:',
				'gain-for-complete-task': 'Por completar esta tarea recibirás:',
				'new-tasks': `{
					newTasks,
					plural,
					one {Nuevas tareas}
					other {Nuevas tareas}
					zero {Nuevas tareas}
				}`,
				'done-tasks': `{
					doneTasks,
					plural,
					one {Tareas realizadas}
					other {Tareas realizadas}
					zero {Tareas realizadas}
				}`,
			},
		},
		graph: {
			content: {
				progress: 'Tu progreso en la misión',
				progressStatus: '{completed} de {all}',
			},
		},
		mockup: {
			button: {
				leave: 'Salir',
				continue: 'Continuar',
			},
			content: {
				'answer-status': {
					'wrong-answer': 'Has respondido mal a la pregunta - inténtalo de nuevo',
					'correct-answer': 'Tarea realizada correctamente',
					'try-again-in': 'Inténtalo de nuevo dentro de: ',
					gaining: 'Recibes',
				},
			},
		},
		'complete-phrase-question': {
			content: {
				'task-description': 'Tu tarea consiste en rellenar las palabras que faltan en los campos.',
			},
		},
		declaration: {
			content: {
				'task-description': 'Tu tarea es declarar la realización de las siguiente actividades.',
			},
		},
		'guess-phrase-question': {
			input: {
				'enter-answer': 'Escribe aquí la respuesta',
			},
			content: {
				'task-description': 'Tu tarea es escribir la respuesta correcta.',
			},
		},
		'retype-phrase-question': {
			content: {
				'task-description': 'Tu tarea es reescribir las palabras indicadas.',
			},
		},
		'visit-link': {
			content: {
				'instruction-after': 'La tarea consistía en revisar la página:',
				'instruction-before':
					'Tu tarea es revisar el contenido. Haz clic en "Confirmar" para completar la tarea.',
			},
		},
		document: {
			button: {
				'download-pdf': 'Descargar PDF',
			},
		},
		'true-false': {
			button: {
				'true-claim': 'Verdadero',
				'false-claim': 'Falso',
			},
		},
		join: {
			button: {
				join: 'Únete',
			},
			'is-a-member-already': {
				description: 'Ya has realizado anteriormente tu elección:',
			},
			'is-anonymous': {
				description: 'No puedes realizar esta tarea porque:',
				title: '¡no has iniciado sesión!',
				'click-button': 'Haz clic en el siguiente botón para participar plenamente en el juego.',
				'button-text': 'Regístrate',
			},
			'join-task-crashed': {
				description: '¡Ha ocurrido un error en el sistema!',
				title: 'Ponte en contacto con el administrador',
			},
			'team-join-view': {
				title: 'Elegir a continuación',
			},
			'user-container-join-view': {
				title: 'Elegir a continuación',
			},
		},
		'user-profile-fields': {
			instruction:
				'Tu tarea consiste en completar todas las posiciones. A continuación, haz clic en "Confirmar" para completar la tarea.',
		},
		common: {
			button: {
				continue: 'Continuar',
				check: 'Comprobar',
				do: 'Realizar',
				'do-again': 'Realizar de nuevo',
				leave: 'Salir',
				confirm: 'Potwierdź',
				help: 'Ayuda',
			},
			content: {
				'answer-status': {
					'remaining-static': 'Restante: ',
					remaining: `Para completar la tarea {
						seconds,
						plural,
						one {restante}
						other {restantes}
					}`,
					'remaining-seconds': `{
						seconds,
						plural,
						one {segundo}
						other {segundos}
					}`,
					'already-completed': '¡Esta tarea ya se ha completado!',
					'wrong-answer-try-again': 'Has respondido mal a la pregunta - inténtalo de nuevo',
					'wrong-answer': 'Por desgracia la respuesta es errónea',
					'correct-answer': 'Tarea realizada correctamente',
					'try-again-in': 'Inténtalo de nuevo dentro de: ',
					gaining: 'Recibes',
					crashed: 'Se ha producido un error inesperado. Inténtalo de nuevo dentro de un momento.',
					awaiting: 'La tarea se completará tras la aprobación del manager de equipo.',
					rejected: 'La tarea no se ha completado.',
					'rejected-reason': '¡La tarea no se ha completado! {reason}',
				},
				repetition: {
					'repetition-time-last': 'La tarea a volver a realizar se desbloqueará en',
					'repetition-approval': 'Puedes realizar una nueva tarea',
					'gained-points': 'Puntos conseguidos',
					'completion-time': 'Tiempo de realización',
					completion: 'actuación',
					days: `{
						days,
						plural,
						one {día}
						other {días}
					} `,
				},
			},
		},
	},
	'user-profile': {
		field: {
			mapped: {
				forename: 'Nombre',
				surname: 'Apellido',
				nickname: 'Seudónimo',
				description: 'descripción',
				email: 'E-mail',
				phone: 'Teléfono',
			},
		},
	},
	'user-details': {
		'notification-tab': 'Notificaciones',
		button: {
			'mobile-mode': {
				edit: 'Guardar',
				preview: 'Editar',
			},
			mode: {
				edit: 'Grabar cambios',
				preview: 'Editar perfil',
			},
			edit: 'Editar',
			'choose-photo': 'Elegir imagen',
		},
		ontent: {
			'user-data': {
				position: 'Posición:',
				'last-activity': 'Última actividad',
			},
			level: 'Nivel ',
			'remaining-signs': 'Caracteres restantes: ',
			'edit-in-progress': 'Editas el perfil',
			editField: {
				nickname: 'Seudónimo',
				description: 'descripción',
			},
		},
		tabs: {
			informations: 'Información',
			notifications: 'Notificaciones',
		},
		agreements: {
			title: 'Tus consentimientos',
			gdpr: {
				title: 'RGPD',
				warning:
					'Si cancela su consentimiento, eliminaremos su cuenta en la aplicación y eliminaremos sus datos personales, los puntos ganados y el progreso en la finalización de las tareas. Una cuenta eliminada no se puede restaurar. Sin embargo, puede crear una nueva.',
			},
			required: 'Obligatorios',
			optional: 'Opcional',
			confirm: '¡Confirmo los cambios de consentimiento!',
			saveButton: 'Guardar',
			'accept-requirements': 'Debes confirmar los cambios',
			'your-agreements': 'Tus consentimientos',
		},
	},
	'team-details': {
		button: {
			'mobile-mode': {
				edit: 'Guardar',
				preview: 'Editar',
			},
			mode: {
				edit: 'Grabar cambios',
				preview: 'Editar perfil',
			},
			edit: 'Editar',
		},
		content: {
			'remaining-signs': 'Caracteres restantes: ',
			'edit-in-progress': 'Editas el perfil',
		},
		tabs: {
			members: 'Miembros',
			tasks: 'Tareas',
			settings: 'Configuración',
		},
		tasks: {
			status: {
				awaiting: '… ha realizado la tarea {task}. ¿Es verdad? ¡Decide!{br}el día {date}',
				accepted: 'ha realizado la tarea {task}{br}el día {date}',
				rejected: 'no ha realizado la tarea correctamente {task}{br}el día {date}',
			},
			button: {
				confirm: 'Confirmar',
				reject: 'Rechazar',
			},
			'rejection-modal': {
				title: 'Rechazo de la tarea',
				'reason-placeholder': 'Indique el motivo del rechazo (opcional)',
				button: 'Rechazar',
			},
		},
	},
	'create-submission': {
		return: {
			or: '¡Haz clic ',
			link: 'aquí',
			rest: ', para cambiar el tipo de notificación!',
		},
		form: {
			error: {
				'too-short': 'La cantidad mínima de caracteres es: {value}',
				'too-long': 'La cantidad máxima de caracteres es: {value}',
				required: '¡Este campo es obligatorio!',
			},
		},
		confirmation: {
			title: 'Notificación añadida',
			button: 'Finalizar',
			content: {
				'line-1': 'Tu notificación ha sido añadida y está en moderación.',
				'line-2': 'Te informaremos de cualquier cambio de estado.',
			},
		},
		error: {
			title: 'Se ha producido un error inesperado',
			content: 'Inténtalo de nuevo en unos momentos.',
			button: 'Finalizar',
		},
		'image-submission': {
			title: 'Tus notificaciones',
			form: {
				title: {
					placeholder: 'Wprowdź tytuł',
				},
				file: 'pulsa aquí para añadir una fotografía',
				button: 'Añadir',
			},
		},
		'submission-type-selection': {
			title: 'Participa en el desafío',
			subtitle: 'Elige el tipo de notificación:',
			type: {
				text: 'Añadir texto',
				image: 'Añadir imagen',
			},
		},
		'text-submission': {
			title: 'Tus notificaciones',
			form: {
				title: {
					placeholder: 'Introducir título',
				},
				text: {
					placeholder: 'Introducir contenido',
				},
				button: 'Añadir',
			},
		},
	},
	'challenge-list': {
		title: 'Desafío',
		subtitle: 'descripción',
		loading: 'Cargando lista de desafíos...',
		tabs: {
			open: 'En proceso',
			future: 'Próximos',
			finished: 'Finalizado',
			all: 'Todos',
		},
		content: {
			'empty-list': 'No hay desafíos. ¡Vuelve en breve! ;-)',
			'challenge-status': {
				notStarted: 'Para comenzar falta: {remainingTime}',
				inProgress: 'En proceso:',
				beforeResolve: 'Esperando resultados',
				finished: 'Finalizado',
			},
			'reverse-status': {
				notStarted: 'Empezamos en',
				inProgress: 'Restante',
				beforeResolve: 'Anun. de resultados en',
			},
			earnings: 'Por ganar',
		},
		button: {
			takePart: 'Participa',
		},
		error: {
			empty: 'La lista de desafíos está vacía.',
		},
		type: {
			user: 'Individual',
			team: 'Equipo',
			'container-team': 'Después de la estructura - equipo',
			'container-user': 'Después de la estructura - individual',
		},
	},
	'challenge-details': {
		creative: {
			type: {
				image: 'Imagen',
				text: 'Texto',
				video: 'Video',
				gif: 'Gif',
			},
			'section-title': {
				'challenge-info': 'Detalles:',
			},
			button: {
				'take-part': 'Participa',
				'add-submission-in': 'Añade otra notificación para',
				'limit-reached': 'Se ha alcanzado el límite de notificaciones',
				'only-for-manager': 'Solo para los managers de equipo',
			},
			content: {
				'challenge-info': {
					'challenge-type': 'Tipo de desafío',
					'creative-type': 'Creativo',
					'submission-type': 'Tipo de notificación',
					'submission-types': {
						video: 'Video',
						image: 'Imagen',
						text: 'Texto',
						gif: 'Gif',
					},
					'participation-mode': 'Tipo de participación',
					'submissions-limit': 'Límite de notificaciones',
					'points-for-added-submission': 'Puntos por notificación añadida',
					'points-for-accepted-submission': 'Puntos por notificación aceptada',
				},
				'not-selected-winners': 'El desafío aún no se ha resuelto',
				remains: 'Restante',
			},
			'menu-tabs': {
				details: 'Detalles',
				'my-submissions': 'Mis',
				'recently-added-submissions': 'Añadido últimamente',
				ranking: 'Ranking',
				prizes: 'Premios',
				winners: 'Vencedores',
			},
			'submission-details': {
				author: 'Autor:',
				date: 'Fecha:',
			},
			'submission-list': {
				error: {
					empty: {
						user: 'Aún no has añadido nada. Cámbialo, haz clic en "¡Participa"! ;-)',
						common: 'Nadie ha añadido nada todavía. Cámbialo, haz clic "¡Participa"! ;-)',
					},
					'publication-at': {
						'line-1': 'Las notificaciones añadidas se publicarán el día {date}.',
						'line-2': 'No te quedes atrás, añade tu notificación ahora - haz clic en "¡Participa"!.',
					},
				},
				ontent: {
					'submission-status': {
						rejected: 'Rechazado',
						accepted: 'Aceptado',
						new: 'Pendiente de moderación',
						'reject-reason': 'Motivo del rechazo: {reason}',
					},
					remains: 'Restante: {remaining-time}',
					removed:
						'El usuario ha dejado el juego y ha retirado su consentimiento para la publicación del contenido',
				},
				button: {
					'remove-submission': 'Eliminar esta notificación',
				},
			},
			popup: {
				'choose-submission-type': {
					title: 'Participa en el desafío',
					subtitle: 'Tipos de desafíos disponibles:',
					button: {
						'add-gif': 'Añadir gif',
						'add-video': 'Añadir video',
						'add-text': 'Añadir texto',
						'add-image': 'Añadir imagen',
						'add-link': 'Añadir enlace',
					},
				},
				'add-submission-confirmation': {
					title: '¡Se ha añadido una notificación!',
					button: {
						close: 'Volver',
					},
					content: {
						'already-added': 'Tu notificación ha sido añadida y está en moderación',
						'status-change-info': 'Le informaremos del cambio de estado.',
					},
				},
				'link-submission': {
					title: 'Agregar un enlace en el asunto del desafío',
					button: {
						return: 'Volver',
					},
					content: {
						'return-to-choose-submission': 'o {return} para cambiar el tipo de envío.',
					},
				},
				'gif-submission': {
					title: 'Agrega un gif en el tema del desafío.',
					subtitle: 'Empieza a buscar gifs',
					button: {
						'press-to-add': 'Haga clic aquí para agregar un gif',
						return: 'Volver',
					},
					content: {
						'return-to-choose-submission': 'o {return} para cambiar el tipo de envío.',
					},
				},
				'video-submission': {
					title: 'Agrega un video en el asunto del desafío',
					button: {
						'press-to-add': 'Haga clic aquí para agregar un video',
						return: 'Volver',
						continue: 'Continuar',
					},
					content: {
						'or-paste-video-link': 'or paste the link to the video',
						'return-to-choose-submission': 'o {return} para cambiar el tipo de envío.',
					},
				},
				'image-submission': {
					title: 'Agrega una imagen en el asunto del desafío.',
					button: {
						'press-to-add': 'Haga clic aquí para agregar una imagen',
						return: 'Volver',
						continue: 'Continuar',
					},
					content: {
						'return-to-choose-submission': 'o {return} para cambiar el tipo de envío.',
					},
				},
				'text-submission': {
					title: 'Escribir texto en el asunto del desafío.',
					button: {
						continue: 'Continuar',
						return: 'Volver',
						or: 'o',
					},
					content: {
						'remaining-signs': 'Caracteres restantes:',
						'return-to-choose-submission': 'o {return} para cambiar el tipo de envío.',
					},
					error: 'Para continuar, agregue el contenido al desafío.',
				},
				'submission-summary': {
					title: 'Resumen',
					subtitle: 'Nombra tu notificación en el desafío',
					button: {
						add: 'Bien, agrego',
						return: 'Volver',
					},
					content: {
						'remaining-signs': 'Caracteres restantes:',
						'return-to-choose-submission': 'o {return} para cambiar el tipo de envío.',
					},
					error: 'Para continuar, agregue el título de la obra.',
				},
				'submission-details': {
					button: {
						'add-comment': 'Agregar comentario',
						answer: 'Respuesta',
					},
					'section-title': {
						comments: 'Comentarios',
						'add-comment': 'Agregar comentario',
					},
					content: {
						wrote: 'Escribió',
						'remaining-signs': 'Caracteres restantes',
						status: 'Estado:',
						submissionAdded: 'Adicional:',
						'current-status': {
							new: 'Esperando la moderación',
							accepted: 'Aceptado',
							rejected: 'Rechazado',
						},
					},
				},
			},
		},
		kpi: {
			'section-title': {
				'challenge-info': 'Detalles',
			},
			button: {
				'take-part': 'Participa',
			},
			content: {
				'challenge-info': {
					'challenge-type': 'Tipo de desafío',
					'kpi-type': 'KPI',
					'submission-type': 'Tipo de notificación',
					'participation-mode': 'Tipo de participación',
					'submissions-limit': 'Límite de notificaciones',
					'last-import-date': 'Última actualización',
				},
				remains: 'Restante: {remaining-time}',
			},
			'menu-tabs': {
				ranking: 'Ranking',
				details: 'Detalles',
				prizes: 'Premios',
			},
		},
		target: {
			'section-title': {
				'challenge-info': 'Detalles:',
			},
			content: {
				'challenge-info': {
					'challenge-type': 'Tipo de desafío',
					'creative-type': 'Objetivo',
					'participation-mode': 'Tipo de participación',
					'submission-count': 'Realizado',
					'submissions-limit': 'Límite',
					'points-for-accepted-submission': 'Puntos por notificación',
					completed: 'Realizado:',
				},
			},
			'menu-tabs': {
				details: 'Detalles',
			},
		},
		common: {
			ranked: {
				place: 'Lugar {placeFrom}',
				'place-range': 'Lugares de {placeFrom} a {placeTo}',
			},
			prizes: {
				'no-prize-set': 'Sin premios.',
				points: 'Puntos',
			},
		},
	},
	cafeteria: {
		title: 'Cafetería con premios',
		error: {
			empty: 'La cafetería con premios está vacía.',
			'internal-server-error': 'Se ha producido un error al mostrar la cafetería.',
			'access-denied': 'No tienes acceso a la cafetería.',
			'disabled-in-configuration': 'La cafetería con premios está apagada.',
			'no-budget': 'Por desgracia, el premio aún no está disponible.',
			'too-few-points': 'Insuficiente cantidad de puntos. Compra imposible de realizar.',
			'invalid-input': 'El formulario contiene errores.',
			'item-sold-out': 'Premio vendido.',
		},
		filter: {
			price: 'Precio',
			afford: 'Mostrar los premios que puedo permitirme.',
			from: 'Desde',
			to: 'hasta',
		},
		item: {
			'buy-now': 'Comprar ahora',
			'not-available': 'No disponible',
			missing: 'Te falta',
			price: 'Precio',
		},
	},
	notification: {
		static: {
			messages: {
				submission: {
					new: 'Has añadido una notificación "{submissionTitle}" al desafío "{challengeTitle}"',
					rejected:
						'Tu notificación "{submissionTitle}" en el desafío "{challengeTitle}" ha sido rechazada por: {reason}',
					'rejected-without-reason':
						'Tu notificación "{submissionTitle}" en el desafío "{challengeTitle}" ha sido rechazada',
					accepted: 'Tu notificación "{submissionTitle}" en el desafío "{challengeTitle}" ha sido aceptada',
					awardedPlace:
						'Has ganado un premio por conseguir el puesto {awardedPlace} en el desafío "{challengeTitle}".',
					addedSubmission:
						'Se ha añadido una notificación "{submissionTitle}" en el desafío "{challengeTitle}".',
				},
				'declaration-task': {
					accepted: 'Tu notificación en la tarea "{taskName}" ha sido aceptada',
					'rejected-reason': 'Tu notificación en la tarea "{taskName}" ha sido rechazada',
					rejected: 'Tu notificación en la tarea "{taskName}" ha sido rechazada',
				},
				email: {
					'remember-pass': 'Enviar un email: Reiniciar contraseña',
					'reset-pass': 'Enviar un email: Reiniciar contraseña',
					registration: 'Enviar un email: Registro de cuenta',
					'submission-rejected': 'Enviar un correo electrónico: la notificación ha sido rechazada',
					'submission-accepted': 'Enviar un correo electrónico: la notificación ha sido aceptada',
				},
				bonusTransaction: 'Cambio del estado de puntos',
				task: '{name}',
				item: '{name}',
				levelUp: {
					withName: 'Nivel {levelNumber} - {name}',
					withoutName: 'Nivel {levelNumber}',
				},
				achievement: '{name}',
				challenge: {
					target: '{name}',
				},
			},
			dates: {
				task: {
					'task-completed': 'Tarea realizada: {date}',
					'mission-completed': 'Misión realizada: {date}',
					'task-progress': 'Se han alcanzado más progresos: {date}',
				},
				achievement: 'Insignia conseguida: {date}',
				item: 'Objeto comprado: {date}',
				email: 'Email recibido: {date}',
				submission: {
					new: 'Notificación rechazada: {date}',
					accepted: 'Notificación aceptada: {date}',
					rejected: 'Notificación rechazada: {date}',
					awardedPlace: 'Lugar otorgado: {date}',
				},
				'declaration-task': {
					accepted: 'Notificación aceptada: {date}',
					rejected: 'Notificación rechazada: {date}',
				},
				bonusTransaction: 'Puntos contabilizados: {date}',
				challenge: {
					target: 'Realizado: {date}',
				},
			},
			'null-states': {
				task: '[Tarea eliminada]',
				achievement: '[Logro eliminado]',
				item: '[Objeto eliminado]',
				submission: '[Eliminado]',
				awardedPlace: '[Eliminado]',
			},
		},
		'real-time': {
			achievement: {
				title: '¡Felicidades!',
				subtitle: 'Has conseguido un logro',
				button: {
					default: 'Recoger',
					'without-earnings': 'Continuar',
				},
				'achievement-unlocked': '¡Logro desbloqueado!',
				'earn-achievement': 'Has conseguido un logro',
				earn: 'Has conseguido',
			},
			challengeSubmissionStateChanged: {
				title: '¡Felicidades!',
				subtitle: 'Ha cambiado el estado de tu notificación en el desafío',
				button: {
					default: 'Continuar',
					'without-earnings': 'Continuar',
				},
				earn: 'Has conseguido',
			},
			declarationTaskAcceptanceChanged: {
				title: '¡Felicidades!',
				subtitle: 'Ha cambiado el estado de tu tarea',
				button: {
					default: 'Continuar',
					task: 'Comprobar por qué',
				},
				earn: 'Has conseguido',
			},
			task: {
				title: '¡Felicidades!',
				subtitle: 'Has finalizado la tarea',
				button: {
					default: 'Recoger puntos',
					'without-earnings': 'Continuar',
					nextTask: 'Continuar',
				},
				congratulations: '¡Felicidades!',
				'for-execution': 'Por realizar la tarea recibes:',
			},
			levelUp: {
				title: '¡Felicidades!',
				subtitle: 'Has alcanzado un nuevo nivel',
				button: {
					default: 'Continuar',
					'without-earnings': 'Continuar',
				},
				earn: 'Has conseguido',
			},
			mission: {
				title: '¡Felicidades!',
				subtitle: 'Misión completada',
				button: {
					default: 'Recoger',
					'without-earnings': 'Continuar',
					nextTask: 'Continuar',
				},
				congratulations: '¡Felicidades!',
				'for-execution': 'Por completar la misión recibes:',
				earn: 'Por completar la misión recibes:',
			},
		},
	},
	currency: {
		bucks: `{
			bucks,
			plural,
			one {pavos}
			other {pavos}
		}`,
		exp: `{
			exp,
			plural,
			one {punto}
			other {puntos}
		} experiencia`,
	},
	graph: {
		tooltip: {
			mission: {
				tasks: 'Tareas',
				progress: '{completed} de {all}',
			},
			button: {
				repeat: 'Repetir',
				execute: 'Realizar',
			},
		},
	},
	loader: {
		text: ['Cargando aplicación...'],
		loading: {
			settings: 'Descargando la aplicación...',
		},
	},
	time: {
		days: `{
			time,
			plural,
			one {# día}
			other {# días}
		}`,
		hours: `{
			time,
			plural,
			one {# hora}
			other {# horas}
		}`,
		minutes: `{
			time,
			plural,
			one {# minuto}
			other {# minutos}
		}`,
		seconds: `{
			time,
			plural,
			one {# segundo}
			other {# segundos}
		}`,
	},
	markdown: {
		'show-more': '(mostrar más)',
		'show-less': '(ocultar)',
	},
	'terms-and-conditions': {
		'terms-and-conditions': 'Términos y Condiciones',
		'privacy-policy': 'Política de privacidad',
		'cookie-policy': 'Política de Cookies',
	},
	'contact-list': {
		title: 'Contactos',
		search: {
			placeholder: 'Busca...',
		},
		tab: {
			all: 'Todos',
			active: 'Activos',
		},
		error: {
			empty: 'No hay resultados.',
			'internal-server-error': 'Se ha producido un error al mostrar el libro de contacto.',
			'access-denied': 'No tienes acceso al libro de contacto.',
			'disabled-in-configuration': 'El libro de contacto está desactivado.',
		},
		pagination: {
			of: 'de',
		},
	},
	// clear translations:
	app: {
		installation: {
			title: 'Instala la aplicación en tu dispositivo',
			subtitle: 'Añade la aplicación a tu pantalla de inicio {separator}para un acceso rápido y sencillo.',
			button: 'Haz clic para instalar',
		},
	},
	// reusable components translations:
	common: {
		'go-back': 'Volver',
		form: {
			block: {
				fileUpload: {
					placeholder: 'Escoge un archivo...',
					chooseButton: 'Elegir archivo',
					deleteButton: 'Eliminar',
					processing: 'Procesando...',
					addAnotherFile: 'Añadir otro archivo',
				},
				dropdownDefault: 'Elegir respuesta...',
			},
		},
		view: {
			list: {
				search: {
					placeholder: 'Busca...',
				},
			},
		},
	},
	component: {
		form: {
			'setting-field': {
				'click-here': 'Haz clic aquí',
				'click-here-to-set': 'Haga clic aquí para establecer...',
			},
		},
	},
	form: {
		error: {
			required: 'El campo es obligatorio.',
			'check-required': 'La declaración es obligatoria.',
			'too-short': 'Demasiado poco caracteres (min. {min} caracteres).',
			'too-long': 'Demasiados caracteres (max. {max} caracteres).',
			'invalid-email': 'Dirección de email incorrecta.',
			'invalid-url': 'Dirección URL incorrecta.',
			'invalid-postal-code': 'Código postal incorrecto.',
			'invalid-mobile-phone': 'Número de teléfono incorrecto.',
			'min-elements': 'Demasiadas pocas respuestas (min. {min}).',
		},
	},
	portal: {
		title: 'Hola {name}, este es tu plan',
		process: {
			placeholder: 'Wow, así es como se ve un tablero sin tareas. ¡Super!',
			title: {
				duration: `Proceso restante: <span class="processList__text--highlighted">{
						duration,
						plural,
						one {#&nbsp;día}
						other {#&nbsp;días}
					}</span>.&nbsp;`,
				overdue:
					'<span class="processList__text--highlighted">¡Tareas atrasadas! Complétalos lo antes posible.</span>&nbsp;',
				'overdue-for-manager':
					'<span class="processList__text--highlighted">La fecha límite para completar el proceso ya ha pasado.</span>&nbsp;',
				date: {
					'first-day': `Queda el día 1: <span class="processList__text--highlighted">{
						time,
						plural,
						one {#&nbsp;día}
						other {#&nbsp;días}
					}</span>.`,
					'key-date': `Quedan <span class="processList__text--highlighted">{
						time,
						plural,
						one {#&nbsp;día}
						other {#&nbsp;días}
					}</span> para que inicie el proceso.`,
					'last-day': `Hasta el último día: <span class="processList__text--highlighted">{
						time,
						plural,
						one {#&nbsp;día}
						other {#&nbsp;días}
					}</span>.`,
				},
			},
			report: {
				downloadReport: 'Descargar el informe',
				button: {
					processReport: 'Procesos',
					processActionsReport: 'Acciones',
				},
			},
			filter: 'Filtrar',
			'filter-all': 'Todo',
		},
		action: {
			type: {
				task: 'Tarea',
				mission: 'Mission',
				form: 'Forma',
			},
			deadline: {
				general: '{time} de {type}',
				overdue: 'Atrasado: {time}',
				'solidsnake-task': 'completo',
				'solidsnake-mission': 'completo',
				'solidsnake-form': 'completo',
				form: 'completo',
				story: 'completo',
				page: 'completo',
				'knowledge-check': 'completo',
			},
			placeholder: {
				messages: {
					1: 'Todas las tareas completas. ¡Todo hecho por hoy!',
					2: '¡Houston, no tenemos más tareas! Ha sido todo por hoy.',
					3: 'Hay nuevas tareas en camino, ¡así que sigue nadando!',
					4: 'Si las tareas son como las nubes, ¡hoy es un día soleado! ¡Adelante, úsalo!',
					5: '¡Abra cadabra! ¡Y todas esas tareas desaparecieron! ¡No te preocupes, habrá más!',
					6: 'No hay más tareas aquí. Pero tenemos un cactus genial. ¡Míralo, es genial!',
					7: 'No es necesario excavar más, no hay ninguna tarea aquí, por ahora.',
				},
				nextAction: 'Espera más tareas: <span class="color-blue font-weight-normal">{date}</span>',
				nextActionUnknownDate: 'Pronto habrá más tareas.',
			},
			'assign-role': {
				placeholder: 'Assigna el puesto {roleName}',
				confirm: 'Confirmar',
			},
		},
		tasks: {
			title: 'Tu plan',
		},
		contacts: {
			title: 'Contactos',
			roleTitle: 'Roles',
			role: {
				manager: 'Manager',
			},
		},
		'knowledge-base': {
			title: 'Knowledge base',
		},
		employees: {
			yours: 'Tu equipo',
		},
	},
};
