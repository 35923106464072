import Task from 'models/task/Task';

/**
 * Class representing a mission.
 * @extends Task
 */
export default class Mission extends Task {
	isMission() {
		return this instanceof Mission;
	}
}
