import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import Logo from 'components/auth/Logo';
import Loader from 'components/commons/_/loader/Loader';

export default function(Agreements, applicationService) {
	return function RequiredAgreements(props) {
		const { agreementsRef, run, loadingStatus } = props;
		const [configFetched, setConfigFetched] = useState(false);

		useEffect(() => {
			applicationService.fetchConfig().then(() => {
				setConfigFetched(true);
			});
		}, []);

		if (!configFetched) {
			return <Loader />;
		}

		return (
			<div>
				<div className="wrapper--col align--center overflow--auto" style={{ height: '100vh' }}>
					<div className="view__auth mt-6">
						<Logo />

						<div className="sectionTitle">Akceptacja zgód</div>

						<form
							onSubmit={event => {
								event.preventDefault();
								run();
							}}
						>
							<Agreements ref={agreementsRef} />

							<div className="wrapper--row justify--between align--center">
								<button
									className={classnames('btn__basic btn__basic--fullWidth noMargin', {
										loading: loadingStatus === 'loading',
									})}
								>
									<span>Przejdź dalej</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	};
}
