import GoogleAnalytics, { GoogleAnalyticsDTO } from 'models/application/config/trackingCodes/GoogleAnalytics';
import GoogleTagManager, { GoogleTagManagerDTO } from 'models/application/config/trackingCodes/GoogleTagManager';

export interface TrackingCodesDTO {
	googleAnalytics: GoogleAnalyticsDTO;
	googleTagManager: GoogleTagManagerDTO;
}

export default class TrackingCodes {
	private _googleAnalytics: GoogleAnalytics;
	private _googleTagManger: GoogleTagManager;

	constructor(trackingCodes: TrackingCodesDTO) {
		this._googleAnalytics = trackingCodes.googleAnalytics && new GoogleAnalytics(trackingCodes.googleAnalytics);
		this._googleTagManger = trackingCodes.googleTagManager && new GoogleTagManager(trackingCodes.googleTagManager);
	}

	googleAnalytics() {
		return this._googleAnalytics;
	}

	googleTagManager() {
		return this._googleTagManger;
	}
}
