import TaskBaseService from 'services/task/TaskBaseService';

import DeclarationTask from 'models/task/declaration/DeclarationTask';
import DeclarationTaskState from 'models/task/state/DeclarationTaskState';
import DeclarationTaskRepetitionState from 'models/task/repetitionState/DeclarationTaskRepetitionState';

export default class DeclarationTaskService extends TaskBaseService {
	constructor(APIService, ...rest) {
		super(...rest);

		this.APIService = APIService;
	}

	toModel(task) {
		return new DeclarationTask({
			...task,
			state:
				task.state &&
				this._taskStateService.mapState(task.state, DeclarationTaskState, DeclarationTaskRepetitionState),
		});
	}

	repetitionStates() {
		return this.APIService.get('v1/declaration-task').then(list =>
			list.map(repetitionState =>
				this._taskStateService.mapRepetitionState(
					DeclarationTaskState,
					DeclarationTaskRepetitionState,
					repetitionState.task_state,
					repetitionState
				)
			)
		);
	}

	count() {
		return this.APIService.get('v1/declaration-task/awaiting-acceptance/count', null, undefined, {
			omitErrorHandlers: true,
		});
	}

	accept({ id }) {
		return this.APIService.post(`v1/declaration-task/${id}/accept`, {
			accept: true,
		});
	}

	reject({ id, rejectionReason }) {
		return this.APIService.post(`v1/declaration-task/${id}/accept`, {
			accept: false,
			rejection_reason: rejectionReason,
		});
	}
}
