import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { UserAvatar } from 'components/_/app/user/user-avatar/UserAvatar';

import Loader from 'components/commons/_/loader/Loader';

import { Currency } from 'components/_/common/currency/Currency';

import { RejectionModal } from './rejection-modal/RejectionModal';

export const TeamProfileTask = ({ repetitionState, confirm, reject }) => {
	const { userProfile } = repetitionState.taskState().player();
	const task = repetitionState.taskState().task();
	const [loading, setLoading] = useState(false);
	const [state, setState] = useState(repetitionState.acceptanceState());
	const [modal, setModalState] = useState(false);

	return (
		<div className="task d-flex pb-2 mb-2">
			{modal && (
				<RejectionModal
					close={() => setModalState(false)}
					onSubmit={reason => {
						setLoading(true);
						setModalState(false);

						return reject(reason).then(() => {
							setState('rejected');
							setLoading(false);
						});
					}}
				/>
			)}
			{loading && (
				<div className="task__loading">
					<Loader />
				</div>
			)}
			<div className="pr-3">
				<UserAvatar
					size={60}
					user={{
						href: `/user/${userProfile.id()}/details`,
						avatar: repetitionState
							.taskState()
							.player()
							.userProfile.avatar(),
					}}
				/>
			</div>
			<div className="d-flex flex-column flex-grow-1 flex-sm-row align-items-start align-items-sm-center justify-content-between">
				<div>
					<p className="task__userName mb-1">
						<Link to={`/user/${userProfile.id()}/details`}>{userProfile.fullName()}</Link>
					</p>
					<p className="task__description">
						{state === 'awaiting' && (
							<FormattedMessage
								id="team-details.tasks.status.awaiting"
								values={{
									task: <Link to={`/task/${task.id()}/details`}>{task.name()}</Link>,
									date: repetitionState.taskState().lastCompletionTime(),
									br: <br />,
								}}
							/>
						)}
						{state === 'accepted' && (
							<FormattedMessage
								id="team-details.tasks.status.accepted"
								values={{
									task: <Link to={`/task/${task.id()}/details`}>{task.name()}</Link>,
									date: repetitionState.taskState().lastCompletionTime(),
									br: <br />,
								}}
							/>
						)}
						{state === 'rejected' && (
							<FormattedMessage
								id="team-details.tasks.status.rejected"
								values={{
									task: <Link to={`/task/${task.id()}/details`}>{task.name()}</Link>,
									date: repetitionState.taskState().lastCompletionTime(),
									br: <br />,
								}}
							/>
						)}
					</p>
				</div>
				{state === 'awaiting' && (
					<div className="d-flex mt-2 mt-sm-0">
						<button
							className="btn__basic btn__basic--basicGreen mr-2"
							onClick={() => {
								setLoading(true);
								confirm().then(() => {
									setState('accepted');
									setLoading(false);
								});
							}}
						>
							<FormattedMessage id="team-details.tasks.button.confirm" />
						</button>
						<button className="btn__basic btn__basic--red" onClick={() => setModalState(true)}>
							<FormattedMessage id="team-details.tasks.button.reject" />
						</button>
					</div>
				)}
				{state === 'accepted' && (
					<div className="d-flex flex-wrap task__currencies">
						{repetitionState.earned() &&
							repetitionState
								.earned()
								.values()
								.map(currency => (
									<Currency
										key={currency.codeName()}
										currency={currency.codeName()}
										value={currency.value()}
										className="my-1 mr-2"
									/>
								))}
					</div>
				)}
			</div>
		</div>
	);
};
