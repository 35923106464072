import _ from 'lodash';

import QuestionTaskService from '../QuestionTaskService';
import TrueFalseQuestionTask from 'models/task/question/questionTask/trueFalse/TrueFalseQuestionTask';

/**
 * @extends TaskBaseService
 */
export default class TrueFalseQuestionTaskService extends QuestionTaskService {
	/**
	 * @param {TaskStateService} taskStateService
	 * @param {TrueFalseAnswerService} trueFalseAnswerService
	 */
	constructor(taskStateService, trueFalseAnswerService) {
		super(taskStateService);

		/**
		 * @type {TrueFalseAnswerService}
		 * @private
		 */
		this._trueFalseAnswerService = trueFalseAnswerService;
	}

	/**
	 * @param {Object} task
	 * @returns {TrueFalseQuestionTask}
	 */
	toModel(task) {
		return new TrueFalseQuestionTask({
			...task,
			question: {
				...task.question,
				answers:
					task.question &&
					_.map(task.question.answers, answer => this._trueFalseAnswerService.toModel(answer)),
			},
			state: this._mapState(task),
		});
	}
}
