export default function colorsConfig() {
	return {
		content: {
			background: {
				alphas: [0, 30, 50, 60, 85],
				fallback: { red: 0, green: 0, blue: 0 },
			},
			foreground: {
				alphas: [0, 1, 3, 5, 10, 15, 20, 30, 35, 50, 60, 80],
				fallback: { red: 0, green: 0, blue: 0 },
			},
		},
		primary: {
			background: {
				alphas: [0, 10, 40, 70],
				fallback: { red: 0, green: 0, blue: 0 },
			},
			foreground: {
				alphas: [10, 30, 40, 70],
				fallback: { red: 0, green: 0, blue: 0 },
			},
		},
		interactive: {
			background: {
				alphas: [10, 40, 80],
				fallback: { red: 0, green: 0, blue: 0 },
			},
			foreground: {
				alphas: [40],
				fallback: { red: 0, green: 0, blue: 0 },
			},
		},
	};
}
