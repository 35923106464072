import QuestionTaskService from '../QuestionTaskService';

import RetypePhraseQuestionTask from 'models/task/question/questionTask/phrase/RetypePhraseQuestionTask';

export default class RetypePhraseQuestionTaskService extends QuestionTaskService {
	toModel(task) {
		return new RetypePhraseQuestionTask({
			...task,
			state: this._mapState(task),
		});
	}
}
