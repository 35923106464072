import MockupTask from 'components/task/types/mockup/MockupTask';

export default {
	'components.MockupTask': {
		factory: MockupTask,
		deps: [
			{ $ref: 'services.eventQueueService' },
			{ $ref: 'components.task.layout.BaseLayout' },
			{ $ref: 'services.browserHistoryService' },
		],
	},
};
