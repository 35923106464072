import ActionService from 'services/onboarding/process/action/ActionService';
import subServices from './subServices';

export default {
	...subServices,
	'services.onboardingActionService': {
		class: ActionService,
		deps: [{ $ref: 'services.logService' }, { $ref: 'services.APIService' }],
		calls: [
			{
				name: 'registerSubService',
				args: ['solidsnake-task', { $ref: 'services.onboardingTaskActionService' }],
			},
			{
				name: 'registerSubService',
				args: ['solidsnake-mission', { $ref: 'services.onboardingTaskActionService' }],
			},
			{
				name: 'registerSubService',
				args: ['solidsnake-form', { $ref: 'services.onboardingTaskActionService' }],
			},
			{
				name: 'registerSubService',
				args: ['form', { $ref: 'services.onboardingTaskActionService' }],
			},
			{
				name: 'registerSubService',
				args: ['assign-role', { $ref: 'services.onboardingAssignRoleActionService' }],
			},
			{
				name: 'registerSubService',
				args: ['story', { $ref: 'services.onboardingTaskActionService' }],
			},
			{
				name: 'registerSubService',
				args: ['page', { $ref: 'services.onboardingTaskActionService' }],
			},
			{
				name: 'registerSubService',
				args: ['knowledge-check', { $ref: 'services.onboardingTaskActionService' }],
			},
		],
	},
};
