import { UserProfileFieldDefinition } from './UserProfileFieldDefinition';

export class MappedUserProfileFieldDefinition extends UserProfileFieldDefinition {
	constructor(definition) {
		super(definition);

		this._mappedProfileField = definition.mapped_profile_field;
	}

	mappedProfileField() {
		return this._mappedProfileField;
	}
}
