import { useEffect } from 'react';
import queryString from 'query-string';
import { wire } from 'react-hot-wire';

function SetAccessToken({ match, ...services }) {
	const accessTokenService = services['services.accessTokenService'];
	const navigationService = services['services.navigationService'];

	useEffect(() => {
		const accessToken = match.params.accessToken;
		const search = queryString.parse(window.location.search);

		if (!accessToken) {
			throw new Error('"accessToken" property must be passed in query string.');
		}

		accessTokenService.setAccessToken('application', accessToken);
		accessTokenService.setImpersonateUserId(search.impersonateUserId);
		accessTokenService.setImpersonateUserProfileId(search.impersonateUserProfileId);
		navigationService.goTo('/');
	}, []);

	return null;
}

export default wire(
	['services.logService', 'services.accessTokenService', 'services.navigationService'],
	SetAccessToken
);
