import DateOpenQuestionService from 'services/common/form/question/open/DateOpenQuestionService';
import EmailOpenQuestionService from 'services/common/form/question/open/EmailOpenQuestionService';
import TextOpenQuestionService from 'services/common/form/question/open/TextOpenQuestionService';
import UrlOpenQuestionService from 'services/common/form/question/open/UrlOpenQuestionService';

export default {
	'services.dateOpenQuestionService': {
		class: DateOpenQuestionService,
	},
	'services.emailOpenQuestionService': {
		class: EmailOpenQuestionService,
	},
	'services.textOpenQuestionService': {
		class: TextOpenQuestionService,
	},
	'services.urlOpenQuestionService': {
		class: UrlOpenQuestionService,
	},
};
