import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Header } from 'components/_/common/view/item/ItemView';

import Mission from 'models/task/Mission';
import ContentTask from 'models/task/content/ContentTask';
import DocumentTask from 'models/task/document/DocumentTask';
import DeclarationTask from 'models/task/declaration/DeclarationTask';
import MockupTask from 'models/task/mockup/MockupTask';
import FormTask from 'models/task/form/FormTask';
import VisitLinkTask from 'models/task/VisitLinkTask';
import JoinTask from 'models/task/join/JoinTask';

import useForceUpdate from 'components/_/hooks/useForceUpdate';
import useSearchQuery from 'components/_/hooks/router/useSearchQuery';

import Loader from 'components/commons/_/loader/Loader';

export default function(taskService, browserHistoryService, userProfileService, types) {
	const TaskCompleted = ({ task }) => (
		<div className="taskDetails wrapper--col el--grow">
			<div className="standardPreview wrapper--col el--grow">
				<Header title={task.name()} goBack={{ defaultLocation: '/mission/list' }} />
				<div className="standardPreview__mainWrapper standardPreview__completedStatus el--grow wrapper--col">
					<div className="wrapper--row el--grow standardPreview__columnWrapper overflow--auto">
						<div className="wrapper--col el--grow align--center">
							<h1 className="mb-6">
								<FormattedMessage id="task-details.common.content.answer-status.already-completed" />
							</h1>
							<div className="roundVisualStatus roundVisualStatus--correct el--shrink">
								<div className="roundVisualStatus__signWrapper" />
							</div>
						</div>
					</div>
				</div>
				<div className="standardPreview__footer wrapper--row align--center justify--end">
					<button
						className="btn__basic btn__basic--basicGreen"
						onClick={() =>
							browserHistoryService.goBack({
								defaultLocation: '/mission/list',
							})
						}
					>
						<FormattedMessage id="task-details.common.button.continue" />
					</button>
				</div>
			</div>
		</div>
	);

	function viewWhenComplete(task) {
		return (
			task.viewWhenComplete() &&
			(task instanceof ContentTask ||
				task instanceof DocumentTask ||
				task instanceof DeclarationTask ||
				task instanceof MockupTask ||
				task instanceof FormTask ||
				task instanceof VisitLinkTask ||
				task instanceof JoinTask)
		);
	}

	return ({
		match: {
			params: { id },
		},
	}) => {
		const [inProcess, setProcess] = useState(false);
		const [task, setTask] = useState(undefined);
		const forceUpdate = useForceUpdate();
		const query = useSearchQuery();

		function goBack(event) {
			event.preventDefault();

			browserHistoryService.goBack({
				defaultLocation: '/mission/list',
			});
		}

		function handleResponse(response) {
			// TODO: move to a service
			task.setFeedback(response.feedback);

			if (response.failure) {
				task.setFailed();
			}

			if (response.completion) {
				userProfileService.addToWalletQueue(response.task.state().lastEarnedPoints());

				const currentRepetitionState = response.task.state().currentRepetitionState();

				if (currentRepetitionState) {
					task.state().setCurrentRepetitionState(currentRepetitionState);
				}

				task.setComplete();
			} else {
				// if task was completed, new state will represent state for next completion, not current one
				task.setState(response.task.state());
			}

			return response;
		}

		/**
		 *
		 * @param {any} data
		 * @param {boolean} skipProcess
		 * 	true occures in the mockup task
		 * @param headers
		 * @returns {Promise|undefined}
		 */
		function process(data, skipProcess = false, headers) {
			if (!skipProcess) {
				if (inProcess) {
					return;
				}

				setProcess(true);
			}

			return taskService
				.processTask(task, data, headers, query.get('actionId'))
				.then(handleResponse)
				.catch(() => task.setCrashed())
				.finally(response => {
					task.setSubmitted();

					if (!skipProcess) {
						setProcess(false);
					}

					forceUpdate();

					return response;
				});
		}

		useEffect(() => {
			(async () => setTask(await taskService.fetchDetails({ id })))();
		}, [id]);

		if (task === undefined) {
			return <Loader />;
		}

		if (task === null) {
			return 'Not found task';
		}

		if (task.state().hasStatus('completed') && !(task instanceof Mission) && !viewWhenComplete(task)) {
			return <TaskCompleted task={task} />;
		}

		return React.createElement(types[task.taskType()], {
			task,
			inProcess,
			goBack,
			process,
		});
	};
}
