import React from 'react';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';
import classname from 'classnames';

import SVGImage from 'components/commons/SVGImage';
import images from 'assets/images';

export default function() {
	function DegressiveCurrency({ currency, bigIcons }) {
		const { current, previous } = currency;

		return (
			<div
				title={current.description()}
				className={`currencyContainer wrapper--row align--center ${current.name()}`}
			>
				<div
					className={classname('wrapper--row align--center', {
						degressionAnimation: previous,
					})}
				>
					<SVGImage
						src={
							current.icon() ||
							images.currency[
								current.icon() || (bigIcons ? `${current.codeName()}Big` : current.codeName())
							]
						}
						className="currencyContainer__icon image--contain currency"
					/>
					{previous ? (
						<CountUp
							className={'currencyContainer__number'}
							start={previous.value()}
							end={current.value()}
							duration={3.5}
							delay={1.5}
						/>
					) : (
						<span className="currencyContainer__number">{current.value()}</span>
					)}
				</div>
			</div>
		);
	}

	DegressiveCurrency.propTypes = {
		currency: PropTypes.object,
		bigIcons: PropTypes.bool,
	};

	return DegressiveCurrency;
}
