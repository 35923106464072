import ChoiceQuestionTask from 'models/task/question/questionTask/choice/ChoiceQuestionTask';

/**
 * Class representing a multiple choice question task.
 * @abstract
 * @extends QuestionTask
 */
export default class MultipleChoiceQuestionTask extends ChoiceQuestionTask {
	/**
	 * @returns {boolean}
	 */
	hasMultipleAnswers() {
		return !!this._multipleAnswers;
	}

	/**
	 *
	 * @param {Answer} answer
	 */
	checkAnswer(answer) {
		answer.invertCheck();
	}

	/**
	 *
	 * @param {Answer} answer
	 * @returns {string}
	 */
	getAnswerType(answer) {
		return `${answer.type()}_multiple`;
	}
}
