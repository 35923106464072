import React, { PureComponent } from 'react';
import _ from 'lodash';

import Attribute from 'models/task/content/Attribute';

export default function() {
	return class AttributesBlock extends PureComponent {
		constructor(props) {
			super();

			this._attributeList = props.block._attributeViews;
		}

		attributeList() {
			return _.map(this._attributeList, attribute => new Attribute(attribute));
		}

		color(passedColor) {
			if (!passedColor) {
				return null;
			}

			const { red, green, blue, alpha } = passedColor;

			return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
		}

		render() {
			return (
				<div className="attributes wrapper--row wrapper--row--wrap justify--center">
					{this.attributeList().map((attribute, index) => (
						<div className="attribute wrapper--col align--center" key={index}>
							<div className="attribute__chartWrapper wrapper--col justify--center align--center">
								{attribute.maxValue() && (
									<div className="svgWrapper">
										<svg width={'120px'} height={'120px'} viewBox={'0 0 120 120'}>
											<path
												className="circle"
												stroke={this.color(attribute.color())}
												strokeDasharray={`${attribute.progress() * 360} 1000`}
												d="M60 3
									            a 57 57 0 0 1 0 114
									            a 57 57 0 0 1 0 -114"
											/>
										</svg>
									</div>
								)}
								<div
									className="image image--cover"
									style={{
										backgroundImage: `url(${attribute.image()}?w=128)`,
										backgroundColor: `${this.color(attribute.color())}`,
									}}
								/>
								<div className="infoCloud">
									<p>{attribute.name()}</p>
									<p>{attribute.description()}</p>
								</div>
							</div>
							<div className="attribute__textData">
								<p className="name">{attribute.name()}</p>
								<div className="number">
									<p>
										{attribute.maxValue()
											? `${attribute.value()}/${attribute.maxValue()}`
											: attribute.value()}
									</p>
									<p>
										{attribute.maxValue()
											? `${_.ceil((attribute.value() / attribute.maxValue()) * 100)}%`
											: attribute.value()}
									</p>
								</div>
							</div>
						</div>
					))}
				</div>
			);
		}
	};
}
