import contentTaskServices from 'services/task/subServices/contentTask';
import documentTaskServices from 'services/task/subServices/documentTask';
import embeddedAnalyticsTaskServices from 'services/task/subServices/embeddedAnalyticsTask';
import formTaskServices from 'services/task/subServices/formTask';
import joinTaskServices from 'services/task/subServices/joinTask';
import questionTaskServices from 'services/task/subServices/questionTask';
import userProfileFieldsTaskServices from 'services/task/subServices/userProfileFieldsTask';

import CollectionMissionTaskService from 'services/task/subServices/CollectionMissionTaskService';
import DeclarationTaskService from 'services/task/subServices/DeclarationTaskService';
import GraphTaskService from 'services/task/subServices/GraphTaskService';
import MapTaskService from 'services/task/subServices/MapTaskService';
import MockupTaskService from 'services/task/subServices/MockupTaskService';
import VisitLinkTaskService from 'services/task/subServices/VisitLinkTaskService';

export default {
	...contentTaskServices,
	...documentTaskServices,
	...embeddedAnalyticsTaskServices,
	...formTaskServices,
	...joinTaskServices,
	...questionTaskServices,
	...userProfileFieldsTaskServices,
	'services.collectionMissionTaskService': {
		class: CollectionMissionTaskService,
		deps: [
			{ $ref: 'services.taskStateService' },
			{ $ref: 'services.taskService' },
			{ $ref: 'services.currencyService' },
		],
	},
	'services.declarationTaskService': {
		class: DeclarationTaskService,
		deps: [{ $ref: 'services.APIService' }, { $ref: 'services.taskStateService' }],
	},
	'services.graphTaskService': {
		class: GraphTaskService,
		deps: [{ $ref: 'services.taskStateService' }],
	},
	'services.mapTaskService': {
		class: MapTaskService,
		deps: [{ $ref: 'services.taskStateService' }],
	},
	'services.mockupTaskService': {
		class: MockupTaskService,
		deps: [{ $ref: 'services.taskStateService' }],
	},
	'services.visitLinkTaskService': {
		class: VisitLinkTaskService,
		deps: [{ $ref: 'services.taskStateService' }],
	},
};
