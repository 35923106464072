import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function(DegressionCurrencies) {
	return class DegressionCurrenciesComponent extends Component {
		constructor(props) {
			super(props);

			this.state = {
				previousCurrencies: undefined,
				currentCurrencies: props.currencies,
			};
		}

		render() {
			return <DegressionCurrencies {...this.props} currencies={this._computedCurrencies()} />;
		}

		/**
		 * Method returns array of objects.
		 * Each represents previous and current currency values.
		 * They are needed by DegressiveCurrenies component for fire animation if previous and current values differ from each other.
		 * @returns {Object[]}
		 */
		_computedCurrencies() {
			const { previousCurrencies, currentCurrencies } = this.state;

			return _.reduce(
				currentCurrencies.values(),
				(computedCurrencies, currency) => {
					computedCurrencies.push({
						current: currency,
						previous: previousCurrencies && previousCurrencies.getCurrencyByName(currency.codeName()),
					});

					return computedCurrencies;
				},
				[]
			);
		}

		static getDerivedStateFromProps(props, currentState) {
			return _.isEqual(currentState.currentCurrencies.values(), props.currencies.values())
				? null
				: {
						previousCurrencies: currentState.currentCurrencies,
						currentCurrencies: props.currencies,
				  };
		}

		static propTypes = {
			currencies: PropTypes.object,
			bigIcons: PropTypes.bool,
			parentClasses: PropTypes.string,
		};
	};
}
