import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import { injectIntl } from 'react-intl';
import { Formik } from 'formik';

import ImportedId from './ImportedId.template';
import { schema } from './ImportedId.schema';
import Register from './register/Register';

export class ImportedIdComponent extends PureComponent {
	_status = null;

	render() {
		return (
			<Formik
				initialValues={{
					identifier: '',
				}}
				validationSchema={schema}
				onSubmit={(...args) => this.login(...args)}
				render={props => {
					if (props.status === 'create-account') {
						return <Register integration={this.props.integration} identifier={props.values.identifier} />;
					}

					return <ImportedId {...props} {...this.props} {...this.state} />;
				}}
			/>
		);
	}

	login(values, actions) {
		const { integration } = this.props;

		if (values.password) {
			return this.props['services.importedIdIntegrationService']
				.login({
					id: integration.id,
					...values,
				})
				.catch(({ error }) => {
					actions.setFieldError(
						error.messageId === 'invalid-password' ? 'password' : 'identifier',
						error.messageId
					);
					actions.setSubmitting(false);
				});
		}

		if (!values.password && !this._status) {
			return this.props['services.importedIdIntegrationService']
				.check({
					id: integration.id,
					...values,
				})
				.then(response => {
					actions.setStatus(response);
					this._status = response;
				})
				.catch(error => {
					if (error === 'user-not-verified') {
						actions.setStatus('create-account');
					} else {
						actions.setFieldError('identifier', error.message ? error.message : error);
					}
				})
				.finally(() => actions.setSubmitting(false));
		}

		actions.setFieldError('password', 'empty-password');
		actions.setSubmitting(false);
	}
}

export default injectIntl(wire(['services.importedIdIntegrationService'], ImportedIdComponent));
