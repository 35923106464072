import AchievementNotification from 'views/application/realTimeNotifications/type/Achievement';
import ChallengeSubmissionStateChanged from 'views/application/realTimeNotifications/type/ChallengeSubmissionStateChanged';
import DeclarationTaskAcceptanceChanged from 'views/application/realTimeNotifications/type/DeclarationTaskAcceptanceChanged';
import LevelUpNotification from 'views/application/realTimeNotifications/type/LevelUp';
import MissionNotification from 'views/application/realTimeNotifications/type/Mission';

export default {
	'components.RealTimeNotifications.type.achievement': {
		factory: AchievementNotification,
		deps: [
			{ $ref: 'components.RealTimeNotifications.Notification' },
			{ $ref: 'components.RealTimeNotifications.buttons.default' },
		],
	},
	'components.RealTimeNotifications.type.challengeSubmissionStateChanged': {
		factory: ChallengeSubmissionStateChanged,
		deps: [
			{ $ref: 'components.RealTimeNotifications.Notification' },
			{ $ref: 'components.RealTimeNotifications.buttons.default' },
		],
	},
	'components.RealTimeNotifications.type.declarationTaskAcceptanceChanged': {
		factory: DeclarationTaskAcceptanceChanged,
		deps: [
			{ $ref: 'components.RealTimeNotifications.Notification' },
			{ $ref: 'components.RealTimeNotifications.buttons.declarationTask' },
		],
	},
	'components.RealTimeNotifications.type.levelUp': {
		factory: LevelUpNotification,
		deps: [
			{ $ref: 'components.RealTimeNotifications.Notification' },
			{ $ref: 'components.RealTimeNotifications.buttons.default' },
		],
	},
	'components.RealTimeNotifications.type.mission': {
		factory: MissionNotification,
		deps: [
			{ $ref: 'components.RealTimeNotifications.Notification' },
			{ $ref: 'components.RealTimeNotifications.buttons.nextTask' },
			{ $ref: 'components.RealTimeNotifications.buttons.default' },
		],
	},
};
