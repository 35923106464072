import ContentBlock from 'models/common/content/block/ContentBlock';

export default class HeaderContentBlock extends ContentBlock {
	constructor(block) {
		super(block);

		this._header = block.header;
		this._level = block.level;
	}

	header() {
		return this._header;
	}

	level() {
		return this._level;
	}
}
