import definition from './definition';
import { UserProfileFieldService } from './UserProfileFieldService';

export default {
	...definition,
	'services.userProfileFieldService': {
		class: UserProfileFieldService,
		deps: [{ $ref: 'services.userProfileFieldDefinitionService' }],
	},
};
