import React, { useState, Fragment } from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';

import { Input } from 'components/_/form/input/Input';
import { Button } from 'components/_/common/button/Button';

import useStyles from './InputFile.jss';

export default injectIntl(({ intl, ...props }) => {
	return (
		<InputFile
			{...props}
			intl={{
				fileUpload: {
					placeholder: intl.formatMessage({ id: 'common.form.block.fileUpload.placeholder' }),
					processing: intl.formatMessage({ id: 'common.form.block.fileUpload.processing' }),
					deleteButton: intl.formatMessage({ id: 'common.form.block.fileUpload.deleteButton' }),
					chooseButton: intl.formatMessage({ id: 'common.form.block.fileUpload.chooseButton' }),
				},
			}}
		/>
	);
});

export const InputFile = ({
	intl,
	file: defaultFile,
	disabled,
	saveFileHandler = () => Promise.resolve(),
	deleteFileHandler = () => Promise.resolve(),
	clearInputHandler,
	...props
}) => {
	const classes = useStyles()();
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState(defaultFile);

	function onChange(event) {
		event.preventDefault();

		const [file] = event.currentTarget.files;

		setLoading(true);

		saveFileHandler(file).then(() => {
			setLoading(false);
			setFile(file);
		});
	}

	function onDelete() {
		setLoading(true);

		deleteFileHandler(file).then(() => {
			setLoading(false);
			setFile(null);
		});
	}

	function onClear() {
		clearInputHandler();
	}

	return (
		<Fragment>
			<div className={classnames('d-flex', classes.container)}>
				<Input
					rounded
					className="mr-1"
					{...props}
					disabled={!!file || loading}
					value={file ? `${file.name} (${file.type})` : intl.fileUpload.placeholder}
				/>
				{loading ? (
					<Button color="warning" className="d-flex align-items-center" disabled>
						<span className="mr-2">{intl.fileUpload.processing}</span>
						<i className="fa fa-cog fa-spin" />
					</Button>
				) : file ? (
					<Button
						color={disabled ? 'disabled' : 'danger'}
						disabled={disabled}
						className="d-flex align-items-center"
						onClick={onDelete}
					>
						<span className="mr-2">{intl.fileUpload.deleteButton}</span>
						<i className="fa fa-trash" />
					</Button>
				) : (
					<Fragment>
						<Button color={disabled ? 'disabled' : 'interactive'} disabled={disabled}>
							{intl.fileUpload.chooseButton}
						</Button>
						<input type="file" onChange={onChange} />
					</Fragment>
				)}
				{clearInputHandler && !loading && (
					<Button
						color={disabled ? 'disabled' : 'danger'}
						disabled={disabled}
						className="ml-1"
						onClick={onClear}
					>
						<i className="fa fa-close" />
					</Button>
				)}
			</div>
		</Fragment>
	);
};
