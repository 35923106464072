import Notification from 'models/notification/Notification';

import images from 'assets/images';

/**
 * Class representing a email notification.
 * @extends Notification
 */
export default class EmailNotification extends Notification {
	getMessage() {
		return super.getMessage(`messages.email.${this._type}`);
	}

	getDate(params) {
		return super.getDate('dates.email', params);
	}

	getIconPath() {
		return super.getIconPath(images.icon.staticNotificationTypes.email);
	}
}
