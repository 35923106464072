export default class AttributeView {
	constructor(attributeView) {
		//TODO: service registration
		this._attributeDefinition = attributeView.attribute_definition;
		this._attributeValue = attributeView.attribute_value;
		this._maxValue = attributeView.max_value;
	}

	attributeValue() {
		return this._attributeValue;
	}

	maxValue() {
		return this._maxValue;
	}
}
