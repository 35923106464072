import React from 'react';

import images from 'assets/images';

import SVGImage from 'components/commons/SVGImage';
import RankingEntry from 'components/ranking/_/entry/Entry';

const User = ({ ranking, position, entry }) => {
	const currency = ranking.currency && (
		<SVGImage src={ranking.currency.image || images.currency[ranking.currency.codeName]} className="pr-2 icon" />
	);

	return (
		<RankingEntry
			ranking={ranking}
			currency={currency}
			position={position}
			entry={{
				...entry,
				url: `/user/${entry.userProfile.id()}/details`,
				avatar: entry.userProfile.avatar() || images.default.avatar.user,
				level: entry.userProfile.user.level() && entry.userProfile.user.level().levelNumber(),
			}}
			team={
				entry.userProfile.membership() &&
				entry.userProfile.membership().team() && {
					avatar:
						entry.userProfile
							.membership()
							.team()
							.avatar() || images.default.avatar.team,
					url: `/team/${entry.userProfile
						.membership()
						.team()
						.id()}/members`,
				}
			}
			name={entry.userProfile.name()}
			shortName={entry.userProfile.nickname()}
		/>
	);
};

export default User;
