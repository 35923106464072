import ContentBlock from 'models/common/content/block/ContentBlock';

export default class TextContentBlock extends ContentBlock {
	constructor(block) {
		super(block);

		this._body = block.body;
	}

	body() {
		return this._body;
	}
}
