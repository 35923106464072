import { PureComponent } from 'react';
import { wire } from 'react-hot-wire';

export class LogoutComponent extends PureComponent {
	UNSAFE_componentWillMount() {
		this.props['services.authService'].logout();
	}

	render() {
		return null;
	}
}

export default wire(['services.authService'], LogoutComponent);
