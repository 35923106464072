import React, { Fragment, PureComponent } from 'react';
import { wire } from 'react-hot-wire';

class ImpersonateUserInfoBar extends PureComponent {
	render() {
		const isImpersonating = Boolean(this.props['services.accessTokenService'].impersonateUserId());
		const user = this.props['services.userService'].user();
		const userInfoFull = user
			? user.userProfile
				? `You are impersonating another user:
					${user.userProfile.forename()}
					${user.userProfile.surname()}
				`
				: 'You are impersonating an anonymous user'
			: 'You are impersonating a user';
		const userInfoShort = user
			? user.userProfile
				? `You are:
					${user.userProfile.forename()}
					${user.userProfile.surname()}
				`
				: 'You are an anonymous user'
			: 'You are not you.';
		return (
			<Fragment>
				{isImpersonating && (
					<div className="globalBar">
						<span className="d-none d-md-inline">{userInfoFull}</span>
						<span className="d-md-none">{userInfoShort}</span>
					</div>
				)}
				{this.props.children}
			</Fragment>
		);
	}
}

export default wire(['services.accessTokenService', 'services.userService'], ImpersonateUserInfoBar);
