export default class TransactionLink {
	constructor(transactionLink) {
		this._linkType = transactionLink.link_type;
		this._referenceType = transactionLink.reference_type;
	}

	linkType() {
		return this._linkType;
	}

	referenceType() {
		return this._referenceType;
	}
}
