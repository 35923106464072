export class RewardCafeteriaItem {
	static DELIVERY_TYPES = ['digital', 'physical'];

	constructor(item) {
		this.id = item.id;
		this.name = item.name;
		this.description = item.description;
		this.price = item.price;
		this.cost = item.cost;
		this.isAvailable = item.is_available;
		this.assets = item.assets || [];
		this.deliveryType = item.delivery_type;

		if (!RewardCafeteriaItem.DELIVERY_TYPES.includes(item.delivery_type)) {
			throw new Error(`Not supported delivery type: ${item.delivery_type}`);
		}
	}

	get image() {
		const defaultImage = `http://placekitten.com/200/${Math.round(200 + Math.random() * 100)}`;

		return (this.assets[0] && this.assets[0].image) || defaultImage;
	}
}
