import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form, Field, ErrorMessage } from 'formik';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

export default injectIntl(({ intl, status, integration, isValid, isSubmitting, errors, touched }) => (
	<div className="integration">
		<Form>
			<h2>
				{integration.authenticationTitle || (
					<FormattedMessage id="auth.login.integration.imported-id.title" tagName={React.Fragment} />
				)}
			</h2>
			<div
				className={classnames('field', {
					valid: status === 'user-ok',
					invalid: errors.identifier && touched.identifier,
				})}
			>
				<Field
					name="identifier"
					render={({ field }) => (
						<input
							{...field}
							type="text"
							autoComplete="username"
							placeholder={
								integration.loginFieldPlaceholder ||
								intl.formatMessage({
									id: 'auth.login.integration.imported-id.field.identifier.placeholder',
								})
							}
							disabled={status}
						/>
					)}
				/>
				<ErrorMessage
					name="identifier"
					render={error => (
						<div className="error">
							<FormattedMessage id={`auth.form.error.${error}`} />
						</div>
					)}
				/>
			</div>
			{status === 'user-ok' && (
				<div
					className={classnames('field', {
						invalid: errors.password && touched.password,
					})}
				>
					<Field
						name="password"
						render={({ field }) => (
							<input
								{...field}
								type="password"
								autoComplete="current-password"
								autoFocus
								placeholder={intl.formatMessage({
									id: 'auth.login.integration.imported-id.field.password.placeholder',
								})}
							/>
						)}
					/>
					<ErrorMessage
						name="password"
						render={error => (
							<div className="error">
								<FormattedMessage id={`auth.form.error.${error}`} />
							</div>
						)}
					/>
				</div>
			)}
			<button
				className={classnames('btn__basic btn__basic--fullWidth', {
					'btn__basic--disabled': !isValid,
					loading: isSubmitting,
				})}
				type="submit"
			>
				<FormattedMessage
					id={`auth.login.integration.imported-id.button.${status === 'user-ok' ? 'login' : 'check'}`}
				/>
			</button>
			<NavLink to={`/auth/reset/${integration.id}`} className="anchor--reset">
				<FormattedMessage id="auth.login.navigate.do-not-remember-identifier" tagName={React.Fragment} />
			</NavLink>
		</Form>
	</div>
));
