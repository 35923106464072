import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import multiplicity from 'utils/Math/multiplicity';

export default function(Currencies) {
	return class Mobile extends PureComponent {
		render() {
			const { task, actions } = this.props;

			return (
				<div className="visible--mobile wrapper--col el--grow">
					{task.isCompleted() ? (
						<div className="mobileTaskStatus mobileTaskStatus--correct mobileTaskStatus--task wrapper--col el--grow visible--mobile">
							<div className="mobileTaskStatus__content mb-3 overflow-auto">
								{task.successMessage() ? (
									<p
										className={`contentText contentText--dynamic--${multiplicity(
											task.successMessage(),
											100
										)}`}
									>
										{task.successMessage()}
									</p>
								) : (
									<p className="contentText contentText--big">
										<FormattedMessage id="task-details.common.content.answer-status.correct-answer" />
									</p>
								)}
								<div
									className={`m-auto roundVisualStatus roundVisualStatus--correct el--shrink image image--contain ${
										task.successMessage()
											? task.successMessage().length > 180
												? 'roundVisualStatus--small'
												: 'roundVisualStatus--medium'
											: ''
									}`}
								>
									<div className="roundVisualStatus__signWrapper" />
								</div>
								{task.state().hasLastEarnedPoints() ? (
									<div className="wrapper--col align--center mt-3">
										<p className="contentText contentText--light">
											<FormattedMessage id="task-details.common.content.answer-status.gaining" />
										</p>
										<Currencies
											parentClasses="wrapper--row align--center justify--center currencyContainer__wrapper"
											currencies={task.state().lastEarnedPoints()}
										/>
									</div>
								) : (
									<div className="mt-3" />
								)}
								<div>
									<NavLink
										to="/"
										onClick={event => actions.goBack(event)}
										className="btn__basic continueButton btn__basic--basicGreen"
									>
										<FormattedMessage id="task-details.common.button.continue" />
									</NavLink>
								</div>
							</div>
						</div>
					) : null}
					{task.isFailed() || task.isCrashed() ? (
						<div className="mobileTaskStatus mobileTaskStatus--incorrect mobileTaskStatus--task wrapper--col el--grow">
							<div className="mobileTaskStatus__content wrapper--col align--center justify--between overflow-auto">
								{task.failureMessage() ? (
									<p
										className={`contentText contentText--dynamic--${multiplicity(
											task.failureMessage(),
											100
										)}`}
									>
										{task.failureMessage()}
									</p>
								) : (
									<p className="contentText contentText--big">
										<FormattedMessage id="task-details.common.content.answer-status.wrong-answer" />
									</p>
								)}
								<div className="roundVisualStatus roundVisualStatus--incorrect el--shrink image image--contain">
									<div className="roundVisualStatus__signWrapper" />
								</div>
								<div className="d-flex align-items-center">
									{task.helpEnabled() && (
										<a
											href={task.helpUrl()}
											className="btn__basic btn__basic--help mr-3"
											target="_blank"
											rel="noopener noreferrer"
										>
											<FormattedMessage id="task-details.common.button.help" />
										</a>
									)}
									<button
										className="btn__basic btn__basic--basicGreen continueButton"
										onClick={() => actions.tryAgain()}
									>
										<FormattedMessage id="task-details.common.button.do-again" />
									</button>
								</div>
							</div>
						</div>
					) : null}
					{task.state().currentRepetitionState() &&
					task
						.state()
						.currentRepetitionState()
						.acceptanceState() === 'awaiting' ? (
						<div className="mobileTaskStatus mobileTaskStatus--awaiting mobileTaskStatus--task wrapper--col el--grow">
							<div className="mobileTaskStatus__content wrapper--col align--center overflow-auto">
								{task.acceptanceMessage() ? (
									<p className="contentText">{task.acceptanceMessage()}</p>
								) : (
									<p className="contentText contentText--big">
										<FormattedMessage id="task-details.common.content.answer-status.awaiting" />
									</p>
								)}
								<div className="roundVisualStatus roundVisualStatus--awaiting el--shrink image image--contain mt-6">
									<div className="roundVisualStatus__signWrapper" />
								</div>
							</div>
						</div>
					) : null}
					{task.state().currentRepetitionState() &&
					task
						.state()
						.currentRepetitionState()
						.acceptanceState() === 'rejected' ? (
						<div className="mobileTaskStatus mobileTaskStatus--rejected mobileTaskStatus--task wrapper--col el--grow">
							<div className="mobileTaskStatus__content wrapper--col align--center justify--between overflow-auto">
								{task
									.state()
									.currentRepetitionState()
									.rejectionReason() ? (
									<p className="contentText">
										<FormattedMessage
											id="task-details.common.content.answer-status.rejected-reason"
											values={{
												reason: task
													.state()
													.currentRepetitionState()
													.rejectionReason(),
											}}
										/>
									</p>
								) : (
									<p className="contentText contentText--big">
										<FormattedMessage id="task-details.common.content.answer-status.rejected" />
									</p>
								)}
								<div className="roundVisualStatus roundVisualStatus--incorrect el--shrink image image--contain">
									<div className="roundVisualStatus__signWrapper" />
								</div>
								<div className="d-flex align-items-center">
									{task.helpEnabled() && (
										<a
											href={task.helpUrl()}
											className="btn__basic btn__basic--help mr-3"
											target="_blank"
											rel="noopener noreferrer"
										>
											<FormattedMessage id="task-details.common.button.help" />
										</a>
									)}
									<button
										className="btn__basic btn__basic--basicGreen continueButton"
										onClick={() => actions.resetTask()}
									>
										<FormattedMessage id="task-details.common.button.do-again" />
									</button>
								</div>
							</div>
						</div>
					) : null}
				</div>
			);
		}
	};
}
