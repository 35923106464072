import React, { Fragment, useState } from 'react';
import classnames from 'classnames';

import { convertToRaw, EditorState, RichUtils } from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';
import Editor from 'draft-js-plugins-editor';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';

import { ItalicButton, BoldButton, UnderlineButton, UnorderedListButton, OrderedListButton } from 'draft-js-buttons';

import buttonStyles from './buttonStyles.module.css';
import toolbarStyles from './toolbarStyles.module.css';

const toolbarPlugin = createToolbarPlugin({
	theme: { buttonStyles, toolbarStyles },
});
const { Toolbar } = toolbarPlugin;

export const WysiwygEditor = (props: {
	onChange: (value: string) => void;
	className?: string;
	placeholder?: string;
}) => {
	const [editorState, setEditorState] = useState(EditorState.createEmpty());

	function onChange(state: EditorState) {
		const content = state.getCurrentContent();
		const rawObject = convertToRaw(content);
		const markdownString = draftToMarkdown(rawObject);

		props.onChange(markdownString);

		setEditorState(state);
	}

	function handleKeyCommand(command: any, editorState: EditorState) {
		const newState = RichUtils.handleKeyCommand(editorState, command);

		if (newState) {
			onChange(newState);

			return 'handled';
		}

		return 'not-handled';
	}

	return (
		<div className={classnames('wysiwyg d-flex flex-column', props.className)}>
			<Toolbar>
				{(externalProps: any) => (
					<Fragment>
						<BoldButton {...externalProps} />
						<ItalicButton {...externalProps} />
						<UnderlineButton {...externalProps} />
						<UnorderedListButton {...externalProps} />
						<OrderedListButton {...externalProps} />
					</Fragment>
				)}
			</Toolbar>
			<div className="wysiwyg__body d-flex">
				<Editor
					editorState={editorState}
					onChange={onChange}
					handleKeyCommand={handleKeyCommand}
					plugins={[toolbarPlugin]}
				/>
			</div>
		</div>
	);
};
