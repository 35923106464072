import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

export default function(BaseLanguageSwitcher, i18nService) {
	return class LanguageSwitcher extends BaseLanguageSwitcher {
		render() {
			return (
				i18nService.availableLocales().length > 1 && (
					<div
						className={classnames('languageSwitcher languageSwitcher__app', {
							opened: this.state.opened,
						})}
					>
						<div className="locale">
							<div className="current" onClick={() => this._open()}>
								{i18nService.locale()}
							</div>
							<div className="list">
								<div className="returnArrow returnArrow--right" onClick={() => this._close()}></div>
								<ul>
									{_.map(i18nService.availableLocales(), locale => (
										<li
											key={locale}
											onClick={() => this._onLocaleChange(locale)}
											className={classnames({
												active: locale === i18nService.locale(),
											})}
										>
											{locale}
										</li>
									))}
								</ul>
							</div>
						</div>
						<div className="background" onClick={() => this._close()}></div>
					</div>
				)
			);
		}
	};
}
