/**
 * https://github.com/ampproject/amphtml/issues/11133
 * In IOs iframe does not resize down to fit it's parent width, but to the full size of the document it contains.
 * If iframe total width is smaller than its parent, it will stretch to desired width, problem is width shrinking which is not happening causing horizontal scroll and ruin mobile layout.
 * It is known issue, although none of founded hack solutions did not work. Solution with dynamic scaling is fixing problem.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Loader from 'components/commons/_/loader/Loader';

export default function() {
	return class ScalableIframe extends Component {
		_elementRefs = {};
		state = {
			scale: 1,
			parentHeight: 'auto',
			loaded: false,
		};

		componentWillUnmount() {
			window.removeEventListener('resize', this.__scaleIframe);
		}

		render() {
			const { src } = this.props;
			const { parentHeight, scale, loaded } = this.state;

			return (
				<div
					className="scalableIframe el--grow el--shrink"
					ref={element => {
						this._elementRefs.parentNode = element;
					}}
					style={{ height: parentHeight }}
				>
					{loaded ? null : <Loader />}
					<iframe
						src={src}
						width="100%"
						height="100%"
						ref={element => {
							this._elementRefs.iframe = element;
						}}
						style={{
							display: loaded ? 'block' : 'none',
							transform: `scale(${scale})`,
						}}
						onLoad={() => this._init()}
						title="scalable-iframe"
					/>
				</div>
			);
		}

		_init() {
			const { iframe } = this._elementRefs;

			this._iframeRatio = iframe.offsetHeight / iframe.offsetWidth;
			this._setScale();
			window.addEventListener('resize', (this.__scaleIframe = () => this._setScale()));
		}

		_setScale() {
			const { iframe, parentNode } = this._elementRefs;
			const scale = (parentNode.offsetWidth / iframe.offsetWidth).toFixed(2);

			this.setState({
				loaded: true,
				scale: scale,
				parentHeight: window.innerWidth < 640 ? `${parentNode.offsetWidth * this._iframeRatio}px` : 'auto',
			});
		}

		static propTypes = {
			src: PropTypes.string,
		};
	};
}
