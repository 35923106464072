import React from 'react';
import { Form, Field, ErrorMessage } from 'formik';
import { injectIntl, FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

export default injectIntl(({ intl, errors, touched, isSubmitting, isValid }) => (
	<div className="integration">
		<Form>
			<h2>
				<FormattedMessage id="auth.reset-password.integration.imported-id.title" tagName={React.Fragment} />
			</h2>
			<p>
				<FormattedMessage
					id="auth.reset-password.integration.imported-id.instruction.text"
					values={{
						identifier: (
							<span>
								{intl.formatMessage({
									id: 'auth.reset-password.integration.imported-id.instruction.identifier',
								})}
							</span>
						),
					}}
					tagName={React.Fragment}
				/>
			</p>
			<div
				className={classnames('field', {
					invalid: errors.identifier && touched.identifier,
				})}
			>
				<Field
					name="identifier"
					render={({ field }) => (
						<input
							{...field}
							type="text"
							autoComplete="username"
							placeholder={intl.formatMessage({
								id: 'auth.reset-password.integration.imported-id.field.identifier.placeholder',
							})}
							disabled={isSubmitting}
						/>
					)}
				/>
				<ErrorMessage
					name="identifier"
					render={error => (
						<div className="error">
							<FormattedMessage id={`auth.form.error.${error}`} />
						</div>
					)}
				/>
			</div>
			<button
				className={classnames('btn__basic btn__basic--fullWidth', {
					'btn__basic--disabled': !isValid,
					loading: isSubmitting,
				})}
				type="submit"
			>
				<FormattedMessage id="auth.reset-password.integration.imported-id.button" />
			</button>
			<p>
				<FormattedMessage id="auth.reset-password.navigate.remember-password" tagName={React.Fragment} />{' '}
				<NavLink to="/auth/login?disableLandingPage">
					<FormattedMessage id="auth.reset-password.navigate.login" tagName={React.Fragment} />
				</NavLink>
			</p>
		</Form>
	</div>
));
