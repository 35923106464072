import { createUseStyles } from 'react-jss';
import chroma from 'chroma-js';

export default () => {
	return createUseStyles(theme => {
		const textSize = 1;
		const borderSize = 0.0625; // 1 / 16
		const verticalPaddings = 0.3;
		const size = textSize + verticalPaddings * 2 + borderSize * 2; // text + (py * 2) + (border * 2)
		const arrowPaddings = 0.2;
		const arrowSize = size - arrowPaddings * 2;

		return {
			select: {
				position: 'relative',
				display: 'block',
				cursor: 'pointer',
				fontSize: `${textSize}em`,
				height: `${size}em`,

				'&:hover': {
					'& select:not([disabled])': {
						color: theme.color.system['color--interactive--background'],
						borderColor: theme.color.system['color--interactive--background'],

						'& + $arrow': {
							background: `linear-gradient(to bottom, ${
								theme.color.system['color--interactive--background']
							} 0%, ${chroma(theme.color.system['color--interactive--background']).darken(0.9)} 100%)`,
						},
					},
				},

				'& select': {
					fontSize: `${textSize}em`,
					position: 'relative',
					zIndex: 300,
					backgroundColor: theme.color.system['color--content--foreground-3'],
					color: theme.color.system['color--content--foreground-60'],
					border: `${borderSize}em solid ${theme.color.system['color--content--foreground-20']}`,
					borderRadius: `${size}em`,
					appearance: 'none',
					padding: `${verticalPaddings}em ${arrowSize +
						arrowPaddings * 2}em ${verticalPaddings}em ${arrowSize / 2}em`,
					height: 'inherit',
					outline: 'none',
					cursor: 'pointer',
					maxWidth: '100%',
					// global overrides:
					width: '100%',
					marginBottom: 'auto',

					'&::-ms-expand': {
						display: 'none',
					},

					'&:active + $arrow': {
						transform: 'rotate(0deg) translateZ(0)',
					},

					'&:focus': {
						backgroundColor: theme.color.system['color--content--foreground-5'],
						color: theme.color.system['color--interactive--background'],
						borderColor: theme.color.system['color--interactive--background'],
					},

					'&[disabled]': {
						cursor: 'not-allowed',
						backgroundColor: theme.color.system['color--content--foreground-20'],
						color: theme.color.system['color--content--foreground-60'],
					},
				},
			},
			arrow: {
				position: 'absolute',
				zIndex: 200,
				top: `${arrowPaddings}em`,
				bottom: `${arrowPaddings}em`,
				right: `${arrowPaddings}em`,
				width: `${arrowSize}em`,
				backgroundColor: theme.color.system['color--content--foreground-20'],
				borderRadius: '50%',
				transform: 'rotate(180deg) translateZ(0)',
				transition: 'transform 0.2s ease-in-out',
				willChange: 'transform',

				'&::after, &::before': {
					content: '""',
					width: `${arrowSize * 0.12}em`,
					height: `${arrowSize * 0.5}em`,
					backgroundColor: '#FFF',
					position: 'absolute',
					top: '50%',
					left: '50%',
				},

				'&::after': {
					transform: `translate(-${arrowSize * 0.2}em, -${arrowSize * 0.3}em) rotate(45deg)`,
				},

				'&::before': {
					transform: `translate(${arrowSize * 0.07}em, -${arrowSize * 0.3}em) rotate(-45deg)`,
				},
			},
		};
	});
};
