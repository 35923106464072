import BaseChallengeAwardedPlaceService from 'services/challenge/awardedPlace/BaseChallengeAwardedPlaceService';

import ChallengeTeamAwardedPlace from 'models/challenge/awardedPlace/ChallengeUserAwardedPlace';

export default class ChallengeTeamAwardedPlaceService extends BaseChallengeAwardedPlaceService {
	constructor(teamService, ...services) {
		super(...services);

		this._teamService = teamService;
	}

	toModel({ teams, ...rest }) {
		return new ChallengeTeamAwardedPlace({
			...super.toModel(rest),
			teams: teams.map(team => this._teamService.toModel(team)),
		});
	}
}
