import ChooseContainer from 'components/application/entryBlocks/blocks/teamAssignment/ChooseContainer';
import ChooseTeam from 'components/application/entryBlocks/blocks/teamAssignment/ChooseTeam';
import TeamAssignment from 'components/application/entryBlocks/blocks/teamAssignment/TeamAssignment';

export default {
	'components.base.EntryBlocks.TeamAssignment.ChooseContainer': {
		factory: ChooseContainer,
		deps: [{ $ref: 'services.teamService' }],
	},
	'components.base.EntryBlocks.TeamAssignment.ChooseTeam': {
		factory: ChooseTeam,
		deps: [{ $ref: 'services.teamService' }],
	},
	'components.base.EntryBlocks.TeamAssignment': {
		factory: TeamAssignment,
		deps: [{ $ref: 'services.teamService' }],
	},
};
