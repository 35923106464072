import AuthService from 'services/auth/AuthService';

export default {
	'services.authService': {
		class: AuthService,
		deps: [
			{ $ref: 'services.APIService' },
			{ $ref: 'services.navigationService' },
			{ $ref: 'services.accessTokenService' },
			{ $ref: 'services.i18nService' },
		],
	},
};
