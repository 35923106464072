import QuestionTask from 'models/task/question/questionTask/QuestionTask';
import WordBasedQuestion from 'models/task/question/WordBasedQuestion';

export default class CompletePhraseQuestionTask extends QuestionTask {
	constructor(task) {
		super(task);

		/**
		 * @type {GuessQuestion}
		 */
		this._question = new WordBasedQuestion(task.question);
	}
}
