import _ from 'lodash';

import RGB from 'models/application/color/RGB';
import RGBA from 'models/application/color/RGBA';

export default class ColorDefinition {
	constructor(name, { color, alphas = [] }) {
		this._name = name;
		this._rgb = new RGB(color);
		this._alphas = _.map(alphas, alpha => this._mapAlpha(alpha));
	}

	name() {
		return this._name;
	}

	rgb() {
		return this._rgb;
	}

	alpha(percent) {
		return _.find(this._alphas, alpha => alpha.alpha === percent).toString();
	}

	alphas() {
		return this._alphas;
	}

	_mapAlpha(alpha) {
		const { red, green, blue } = this._rgb;

		return new RGBA({ red, green, blue, alpha });
	}
}
