import React, { Fragment, useEffect, useState } from 'react';
import { wire } from 'react-hot-wire';
import { FormattedMessage } from 'react-intl';
import find from 'lodash/find';

import View from 'components/_/common/view/list/ListView';
import RankingUserPosition from 'components/ranking/_/user-position/UserPosition';
import { Markdown } from 'components/_/common/text/markdown/Markdown';

import { RankingPositionList } from './RankingPositionList';
import RankingFilters from 'components/_/app/ranking/RankingFilters';

import useFetch from 'components/_/hooks/useFetch';

import Loader from 'components/commons/_/loader/Loader';

const RankingList = ({ match, ...props }) => {
	const i18nKey = 'ranking';
	const rankingService = props['services.ranking.rankingService'];
	const navigationService = props['services.navigationService'];

	const [selectedContainer, setSelectedContainer] = useState();
	const [searchContainer, setSearchContainer] = useState('');
	const [selectedTeam, setSelectedTeam] = useState();
	const [searchTeam, setSearchTeam] = useState('');

	const {
		loading,
		error,
		response: { ranking, rankings },
	} = useFetch(
		() =>
			rankingService.fetchRankingList().then(rankings => {
				const id = match.params.id || rankings[0]?.id;

				navigationService.goTo(`/ranking/list/${id}`);

				return { ranking: find(rankings, { id }), rankings };
			}),
		{ ranking: null, rankings: [] },
		[match.params.id]
	);

	useEffect(() => {
		setSelectedContainer();
		setSearchContainer('');
		setSelectedTeam();
		setSearchTeam('');
	}, [match.params.id]);

	return (
		<View
			name="ranking"
			i18nKey="ranking"
			config={{
				search: false,
			}}
			tabs={{
				url: '/ranking/list/${tab}', // eslint-disable-line no-template-curly-in-string
				list: rankings.map(tab => ({
					tab: tab.id,
					content: tab.name,
				})),
			}}
			headerBlock={
				<RankingFilters
					ranking={ranking}
					selectedContainer={selectedContainer}
					setSelectedContainer={setSelectedContainer}
					selectedTeam={selectedTeam}
					setSelectedTeam={setSelectedTeam}
					searchContainer={searchContainer}
					setSearchContainer={setSearchContainer}
					searchTeam={searchTeam}
					setSearchTeam={setSearchTeam}
				/>
			}
		>
			<Fragment>
				<hr className="d-none d-sm-block mb-4 mb-sm-5" />
				<div className="ranking-view">
					<div className="d-flex flex-column flex-sm-row fs-4" key={match.params.id}>
						<div className="ranking-view__information px-3 py-4 py-sm-0 pr-sm-4">
							{!loading && (
								<div>
									{ranking
										? ranking.image && (
												<img
													src={ranking.image}
													alt={ranking.image}
													className="rounded mb-5 d-none d-sm-block"
												/>
										  )
										: null}
									{ranking ? <p className="fs-5 fs-sm-6">{ranking.name}</p> : null}
									{ranking
										? ranking.description && (
												<p className="mt-3">
													<Markdown>{ranking.description}</Markdown>
												</p>
										  )
										: null}
									{ranking ? <RankingUserPosition ranking={ranking} /> : null}
								</div>
							)}
						</div>
						{loading ? (
							<Loader />
						) : error ? (
							<p className="fs-6 mx-0 mx-sm-3">
								<FormattedMessage id={`${i18nKey}.error.${error}`} />
							</p>
						) : ranking ? (
							<RankingPositionList
								rankingId={ranking.id}
								container={selectedContainer?.value}
								team={selectedTeam?.value}
								rankingService={rankingService}
							/>
						) : null}
					</div>
				</div>
			</Fragment>
		</View>
	);
};

export default wire(['services.ranking.rankingService', 'services.navigationService'], RankingList);
