import Currencies from 'views/commons/currencies/Currencies';
import common from 'views/commons/currencies/common';
import degression from 'views/commons/currencies/degression';

export default {
	'components.Currencies': {
		factory: Currencies,
		deps: [{ $ref: 'components.Currency' }],
	},
	...common,
	...degression,
};
