import React from 'react';
import { Form, Field, ErrorMessage } from 'formik';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import CreateSubmission from '../../Create.template';

import { WysiwygEditor } from 'components/_/form/wysiwyg/Wysiwyg.tsx';

export default ({ intl, challenge, activateModal, isValid, isSubmitting, setFieldValue, closeModal }) => (
	<CreateSubmission
		closeModal={closeModal}
		title={intl.formatMessage({
			id: 'create-submission.text-submission.title',
		})}
	>
		<div className="create-text-submission text-center">
			<Form className="form p-0 p-sm-3">
				<Field name="title">
					{({ field }) => (
						<input
							{...field}
							className="w-100"
							disabled={isSubmitting}
							placeholder={intl.formatMessage({
								id: 'create-submission.text-submission.form.title.placeholder',
							})}
						/>
					)}
				</Field>
				<ErrorMessage name="title">
					{({ id, ...values }) => (
						<div className="error">
							<FormattedMessage id={`create-submission.form.error.${id}`} values={values} />
						</div>
					)}
				</ErrorMessage>
				<WysiwygEditor
					onChange={value => setFieldValue('text', value)}
					disabled={isSubmitting}
					className="mt-3"
					placeholder={intl.formatMessage({
						id: 'create-submission.text-submission.form.text.placeholder',
					})}
				/>
				<ErrorMessage name="text">
					{({ id, ...values }) => (
						<div className="error">
							<FormattedMessage id={`create-submission.form.error.${id}`} values={values} />
						</div>
					)}
				</ErrorMessage>
				<button
					type="submit"
					disabled={isSubmitting}
					className={classnames('btn__basic mt-2 mt-sm-4', {
						'btn__basic--disabled': !isValid,
						loading: isSubmitting,
					})}
				>
					<FormattedMessage id="create-submission.text-submission.form.button" />
				</button>
				{challenge.submissionTypes().length > 1 && (
					<p className="return mt-4">
						{intl.formatMessage({
							id: 'create-submission.return.or',
						})}
						<span onClick={() => activateModal('selection')}>
							{intl.formatMessage({
								id: 'create-submission.return.link',
							})}
						</span>
						{intl.formatMessage({
							id: 'create-submission.return.rest',
						})}
					</p>
				)}
			</Form>
		</div>
	</CreateSubmission>
);
