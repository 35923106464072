import ChallengeState from './ChallengeState';

/**
 * Class representing a creative challenge state.
 */
export default class KPIChallengeState extends ChallengeState {
	constructor(state) {
		super(state);

		/**
		 * @type {number}
		 */
		this._submissionCount = (state.submission_count || 0);
		/**
		 * @type {string}
		 */
		this._cooldownEnd = state.cooldown_end;
	}

	/**
	 * @returns {boolean}
	 */
	didNotParticipateYet() {
		return this._submissionCount === 0;
	}

	/**
	 * @returns {number}
	 */
	submissionCount() {
		return this._submissionCount;
	}

	/**
	 * @returns {string}
	 */
	cooldownEnd() {
		return this._cooldownEnd;
	}
}
