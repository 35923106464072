import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Field as FormikField } from 'formik';

import { SettingFormFieldProps } from '../SettingField';
import { ErrorMessage } from '../../error/Error';

export const SettingTextAreaField = ({
	label,
	valueType,
	value,
	editable,
	editMode,
	saving,
	onClick,
	inForm,
	...props
}: SettingFormFieldProps) => {
	const Field = inForm ? FormikField : 'textarea';
	const fieldProps = props;

	if (inForm) {
		fieldProps.as = 'textarea';
	}

	return !value && !editable ? null : (
		<div
			className={classnames('settingField settingField--textarea', {
				editMode,
				editable,
			})}
			onClick={onClick}
		>
			<div className="settingField__container p-3 text-left">
				{label && <h6>{label}</h6>}
				<div className="d-flex justify-content-between">
					{editMode ? (
						<Field
							{...fieldProps}
							onKeyDown={() => {}} // allow "Enter" key in textarea
							className="border-0 p-0 w-100 p-0 m-0 rounded-0"
							value={value}
							autoFocus
							disabled={saving}
							spellCheck={false}
						/>
					) : (
						<p className="settingField__value">
							{value ? value : <FormattedMessage id="component.form.setting-field.click-here-to-set" />}
						</p>
					)}
					{editable && (
						<div className="ml-3">
							{saving ? <i className="fa fa-cog fa-spin" /> : <i className="fa fa-pencil" />}
						</div>
					)}
				</div>
			</div>
			<ErrorMessage name={fieldProps.name} inForm={inForm} />
		</div>
	);
};
