import React from 'react';

import images from 'assets/images';

import SVGImage from 'components/commons/SVGImage';
import RankingEntry from 'components/ranking/_/entry/Entry';

const Team = ({ ranking, position, entry }) => {
	const currency = ranking.currency && (
		<SVGImage src={ranking.currency.image || images.currency[ranking.currency.codeName]} className="pr-2 icon" />
	);

	return (
		<RankingEntry
			ranking={ranking}
			currency={currency}
			position={position}
			entry={{
				...entry,
				url: `/team/${entry.team.id()}/members`,
				avatar: entry.team.avatar() || images.default.avatar.team,
			}}
			name={entry.team.name()}
		/>
	);
};

export default Team;
