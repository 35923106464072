import React from 'react';
import Autocomplete from 'react-autocomplete';

export default function(BaseChooseContainer) {
	return class ChooseContainer extends BaseChooseContainer {
		render() {
			return (
				<label className="autocomplete">
					<Autocomplete
						{...this._autoCompleteProperties()}
						renderMenu={children => (
							<div className="menu">
								<ul className="menu__scrollContainer">{children}</ul>
							</div>
						)}
						renderItem={(item, isHighlighted) => (
							<li
								className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
								style={{
									fontWeight: isHighlighted ? 'bold' : 'normal',
								}}
								key={item.id}
							>
								{item.name}
							</li>
						)}
						renderInput={props => (
							<div>
								<input
									{...props}
									type="text"
									style={{ textAlign: 'left' }}
									placeholder={this.props.placeholder}
									disabled={this.props.disabled}
								/>
								<div className="arrow">
									<i className="fas fa-search visible--mobile"></i>
								</div>
							</div>
						)}
					/>
				</label>
			);
		}
	};
}
