import Action, { ActionDTO } from './Action';

export interface AssignRoleActionDTO extends ActionDTO {
	process_id: string;
	role_id: string;
	role_name: string;
}

export default class AssignRoleAction extends Action {
	private _roleId: string;
	private _roleName: string;

	constructor(actionDTO: AssignRoleActionDTO) {
		super(actionDTO);

		this._roleId = actionDTO.role_id;
		this._roleName = actionDTO.role_name;
	}

	get roleId() {
		return this._roleId;
	}

	get roleName() {
		return this._roleName;
	}
}

export const isAssignRoleAction = (action: unknown): action is AssignRoleAction => {
	return action instanceof AssignRoleAction;
};
