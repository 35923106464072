import { PointRanking } from './PointRanking';

/**
 * Class representing a group point ranking.
 * @extends PointRanking
 * @abstract
 */
export class GroupPointRanking extends PointRanking {
	constructor(ranking) {
		super(ranking);

		/**
		 * @type {boolean}
		 */
		this.average = Boolean(ranking.average);
	}
}
