import React from 'react';
import groupBy from 'lodash/groupBy';
import Process from 'models/onboarding/process/Process';
import ProcessList from 'components/employeePortal/process/ProcessList';

export type EmployeeProgressProps = {
	processList: Process[];
};

export const EmployeeProgress = ({ processList }: EmployeeProgressProps) => {
	const employees = groupBy(processList, process => process.userId);

	return (
		<div>
			{Object.entries(employees).map(([key, employeeProcesses]) => (
				<ProcessList
					key={key}
					className="processList--employees mb-2"
					processList={employeeProcesses}
					overdueTranslation="portal.process.title.overdue-for-manager"
					showUserData
				/>
			))}
		</div>
	);
};

export default EmployeeProgress;
