import React from 'react';
import { Field } from 'formik';

import useStyles from 'components/task/types/form/formBlock/question/Question.jss';

import { ErrorMessage } from 'components/_/form/error/Error';

import { DatePicker } from 'components/_/form/date-picker/DatePicker';

export const DateOpenQuestion = ({ formBlock, disabled }) => {
	const classes = useStyles();

	return (
		<div className="fs-4">
			<p className={classes.question}>{formBlock.body()}</p>
			<div className="w-100 w-sm-50">
				<Field name={formBlock.id()}>
					{({ field, form }) => (
						<DatePicker
							format={formBlock.answerFormat()}
							{...field}
							onChange={value => form.setFieldValue(formBlock.id(), value)}
							rounded
							disabled={disabled}
						/>
					)}
				</Field>
			</div>
			<ErrorMessage name={formBlock.id()} />
		</div>
	);
};
