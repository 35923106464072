import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createInstance } from 'react-async-action';
import queryString from 'query-string';
import _ from 'lodash';

import Loader from 'components/commons/_/loader/Loader';

export default function(applicationService, colorService, LandingPage) {
	const LandingPageConfig = createInstance({
		action: () => applicationService.fetchLandingPage(),
	});

	return class LandingPageContainer extends Component {
		state = {
			render: false,
		};

		render() {
			const search = queryString.parse(window.location.search);

			if (_.has(search, 'disableLandingPage')) {
				return this.props.children;
			}

			return (
				<LandingPageConfig>
					<LandingPageConfig.Loading>
						<Loader />
					</LandingPageConfig.Loading>
					<LandingPageConfig.Resolved>
						{({ response: config }) =>
							this.state.render || !config ? (
								this.props.children
							) : (
								<LandingPage
									config={{
										...config,
										textStyles: {
											color: config.colorScheme.content.foreground,
											textShadow:
												!!config.backgroundImage &&
												`1px 1px 4px ${colorService.reverseColor(
													config.colorScheme.content.foreground
												)}`,
										},
									}}
									allowRender={() => this._allowRender()}
									style={this._style(config)}
								/>
							)
						}
					</LandingPageConfig.Resolved>
				</LandingPageConfig>
			);
		}

		_allowRender() {
			this.setState({
				render: true,
			});
		}

		_style(config) {
			const style = {
				color: config.colorScheme.content.foreground,
			};

			if (config.backgroundImage) {
				style.backgroundImage = `url(${config.backgroundImage})`;
			} else {
				style.backgroundColor = config.colorScheme.content.background;
			}

			return style;
		}

		static propTypes = {
			children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
		};
	};
}
