import DegressionCurrencies from 'views/commons/currencies/degression/Currencies';
import DegressionCurrency from 'views/commons/currencies/degression/Currency';

export default {
	'components.DegressionCurrencies': {
		factory: DegressionCurrencies,
		deps: [{ $ref: 'components.DegressionCurrency' }],
	},
	'components.DegressionCurrency': {
		factory: DegressionCurrency,
	},
};
