import TabBase from 'components/commons/tabs/TabBase';
import tabFactory from 'components/commons/tabs/tabFactory';
import TabManager from 'components/commons/tabs/TabManager';
import tabPanelFactory from 'components/commons/tabs/tabPanelFactory';

export default {
	'components.tabs.TabBase': {
		factory: TabBase,
	},
	'components.tabs.tabFactory': {
		factory: tabFactory,
		deps: [{ $ref: 'components.tabs.TabBase' }],
	},
	'components.tabs.TabManager': {
		factory: TabManager,
		deps: [{ $ref: 'components.tabs.tabFactory' }, { $ref: 'components.tabs.tabPanelFactory' }],
	},
	'components.tabs.tabPanelFactory': {
		factory: tabPanelFactory,
		deps: [{ $ref: 'components.tabs.TabBase' }],
	},
};
