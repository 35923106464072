import { ContainerGroup, ContainerGroupDTO, ContainerGroupInterface, ContainerGroupType } from './ContainerGroup';

export default class TeamContainerGroup extends ContainerGroup implements TeamContainerGroupInterface {
	public containerGroupType: ContainerGroupType.TeamContainerGroup = ContainerGroupType.TeamContainerGroup;
}

export interface TeamContainerGroupInterface extends ContainerGroupInterface {
	containerGroupType: ContainerGroupType.TeamContainerGroup;
}

export interface TeamContainerGroupDTO extends ContainerGroupDTO {
	container_group_type: ContainerGroupType.TeamContainerGroup;
}
