import React from 'react';
import classnames from 'classnames';

import images from 'assets/images';

import RankingEntry from 'components/ranking/_/entry/Entry';

const Submission = ({ ranking, position, entry }) => {
	const icon = ranking.rankBy === 'likes' ? entry.currency : ranking.rankBy === 'score' ? 'star' : null;

	const currency = (
		<i
			className={classnames('fa mr-2', {
				[`fa-${icon}`]: true,
			})}
		/>
	);

	return (
		<RankingEntry
			ranking={ranking}
			currency={currency}
			position={position}
			entry={{
				...entry,
				url: `/challenge/submission/${entry.submission.id()}/details`,
				avatar: entry.submission.author().avatar() || images.default.avatar.user,
			}}
			name={entry.submission.title()}
			shortName={entry.submission.author().fullName()}
		/>
	);
};

export default Submission;
