import React from 'react';
import classnames from 'classnames';

export default ({
	answer,
	component,
	component: {
		props: { task },
	},
}) => (
	<li
		className={classnames('answer answer__imageType wrapper--row', {
			correct: task.isCompleted(),
			incorrect: task.isFailed(),
		})}
	>
		<label className="wrapper--row el--grow">
			<input
				type="radio"
				checked={answer.isChecked()}
				className="wrapper--row el--grow technicalInput"
				name="answer"
				onClick={() => component.click(answer)}
			/>
			<div className="imageWrapper">
				<div className="image image--contain" style={{ backgroundImage: `url(${answer.image()}?w=512)` }} />
			</div>
			<div className="answer__selectArea answer__selectArea--imageType wrapper--row align--center justify--center el--shrink">
				<div className="iterator">{answer.index()}</div>
				<div className="customInput customInput--radio">
					<div className="checked" />
				</div>
			</div>
		</label>
	</li>
);
