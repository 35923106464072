import React, { Fragment, PureComponent } from 'react';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import { wire } from 'react-hot-wire';
import _ from 'lodash';

import Item from './Item.template';

import { Button } from 'components/_/common/button/Button';
import { Currency } from 'components/_/common/currency/Currency';
import { ProgressBar } from 'components/_/common/progress-bar/ProgressBar';

export class StoreItemComponent extends PureComponent {
	constructor(...args) {
		super(...args);

		const { item } = this.props;

		this._wallet = this.props['services.userProfileService'].user().wallet;
		this._availability =
			item.currentAvailabilityWithStock() || item.nearestAvailabilityWithStock() || item.nearestAvailability();
	}

	render() {
		const { item } = this.props;

		return (
			<Item
				item={item}
				renderAvailability={this._renderAvailability()}
				renderButton={this._renderButton()}
				renderPrice={this._renderPrice()}
			/>
		);
	}

	_renderAvailability() {
		const availability = this._availability;
		let availabilityRender = null;

		if (
			availability &&
			availability.isNow() &&
			!availability.currentRemainingQuantity() <= 0 &&
			availability.inBudget()
		) {
			availabilityRender = (
				<div className="d-flex justify-content-between py-2">
					<div className="mr-2">
						<FormattedMessage id="prize-list.content.amount" />
					</div>
					<div>{this._availabilityInfo()}</div>
				</div>
			);
		} else if (!availability || availability.futureRemainingQuantity() <= 0 || !availability.inBudget()) {
			availabilityRender = (
				<div className="d-flex justify-content-between py-2">
					<div className="mr-2">
						<FormattedMessage id="prize-list.content.sold-out" />
					</div>
				</div>
			);
		} else if (availability.isFuture()) {
			availabilityRender = (
				<Fragment>
					<div className="d-flex justify-content-between py-2">
						<div className="mr-2">
							<FormattedMessage id="prize-list.content.from-date" />
						</div>
						<div>
							<strong>
								{/*<FormattedRelative value={availability.from().toDate()} />*/}
								<FormattedDate value={availability.from().toDate()} />
								{', '}
								<FormattedTime value={availability.from().toDate()} />
							</strong>
						</div>
					</div>
					{availability.futureRemainingQuantity() > 0 && (
						<div className="d-flex justify-content-between py-2">
							<div className="mr-2">
								<FormattedMessage id="prize-list.content.amount" />
							</div>
							<div>
								<strong>{availability.futureRemainingQuantity()}</strong>
							</div>
						</div>
					)}
				</Fragment>
			);
		}

		if (
			availability.isNow() &&
			availability.currentRemainingQuantity() > 0 &&
			availability.inBudget() &&
			!this._enoughPoints()
		) {
			availabilityRender = (
				<Fragment>
					{availabilityRender}
					{this._renderMissingCurrencies()}
				</Fragment>
			);
		} else {
			availabilityRender = (
				<Fragment>
					{availabilityRender}
					<div className="d-flex justify-content-between py-2">
						<div className="mr-2">
							<FormattedMessage id="prize-list.content.price" />
						</div>
						{this._renderPrice()}
					</div>
				</Fragment>
			);
		}

		return availabilityRender;
	}

	_renderPrice() {
		const availability = this._availability;

		return (
			<div className="d-flex flex-wrap">
				{availability
					.price()
					.values()
					.map(currency => (
						<Currency
							key={currency.codeName()}
							className="mx-1"
							currency={currency.codeName()}
							value={currency.value()}
						/>
					))}
			</div>
		);
	}

	_renderButton() {
		const availability = this._availability;
		const canBuy = availability && availability.canBuy();

		return (
			<Button className="fs-5" color={canBuy ? 'interactive' : 'disabled'}>
				<FormattedMessage id={`prize-list.button.${availability.isNow() ? 'buy-now' : 'soon'}`} />
			</Button>
		);
	}

	_availabilityInfo() {
		const currentQuantities = this.props.item.currentQuantities();

		return (
			currentQuantities.start && (
				<FormattedMessage
					id="prize-list.content.count"
					values={{
						remaining: <strong>{currentQuantities.remaining}</strong>,
						start: <strong>{currentQuantities.start}</strong>,
					}}
				/>
			)
		);
	}

	_renderMissingCurrencies() {
		return (
			this._wallet &&
			_.map(this._missingCurrencies(), missingCurrency => (
				<div key={missingCurrency.currency.codeName()} className="my-2 d-flex align-items-center">
					<ProgressBar progress={missingCurrency.progress * 100} className="flex-grow-1 mr-2" />
					<Currency currency={missingCurrency.currency.codeName()} value={missingCurrency.value} />
				</div>
			))
		);
	}

	//TODO: do not duplicate!
	_missingCurrencies() {
		const availability = this._availability;
		const missingCurrencies = [];

		_.forEach(availability.price().values(), currency => {
			const name = currency.codeName();
			const value = currency.value();
			const walletValue = this._wallet.getCurrencyByName(name).value();

			if (value > walletValue && value !== 0) {
				missingCurrencies.push({
					currency,
					value: value - walletValue,
					progress: walletValue / value,
				});
			}
		});

		return missingCurrencies;
	}

	//TODO: do not duplicate!
	_enoughPoints() {
		const availability = this._availability;

		return _.every(availability.price().values(), currencyEntry => {
			const walletValue = this._wallet.getCurrencyByName(currencyEntry.codeName()).value();

			return currencyEntry.value() <= Math.max(walletValue, 0);
		});
	}
}

export default wire(['services.userProfileService'], StoreItemComponent);
