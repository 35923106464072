import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

export default ({ integration: { authorizeButtonText, autoRedirect }, login, action }) => {
	useEffect(() => {
		if (autoRedirect) {
			login();
		}
	}, []);

	return autoRedirect ? (
		<h2>
			<FormattedMessage id="auth.login.integration.oauth.loading" />
		</h2>
	) : (
		<div className="integration integration__oauth--generic">
			<button onClick={login}>
				{authorizeButtonText ? (
					authorizeButtonText
				) : (
					<FormattedMessage id={`auth.${action}.integration.oauth.generic`} tagName={React.Fragment} />
				)}
			</button>
		</div>
	);
};
