import Employee, { EmployeeDTO } from '../employee/Employee';

export interface ContactDTO extends EmployeeDTO {
	role: string[];
}

export default class Contact extends Employee {
	private _role: string[];

	constructor(contact: ContactDTO) {
		super(contact);
		this._role = contact.role;
	}

	get role() {
		return this._role;
	}
}
