export default class AccessTokenService {
	constructor(sessionStorage, LocalStorageService) {
		this._sessionStorage = sessionStorage;
		this._localStorageService = {
			admin: new LocalStorageService({ key: 'panelAccessToken' }),
			application: new LocalStorageService({ key: 'applicationAccessToken' }),
		};
		this._impersonateUserId = this._sessionStorage.getItem('impersonateUserId');
		this._impersonateUserProfileId = this._sessionStorage.getItem('impersonateUserProfileId');
	}

	accessToken(type) {
		return this._localStorageService[type].value();
	}

	setAccessToken(type, accessToken) {
		this._localStorageService[type].setValue(accessToken);
	}

	setImpersonateUserId(impersonateUserId) {
		if (impersonateUserId) {
			this._sessionStorage.setItem('impersonateUserId', impersonateUserId);
		} else {
			this._sessionStorage.removeItem('impersonateUserId');
		}

		this._impersonateUserId = impersonateUserId;
	}

	setImpersonateUserProfileId(impersonateUserProfileId) {
		if (impersonateUserProfileId) {
			this._sessionStorage.setItem('impersonateUserProfileId', impersonateUserProfileId);
		} else {
			this._sessionStorage.removeItem('impersonateUserProfileId');
		}

		this._impersonateUserProfileId = impersonateUserProfileId;
	}

	impersonateUserId() {
		return this._impersonateUserId;
	}

	impersonateUserProfileId() {
		return this._impersonateUserProfileId;
	}

	addChangeListener(listener) {
		this._localStorageService.admin.addChangeListener(listener);
		this._localStorageService.application.addChangeListener(listener);
	}

	clear() {
		this.setAccessToken('application', null);
		this.setImpersonateUserId(null);
		this.setImpersonateUserProfileId(null);
	}
}
