import OpenQuestion from 'models/common/form/question/open/OpenQuestion';

export default class DateOpenQuestion extends OpenQuestion {
	constructor(question) {
		super(question);

		this._answerFormat = question.answer_format;
	}

	answerFormat() {
		return this._answerFormat;
	}
}
