import ChallengeImageSubmissionService from 'services/challenge/submission/subServices/ChallengeImageSubmissionService';
import ChallengeTextSubmissionService from 'services/challenge/submission/subServices/ChallengeTextSubmissionService';

export default {
	'services.challengeImageSubmissionService': {
		class: ChallengeImageSubmissionService,
		deps: [{ $ref: 'services.challengeService' }, { $ref: 'services.userProfileService' }],
	},
	'services.challengeTextSubmissionService': {
		class: ChallengeTextSubmissionService,
		deps: [{ $ref: 'services.challengeService' }, { $ref: 'services.userProfileService' }],
	},
};
