import { createUseStyles } from 'react-jss';

/**
 * @TODO:
 *   1. remove important! after use this component everywhere
 *   2. remove styles from _form.scss
 */
export default createUseStyles(theme => {
	const textSize = 1;
	const borderSize = 0.0625; // 1 / 16
	const verticalPaddings = 0.3;
	const size = textSize + verticalPaddings * 2 + borderSize * 2; // text + (py * 2) + (border * 2)

	return {
		label: {},
		input: {
			height: `${size}em !important`,
			maxHeight: `${size}em !important`,
			outline: 0,
		},
		textarea: {
			display: 'block !important',
			lineHeight: '1.2 !important',
			height: '150px !important',
			outline: 0,
		},
		field: {
			backgroundColor: `${theme.color.system['color--content--foreground-3']} !important`,
			color: `${theme.color.system['color--content--foreground-60']} !important`,
			border: `${borderSize}em solid ${theme.color.system['color--content--foreground-20']} !important`,
			maxWidth: '100%',
			// GLOBAL OVERRIDES:
			width: '100% !important',
			marginBottom: 'auto !important',
			borderRadius: `0.6em !important`,

			fontSize: `${textSize}em !important`,
			minHeight: `${size}em !important`,
			padding: `${verticalPaddings}em 0.5em !important`,

			'&:focus, &.active': {
				backgroundColor: `${theme.color.system['color--content--foreground-5']} !important`,
				color: `${theme.color.system['color--interactive--background']} !important`,
				borderColor: `${theme.color.system['color--interactive--background']} !important`,
			},

			'&[disabled]': {
				backgroundColor: `${theme.color.system['color--content--foreground-20']} !important`,
				color: `${theme.color.system['color--content--foreground-60']} !important`,
			},

			'&[size]': {
				width: 'auto !important',
			},

			'&::placeholder': {
				color: `${theme.color.system['color--content--foreground-30']} !important`,
			},
		},
		rounded: {
			borderRadius: `${textSize}em !important`,
		},
	};
});
