import ImageMultipleChoiceQuestionTaskService from 'services/task/subServices/questionTask/choice/image/ImageMultipleChoiceQuestionTaskService';
import ImageSingleChoiceQuestionTaskService from 'services/task/subServices/questionTask/choice/image/ImageSingleChoiceQuestionTaskService';

export default {
	'services.imageMultipleChoiceQuestionTaskService': {
		class: ImageMultipleChoiceQuestionTaskService,
		deps: [{ $ref: 'services.taskStateService' }],
	},
	'services.imageSingleChoiceQuestionTaskService': {
		class: ImageSingleChoiceQuestionTaskService,
		deps: [{ $ref: 'services.taskStateService' }],
	},
};
