import axios from 'axios';
import { each } from 'lodash';

export default class MetadataConfigurationService {
	constructor(document, applicationService, platformService) {
		this._document = document;
		this._applicationService = applicationService;
		this._platformService = platformService;
	}

	init() {
		const headMetadata = this._applicationService.settings().headMetadata();
		this._document.title = headMetadata.title();

		this._updateFavicon(headMetadata.favicon());
		this._iOS();
	}

	_updateFavicon(favicon) {
		let head = this._document.head;
		let oldLink = this._document.querySelector('link[rel="icon"]');
		let link = this._document.createElement('link');

		link.rel = 'icon';
		link.href = favicon;

		if (oldLink) {
			head.removeChild(oldLink);
		}

		head.appendChild(link);
	}

	_iOS() {
		if (!this._platformService.isIOS()) {
			return;
		}

		axios.get('/api/app.php/v1/metadata/webapp-manifest').then(({ data: manifest }) => {
			// configuration: https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/ConfiguringWebApplications/ConfiguringWebApplications.html
			const tags = [
				{
					tag: 'link',
					rel: 'apple-touch-icon',
					href: manifest.icons[0].src,
				},
			];

			each(tags, ({ tag, ...rest }) => {
				let $tag = this._document.createElement(tag);

				each(rest, (value, name) => {
					$tag.setAttribute(name, value);
				});

				this._document.head.appendChild($tag);
			});
		});
	}
}
