import React from 'react';
import { FormattedMessage } from 'react-intl';

import images from 'assets/images';

import { Markdown } from 'components/_/common/text/markdown/Markdown';

export default function(BaseNewsDetails, Image) {
	return class NewsDetails extends BaseNewsDetails {
		view({ response }) {
			return (
				<div className="wrapper--col el--grow">
					<div className="view newsDetails wrapper--col">
						<div className="viewTitleBox">
							<div className="viewTitleBox__titleSection wrapper--row justify--between">
								<h1 className="pb-2">{response.title()}</h1>
							</div>
							<div className="viewTitleBox__descriptionSection wrapper--row align--end">
								<div className="subtitle">
									<FormattedMessage id="news-details.subtitle" />
									<span>{response.createdAt()}</span>
								</div>
							</div>
						</div>
						<div className="wrapper--row mainContentWrapper wrapper--col overflow--auto">
							<div className="newsDetails__contentWrapper wrapper--row el--grow">
								<div className="wrapper--col textContentWrapper" style={{ fontSize: '1rem' }}>
									<Markdown>{response.body()}</Markdown>
									<div className="commentsSection el--shrink hidden">
										<div className="sectionTitle">
											<FormattedMessage id="news-details.section-title.comments" />
										</div>
										<div className="commentsWrapper">
											<div className="comment wrapper--row">
												<div
													className="comment__avatar image image--cover el--shrink"
													style={{
														backgroundImage: `url(${images.default.avatar.user})`,
													}}
												/>
												<div className="comment__commentBox el--grow">
													<div className="wrapper--row justify--between el--grow">
														<div className="wrapper--row align--center">
															<div className="author">Anna Nowak</div>
															<div>napisał(a):</div>
														</div>
														<div className="date">Poniedziałek, 01.08.2017, 15:45</div>
													</div>
													<div className="textContent">
														Lorem ipsum dolor sit amet consectetur adipiscing trololo
														trlololo. Lorem ipsum dolor sit amet consectetur adipiscing
														trololo trlololo.Lorem ipsum dolor sit amet consectetur
														adipiscing trololo trlololo.Lorem ipsum dolor sit amet
														consectetur adipiscing trololo trlololo.Lorem ipsum dolor sit
														amet consectetur adipiscing trololo trlololo.Lorem ipsum dolor
														sit amet consectetur adipiscing trololo trlololo.Lorem ipsum
														dolor sit amet consectetur adipiscing trololo trlololo.
													</div>
													<button className="btn__basic btn__basic--contentFit">
														<span>Odpowiedz</span>
													</button>
												</div>
											</div>
										</div>
										<div className="sectionTitle">Dodaj komentarz</div>
										<div className="inputSection wrapper--col">
											<textarea />
											<div className="wrapper--row justify--end align--center">
												<div className="remainingSigns">
													<span>Pozostało znaków:</span> 123
												</div>
												<button className="btn__basic btn__basic--contentFit">
													<span>Dodaj komentarz</span>
												</button>
											</div>
										</div>
									</div>
								</div>
								<div
									className="imageContainer image image--contain wrapper--col align--center"
									style={{
										backgroundImage: `url(${response.listImage()}?w=1024)`,
									}}
								>
									<Image src={response.listImage()} className="visible--mobile" />
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	};
}
