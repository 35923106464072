import _ from 'lodash';

import TaskState from 'models/task/state/TaskState';
import TaskRepetitionState from 'models/task/repetitionState/TaskRepetitionState';

export default class TaskStateService {
	constructor(currencyService, taskService, userService) {
		this._currencyService = currencyService;
		this._taskService = taskService;
		this._userService = userService;
	}

	mapState(state = {}, State = TaskState, RepetitionState = TaskRepetitionState) {
		const { current_repetition_state, repetition_states, task, player, ...rest } = state;
		const mapRepetitionState = this.mapRepetitionState.bind(this, State, RepetitionState);

		return new State({
			...rest,
			current_repetition_state:
				current_repetition_state &&
				mapRepetitionState(current_repetition_state.state, current_repetition_state),
			repetition_states: _.map(repetition_states || [], repetitionState =>
				mapRepetitionState(repetitionState.state, repetitionState)
			),
			task: task && this._taskService.toModel(task),
			player: player && this._userService.toModel(player),
		});
	}

	mapRepetitionState(State, RepetitionState, state, repetitionState) {
		return new RepetitionState({
			...repetitionState,
			earned: repetitionState.earned && this._currencyService.toModel(repetitionState.earned),
			task_state: state && this.mapState(state, State, RepetitionState),
		});
	}
}
