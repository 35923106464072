import { Children, cloneElement } from 'react';
import when from 'when';

export default function tabPanelFactory(TabBase) {
	return function(tabManager) {
		return class TabPanel extends TabBase {
			componentDidMount() {
				tabManager.registerName(this.props.name);
				tabManager.register('panel', this);
			}

			render() {
				if (!this.state.active) {
					return null;
				}

				let tab = Children.only(this.props.children);

				return cloneElement(tab, {
					close: (confirmation, delay = 0, reload = false) =>
						when()
							.delay(delay)
							.then(() => {
								if (reload) {
									window.location.reload();
								} else {
									tabManager.close(this.props.name, confirmation);
								}
							}),
					activate: (name, data) => tabManager.activate(name, data),
					manager: name => tabManager.manager(name),
					next: () => tabManager.activate(tabManager._siblingName()),
					previous: () => tabManager.activate(tabManager._siblingName(false)),
					hasNoSibling: next => tabManager._hasNoSibling(next),
					...this.state.data,
				});
			}
		};
	};
}
