import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { Markdown } from 'components/_/common/text/markdown/Markdown';

export default (ReactMarkdown, Currencies, MatchingImageType) => {
	function challengeDetails({ intl, challenge }) {
		return (
			<section className="wrapper--row challengeDetails el--grow">
				{challenge.image() && (
					<div className="challengeDetails__mainImage wrapper--col overflow--hidden pt-2 pb-4">
						<MatchingImageType classes="el--grow rounded el--shrink" src={challenge.image()} />
					</div>
				)}
				<div className="challengeDetails__descriptionSection el--shrink el--grow">
					{challenge.description() && <Markdown>{challenge.description()}</Markdown>}
					<div className="sectionTitle hidden--mobile pb-3">
						<FormattedMessage id="challenge-details.creative.section-title.challenge-info" />
					</div>
					<div className="challengeDetails__details pb-4">
						<div className="challengeDetails__detailsRow wrapper--row justify--between align--center">
							<FormattedMessage id="challenge-details.target.content.challenge-info.challenge-type" />
							<FormattedMessage id="challenge-details.target.content.challenge-info.creative-type" />
						</div>
						<div className="challengeDetails__detailsRow wrapper--row justify--between align--center">
							<FormattedMessage id="challenge-details.target.content.challenge-info.participation-mode" />
							<FormattedMessage id={`challenge-list.type.${challenge.participationMode()}`} />
						</div>
						{challenge.state() && (
							<div className="challengeDetails__detailsRow wrapper--row justify--between align--center">
								<FormattedMessage id="challenge-details.target.content.challenge-info.submission-count" />
								<div>{challenge.state().submissionCount()}</div>
							</div>
						)}
						{challenge.isSubmissionLimit() && (
							<div className="challengeDetails__detailsRow wrapper--row justify--between align--center">
								<FormattedMessage id="challenge-details.target.content.challenge-info.submissions-limit" />
								<div>{challenge.submissionLimit()}</div>
							</div>
						)}
						{challenge.submissionEarnings().hasValues() && (
							<div className="challengeDetails__detailsRow wrapper--row justify--between align--center">
								<FormattedMessage id="challenge-details.target.content.challenge-info.points-for-accepted-submission" />
								<Currencies
									parentClasses="wrapper--row align--center tests-itemPrice currencies"
									currencies={challenge.submissionEarnings()}
								/>
							</div>
						)}
					</div>
				</div>
			</section>
		);
	}

	challengeDetails.propTypes = {
		challenge: PropTypes.object,
	};

	return injectIntl(challengeDetails);
};
