import QuestionTask from 'models/task/question/questionTask/QuestionTask';
import OrderQuestion from 'models/task/question/OrderQuestion';

/**
 * Class representing a order question task.
 * @extends QuestionTask
 */
export default class OrderQuestionTask extends QuestionTask {
	/**
	 * @param {Object} task
	 */
	constructor(task) {
		super(task);

		/**
		 * @type {OrderQuestion}
		 */
		this._question = new OrderQuestion(task.question);
	}
}
