import AttributesContentBlockService from 'services/common/contentBlocks/blocks/AttributesContentBlockService';
import ButtonContentBlockService from 'services/common/contentBlocks/blocks/ButtonContentBlockService';
import HeaderContentBlockService from 'services/common/contentBlocks/blocks/HeaderContentBlockService';
import ImageContentBlockService from 'services/common/contentBlocks/blocks/ImageContentBlockService';
import TextContentBlockService from 'services/common/contentBlocks/blocks/TextContentBlockService';

export default {
	'services.attributesContentBlockService': {
		class: AttributesContentBlockService,
	},
	'services.buttonContentBlockService': {
		class: ButtonContentBlockService,
	},
	'services.headerContentBlockService': {
		class: HeaderContentBlockService,
	},
	'services.imageContentBlockService': {
		class: ImageContentBlockService,
	},
	'services.textContentBlockService': {
		class: TextContentBlockService,
	},
};
