import React from 'react';
import classnames from 'classnames';
import { useTabsContext } from './TabsContext';

export type TabProps = {
	content?: string | JSX.Element;
	badge?: string | JSX.Element;
};

export type TabListProps = {
	className?: string;
	after?: React.ReactNode;
};

export function TabList({ className, after }: TabListProps) {
	const { tabs, current, setCurrent } = useTabsContext<TabProps>();

	return (
		<div className={classnames('tabs', className)}>
			<ul className="d-flex" onScroll={event => event.stopPropagation()}>
				{tabs
					.filter(item => item)
					.map((item, index) => (
						<li key={index} className="d-flex align-items-stretch mr-1 mr-md-3">
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							<a
								className={classnames('px-3 py-2 rounded-pill', {
									active: current === item.id,
								})}
								onClick={() => setCurrent(item.id)}
							>
								{item.content}
								{item.badge ? <span className="badge ml-2">{item.badge}</span> : null}
							</a>
						</li>
					))}
			</ul>
			{after}
		</div>
	);
}
