import Notification from 'models/notification/Notification';

/**
 * Class representing a task notification.
 * @extends Notification
 */
export default class TaskNotification extends Notification {
	/**
	 * @param {Object} notification
	 */
	constructor(notification) {
		super(notification);

		this._task = notification.task;
		this._completion = notification.completion;

		this._removed = !this._task;
		this._type = this._task.isMission() ? 'mission' : 'task';
	}

	getMessage() {
		if (this._removed) {
			return super.getMessage('null-states.task');
		}

		return super.getMessage('messages.task', {
			task: this._task,
			name: this._task && this._task.name(),
		});
	}

	getDate(params) {
		if (this._task && this._task.is_mission) {
			return super.getDate('dates.task.mission-completed', params);
		} else if (this._completion) {
			return super.getDate('dates.task.task-completed', params);
		} else {
			return super.getDate('dates.task.task-progress', params);
		}
	}

	getIconPath() {
		return this._task && this._task.icon();
	}

	task() {
		return this._task;
	}
}
