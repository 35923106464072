import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import images from 'assets/images';

import { Button } from 'components/_/common/button/Button';
import { Currency } from 'components/_/common/currency/Currency';
import { ProgressBar } from 'components/_/common/progress-bar/ProgressBar';

function ChallengeListTile({ challenge }) {
	const status = challenge.status().progress ? (
		<div className="d-flex align-items-center">
			<FormattedMessage id="challenge-list.content.reverse-status.inProgress" />
			<ProgressBar className="flex-grow-1 mx-2" progress={challenge.status().progress} />
			{challenge.status().remainingTime}
		</div>
	) : (
		<FormattedMessage
			id={`challenge-list.content.challenge-status.${challenge.status().type}`}
			values={{
				remainingTime: challenge.status().remainingTime,
			}}
		/>
	);

	return (
		<div className="col-24 col-xs-12 col-md-8 col-lg-6 col-xl-4 mb-4 mb-sm-5">
			<div className="mx-0 mx-sm-3 h-100">
				<NavLink to={`/challenge/${challenge.id}/details`}>
					<div className="tile challengeList__tile fs-4 rounded h-100">
						<div className="tile__front d-flex flex-column rounded">
							<div
								className="tile__image rounded-top background-image image--cover"
								style={{ backgroundImage: `url(${challenge.image()})` }}
							/>
							<div className="d-flex flex-column flex-grow-1 justify-content-between px-4 text-center">
								<p className="challenge-name fs-5 py-3 font-weight-bold">{challenge.title()}</p>
								<div className="pb-3">{status}</div>
							</div>
						</div>
						<div className="tile__back d-flex flex-column justify-content-between rounded text-center">
							<div className="d-flex flex-column justify-content-between flex-grow-1 p-4">
								<div className="mb-3">
									<p className="challenge-name fs-5 pb-3 font-weight-bold">{challenge.title()}</p>
									<div className="d-flex align-items-center justify-content-center">
										<img
											className="challenge-participation"
											src={
												images.example.icon.challenge.participationMode[
													challenge.participationMode()
												]
											}
											alt={challenge.participationMode()}
										/>
										{challenge.challengeType === 'creative' && (
											<div className="challenge-submissions pl-3 ml-3">
												{challenge.submissionTypes().map(type => (
													<img
														key={type}
														className="mr-2"
														src={images.example.icon.challenge[type]}
														alt={type}
													/>
												))}
											</div>
										)}
									</div>
								</div>
								<div>
									{status}
									{challenge.challengeType === 'creative' && challenge.hasSubmissionEarnings() && (
										<div className="d-flex justify-content-center mt-3">
											<FormattedMessage id="challenge-list.content.earnings" />
											<div className="d-flex flex-wrap ml-2">
												{challenge
													.submissionEarnings()
													.values()
													.map(currency => (
														<Currency
															key={currency.codeName()}
															className="mr-2"
															currency={currency.codeName()}
															value={currency.value()}
														/>
													))}
											</div>
										</div>
									)}
								</div>
							</div>
							<div className="challenge-button fs-5 py-3 px-4">
								<Button color={challenge.status().type !== 'inProgress' ? 'disabled' : 'interactive'}>
									<FormattedMessage id="challenge-list.button.takePart" />
								</Button>
							</div>
						</div>
					</div>
				</NavLink>
			</div>
		</div>
	);
}

export default ChallengeListTile;
