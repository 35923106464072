import Mission from 'models/task/Mission';

/**
 * Class representing a graph task.
 * @extends Mission
 */
export default class GraphTask extends Mission {
	/**
	 * @param {Object} task
	 */
	constructor(task) {
		super(task);

		/**
		 * @type {string}
		 */
		this._graphId = task.graph_id;
	}

	/**
	 * @returns {string}
	 */
	graphId() {
		return this._graphId;
	}
}
