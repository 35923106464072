import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import imageCropper from 'components/commons/imageCropper';

export default function() {
	return imageCropper(
		class Avatar extends Component {
			render() {
				const { currentImage, defaultImage, editMode } = this.props;

				return (
					<div
						className="profile-avatar rounded-circle mr-2 position-relative overflow-hidden"
						style={{
							backgroundImage: `url(${this.state.avatar.image || `${currentImage || defaultImage}`})`,
						}}
					>
						{editMode && (
							<Fragment>
								<input
									type="file"
									accept="image/*"
									onChange={event => this._save(event)}
									title="Select image"
									className="position-absolute"
								/>
								<button
									className={classnames('position-absolute px-3 py-2 border-0', {
										inProgress: this.state.inProgress,
									})}
								>
									{this.state.inProgress ? (
										<i className="fa fa-cog fa-spin" />
									) : (
										<i className="fa fa-pencil" />
									)}
								</button>
							</Fragment>
						)}
					</div>
				);
			}

			_save(event) {
				this._onImageChanged(event)
					.then(() => this.setBlob())
					.then(() => this.props.onChange(this.state.avatar));
			}

			static propTypes = {
				editMode: PropTypes.bool,
				defaultImage: PropTypes.string,
				currentImage: PropTypes.string,
				onChange: PropTypes.func,
				name: PropTypes.string,
			};
		}
	);
}
