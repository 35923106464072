import { createUseStyles } from 'react-jss';
import get from 'lodash/get';

export default ({
	progress,
	duration,
	progressBarColor = 'system.color--interactive--background',
	border = 0.0625, // 1 / 16
	height = 0.625, // 10 / 16
	innerPadding = 0.1,
}: {
	progress: number;
	duration: string;
	progressBarColor?: string;
	border?: number;
	height?: number;
	innerPadding?: number;
}) =>
	createUseStyles((theme: any) => {
		const progressBarBackgroundColor = get(theme.color, progressBarColor);

		return {
			'@keyframes animation': {
				from: { width: 0 },
				to: { width: `${progress}%` },
			},
			container: {
				backgroundColor: '#FFF',
				border: `${border}em solid ${theme.color.system['color--content--foreground-10']}`,
				borderRadius: `${height}em`,
				padding: `${innerPadding}em`,
			},
			transparent: {
				backgroundColor: 'transparent',
				border: `${border}em solid ${theme.color.system['color--content--background-30']}`,
			},
			progressBar: {
				height: `${height}em`,
				background: progressBarBackgroundColor,
				borderRadius: `${height}em`,
				position: 'relative',
				animation: `$animation ${duration} ease-in-out forwards`,
			},
			tooltip: {
				position: 'absolute',
				right: 0,
				top: '100%',
				transform: `translateX(${progress === 100 ? 0 : 50}%)`,
				marginTop: '0.5em',
				fontWeight: 600,
				fontSize: `${0.75}em`,
			},
			text: {
				marginTop: '0.5em',
				marginRight: '2em',
				fontSize: '0.7em',
			},
		};
	});
