import LandingPageContainer from 'components/application/landingPage/LandingPage';

export default {
	'component.container.LandingPage': {
		factory: LandingPageContainer,
		deps: [
			{ $ref: 'services.applicationService' },
			{ $ref: 'services.colorService' },
			{ $ref: 'component.LandingPage' },
		],
	},
};
