import Action, { ActionDTO } from '../process/action/Action';

export interface KnowledgeBaseDTO extends ActionDTO {
	id: string;
	content_id: string;
	name: string;
}

export default class KnowledgeBase extends Action {
	private _id: string;
	private _contentId: string;
	private _name: string;

	constructor(knowledgeBaseDTO: KnowledgeBaseDTO) {
		super(knowledgeBaseDTO);

		this._id = knowledgeBaseDTO.id;
		this._contentId = knowledgeBaseDTO.content_id;
		this._name = knowledgeBaseDTO.name;
	}

	get id() {
		return this._id;
	}

	get name() {
		return this._name;
	}

	get contentId() {
		return this._contentId;
	}
}
