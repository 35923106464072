import CustomAnswerMode from 'components/task/types/question/trueFalse/modes/CustomAnswerMode';
import HappySadAnswerMode from 'components/task/types/question/trueFalse/modes/HappySadAnswerMode';
import PlusMinusAnswerMode from 'components/task/types/question/trueFalse/modes/PlusMinusAnswerMode';
import TrueFalseAnswerMode from 'components/task/types/question/trueFalse/modes/TrueFalseAnswerMode';

export default {
	'components.TrueFalseTask.mode.custom': {
		factory: CustomAnswerMode,
	},
	'components.TrueFalseTask.mode.happySad': {
		factory: HappySadAnswerMode,
	},
	'components.TrueFalseTask.mode.plusMinus': {
		factory: PlusMinusAnswerMode,
	},
	'components.TrueFalseTask.mode.trueFalse': {
		factory: TrueFalseAnswerMode,
	},
};
