import React from 'react';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import Loader from 'components/commons/_/loader/Loader';

import useFetch from 'components/_/hooks/useFetch';
import { Markdown } from 'components/_/common/text/markdown/Markdown';

function FAQ({ i18nKey, newsService }) {
	const {
		loading,
		error,
		response: { list },
	} = useFetch(() => newsService.fetchList({ tag: 'faq' }), { list: [] });

	return loading ? (
		<Loader />
	) : error ? (
		<p className="fs-6 pl-2 pt-2 pl-md-0">
			<FormattedMessage id={`${i18nKey}.error.${error}`} />
		</p>
	) : isEmpty(list) ? (
		<p className="fs-6 pl-2 pt-2 pl-md-0">
			<FormattedMessage id={`${i18nKey}.error.empty`} />
		</p>
	) : (
		<div>
			<ul className="faq__list">
				{list.map(question => (
					<li
						key={question.title()}
						className="px-3 px-sm-4 py-3 rounded"
						onClick={event => event.currentTarget.classList.toggle('active')}
					>
						<div className="d-flex justify-content-between">
							<div className="question">{question.title()}</div>
							<div className="category flex-grow-1 text-right ml-2">
								<FormattedMessage id={`${i18nKey}.content.category`} /> {question.tags()}
							</div>
						</div>
						<div className="answer mt-2">
							<Markdown>{question.body()}</Markdown>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
}

export default FAQ;
