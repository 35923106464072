import React, { Fragment } from 'react';

import images from 'assets/images';

export default () =>
	function Logo() {
		return (
			<Fragment>
				<svg className="lines" viewBox="0 0 291 232.15" style={{ width: '44px', height: '30px' }}>
					<g fillRule="evenodd" stroke="#FFF" strokeWidth="2">
						<path
							stroke="#FFF"
							d="M393.58,276.81c5.83,1,11.43,1.85,17,2.89a75.74,75.74,0,0,1,25.74,9.59c15.69,9.56,27.08,22.61,31.42,40.88,3.67,15.48,1.13,30.24-6.57,44-5.36,9.55-14.25,15.06-24.26,18.86a103.24,103.24,0,0,1-25.83,5.69c-9.54,1-19.1.86-28.66.6-5.5-.15-11-.73-16.49-1-12.08-.63-24.69,8.12-27.19,22.72-2.43,14.19,7.81,28.54,22,30.77a28.64,28.64,0,0,0,13.39-1c-8.23,8.42-16.79,15.92-28,19.55A47.48,47.48,0,0,1,326.61,472C305.77,470,290.06,459.37,278,442.81c-3.86-5.29-5.73-11.51-7.61-17.69a89.24,89.24,0,0,1-4-27.37c.2-12.19,3-23.63,10.89-33.3,8.44-10.36,19.56-16.31,32.54-18.78,15.59-2.95,30.27.62,44.49,6.81A146.91,146.91,0,0,1,377.89,366a55.91,55.91,0,0,0,20.67,9c19.37,3.85,39.18-9.1,43.56-28.41,2.41-10.65.56-20.61-4.83-30-5.57-9.67-13.24-17.52-22-24.28C408.42,287,401.12,282.15,393.58,276.81Z"
							transform="translate(-176.4 -252)"
							strokeDasharray="818.6390991210938"
							style={{
								opacity: 1,
								strokeDashoffset: 0,
								fill: '#FFF',
							}}
						/>
						<path
							stroke="#FFF"
							d="M211.24,472.93c-16.46-1.5-30.92-14.3-33.67-30.84-3.7-22.24,1.35-42.49,15.74-60.14.29-.35.61-.68,1.33-1.48-3.81,33.84,3.49,64.62,21.59,92.95"
							transform="translate(-176.4 -252)"
							strokeDasharray="212.61007690429688"
							style={{
								opacity: 1,
								strokeDashoffset: 0,
								fill: '#FFF',
							}}
						/>
						<path
							stroke="#FFF"
							d="M252,285.33c10.65-31.46,52.74-43.1,79.18-24.23C302.28,260.79,276.08,268.83,252,285.33Z"
							transform="translate(-176.4 -252)"
							strokeDasharray="179.34539794921875"
							style={{
								opacity: 1,
								strokeDashoffset: 0,
								fill: '#FFF',
							}}
						/>
						<path
							stroke="#FFF"
							d="M442.11,473.26c10.24-18.28,11.3-16.33,20.58-50.91,4.69,3.16,7.85,7.2,8.15,13,.58,11.26-4.08,20.22-12.66,27.08-4.83,3.86-10.22,7-15.36,10.51A4.58,4.58,0,0,1,442.11,473.26Z"
							transform="translate(-176.4 -252)"
							strokeDasharray="121.7225341796875"
							style={{
								opacity: 1,
								strokeDashoffset: 0,
								fill: '#FFF',
							}}
						/>
					</g>
				</svg>
				<img src={images.icon.gamfi} className="gamfi-logo" alt="" />
			</Fragment>
		);
	};
