import InternalDynamicAnchor from 'models/application/anchor/types/internalDynamic/InternalDynamic';

export default class InternalDynamicAnchorMap extends InternalDynamicAnchor {
	/**
	 * @type {string}
	 * @private
	 */
	_pattern = '/mission/:id/details';

	constructor(anchor) {
		super(anchor);

		this.task = anchor.task;

		this._params = {
			id: this.task.id(),
		};
	}
}
