import InternalDynamicAnchorService from 'services/application/anchor/subServices/internalDynamic/InternalDynamicAnchorService';
import subServices from 'services/application/anchor/subServices/internalDynamic/subServices';

export default {
	'services.internalDynamicAnchorService': {
		class: InternalDynamicAnchorService,
		calls: [
			{
				name: 'registerSubService',
				args: ['achievement', { $ref: 'services.internalDynamicAnchorAchievementService' }],
			},
			{
				name: 'registerSubService',
				args: ['challenge', { $ref: 'services.internalDynamicAnchorChallengeService' }],
			},
			{
				name: 'registerSubService',
				args: ['map', { $ref: 'services.internalDynamicAnchorMapService' }],
			},
			{
				name: 'registerSubService',
				args: ['mission', { $ref: 'services.internalDynamicAnchorMissionService' }],
			},
			{
				name: 'registerSubService',
				args: ['news', { $ref: 'services.internalDynamicAnchorNewsService' }],
			},
			{
				name: 'registerSubService',
				args: ['item', { $ref: 'services.internalDynamicAnchorPrizeService' }],
			},
			{
				name: 'registerSubService',
				args: ['ranking', { $ref: 'services.internalDynamicAnchorRankingService' }],
			},
			{
				name: 'registerSubService',
				args: ['submission', { $ref: 'services.internalDynamicAnchorSubmissionService' }],
			},
			{
				name: 'registerSubService',
				args: ['task', { $ref: 'services.internalDynamicAnchorTaskService' }],
			},
			{
				name: 'registerSubService',
				args: ['team-profile', { $ref: 'services.internalDynamicAnchorTeamProfileService' }],
			},
			{
				name: 'registerSubService',
				args: ['user-profile', { $ref: 'services.internalDynamicAnchorUserProfileService' }],
			},
		],
	},
	...subServices,
};
