import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { each, has } from 'lodash';
import { injectIntl } from 'react-intl';
import { Form, Formik } from 'formik';

export default function(eventQueueService, Layout, browserHistoryService) {
	return injectIntl(
		class MockupTask extends PureComponent {
			_eventQueue = eventQueueService.createEventQueue();
			_previousLocation =
				browserHistoryService.previousLocation() ||
				browserHistoryService.defaultLocation({
					defaultLocation: '/mission/list',
				});

			constructor() {
				super();
				this._instanceMessageListener = messageEvent => this._messageListener(messageEvent);
			}

			componentDidMount() {
				window.addEventListener('message', this._instanceMessageListener);
			}

			render() {
				const { task } = this.props;

				return (
					<Formik initialValues={{}} onReset={() => this.props.task.reset()}>
						{({ handleReset, isSubmitting }) => (
							<Form className="form d-flex flex-grow-1">
								<Layout
									task={task}
									options={{
										hideFooterBeforeSubmit: true,
									}}
									actions={{
										submit: {
											buttonText: 'task-details.common.button.confirm',
										},
										goBack: () => browserHistoryService.push(this._previousLocation),
										tryAgain: handleReset,
										isSubmitting,
									}}
								>
									<div className="wrapper--row el--grow standardPreview__columnWrapper overflow--auto justify--center">
										<div className="wrapper--col el--grow">
											<iframe
												className="el--grow mockup"
												src={task.getMockupPath()}
												onLoad={() => this._iframeLoad()}
												title="mockup"
												// In PG and Danone installations mockups embed in iframes YouTube videos or PDF files.
												// 	 Both of which don't work in sandboxed iframes.
												// sandbox="allow-forms allow-scripts allow-modals"
											/>
										</div>
									</div>
								</Layout>
							</Form>
						)}
					</Formik>
				);
			}

			componentWillUnmount() {
				window.removeEventListener('message', this._instanceMessageListener);
			}

			_messageListener(messageEvent) {
				if (
					messageEvent.data.type === 'mockupEvent' &&
					has(this.props.task.trackedMockupEvents, messageEvent.data.mockupEventName)
				) {
					this._registerMockupEvent(messageEvent.data.mockupEventName);
				}
			}

			_iframeLoad() {
				let DOMNode = ReactDOM.findDOMNode(this);
				this._iframeWindow = DOMNode.querySelector('iframe').contentWindow;

				if (!this._iframeWindow) {
					return;
				}

				each(this.props.task.trackedMockupEvents, event => this._registerListener(event.name));
			}

			_registerListener(eventName) {
				try {
					if (this._iframeWindow.registerMockupEventListener) {
						this._iframeWindow.registerMockupEventListener(eventName, () =>
							this._registerMockupEvent(eventName)
						);
					}
				} catch (error) {
					// no-op, probably failure due to sandboxing
				}
			}

			_registerMockupEvent(eventName) {
				if (this.props.task.isCompleted()) {
					return;
				}

				this._eventQueue.addEventToQueue(eventName, () =>
					this.props
						.process({
							mockup_event_name: eventName,
						})
						.tap(({ completion }) => completion && this._eventQueue.clear())
				);
			}
		}
	);
}
