import QuestionAnswer from 'models/common/form/questionAnswer/QuestionAnswer';

export default class StandardQuestionAnswer extends QuestionAnswer {
	constructor(answer) {
		super(answer);

		this._body = answer.body;
	}

	body() {
		return this._body;
	}
}
