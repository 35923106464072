import ChallengeAwardedPlace from 'models/challenge/awardedPlace/ChallengeAwardedPlace';

/**
 * Class representing a challenge submission awarded place.
 * @extends ChallengeAwardedPlace
 */
export default class ChallengeSubmissionAwardedPlace extends ChallengeAwardedPlace {
	/**
	 * @param {Object} awardedPlace
	 */
	constructor(awardedPlace) {
		super(awardedPlace);

		/**
		 * @type {Submission[]}
		 * @private
		 */
		this._submissions = awardedPlace.submissions || [];
	}

	submissions() {
		return this._submissions;
	}
}
