import React from 'react';
import { wire } from 'react-hot-wire';

import { Modal } from 'components/_/common/modal/Modal';
import useFetch from 'components/_/hooks/useFetch';

import { BuyModalForm } from 'components/_/app/store/buy-item/BuyModal.form';

export const BuyModal = ({ item, close, ...props }) => {
	const rewardCafeteriaService = props['services.rewardCafeteriaService'];
	const userProfileService = props['services.userProfileService'];
	const { userProfile } = userProfileService.user();
	const {
		response: { addresses },
		loading,
	} = useFetch(() => rewardCafeteriaService.addresses({ type: item.deliveryType }), { addresses: [] });

	function onSubmit(values, actions) {
		return rewardCafeteriaService
			.buyItem(item, values)
			.then(() => {
				actions.setStatus({ message: 'bought' });
				userProfileService.addToWalletQueue(item.cost);
			})
			.catch(error => actions.setStatus({ error: error.messageId }));
	}

	return (
		<Modal close={close} loading={loading}>
			{() => (
				<BuyModalForm
					item={item}
					addresses={addresses}
					userProfile={userProfile}
					onSubmit={onSubmit}
					success={
						<div>
							<h3 className="mb-2">Nagroda została kupiona!</h3>
							<h5>Zrobimy wszystko, aby dotarła do Ciebie najszybciej jak to możliwe.</h5>
						</div>
					}
				/>
			)}
		</Modal>
	);
};

export default wire(['services.rewardCafeteriaService', 'services.userProfileService'], BuyModal);
