import React from 'react';

import { Modal } from 'components/_/common/modal/Modal';

export default ({ children, title, subtitle, closeModal }) => (
	<Modal close={closeModal}>
		{() => (
			<div className="create-submission">
				{(title || subtitle) && (
					<header className="p-3 rounded-top text-center">
						{title && <h3 className="mb-0">{title}</h3>}
						{subtitle && <h4 className="mb-0">{subtitle}</h4>}
					</header>
				)}
				<section className="rounded-bottom">
					<div className="p-3">{children}</div>
				</section>
			</div>
		)}
	</Modal>
);
