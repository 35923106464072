/* eslint-disable no-console */

class LogService {
	log(...rest) {
		console.log(...rest);
	}

	warn(...rest) {
		console.warn(...rest);
	}

	error(...rest) {
		console.error(...rest);
	}

	info(...rest) {
		console.info(...rest);
	}

	debug(...rest) {
		if (console.debug) {
			console.debug(...rest);
		} else {
			console.log(...rest);
		}
	}
}

export default LogService;
