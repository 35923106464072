import { ImportedRankingEntry } from './ImportedRankingEntry';
import UserProfile from '../../../user/UserProfile';

/**
 * Class representing a user imported ranking entry.
 * @extends ImportedRankingEntry
 */
export class UserImportedRankingEntry extends ImportedRankingEntry {
	constructor(entry) {
		super(entry);

		/**
		 * @type {UserProfile}
		 */
		this.userProfile = new UserProfile(entry.user_profile);
	}
}
