import React from 'react';

import { TeamMember } from './member/Member';

export const TeamMembers = ({ editable, profile, teamService }) => {
	// admin or manager
	const admins = profile.teamMembers().filter(member => member.roles.isAdmin() || member.roles.isManager());
	// user (not admin or manager)
	const users = profile
		.teamMembers()
		.filter(member => member.roles.isUser() && !(member.roles.isAdmin() || member.roles.isManager()));

	return (
		<div className="mx-0 mx-md-2 my-4">
			<div className="row no-gutters members mx-4 mx-md-0">
				{admins.map((member, index) => (
					<TeamMember
						member={member}
						editable={!member.roles.isAdmin() && editable}
						key={member.userProfile.id()}
						teamService={teamService}
					/>
				))}
			</div>
			{users.length > 0 && <h5 className="mb-4 mx-4 mx-md-0">Pozostali członkowie</h5>}
			<div className="row no-gutters members mx-4 mx-md-0">
				{users.map((member, index) => (
					<TeamMember
						member={member}
						editable={editable}
						key={member.userProfile.id()}
						teamService={teamService}
					/>
				))}
			</div>
		</div>
	);
};
