import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ProgressBar } from 'components/_/common/progress-bar/ProgressBar';

export default function KPIProgressStatus({ challenge }) {
	return (
		<div className="fs-4 text-nowrap">
			{challenge.status().type === 'notStarted' ? (
				<div>
					<FormattedMessage
						id={`challenge-list.content.challenge-status.${challenge.status().type}`}
						values={{
							remainingTime: <strong>{` ${challenge.status().remainingTime}`}</strong>,
						}}
					/>
				</div>
			) : (
				<ProgressBar transparent progress={challenge.status().progress}>
					{challenge.status().type === 'inProgress' ? (
						<FormattedMessage
							id="challenge-details.kpi.content.remains"
							values={{
								'remaining-time': <strong>{challenge.status().remainingTime}</strong>,
							}}
						/>
					) : (
						<FormattedMessage id={`challenge-list.content.challenge-status.${challenge.status().type}`} />
					)}
				</ProgressBar>
			)}
		</div>
	);
}
