export default class AccessPopupService {
	_handler = null;

	setHandler(handler) {
		this._handler = handler;
	}

	open() {
		if (!this._handler) {
			return;
		}

		this._handler();
	}
}
