import { Component } from 'react';
import _ from 'lodash';

export default function(SwipeMenu, applicationService, browserHistoryService, platformService) {
	const options = {
		freeSpaceSize: 50,
	};

	return class WrapperMenu extends Component {
		elementRefs = {};
		_storedEvents = [];

		constructor(...args) {
			super(...args);
			this._swipeMenu = new SwipeMenu(this.elementRefs, this.props);
		}

		goBack(event) {
			event.preventDefault();

			browserHistoryService.goBack();
		}

		componentDidMount() {
			this._handlePositionTransfer();

			_.delay(() => {
				this._registerMenuEvents();
				this._handlePositionTransfer();
			}, 500);
		}

		componentDidUpdate() {
			this._handlePositionTransfer();
		}

		componentWillUnmount() {
			this._unregisterMenuEvents();
			window.removeEventListener('resize', this.__cachedResizeHandler);
		}

		_registerDOMEvent(element, event) {
			this._storedEvents.push({ element, ...event });
			element.addEventListener(event.type, event.handler);
		}

		_registerMenuEvents() {
			const { more, mobileMenu, mobileMenuList } = this.elementRefs;
			const {
				parent: { container },
			} = this.props;

			if (!more || !mobileMenu) {
				return;
			}

			this._registerDOMEvent(window, {
				type: 'resize',
				handler: () => this._handlePositionTransfer(),
			});

			this._registerDOMEvent(more, {
				type: 'pointerover',
				handler: event => {
					if (event.pointerType === 'mouse') {
						mobileMenu.classList.add('expanded');
					}
				},
			});

			this._registerDOMEvent(more, {
				type: 'pointerleave',
				handler: event => {
					if (event.pointerType === 'mouse') {
						mobileMenu.classList.remove('expanded');
					}
				},
			});

			this._registerDOMEvent(more, {
				type: 'touchstart',
				handler: event => this._swipeMenu.startTouch(event, mobileMenu),
			});

			this._registerDOMEvent(more, {
				type: 'touchend',
				handler: event => this._swipeMenu.endTouchController(event),
			});

			this._registerDOMEvent(mobileMenuList, {
				type: 'scroll',
				handler: () => this._swipeMenu.scrollHandler(),
			});

			this._registerDOMEvent(container, {
				type: 'touchstart',
				handler: event => this._swipeMenu.startTouch(event, container, true),
			});

			this._registerDOMEvent(container, {
				type: 'touchend',
				handler: event => this._swipeMenu.containerEndTouchController(event),
			});
		}

		_unregisterMenuEvents() {
			_.forEach(this._storedEvents, eventObject => {
				eventObject.element.removeEventListener(eventObject.type, eventObject.handler);
			});
		}

		_handlePositionTransfer() {
			const { nav, menu } = this.elementRefs;

			if (nav && menu) {
				const currentFreeSpace = nav.offsetWidth - menu.offsetWidth;

				const { _desktopPositions: desktopList, _mobilePositions: mobileList } = applicationService.menu();

				if (platformService.isMobile() || nav.offsetWidth < 500) {
					applicationService.menu()._moveAllToMobile();
					try {
						super.forceUpdate();
					} catch (e) {}
					return;
				}

				if (currentFreeSpace < options.freeSpaceSize && this._isTransferPossible(desktopList, 'mobile')) {
					applicationService.menu()._moveDesktopToMobile();
					this._$lastFalledPositionWidth = menu.querySelector('li:last-of-type').offsetWidth;
					this.forceUpdate();
				} else if (this._isTransferPossible(mobileList, 'desktop')) {
					if (currentFreeSpace > options.freeSpaceSize + this._$lastFalledPositionWidth) {
						applicationService.menu()._moveMobileToDesktop();
						this.forceUpdate();
					}
				}
			}
		}

		// TEMPORARY HACK - WRAPPER MENU - TO THE HELL
		forceUpdate() {
			if (Math.random() > 0.5) {
				super.forceUpdate();
			}
		}

		_isTransferPossible(list, flag) {
			return _.filter(list, position => position[flag]).length > 0;
		}
	};
}
