import CompletePhraseQuestionTask from 'components/task/types/question/phrase/CompletePhraseQuestionTask';
import GuessPhraseQuestionTask from 'components/task/types/question/phrase/GuessPhraseQuestionTask';
import RetypePhraseQuestionTask from 'components/task/types/question/phrase/RetypePhraseQuestionTask';

export default {
	'components.CompletePhraseQuestionTask': {
		factory: CompletePhraseQuestionTask,
		deps: [{ $ref: 'components.task.layout.BaseLayout' }],
	},
	'components.GuessPhraseQuestionTask': {
		factory: GuessPhraseQuestionTask,
		deps: [{ $ref: 'components.task.layout.BaseLayout' }],
	},
	'components.RetypePhraseQuestionTask': {
		factory: RetypePhraseQuestionTask,
		deps: [{ $ref: 'components.task.layout.BaseLayout' }],
	},
};
