import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { wire } from 'react-hot-wire';

import View from 'components/_/common/view/list/ListView';

import { ContactList } from './ContactList';

const ContactListView = props => {
	const contactListService = props['services.contactListService'];
	const {
		match: {
			params: { tab },
		},
	} = props;

	const [search, setSearch] = useState('');

	return (
		<View
			name="contactList"
			i18nKey="contact-list"
			search={search}
			setSearch={setSearch}
			tabs={{
				url: '/contact/list/${tab}', // eslint-disable-line no-template-curly-in-string
				list: [
					{
						tab: 'all',
						content: <FormattedMessage id="contact-list.tab.all" />,
					},
					{
						tab: 'active',
						content: <FormattedMessage id="contact-list.tab.active" />,
					},
				],
			}}
		>
			<ContactList tab={tab} search={search} contactListService={contactListService} />
		</View>
	);
};

export default wire(['services.contactListService'], ContactListView);
