import Answer from 'models/task/question/answer/Answer';

/**
 * Class representing a text answer.
 * @extends Answer
 */
export default class TextAnswer extends Answer {
	/**
	 * @param {Object} answer
	 * @param {string} index
	 */
	constructor(answer, index) {
		super(answer, index);

		/**
		 * @type {string}
		 */
		this._body = answer.body;
	}

	body() {
		return this._body;
	}
}
