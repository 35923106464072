import { Address } from './Address';

export class PhysicalAddress extends Address {
	constructor(address) {
		super(address);

		this.companyName = address.company_name;
		this.streetName = address.street_name;
		this.buildingNumber = address.building_number;
		this.flatNumber = address.flat_number;
		this.postalCode = address.postal_code;
		this.city = address.city;
		this.phone = address.phone;
		this.note = address.note;
	}
}
