import io from 'socket.io-client';

/**
 *
 */
class RealTimeNotificationService {
	/**
	 * @param {Object} applicationConfig
	 * @param {Object} accessTokenService
	 * @param {Object} notificationService
	 */
	constructor(applicationConfig, accessTokenService, notificationService) {
		this._websocketAddress = applicationConfig.WEBSOCKET_SERVER_ADDRESS;
		this._accessTokenService = accessTokenService;
		this._notificationService = notificationService;
		this._lastMessagePromise = Promise.resolve();
		this._recipientHandler = null;
		this._socket = null;
		this._waitingNotifications = [];

		this._subscribeNotification();

		this._accessTokenService.addChangeListener(() => this._subscribeNotification());
	}

	setRecipient(handler) {
		this._recipientHandler = handler;

		this._waitingNotifications.forEach(notification => {
			this._displayNotification(notification);
		});

		this._waitingNotifications = [];
	}

	removeRecipient() {
		this._recipientHandler = null;
	}

	_subscribeNotification() {
		let accessToken = this._accessTokenService.accessToken('application');

		if (this._socket) {
			this._socket.close();
			this._socket = null;
		}

		if (accessToken) {
			this._socket = io(`${this._websocketAddress}/`, {
				transports: ['websocket'],
			});

			this._socket.on('notification', this._onNotification.bind(this));
			this._socket.on('authenticationRequest', () => {
				this._socket.emit('authenticationResponse', {
					accessToken: this._accessTokenService.accessToken('application'),
				});
			});
		}
	}

	/**
	 * @private
	 *
	 * @param {notification} notification
	 */
	_onNotification(notification) {
		const model = this._notificationService.toModel(notification.content);

		if (model) {
			if (this._recipientHandler) {
				this._displayNotification(model);
			} else {
				this._waitingNotifications.push(model);
			}
		}
	}

	/**
	 * @private
	 *
	 * @param {notification} notification
	 */
	_displayNotification(notification) {
		this._lastMessagePromise = this._lastMessagePromise.then(() => {
			return new Promise(resolve => {
				this._recipientHandler({
					notification,
					closeFunction: resolve,
				});
			});
		});
	}
}

export default RealTimeNotificationService;
