import React from 'react';

import images from 'assets/images';

import SVGImage from 'components/commons/SVGImage';
import RankingEntry from 'components/ranking/_/entry/Entry';

const Container = ({ ranking, position, entry }) => {
	const currency = ranking.currency && (
		<SVGImage src={ranking.currency.image || images.currency[ranking.currency.codeName]} className="pr-2 icon" />
	);

	return (
		<RankingEntry
			ranking={ranking}
			currency={currency}
			position={position}
			entry={entry}
			container={true}
			name={entry.container.name}
		/>
	);
};

export default Container;
