import ImageMultipleChoice from 'components/task/types/question/choice/answers/ImageMultipleChoice';
import ImageSingleChoice from 'components/task/types/question/choice/answers/ImageSingleChoice';
import TextMultipleChoice from 'components/task/types/question/choice/answers/TextMultipleChoice';
import TextSingleChoice from 'components/task/types/question/choice/answers/TextSingleChoice';

export default {
	text_single: TextSingleChoice,
	text_multiple: TextMultipleChoice,
	image_single: ImageSingleChoice,
	image_multiple: ImageMultipleChoice,
};
