import Attributes from 'components/task/types/content/blocks/Attributes';
import Button from 'components/task/types/content/blocks/Button';
import Header from 'components/task/types/content/blocks/Header';
import Image from 'components/task/types/content/blocks/Image';
import Text from 'components/task/types/content/blocks/Text';

export default {
	'components.task.Content.block.Attributes': {
		factory: Attributes,
	},
	'components.task.Content.block.Button': {
		factory: Button,
	},
	'components.task.Content.block.Header': {
		factory: Header,
	},
	'components.task.Content.block.Image': {
		factory: Image,
		deps: [{ $ref: 'components.Image' }],
	},
	'components.task.Content.block.Text': {
		factory: Text,
	},
};
