import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import CreateSubmission from '../../Create.template';

export default class CreateSubmissionErrorComponent extends PureComponent {
	render() {
		const { intl, closeModal } = this.props;

		return (
			<CreateSubmission
				closeModal={closeModal}
				title={intl.formatMessage({
					id: 'create-submission.error.title',
				})}
			>
				<div className="error-view text-center">
					<i className="fa fa-close my-3" />
					<p>
						{intl.formatMessage({
							id: 'create-submission.error.content',
						})}
					</p>
					<button className="btn__basic my-4" onClick={closeModal}>
						<FormattedMessage id="create-submission.error.button" />
					</button>
				</div>
			</CreateSubmission>
		);
	}
}
