import React, { Fragment, InputHTMLAttributes, PropsWithChildren } from 'react';
import classnames from 'classnames';

import useStyles from './Checkbox.jss';
import { Field } from 'formik';

export type CheckboxProps = PropsWithChildren<
	InputHTMLAttributes<HTMLInputElement> & {
		className?: string;
		contentClassName?: string;
		disabled?: boolean;
		name?: string;
		error?: string | false;
		fieldType?: React.FC | 'input';
	}
>;

export const Checkbox = ({
	name,
	className,
	contentClassName,
	disabled,
	children,
	error,
	fieldType: FieldType = Field,
	...props
}: CheckboxProps) => {
	const classes = useStyles();

	return (
		<Fragment>
			<label className={classnames(classes.label, disabled && classes.disabled, className)}>
				<FieldType type="checkbox" name={name} className={classes.input} disabled={disabled} {...props} />
				<div className={classes.check} />
				{children && <div className={classnames(classes.content, contentClassName)}>{children}</div>}
			</label>
			{error && <div className="m-2 mb-3 text-danger">{error}</div>}
		</Fragment>
	);
};
