export default class Integration implements IntegrationInterface {
	public id: string;
	public type: string;
	public authEnabled: boolean;
	public registrationEnabled: boolean;
	public destination: string;

	constructor(integration: IntegrationDTO) {
		this.id = integration.id;
		this.type = integration.type;
		this.authEnabled = integration.auth_enabled;
		this.registrationEnabled = integration.registration_enabled;
		this.destination = integration.destination;
	}
}

export interface IntegrationInterface {
	id: string;
	type: string;
	authEnabled: boolean;
	registrationEnabled: boolean;
	destination: string;
}

export interface IntegrationDTO {
	id: string;
	type: string;
	auth_enabled: boolean;
	registration_enabled: boolean;
	destination: string;
}
