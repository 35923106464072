import OAuth from './OAuth';

import Facebook from './facebook/Facebook.template';
import GenericOAuth from './generic-oauth/GenericOAuth.template';
import Google from './google/Google.template';
import LinkedIn from './linked-in/LinkedIn.template';

export default {
	facebook: OAuth(Facebook),
	generic_oauth: OAuth(GenericOAuth),
	google: OAuth(Google),
	linked_in: OAuth(LinkedIn),
};
