import React from 'react';
import PropTypes from 'prop-types';

export default function(Image) {
	function MatchingImageType({ src, classes, backgroundMode, setImageType, imageWrapperRef, imageRef, borderSize }) {
		return (
			<div
				className={`matchingImageType image image--contain el--grow matchingImageType--${backgroundMode} ${classes}`}
				style={{ backgroundImage: `url(${src}?w=1024)` }}
				ref={imageWrapperRef}
			>
				{backgroundMode === 'background' && (
					<div
						className="matchingImageType__radius"
						style={{
							width: borderSize ? parseFloat(borderSize.width) : 0,
							height: borderSize ? parseFloat(borderSize.height) : 0,
						}}
					/>
				)}
				<Image
					className="matchingImageType__previewImage"
					ref={imageRef}
					src={src}
					onLoad={e => setImageType(e)}
				/>
			</div>
		);
	}

	MatchingImageType.propTypes = {
		src: PropTypes.string,
		classes: PropTypes.string,
		backgroundMode: PropTypes.string,
		setImageType: PropTypes.func,
		imageWrapperRef: PropTypes.object,
		imageRef: PropTypes.object,
		borderSize: PropTypes.object,
	};

	return MatchingImageType;
}
