import _ from 'lodash';

/**
 * Class representing a application menu.
 * @abstract
 */
export default class Menu {
	/**
	 * @param {Object[]} positions
	 */
	constructor(positions) {
		let morePositionIndex = _.findIndex(positions, { type: 'more' });

		if (morePositionIndex === -1) {
			morePositionIndex = positions.length;
		}

		/**
		 * @type {Object}
		 */
		this._logo = this._extractLogo(positions);
		/**
		 * @type {Object}
		 */
		this._more = this._extractMore(positions);
		/**
		 * @type {Object[]}
		 */
		this._desktopPositions = _.map(this._extractDesktopPositions(positions, morePositionIndex), position => {
			position.allowDesktop();
			position.allowMobile();

			return position;
		});
		/**
		 * @type {Object[]}
		 */
		this._mobilePositions = _.map(this._extractMobilePositions(positions, morePositionIndex), position => {
			position.allowMobile();

			return position;
		});
		/**
		 * @type {Object[]}
		 */
		this._imagePositions = this._extractImagePositions(positions);
		/**
		 * @type {Object}
		 */
		this._mainPage = this._extractMainPage(positions);
		/**
		 * @type {Object}
		 */
		this._welcomePage = this._extractWelcomePage(positions);
	}

	mainPage() {
		return this._mainPage;
	}

	welcomePage() {
		return this._welcomePage;
	}

	/**
	 * @param {Object[]} positions
	 * @returns {Object|undefined}
	 * @private
	 */
	_extractLogo(positions) {
		return _.find(positions, { type: 'logo' });
	}

	/**
	 * @param {Object[]} positions
	 * @returns {Object|undefined}
	 * @private
	 */
	_extractMore(positions) {
		return _.find(positions, { type: 'more' });
	}

	/**
	 * @param {Object[]} positions
	 * @param {number} morePositionIndex
	 * @returns {Object[]}
	 * @private
	 */
	_extractDesktopPositions(positions, morePositionIndex) {
		return _.filter(_.slice(positions, 0, morePositionIndex), {
			type: 'link',
		});
	}

	/**
	 * @param {Object[]} positions
	 * @param {number} morePositionIndex
	 * @returns {Object[]}
	 * @private
	 */
	_extractMobilePositions(positions, morePositionIndex) {
		return !~morePositionIndex ? [] : _.filter(_.slice(positions, morePositionIndex), { type: 'link' });
	}

	/**
	 * @param {Object[]} positions
	 * @returns {Object[]}
	 * @private
	 */
	_extractImagePositions(positions) {
		return _.filter(positions, { type: 'image' });
	}

	/**
	 * @param {Object[]} positions
	 * @returns {Object}
	 * @private
	 */
	_extractMainPage(positions) {
		return _.find(positions, { mainPage: true });
	}

	/**
	 * @param {Object[]} positions
	 * @returns {Object}
	 * @private
	 */
	_extractWelcomePage(positions) {
		return _.find(positions, { welcomePage: true });
	}

	_moveDesktopToMobile() {
		this._mobilePositions.unshift(_.last(this._desktopPositions));
		this._desktopPositions.splice(-1, 1);
	}

	_moveAllToMobile() {
		this._mobilePositions.unshift(...this._desktopPositions);
		this._desktopPositions = [];
	}

	_moveMobileToDesktop() {
		this._desktopPositions.push(this._mobilePositions.shift());
	}
}
