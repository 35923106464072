import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

/**
 * @deprecated use _/app/application/agreements
 */
export default function(userService, ReactMarkdown) {
	return class Agreement extends Component {
		static propTypes = {
			agreement: PropTypes.object.isRequired,
			onChange: PropTypes.func,
		};

		constructor(...args) {
			super(...args);

			this.state = {
				checked: this._mustBeChecked(),
				error: false,
			};
		}

		render() {
			const { agreement, errorJSX } = this.props;

			return (
				<div style={{ marginBottom: '10px' }}>
					<label className="selectWrapper noMargin wrapper--row">
						<div className="checkboxWrapper el--shrink">
							<input
								type="checkbox"
								ref={checkbox => {
									this._checkbox = checkbox;
								}}
								checked={this.state.checked}
								onChange={event => this._onChange(event)}
							/>
							<div className="checked" />
						</div>
						<div className="standardText standardText--noMargin standardText--leftAlign">
							<ReactMarkdown source={agreement.body()} className="inline" />
						</div>
					</label>
					{this.state.error &&
						(errorJSX ? (
							errorJSX
						) : (
							<div className="errorWrapper">
								<div className="errorMessage rulesError text-left">
									<FormattedMessage id="auth.form.error.required-agreements" />
								</div>
							</div>
						))}
				</div>
			);
		}

		check() {
			const { checked } = this._checkbox;
			const { agreement } = this.props;

			this.setState({
				checked,
				error: !checked && agreement.required(),
			});
		}

		_onChange() {
			const { checked } = this._checkbox;
			const { agreement, onChange } = this.props;

			agreement.setCheckStatus(checked);

			if (onChange) {
				onChange(agreement);
			}

			this.check();
		}

		_mustBeChecked() {
			const { agreement } = this.props;

			if (!userService.user() || !userService.user().userProfile) {
				return false;
			}

			let mustBeChecked = userService.user().userProfile.checkAgreement({
				id: agreement.id(),
			});

			if (mustBeChecked) {
				agreement.setCheckStatus(true);

				return mustBeChecked;
			}
		}
	};
}
