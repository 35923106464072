import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import { isBoolean } from 'lodash';

import VerifyToken from './VerifyToken.template';
import SetPassword from '../set-password/SetPassword';

export class VerifyTokenComponent extends PureComponent {
	state = {
		isValid: null,
	};

	componentDidMount() {
		const { integration, token } = this.props;

		this.props['services.importedIdIntegrationService']
			.resetPasswordCheckEmail({ id: integration.id, reset_token: token })
			.then(isValid => this.setState({ isValid }));
	}

	render() {
		if (!isBoolean(this.state.isValid)) {
			return null;
		}

		return (
			<VerifyToken isValid={this.state.isValid}>
				<SetPassword {...this.props} type="email" />
			</VerifyToken>
		);
	}
}

export default wire(['services.importedIdIntegrationService'], VerifyTokenComponent);
