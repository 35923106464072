import React from 'react';

import { Notification } from './Notification.template';

export function Notifications({ list }) {
	return (
		<div className="notificationList__list">
			<div className="row">
				{list.map(notification => (
					<Notification key={notification.id()} notification={notification} />
				))}
			</div>
		</div>
	);
}
