import { Component } from 'react';

export default function() {
	return class CreativeChallenge extends Component {
		_countDownEndHandler() {
			this.props.challenge.state().setCanParticipate();
			this.forceUpdate();
		}
	};
}
