import React from 'react';
import classnames from 'classnames';
import { wire } from 'react-hot-wire';

import useStyles from './Currency.jss';

import images from 'assets/images';

export const Currency = wire(['services.currencyService'], ({ currency: codeName, className, ...props }) => {
	const currencyService = props['services.currencyService'];
	const classes = useStyles();

	const currency = currencyService.currency(codeName);
	const icon = (currency && currency.icon()) || images.currency[codeName];

	return (
		<div className={classnames('d-flex', classes.currency, className)}>
			<img src={icon} alt={`currency_${codeName}`} className={classes.img} />
			{props.hasOwnProperty('value') ? (
				<div className={classnames('align-self-center', classes.amount)}>{props.value}</div>
			) : null}
		</div>
	);
});
