import AchievementDetails from 'views/achievement/AchievementDetails';

export default {
	'components.AchievementDetails': {
		factory: AchievementDetails,
		deps: [
			{ $ref: 'components.Currencies' },
			{ $ref: 'components.AutoScrollingText' },
			{ $ref: 'components.ReactMarkdown' },
			{ $ref: 'components.Image' },
		],
	},
};
