import InternalStaticAnchor from 'models/application/anchor/types/internalStatic/InternalStatic';

export default class InternalStaticAnchorLogin extends InternalStaticAnchor {
	/**
	 * @type {string}
	 * @private
	 */
	_pattern = '/auth/login';

	anonymous = true;
}
