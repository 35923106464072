import { createUseStyles } from 'react-jss';

export default (() => {
	return createUseStyles(theme => ({
		container: {
			overflow: 'auto',
			position: 'relative',
			height: '100%',
		},
		sticky: ({ isSticky, top } = {}) =>
			isSticky
				? {
						position: 'fixed',
						top: `${top}px`,
						left: 0,
						right: 0,
						zIndex: theme.zIndex.sticky,
				  }
				: {
						position: 'relative',
				  },
	}));
})();
