import React, { Fragment } from 'react';
import { Container } from 'modules/react-sticky/react-sticky';
import Loader from 'components/commons/_/loader/Loader';
import PortalDesktop from 'components/employeePortal/Desktop';
import PortalMobile from 'components/employeePortal/Mobile';
import Media from 'react-media';
import useEmployeePortalContext from './context/employeePortalContext';

export const EmployeePortalView = () => {
	const {
		progress: { isLoading: processListLoading },
	} = useEmployeePortalContext();

	return (
		<Container className="">
			{processListLoading ? (
				<Loader />
			) : (
				<div className="portal p-1 pt-4 pt-md-0 px-sm-5 px-3">
					<Media
						queries={{
							small: '(max-width: 991px)',
							large: '(min-width: 992px)',
						}}
					>
						{matches => (
							<Fragment>
								{matches.small && <PortalMobile />}
								{matches.large && <PortalDesktop />}
							</Fragment>
						)}
					</Media>
				</div>
			)}
		</Container>
	);
};

export default EmployeePortalView;
