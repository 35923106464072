import React, { PureComponent } from 'react';

export default function(imageService) {
	return class Image extends PureComponent {
		_ref = React.createRef();

		render() {
			const { tag = 'img', src, children, style = {}, ...props } = this.props;

			if (!src) {
				return null;
			}

			return React.createElement(
				tag,
				tag === 'img'
					? {
							...props,
							src,
							ref: this._ref,
							style,
							srcSet: imageService
								.thumbnailSizes()
								.map(size => `${src}?w=${size} ${size}w`)
								.join(', '),
					  }
					: {
							...props,
							ref: this._ref,
							style: {
								...style,
								backgroundImage: `image-set(${imageService
									.thumbnailSizes()
									.map((size, index) => `url('${src}?w=${size}') ${index}x`)
									.join(', ')})`,
							},
					  },
				children
			);
		}

		ref() {
			return this._ref;
		}
	};
}
