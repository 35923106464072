import React from 'react';
import { wire } from 'react-hot-wire';

import View from 'components/_/common/view/list/ListView';

import MissionList from './MissionList';

function MissionListView(props) {
	const taskService = props['services.taskService'];

	return (
		<View
			name="missionList"
			i18nKey="mission-list"
			config={{
				search: false,
			}}
		>
			<MissionList taskService={taskService} />
		</View>
	);
}

export default wire(['services.taskService'], MissionListView);
