export enum WorkflowCategoryPhase {
	'first-day' = 'first-day',
	'key-date' = 'key-date',
	'last-day' = 'last-day',
}

export enum WorkflowCategory {
	'talent-and-onboarding' = 'talent-and-onboarding',
	'learning-and-development' = 'learning-and-development',
	offboarding = 'offboarding',
}
