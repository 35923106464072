import Task from 'models/task/Task';

/**
 * Class representing a document task.
 * @extends Task
 */
export default class DocumentTask extends Task {
	/**
	 * @param {Object} task
	 */
	constructor(task) {
		super(task);

		this._document = task.document;
		this._downloadable = !!task.downloadable;
	}

	document() {
		return this._document;
	}

	downloadable() {
		return this._downloadable;
	}
}
