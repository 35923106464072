import React from 'react';
import { wire } from 'react-hot-wire';

import TeamProfile from './TeamProfile.template';

import Loader from 'components/commons/_/loader/Loader';
import useFetch from 'components/_/hooks/useFetch';

function TeamProfileComponent({ match: { params }, ...props }) {
	const Avatar = props['components.Avatar'];
	const teamService = props['services.teamService'];
	const declarationTaskService = props['services.declarationTaskService'];
	const { response, loading, error, setResponse } = useFetch(() =>
		teamService.fetchDetails({
			id: params.id,
		})
	);
	const { response: taskCount } = useFetch(() => declarationTaskService.count(), 0);

	return loading ? (
		<Loader />
	) : error ? (
		<div>error {JSON.stringify(error, null, '    ')}</div>
	) : (
		<TeamProfile
			{...response}
			params={params}
			Avatar={Avatar}
			onAvatarChange={avatar => {
				return teamService
					.editProfile(response.team, {
						avatar,
					})
					.then(() => {
						response.team.setFieldValue('avatar', avatar.image);
						setResponse({ ...response });
					});
			}}
			onFieldChange={(field, value) => {
				response.team.setFieldValue(field, value);
				setResponse({ ...response });
			}}
			teamService={teamService}
			taskCount={taskCount}
		/>
	);
}

export default wire(
	['components.Avatar', 'services.teamService', 'services.declarationTaskService'],
	TeamProfileComponent
);
