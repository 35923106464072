import { CustomAnswerMode } from 'models/task/question/questionTask/trueFalse/answer/mode/CustomAnswerMode';

export default class CustomAnswerModeService {
	/**
	 * @param {Object} answer
	 * @returns {CustomAnswerMode}
	 */
	toModel(answer) {
		return new CustomAnswerMode(answer);
	}
}
