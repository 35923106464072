import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import ChallengeProgressStatus from './CreativeProgressStatus';
import Countdown from 'components/commons/Countdown';

import CreativeChallengeMySubmissions from 'components/challenge/_/challenge/creative/submission/list/my/My';
import CreativeChallengeRecentlyAdded from 'components/challenge/_/challenge/creative/submission/list/recently-added/RecentlyAdded';

import { Header } from 'components/_/common/view/item/ItemView';
import { NavTabList as Nav } from 'components/commons/_/tabs/NavTabList';

import CreateSubmission from 'components/challenge/_/challenge/creative/submission/create/Create';
import { RankingPositionList } from 'components/ranking/_/RankingPositionList';

export default function(
	{ Tabs },
	BaseCreativeChallenge,
	userProfileService,
	accessPopupService,
	ReactMarkdown,
	AutoScrollingText,
	modalService,
	navigationService,
	rankingService
) {
	return class CreativeChallenge extends BaseCreativeChallenge {
		render() {
			const { challenge } = this.props;

			return (
				<div className="view__challengeDetails view__challengeDetails--creative el--grow">
					<CreateSubmission
						challenge={challenge}
						onSubmit={() => {
							navigationService.goTo(`/challenge/${challenge.id}/mySubmissions`);
							this.props.reload();
						}}
					/>
					<div className="standardPreview wrapper--col challengeDetails">
						<Header title={challenge.title()} goBack={{ location: '/challenge/list/open' }} />
						<div className="wrapper--col el--grow overflow--auto overflowContainer">
							<div className="standardPreview__mainWrapper el--grow wrapper--col">
								<Nav
									className="my-3"
									url="/challenge/${id}/${tab}" // eslint-disable-line no-template-curly-in-string
									list={[
										{
											id: challenge.id,
											tab: 'details',
											content: (
												<FormattedMessage id="challenge-details.creative.menu-tabs.details" />
											),
										},
										{
											id: challenge.id,
											tab: 'mySubmissions',
											content: (
												<FormattedMessage id="challenge-details.creative.menu-tabs.my-submissions" />
											),
										},
										{
											id: challenge.id,
											tab: 'recentlyAddedSubmissions',
											content: (
												<FormattedMessage id="challenge-details.creative.menu-tabs.recently-added-submissions" />
											),
											badge: challenge.submissionCount(),
										},
										challenge.ranking() && {
											id: challenge.id,
											tab: 'ranking',
											content: (
												<FormattedMessage id="challenge-details.creative.menu-tabs.ranking" />
											),
										},
										challenge.hasConfiguredAwardedPlaces() && {
											id: challenge.id,
											tab: 'prizes',
											content: (
												<FormattedMessage id="challenge-details.creative.menu-tabs.prizes" />
											),
										},
										challenge.hasConfiguredAwardedPlaces() && {
											id: challenge.id,
											tab: 'winners',
											content: (
												<FormattedMessage id="challenge-details.creative.menu-tabs.winners" />
											),
										},
									].filter(item => item)}
								/>
								<hr />
								<div className="challengeDetails__contentSection el--grow wrapper--col">
									<Route
										exact
										path={`/challenge/${challenge.id}/details`}
										render={() => <Tabs.ChallengeDetails challenge={challenge} user={true} />}
									/>
									<Route
										exact
										path={`/challenge/${challenge.id}/mySubmissions`}
										render={() => <CreativeChallengeMySubmissions challenge={challenge} />}
									/>
									<Route
										exact
										path={`/challenge/${challenge.id}/recentlyAddedSubmissions`}
										render={() => <CreativeChallengeRecentlyAdded challenge={challenge} />}
									/>
									{challenge.ranking() && (
										<Route
											exact
											path={`/challenge/${challenge.id}/ranking`}
											render={() => (
												<RankingPositionList
													rankingId={challenge.ranking().id}
													rankingService={rankingService}
												/>
											)}
										/>
									)}
									{challenge.hasConfiguredAwardedPlaces() && (
										<Fragment>
											<Route
												exact
												path={`/challenge/${challenge.id}/prizes`}
												render={() => <Tabs.ChallengePrizeList challenge={challenge} />}
											/>
											<Route
												exact
												path={`/challenge/${challenge.id}/winners`}
												render={() => <Tabs.ChallengeWinners challenge={challenge} />}
											/>
										</Fragment>
									)}
								</div>
							</div>
						</div>
						<div
							className={classnames(
								'challengeDetails__footer wrapper--row align--center el--shrink flex-row flex-wrap',
								{
									'justify-content-around': challenge.state(),
									'justify--end': !challenge.state(),
									canParticipate: challenge.state() && challenge.state().canParticipate(),
								}
							)}
						>
							{challenge.state() && (
								<ChallengeProgressStatus
									challenge={challenge}
									canParticipate={challenge.state().canParticipate()}
								/>
							)}
							<div className="pt-2 pt-sm-0 pl-0 pl-sm-3">
								{userProfileService.isAnonymous() ? (
									<button className="btn__basic" onClick={() => accessPopupService.open()}>
										<FormattedMessage id="challenge-details.creative.button.take-part" />
									</button>
								) : (
									this._button() && (
										<button
											className={classnames('btn__basic', {
												'btn__basic--disabled': !challenge.state().canParticipate(),
											})}
											onClick={() =>
												challenge.state().canParticipate() &&
												modalService.runChangeListeners('create-submission')
											}
										>
											{this._button()}
										</button>
									)
								)}
							</div>
						</div>
					</div>
				</div>
			);
		}

		_button() {
			const { challenge } = this.props;
			const isOwner =
				userProfileService.user().membership &&
				(userProfileService
					.user()
					.membership.roles()
					.isManager() ||
					userProfileService
						.user()
						.membership.roles()
						.isAdmin());
			const isParticipationTeamMode = challenge.isParticipationTeamMode();

			if (challenge.hasFinished()) {
				return null;
			}

			return isParticipationTeamMode && !isOwner ? (
				<FormattedMessage id="challenge-details.creative.button.only-for-manager" />
			) : challenge.limitReached() ? (
				<FormattedMessage id="challenge-details.creative.button.limit-reached" />
			) : challenge.state().canParticipate() ? (
				<FormattedMessage id="challenge-details.creative.button.take-part" />
			) : challenge.state().cooldownEnd() ? (
				<React.Fragment>
					<FormattedMessage id="challenge-details.creative.button.add-submission-in" />
					<span className="countdown">
						<Countdown
							date={challenge.state().cooldownEnd()}
							endHandler={() => this._countDownEndHandler()}
						/>
					</span>
				</React.Fragment>
			) : null;
		}
	};
}
