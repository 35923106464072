import TeamContainerService from './TeamContainerService';
import UserContainerService from './UserContainerService';

export default {
	'services.teamContainerService': {
		class: TeamContainerService,
	},
	'services.userContainerService': {
		class: UserContainerService,
	},
};
