import find from 'lodash/find';

import { CustomUserProfileFieldDefinition } from './definition/CustomUserProfileFieldDefinition';
import { MappedUserProfileFieldDefinition } from './definition/MappedUserProfileFieldDefinition';

export class UserProfileFields extends Array {
	custom() {
		return this.filter(field => field.definition() instanceof CustomUserProfileFieldDefinition);
	}

	mapped() {
		return this.filter(field => field.definition() instanceof MappedUserProfileFieldDefinition);
	}

	include(include = [], withValueOrEditable = true) {
		let fields = this;

		fields = fields.filter(field => include.includes(field.definition().valueType()));

		if (withValueOrEditable) {
			fields = fields.filter(field => field.value() || field.definition().userEditable());
		}

		return fields;
	}

	/**
	 *
	 * @param {Array[]} exclude
	 * @param {boolean} withValueOrEditable - for editing and viewing fields in the user profile
	 *
	 * NOTE: "image" type is not yet supported
	 */
	exclude(exclude = [], withValueOrEditable = true) {
		let fields = this;

		fields = fields.filter(field => !exclude.includes(field.definition().valueType()));

		if (withValueOrEditable) {
			fields = fields.filter(field => field.value() || field.definition().userEditable());
		}

		return fields;
	}

	avatar() {
		return find(this.mapped(), field => field.definition().mappedProfileField() === 'avatar');
	}

	value(definitionId) {
		return find(this, field => field.definition().id() === definitionId).value();
	}

	get contactList() {
		const avatar = this.filter(
			field =>
				field.definition() instanceof MappedUserProfileFieldDefinition &&
				field.definition().mappedProfileField() === 'avatar'
		)[0];

		const fields = this
			// without images..
			.filter(field => field.definition().valueType() !== 'image')
			// ..and without mapped fields: "forename" and "surname"
			.filter(
				field =>
					!(
						field.definition().type() === 'mapped' &&
						['forename', 'surname'].includes(field.definition().mappedProfileField())
					)
			);

		return {
			avatar,
			avatarValue: avatar && avatar.value(),
			name: this
				// get "forename" and "surname"
				.filter(
					field =>
						field.definition().type() === 'mapped' &&
						['forename', 'surname'].includes(field.definition().mappedProfileField())
				)
				// only not empty fields
				.filter(field => field.value())
				// build final name from values
				.map(field => field.value())
				.join(' '),
			fields,
			hasFields: fields.filter(field => field.value()).length > 0,
		};
	}
}
