import InternalDynamicAnchor from 'models/application/anchor/types/internalDynamic/InternalDynamic';

export default class InternalDynamicAnchorAchievement extends InternalDynamicAnchor {
	/**
	 * @type {string}
	 * @private
	 */
	_pattern = '/achievement/:id/details';

	constructor(anchor) {
		super(anchor);

		this.achievement = anchor.achievement;

		this._params = {
			id: this.achievement.id,
		};
	}
}
