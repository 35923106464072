import Currencies from 'models/application/currency/Currencies';
import Currency from 'models/application/currency/Currency';
import _ from 'lodash';

export default class CurrencyService {
	constructor(applicationService) {
		this._applicationService = applicationService;
	}

	currency(codeName) {
		const currencies = this._applicationService.config().currencies();

		return new Currency(_.find(currencies, currency => currency.code_name === codeName));
	}

	toModel(points, options = {}) {
		const currenciesOrder = this._applicationService.config().currencies();

		options = {
			allowZeroValues: false,
			reverse: false,
			...options,
		};

		const sortedCurrencies = _.map(currenciesOrder, ({ code_name, ...rest }) => {
			const value = (points && points[code_name]) || 0;

			return {
				...rest,
				code_name,
				value: options.reverse ? -value : value,
			};
		});

		return new Currencies(
			_.map(sortedCurrencies, currency => new Currency(currency)),
			options
		);
	}

	merge(options, ...args) {
		return this.toModel(
			_.reduce(
				args,
				(points, amounts) => {
					_.each(amounts.values(), currency => {
						if (!_.isNumber(points[currency.codeName()])) {
							points[currency.codeName()] = 0;
						}

						points[currency.codeName()] = points[currency.codeName()] + currency.value();
					});

					return points;
				},
				{}
			),
			options || { allowZeroValues: true }
		);
	}

	diff(wallet, points) {
		return this.toModel(
			_.reduce(
				wallet.values(),
				(diff, walletCurrency) => {
					const pointsCurrency = points.getCurrencyByName(walletCurrency.codeName());

					diff[walletCurrency.codeName()] = walletCurrency.value();

					if (pointsCurrency) {
						diff[walletCurrency.codeName()] = walletCurrency.value() - pointsCurrency.value();
					}

					return diff;
				},
				{}
			),
			{ allowZeroValues: true }
		);
	}
}
