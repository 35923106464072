import React from 'react';
import { SortableElement } from 'react-sortable-hoc';

import SVGImage from 'components/commons/SVGImage';
import images from 'assets/images';

export default () =>
	SortableElement(({ answer }) => (
		<li
			className="answer answer--global answer__textType wrapper--row"
			onTouchStart={e => e.currentTarget.classList.add('mobileOrder')}
			onTouchEnd={e => e.currentTarget.classList.remove('mobileOrder')}
		>
			<label className="wrapper--row el--grow">
				<div className="answer__contentArea wrapper--row el--grow align--middle">
					<SVGImage src={images.icon.orderTaskIcon} className="icon" />
					<div className="standardText standardText--noMargin wrapper--row align--center">
						{answer.body()}
					</div>
				</div>
				<div className="roundVisualStatus hidden--mobile" />
			</label>
		</li>
	));
