import types from 'components/mission/types';
import missionDetails from 'components/mission/missionDetails';

export default {
	...types,
	'components.MissionDetails': {
		factory: missionDetails,
		deps: [
			{ $ref: 'services.taskService' },
			{
				collection_mission: { $ref: 'components.CollectionMission' },
				graph: { $ref: 'components.GraphTask' },
				map: { $ref: 'components.MapTask' },
			},
		],
	},
};
