/**
 * Class representing a commitment ranking entry.
 * @abstract
 */
export class CommitmentRankingEntry {
	constructor(entry) {
		/**
		 * @type {Object<string,number>}
		 */
		this.score = entry.score;
		/**
		 * @type {string[]}
		 */
		this.scores = [`${this.score.commitment}%`];
	}
}
