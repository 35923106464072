import AutoScrollingText from 'views/commons/AutoScrollingText';
import MatchingImageType from 'views/commons/MatchingImageType';
import ImageViewer from 'views/commons/ImageViewer';
import ReactMarkdown from 'views/commons/ReactMarkdown';
import ScalableIframe from 'views/commons/ScalableIframe';
import currencies from 'views/commons/currencies';

export default {
	...currencies,
	'components.AutoScrollingText': {
		factory: AutoScrollingText,
		deps: [{ $ref: 'components.base.AutoScrollingText' }],
	},
	'components.MatchingImageType.template': {
		factory: MatchingImageType,
		deps: [{ $ref: 'components.Image' }],
	},
	'components.ImageViewer': {
		factory: ImageViewer,
		deps: [{ $ref: 'components.base.ImageViewer' }],
	},
	'components.ReactMarkdown': {
		factory: ReactMarkdown,
	},
	'components.ScalableIframe': {
		factory: ScalableIframe,
	},
};
