import React, { PureComponent } from 'react';
import { Form, Formik } from 'formik';

export default function(Layout, userProfileService, IsAnonymous, joinTypes) {
	return class JoinTask extends PureComponent {
		render() {
			const { task, goBack } = this.props;

			return (
				<Formik
					initialValues={{}}
					onReset={() => this.props.task.reset()}
					onSubmit={({ answer }, actions) => this._finish().then(() => actions.setSubmitting(false))}
				>
					{({ handleReset, isSubmitting }) => (
						<Form className="task__join middle wrapper--col">
							<Layout
								task={task}
								actions={{
									submit: {
										buttonText: 'task-details.join.button.join',
									},
									goBack,
									tryAgain: handleReset,
									isSubmitting,
								}}
							>
								<div className="task__container el--align--center">{this._prepareView()}</div>
							</Layout>
						</Form>
					)}
				</Formik>
			);
		}

		_prepareView() {
			const { task } = this.props;
			const JoinComponent = joinTypes[task.joinType()];

			if (userProfileService.isAnonymous()) {
				return <IsAnonymous />;
			}

			return (
				<JoinComponent
					checkMembership={membership => this._checkMembership(membership)}
					onChange={membership => this._onChange(membership)}
					task={task}
				/>
			);
		}

		_checkMembership(membership) {
			const { task } = this.props;

			if (!membership) {
				return;
			}

			if (task.state().hasStatus('completed') && task.viewWhenComplete()) {
				return;
			}

			task.setMembership(true);
			this._finish();
		}

		_onChange(membership) {
			this.props.task.setMembership(membership);
			this.forceUpdate();
		}

		_finish() {
			const { task } = this.props;
			let data = {};

			if (task.membership().id) {
				data[task.joinType()] = {
					id: task.membership().id,
				};
			}

			return this.props.process(data);
		}

		_tryAgain() {
			this.props.task.reset();
			this.forceUpdate();
		}
	};
}
