import ChatweeIntegration from 'models/application/integration/ChatweeIntegration';

export default class ChatweeIntegrationService {
	constructor(APIService, cookieService) {
		this.APIService = APIService;
		this._cookieService = cookieService;
		this._chatId = null; // chat_id ???????????
		this._cookieName = null;
		this._cookieSessionId = null;
		this._chatweeManager = null;
	}

	toModel(integration) {
		return new ChatweeIntegration(integration);
	}

	init() {
		this._session().then(({ cookie_name, session_id }) => {
			this._cookieName = cookie_name;
			this._cookieSessionId = session_id;

			this._cookieService.setCookie(this._cookieName, this._cookieSessionId, 1);

			this._attachScript().then(() => {
				this._chatweeManager = new window.ChatweeLib.ChatweeManager(this._chatId);
				this._chatweeManager.Run();
			});
		});
	}

	unregister() {
		this._cookieService.deleteCookie(this._cookieName);
		this._chatweeManager.Dispose();
	}

	_session() {
		return this.APIService.get('v1/integration/chatwee/session');
	}

	_attachScript() {
		const script = document.createElement('script');

		script.setAttribute('async', true);
		script.setAttribute('src', 'https://static.chatwee-api.com/v2/script.js');

		document.head.appendChild(script);

		return new Promise(resolve => {
			script.onload = resolve;
		});
	}
}
