import ItemAssetService from 'services/store/itemAsset/ItemAssetService';

import subServices from 'services/store/itemAsset/subServices';

export default {
	'services.itemAssetService': {
		class: ItemAssetService,
		calls: [
			{
				name: 'registerSubService',
				args: ['image', { $ref: 'services.imageItemAssetService' }],
			},
		],
	},
	...subServices,
};
