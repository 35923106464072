import DeclarationTaskButton from 'views/application/realTimeNotifications/button/DeclarationTaskButton';
import DefaultButton from 'views/application/realTimeNotifications/button/DefaultButton';
import NextTaskButton from 'views/application/realTimeNotifications/button/NextTaskButton';

export default {
	'components.RealTimeNotifications.buttons.declarationTask': {
		factory: DeclarationTaskButton,
	},
	'components.RealTimeNotifications.buttons.default': {
		factory: DefaultButton,
	},
	'components.RealTimeNotifications.buttons.nextTask': {
		factory: NextTaskButton,
	},
};
