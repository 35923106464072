import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
//import classnames from 'classnames';

export default injectIntl(({ intl, count, page, rowsPerPage, onChange: setPage = () => {} }) => {
	const pageCount = Math.ceil(count / rowsPerPage);
	const from = page * rowsPerPage + 1;
	const to = Math.min((page + 1) * rowsPerPage, count);

	useEffect(() => {
		if (page < 0) {
			setPage(0);
		} else if (page >= pageCount) {
			setPage(pageCount - 1);
		}
	}, [page, pageCount, setPage]);

	if (page < 0 || page > pageCount) {
		return null;
	}

	return (
		<div className="pagination d-flex justify-content-end flex-wrap mb-3">
			<p className="align-self-center px-2">{`${from}-${to} ${intl.formatMessage({
				id: 'contact-list.pagination.of',
			})} ${count}`}</p>
			<button className="m-1 px-2 py-1" onClick={() => setPage(0)} disabled={page === 0}>
				<i className="fa fa-angle-double-left" />
			</button>
			<button className="m-1 px-2 py-1" onClick={() => setPage(page - 1)} disabled={page === 0}>
				<i className="fa fa-angle-left" />
			</button>
			<button className="m-1 px-2 py-1 active">{page + 1}</button>
			{/*Array.from({ length: pageCount }).map((_, index) => (
				<button
					key={index}
					className={classnames('m-1 p-2', {
						active: page === index,
					})}
					onClick={() => setPage(index)}
				>
					{index + 1}
				</button>
			))*/}
			<button className="m-1 px-2 py-1" onClick={() => setPage(page + 1)} disabled={page === pageCount - 1}>
				<i className="fa fa-angle-right" />
			</button>
			<button className="m-1 px-2 py-1" onClick={() => setPage(pageCount - 1)} disabled={page === pageCount - 1}>
				<i className="fa fa-angle-double-right" />
			</button>
		</div>
	);
});
