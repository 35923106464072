import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

export type ErrorLayerProps = InjectedIntlProps & {
	message: string;
	error: {
		error: unknown;
		url: string;
	};
};

export const ErrorLayer = ({ intl, message, error: { error, url } }: ErrorLayerProps) => {
	return (
		<div className="view__error">
			<div className="content">
				<div className="icon text-center">
					<i className="fa fa-lock" />
				</div>
				<h2 className="mb-4">
					{intl.formatMessage({
						id: 'general-error.title',
					})}
					: {message}
				</h2>
				<a href="/" className="btn__basic mb-4">
					<span>
						{intl.formatMessage({
							id: 'general-error.refresh',
						})}
					</span>
				</a>
				<p className="info mb-4 pt-4">
					{intl.formatMessage({
						id: 'general-error.contact',
					})}
				</p>
				<p className="info mb-4 pt-4">
					<strong>
						{intl.formatMessage({
							id: 'general-error.details',
						})}
						:
					</strong>
					<br />
					<br />
					URL: {url}
					<br />
					{JSON.stringify(error)}
				</p>
			</div>
		</div>
	);
};

export default injectIntl(ErrorLayer);
