import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import { injectIntl } from 'react-intl';
import { Formik } from 'formik';

import Email from './Email.template';
import { schema } from './Email.schema';
import Info from '../../../Info';

export class EmailComponent extends PureComponent {
	_status = null;

	render() {
		const { intl } = this.props;

		return (
			<Formik
				initialValues={{
					email: '',
					password: '',
				}}
				validationSchema={schema}
				onSubmit={(...args) => this.login(...args)}
				render={props => {
					if (props.status === 'reset-password') {
						return (
							<Info
								title={intl.formatMessage({
									id: 'auth.login.integration.email.status.reset-password.title',
								})}
								message={intl.formatMessage({
									id: 'auth.login.integration.email.status.reset-password.message',
								})}
							/>
						);
					}

					return <Email {...props} {...this.props} {...this.state} />;
				}}
			/>
		);
	}

	login(values, actions) {
		const { integration } = this.props;

		if (values.password) {
			return this.props['services.emailIntegrationService']
				.login({
					id: integration.id,
					...values,
				})
				.catch(({ error }) => {
					actions.setFieldError(
						error.messageId === 'invalid-password' ? 'password' : 'email',
						error.messageId
					);
					actions.setSubmitting(false);
				});
		}

		if (!values.password && !integration.annoying && !this._status) {
			return this.props['services.emailIntegrationService']
				.check({
					id: integration.id,
					...values,
				})
				.then(response => {
					actions.setStatus(response);
					this._status = 'user-ok';
				})
				.catch(error => {
					if (error === 'user-not-verified') {
						return this.props['services.emailIntegrationService']
							.resetPasswordStart({
								id: integration.id,
								email: values.email,
							})
							.then(() => actions.setStatus('reset-password'))
							.catch(_error => actions.setFieldError('email', _error));
					} else {
						actions.setFieldError('email', error);
					}
				})
				.finally(() => actions.setSubmitting(false));
		}

		actions.setFieldError('password', 'empty-password');
		actions.setSubmitting(false);
	}
}

export default injectIntl(wire(['services.emailIntegrationService'], EmailComponent));
