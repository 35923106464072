import InternalDynamicAnchorChallenge from 'models/application/anchor/types/internalDynamic/types/Challenge';

export default class InternalDynamicAnchorChallengeService {
	constructor(challengeService) {
		this._challengeService = challengeService;
	}

	toModel({ challenge, ...rest }) {
		return new InternalDynamicAnchorChallenge({
			challenge: this._challengeService.toModel(challenge),
			...rest,
		});
	}
}
