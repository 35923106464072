import { createUseStyles } from 'react-jss';

export default ({ tooltip }) => {
	return createUseStyles(theme => ({
		goBack: {
			position: 'relative',

			'&::after': {
				content: `'${tooltip}'`,
				zIndex: 1050,
				visibility: 'hidden',
				position: 'absolute',
				whiteSpace: 'nowrap',
				padding: '7px 9px',
				borderBottomLeftRadius: '5px',
				borderBottomRightRadius: '5px',
				backgroundColor: theme.color.system['color--primary--background'],
				bottom: 0,
				left: '50%',
				transform: 'translate(-50%, 0)',
				fontSize: '12px',
				transition: 'color 0.1s ease-in, bottom 0.1s ease-in',
				textTransform: 'none',
			},

			'&:hover': {
				'&::after': {
					visibility: 'visible',
					bottom: '-25px',
				},
			},
		},
		arrows: {
			position: 'relative',
			width: '40px',
			height: '40px',
			borderRadius: '50%',
			transition: 'background-color 0.05s ease-in',
			cursor: 'pointer',
			zIndex: 1100,

			'&:hover': {
				backgroundColor: theme.color.system['color--interactive--background'],
			},

			'&::after, &::before': {
				position: 'absolute',
				content: '""',
				width: '11px',
				height: '3px',
				borderRadius: '3px',
				backgroundColor: theme.color.system['color--primary--foreground'],
				transition: 'all 0.15s ease-in',
				left: '50%',
				top: '50%',
			},

			'&::after': {
				transform: 'rotate(50deg) translate(-2px, 7px)',
			},

			'&::before': {
				transform: 'rotate(-50deg) translate(-1px, -8px)',
			},
		},
	}));
};
