import Employee, { EmployeeDTO } from '../employee/Employee';

export type RoleDTO = {
	id: string;
	name: string;
	initials: string | null;
	color: string;
	users: EmployeeDTO[];
};

export default class Role {
	private _id: string;
	private _name: string;
	private _initials: string | null;
	private _color: string;
	private _users: Employee[];

	constructor(role: RoleDTO) {
		this._id = role.id;
		this._name = role.name;
		this._initials = role.initials;
		this._color = role.color;
		this._users = role.users.map(user => new Employee(user));
	}

	get id() {
		return this._id;
	}

	get name() {
		return this._name;
	}

	get initials() {
		return this._initials;
	}

	get color() {
		return this._color;
	}

	get users() {
		return this._users;
	}
}
