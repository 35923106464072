import openQuestionServices from 'services/common/form/question/open';
import DeclarationQuestionService from 'services/common/form/question/DeclarationQuestionService';
import DropdownClosedQuestionService from 'services/common/form/question/DropdownClosedQuestionService';
import MultipleChoiceClosedQuestionService from 'services/common/form/question/MultipleChoiceClosedQuestionService';
import SingleChoiceClosedQuestionService from 'services/common/form/question/SingleChoiceClosedQuestionService';

export default {
	...openQuestionServices,
	'services.declarationQuestionService': {
		class: DeclarationQuestionService,
		deps: [{ $ref: 'services.questionAnswerService' }],
	},
	'services.dropdownClosedQuestionService': {
		class: DropdownClosedQuestionService,
		deps: [{ $ref: 'services.questionAnswerService' }],
	},
	'services.multipleChoiceClosedQuestionService': {
		class: MultipleChoiceClosedQuestionService,
		deps: [{ $ref: 'services.questionAnswerService' }],
	},
	'services.singleChoiceClosedQuestionService': {
		class: SingleChoiceClosedQuestionService,
		deps: [{ $ref: 'services.questionAnswerService' }],
	},
};
