import blocks from 'services/common/contentBlocks/blocks';
import ContentBlocksService from 'services/common/contentBlocks/ContentBlocksService';

export default {
	...blocks,
	'services.contentBlocksService': {
		class: ContentBlocksService,
		deps: [{ $ref: 'services.logService' }],
		calls: [
			{
				name: 'registerSubService',
				args: ['attributes', { $ref: 'services.attributesContentBlockService' }],
			},
			{
				name: 'registerSubService',
				args: ['button', { $ref: 'services.buttonContentBlockService' }],
			},
			{
				name: 'registerSubService',
				args: ['header', { $ref: 'services.headerContentBlockService' }],
			},
			{
				name: 'registerSubService',
				args: ['image', { $ref: 'services.imageContentBlockService' }],
			},
			{
				name: 'registerSubService',
				args: ['text', { $ref: 'services.textContentBlockService' }],
			},
		],
	},
};
