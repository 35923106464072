import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import View from 'components/_/common/view/list/ListView';

import ChallengeList from './ChallengeList';

export default function ChallengeListView(props) {
	const { tab } = props.match.params;
	const [search, setSearch] = useState('');

	return (
		<View
			name="challengeList"
			i18nKey="challenge-list"
			config={{
				search: true,
			}}
			setSearch={setSearch}
			search={search}
			tabs={{
				url: '/challenge/list/${tab}', // eslint-disable-line no-template-curly-in-string
				list: ['open', 'future', 'finished', 'all'].map(tab => ({
					tab,
					content: <FormattedMessage id={`challenge-list.tabs.${tab}`} />,
				})),
			}}
		>
			<ChallengeList tab={tab} search={search} />
		</View>
	);
}
