import ImageScalerService from 'services/application/image/ImageScalerService';
import ImageService from 'services/application/image/ImageService';

export default {
	'services.imageScalerService': {
		class: ImageScalerService,
	},
	'services.imageService': {
		class: ImageService,
		deps: [[32, 128, 256, 512, 1024, 1366, 1920, 3840]],
	},
};
